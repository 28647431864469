import React, { useState } from 'react'
import AkahuLinkModal from './AkahuLinkModal';

const AkahuLink=({ButtonUI})=>{
  const [isOpen,setIsOpen]=useState(false);



    return(
        <div onClick={()=>setIsOpen(true)}>
          <AkahuLinkModal
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          />
            <ButtonUI />
        </div>
    )
}

export default AkahuLink;