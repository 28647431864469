import React, { useState, useEffect, useRef } from "react";
import useClickOutside from "../../../utils/useClickOutside";

const InternalVendorFlyout = ({flyoutOpen, setFlyoutOpen, vendor}) => {
  const flyoutContent = useRef(null);

  useClickOutside(flyoutContent, () => {
    if (flyoutOpen) {
      setFlyoutOpen(false);
    }
  });

  return (
    <>
      <div
        ref={flyoutContent}
        className={`absolute inset-0 bg-white sm:left-auto shadow-xl transition-transform duration-200 ease-in-out z-40  ${
          flyoutOpen ? "translate-x-0" : "translate-x-full"
        }`}
      >
        <div
          className="sticky top-16 bg-white dark:bg-gradient-to-b dark:from-slate-800 dark:to-slate-900 overflow-x-hidden overflow-y-auto no-scrollbar shrink-0  w-full sm:w-[455px] h-[calc(100dvh-64px)]"
          style={{ boxShadow: " -10px 0px 15px 0px #D0D5DD80" }}         

        >
          <div className="sticky top-0 h-[3.875rem] bg-white z-10">
            <div className="flex justify-end pt-2.5 pr-2.5">
              <button
                className="ml-auto group"
                onClick={() => setFlyoutOpen(false)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 384 512"
                  className="h-5 w-5 fill-[#667085] group-hover:fill-slate-700"
                >
                  <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
                </svg>
              </button>
            </div>
            <div className="relative flex flex-row gap-[3.125rem] justify-start items-center mt-3 pb-1 w-full border-b-2 border-gray-300 pl-[1.625rem] bg-white">
              <p
                className={`text-sm leading-6 font-normal cursor-pointer text-black`} 
              >
                Vendor Details
              </p>
            </div>
          </div>
          <div className="bg-white w-full flex flex-col items-center justify-center gap-5 py-6 px-7">
            {JSON.stringify(vendor, null, 2)}
          </div>
        </div>
      </div>
    </>
  );
};

export default InternalVendorFlyout;
