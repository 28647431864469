import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { showNewMessage, boot } from '@intercom/messenger-js-sdk';

import {
  getTeamSubscriptions,
  subscriptionCheckout,
} from '../../../API/backend_helper';
import {
  getCurrencySymbol,
  IS_ACCOUNTANT,
  TEAM_ID,
} from '../../../utils/Utils';
import { setSubscription } from '../../../redux/Subscription/reducer';
import SubscriptionConfirmationModal from './SubscriptionConfirmationModal';

const SubscriptionPlan = ({
  subscriptions = [],
  selectedSubscription = null,
  setSelectedSubscription,
  isBillingPage = false,
  subscription = null,
  country = '',
  hasUsedTrial = true,
  teams = [],
}) => {
  const dispatch = useDispatch();
  const teamId = localStorage.getItem(TEAM_ID);
  const { team } = useSelector((state) => state.Team);
  const _country = isBillingPage ? team?.country : country;

  const FREE_FEATURES = [
    'Connect 1 live bank account feed.',
    'Access all accounting tools at no cost.',
    'Ideal for startups and small teams.',
    `Monthly expense or revenue limit of ${_country === 'NZ' ? 'NZ$25K' : _country === 'ZA' ? 'R250K' : _country === 'ZA' ? 'C$15K' : '$15K'}.`,
  ];
  // ${team?.currency==="CA" "NZ" "ZA"}
  const GROWING_FEATURES = [
    'Connect up to 3 live bank account feeds.',
    'Great for small, established businesses.',
    `Monthly expense or revenue limit of ${_country === 'NZ' ? 'NZ$300K' : _country === 'ZA' ? 'R3.5M' : _country === 'ZA' ? 'C$200K' : '$200K'}.`,
  ];

  const PRO_FEATURES = [
    'Connect unlimited bank accounts.',
    'Remove COUNT branded communications.',
    'Perfect for small to medium sized businesses.',
    'No monthly expense or revenue limit.',
  ];

  const ENTERPRISE_FEATURES = [
    'Custom integrations and features upon request.',
    'SSO login and guaranteed SLAs.',
    'Tailored solutions for complex business requirements.',
    'Premium support with dedicated account management.',
  ];

  useEffect(() => {
    boot();
  }, []);

  const [loading, setLoading] = useState(false);
  const [localSubscriptions, setLocalSubscriptions] = useState([]);
  const IsAccountant = localStorage.getItem(IS_ACCOUNTANT)
    ? JSON.parse(localStorage.getItem(IS_ACCOUNTANT))
    : null;
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [planId, setPlanId] = useState(null);
  const [confirmationText, setConfirmationText] = useState('');

  useEffect(() => {
    if (subscriptions?.length > 0) {
      const temp = [...subscriptions];
      temp.sort(
        (a, b) => a?.prices?.[0]?.unit_amount - b?.prices?.[0]?.unit_amount,
      );
      setLocalSubscriptions(temp);
    }
  }, [subscriptions]);

  const onPricePlanChange = (id, sub) => {
    setPlanId(id);
    // ${
    //   subscription?.items?.data?.filter(
    //     (_d) => _d?.plan?.product === selectedSubscription,
    //   )?.[0]?.price?.unit_amount > sub?.prices?.[0]?.unit_amount
    //     ? 'downgrading plan'
    //     : 'upgrading Plan'
    // }
    setConfirmationText(
      `Are you sure you want to change your subscription to: ${sub?.name}`,
    );
    setConfirmationModal(true);
  };

  const onPricePlanChangeConfirm = async () => {
    try {
      setLoading(planId);
      const res = await subscriptionCheckout(planId);
      if (res?.data?.session?.url) {
        window.location = res?.data?.session?.url;
      } else {
        const res = await getTeamSubscriptions(teamId);
        dispatch(setSubscription(res?.data?.subscription));
      }
      setConfirmationModal(false);
      setConfirmationText('');
      setPlanId(null);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log('err', err);
    }
  };

  const onSubscriptionModalClose = () => {
    setConfirmationModal(false);
    setConfirmationText('');
    setPlanId(null);
  };

  return (
    <div className={`grid grid-cols-12 gap-5 ${isBillingPage ? 'my-8' : ''}`}>
      <SubscriptionConfirmationModal
        setIsOpen={setConfirmationModal}
        isOpen={confirmationModal}
        text={confirmationText}
        loading={loading}
        onConfirm={onPricePlanChangeConfirm}
        onClose={onSubscriptionModalClose}
      />
      {
        // if accountant and it's first team then don't show it
        !(IsAccountant && teams?.length === 0) &&
          localSubscriptions?.map((sub) => (
            <div
              className="col-span-12 sm:col-span-6 lg:col-span-4 1xl:col-span-3 rounded-[10px] border border-[#D0D5DD]"
              key={sub?.id}
            >
              <div className="h-[89px] border-b border-[#D0D5DD] bg-white w-full rounded-t-[inherit] px-[22px] inline-flex justify-between items-center">
                <h3 className="text-[24px] font-semibold text-[#232323CC]">
                  {sub?.name?.replace('Plan', '')}
                </h3>
                {isBillingPage && (
                  <h3 className="text-[24px] font-semibold text-indigo-500">
                    {sub?.prices?.[0]?.unit_amount === 0
                      ? 'No Charge'
                      : `${getCurrencySymbol(sub?.prices?.[0]?.currency)}${parseFloat(
                          sub?.prices?.[0]?.unit_amount / 100,
                        )?.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}`}
                  </h3>
                )}
              </div>
              <div className="min-h-[250px] h-fit px-6 py-4 w-full flex flex-col justify-center gap-4 border-b border-[#D0D5DD]">
                {(sub?.name === 'Free Plan'
                  ? FREE_FEATURES
                  : sub?.name === 'Growing Plan'
                    ? GROWING_FEATURES
                    : sub?.name === 'Pro Plan'
                      ? PRO_FEATURES
                      : []
                ).map((f, index) => (
                  <div key={index} className="flex gap-3">
                    <svg
                      width="22"
                      height="23"
                      viewBox="0 0 22 23"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="min-h-[22px] min-w-[22px]"
                    >
                      <path
                        d="M6.5 11.5L9.5 14.5L15.5 8.5M21 11.5C21 17.0228 16.5228 21.5 11 21.5C5.47715 21.5 1 17.0228 1 11.5C1 5.97715 5.47715 1.5 11 1.5C16.5228 1.5 21 5.97715 21 11.5Z"
                        stroke="#78BD4F"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    <p className="text-[#667085]">{f}</p>
                  </div>
                ))}
              </div>

              <div className="py-6 px-7">
                <button
                  className={`h-[53px] w-full px-4 leading-tight   rounded-[6px] inline-flex justify-center items-center gap-2 text-[16px] font-semibold disabled:bg-indigo-400 disabled:cursor-not-allowed
                    ${(isBillingPage ? sub?.id : sub?.prices?.[0]?.id) === selectedSubscription ? 'bg-indigo-400 text-white' : 'bg-white border text-indigo-500 border-indigo-500'}
                    `}
                  disabled={sub?.id === selectedSubscription}
                  // onClick={onGustoConnect}
                  onClick={() =>
                    isBillingPage
                      ? onPricePlanChange(sub?.prices?.[0]?.id, sub)
                      : setSelectedSubscription(sub?.prices?.[0])
                  }
                  type="button"
                >
                  {loading === sub?.prices?.[0]?.id && (
                    <svg
                      className="animate-spin w-4 h-4 fill-current shrink-0 mr-2"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z" />
                    </svg>
                  )}
                  {!isBillingPage
                    ? sub?.prices?.[0]?.unit_amount === 0
                      ? 'No Charge'
                      : `${getCurrencySymbol(sub?.prices?.[0]?.currency)}${parseFloat(
                          sub?.prices?.[0]?.unit_amount / 100,
                        )?.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}`
                    : (isBillingPage ? sub?.id : sub?.prices?.[0]?.id) ===
                        selectedSubscription
                      ? 'Subscribed'
                      : subscription?.items?.data?.filter(
                            (_d) => _d?.plan?.product === selectedSubscription,
                          )?.[0]?.price?.unit_amount >
                          sub?.prices?.[0]?.unit_amount
                        ? 'Downgrade Plan'
                        : sub?.prices?.[0]?.unit_amount === 0
                          ? 'Subscribe'
                          : !hasUsedTrial
                            ? 'Start Free Trial'
                            : 'Upgrade Plan'}
                </button>
              </div>
            </div>
          ))
      }

      {IsAccountant && teams?.length === 0 ? (
        <div className="col-span-12 rounded-[10px] border border-[#D0D5DD]">
          <div className="h-[89px] border-b border-[#D0D5DD] bg-white w-full rounded-t-[inherit] px-[22px] inline-flex justify-between items-center">
            <h3 className="text-[24px] font-semibold text-[#232323CC]">
              Growing
            </h3>
          </div>
          <div className="min-h-[250px] h-fit px-6 py-4 w-full flex flex-col justify-center gap-4 border-b border-[#D0D5DD]">
            {GROWING_FEATURES.map((f, index) => (
              <div key={index} className="flex gap-3">
                <svg
                  width="22"
                  height="23"
                  viewBox="0 0 22 23"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="min-h-[22px] min-w-[22px]"
                >
                  <path
                    d="M6.5 11.5L9.5 14.5L15.5 8.5M21 11.5C21 17.0228 16.5228 21.5 11 21.5C5.47715 21.5 1 17.0228 1 11.5C1 5.97715 5.47715 1.5 11 1.5C16.5228 1.5 21 5.97715 21 11.5Z"
                    stroke="#78BD4F"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <p className="text-[#667085]">{f}</p>
              </div>
            ))}
          </div>

          <div className="py-6 px-7">
            <button
              className={`h-[53px] w-full px-4 leading-tight   rounded-[6px] inline-flex justify-center items-center gap-2 text-[16px] font-semibold disabled:bg-indigo-400 disabled:cursor-not-allowed
                    bg-indigo-400 border text-white border-indigo-400
                    `}
              type="button"
              // onClick={() => {
              //   showNewMessage(
              //     "Hi, I'm interested in the Enterprise Plan. Can we discuss how it can fit our business needs?",
              //   );
              // }}
            >
              Selected
            </button>
          </div>
        </div>
      ) : (
        <div className="col-span-12 sm:col-span-6 lg:col-span-4 1xl:col-span-3 rounded-[10px] border border-[#D0D5DD]">
          <div className="h-[89px] border-b border-[#D0D5DD] bg-white w-full rounded-t-[inherit] px-[22px] inline-flex justify-between items-center">
            <h3 className="text-[24px] font-semibold text-[#232323CC]">
              Enterprise
            </h3>
            {isBillingPage && (
              <h3 className="text-[24px] font-semibold text-indigo-500">
                Custom
              </h3>
            )}
          </div>
          <div className="min-h-[250px] h-fit px-6 py-4 w-full flex flex-col justify-center gap-4 border-b border-[#D0D5DD]">
            {ENTERPRISE_FEATURES.map((f, index) => (
              <div key={index} className="flex gap-3">
                <svg
                  width="22"
                  height="23"
                  viewBox="0 0 22 23"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="min-h-[22px] min-w-[22px]"
                >
                  <path
                    d="M6.5 11.5L9.5 14.5L15.5 8.5M21 11.5C21 17.0228 16.5228 21.5 11 21.5C5.47715 21.5 1 17.0228 1 11.5C1 5.97715 5.47715 1.5 11 1.5C16.5228 1.5 21 5.97715 21 11.5Z"
                    stroke="#78BD4F"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <p className="text-[#667085]">{f}</p>
              </div>
            ))}
          </div>

          <div className="py-6 px-7">
            <button
              className={`h-[53px] w-full px-4 leading-tight   rounded-[6px] inline-flex justify-center items-center gap-2 text-[16px] font-semibold disabled:bg-indigo-400 disabled:cursor-not-allowed
                    bg-white border text-indigo-500 border-indigo-500
                    `}
              type="button"
              onClick={() => {
                showNewMessage(
                  "Hi, I'm interested in the Enterprise Plan. Can we discuss how it can fit our business needs?",
                );
              }}
            >
              Talk with a Specialist
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default SubscriptionPlan;
