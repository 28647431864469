import React from "react";

export const DecreaseIcon = () => {
  return (
    <svg
      width='17'
      height='17'
      viewBox='0 0 17 17'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clip-path='url(#clip0_2843_77835)'>
        <path
          d='M12.75 16.2904L6.02083 9.5612L9.5625 6.01953L4.25 0.707031'
          stroke='#101828'
          stroke-width='1.5'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M12.75 12.0391L12.75 16.2891L8.5 16.2891'
          stroke='#101828'
          stroke-width='1.5'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_2843_77835'>
          <rect
            width='17'
            height='17'
            fill='white'
            transform='translate(17) rotate(90)'
          />
        </clipPath>
      </defs>
    </svg>
  );
};
