import React from 'react';
import Tooltip from '../../../components/Tooltip';
import { getStringSegments, trimText } from '../../../utils/Utils';

const OverviewTab = ({ people }) => (
  <div className="grid grid-cols-12 gap-6">
    {/* Box 1  */}
    <div
      className="flex flex-col w-full col-span-full md:col-span-8 max-w-full  border border-black border-opacity-5 py-4 px-[1rem] bg-white rounded-xl"
      style={{
        boxShadow: '0px 4px 14px 0px rgba(0, 0, 0, 0.06)',
      }}
    >
      {/* Top Section */}
      <div className="flex justify-between w-full items-center">
        <p className="text-slate-600 text-[16px] font-medium leading-6">
          {people?.firstName} {people?.lastName}
        </p>
      </div>
      <div className="flex items-center self-stretch gap-2 mt-4">
        <p className="text-slate-600 text-[0.875rem] font-bold leading-5">
          Details
        </p>
        <div className="bg-[#C6C7C9] h-[0.5px] w-full flex-grow flex-shrink-0 basis-0" />
      </div>
      <div className="flex items-start justify-between self-stretch mt-4 gap-2">
        <div className="flex flex-col w-1/2">
          <p className="text-slate-600 font-medium text-[0.938rem] leading-6">
            Email
          </p>

          <Tooltip
            className="w-fit"
            tooltipShow={people?.email?.length > 40}
            content={
              people?.email?.length > 30
                ? (() => {
                    const segments = getStringSegments(people?.email, 30);
                    return segments.map((segment, index) => (
                      <p key={index}>
                        {segment}
                        {index !== segments?.length - 1 ? '-' : ''}{' '}
                      </p>
                    ));
                  })()
                : people?.email
            }
            contentClassName="border-none rounded-[10px] overflow-visible text-sm text-[#667085] relative tooltipArrowDown"
          >
            <p className="text-slate-600 text-[0.875rem] overflow-hidden text-ellipsis  leading-tight">
              {trimText(people?.email || '', 40)}
            </p>
          </Tooltip>
        </div>
        <div className="flex flex-col w-1/2">
          <p className="text-slate-600 font-medium text-[0.938rem] leading-6">
            Phone
          </p>
          <p className="text-slate-600 leading-6 text-[0.875rem]">
            {people?.phone}
          </p>
        </div>
      </div>
      <div className="flex items-start justify-between self-stretch mt-3">
        <div className="flex flex-col w-1/2">
          <p className="text-slate-600 font-medium text-[0.938rem] leading-6">
            Position
          </p>
          <p className="text-slate-600 leading-6 text-[0.875rem]">
            {people?.position}
          </p>
        </div>
        <div className="flex flex-col w-1/2">
          <p className="text-slate-600 font-medium text-[0.938rem] leading-6">
            Type
          </p>
          <p className="text-slate-600 leading-6 text-[0.875rem]">
            {people?.type}
          </p>
        </div>
        <div className="flex flex-col w-1/2">
          <p className="text-slate-600 font-medium text-[0.938rem] leading-6">
            Role
          </p>
          <p className="text-slate-600 leading-6 text-[0.875rem] capitalize">
            {people?.role?.map((r, i) => (
              <div key={i}>{r}</div>
            ))}
          </p>
        </div>
      </div>
      <div className="mt-3">
        <p className="text-slate-600 font-medium text-[0.938rem] leading-6">
          Address
        </p>
        <p className="text-slate-600 leading-6 text-[0.875rem]">
          {people?.address?.street}{' '}
          {people?.address?.street2 ? `, ${people?.address?.street2}` : ''}
        </p>
        <p className="text-slate-600 leading-6 text-[0.875rem]">
          {people?.address?.city}{' '}
          {people?.address?.country ? `, ${people?.address?.country}` : ''}
        </p>
        <p className="text-slate-600 leading-6 text-[0.875rem]">
          {people?.address?.zipCode}{' '}
        </p>
      </div>
    </div>
    {/* Box 2  */}
    {people?.provider === 'gusto' && (
      <div
        className="col-span-full md:col-span-4 flex flex-col w-full max-w-full  border border-black border-opacity-5 py-4 px-[1rem] bg-white rounded-xl"
        style={{
          boxShadow: '0px 4px 14px 0px rgba(0, 0, 0, 0.06)',
        }}
      >
        {/* Top Section */}
        <div className="flex justify-between w-full items-center">
          <p className="text-slate-600 text-[16px] font-medium leading-6">
            Gusto Information
          </p>
        </div>
        <div className="flex items-center self-stretch gap-2 mt-4">
          <p className="text-slate-600 text-[0.875rem] font-bold leading-5">
            Details
          </p>
          <div className="bg-[#C6C7C9] h-[0.5px] w-full flex-grow flex-shrink-0 basis-0" />
        </div>
        <div className="flex items-start justify-between self-stretch mt-4">
          <div className="flex flex-col w-1/2">
            <p className="text-slate-600 font-medium text-[0.938rem] leading-6">
              Position
            </p>
            <p className="text-slate-600 leading-6 text-[0.875rem]">
              {people?.position}
            </p>
          </div>
          <div className="flex flex-col w-1/2">
            <p className="text-slate-600 font-medium text-[0.938rem] leading-6">
              Wage
            </p>
            <p className="text-slate-600 leading-6 text-[0.875rem]">
              {people?.jobs?.length > 0 &&
                people?.jobs?.[people?.jobs?.length - 1]?.compensations
                  ?.length > 0 && (
                  <span>
                    $
                    {parseFloat(
                      people?.jobs?.[people?.jobs?.length - 1]?.compensations?.[
                        people?.jobs?.[people?.jobs?.length - 1]?.compensations
                          ?.length - 1
                      ]?.rate,
                    )?.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                    /
                    {
                      people?.jobs?.[people?.jobs?.length - 1]?.compensations?.[
                        people?.jobs?.[people?.jobs?.length - 1]?.compensations
                          ?.length - 1
                      ]?.paymentUnit
                    }
                  </span>
                )}
            </p>
          </div>
        </div>
      </div>
    )}
  </div>
);

export default OverviewTab;
