import React, { useEffect, useRef, useState } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import {
  changePassword,
  changePersonPassword,
  updateUser,
  updateUserAvatar,
} from '../../API/backend_helper';
import TextInputDash from '../../components/TextInputDash';
import AppLayout from '../../components/AppLayout';
import { setLocalUser } from '../../redux/User/reducer';

const AccountDetails = () => {
  const dispatch = useDispatch();
  const IsPerson = localStorage.getItem('IS_PERSON')
    ? JSON.parse(localStorage.getItem('IS_PERSON'))
    : null;

  const formikRef = useRef(null);
  const { localUser } = useSelector((state) => state.User);
  const [avatar, setAvatar] = useState('');
  const [defaultFirst, setDefaultFirst] = useState('');
  const [defaultLast, setDefaultLast] = useState('');
  const [localEmail, setLocalEmail] = useState('');
  const [imageLoading, setImageLoading] = useState(false);
  const [nameLoading, setNameLoading] = useState(false);
  const [passwordLoading, setPasswordLoading] = useState(false);

  document.title = 'COUNT | User Details';

  useEffect(() => {
    if (localUser) {
      setDefaultFirst(localUser?.firstName || '');
      setDefaultLast(localUser?.lastName || '');
      setLocalEmail(localUser.email);
    }
  }, [localUser]);

  const handleSubmit = async (values) => {
    setNameLoading(true);
    try {
      setDefaultFirst(values.firstName);
      setDefaultLast(values.lastName);
      setLocalEmail(values.email);

      const res = await updateUser(localUser.id, values);
      dispatch(setLocalUser(res));
      formikRef.current?.resetForm();
    } catch (err) {
      console.log('err', err);
    } finally {
      setNameLoading(false);
    }
  };

  const handleAvatarSubmit = async ({ avatar }) => {
    setImageLoading(true);
    try {
      const formData = new FormData();
      if (avatar) {
        formData.append('avatar', avatar);
        const res = await updateUserAvatar(localUser.id, formData);
        dispatch(setLocalUser(res));
        setAvatar('');
        console.log('response', res);
      }
    } catch (err) {
      console.log('err', err);
    } finally {
      setImageLoading(false);
    }
  };

  const handlePasswordSubmit = async (values) => {
    setPasswordLoading(true);
    try {
      const { password, oldPassword } = values;
      let res = null;
      if (IsPerson) {
        res = await changePersonPassword(oldPassword, password);
      } else {
        res = await changePassword(oldPassword, password);
      }
      if (res?.data?.updatedUser?.access_token) {
        localStorage.setItem(
          'accessToken',
          res?.data?.updatedUser?.access_token,
        );
      }
      if (res?.data?.updatedUser?.updatedUser) {
        dispatch(setLocalUser(res?.data?.updatedUser?.updatedUser));
      }
      formikRef.current?.resetForm();
      toast.success('Password updated successfully');
    } catch (err) {
      console.log('err', err);
    } finally {
      setPasswordLoading(false);
    }
  };

  return (
    <AppLayout>
      <main className="grow">
        <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
          {/* Page header */}
          <div className="mb-8 flex justify-between">
            <main className="space-y-4 sm:space-y-8 md:space-y-12 grow pb-4 w-full max-w-9xl mx-auto">
              {/* Panel body */}
              <h2 className="text-2xl md:text-[36px] text-[#00000099] dark:text-slate-100 font-medium ">
                User Details
              </h2>
              {!IsPerson && (
                <>
                  {/* Picture */}
                  <Formik
                    enableReinitialize
                    initialValues={{
                      avatar: localUser?.avatarUrl || null,
                    }}
                    onSubmit={handleAvatarSubmit}
                    innerRef={formikRef}
                  >
                    {(validation) => (
                      <Form>
                        <h3 className="text-lg font-medium text-[#101828B2] mb-4">
                          Your Photo
                        </h3>
                        <section className=" grid grid-cols-12 pb-10 border-b border-[#D0D5DD80] gap-2">
                          {/* <div className="col-span-12 md:col-span-4 xl:col-span-3">
                          <h3 className="text-lg font-medium text-[#101828B2] mb-4">
                            Your Photo
                          </h3>
                           <p className="text-sm text-[#667085B2] mb-4 md:mb-0 md:max-w-[240px] leading-tight">
                            If you exclude a transaction, it will not show up in
                            your spending and budget
                          </p>
                        </div> */}
                          <div className="col-span-12 md:col-span-8 flex items-end flex-wrap gap-2">
                            <div className="mr-[30px]">
                              {avatar || localUser?.avatarUrl ? (
                                <img
                                  className="w-20 h-20 rounded-full"
                                  src={avatar || localUser?.avatarUrl}
                                  width="80"
                                  height="80"
                                  alt="User upload"
                                />
                              ) : (
                                <div className="w-20 h-20 rounded-full bg-slate-400 flex justify-center items-center text-white font-semibold text-4xl">
                                  {localUser?.firstName?.charAt(0)}
                                  {localUser?.lastName?.charAt(0)}
                                </div>
                              )}
                            </div>
                            <input
                              id="avatar"
                              type="file"
                              name="avatar"
                              accept=".jpg, .svg, .png, .webp, .gif, .jpeg"
                              className="hidden"
                              onChange={(event) => {
                                validation.setFieldValue(
                                  'avatar',
                                  event.target.files[0],
                                );
                                setAvatar(
                                  URL.createObjectURL(event.target.files[0]),
                                );
                              }}
                              onBlur={validation.handleBlur}
                              required
                            />
                            <label
                              htmlFor="avatar"
                              className="cursor-pointer font-normal whitespace-nowrap py-[11px] px-[10px] sm:px-[20px] lg:px-[30px] inline-flex items-center justify-center btn border-[#D0D5DD66] text-base shadow-md hover:shadow-lg rounded text-slate-600 mr-4"
                            >
                              Choose An Image
                            </label>
                            <button
                              type="submit"
                              className="btn py-[11px] px-[10px]  sm:px-[20px] lg:px-[30px] whitespace-nowrap text-base
                            !bg-indigo-500 hover:!bg-indigo-600 shadow-md hover:shadow-lg text-white disabled:!bg-indigo-300 disabled:cursor-not-allowed"
                              disabled={imageLoading || !avatar}
                            >
                              {imageLoading && (
                                <svg
                                  className="animate-spin w-4 h-4 fill-current shrink-0 mr-2"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z" />
                                </svg>
                              )}
                              Save Changes
                            </button>
                          </div>
                        </section>
                      </Form>
                    )}
                  </Formik>
                  {/* User Details */}
                  <section className="grid grid-cols-12  pb-5 border-b border-[#D0D5DD80] gap-2">
                    {/* <div className="col-span-12 md:col-span-4 xl:col-span-3">
                    <h3 className="text-lg font-medium text-[#101828B2] mb-4">
                      Name
                    </h3>
                    <p className="text-sm text-[#667085B2] mb-4 md:mb-0 md:max-w-[240px] leading-tight">
                      If you exclude a transaction, it will not show up in your
                      spending and budget
                    </p>
                  </div> */}
                    <h3 className="text-lg font-medium text-[#101828B2] mb-4">
                      Personal
                    </h3>
                    <div className="col-span-12 md:col-span-12 xl:col-span-12 flex items-end flex-wrap gap-2">
                      <Formik
                        enableReinitialize
                        initialValues={{
                          firstName: defaultFirst || '',
                          lastName: defaultLast || '',
                          email: localEmail || '',
                        }}
                        validationSchema={Yup.object({
                          firstName: Yup.string().required(
                            'Please Enter First Name',
                          ),
                          lastName: Yup.string().required(
                            'Please Enter Last Name',
                          ),
                          email: Yup.string()
                            .email('Invalid email address')
                            .required('Please Enter Email'),
                        })}
                        onSubmit={handleSubmit}
                        innerRef={formikRef}
                      >
                        {(validation) => (
                          <Form>
                            <div className="flex gap-4 items-center flex-wrap  ">
                              <div className="w-[313px]">
                                <TextInputDash
                                  label="Email"
                                  id="email"
                                  name="email"
                                  required
                                  placeholder="Email"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.email || ''}
                                  error={
                                    validation.touched.email &&
                                    validation.errors.email
                                  }
                                />
                              </div>
                              <div className="w-[313px]">
                                <TextInputDash
                                  label="First Name"
                                  id="firstName"
                                  name="firstName"
                                  required
                                  placeholder="First Name"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.firstName || ''}
                                  error={
                                    validation.touched.firstName &&
                                    validation.errors.firstName
                                  }
                                />
                              </div>
                              <div className="w-[313px]">
                                <TextInputDash
                                  label="Last Name"
                                  id="lastName"
                                  name="lastName"
                                  required
                                  placeholder="Last Name"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.lastName || ''}
                                  error={
                                    validation.touched.lastName &&
                                    validation.errors.lastName
                                  }
                                />
                              </div>
                              <button
                                type="submit"
                                className="btn   h-fit py-[11px] mt-[7px] px-[10px] sm:px-[20px] lg:px-[30px] whitespace-nowrap text-base !bg-indigo-500
                              hover:!bg-indigo-600 shadow-md hover:shadow-lg text-white disabled:!bg-indigo-300 disabled:cursor-not-allowed"
                                disabled={nameLoading}
                              >
                                {nameLoading && (
                                  <svg
                                    className="animate-spin w-4 h-4 fill-current shrink-0 mr-2"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z" />
                                  </svg>
                                )}
                                Save Changes
                              </button>
                            </div>
                          </Form>
                        )}
                      </Formik>
                    </div>
                  </section>
                </>
              )}
              {IsPerson && (
                <section className="grid grid-cols-12  pb-5 border-b border-[#D0D5DD80] gap-2">
                  <h3 className="text-lg font-medium text-[#101828B2] mb-4">
                    Personal
                  </h3>
                  <div className="col-span-12 md:col-span-12 xl:col-span-12 flex items-end flex-wrap gap-2">
                    <div className="flex gap-4 items-center flex-wrap  ">
                      <div className="">
                        <label
                          className={`text-sm font-medium text-slate-600 block mb-1 `}
                        >
                          Email:
                        </label>
                        <p>{localEmail || ''}</p>
                      </div>
                    </div>
                  </div>
                </section>
              )}
              {/* Password */}
              <section className="grid grid-cols-12 pb-10  gap-2">
                <div className="col-span-12 md:col-span-4 xl:col-span-3">
                  <h3 className="text-lg font-medium text-[#101828B2] mb-4">
                    Password
                  </h3>
                  {/* <p className="text-sm text-[#667085B2] mb-4 md:mb-0 md:max-w-[240px] leading-tight">
                      If you exclude a transaction, it will not show up in your
                      spending and budget
                    </p> */}
                </div>
                <div className="col-span-12 md:col-span-12 xl:col-span-12 flex items-end flex-wrap gap-2">
                  <Formik
                    enableReinitialize
                    initialValues={{
                      oldPassword: '',
                      password: '',
                      confirmPassword: '',
                    }}
                    validationSchema={Yup.object({
                      oldPassword: Yup.string().required(
                        'Please Enter Old Password',
                      ),
                      password: Yup.string()
                        .required('Please Enter Password')
                        .min(8, 'Password must be at least 8 characters')
                        .matches(/^\S*$/, 'Password cannot contain spaces')
                        .matches(/.*[a-z].*/, 'At least one lowercase letter')
                        .matches(/.*[A-Z].*/, 'At least one uppercase letter')
                        .matches(/.*[0-9].*/, 'At least one number')
                        .max(200, 'Password must be at most 200 characters'),
                      confirmPassword: Yup.string()
                        .required('Confirm password is required')
                        .oneOf(
                          [Yup.ref('password'), null],
                          'Passwords must match',
                        )
                        .max(200, 'Password must be at most 200 characters'),
                    })}
                    onSubmit={handlePasswordSubmit}
                    innerRef={formikRef}
                  >
                    {(validation) => (
                      <Form>
                        <div className="flex gap-4 items-center flex-wrap">
                          <div className="w-[313px]">
                            <TextInputDash
                              label="Old Password"
                              id="oldPassword"
                              name="oldPassword"
                              type="password"
                              required
                              placeholder="Old Password"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.oldPassword || ''}
                              error={
                                validation.touched.oldPassword &&
                                validation.errors.oldPassword
                              }
                            />
                          </div>
                          <div className="w-[313px]">
                            <TextInputDash
                              label="New Password"
                              id="password"
                              name="password"
                              type="password"
                              required
                              placeholder="New Password"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.password || ''}
                              error={
                                validation.touched.password &&
                                validation.errors.password
                              }
                            />
                          </div>
                          <div className="w-[313px]">
                            <TextInputDash
                              label="Confirm New Password"
                              id="confirmPassword"
                              name="confirmPassword"
                              type="password"
                              required
                              placeholder="Confirm New Password"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.confirmPassword || ''}
                              error={
                                validation.touched.confirmPassword &&
                                validation.errors.confirmPassword
                              }
                            />
                          </div>

                          <button
                            type="submit"
                            className="btn   h-fit py-[11px] mt-[7px] px-[10px] sm:px-[20px] lg:px-[30px] whitespace-nowrap text-base
                               !bg-indigo-500 hover:!bg-indigo-600 shadow-md hover:shadow-lg text-white disabled:!bg-indigo-300 disabled:cursor-not-allowed"
                            disabled={passwordLoading}
                          >
                            {passwordLoading && (
                              <svg
                                className="animate-spin w-4 h-4 fill-current shrink-0 mr-2"
                                viewBox="0 0 16 16"
                              >
                                <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z" />
                              </svg>
                            )}
                            Save Changes
                          </button>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </section>
            </main>
          </div>
        </div>
      </main>
    </AppLayout>
  );
};

export default AccountDetails;
