import React from 'react';
import { DateTime } from 'luxon';

import CreateInvoicesTable from './CreateInvoicesTable';
import CustomersBox from './CustomersBox';

const LiveInvoiceDownload = ({
  title,
  invoiceNumber,
  editInvoice,
  attachFiles,
  psoNumber,
  invoiceDate,
  getCountryName,
  type,
  customers,
  team,
  currencies,
  projects,
  taxes,
  setTaxes,
  defaultAttachments = [],
  salesRep,
  tags,

  selectedColor,
  setCustomerModalOpen,
  fontFamily,
  selectedCustomer,
  setSelectedCustomer,
  setInvoiceSalesRep,
  products,
  setSelectedProducts,
  selectedProducts,
  getProductsApi,
  discount,
  setDiscount,
  discountDescription,
  setDiscountDescription,
  categories,
  expenseCategories,
  isDiscountAdded,
  setIsDiscountAdded,
  checkProductRows,
  selectedProject,
  setSelectedProject,
  selectedTax,
  setSelectedTax,
  addTax,
  getTaxById,
  summary,
  setSummary,
  setInvoicePanelOpen,

  setReceiptModalOpen,
  setActiveReceipt,
  invoiceSalesRep,
  selectedTags,
  setSelectedTags,
  discountError,
  setDiscountError,
  isRecurring,
  setIsRecurring,
  recurrencePattern,
  setRecurrencePattern,
  occurrenceCount,
  setOccurrenceCount,
  recurrenceEndDate,
  setRecurrenceEndDate,
  RecurrencePatternOptions,
  inAdvanceCreationDays,
  setInAdvanceCreationDays,
  emailCustomer,
  setEmailCustomer,
  endRecurringType,
  setEndRecurringType,
  invoiceDueDate,
}) => (
  <div
    className="fixed top-0 left-0 -translate-x-full -tranlate-y-full  bg-white  w-[900px]"
    id="invoiceCreatePrint"
  >
    {/* Section 1  */}
    <div
      className="p-6 grid gap-4 grid-cols-12 border-b border-[#D0D5DD]"
      id="invoiceCreatePrint"
    >
      <div className="col-span-6 space-y-2.5">
        <div style={fontFamily ? { fontFamily: fontFamily || '' } : {}}>
          <h3
            style={selectedColor ? { color: selectedColor } : {}}
            className="text-[20px] font-medium leading-tight"
          >
            {title}
          </h3>
          {/* <p>{summary}</p> */}
        </div>
      </div>
      <div className="col-span-6 text-right flex flex-col justify-end h-full">
        {team?.logoUrl && (
          <img
            src={team?.logoUrl}
            alt="logo"
            className="h-[100px] w-[100px] rounded-full mb-1 ml-auto"
          />
        )}
        <h3
          style={
            selectedColor
              ? {
                  color: selectedColor,
                  fontFamily: fontFamily || '',
                }
              : {}
          }
          className="mb-1 text-sm leading-tight font-medium text-[#667085]"
        >
          {team?.name}
        </h3>
        {team?.addressId && (
          <div
            className="text-sm text-[#667085CC] leading-tight space-y-1 mt-1 mb-1"
            style={fontFamily ? { fontFamily: fontFamily || '' } : {}}
          >
            <p>
              {team?.businessAddress?.street}{' '}
              {team?.businessAddress?.street2
                ? `, ${team?.businessAddress?.street2}`
                : ''}
            </p>
            <p>
              {team?.businessAddress?.city}
              {team?.businessAddress?.city && ','} {team?.state}{' '}
              {team?.businessAddress?.zipCode}
            </p>
            <p>{getCountryName() || ''}</p>
          </div>
        )}
        {team?.businessTaxNumber && (
          <p
            style={fontFamily ? { fontFamily: fontFamily || '' } : {}}
            className="my-1 mt-4 text-sm text-[#667085CC] leading-tight"
          >
            Tax Number: {team?.businessTaxNumber}
          </p>
        )}
        <p
          style={fontFamily ? { fontFamily: fontFamily || '' } : {}}
          className={`${team?.businessTaxNumber ? 'my-1' : 'mt-4 my-1'} text-sm text-[#667085CC] leading-tight`}
        >
          {team?.email}
        </p>
        <p
          style={fontFamily ? { fontFamily: fontFamily || '' } : {}}
          className="my-1 text-sm text-[#667085CC] leading-tight"
        >
          {team?.phone}
        </p>
        {/* <p
                  className={`text-sm text-[#667085CC] leading-tight ${
                    fontFamily ? fontFamily : ""
                  }`}
                >
                  Email Address
                </p> */}
      </div>
    </div>
    {/* Section 2  */}
    <div className="p-6 grid gap-4 grid-cols-12 justify-between">
      <div className="col-span-6 ">
        <CustomersBox
          isPreview
          selectedColor={selectedColor}
          setCustomerModalOpen={setCustomerModalOpen}
          fontFamily={fontFamily}
          customers={customers}
          selectedCustomer={selectedCustomer}
          setSelectedCustomer={setSelectedCustomer}
          setInvoiceSalesRep={setInvoiceSalesRep}
        />
      </div>
      <div className="col-span-2" />
      <div className="col-span-4 space-y-2.5">
        <div className="flex  gap-2.5 md:justify-end justify-between">
          <label
            style={{
              color: selectedColor,
              marginTop: '0',
              fontFamily: fontFamily || '',
            }}
            className="mt-[14px] text-sm font-medium text-[#667085] text-nowrap"
          >
            {type} Number
          </label>

          <div
            className="text-[#667085CC] text-sm w-[256px] text-right flex-1 md:w-fit"
            style={fontFamily ? { fontFamily: fontFamily || '' } : {}}
          >
            {invoiceNumber?.toString()?.padStart(4, '0')}
          </div>
        </div>
        <div className="flex  gap-2.5 md:justify-end justify-between">
          <label
            style={{
              color: selectedColor,
              marginTop: '0',
              fontFamily: fontFamily || '',
            }}
            className="mt-[14px] text-sm font-medium text-[#667085] text-nowrap"
          >
            P.O./S.O. Number
          </label>

          <div
            className="text-[#667085CC] text-sm w-[256px] text-right flex-1 md:w-fit"
            style={fontFamily ? { fontFamily: fontFamily || '' } : {}}
          >
            {psoNumber}
          </div>
        </div>
        <div className="flex  gap-2.5  md:justify-end justify-between">
          <label
            style={{
              color: selectedColor,
              marginTop: '0',
              fontFamily: fontFamily || '',
            }}
            className="mt-[14px] text-sm font-medium text-[#667085] text-nowrap"
          >
            {type} Date
          </label>

          <div
            className="text-[#667085CC] text-sm w-[256px] text-right flex-1 md:w-fit"
            style={fontFamily ? { fontFamily: fontFamily || '' } : {}}
          >
            {invoiceDate
              ? DateTime.fromISO(invoiceDate, {
                  setZone: true,
                }).toFormat('MMM dd, yyyy')
              : // moment(invoiceDate).format("MMM DD, yyyy")
                ''}
          </div>
        </div>
        <div className="flex  gap-2.5 md:justify-end justify-between">
          <label
            style={{
              color: selectedColor,
              marginTop: '0',
              fontFamily: fontFamily || '',
            }}
            className={`mt-[14px] text-sm font-medium text-[#667085] text-nowrap `}
          >
            {type === 'Invoice' ? `${type} Due Date` : 'Estimate Valid Until'}
          </label>

          <div
            className="text-[#667085CC] text-sm w-[256px] text-right flex-1 md:w-fit"
            style={fontFamily ? { fontFamily: fontFamily || '' } : {}}
          >
            {invoiceDueDate
              ? DateTime.fromISO(invoiceDueDate, {
                  setZone: true,
                }).toFormat('MMM dd, yyyy')
              : //  moment(invoiceDueDate).format("MMM DD, yyyy")
                ''}
          </div>
        </div>
      </div>
    </div>
    {/* Section 3  */}
    <div className="">
      <CreateInvoicesTable
        isPreview
        selectedColor={selectedColor}
        fontFamily={fontFamily}
        products={products}
        setSelectedProducts={setSelectedProducts}
        selectedProducts={selectedProducts}
        refreshProducts={getProductsApi}
        discount={discount}
        setDiscount={setDiscount}
        discountDescription={discountDescription}
        setDiscountDescription={setDiscountDescription}
        categories={categories}
        expenseCategories={expenseCategories}
        isDiscountAdded={isDiscountAdded}
        setIsDiscountAdded={setIsDiscountAdded}
        type={type}
        currencies={currencies}
        checkProductRows={checkProductRows}
        projects={projects}
        selectedProject={selectedProject}
        setSelectedProject={setSelectedProject}
        team={team}
        taxes={taxes}
        setTaxes={setTaxes}
        selectedTax={selectedTax}
        setSelectedTax={setSelectedTax}
        addTax={addTax}
        getTaxById={getTaxById}
        summary={summary}
        setSummary={setSummary}
        setInvoicePanelOpen={setInvoicePanelOpen}
        attachments={
          editInvoice?.id
            ? editInvoice?.attachments
            : [...defaultAttachments, ...attachFiles]
        }
        setReceiptModalOpen={setReceiptModalOpen}
        setActiveReceipt={setActiveReceipt}
        invoiceSalesRep={invoiceSalesRep}
        setInvoiceSalesRep={setInvoiceSalesRep}
        salesRep={salesRep}
        tags={tags}
        selectedTags={selectedTags}
        setSelectedTags={setSelectedTags}
        discountError={discountError}
        setDiscountError={setDiscountError}
        isRecurring={isRecurring}
        setIsRecurring={setIsRecurring}
        recurrencePattern={recurrencePattern}
        setRecurrencePattern={setRecurrencePattern}
        occurrenceCount={occurrenceCount}
        setOccurrenceCount={setOccurrenceCount}
        recurrenceEndDate={recurrenceEndDate}
        setRecurrenceEndDate={setRecurrenceEndDate}
        RecurrencePatternOptions={RecurrencePatternOptions}
        inAdvanceCreationDays={inAdvanceCreationDays}
        setInAdvanceCreationDays={setInAdvanceCreationDays}
        emailCustomer={emailCustomer}
        setEmailCustomer={setEmailCustomer}
        endRecurringType={endRecurringType}
        setEndRecurringType={setEndRecurringType}
        invoiceDueDate={invoiceDueDate}
        isNewInvoice={type === 'Invoice' && !editInvoice?.id}
      />
    </div>
  </div>
);

export default LiveInvoiceDownload;
