import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import ModalBasic from '../../../components/ModalBasic';
import FileUploader from './FileUploader';
import { Loader } from '../../../components/Svg';
import { processBankStatement } from '../../../API/backend_helper';
import TextInputDash from '../../../components/TextInputDash';

const BankStatementModal = ({ isOpen, setIsOpen }) => {
  const { localUser } = useSelector((state) => state.User);

  const [file1, setFile1] = useState(null);
  const [error1, setError1] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (values, formik) => {
    const data = new FormData();
    data.append('bank-statement', file1);
    data.append('name', values.name);
    data.append('email', values.email);
    setLoading(true);
    try {
      const res = await processBankStatement(data);
      if (res?.message) {
        toast.success(res?.message);
        formik?.resetForm();
        setIsOpen(false);
      }
    } catch (err) {
      console.log('err', err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <ModalBasic
      title="Bank Statement Converter"
      modalOpen={isOpen}
      setModalOpen={setIsOpen}
      width="!w-[600px]"
    >
      <Formik
        enableReinitialize
        initialValues={{
          name: localUser?.firstName || '',
          email: localUser?.email || '',
        }}
        validationSchema={Yup.object({
          email: Yup.string()
            .matches(
              /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              'Please enter valid email',
            )
            .required('Please Enter Email'),
          name: Yup.string().required('Please Enter Name'),
        })}
        onSubmit={handleSubmit}
      >
        {(validation) => (
          <Form>
            <div className="space-y-4 py-4 px-8">
              <TextInputDash
                label="Email"
                type="email"
                placeholder="Email"
                id="email"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.email || ''}
                error={validation.touched.email ? validation.errors.email : ''}
                required
                paddingBottom="pb-4"
              />
              <TextInputDash
                label="Name"
                placeholder="Name"
                id="name"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.name || ''}
                error={validation.touched.name ? validation.errors.name : ''}
                required
                paddingBottom="pb-4"
              />
              <FileUploader
                file={file1}
                setFile={setFile1}
                error={error1}
                setError={setError1}
              />
            </div>
            <div className="border-t border-[#D0D5DD] py-4 px-8 flex w-full justify-end items-center gap-4">
              <button
                type="button"
                className="h-11 w-[90px] flex justify-center items-center rounded-[5px] bg-white text-slate-600 border border-slate-600"
                onClick={() => setIsOpen(false)}
              >
                Close
              </button>
              <button
                className="h-11 w-[90px] flex justify-center items-center rounded-[5px] bg-indigo-500 text-white border border-indigo-500
                disabled:!bg-indigo-300 disabled:border-indigo-300 disabled:cursor-not-allowed"
                type="submit"
                disabled={loading || !file1}
              >
                {loading ? (
                  <Loader color="#FFFFFF" width="w-4" height="h-4" />
                ) : (
                  'Convert'
                )}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </ModalBasic>
  );
};

export default BankStatementModal;
