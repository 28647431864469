import React, { forwardRef, useState, useEffect } from 'react';

const TextArea = forwardRef(
  (
    {
      id,
      label,
      error,
      required = false,
      onBlur = () => {},
      inputClass = '',
      labelClass = '',
      dropdownOpen = false,
      className = '',
      ...rest
    },
    ref,
  ) => {
    // const inputRef = useRef(null);
    const [focused, setFocused] = useState(false);

    useEffect(() => {
      if (document?.getElementById?.(id)?.value?.length > 0) {
        setFocused(true);
      }
    }, []);

    return (
      <div
        style={error ? { marginBottom: '1rem' } : {}}
        className={`textInput textArea ${className}`}
      >
        <textarea
          className={`textInput_input ${inputClass} ${
            error ? '!border-rose-400' : ''
          } `}
          ref={ref}
          id={id}
          placeholder={label}
          onFocus={() => {
            setFocused(true);
          }}
          {...rest}
          onBlur={(e) => {
            setFocused(false);
            onBlur?.(e);
          }}
          required={false}
        />
        {label && (
          <label
            htmlFor={`${id}`}
            id={`${id}-label`}
            className={
              document?.getElementById?.(id)?.value?.length > 0 ||
              focused ||
              dropdownOpen
                ? `textInput_label ${labelClass} textInput_label_focus ${
                    error ? '!text-rose-400' : ''
                  }`
                : `textInput_label ${labelClass} ${
                    error ? '!text-rose-400' : ''
                  }`
            }
            // onClick={() => inputRef.current.focus()}
          >
            {label}
            {required && <span className="text-rose-500">*</span>}
          </label>
        )}

        {error && <div className=" text-xs text-rose-400">{error}</div>}
      </div>
    );
  },
);

TextArea.displayName = 'TextArea';

export default TextArea;
