import React from 'react';
import AppLayout from '../../../components/AppLayout';

const SquareSettings = () => (
  <AppLayout>
    <main className="relative grow ">
      {/* Content */}
      <div className=" px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto h-full flex flex-col">
        {/* Page header */}
        <div className="flex flex-col sm:flex-row gap-2 sm:gap-0 justify-between mb-10">
          <h1 className="text-black text-opacity-60 text-4xl font-medium leading-[2.375rem]">
            Square Settings
          </h1>
        </div>
      </div>
    </main>
  </AppLayout>
);

export default SquareSettings;
