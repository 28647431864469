import React, { useEffect, useRef, useState } from "react";
import ModalBasic from "../../components/ModalBasic";

const ResetModal = ({ isOpen, setIsOpen, handleReset }) => {
  const [loading, setLoading] = useState(false);
  return (
    <ModalBasic
      id="basic-modal"
      modalOpen={isOpen}
      setModalOpen={setIsOpen}
      title={`Reset Automation`}
      dialogClassName="!overflow-visible"
    >
      <p className="text-slate-600 px-5 py-4 border-b border-[#D0D5DD]">
        This will reset your automation and cannot be reversed. Are you sure you
        want to proceed?
      </p>
      {/* Modal footer */}
      <div className="flex flex-wrap  justify-end space-x-2 px-5 py-4">
        <button
          type="submit"
          className="py-2.5 px-[1.875rem] bg-white text-indigo-500 border border-indigo-500 text-base leading-6 font-base rounded-[0.313rem]"
          onClick={() => setIsOpen(false)}
        >
          {loading && (
            <svg
              className="animate-spin w-4 h-4 fill-current shrink-0 mr-2"
              viewBox="0 0 16 16"
            >
              <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z" />
            </svg>
          )}
          Cancel
        </button>
        <button
          type="submit"
          className="py-2.5 px-[1.875rem] bg-indigo-500 text-white text-base leading-6 font-base rounded-[0.313rem]
            disabled:bg-indigo-300    disabled:cursor-not-allowed"
          disabled={loading}
          onClick={() => {
            handleReset();
            setIsOpen(false);
          }}
        >
          {loading && (
            <svg
              className="animate-spin w-4 h-4 fill-current shrink-0 mr-2"
              viewBox="0 0 16 16"
            >
              <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z" />
            </svg>
          )}
          Reset
        </button>
      </div>
    </ModalBasic>
  );
};

export default ResetModal;
