export const PnLTagsData = {
  ProfitAndLossByClass: {
    tags: [
      { id: 115, name: 'King County Unincorp.' },
      { id: 115, name: 'Bellevue' },
      { id: 115, name: 'Bothell in King County' },
      { id: 115, name: 'Issaquah' },
      { id: 115, name: 'Kirkland' },
      { id: 115, name: 'Lake Forest Park' },
      { id: 115, name: 'Mercer Island' },
      { id: 115, name: 'Redmond' },
      { id: 115, name: 'Renton' },
      { id: 115, name: 'Seattle' },
      { id: 115, name: 'Woodinville' },
      { id: 115, name: 'Shoreline' },
      { id: 115, name: 'Kenmore' },
      { id: 115, name: 'Kitsap/Bremertom' },
      { id: 115, name: 'Snohomish County Unincorp.' },
      { id: 115, name: 'Arlington' },
      { id: 115, name: 'Brier' },
      { id: 115, name: 'Edmonds' },
      { id: 115, name: 'Everett' },
      { id: 115, name: 'Lake Stevens' },
      { id: 115, name: 'Lynnwood' },
      { id: 115, name: 'Marysville' },
      { id: 115, name: 'Monroe' },
      { id: 115, name: 'Mountlake Terrace' },
      { id: 115, name: 'Mukilteo' },
      { id: 115, name: 'Snohomish (City)' },
      { id: 115, name: 'Sultan' },
      { id: 115, name: 'Mill Creek' },
      { id: 115, name: 'Bothell in Snohomish County' },
      { id: 115, name: 'Snohomish County Unincorp. PTBA' },
      { id: 115, name: 'King County Unincorp. Non-RTA' },
      { id: 115, name: 'Snohomish County Unincorp. Areas Non-RTA' },
      { id: 115, name: 'Snohomish County Unincorp. PTBA Non-RTA' },
      { id: 115, name: 'Not Specified (not tagged)' },
      { id: 115, name: 'TOTAL' },
    ],
    data: {
      Income: {
        'Discounts Given': {
          Seattle: -50,
          TOTAL: -50,
        },
        'Sales - Labor': {
          Kirkland: 25,
          'Mercer Island': 1500,
          Seattle: 100,
          TOTAL: 1625,
        },
        'Sales - Retail': {
          'Kitsap/Bremertom': 9800,
          'Bothell in Snohomish County': 1944,
          'Snohomish County Unincorp. PTBA Non-RTA': 857,
          TOTAL: 12601,
        },
        'Sales - Scrap Metal': {
          'Lake Stevens': 27.5,
          'Snohomish County Unincorp. Areas Non-RTA': 15.4,
          'Not Specified (not tagged)': 1288,
          TOTAL: 1330.9,
        },
        'Sales - Service': {
          'King County Unincorp.': 281,
          Bellevue: 2500,
          'Bothell in King County': 1380.5,
          Issaquah: 2366,
          Kirkland: 13835.73,
          'Lake Forest Park': 650,
          'Mercer Island': 2535.48,
          Redmond: 590,
          Renton: 573,
          Seattle: 3280.3,
          Woodinville: 270,
          Shoreline: 7477.56,
          Kenmore: 509,
          'Kitsap/Bremertom': 834.5,
          'Snohomish County Unincorp.': 480,
          Arlington: 253.42,
          Brier: 176,
          Edmonds: 5375.14,
          Everett: 5838.3,
          'Lake Stevens': 2629.98,
          Lynnwood: 438,
          Marysville: 2756.33,
          Monroe: 4357.43,
          'Mountlake Terrace': 1084,
          Mukilteo: 209,
          'Snohomish (City)': 6174,
          Sultan: 1766.98,
          'Mill Creek': 1641,
          'Bothell in Snohomish County': 1563,
          'Snohomish County Unincorp. PTBA': 11126,
          'King County Unincorp. Non-RTA': 4073,
          'Snohomish County Unincorp. Areas Non-RTA': 7422,
          'Snohomish County Unincorp. PTBA Non-RTA': 5000,
          'Not Specified (not tagged)': 158,
          TOTAL: 99604.65,
        },
        'Sales - Wholesale': {
          Seattle: 2200,
          TOTAL: 2200,
        },
        'Tips Received': {
          'King County Unincorp.': 28.1,
          'Bothell in King County': 40.2,
          Issaquah: 13.8,
          Kirkland: 223.5,
          Redmond: 100.4,
          Seattle: 373.6,
          Woodinville: 54,
          Shoreline: 35.2,
          Kenmore: 30.6,
          'Snohomish County Unincorp.': 22.7,
          Arlington: 50.68,
          Edmonds: 556.93,
          Everett: 253.65,
          'Lake Stevens': 88.7,
          Lynnwood: 2,
          Marysville: 218.33,
          Monroe: 145.1,
          'Mountlake Terrace': 16,
          'Snohomish (City)': 143.3,
          Sultan: 119.65,
          'Mill Creek': 176,
          'Bothell in Snohomish County': 156.9,
          'Snohomish County Unincorp. PTBA': 1695.63,
          'King County Unincorp. Non-RTA': 352.5,
          'Snohomish County Unincorp. Areas Non-RTA': 1149.75,
          'Snohomish County Unincorp. PTBA Non-RTA': 855.49,
          'Not Specified (not tagged)': 225,
          TOTAL: 7127.71,
        },
        'Uncategorized Income': {
          'Not Specified (not tagged)': 115,
          TOTAL: 115,
        },
        'Total Income': {
          'King County Unincorp.': 309.1,
          Bellevue: 2500,
          'Bothell in King County': 1420.7,
          Issaquah: 2379.8,
          Kirkland: 14084.23,
          'Lake Forest Park': 650,
          'Mercer Island': 4035.48,
          Redmond: 690.4,
          Renton: 573,
          Seattle: 5903.9,
          Woodinville: 324,
          Shoreline: 7512.76,
          Kenmore: 539.6,
          'Kitsap/Bremertom': 10634.5,
          'Snohomish County Unincorp.': 502.7,
          Arlington: 304.1,
          Brier: 176,
          Edmonds: 5932.07,
          Everett: 6091.95,
          'Lake Stevens': 2746.18,
          Lynnwood: 440,
          Marysville: 2974.66,
          Monroe: 4502.53,
          'Mountlake Terrace': 1100,
          Mukilteo: 209,
          'Snohomish (City)': 6317.3,
          Sultan: 1886.63,
          'Mill Creek': 1817,
          'Bothell in Snohomish County': 3663.9,
          'Snohomish County Unincorp. PTBA': 12821.63,
          'King County Unincorp. Non-RTA': 4425.5,
          'Snohomish County Unincorp. Areas Non-RTA': 8587.15,
          'Snohomish County Unincorp. PTBA Non-RTA': 6712.49,
          'Not Specified (not tagged)': 1786,
          TOTAL: 124554.26,
        },
      },
    },
    summary: {
      Service: 101344.65,
      Retail: 12601,
      Wholesale: 3530.9,
    },
  },
};
