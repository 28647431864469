import React from 'react';
import ReactDOM from 'react-dom/client';

import { BrowserRouter as Router } from 'react-router-dom';
// import "bootstrap/dist/css/bootstrap.min.css";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  persistStore,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import { configureStore } from '@reduxjs/toolkit';
import { Provider } from 'react-redux';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { ProdEnv } from './utils/Utils';
import rootReducer from './redux';
import ThemeProvider from './utils/ThemeContext';
import reportWebVitals from './reportWebVitals';
import App from './App';
// import './vite-preload-error';

export const store = configureStore({
  reducer: rootReducer,
  devTools: process.env.REACT_APP_ENV !== ProdEnv,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

const persistor = persistStore(store);

if (process.env.REACT_APP_ENV === ProdEnv) {
  console.error = () => {};
  console.warn = () => {};
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  <Router>
    <ThemeProvider>
      {/* <Auth0Provider
          // domain="wallet-monk.us.auth0.com"
          // clientId="r8HYdP0zbZ4fnTfFobZZpbPwzOHDXM7e"
          //=========^^ ReactApp=======//
          domain="wallet-monk.us.auth0.com"
          clientId="jjeMGqKun6aBSOWwFAAOW6x9cFyfxYNe"
          authorizationParams={{
            redirect_uri: window.location.origin,
            audience: "https://primary-auth0.walletmonk.com",
            scope: "openid profile email",
          }}
          useRefreshTokens={true}
          cacheLocation="localstorage"
        > */}
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <App />
        </PersistGate>
      </Provider>
      {/* </Auth0Provider> */}
      <ToastContainer
        icon={({ type }) => {
          if (type === 'success')
            return (
              <svg
                width="19"
                height="20"
                viewBox="0 0 19 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.58333 9.99935L8.08333 12.4993L13.0833 7.49935M17.6667 9.99935C17.6667 14.6017 13.9357 18.3327 9.33333 18.3327C4.73096 18.3327 1 14.6017 1 9.99935C1 5.39698 4.73096 1.66602 9.33333 1.66602C13.9357 1.66602 17.6667 5.39698 17.6667 9.99935Z"
                  stroke="white"
                  strokeWidth="1.66667"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            );
          if (type === 'error')
            return (
              <svg
                width="20"
                height="17"
                viewBox="0 0 20 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.99817 6.50019V9.83353M9.99817 13.1669H10.0065M8.84427 2.24329L1.99019 14.0821C1.61002 14.7388 1.41994 15.0671 1.44803 15.3366C1.47254 15.5716 1.59568 15.7852 1.78681 15.9242C2.00594 16.0835 2.38533 16.0835 3.1441 16.0835H16.8522C17.611 16.0835 17.9904 16.0835 18.2095 15.9242C18.4007 15.7852 18.5238 15.5716 18.5483 15.3366C18.5764 15.0671 18.3863 14.7388 18.0061 14.0821L11.1521 2.24329C10.7733 1.58899 10.5839 1.26184 10.3368 1.15196C10.1212 1.05612 9.87513 1.05612 9.65959 1.15196C9.41248 1.26184 9.22307 1.58899 8.84427 2.24329Z"
                  stroke="white"
                  strokeWidth="1.66667"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            );
          return 'ℹ️';
        }}
      />
    </ThemeProvider>
  </Router>,
  // </React.StrictMode>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
