import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  subscription: '',
};

const SubscriptionSlice = createSlice({
  name: 'Subscription',
  initialState,
  reducers: {
    setSubscription(state, action) {
      state.subscription = action.payload;
    },
  },
});

export const { setSubscription } = SubscriptionSlice.actions;

export default SubscriptionSlice.reducer;
