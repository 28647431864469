import React, { useState, useRef, useEffect } from 'react';
import Transition from '../../utils/Transition';
import useClickOutside from '../../utils/useClickOutside';
import { Loader } from '../../components/Svg';
import Tooltip from '../../components/Tooltip';

function AssigneeDropdown({
  options,
  selected,
  setSelected,
  name,
  accounts = false,
  categories = false,
  error = false,
  countries = false,
  disabled = false,
  className = '',
  categoryModal = false,
  noSearch = false,
  scrollIntoView = true,
  subType = false,
  pagination = false,
  placeholder,
  accountStyle = false,
  taxes = false,
  addOption = null,
  fontFamily = false,
  height = null,
  bulkDropdown = false,
  search = false,
  flyout = false,
  currency = false,
  users = false,
  inbox = false,
  bottomOfTable = false,
  isTableDropdown = false,
  ids = [],
  addNewOptionButton = false,
  addNewOptionLabel = '',
  handleNewOptionButton = () => {},
  buttonTextClass = '',
  buttonTextClassSub = '',
  selectFullObj = false,
  manualOption = null,
  noMinW = false,
  transitionClass = '',
  handleSearch = null,
  resetSearch = null,
  searchPlaceholder = '',
  searchLoading = false,
}) {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const trigger = useRef(null);
  const dropdown = useRef(null);
  const searchInput = useRef(null);
  const [fixedDropdownPositions, setFixedDropdownPositions] = useState({});

  useEffect(() => {
    if (dropdownOpen) {
      searchInput?.current?.focus();
    }
  }, [dropdownOpen]);

  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!dropdown.current) return;
      if (
        !dropdownOpen ||
        dropdown.current.contains(target) ||
        trigger.current.contains(target)
      )
        return;
      setDropdownOpen(false);
      setSearchValue('');
    };
    document.addEventListener('click', clickHandler);
    return () => document.removeEventListener('click', clickHandler);
  });

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!dropdownOpen || keyCode !== 27) return;
      setDropdownOpen(false);
      setSearchValue('');
    };
    document.addEventListener('keydown', keyHandler);
    return () => document.removeEventListener('keydown', keyHandler);
  });

  useClickOutside(dropdown, (event) => {
    if (!trigger.current.contains(event.target))
      if (dropdownOpen) {
        setDropdownOpen(false);
        setSearchValue('');
      }
  });

  useEffect(() => {
    if (scrollIntoView) {
      if (dropdownOpen && scrollIntoView) {
        dropdown.current?.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [dropdownOpen]);

  const OptionsToMap = ({ options }) =>
    options?.map((option) =>
      option[name] === 'dotted-line-item' && option.id === 'dotted-line' ? (
        <div key={option.id} className="px-1 my-1">
          <svg
            className="w-full"
            width="400"
            height="2"
            viewBox="0 0 400 2"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.16309 1.5L399.163 0.5"
              stroke="#000000"
              strokeOpacity="0.8"
              strokeLinecap="round"
              strokeDasharray="6 6"
            />
          </svg>
        </div>
      ) : (
        <button
          type="button"
          key={accounts ? option[name] : option.id}
          tabIndex="0"
          className={`text-left leading-normal flex border-none items-center gap-2 text-[13px] hover:text-slate-800 w-full dark:hover:bg-slate-700/20 py-1 px-3 cursor-pointer ${
            (accounts ? option[name] : option.id) === selected &&
            'text-indigo-500 hover:!text-indigo-600'
          }`}
          onClick={(e) => {
            e.stopPropagation();
            setSelected(
              selectFullObj ? option : accounts ? option[name] : option.id,
            );
            if (resetSearch) {
              resetSearch(option);
              setSearchValue('');
            }
            setDropdownOpen(false);
          }}
          style={fontFamily ? { fontFamily: option[name] } : {}}
        >
          <span
            className={`text-slate-600 hover:text-slate-800 ${
              users ? 'flex gap-2 items-center text-[14px]' : ''
            } ${
              (accounts ? option[name] : option.id) === selected &&
              '!text-indigo-500 !hover:text-indigo-600'
            }
          ${currency ? 'whitespace-nowrap' : ''}`}
          >
            {countries && (
              <span className="text-lg mr-2 leading-none"> {option.flag}</span>
            )}
            {currency && <span className="mr-1">{option.symbol}</span>}
            {users &&
              (option.avatarUrl ? (
                <img
                  src={option.avatarUrl}
                  alt="avatar"
                  className="w-8 h-8 rounded-[42px] object-contain"
                />
              ) : (
                <div className="min-h-8 min-w-8 rounded-[42px] bg-slate-400 text-white text-md flex items-center justify-center">
                  {option.firstName.charAt(0).toUpperCase() +
                    option.lastName.charAt(0).toUpperCase()}
                </div>
              ))}
            {!users ? option[name] : `${option.firstName} ${option.lastName}`}
            {taxes && <span className="ml-1">({option.percentage}%)</span>}
          </span>
          <svg
            className={`shrink-0 mr-2 fill-current text-indigo-500 ${
              (accounts ? option[name] : option.id) !== selected && 'invisible'
            }`}
            width="12"
            height="9"
            viewBox="0 0 12 9"
          >
            <path d="M10.28.28L3.989 6.575 1.695 4.28A1 1 0 00.28 5.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28.28z" />
          </svg>
        </button>
      ),
    );

  const getFixedPositionValues = () => {
    const { x, y, height } = trigger.current?.getBoundingClientRect();
    const dropdownDimensions = dropdown.current?.getBoundingClientRect();
    const windowHeight = window.innerHeight;
    const windowWidth = window.innerWidth;
    const values = {
      left:
        x + dropdownDimensions?.width > windowWidth
          ? windowWidth - dropdownDimensions?.width - 20
          : x,
      top:
        y + height + dropdownDimensions?.height > windowHeight
          ? windowHeight - dropdownDimensions?.height - 20
          : y + height,
    };
    setFixedDropdownPositions(values);
  };

  useEffect(() => {
    if (bottomOfTable) {
      if (dropdownOpen) {
        ids?.forEach((id) => {
          const ele = document.getElementById(id);
          if (ele) {
            // ele.style.overflow = 'hidden';
            ele.style.pointerEvents = 'none';
          }
        });
      } else {
        ids?.forEach((id) => {
          const ele = document.getElementById(id);
          if (ele) {
            // ele.style.overflow = 'auto';
            ele.style.pointerEvents = 'auto';
          }
        });
      }
      getFixedPositionValues();
      return () => {
        ids?.forEach((id) => {
          const ele = document.getElementById(id);
          if (ele) {
            // ele.style.overflow = 'auto';
            ele.style.pointerEvents = 'auto';
          }
        });
      };
    }
  }, [dropdownOpen]);

  useEffect(() => {
    if (isTableDropdown) {
      const mouseMoveHandler = ({ clientX, clientY }) => {
        if (!dropdown.current) {
          return;
        }
        const { left, top, width, height } =
          dropdown?.current?.getBoundingClientRect();
        const offsetX = Math.max(clientX - (left + width), left - clientX);
        const offsetY = Math.max(clientY - (top + height), top - clientY);
        const distance = Math.sqrt(offsetX ** 2 + offsetY ** 2);
        if (dropdownOpen && distance > 300) {
          setDropdownOpen(false);
        }
      };
      document.addEventListener('mousemove', mouseMoveHandler);
      return () => document.removeEventListener('mousemove', mouseMoveHandler);
    }
  }, [dropdownOpen]);

  return (
    <>
      <div className={`relative inline-flex h-full w-full ${className}`}>
        <button
          type="button"
          ref={trigger}
          className={
            !categoryModal
              ? `${
                  height || 'h-auto'
                } px-3 text-base tracking-normal max-h-full btn font-normal w-full justify-between  ${
                  pagination || noMinW ? '' : 'min-w-44'
                } ${
                  bulkDropdown ? 'py-[11px]' : ''
                } bg-white dark:bg-slate-800 border-[#D0D5DD] dark:border-slate-700 hover:border-slate-400
          dark:hover:border-slate-600 text-slate-600 hover:text-slate-600 dark:text-slate-300
          dark:hover:text-slate-200
          ${error ? '!border-rose-500 !text-rose-500' : ''}
          ${
            bulkDropdown && disabled
              ? '!border-[#66708533] cursor-not-allowed'
              : ''
          }
          ${bulkDropdown ? 'text-[14px]' : ''}
          ${flyout ? 'rounded-[6px]' : ''}
          ${
            accountStyle
              ? 'btn text-base tracking-normal h-12 rounded-[0.313rem] border border-[#D0D5DD] hover:border-slate-400 text-slate-600 leading-6 font-normal px-4 py-3.5 bg-white'
              : ''
          }`
              : 'btn form-input h-12 px-3 py-[11px] rounded-[0.313rem] font-normal leading-6 text-slate-600 border-[#D0D5DD] hover:border-slate-400 flex justify-between w-full bg-white'
          }
          aria-label="Select date range"
          aria-haspopup="true"
          onClick={(e) => {
            e.stopPropagation();
            setDropdownOpen(!dropdownOpen);
          }}
          aria-expanded={dropdownOpen}
          disabled={disabled}
        >
          <Tooltip
            content={(() => {
              const selectedOption = options?.find(
                (opt) => (accounts ? opt[name] : opt.id) === selected,
              );
              const fullName = selectedOption
                ? `${selectedOption.firstName} ${selectedOption.lastName}`
                : '';
              return (
                fullName ||
                (subType && disabled && 'Please select a type') ||
                (placeholder && !disabled
                  ? placeholder
                  : manualOption
                    ? null
                    : 'Select...')
              );
            })()}
            tooltipShow={!dropdownOpen && isTableDropdown}
            className={isTableDropdown ? 'text-center w-fit' : 'w-full'}
            contentClassName="border-none overflow-visible text-sm text-[#667085] relative tooltipArrowDown"
          >
            <span
              className={`flex items-center  ${buttonTextClass || ''}`}
              style={{ maxWidth: 'calc(100% - 12px)' }}
            >
              {manualOption && manualOption}
              {users &&
                !manualOption &&
                options?.find(
                  (opt) => (accounts ? opt[name] : opt.id) === selected,
                ) &&
                (options?.find(
                  (opt) => (accounts ? opt[name] : opt.id) === selected,
                )?.avatarUrl ? (
                  <img
                    src={
                      options?.find(
                        (opt) => (accounts ? opt[name] : opt.id) === selected,
                      )?.avatarUrl
                    }
                    alt="avatar"
                    className="w-8 h-8 rounded-[42px] mr-2 object-contain"
                  />
                ) : (
                  <div className="min-h-8 min-w-8 rounded-[42px] bg-slate-400 text-white text-md flex items-center justify-center mr-2">
                    {options
                      ?.find(
                        (opt) => (accounts ? opt[name] : opt.id) === selected,
                      )
                      ?.firstName.charAt(0)
                      .toUpperCase() +
                      options
                        ?.find(
                          (opt) => (accounts ? opt[name] : opt.id) === selected,
                        )
                        ?.lastName.charAt(0)
                        .toUpperCase()}
                  </div>
                ))}
              {!isTableDropdown && (
                <span
                  className={`${
                    options?.find(
                      (opt) => (accounts ? opt[name] : opt.id) === selected,
                    )
                      ? 'text-slate-600 whitespace-nowrap overflow-hidden text-ellipsis max-w-full inline-block'
                      : 'text-slate-600 text-opacity-80'
                  } ${selected || ''}
              ${
                bulkDropdown
                  ? disabled
                    ? '!text-[#66708533] !text-[14px]'
                    : '!text-[14px]'
                  : ''
              }${buttonTextClassSub || ''}
              `}
                >
                  {countries && (
                    <span className="text-lg mr-2 leading-none">
                      {' '}
                      {options?.find((opt) => opt.id === selected)?.flag}
                    </span>
                  )}
                  {users
                    ? (() => {
                        const selectedOption = options?.find(
                          (opt) => (accounts ? opt[name] : opt.id) === selected,
                        );
                        const fullName = selectedOption
                          ? `${selectedOption.firstName} ${selectedOption.lastName}`
                          : '';
                        return (
                          fullName ||
                          (subType && disabled && 'Please select a type') ||
                          (placeholder && !disabled
                            ? placeholder
                            : manualOption
                              ? null
                              : 'Select...')
                        );
                      })()
                    : options?.find(
                        (opt) => (accounts ? opt[name] : opt.id) === selected,
                      )?.[name] ||
                      (subType && disabled && 'Please select a type') ||
                      (placeholder && !(subType && disabled) && !manualOption
                        ? placeholder
                        : manualOption
                          ? null
                          : 'Select...')}
                </span>
              )}
            </span>
          </Tooltip>
          {!isTableDropdown && !(disabled && inbox) && (
            <svg
              className={`shrink-0 ml-1 text-[#667085cc] ${
                error ? 'fill-rose-500' : ''
              } ${dropdownOpen ? 'rotate-180' : ''}`}
              width="10"
              height="6"
              viewBox="0 0 10 6"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9 1.83398L5.70707 4.58988C5.31818 4.91535 4.68182 4.91535 4.29293 4.58988L1 1.83398"
                stroke="#667085"
                strokeOpacity={`${bulkDropdown && disabled ? '0.4' : '0.8'}`}
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          )}
        </button>
        <Transition
          show={dropdownOpen}
          tag="div"
          className={`${
            bottomOfTable
              ? 'fixed !w-fit min-w-44 md:-translate-x-1/3'
              : 'origin-top-right z-10 absolute'
          } ${
            pagination && !bulkDropdown ? 'bottom-full' : 'top-full'
          } left-0 w-full bg-white dark:bg-slate-800 border border-slate-200 dark:border-slate-700 py-1.5 rounded shadow-lg overflow-hidden mt-1 ${transitionClass || ''}`}
          enter="transition ease-out duration-100 transform"
          enterStart="opacity-0 -translate-y-2"
          enterEnd="opacity-100 translate-y-0"
          leave="transition ease-out duration-100"
          leaveStart="opacity-100"
          leaveEnd="opacity-0"
          style={
            bottomOfTable
              ? {
                  top: fixedDropdownPositions?.top,
                  left: fixedDropdownPositions?.left,
                  zIndex: '1000',
                }
              : {}
          }
        >
          {dropdownOpen && (
            <div
              ref={dropdown}
              className={`font-medium pointer-events-auto ${
                categoryModal
                  ? 'max-h-60'
                  : fontFamily
                    ? 'max-h-fit'
                    : addNewOptionButton
                      ? 'max-h-80'
                      : 'max-h-48'
              } scrollbar text-sm text-slate-600 ${accountStyle ? '' : ''}`}
              // onFocus={() => setDropdownOpen(true)}
              // onBlur={() => setDropdownOpen(false)}
            >
              {((categoryModal && !noSearch) || search) && (
                <div className="relative flex-1 px-3 mb-1">
                  <input
                    type="text"
                    placeholder={searchPlaceholder || 'Search...'}
                    className={`form-input text-indigo-600  placeholder:!text-indigo-600 !border-indigo-600 my-2 px-8 w-full inline-block font-normal `}
                    value={searchValue}
                    onChange={(e) => {
                      setSearchValue(e.target.value);
                      if (handleSearch) {
                        handleSearch(e.target.value);
                      }
                    }}
                    autoFocus
                    ref={searchInput}
                    onClick={(e) => e.stopPropagation()}
                  />
                  <svg
                    className="absolute left-3 top-[50%] -translate-y-[50%] pl-2 w-6 h-6"
                    width="16"
                    height="17"
                    viewBox="0 0 16 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M14 14.5L11.6667 12.1667M13.3333 8.16667C13.3333 11.2963 10.7963 13.8333 7.66667 13.8333C4.53705 13.8333 2 11.2963 2 8.16667C2 5.03705 4.53705 2.5 7.66667 2.5C10.7963 2.5 13.3333 5.03705 13.3333 8.16667Z"
                      stroke="#E48642"
                      strokeWidth="1.2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  {searchLoading && (
                    <div className="absolute right-6 top-[50%] -translate-y-[50%]">
                      <Loader height="h-5 " width="w-5" fill="" />
                    </div>
                  )}
                </div>
              )}
              <div className="overflow-auto max-h-48 scrollbar">
                {categoryModal ? (
                  <div className="scrollbar pt-1 max-h-60 overflow-auto">
                    <OptionsToMap
                      options={options.filter((opt) =>
                        opt[name]
                          .toLowerCase()
                          .includes(searchValue.toLowerCase()),
                      )}
                    />
                  </div>
                ) : categories ? (
                  // Special for categories
                  options?.map(
                    (group) =>
                      group?.categories?.length > 0 && (
                        <div
                          key={group.id}
                          className="scrollbar overflow-auto pt-2 max-h-60"
                        >
                          <span className=" cursor-default font-bold whitespace-nowrap flex items-center pr-4 text-sm text-slate-600 dark:text-slate-300 py-1 px-4">
                            {group?.name}
                          </span>

                          <OptionsToMap
                            options={group?.categories.map((cat) => ({
                              id: cat.id,
                              name: `${cat.name}`,
                            }))}
                          />
                        </div>
                      ),
                  )
                ) : (
                  <>
                    <OptionsToMap options={options} />
                    {taxes && (
                      <button
                        type="button"
                        tabIndex="0"
                        className="flex border-none items-center gap-2 text-[13px] hover:text-slate-800 w-full dark:hover:bg-slate-700/20 py-1 px-3 cursor-pointer"
                        onClick={() => {
                          addOption();
                        }}
                      >
                        <span className="text-slate-600 font-semibold hover:text-slate-800">
                          Add A New Tax
                        </span>
                      </button>
                    )}
                  </>
                )}
              </div>
              {addNewOptionButton && (
                <button
                  type="button"
                  className="btn h-10 border-b-0 border-x-0 !border-t border-[#D0D5DD]  gap-2 text-indigo-500 text-base w-full whitespace-nowrap cursor-pointer"
                  onClick={handleNewOptionButton}
                >
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6 flex-shrink-0"
                  >
                    <path
                      d="M8.00004 14.6673C11.6667 14.6673 14.6667 11.6673 14.6667 8.00065C14.6667 4.33398 11.6667 1.33398 8.00004 1.33398C4.33337 1.33398 1.33337 4.33398 1.33337 8.00065C1.33337 11.6673 4.33337 14.6673 8.00004 14.6673Z"
                      stroke="#E48642"
                      strokeWidth="1.2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M5.33337 8H10.6667"
                      stroke="#E48642"
                      strokeWidth="1.2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M8 10.6663V5.33301"
                      stroke="#E48642"
                      strokeWidth="1.2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  {addNewOptionLabel}
                </button>
              )}
            </div>
          )}
        </Transition>
      </div>
      {error && <div className=" text-xs mt-1 text-rose-500">{error}</div>}
    </>
  );
}

export default AssigneeDropdown;
