import React from 'react';
import ModalBasic from '../../components/ModalBasic';

const ErrorsModal = ({ isOpen, setIsOpen }) => (
  <ModalBasic
    title="Errors"
    modalOpen={isOpen}
    setModalOpen={setIsOpen}
    smallModal
  >
    <div className="w-full px-3 py-4 bg-white">
      {[1, 2, 3, 4].map((e) => (
        <div
          key={e}
          className="flex justify-between items-center w-full py-3.5 px-3 border-b border-[#D0D5DD]"
        >
          <div className="flex gap-2 items-center">
            {/* Warning Icon  */}
            <svg
              width="20"
              height="19"
              viewBox="0 0 20 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.99935 6.40332V9.73665M9.99935 13.07H10.0077M18.3327 9.73665C18.3327 14.339 14.6017 18.07 9.99935 18.07C5.39698 18.07 1.66602 14.339 1.66602 9.73665C1.66602 5.13428 5.39698 1.40332 9.99935 1.40332C14.6017 1.40332 18.3327 5.13428 18.3327 9.73665Z"
                stroke="#FF4B4B"
                strokeWidth="1.66667"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <p className="text-sm font-medium text-[#101828]">
              24 Transfer Matches Found
            </p>
          </div>
          <button
            type="button"
            className="text-[#FF4B4B] text-base hover:underline"
          >
            View
          </button>
        </div>
      ))}
    </div>
  </ModalBasic>
);

export default ErrorsModal;
