import React, { useState } from "react";
import ModalBasic from "../../components/ModalBasic";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { updateTransaction } from "../../API/backend_helper";
import { toast } from "react-toastify";

const EditTransaction = ({
  id,
  modalOpen,
  setModalOpen,
  selectedRow,
  reloadTransaction,
  setSelectedRow,
}) => {
  const [loading, setLoading] = useState(false);

  const onSubmit = async (name, amount) => {
    setLoading(true);
    try {
      const response = await updateTransaction(selectedRow?.id, {
        amount,
        name,
      });
      await reloadTransaction({});
      setLoading(false);
      setSelectedRow(response);
      setModalOpen(false);
    } catch (e) {
      toast.error(
        "An error occurred while saving. Please refresh and try again."
      );
      setLoading(false);
    }
  };

  return (
    <ModalBasic
      id={id}
      modalOpen={modalOpen}
      setModalOpen={setModalOpen}
      title="Edit Transaction"
    >
      {/* Modal content */}
      {modalOpen && (
        <div className="px-5 py-4">
          <Formik
            enableReinitialize={true}
            initialValues={{
              name: selectedRow?.name || "",
              amount:
                selectedRow?.amount < 0
                  ? selectedRow?.amount * -1
                  : selectedRow?.amount || "",
            }}
            validationSchema={Yup.object({
              name: Yup.string().required("Please enter transaction name"),
              amount: Yup.number().required("Please Enter Amount"),
            })}
            onSubmit={async (values) => {
              const { name, amount } = values;

              onSubmit(name, selectedRow?.amount < 0 ? -1 * +amount : +amount);
            }}
          >
            {(validation) => (
              <Form>
                <div className="space-y-4">
                  <div>
                    <div>
                      <label
                        className="block text-sm font-medium mb-1"
                        htmlFor="name"
                      >
                        Name <span className="text-rose-500">*</span>
                      </label>
                      <input
                        name="name"
                        id="name"
                        placeholder="Enter name"
                        className={`form-input w-full ${
                          validation.touched.name &&
                          validation.errors.name &&
                          "border-rose-300"
                        }`}
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.name || ""}
                        invalid={
                          validation.touched.name && validation.errors.name
                            ? true
                            : false
                        }
                      />
                    </div>
                    {validation.touched.name && validation.errors.name ? (
                      <div className="text-xs mt-1 text-rose-500">
                        {validation.errors.name}
                      </div>
                    ) : null}
                  </div>
                  <div className="col-span-full sm:col-span-6">
                    <label
                      className="block text-sm font-medium mb-1"
                      htmlFor="amount"
                    >
                      Amount <span className="text-rose-500">*</span>
                    </label>
                    <input
                      id="amount"
                      type="text"
                      placeholder="Amount"
                      name="amount"
                      className={`form-input w-full ${
                        validation.touched.amount &&
                        validation.errors.amount &&
                        "border-rose-300"
                      }`}
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.amount || ""}
                      invalid={
                        validation.touched.amount && validation.errors.amount
                          ? true
                          : false
                      }
                    />
                    {validation.touched.amount && validation.errors.amount ? (
                      <div className="text-xs mt-1 text-rose-500">
                        {validation.errors.amount}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="mt-5 pt-5 border-t border-slate-200 dark:border-slate-700">
                  <div className="flex flex-wrap justify-end space-x-2">
                    <button
                      className="btn-sm border-slate-200 dark:border-slate-700 hover:border-slate-300 dark:hover:border-slate-600 text-slate-600 dark:text-slate-300"
                      onClick={(e) => {
                        e.stopPropagation();
                        setModalOpen(false);
                      }}
                      type="button"
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      disabled={loading}
                      className="btn-sm bg-indigo-500 hover:bg-indigo-600 text-white
                      disabled:bg-indigo-100  disabled:text-slate-400  disabled:cursor-not-allowed"
                    >
                      {loading && (
                        <svg
                          className="animate-spin w-4 h-4 fill-current shrink-0 mr-2"
                          viewBox="0 0 16 16"
                        >
                          <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z" />
                        </svg>
                      )}{" "}
                      Save
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      )}
      {/* Modal footer */}
    </ModalBasic>
  );
};

export default EditTransaction;
