import React, { useEffect, useState } from 'react';
import moment from 'moment';
import ModalBasic from '../../components/ModalBasic';
import {
  getBillEventStatusColor,
  getInvoiceStatusColor,
} from '../../utils/Utils';
import { getBillsEvents } from '../../API/backend_helper';

const BillEmailHistoryModal = ({ isOpen, setIsOpen }) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  const loadBillEvents = async () => {
    setLoading(true);
    try {
      const res = await getBillsEvents();
      setData(res?.data?.events);
    } catch (err) {
      console.log('err', err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadBillEvents();
  }, []);

  return (
    <ModalBasic
      modalOpen={isOpen}
      setModalOpen={setIsOpen}
      title="Bill Email History"
      width="!w-[800px]"
    >
      <div>
        <div className="overflow-x-auto h-full px-8 py-6 min-h-[400px]">
          {loading ? (
            <div className="flex flex-col gap-2 items-center pb-2 justify-center my-8">
              <svg
                width="29"
                height="29"
                viewBox="0 0 29 29"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="animate-spin w-10 h-10 fill-current shrink-0"
              >
                <path
                  d="M14.5 2.41699V7.25033"
                  stroke="#E48642"
                  strokeWidth="2.55882"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M14.5 21.749V26.5824"
                  stroke="#E48642"
                  strokeWidth="2.55882"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M5.95703 5.95703L9.37662 9.37662"
                  stroke="#E48642"
                  strokeWidth="1.58955"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M19.623 19.6211L23.0426 23.0407"
                  stroke="#E48642"
                  strokeWidth="1.58955"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M2.41699 14.5H7.25033"
                  stroke="#E48642"
                  strokeWidth="1.58955"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M21.749 14.5H26.5824"
                  stroke="#E48642"
                  strokeWidth="1.58955"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M5.95703 23.0407L9.37662 19.6211"
                  stroke="#E48642"
                  strokeWidth="1.58955"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M19.623 9.37662L23.0426 5.95703"
                  stroke="#E48642"
                  strokeWidth="1.58955"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <p className="text-slate-600 font-medium text-[0.875rem] text-center">
                Fetching data, please wait...
              </p>
            </div>
          ) : (
            <table className="table-fixed w-full dark:text-slate-300">
              {/* Table header */}
              <thead className="w-full shadow-md rounded-[0.313rem] text-sm leading-[1.125rem] text-slate-600">
                <tr className="w-full py-[1.438rem] px-[1.375rem] h-16">
                  <th className="px-4 w-[6rem] max-w-[6rem]">
                    <div className="text-left w-[6.25rem] font-medium">
                      Date
                    </div>
                  </th>
                  <th className="w-[14rem] max-w-[14rem]">
                    <div className="text-left w-[8.75rem] font-medium">
                      From
                    </div>
                  </th>
                  <th className="w-[6rem] max-w-[6rem]">
                    <div className="text-left font-medium">Status</div>
                  </th>
                </tr>
              </thead>
              {/* Table body */}
              {data?.length > 0 ? (
                <tbody className="text-sm divide-y divide-gray-300 border-b border-gray-300">
                  {data?.map((event, index) => (
                    <tr
                      key={index}
                      className=" w-full pl-[1.375rem]  pr-[1.125rem] h-20 hover:bg-[#a0cd85] hover:bg-opacity-5"
                    >
                      <td className="px-4">
                        <div className=" break-words text-[0.938rem] font-medium leading-6 text-left text-slate-600">
                          {moment(event?.date).format('MMM DD, yyyy')}
                        </div>
                      </td>
                      <td className="pr-4">
                        <div className=" text-left text-slate-600 text-[0.938rem] leading-6 break-words">
                          {event?.fromEmail}
                        </div>
                      </td>
                      <td className="whitespace-nowrap">
                        <div className="text-left">
                          <p className="text-slate-600 text-[0.938rem] leading-6">
                            <div
                              className={`capitalize  px-2.5 py-1 w-fit rounded-md  text-sm font-medium
                              ${getBillEventStatusColor(event?.status)}`}
                            >
                              {event?.status}
                            </div>
                          </p>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              ) : (
                <tr>
                  <td className="p-4" colSpan={3}>
                    <div className=" text-center text-slate-400">
                      No Data Found
                    </div>
                  </td>
                </tr>
              )}
            </table>
          )}
        </div>
      </div>
    </ModalBasic>
  );
};

export default BillEmailHistoryModal;
