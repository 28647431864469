import React, { useState, useEffect } from 'react';
import { PaperClipSquare, SmallCheckedSquare, TrashSquare } from './Svg';
import { Loader } from '../../components/Svg';
import {
  deleteNotification,
  markNotificationAsRead,
} from '../../API/backend_helper';
import { VIEWER } from '../../utils/Utils';

const Notifications = ({
  isLoading,
  notifications,
  loadData,
  loadSingleTransaction,
  setCurrentTab,
  loadSingleTask,
  setTaskFlyoutOpen,
  accessType,
}) => {
  const [mapNotifications, setMapNotifications] = useState([]);

  useEffect(() => {
    const sortedNotifications = [...notifications].sort(
      (a, b) => new Date(b.createdAt) - new Date(a.createdAt),
    );
    setMapNotifications(sortedNotifications);
  }, [notifications]);

  const handleMarkAsRead = async (id) => {
    // set read loading to true
    await markNotificationAsRead(id);
    await loadData(false);
    // set read loading to false
  };

  return isLoading ? (
    <div className="w-full mt-10 flex justify-center items-center">
      <Loader />
    </div>
  ) : (
    <div className="flex flex-col items-end gap-5 self-stretch">
      {!isLoading && mapNotifications.length === 0 ? (
        <div className="flex w-full items-center justify-center flex-col mt-10">
          <p className="text-slate-600 text-[20px] text-center">
            No Notifications
          </p>
          <p className="text-slate-600 text-[16px] text-center">
            You don't have any notifications right now
          </p>
        </div>
      ) : (
        <>
          {mapNotifications.map((notification) => (
            <div
              key={notification?.id}
              className="flex w-full pt-2 pb-4 items-center border-b border-[#D0D5DD] justify-between"
            >
              <div className="flex gap-5 items-center">
                <PaperClipSquare />
                <p
                  className={`text-slate-600 leading-6 ${!notification?.seen && accessType !== VIEWER ? 'font-semibold' : ''} `}
                >
                  {notification?.notificationType === 'transaction'
                    ? `You were tagged in a comment in Transaction #${notification?.associationId}. `
                    : notification?.notificationType === 'task'
                      ? `${notification?.message}. `
                      : ''}
                  <span
                    className="text-indigo-500 cursor-pointer"
                    onClick={() => {
                      if (notification?.notificationType === 'transaction') {
                        loadSingleTransaction(notification?.associationId);
                        setCurrentTab('comments');
                      } else if (notification?.notificationType === 'task') {
                        loadSingleTask(notification?.associationId);
                        setTaskFlyoutOpen(true);
                      }
                    }}
                    role="button"
                    tabIndex="0"
                  >
                    Click here
                  </span>{' '}
                  to view details
                </p>
              </div>
              <div className="flex gap-2 items-center">
                {!notification?.seen && accessType !== VIEWER && (
                  <div className="flex items-center gap-1.5">
                    <SmallCheckedSquare />
                    <p
                      className="text-indigo-500 font-medium text-sm leading-[18px] cursor-pointer whitespace-nowrap"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleMarkAsRead(notification.id);
                      }}
                    >
                      Mark as Read
                    </p>
                  </div>
                )}
                {accessType !== VIEWER && (
                  <div
                    className="cursor-pointer"
                    onClick={() => {
                      deleteNotification(notification.id);
                      loadData(false);
                    }}
                    role="button"
                    tabIndex="0"
                  >
                    <TrashSquare />
                  </div>
                )}
              </div>
            </div>
          ))}
        </>
      )}
    </div>
  );
};

export default Notifications;
