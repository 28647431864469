export const Transactions_Data = [
  {
    id: 1,
    reportName: 'John Doe',
    date: 'Jun 08, 2024',
    expenseType: 'Income',
    amount: '$15.00',
  },
  {
    id: 2,
    reportName: 'John Doe',
    date: 'Jun 08, 2024',
    expenseType: 'Income',
    amount: '$15.00',
  },
  {
    id: 3,
    reportName: 'John Doe',
    date: 'Jun 08, 2024',
    expenseType: 'Income',
    amount: '$15.00',
  },
  {
    id: 4,
    reportName: 'John Doe',
    date: 'Jun 08, 2024',
    expenseType: 'Income',
    amount: '$15.00',
  },
];

export const REPORTERS_DATA = [
  {
    name: 'John Doe',
    email: 'testing@test.com',
  },
  {
    name: 'John Doe',
    email: 'testing@test.com',
  },
  {
    name: 'John Doe',
    email: 'testing@test.com',
  },
  {
    name: 'John Doe',
    email: 'testing@test.com',
  },
  {
    name: 'John Doe',
    email: 'testing@test.com',
  },
  {
    name: 'John Doe',
    email: 'testing@test.com',
  },
];
