import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import BillsModal from './BillsModal';
import {
  assignTransactionToBill,
  deleteBillById,
  getAccounts,
  getAllBills,
  getMerchants,
  updateInstanceById,
  getAllProjects,
  getTags,
  getProcessBillsStat,
} from '../../API/backend_helper';
import { DevEnv, transformAccounts, VIEWER } from '../../utils/Utils';
import BillsTable from './BillsTable';
import InstancesModal from './InstancesModal';
import PaginationClassic from '../../components/PaginationClassic';
import BasicCheckboxDropdown from '../component/BasicCheckboxDropdown';
import NoBillBox from './NoBillBox';
import TransactionsModal from './TransactionsModal';
import BillDetails from './BillDetails';
import AddTaskModal from '../inbox/AddTaskModal';
import BillsChoiceModal from './BillsChoiceModal';
import AppLayout from '../../components/AppLayout';
import BillsEmailHistoryModal from './BillEmailHistoryModal';
import ReceiptModal from '../transactions/ReceiptModal';

export const BILLS_MODAL = 'billModal';
export const INSTANCES_MODAL = 'instancesModal';

const Bills = () => {
  const location = useLocation();
  const { accessType } = useSelector((state) => state.User);
  const { team } = useSelector((state) => state.Team);
  const { currencies } = useSelector((state) => state.Currencies);

  const [isLoading, setIsLoading] = useState(true);
  const [instancesModalOpen, setInstancesModalOpen] = useState(false);
  const [locationWorked, setLocationWorked] = useState(false);

  const [allBills, setAllBills] = useState([]);
  const [billStat, setBillStat] = useState(null);
  const [vendors, setVendors] = useState([]);
  const [projects, setProjects] = useState([]);
  const [allCategories, setAllCategories] = useState([]);
  const [expenseCategories, setExpenseCategories] = useState([]);
  const [localLimit, setLocalLimit] = useState(50);

  const [activeBillId, setActiveBillId] = useState(null);
  const [activeInstance, setActiveInstance] = useState(-1);
  const [activeBill, setActiveBill] = useState(null);
  const [apiLoading, setApiLoading] = useState(false);
  const [tags, setTags] = useState([]);
  const [billEmailHistoryModalOpen, setBillEmailHistoryModalOpen] =
    useState(false);
  const [receiptModalOpen, setReceiptModalOpen] = useState(false);
  const [activeReceipt, setActiveReceipt] = useState(null);

  // For Bills Filtering
  const [selectedVendors, setSelectedVendors] = useState([]);
  // For Active Modal
  const [isActive, setIsActive] = useState('');
  const [billModalPdfView, setBillModalPdfView] = useState(false);
  // Transactions Modal
  const [transactionsModalOpen, setTransactionsModalOpen] = useState(false);
  const [activeTransaction, setActiveTransaction] = useState(null);
  const [billDetails, setBillDetails] = useState(false);
  // Tasks Modal
  const [tasksModalOpen, setTasksModalOpen] = useState(false);
  // Bill Choice Modal
  const [billChoiceModalOpen, setBillChoiceModalOpen] = useState(false);
  const [uploadedBillData, setUploadedBillData] = useState({});

  document.title = 'COUNT | Bills';

  const loadAllCategories = async () => {
    try {
      const accountsRes = await getAccounts();
      setAllCategories(
        transformAccounts(accountsRes, 'CATEGORY_PHYSICAL_ACCOUNT', 'expense'),
      );
      setExpenseCategories(transformAccounts(accountsRes, 'bills', 'expense'));
    } catch (err) {
      console.log('err', err);
    }
  };

  const loadAllVendors = async () => {
    try {
      const res = await getMerchants({});
      setVendors(res?.vendors);
    } catch (err) {
      console.log('err', err);
    }
  };

  const getTagsApi = async () => {
    try {
      const res = await getTags();
      setTags(res);
    } catch (e) {
      console.log('error', e);
    }
  };

  const loadAllProjects = async () => {
    try {
      const res = await getAllProjects({});
      const filteredProjects = res?.filter(
        (project) =>
          project?.status === 'Not started' ||
          project?.status === 'In progress',
      );
      setProjects(filteredProjects);
    } catch (err) {
      console.log('err', err);
    }
  };

  const getProcessBillsStatApi = async () => {
    try {
      const res = await getProcessBillsStat({});
      setBillStat(res?.data?.stat?.[0]);
    } catch (err) {
      console.log('err', err);
    }
  };

  const loadAllBills = async ({
    loading = false,
    page = allBills?.page,
    limit = localLimit,
    vendors = selectedVendors || [],
    startDate = '',
    endDate = '',
  }) => {
    if (loading) {
      setIsLoading(true);
    }
    try {
      const res = await getAllBills({
        page,
        limit,
        vendors,
        startDate,
        endDate,
      });
      setAllBills(res);
      setApiLoading(false);
      //  setTimeout(()=>,1000)
    } catch (err) {
      console.log('err', err);
      setApiLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (location?.state?.isActive && !locationWorked) {
      setIsActive(BILLS_MODAL);
      setLocationWorked(true);
    }
    if (location?.state?.activeBillId && !locationWorked) {
      const foundBill = allBills?.bills?.find((bill) =>
        bill.instances.some(
          (instance) => instance.id === location.state.activeBillId,
        ),
      );
      if (foundBill) {
        setLocationWorked(true);
        setActiveBill(foundBill);
        setInstancesModalOpen(true);
      }
    }
  }, [location, allBills]);

  useEffect(() => {
    getProcessBillsStatApi();
    loadAllCategories();
    loadAllVendors();
    loadAllProjects();
    getTagsApi();
    loadAllBills({ loading: true });
  }, []);

  const handleDeleteBill = async (id) => {
    try {
      await deleteBillById(id);
      await loadAllBills({ loading: false });
      toast.success('Bill deleted successfully');
    } catch (err) {
      console.log('err', err);
    }
  };

  // const onDateChange = async (startDate, endDate) => {
  //   await loadAllBills({
  //     startDate: startDate ? moment(startDate).format('YYYY-MM-DD') : '',
  //     endDate: endDate ? moment(endDate).format('YYYY-MM-DD') : '',
  //     page: 1,
  //     loading: false,
  //   });
  // };

  const handleTransactionAssignment = async (
    transactionId = activeTransaction?.id,
  ) => {
    try {
      const res = await assignTransactionToBill(
        activeBill?.id,
        activeInstance?.id,
        {
          transactionId,
        },
      );
      if (res?.id) {
        loadAllBills({ loading: true });
      }
      return res;
    } catch (err) {
      console.log('err', err);
    }
  };

  const updateInstanceTotal = async (total) => {
    const data = {
      total,
    };

    try {
      const res = await updateInstanceById(
        activeBill?.id,
        activeInstance?.id,
        data,
      );
      return res;
    } catch (err) {
      console.log('err', err);
    }
  };

  useEffect(() => {
    if (isActive !== BILLS_MODAL && billModalPdfView) {
      setBillModalPdfView(false);
    }
  }, [isActive]);

  const { page, totalRecords } = allBills;

  return (
    <>
      <BillsChoiceModal
        modalOpen={billChoiceModalOpen}
        setModalOpen={setBillChoiceModalOpen}
        setIsActive={setIsActive}
        uploadedBillData={uploadedBillData}
        setUploadedBillData={setUploadedBillData}
        BILLS_MODAL={BILLS_MODAL}
      />
      <InstancesModal
        isOpen={instancesModalOpen}
        setIsOpen={setInstancesModalOpen}
        instances={activeBill?.id > -1 ? activeBill?.instances : []}
        setActiveInstance={setActiveInstance}
        setTransactionsModalOpen={setTransactionsModalOpen}
        billId={activeBill?.id}
        reloadData={() => loadAllBills({ loading: true })}
        activeBill={activeBill || {}}
        currencies={currencies}
        activeInstance={activeInstance}
      />
      <TransactionsModal
        isOpen={transactionsModalOpen}
        setIsOpen={setTransactionsModalOpen}
        activeTransaction={activeTransaction}
        activeInstance={activeInstance}
        setActiveTransaction={setActiveTransaction}
        handleTransactionAssignment={handleTransactionAssignment}
        updateInstanceTotal={updateInstanceTotal}
        allCategories={allCategories}
        currencies={currencies}
        activeBill={activeBill || {}}
        setActiveBill={setActiveBill}
        loadAllBills={loadAllBills}
      />
      <BillDetails
        allCategories={allCategories}
        vendors={vendors}
        setVendors={setVendors}
        activeBillId={activeBillId}
        setActiveBillId={setActiveBillId}
        loadAllBills={loadAllBills}
        setIsActive={setIsActive}
        currencies={currencies}
        team={team}
        expenseCategories={expenseCategories}
        isOpen={billDetails}
        setIsOpen={setBillDetails}
        projects={projects}
        setReceiptModalOpen={setReceiptModalOpen}
        setActiveReceipt={setActiveReceipt}
      />
      <ReceiptModal
        isExpenseReporting
        isOpen={receiptModalOpen}
        setIsOpen={setReceiptModalOpen}
        receipt={activeReceipt}
        type={uploadedBillData?.type}
      />
      <AddTaskModal
        modalOpen={tasksModalOpen}
        setModalOpen={setTasksModalOpen}
        bills
        selectedBill={activeBill}
      />

      <AppLayout
        pageId="billsPageContainer"
        showSidebar={!billModalPdfView}
        showNavLogo={billModalPdfView}
      >
        <main className="relative grow ">
          {/* Content */}
          <div
            className={`${billModalPdfView ? 'max-w-[2000px]' : 'max-w-9xl'} px-4 sm:px-6 lg:px-8 py-8 w-full  mx-auto h-full flex flex-col`}
          >
            <BillsEmailHistoryModal
              isOpen={billEmailHistoryModalOpen}
              setIsOpen={setBillEmailHistoryModalOpen}
            />
            {isActive === BILLS_MODAL ? (
              <BillsModal
                allCategories={allCategories}
                vendors={vendors}
                setVendors={setVendors}
                activeBillId={activeBillId}
                setActiveBillId={setActiveBillId}
                loadAllBills={loadAllBills}
                setIsActive={setIsActive}
                currencies={currencies}
                team={team}
                expenseCategories={expenseCategories}
                projects={projects}
                uploadedBillData={uploadedBillData}
                setUploadedBillData={setUploadedBillData}
                tags={tags}
                setReceiptModalOpen={setReceiptModalOpen}
                setActiveReceipt={setActiveReceipt}
                billModalPdfView={billModalPdfView}
                setBillModalPdfView={setBillModalPdfView}
              />
            ) : (
              <>
                {/* Page header */}
                <div className="flex justify-between items-center mb-4 md:mb-5">
                  <h1 className="text-[36px] leading-tight font-medium text-[#00000099]">
                    Managing Bills
                  </h1>
                  {accessType !== VIEWER && (
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        setBillChoiceModalOpen(true);
                        setActiveBillId(null);
                        setUploadedBillData({});
                      }}
                      type="button"
                      className="btn-sm h-11 px-8 text-base bg-indigo-500 hover:bg-indigo-600 text-white
                        disabled:bg-indigo-400 whitespace-nowrap    disabled:cursor-not-allowed  flex items-center justify-center  gap-2"
                    >
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="mb-[3px]"
                      >
                        <path
                          d="M10 4.16602V15.8327M4.16667 9.99935H15.8333"
                          stroke="white"
                          strokeWidth="1.66667"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      Add a Bill
                    </button>
                  )}
                </div>
                {/* Filters */}
                <div className="w-full  flex justify-between items-center flex-wrap gap-2">
                  <ul className="flex flex-wrap -m-1 py-2">
                    <li className="m-1 w-[300px] max-w-full inline-flex">
                      <BasicCheckboxDropdown
                        selectedOptions={selectedVendors}
                        setSelectedOptions={(values) => {
                          setApiLoading(true);
                          setSelectedVendors(values);
                        }}
                        apiFunc={loadAllBills}
                        options={
                          vendors?.map((t) => ({
                            label: t?.name,
                            value: t?.id,
                          })) || []
                        }
                        onClear={() => {
                          setSelectedVendors([]);
                          loadAllBills({
                            page: 1,
                            vendors: [],
                            loading: true,
                          });
                        }}
                        label="Vendors"
                        type="vendors"
                        apiFilter={allBills?.filters?.vendors}
                        search
                      />
                    </li>
                    {/* <li className="m-1 w-[300px] max-w-full inline-flex">
                        <DateFilter
                          startDateFilter={startDateFilter}
                          endDateFilter={endDateFilter}
                          setStartDateFilter={setStartDateFilter}
                          setEndDateFilter={setEndDateFilter}
                          onDateChange={onDateChange}
                          align="right"
                        />
                      </li> */}
                  </ul>
                  <button
                    onClick={() => {
                      setBillEmailHistoryModalOpen(true);
                    }}
                    type="button"
                    className="btn-sm h-10 px-4 text-base bg-white border border-indigo-500  text-indigo-500
                        disabled:bg-indigo-400 whitespace-nowrap    disabled:cursor-not-allowed  flex items-center justify-center  gap-2"
                  >
                    Bill Email History
                  </button>
                </div>
                {isLoading ? (
                  <div className="flex flex-col gap-2 items-center pb-2 justify-center mt-4">
                    <svg
                      width="29"
                      height="29"
                      viewBox="0 0 29 29"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="animate-spin w-10 h-10 fill-current shrink-0"
                    >
                      <path
                        d="M14.5 2.41699V7.25033"
                        stroke="#E48642"
                        strokeWidth="2.55882"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M14.5 21.749V26.5824"
                        stroke="#E48642"
                        strokeWidth="2.55882"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M5.95703 5.95703L9.37662 9.37662"
                        stroke="#E48642"
                        strokeWidth="1.58955"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M19.623 19.6211L23.0426 23.0407"
                        stroke="#E48642"
                        strokeWidth="1.58955"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M2.41699 14.5H7.25033"
                        stroke="#E48642"
                        strokeWidth="1.58955"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M21.749 14.5H26.5824"
                        stroke="#E48642"
                        strokeWidth="1.58955"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M5.95703 23.0407L9.37662 19.6211"
                        stroke="#E48642"
                        strokeWidth="1.58955"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M19.623 9.37662L23.0426 5.95703"
                        stroke="#E48642"
                        strokeWidth="1.58955"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    <p className="text-slate-600 font-medium text-[0.875rem] text-center">
                      Fetching data, please wait...
                    </p>
                  </div>
                ) : allBills?.bills?.length > 0 ||
                  selectedVendors.length > 0 ||
                  apiLoading ? (
                  <>
                    <BillsTable
                      bills={allBills?.bills}
                      setActiveBillId={setActiveBillId}
                      handleDeleteBill={handleDeleteBill}
                      setInstancesModalOpen={setInstancesModalOpen}
                      setActiveBill={setActiveBill}
                      setIsActive={setIsActive}
                      setBillDetails={setBillDetails}
                      currencies={currencies}
                      setTasksModalOpen={setTasksModalOpen}
                      BILLS_MODAL={BILLS_MODAL}
                    />
                    <div className="mt-8">
                      <PaginationClassic
                        pagination={{
                          limit: localLimit,
                          page,
                          totalRecords,
                        }}
                        onPageChange={loadAllBills}
                        setLimit={setLocalLimit}
                      />
                    </div>
                  </>
                ) : null}
              </>
            )}
            {!isLoading &&
              !(isActive === BILLS_MODAL) &&
              !(
                allBills?.bills?.length > 0 ||
                selectedVendors.length > 0 ||
                apiLoading
              ) && (
                <NoBillBox
                  handleCreateBill={() => {
                    setActiveBillId(null);
                    setUploadedBillData({});
                    setBillChoiceModalOpen(true);
                  }}
                />
              )}
          </div>
        </main>
      </AppLayout>
    </>
  );
};

export default Bills;
