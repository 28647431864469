import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import CustomerForm from './CustomerForm';
import {
  getCustomers,
  createCustomer,
  updateCustomer,
  deleteCustomer,
  getPeople,
} from '../../API/backend_helper';
import PaginationClassic from '../../components/PaginationClassic';
import emptyCustomerImg from '../../images/custom/empty_customer.svg';
import SearchForm from '../../partials/actions/SearchForm';
import DeleteCustomerModal from './DeleteCustomerModal';
import { VIEWER } from '../../utils/Utils';
import AppLayout from '../../components/AppLayout';
import CustomersTable from './CustomersTable';
import { toast } from 'react-toastify';

let searchTimeout = null;
const Customers = () => {
  const { accessType } = useSelector((state) => state.User);

  const [isLoading, setIsLoading] = useState(true);

  const [salesRep, setSalesRep] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [activeCustomer, setActiveCustomer] = useState(null);
  const [displayedCustomers, setDisplayedCustomers] = useState([]);
  const [searchVal, setSearchVal] = useState('');
  const [localLimit, setLocalLimit] = useState(50);
  const [modalOpen, setModalOpen] = useState(false);
  const [formOpen, setFormOpen] = useState(false);
  const [title, setTitle] = useState('Customers');
  const [errMessage, setErrMessage] = useState('');

  document.title = 'COUNT | Customers';

  const getData = async ({
    limit = localLimit,
    page = customers?.page,
    search = searchVal || '',
    loading = false,
  }) => {
    if (loading) {
      setIsLoading(true);
    }
    try {
      const res = await getCustomers({
        page,
        search,
        limit,
      });
      setCustomers(res?.data);
      setDisplayedCustomers(res?.data);
      if (loading) {
        setIsLoading(false);
      }
    } catch (e) {
      if (loading) {
        setIsLoading(false);
      }
      console.log('error', e);
    }
  };

  const getSalesRepApi = async () => {
    try {
      const res = await getPeople({
        limit: 1000000,
        roles: ['sales rep'],
      });
      setSalesRep(res?.people);
    } catch (e) {
      console.log('error', e);
    }
  };

  const handleAddCustomer = async (data) => {
    try {
      const response = await createCustomer(data);
      getData({});
      if (response?.data?.customer?.id) {
        toast.success('Customer created successfully.');
      }
      return response;
    } catch (err) {
      console.log('err', err);
    }
  };

  const handleUpdateCustomer = async (data, id) => {
    try {
      const res = await updateCustomer(data, id);
      getData({});
      setActiveCustomer(null);
      if (res?.data?.updatedCustomer?.id) {
        toast.success('Customer updated successfully.');
      }
    } catch (err) {
      console.log('err', err);
    }
  };

  const handleDeleteCustomer = async (customer) => {
    try {
      const res = await deleteCustomer(customer.id);
      getData({});
      setActiveCustomer(null);
      setModalOpen(false);
      if (res?.status === 204) {
        toast.success('Customer deleted successfully.');
      }
    } catch (err) {
      if (err.message) {
        setErrMessage(JSON.parse(err.message));
      } else {
        setModalOpen(false);
        toast.success('Customer deleted successfully.');
      }
    }
  };

  useEffect(() => {
    getData({ loading: true });
    getSalesRepApi();
  }, []);

  const onSearch = (value) => {
    setSearchVal(value);
    clearTimeout(searchTimeout);
    searchTimeout = setTimeout(() => {
      getData({
        search: value ? encodeURIComponent(value || '') : '',
        page: 1,
      });
    }, 500);
  };

  const { page, totalRecords } = customers;

  return (
    <>
      <DeleteCustomerModal
        isOpen={modalOpen}
        setIsOpen={setModalOpen}
        errMessage={errMessage}
        activeCustomer={activeCustomer}
        handleDeleteCustomer={handleDeleteCustomer}
      />
      <AppLayout>
        <main className="relative grow ">
          {/* Content */}
          <div className=" px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto h-full flex flex-col">
            {/* Page header */}
            <div className="flex flex-col sm:flex-row gap-2 sm:gap-0 justify-between mb-10">
              <h1 className="text-black text-opacity-60 text-4xl font-medium leading-[2.375rem]">
                {title}
              </h1>
              {!formOpen ? (
                <div className="flex flex-col sm:flex-row gap-2 sm:gap-4 items-start sm:items-center">
                  <SearchForm
                    placeholder="Search"
                    searchVal={searchVal}
                    setSearchVal={onSearch}
                    transaction
                  />
                  {accessType !== VIEWER && (
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        setActiveCustomer(null);
                        setTitle('Add a Customer');
                        setFormOpen(true);
                      }}
                      className="bg-indigo-500 h-10 border border-indigo-500 rounded-[0.313rem] px-[1.125rem] py-2.5 shadow text-white text-base leading-6 flex items-center justify-center gap-2 font-normal"
                      type="button"
                    >
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        onClick={() => setFormOpen(true)}
                      >
                        <path
                          d="M10 4.16699V15.8337M4.16669 10.0003H15.8334"
                          stroke="white"
                          strokeWidth="1.66667"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      Add a Customer
                    </button>
                  )}
                </div>
              ) : (
                <button
                  onClick={() => {
                    setActiveCustomer(null);
                    setTitle('Customers');
                    setFormOpen(false);
                  }}
                  className=""
                  type="button"
                >
                  <svg
                    width="28"
                    height="28"
                    viewBox="0 0 28 28"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect
                      x="6.17688"
                      y="7.82422"
                      width="2.3292"
                      height="19.7982"
                      rx="1.1646"
                      transform="rotate(-45 6.17688 7.82422)"
                      fill="#667085"
                    />
                    <rect
                      x="20.1771"
                      y="6.17676"
                      width="2.3292"
                      height="19.7982"
                      rx="1.1646"
                      transform="rotate(45 20.1771 6.17676)"
                      fill="#667085"
                    />
                  </svg>
                </button>
              )}
            </div>
            {formOpen && (
              <CustomerForm
                setActiveCustomer={setActiveCustomer}
                activeCustomer={activeCustomer}
                handleUpdateCustomer={handleUpdateCustomer}
                handleAddCustomer={handleAddCustomer}
                setFormOpen={setFormOpen}
                setTitle={setTitle}
                salesRep={salesRep}
              />
            )}
            {isLoading && (
              <div className="flex flex-col gap-2 items-center pb-2 justify-center mt-8">
                <svg
                  width="29"
                  height="29"
                  viewBox="0 0 29 29"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="animate-spin w-10 h-10 fill-current shrink-0"
                >
                  <path
                    d="M14.5 2.41699V7.25033"
                    stroke="#E48642"
                    strokeWidth="2.55882"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M14.5 21.749V26.5824"
                    stroke="#E48642"
                    strokeWidth="2.55882"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M5.95703 5.95703L9.37662 9.37662"
                    stroke="#E48642"
                    strokeWidth="1.58955"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M19.623 19.6211L23.0426 23.0407"
                    stroke="#E48642"
                    strokeWidth="1.58955"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M2.41699 14.5H7.25033"
                    stroke="#E48642"
                    strokeWidth="1.58955"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M21.749 14.5H26.5824"
                    stroke="#E48642"
                    strokeWidth="1.58955"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M5.95703 23.0407L9.37662 19.6211"
                    stroke="#E48642"
                    strokeWidth="1.58955"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M19.623 9.37662L23.0426 5.95703"
                    stroke="#E48642"
                    strokeWidth="1.58955"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <p className="text-slate-600 font-medium text-[0.875rem] text-center">
                  Fetching data, please wait...
                </p>
              </div>
            )}
            {customers?.records?.length > 0 && !isLoading && !formOpen && (
              <>
                <CustomersTable
                  customers={customers}
                  displayedCustomers={displayedCustomers}
                  setTitle={setTitle}
                  setActiveCustomer={setActiveCustomer}
                  setFormOpen={setFormOpen}
                  setModalOpen={setModalOpen}
                />
                <div className="mt-10">
                  <PaginationClassic
                    setLimit={setLocalLimit}
                    onPageChange={getData}
                    pagination={{ limit: localLimit, page, totalRecords }}
                  />
                </div>
              </>
            )}
            {!formOpen && customers?.records?.length <= 0 && !isLoading && (
              <div className="flex flex-col gap-5 items-center justify-center w-full h-full">
                <h6 className="text-center mt-4">No Customers Found</h6>
                <img
                  src={emptyCustomerImg}
                  alt="No Customers found"
                  className="h-[12.5rem]"
                />
              </div>
            )}
          </div>
        </main>
      </AppLayout>
    </>
  );
};

export default Customers;
