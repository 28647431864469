import React from 'react';
import ModalBasic from '../../components/ModalBasic';

const BillsWarningModal = ({ isOpen, setIsOpen, handleDone }) => (
  <ModalBasic
    title="Close Bill"
    modalOpen={isOpen}
    setModalOpen={setIsOpen}
    smallModal
  >
    <div className="w-full px-5 py-[1.875rem] flex items-center self-stretch bg-white">
      <p className="text-base leading-6 text-slate-600 text-opacity-80">
        Are you sure you want to delete all these changes? This action cannot be
        undone.
      </p>
    </div>
    <div className="border-t border-gray-300 h-20 flex items-center justify-end gap-4 px-5">
      <button
        className="w-[5.625rem] h-11 flex items-center justify-center py-2.5 px-[1.875rem] rounded-[0.313rem] border border-gray-300 border-opacity-40 shadow bg-white text-slate-600 leading-6"
        onClick={(e) => {
          e.stopPropagation();
          setIsOpen(false);
        }}
        type="button"
      >
        No
      </button>
      <button
        className="w-[5.625rem] h-11 
          disabled:bg-indigo-300 disabled:border-indigo-300 disabled:cursor-not-allowed 
          flex items-center justify-center py-2.5 px-[1.875rem] rounded-[0.313rem] bg-indigo-500 shadow text-white leading-6"
        onClick={handleDone}
        type="button"
      >
        Yes
      </button>
    </div>
  </ModalBasic>
);

export default BillsWarningModal;
