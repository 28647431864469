import React, { useState, useEffect, useRef } from "react";
import Transition from "../../utils/Transition";

const AddTaskBtn = ({
  setModalOpen,
  modalOpen,
  dropdownOpen,
  setDropdownOpen,
  setSelectedTaskOption,
}) => {
  const dropdownRef = useRef(null);

  const options = [
    { name: "Review Transaction", id: "reviewTransaction" },
    { name: "Set Transaction Vendor", id: "transactionVendor" },
    { name: "Set Transaction Category", id: "transactionCategory" },
    { name: "Upload Transaction Attachment", id: "transactionAttachment" },
  ];

  return (
    <>
      <div className="relative w-full">
        <button
          type="button"
          onClick={() => setModalOpen(true)}
          className="bg-indigo-500 w-fit h-8 py-2 px-[0.5rem] rounded-[5px] flex items-center justify-center gap-2 text-white text-[14px] whitespace-nowrap"
        >
          <svg
            width="12"
            height="11"
            viewBox="0 0 15 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7.5013 1.16699V12.8337M1.66797 7.00033H13.3346"
              stroke="white"
              stroke-width="1.66667"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <span>Assign Task</span>
        </button>
      </div>
    </>
  );
};

export default AddTaskBtn;
