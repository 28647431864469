import React, { useContext, useEffect, useState } from "react";
import WarningBox from "../../components/WarningBox";
import {
  bulkExcludeTransactions,
  ignoreMatchingTransaction,
} from "../../API/backend_helper";
import moment from "moment";
import { amountColor, getContrast, TEAM_ID } from "../../utils/Utils";
import Tooltip from "../../components/Tooltip";
import { Loader } from "../../components/Svg";
import emptyMatchingsImg from "../../images/custom/empty-search.svg";
import { useNavigate } from "react-router-dom";
import PaginationClassic from "../../components/PaginationClassic";
import { VIEWER } from "../../utils/Utils";
import { useSelector } from "react-redux";

const DuplicateTransactions = ({
  matchings,
  setMatchings,
  currencies,
  team,
  selectedTransactions,
  setSelectedTransactions,
  loadTransactionsApi,
  localLimit,
  setLocalLimit,
  loadSingleTransaction,
}) => {
  const { accessType } = useSelector((state) => state.User);

  const [descriptionLength, setDescriptionLength] = useState(15);
  const [isLoading, setIsLoading] = useState(false);
  const [keepAllLoading, setKeepAllLoading] = useState({});
  const [keepSelectedLoading, setKeepSelectedLoading] = useState({});
  const [isMobile, setIsMobile] = useState(false);
  const [keepAllSelectedLoading, setKeepAllSelectedLoading] = useState(false);
  const [bulkKeepAllLoading, setBulkKeepAllLoading] = useState(false);
  const teamId = localStorage.getItem(TEAM_ID);
  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 1800) {
        setDescriptionLength(45);
        setIsMobile(false);
      }
      if (window.innerWidth > 1700) {
        setDescriptionLength(38);
        setIsMobile(false);
      } else if (window.innerWidth > 1300) {
        setDescriptionLength(24);
        setIsMobile(false);
      } else if (window.innerWidth > 760) {
        setDescriptionLength(22);
        setIsMobile(false);
      } else {
        setDescriptionLength(10);
        setIsMobile(true);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const findCurrencySymbol = (isoCode) => {
    const currency = currencies?.find?.(
      (currency) => currency.isoCode === isoCode
    );
    return currency?.symbol === "$" && currency?.isoCode != "USD"
      ? currency.symbol
      : currency?.symbol;
  };

  const handleTransactionCheckbox = (e, matchId) => {
    e.stopPropagation();
    const { id } = e.target;
    const parsedId = parseInt(id);
    setSelectedTransactions((prev) => {
      const currentMatchTransactions = prev[matchId] || [];
      if (currentMatchTransactions.includes(parsedId)) {
        return {
          ...prev,
          [matchId]: currentMatchTransactions.filter(
            (item) => item !== parsedId
          ),
        };
      } else {
        return {
          ...prev,
          [matchId]: [...currentMatchTransactions, parsedId],
        };
      }
    });
  };

  const handleKeepAll = async (id) => {
    setKeepAllLoading((prev) => ({ ...prev, [id]: true }));
    const matching = matchings?.matchings?.find(
      (matching) => matching.id === id
    );
    if (matching) {
      try {
        await ignoreMatchingTransaction(id);
        await loadTransactionsApi({});
        setSelectedTransactions((prev) => {
          const { [id]: _, ...rest } = prev;
          return rest;
        });
      } catch (error) {
        console.log(error);
      } finally {
        setKeepAllLoading((prev) => ({ ...prev, [id]: false }));
      }
    }
  };

  const handleBulkKeepAll = async () => {
    setBulkKeepAllLoading(true);
    matchings?.matchings?.forEach(async (matching) => {
      try {
        await ignoreMatchingTransaction(matching.id);
        setSelectedTransactions((prev) => {
          const { [matching.id]: _, ...rest } = prev;
          return rest;
        });
      } catch (error) {
        console.log(error);
      }
    });
    await loadTransactionsApi({firstLoad: true});
    setBulkKeepAllLoading(false);
  };

  const handleKeepSelected = async (id) => {
    setKeepSelectedLoading((prev) => ({ ...prev, [id]: true }));

    if (selectedTransactions[id]?.length > 0) {
      try {
        const matching = matchings?.matchings?.find(
          (matching) => matching.id === id
        );
        const transactionIds = matching?.duplicateTransactions?.map(
          (transaction) => transaction.id
        );
        const transactionsToDelete = transactionIds?.filter(
          (transactionId) => !selectedTransactions[id].includes(transactionId)
        );
        if (transactionsToDelete.length > 0) {
          await bulkExcludeTransactions({
            transactionIds: transactionsToDelete,
            excluded: true,
          });
          await ignoreMatchingTransaction(id, {
            deletedTransactionIds: transactionsToDelete,
          });
          await loadTransactionsApi({});
        }
        setSelectedTransactions((prev) => {
          const { [id]: _, ...rest } = prev;
          return rest;
        });
      } catch (error) {
        console.log(error);
      } finally {
        setKeepSelectedLoading((prev) => ({ ...prev, [id]: false }));
      }
    } else {
      setKeepSelectedLoading((prev) => ({ ...prev, [id]: false }));
    }
  };

  const handleKeepAllSelected = async () => {
    setKeepAllSelectedLoading(true);
    setKeepSelectedLoading((prev) => {
      const loadingState = {};
      Object.keys(selectedTransactions).forEach((id) => {
        loadingState[id] = true;
      });
      return { ...prev, ...loadingState };
    });

    try {
      for (const id of Object.keys(selectedTransactions)) {
        if (selectedTransactions[id]?.length > 0) {
          const matching = matchings?.matchings?.find(
            (matching) => matching.id === parseFloat(id)
          );
          const transactionIds = matching?.duplicateTransactions?.map(
            (transaction) => transaction.id
          );
          const transactionsToDelete = transactionIds?.filter(
            (transactionId) => !selectedTransactions[id].includes(transactionId)
          );
          if (transactionsToDelete.length > 0) {
            await bulkExcludeTransactions({
              transactionIds: transactionsToDelete,
              excluded: true,
            });
            await ignoreMatchingTransaction(id, {
              deletedTransactionIds: transactionsToDelete,
            });
          }
        }
      }
      await loadTransactionsApi({});
      setSelectedTransactions({});
    } catch (error) {
      console.log(error);
    } finally {
      setKeepSelectedLoading((prev) => {
        const loadingState = {};
        Object.keys(selectedTransactions).forEach((id) => {
          loadingState[id] = false;
        });
        return { ...prev, ...loadingState };
      });
      setKeepAllSelectedLoading(false);
    }
  };

  const { page, totalRecords } = matchings;

  return (
    <>
      {matchings?.totalRecords > 0 && !isLoading && (
        <WarningBox content="Please review potential duplicate transactions below. You can choose to keep all of them or select some to keep and the other ones will be marked as excluded." />
      )}
      {/*  Commented out in case a heading is desired
      <div className="flex justify-between flex-wrap items-center mt-2 mb-4 md:mb-2">
        <h1 className="text-3xl md:text-4xl text-black opacity-60  font-medium">
          Duplicate Transactions
        </h1>
      </div>*/}
      {isLoading ? (
        <div className="flex flex-col gap-2 items-center pb-2 justify-center mt-8">
          <Loader />
          <p className="text-slate-600 font-medium text-[0.875rem] text-center">
            Fetching data, please wait...
          </p>
        </div>
      ) : (
        matchings?.totalRecords > 0 && (
          <>
            <div className="w-full mt-4 rounded-[5px] shadow flex items-center px-6 self-stretch gap-4 sm:gap-11 h-[3.5rem]">
              <div className="text-slate-600 text-[14px] leading-[1.125rem] font-medium w-[20%] sm:w-[15%]">
                Date
              </div>
              <div className="text-slate-600 text-[14px] leading-[1.125rem] font-medium w-[20%] sm:w-[20%]">
                Description
              </div>
              <div className="text-slate-600 text-[14px] leading-[1.125rem] font-medium w-[20%] sm:w-[20%]">
                Account
              </div>
              <div className="text-slate-600 text-[14px] leading-[1.125rem] font-medium w-[20%] sm:w-[15%]">
                Amount
              </div>
              {!isMobile && (
                <div className="text-slate-600 text-[14px] leading-[1.125rem] font-medium w-[20%]">
                  Vendor/Customer
                </div>
              )}
            </div>
            {matchings?.matchings?.map((matching) => {
              return (
                <div className="w-full flex items-start mt-4 mb-10">
                  <div className="w-full flex-col flex gap-4 items-start">
                    {matching?.duplicateTransactions?.map((transaction) => {
                      const anySelected =
                        selectedTransactions[matching.id]?.length > 0;
                      const isSelected =
                        selectedTransactions[matching.id]?.includes(
                          transaction.id
                        ) || false;
                      return (
                        <div className="w-full rounded-[5px] flex items-center pl-6 self-stretch gap-4 sm:gap-11 py-4 px-6 relative">
                          {!isSelected && anySelected && (
                            <div className="absolute w-[90%] ml-8 top-1/2 h-px bg-slate-700"></div>
                          )}
                          <div className="text-slate-600 text-[14px] leading-[1.125rem] w-[20%] sm:w-[15%] flex gap-1 sm:gap-4 items-center whitespace-nowrap">
                            <div className="flex items-center">
                              <label className="inline-flex">
                                <span className="sr-only">Select</span>
                                <input
                                  id={transaction.id}
                                  className="form-checkbox cursor-pointer h-5 w-5"
                                  type="checkbox"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                  }}
                                  onChange={(e) =>
                                    handleTransactionCheckbox(e, matching.id)
                                  }
                                  checked={
                                    selectedTransactions[matching.id]?.includes(
                                      transaction.id
                                    ) || false
                                  }
                                />
                              </label>
                            </div>
                            <p>
                              {isMobile
                                ? moment(transaction?.authorizedDate).format(
                                    "MMM DD"
                                  )
                                : moment(transaction?.authorizedDate).format(
                                    "MMM DD, YYYY"
                                  )}
                            </p>
                          </div>
                          <div className="text-slate-600 text-[14px] leading-[1.125rem] w-[20%] whitespace-nowrap">
                            <div className="flex items-center">
                              {!isMobile && (
                                <div className="w-[1.687rem] h-[1.687rem] flex-shrink-0 mr-2 sm:mr-3 ">
                                  {transaction?.vendor?.logoUrl ||
                                  transaction?.plaidCategoryIconUrl ? (
                                    <img
                                      className="rounded-full"
                                      src={
                                        transaction?.vendor?.logoUrl ||
                                        transaction?.plaidCategoryIconUrl
                                      }
                                      alt={transaction?.name}
                                    />
                                  ) : (
                                    <div
                                      className="w-[1.687rem] h-[1.687rem] rounded-full flex items-center justify-center bg-slate-400 dark:bg-slate-600"
                                      style={{
                                        backgroundColor: transaction
                                          ?.categoryAccount?.group?.color
                                          ? transaction?.categoryAccount?.group
                                              ?.color
                                          : "",
                                      }}
                                    >
                                      <div
                                        className={`${
                                          transaction?.categoryAccount?.group
                                            ?.color
                                            ? getContrast(
                                                transaction?.categoryAccount
                                                  ?.group?.color,
                                                "text-black",
                                                "text-white"
                                              )
                                            : "text-black"
                                        } text-xs font-normal uppercase`}
                                      >
                                        {transaction?.description
                                          ? transaction.description
                                              .replace(/[^\w\s]/gi, "")
                                              .split(" ")
                                              .filter(Boolean)
                                              .slice(0, 2)
                                              .map((word) => word.charAt(0))
                                              .join("")
                                          : ""}
                                      </div>
                                    </div>
                                  )}
                                </div>
                              )}
                              <div className="font-normal leading-6 mr-2">
                                {transaction?.description?.length >
                                descriptionLength ? (
                                  <Tooltip
                                    content={
                                      transaction?.description?.length >
                                      descriptionLength
                                        ? (() => {
                                            const segmentLength = 75;
                                            let segments = [];
                                            let lastIndex = 0;
                                            while (
                                              lastIndex <
                                              transaction?.description.length
                                            ) {
                                              let endIndex = Math.min(
                                                lastIndex + segmentLength,
                                                transaction?.description.length
                                              );
                                              if (
                                                endIndex <
                                                  transaction?.description
                                                    .length &&
                                                transaction?.description[
                                                  endIndex
                                                ] !== " "
                                              ) {
                                                const lastSpaceIndex =
                                                  transaction?.description.lastIndexOf(
                                                    " ",
                                                    endIndex
                                                  );
                                                endIndex =
                                                  lastSpaceIndex > lastIndex
                                                    ? lastSpaceIndex
                                                    : endIndex;
                                              }
                                              segments.push(
                                                transaction?.description.substring(
                                                  lastIndex,
                                                  endIndex
                                                )
                                              );
                                              lastIndex = endIndex + 1;
                                            }
                                            return segments.map(
                                              (segment, index) => (
                                                <p key={index}>{segment}</p>
                                              )
                                            );
                                          })()
                                        : transaction?.description
                                    }
                                    contentClassName={`border-none rounded-[10px] overflow-visible text-sm text-[#667085] relative tooltipArrowDown ${
                                      transaction?.description?.length >
                                      descriptionLength
                                        ? ""
                                        : ""
                                    }`}
                                  >
                                    <span
                                      className="text-indigo-500 cursor-pointer"
                                      onClick={() =>
                                        loadSingleTransaction(transaction?.id)
                                      }
                                    >
                                      {transaction?.description?.slice(
                                        0,
                                        descriptionLength
                                      ) + "..."}
                                    </span>
                                  </Tooltip>
                                ) : (
                                  <span
                                    className="text-indigo-500 cursor-pointer"
                                    onClick={() =>
                                      loadSingleTransaction(transaction?.id)
                                    }
                                  >
                                    {transaction?.description}
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="text-slate-600 text-[14px] leading-[1.125rem] w-[20%] whitespace-nowrap">
                            {`${transaction?.account?.name} ${
                              transaction?.account?.mask
                                ? `(...${transaction?.account?.mask})`
                                : ``
                            }`}
                          </div>
                          <div className="text-slate-600 text-[14px] leading-[1.125rem] w-[20%] sm:w-[15%] whitespace-nowrap">
                            <div
                              className={`font-semibold leading-6 text-sm  ${amountColor(
                                transaction?.amount?.toString()
                              )}`}
                            >
                              {transaction?.amount?.toString().charAt(0) === "-"
                                ? ""
                                : "-"}
                              {findCurrencySymbol(transaction?.currency)}
                              {transaction?.amount?.toString().charAt(0) === "-"
                                ? parseFloat(
                                    transaction?.amount
                                      ?.toString()
                                      .replace("-", "")
                                  )?.toLocaleString(undefined, {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })
                                : parseFloat(
                                    transaction?.amount
                                  )?.toLocaleString(undefined, {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })}
                              {transaction?.currency !== team?.currency &&
                                ` (${transaction?.currency})`}
                            </div>
                          </div>
                          {!isMobile && (
                            <div className="text-slate-600 text-[14px] leading-[1.125rem] font-medium w-[20%]">
                              {transaction?.vendor?.name ||
                                transaction?.customer?.customer}
                            </div>
                          )}
                        </div>
                      );
                    })}
                    <div className="w-full flex justify-end items-center pb-4 gap-4 border-b-2 border-[#D0D5DD]">
                      <Tooltip
                        contentClassName={`border-none rounded-[10px] overflow-visible text-sm text-[#667085] relative`}
                        content="Viewer roles cannot perform this action."
                        tooltipShow={accessType === VIEWER}
                      >
                        <button
                          type="button"
                          onClick={() => handleKeepAll(matching?.id)}
                          className="whitespace-nowrap h-11 w-[7.813rem] py-2.5 px-[1.87rem] flex justify-center items-center rounded-[5px] border leading-6 border-indigo-500 bg-white text-slate-600"
                          disabled={
                            keepSelectedLoading[matching.id] ||
                            keepAllLoading[matching.id] ||
                            accessType === VIEWER
                          }
                        >
                          {keepAllLoading[matching.id] ? (
                            <Loader width="w-4" height="w-4" />
                          ) : matching?.duplicateTransactions?.length > 2 ? (
                            "Keep All"
                          ) : (
                            "Keep Both"
                          )}
                        </button>
                      </Tooltip>
                      <Tooltip
                        contentClassName={`border-none rounded-[10px] overflow-visible text-sm text-[#667085] relative right-[5.5rem]`}
                        content="Viewer roles cannot perform this action."
                        tooltipShow={accessType === VIEWER}
                      >
                        <button
                          type="button"
                          onClick={() => handleKeepSelected(matching?.id)}
                          className="whitespace-nowrap h-11 w-[7.813rem] py-2.5 px-[1.87rem] flex justify-center items-center rounded-[5px] border leading-6 border-indigo-500 bg-indigo-500 text-white "
                          disabled={
                            keepSelectedLoading[matching.id] ||
                            keepAllLoading[matching.id] ||
                            accessType === VIEWER
                          }
                        >
                          {keepSelectedLoading[matching.id] ? (
                            <Loader width="w-4" height="w-4" color="#FFFFFF" />
                          ) : (
                            "Keep Selected"
                          )}
                        </button>
                      </Tooltip>
                    </div>
                  </div>
                </div>
              );
            })}
            <div className="w-full flex justify-center mt-4 gap-2">
              <Tooltip
                contentClassName={`border-none rounded-[10px] overflow-visible text-sm text-[#667085] relative`}
                content="Viewer roles cannot perform this action."
                tooltipShow={accessType === VIEWER}
              >
                <button
                  type="button"
                  onClick={() => handleBulkKeepAll()}
                  disabled={bulkKeepAllLoading || accessType === VIEWER}
                  className="whitespace-nowrap w-[11.25rem] h-11 py-2.5 px-[1.87rem] flex justify-center items-center rounded-[5px] border leading-6 border-indigo-500 bg-white text-slate-600"
                >
                  {bulkKeepAllLoading ? (
                    <Loader width="w-4" height="w-4" />
                  ) : (
                    "Keep All"
                  )}
                </button>
              </Tooltip>
              <Tooltip
                contentClassName={`border-none rounded-[10px] overflow-visible text-sm text-[#667085] relative`}
                content="Viewer roles cannot perform this action."
                tooltipShow={accessType === VIEWER}
              >
                <button
                  type="button"
                  onClick={() => handleKeepAllSelected()}
                  disabled={
                    Object.keys(selectedTransactions).length === 0 ||
                    keepAllSelectedLoading ||
                    accessType === VIEWER
                  }
                  className="whitespace-nowrap w-[11.25rem] h-11 py-2.5 px-[1.87rem] flex justify-center items-center rounded-[5px] border leading-6 border-indigo-500 bg-indigo-500 text-white"
                >
                  {keepAllSelectedLoading ? (
                    <Loader width="w-4" height="w-4" color="#FFFFFF" />
                  ) : (
                    "Keep All Selected"
                  )}
                </button>
              </Tooltip>
            </div>
          </>
        )
      )}
      {matchings?.totalRecords === 0 && !isLoading && (
        <div className="flex flex-col gap-5 items-center justify-center w-full h-full">
          <h6 className="text-center mt-4">No Duplicates Found</h6>
          <button
            className="h-11 bg-indigo-500 rounded-[5px] text-white whitespace-nowrap w-[30%] max-w-[200px]"
            onClick={() => {
              navigate("/transactions");
            }}
          >
            Back to Transactions
          </button>
        </div>
      )}
      {!isLoading && totalRecords > 0 && (
        <div className="mt-10">
          <PaginationClassic
            pagination={{ limit: localLimit, page, totalRecords }}
            setLimit={setLocalLimit}
            onPageChange={loadTransactionsApi}
          />
        </div>
      )}
    </>
  );
};

export default DuplicateTransactions;
