import React, { useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import Logo from '../../../images/custom/count-logo.png';
import { signUp } from '../../../API/backend_helper';
import AuthSlider from '../../../components/AuthSlider';
import Form1 from './Form1';
import Form2 from './Form2';

const BUSINESS_ACCOUNT = 'business';
const BOOKKEEPER_ACCOUNT = 'bookkeeper';

function Signup() {
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const defaultMail = decodeURI(searchParams.get('email') || '');

  const [loading, setLoading] = useState(false);
  const [form1Data, setForm1Data] = useState({});
  const [currentStep, setCurrentStep] = useState(1);
  const [accountType, setAccountType] = useState(BUSINESS_ACCOUNT);

  document.title = 'COUNT | Sign Up';

  const handleSignUp = async () => {
    const data = {
      firstName: form1Data?.firstName,
      lastName: form1Data?.lastName,
      email: form1Data?.email?.toLowerCase(),
      phone: form1Data?.phone,
      password: form1Data?.password,
    };

    if (accountType === BOOKKEEPER_ACCOUNT) {
      data.realm = 'accounting-firm';
      data.isAccountingFirmUser = true;
    }

    setLoading(true);
    try {
      const response = await signUp(data);
      if (typeof response === 'string') {
        toast.error(response);
      } else {
        navigate(`/verify-email?email=${encodeURIComponent(form1Data?.email)}`);
      }
    } catch (e) {
      console.log('error', e);
    } finally {
      setLoading(false);
    }
  };

  const onSubmit = (values) => {
    setForm1Data(values);
    setCurrentStep(2);
  };

  return (
    <main className="bg-white dark:bg-slate-900">
      <div className="relative md:flex">
        {/* Content */}
        <div className="md:w-1/2">
          <div className="min-h-[100dvh] h-full flex flex-col after:flex-1">
            {/* Header */}
            <div className="flex-1">
              <div className="relative flex items-center justify-between h-16 px-4 sm:px-6 lg:px-8">
                {/* Logo */}
                <Link className="block" to="/">
                  <img src={Logo} alt="logo" className="h-8" />
                </Link>
                {currentStep === 2 && (
                  <button
                    onClick={() => {
                      setCurrentStep(+currentStep - 1);
                    }}
                    className="absolute top-24 left-4 md:left-8 h-8 w-8 bg-[#FBF3ED] rounded inline-flex justify-center items-center"
                    type="button"
                  >
                    <svg
                      width="10"
                      height="17"
                      viewBox="0 0 10 17"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8 2.5L2 8.5L8 14.5"
                        stroke="#E48642"
                        strokeWidth="3.6"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </button>
                )}
              </div>
            </div>

            <div className="max-w-[476px] min-w-[50%] mx-auto w-full px-4 py-8">
              <h1 className="text-[40px] leading-tight font-medium text-[#232323CC] mb-3 ">
                {currentStep === 1
                  ? 'Sign up'
                  : 'Tell Us Which Account You Want To Create'}
              </h1>
              <p className="text-lg text-[#667085CC] mb-9">
                {currentStep === 1
                  ? 'Sign up to enjoy the features of COUNT'
                  : 'Select the account that suits you the most'}
              </p>
              {/* Form */}
              {currentStep === 1 && (
                <Form1
                  defaultMail={defaultMail}
                  onSubmit={onSubmit}
                  form1Data={form1Data}
                />
              )}
              {currentStep === 2 && (
                <Form2
                  accountType={accountType}
                  setAccountType={setAccountType}
                  BUSINESS_ACCOUNT={BUSINESS_ACCOUNT}
                  BOOKKEEPER_ACCOUNT={BOOKKEEPER_ACCOUNT}
                  handleSignUp={handleSignUp}
                  loading={loading}
                />
              )}

              {/* Footer */}
              {/* Divider  Line */}
              <div className="flex w-full mt-7 items-center gap-3 mb-6 !hidden">
                <div className="h-[1px] flex-1 bg-[#D9D9D9]" />
                <span className="text-[#6E6E6E] font-medium">or</span>
                <div className="h-[1px] flex-1 bg-[#D9D9D9]" />
              </div>
              {/* Social Login Buttons  */}
              <div className="flex justify-between gap-2 flex-wrap !hidden">
                <button
                  className="mx-auto btn gap-3 whitespace-nowrap text-base py-[15px] px-5 bg-white border border-[#E6E8E7] rounded-[10px]"
                  type="button"
                >
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M17.16 9.31774C17.16 8.71501 17.1059 8.13547 17.0055 7.5791H9V10.8671H13.5746C13.3775 11.9296 12.7786 12.8298 11.8784 13.4325V15.5653H14.6255C16.2327 14.0855 17.16 11.9064 17.16 9.31774Z"
                      fill="#4285F4"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M8.99826 17.6252C11.2933 17.6252 13.2174 16.8641 14.6237 15.5659L11.8767 13.4332C11.1155 13.9432 10.1419 14.2446 8.99826 14.2446C6.7844 14.2446 4.91053 12.7493 4.24212 10.7402H1.40234V12.9425C2.80098 15.7205 5.67553 17.6252 8.99826 17.6252Z"
                      fill="#34A853"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M4.24387 10.7399C4.07387 10.2299 3.97728 9.68512 3.97728 9.1249C3.97728 8.56467 4.07387 8.01989 4.24387 7.50989V5.30762H1.40409C0.82841 6.45512 0.5 7.7533 0.5 9.1249C0.5 10.4965 0.82841 11.7947 1.40409 12.9422L4.24387 10.7399Z"
                      fill="#FBBC05"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M8.99826 4.00569C10.2462 4.00569 11.3667 4.43455 12.2476 5.27683L14.6855 2.83887C13.2135 1.46727 11.2894 0.625 8.99826 0.625C5.67553 0.625 2.80098 2.52978 1.40234 5.30773L4.24212 7.51001C4.91053 5.50092 6.7844 4.00569 8.99826 4.00569Z"
                      fill="#EA4335"
                    />
                  </svg>{' '}
                  Sign in with Google
                </button>
                <button
                  className="mx-auto btn gap-3 whitespace-nowrap text-base py-[15px] px-5 bg-white border border-[#E6E8E7] rounded-[10px]"
                  type="button"
                >
                  <svg
                    width="24"
                    height="25"
                    viewBox="0 0 24 25"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M19.1001 19.285C19.6901 18.385 19.9101 17.925 20.3601 16.915C17.0401 15.655 16.5101 10.925 19.7901 9.11499C18.7901 7.85499 17.3801 7.125 16.0501 7.125C15.0901 7.125 14.4301 7.37501 13.8401 7.60501C13.3401 7.79501 12.8901 7.965 12.3301 7.965C11.7301 7.965 11.2001 7.77501 10.6401 7.57501C10.0301 7.35501 9.39006 7.125 8.59006 7.125C7.10006 7.125 5.51007 8.035 4.50007 9.595C3.08007 11.795 3.33007 15.915 5.62007 19.435C6.44007 20.695 7.54007 22.105 8.97007 22.125C9.57007 22.135 9.96007 21.955 10.3901 21.765C10.8801 21.545 11.4101 21.305 12.3401 21.305C13.2701 21.295 13.7901 21.545 14.2801 21.765C14.7001 21.955 15.0801 22.135 15.6701 22.125C17.1201 22.105 18.2801 20.545 19.1001 19.285Z"
                      fill="#101828"
                      fillOpacity="0.8"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M15.8404 2.125C16.0004 3.225 15.5504 4.31501 14.9604 5.07501C14.3304 5.89501 13.2304 6.535 12.1704 6.495C11.9804 5.435 12.4704 4.34499 13.0704 3.61499C13.7404 2.81499 14.8704 2.195 15.8404 2.125Z"
                      fill="#101828"
                      fillOpacity="0.8"
                    />
                  </svg>{' '}
                  Sign in with Apple
                </button>
              </div>
              <div className="mt-8 text-center">
                <div className="text-base">
                  Already have an account?{' '}
                  <Link
                    className="font-medium underline ml-1 text-indigo-500 hover:text-indigo-600 dark:hover:text-indigo-400"
                    to="/signin"
                  >
                    Sign in
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Auth Slider */}

        <AuthSlider />
      </div>
    </main>
  );
}

export default Signup;
