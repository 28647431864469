import React, { useState } from "react";
import ModalBasic from "../../components/ModalBasic";

const StaffModal = ({ transaction, isOpen, setIsOpen }) => {
  const localTransaction = transaction;

  const handleModalClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  return (
    <div>
      <ModalBasic
        title="Staff Information"
        modalOpen={isOpen}
        setModalOpen={setIsOpen}
        width="max-w-4xl"
      >
        <pre onClick={handleModalClick}>{JSON.stringify(localTransaction, null, 2)}</pre>
      </ModalBasic>
    </div>
  );
};

export default StaffModal;
