import React, { useEffect, useState } from 'react';
import ModalBasic from '../../components/ModalBasic';
import { updateAccountById } from '../../API/backend_helper';
import { Loader } from '../../components/Svg';

const SimpleAccountModal = ({ modalOpen, setModalOpen, account, loadData }) => {
  const [name, setName] = useState(account?.name);
  const [nameError, setNameError] = useState(false);
  const [localAccount, setLocalAccount] = useState(account);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (account) {
      setName(account?.name);
      setLocalAccount(account);
    }
  }, [account]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!name) {
      setNameError(true);
      return;
    }
    setLoading(true);
    await updateAccountById(localAccount?.id, {
      name,
    });
    loadData(true);
    setLoading(false);
    setName('');
    setModalOpen(false);
  };

  return (
    <ModalBasic
      smallModal
      modalOpen={modalOpen}
      setModalOpen={setModalOpen}
      title="Update Display Name"
    >
      <form onSubmit={handleSubmit} className="flex  justify-between flex-col">
        <div className="px-5 pt-4 pb-1">
          <label className="block text-sm font-medium mb-1">Name</label>
          <div className="relative flex">
            <input
              value={name}
              onChange={(e) => {
                if (e.target.value) {
                  setNameError(false);
                }
                setName(e.target.value);
              }}
              className={`form-input w-full ${
                nameError && !name && 'border-rose-300'
              }`}
              type="text"
              placeholder="Name"
              style={{
                borderTopLeftRadius: '0',
                borderBottomLeftRadius: '0',
              }}
              onBlur={() => {
                if (!name) {
                  setNameError(true);
                }
              }}
            />
          </div>
          {nameError && !name ? (
            <div className="text-xs mt-1 text-rose-500">Name is required.</div>
          ) : null}
        </div>

        {/* Modal footer */}
        <div className="flex flex-wrap  justify-end space-x-2 px-5 py-4 mt-3">
          <button
            type="submit"
            className="py-2.5 px-[1.875rem] h-11 w-[7rem] bg-indigo-500 text-center text-white text-base leading-6 font-base rounded-[0.313rem]
            disabled:bg-indigo-100  disabled:text-slate-400  disabled:cursor-not-allowed"
            disabled={loading}
          >
            {loading ? (
              <div className="flex items-center justify-center">
                <Loader width="w-6" height="h-6" />
              </div>
            ) : (
              <span>Update</span>
            )}
          </button>
        </div>
      </form>
    </ModalBasic>
  );
};

export default SimpleAccountModal;
