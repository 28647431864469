import React, { useState, useEffect } from 'react';
import { CloseBtn, Cog, Cube } from '../svg';

const Component1 = ({
  setPanelStep,
  createNewNode,
  setWorkingNodeId,
  isEditing,
  setIsEditing,
  isInBetween,
  setIsInBetween,
  createNodeBetween,
  activeNodeId,
  nodes,
  setActiveNodeId,
  setPanelOpen,
}) => {
  const [actionSelected, setActionSelected] = useState(false);
  const [conditionSelected, setConditionSelected] = useState(false);
  const [displayCondition, setDisplayCondition] = useState(true);

  useEffect(() => {
    if (!isEditing && !isInBetween?.source) {
      setActiveNodeId(nodes.find((node) => node.type === 'placeholder')?.id);
    }
    // Prevent creating a condition if there is an action node physically above the current node
    const checkForActionNode = (currentNodeId, searchingNodes) => {
      const currentIndex = searchingNodes.findIndex(
        (node) => node.id === currentNodeId,
      );

      for (let i = 0; i < currentIndex; i++) {
        if (searchingNodes[i].type === 'action') {
          return true;
        }
      }
      return false;
    };

    const currentNodeId = activeNodeId || isInBetween?.source;
    const searchingNodes = nodes.sort((a, b) => a.position - b.position);

    if (currentNodeId) {
      const currentNode = nodes.find((node) => node.id === currentNodeId);
      if (currentNode.type === 'action') {
        setDisplayCondition(false);
        return;
      }
      const hasActionNodeBefore = checkForActionNode(
        currentNodeId,
        searchingNodes,
      );
      setDisplayCondition(!hasActionNodeBefore);
    } else {
      setDisplayCondition(true);
    }
  }, []);

  const handleNextClick = (type) => {
    const newNodeData = {
      name: type === 'action' ? 'Then:' : 'If:',
      type: type === 'action' ? 'action' : 'condition',
      description: '',
    };

    if (isInBetween?.source && isInBetween?.target) {
      const newNodeId = createNodeBetween(
        isInBetween.source,
        isInBetween.target,
        newNodeData,
      );
      setIsInBetween({ source: newNodeId, target: isInBetween.target });
      setIsEditing(true);
    } else {
      createNewNode(activeNodeId, newNodeData);
    }

    setWorkingNodeId(activeNodeId);
    setPanelStep(type === 'action' ? 'action1' : 'condition1');
  };

  return (
    <>
      <div className="flex items-center w-full justify-between">
        <p className="text-black text-[1.375rem] leading-[2.375rem] font-medium text-left w-full">
          Add a Component
        </p>
        <CloseBtn onClick={() => setPanelOpen(false)} />
      </div>
      <p className="text-slate-600 leading-6 text-[14px] w-full text-left">
        Required
      </p>
      <div
        className={`flex flex-col items-start gap-2.5 self-stretch rounded-[15px] p-[0.938rem] cursor-pointer hover:bg-[#fbfbfb] ${
          actionSelected ? 'bg-[#F2F2F2]' : ''
        }`}
        onClick={() => {
          setActionSelected(true);
          handleNextClick('action');
        }}
        role="button"
        tabIndex="0"
      >
        <div className="flex items-start gap-2.5 self-stretch">
          <div className="bg-[#FCF1E9] rounded-[10px] gap-2.5 px-2.5 py-[0.938rem] w-11 h-11 flex items-center justify-center">
            <Cog />
          </div>
          <div className="flex flex-col items-start -mt-1">
            <p className="text-black text-[1rem]">THEN: Add an Action</p>
            <p className="text-slate-600 text-[14px]">
              Actions will execute when your rule runs successfully.
            </p>
          </div>
        </div>
      </div>
      {displayCondition && (
        <>
          <p className="text-slate-600 leading-6 text-[14px] w-full text-left">
            Optional
          </p>
          <div
            className={`flex flex-col items-start gap-2.5 self-stretch rounded-[15px] p-[0.938rem] cursor-pointer hover:bg-[#fbfbfb] ${
              conditionSelected ? 'bg-[#F2F2F2]' : ''
            }`}
            onClick={() => {
              setConditionSelected(true);
              handleNextClick('condition');
            }}
            role="button"
            tabIndex="0"
          >
            <div className="flex items-start gap-2.5 self-stretch">
              <div className="bg-[#E7F0FE] rounded-[10px] gap-2.5 px-2.5 py-[0.938rem] w-11 h-11 flex items-center justify-center">
                <Cube />
              </div>
              <div className="flex flex-col items-start -mt-1">
                <p className="text-black text-[1rem]">IF: Add a Condition</p>
                <p className="text-slate-600 text-[14px]">
                  Conditions limit the scope to specific user groups or
                  keywords. You can make your rule run a specific path,
                  depending on which condition is met.{' '}
                </p>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Component1;
