import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Transition from '../utils/Transition';
import { VIEWER, ACCOUNTANT, IS_ACCOUNTANT } from '../utils/Utils';

function ManageTeamDropdown({
  options,
  selected,
  setSelected,
  name,
  error = false,
  disabled = false,
  className = '',
}) {
  const navigate = useNavigate();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const { accessType } = useSelector((state) => state.User);

  const trigger = useRef(null);
  const dropdown = useRef(null);
  const IsAccountant = localStorage.getItem(IS_ACCOUNTANT)
    ? JSON.parse(localStorage.getItem(IS_ACCOUNTANT))
    : null;

  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!dropdown.current) return;
      if (
        !dropdownOpen ||
        dropdown.current.contains(target) ||
        trigger.current.contains(target)
      )
        return;
      setDropdownOpen(false);
    };
    document.addEventListener('click', clickHandler);
    return () => document.removeEventListener('click', clickHandler);
  });

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!dropdownOpen || keyCode !== 27) return;
      setDropdownOpen(false);
    };
    document.addEventListener('keydown', keyHandler);
    return () => document.removeEventListener('keydown', keyHandler);
  });

  const OptionsToMap = ({ options }) =>
    options?.map((option) => (
      <button
        type="button"
        key={option.id}
        tabIndex="0"
        className={`flex items-center whitespace-nowrap  gap-2 border-none  w-full text-[#667085]  hover:text-slate-800 py-1 px-3 cursor-pointer ${
          option.id === selected && 'text-indigo-500 hover:!text-indigo-600'
        }`}
        onClick={() => {
          setSelected(option);
          setDropdownOpen(false);
        }}
      >
        <span className="truncate">{option[name]}</span>
        <svg
          className={`shrink-0 mr-2 fill-current text-indigo-500 ${
            option.id !== selected && 'invisible'
          }`}
          width="12"
          height="9"
          viewBox="0 0 12 9"
        >
          <path d="M10.28.28L3.989 6.575 1.695 4.28A1 1 0 00.28 5.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28.28z" />
        </svg>
      </button>
    ));

  return (
    <div className={`relative inline-flex w-full ${className}`}>
      <button
        type="button"
        ref={trigger}
        className={`  btn w-full justify-between min-w-44 bg-white dark:bg-slate-800 border-[#D0D5DD] hover:border-slate-400  dark:border-slate-700
        dark:hover:border-slate-600 text-[#667085CC] rounded-md
         dark:hover:text-slate-200  whitespace-nowrap ${
           error ? '!border-rose-300' : ''
         }
         ${
           dropdownOpen
             ? 'hover:!border-[#D0D5DD]  hover:!border-b-transparent  !border-b-transparent  rounded-b-none'
             : ''
         } `}
        aria-label="Select date range"
        aria-haspopup="true"
        onClick={() => setDropdownOpen(!dropdownOpen)}
        aria-expanded={dropdownOpen}
        disabled={disabled}
      >
        <span className="flex items-center truncate">
          <span>
            {options?.find((opt) => opt.id === selected)?.[name] || 'Select...'}
          </span>
        </span>
        <svg
          width="10"
          height="5"
          viewBox="0 0 10 5"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={`shrink-0 ml-1 stroke-[#667085CC]  ${
            dropdownOpen ? 'rotate-180' : ''
          } ${error ? 'fill-rose-300' : ''}`}
        >
          <path
            d="M9 1L5.70707 3.7559C5.31818 4.08137 4.68182 4.08137 4.29293 3.7559L1 1"
            stroke="#667085"
            strokeOpacity="0.8"
            strokeWidth="1.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="stroke-current"
          />
        </svg>
      </button>
      <Transition
        show={dropdownOpen}
        tag="div"
        className="z-10 absolute top-full rounded-t-none border-t-0 left-0 w-full bg-white dark:bg-slate-800 border border-[#D0D5DD] dark:border-slate-700 py-1.5 rounded-md shadow-lg overflow-hidden"
        enter="transition ease-out duration-100 transform"
        enterStart="opacity-0 -translate-y-2"
        enterEnd="opacity-100 translate-y-0"
        leave="transition ease-out duration-100"
        leaveStart="opacity-100"
        leaveEnd="opacity-0"
      >
        {dropdownOpen && (
          <div
            ref={dropdown}
            className="font-medium 	 text-sm text-slate-600 dark:text-slate-300 divide-y divide-slate-200 dark:divide-slate-700"
            onFocus={() => setDropdownOpen(true)}
            onBlur={() => setDropdownOpen(false)}
          >
            <div className="max-h-48 overflow-auto scrollbar">
              <OptionsToMap options={options} />
            </div>
            <div className="mx-4 mt-2">
              {accessType !== VIEWER && accessType !== ACCOUNTANT && (
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    navigate('/manage-workspace/details');
                    setDropdownOpen(false);
                  }}
                  className=" group whitespace-nowrap text-[#667085]  hover:text-slate-800 btn w-full inline-flex gap-2.5 justify-start px-0 border-b-0 border-l-0 border-r-0 shadow-none  border-[#D0D5DD66] bg-transparent"
                  type="button"
                >
                  <svg
                    width="15"
                    height="14"
                    viewBox="0 0 15 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="text-[#667085] group-hover:text-slate-800 "
                  >
                    <path
                      d="M14.0312 0.5625H0.96875C0.811278 0.5625 0.660255 0.625056 0.548905 0.736405C0.437556 0.847755 0.375 0.998778 0.375 1.15625V12.4375C0.375 12.7524 0.500111 13.0545 0.722811 13.2772C0.94551 13.4999 1.24756 13.625 1.5625 13.625H4.53125C4.84619 13.625 5.14824 13.4999 5.37094 13.2772C5.59364 13.0545 5.71875 12.7524 5.71875 12.4375V8.875H9.28125V10.0625C9.28125 10.3774 9.40636 10.6795 9.62906 10.9022C9.85176 11.1249 10.1538 11.25 10.4688 11.25H13.4375C13.7524 11.25 14.0545 11.1249 14.2772 10.9022C14.4999 10.6795 14.625 10.3774 14.625 10.0625V1.15625C14.625 0.998778 14.5624 0.847755 14.4511 0.736405C14.3397 0.625056 14.1887 0.5625 14.0312 0.5625ZM4.53125 12.4375H1.5625V6.5H4.53125V12.4375ZM4.53125 5.3125H1.5625V1.75H4.53125V5.3125ZM9.28125 7.6875H5.71875V1.75H9.28125V7.6875ZM13.4375 10.0625H10.4688V6.5H13.4375V10.0625ZM13.4375 5.3125H10.4688V1.75H13.4375V5.3125Z"
                      fill="#667085"
                      className="fill-current"
                    />
                  </svg>
                  <span className="text-[13px]">Edit Workspace</span>
                </button>
              )}
              {accessType !== VIEWER && accessType !== ACCOUNTANT && (
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    navigate('/manage-workspace/members');
                    setDropdownOpen(false);
                  }}
                  className=" group whitespace-nowrap text-[#667085]  hover:text-slate-800 btn w-full inline-flex gap-2.5 justify-start px-0 shadow-none bg-transparent"
                  type="button"
                >
                  <svg
                    width="16"
                    height="15"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="text-[#667085] group-hover:text-slate-800 "
                  >
                    <path
                      d="M7.63338 9.05768C7.55005 9.04935 7.45005 9.04935 7.35838 9.05768C5.37505 8.99102 3.80005 7.36602 3.80005 5.36602C3.80005 3.32435 5.45005 1.66602 7.50005 1.66602C9.54172 1.66602 11.2 3.32435 11.2 5.36602C11.1917 7.36602 9.61672 8.99102 7.63338 9.05768Z"
                      stroke="#667085"
                      strokeWidth="1.3"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="stroke-current"
                    />
                    <path
                      d="M13.675 3.33398C15.2916 3.33398 16.5916 4.64232 16.5916 6.25065C16.5916 7.82565 15.3417 9.10898 13.7833 9.16732C13.7167 9.15898 13.6417 9.15898 13.5667 9.16732"
                      stroke="#667085"
                      strokeWidth="1.3"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="stroke-current"
                    />
                    <path
                      d="M3.46672 12.134C1.45006 13.484 1.45006 15.684 3.46672 17.0257C5.75839 18.559 9.51672 18.559 11.8084 17.0257C13.8251 15.6757 13.8251 13.4757 11.8084 12.134C9.52506 10.609 5.76672 10.609 3.46672 12.134Z"
                      stroke="#667085"
                      strokeWidth="1.3"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="stroke-current"
                    />
                    <path
                      d="M15.2833 16.666C15.8833 16.541 16.45 16.2993 16.9167 15.941C18.2167 14.966 18.2167 13.3577 16.9167 12.3827C16.4583 12.0327 15.9 11.7993 15.3083 11.666"
                      stroke="#667085"
                      strokeWidth="1.3"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="stroke-current"
                    />
                  </svg>
                  <span className="text-[13px]">Manage Team</span>
                </button>
              )}
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  navigate(
                    IsAccountant ? '/accountant/clients' : '/manage-workspaces',
                  );
                  setDropdownOpen(false);
                }}
                className="group  whitespace-nowrap text-[#667085]  hover:text-slate-800 btn w-full inline-flex gap-2.5 justify-start px-0 border-t-[2px] border-b-0 border-l-0 border-r-0 shadow-none  border-[#D0D5DD66] bg-transparent"
                type="button"
              >
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 19 19"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="text-[#667085] group-hover:text-slate-800 "
                >
                  <path
                    d="M15.0423 7.08366C15.4565 7.08366 15.7923 6.74787 15.7923 6.33366C15.7923 5.91945 15.4565 5.58366 15.0423 5.58366V7.08366ZM5.37569 4.27362L4.84773 3.74093L4.84773 3.74093L5.37569 4.27362ZM7.0202 3.69968C7.3144 3.4081 7.31652 2.93323 7.02493 2.63903C6.73335 2.34484 6.25848 2.34272 5.96428 2.6343L7.0202 3.69968ZM13.7841 14.727L14.3121 15.2597L13.7841 14.727ZM12.1396 15.301C11.8454 15.5926 11.8433 16.0674 12.1349 16.3616C12.4265 16.6558 12.9014 16.6579 13.1955 16.3664L12.1396 15.301ZM15.1883 12.8654L15.9323 12.9603L15.1883 12.8654ZM3.95899 11.917C3.54477 11.917 3.20899 12.2528 3.20899 12.667C3.20899 13.0812 3.54477 13.417 3.95899 13.417V11.917ZM15.1929 12.8253L15.9391 12.901L15.1929 12.8253ZM4.15796 7.08366H15.0423V5.58366H4.15796V7.08366ZM5.90365 4.80631L7.0202 3.69968L5.96428 2.6343L4.84773 3.74093L5.90365 4.80631ZM4.84773 3.74093C4.41505 4.16977 4.04472 4.53522 3.78063 4.86405C3.50704 5.20473 3.28646 5.57796 3.22752 6.04038L4.71548 6.23004C4.72509 6.15465 4.7614 6.03834 4.95016 5.8033C5.14843 5.55643 5.44591 5.25998 5.90365 4.80631L4.84773 3.74093ZM13.2562 14.1943L12.1396 15.301L13.1955 16.3664L14.3121 15.2597L13.2562 14.1943ZM14.3121 15.2597C14.7448 14.8309 15.1151 14.4654 15.3792 14.1366C15.6528 13.7959 15.8734 13.4227 15.9323 12.9603L14.4443 12.7706C14.4347 12.846 14.3984 12.9623 14.2097 13.1974C14.0114 13.4442 13.7139 13.7407 13.2562 14.1943L14.3121 15.2597ZM14.4467 12.7497C14.446 12.7566 14.4452 12.7636 14.4443 12.7706L15.9323 12.9603C15.9348 12.9405 15.9371 12.9208 15.9391 12.901L14.4467 12.7497ZM15.0423 11.917H3.95899V13.417H15.0423V11.917ZM15.9391 12.901C15.9979 12.3211 15.5213 11.917 15.0423 11.917L15.0423 13.417C14.7386 13.417 14.4056 13.1553 14.4467 12.7497L15.9391 12.901ZM4.15796 5.58366C4.43213 5.58366 4.76697 5.82613 4.71548 6.23004L3.22752 6.04039C3.14825 6.66231 3.66401 7.08366 4.15796 7.08366V5.58366Z"
                    fill="#667085"
                    className="stroke-current fill-current"
                  />
                </svg>{' '}
                <span className="text-[13px]"> Switch Workspace</span>
              </button>
            </div>
          </div>
        )}
      </Transition>
    </div>
  );
}

export default ManageTeamDropdown;
