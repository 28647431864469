import React from 'react';
import DropdownFull from '../../components/DropdownFull';
import CategoriesDropdown from '../transactions/CategoriesDropdown';

const Step2 = ({
  mapping,
  setMapping,
  keyArray,
  parsedInfo,
  accounts,
  selectedAccount,
  setSelectedAccount,
  error,
  setError,
}) => {
  const { total, transactions } = parsedInfo;

  const dropdownHeaders = [
    { name: 'Authorized Date', id: 'authorizedDate' },
    { name: 'Amount', id: 'amount' },
    { name: 'Description', id: 'description' },
    { name: 'Category', id: 'category' },
    { name: 'Vendor', id: 'vendor' },
    { name: 'Customer', id: 'customer' },
    { name: 'Currency', id: 'currency' },
    { name: 'Notes', id: 'notes' },
  ];

  return (
    <div className="">
      <h3 className="text-2xl font-semibold">Map your fields</h3>
      <p className="text-base text-slate-600 mb-3">
        Please use the dropdown menus in the header row to map each column to
        the appropriate field. Ensure that Authorized Date, Amount, and
        Description are selected. You can choose the "--" option to skip any
        unnecessary columns. Below you will see the first 6 transactions
        displayed, with a summary indicating the total number of transactions
        being imported.
      </p>
      <div className="w-full flex justify-between items-center">
        <div className="w-[25%] 2xl:w-[20%] mb-4 flex flex-col gap-1">
          <p>Save Transactions to Account:</p>
          <CategoriesDropdown
            allCategories={accounts}
            selectedCategoryId={selectedAccount}
            setSelectedCategoryId={(value) => {
              setSelectedAccount(value);
              if (error === 'Please select an account to proceed') {
                setError('');
              }
            }}
            isSetCategoryStyle
            id="accountId"
            name="accountId"
            height="h-10"
            type="account"
          />
          {error && <p className="text-rose-500">{error}</p>}
        </div>
      </div>
      <div className="bg-transparent w-full rounded-[5px]  my-5 dark:bg-transparent border border-[#D0D5DD] " />
      <div className="w-full items-start flex overflow-x-auto scrollbar">
        <table className="min-w-max w-full flex flex-col items-start">
          <thead className="flex w-full gap-1 items-center justify-around rounded-[0.313rem] shadow-md bg-white min-h-[3.5rem] mb-1">
            {keyArray.map((key, index) => (
              <th
                key={index}
                className="text-slate-600 text-left text-[14px] font-medium leading-[1.125rem] w-[10dvw] flex-shrink-0 flex-grow-0 whitespace-nowrap "
              >
                {key.name}
              </th>
            ))}
          </thead>
          <tr className="flex w-full items-center gap-1 justify-around bg-white min-h-[3.5rem] border-b border-[#D0D5DD] hover:bg-[#a0cd85] hover:bg-opacity-5 cursor-pointer">
            {keyArray.map((key, index) => (
              <td
                key={index}
                className="text-left text-[14px] font-medium leading-[1.125rem] w-[10dvw] flex-shrink-0 flex-grow-0 whitespace-nowrap"
              >
                <DropdownFull
                  height="h-10"
                  options={[{ name: '--', id: '--' }, ...dropdownHeaders]}
                  name="name"
                  noMinW
                  selected={
                    Object.keys(mapping).find(
                      (key) => mapping[key] === keyArray[index]?.name,
                    ) || '--'
                  }
                  setSelected={(id) => {
                    const newMapping = { ...mapping };

                    const previousKey = Object.keys(newMapping).find(
                      (key) => newMapping[key] === keyArray[index]?.name,
                    );
                    if (previousKey) {
                      delete newMapping[previousKey];
                    }

                    newMapping[id] = keyArray[index]?.name;
                    setMapping(newMapping);
                  }}
                  buttonTextClass="truncate"
                  transitionClass="!min-w-fit"
                />
              </td>
            ))}
          </tr>
          {transactions &&
            transactions.map((transaction, index) => (
              <tr
                key={index}
                className="flex w-full items-center gap-1 justify-around bg-white h-[3.5rem] border-b border-[#D0D5DD] hover:bg-[#a0cd85] hover:bg-opacity-5"
              >
                {keyArray.map((key, index) => (
                  <td
                    key={index}
                    className="text-left text-[14px] font-medium leading-[1.125rem] w-[10dvw] flex-shrink-0 flex-grow-0 truncate"
                  >
                    {transaction[key.id]}
                  </td>
                ))}
              </tr>
            ))}
        </table>
      </div>
      {total > 6 && (
        <p className="text-base font-medium w-full text-center">
          ...showing 6 of {total} transactions{' '}
        </p>
      )}
    </div>
  );
};

export default Step2;
