export const EmptySelect = () => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="0.5" y="0.5" width="21" height="21" rx="5.5" fill="white" />
    <rect x="0.5" y="0.5" width="21" height="21" rx="5.5" stroke="#D0D5DD" />
  </svg>
);

export const HighPriority = () => (
  <svg
    width="11"
    height="11"
    viewBox="0 0 11 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="flex-shrink-0"
  >
    <circle cx="5.46094" cy="5.5" r="5" fill="#FA8E8E" />
  </svg>
);

export const MediumPriority = () => (
  <svg
    width="11"
    height="10"
    viewBox="0 0 11 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="flex-shrink-0"
  >
    <circle cx="5.46094" cy="5" r="5" fill="#EDB900" />
  </svg>
);

export const LowPriority = () => (
  <svg
    width="11"
    height="10"
    viewBox="0 0 11 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="flex-shrink-0"
  >
    <circle cx="5.46094" cy="5" r="5" fill="#40AA00" />
  </svg>
);

export const PaperClipSquare = () => (
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="flex-shrink-0"
  >
    <path
      d="M10.1 27H17.9C24.4 27 27 24.4 27 17.9V10.1C27 3.6 24.4 1 17.9 1H10.1C3.6 1 1 3.6 1 10.1V17.9C1 24.4 3.6 27 10.1 27Z"
      fill="white"
      stroke="#D0D5DD"
      strokeOpacity="0.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20.4029 12.9061L14.6591 18.6498C13.9555 19.3535 13.0011 19.7488 12.006 19.7488C11.0109 19.7488 10.0565 19.3535 9.35287 18.6498C8.64921 17.9462 8.25391 16.9918 8.25391 15.9967C8.25391 15.0016 8.64921 14.0472 9.35287 13.3436L15.0966 7.59983C15.5657 7.13073 16.202 6.86719 16.8654 6.86719C17.5288 6.86719 18.165 7.13073 18.6341 7.59983C19.1032 8.06893 19.3668 8.70517 19.3668 9.36858C19.3668 10.032 19.1032 10.6682 18.6341 11.1373L12.8841 16.8811C12.6496 17.1156 12.3314 17.2474 11.9997 17.2474C11.668 17.2474 11.3499 17.1156 11.1154 16.8811C10.8808 16.6465 10.749 16.3284 10.749 15.9967C10.749 15.665 10.8808 15.3469 11.1154 15.1123L16.4216 9.81233"
      stroke="#667085"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const SmallCheckedSquare = () => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_4760_36003)">
      <path
        d="M4.5 5.5L6 7L11 2M8 1.5H3.9C3.05992 1.5 2.63988 1.5 2.31901 1.66349C2.03677 1.8073 1.8073 2.03677 1.66349 2.31901C1.5 2.63988 1.5 3.05992 1.5 3.9V8.1C1.5 8.94008 1.5 9.36012 1.66349 9.68099C1.8073 9.96323 2.03677 10.1927 2.31901 10.3365C2.63988 10.5 3.05992 10.5 3.9 10.5H8.1C8.94008 10.5 9.36012 10.5 9.68099 10.3365C9.96323 10.1927 10.1927 9.96323 10.3365 9.68099C10.5 9.36012 10.5 8.94008 10.5 8.1V6"
        stroke="#E48642"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_4760_36003">
        <rect width="12" height="12" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const TrashSquare = () => (
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.1 27H17.9C24.4 27 27 24.4 27 17.9V10.1C27 3.6 24.4 1 17.9 1H10.1C3.6 1 1 3.6 1 10.1V17.9C1 24.4 3.6 27 10.1 27Z"
      fill="white"
      stroke="#D0D5DD"
      strokeOpacity="0.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.6078 9.87474C17.4503 9.66093 15.2797 9.55078 13.1157 9.55078C11.8328 9.55078 10.5499 9.61557 9.26706 9.74516L7.94531 9.87474"
      stroke="#667085"
      strokeOpacity="0.8"
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.5078 9.22021L11.6504 8.37144C11.754 7.75592 11.8318 7.2959 12.9267 7.2959H14.6243C15.7193 7.2959 15.8035 7.78184 15.9007 8.37792L16.0432 9.22021"
      stroke="#858D9D"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.2124 11.9219L17.7913 18.4464C17.72 19.4636 17.6617 20.2541 15.854 20.2541H11.6944C9.88667 20.2541 9.82835 19.4636 9.75708 18.4464L9.33594 11.9219"
      stroke="#667085"
      strokeOpacity="0.8"
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.6914 16.6904H14.849"
      stroke="#667085"
      strokeOpacity="0.8"
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.1562 14.0986H15.3958"
      stroke="#667085"
      strokeOpacity="0.8"
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const StatusDropdownArrow = ({ fill, rotate = false }) => (
  <svg
    width="11"
    height="5"
    viewBox="0 0 11 5"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={`flex-shrink-0 ${rotate ? 'rotate-180' : ''}`}
  >
    <path
      d="M9.46094 1L6.16801 3.7559C5.77912 4.08137 5.14276 4.08137 4.75387 3.7559L1.46094 1"
      stroke={fill}
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const AnimatedCheckmark = () => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 130.2 130.2"
    className="w-20 h-20"
  >
    <polyline
      className="path check"
      fill="none"
      stroke="#40AA00"
      strokeWidth="6"
      strokeLinecap="round"
      strokeMiterlimit="10"
      points="100.2,40.2 51.5,88.8 29.8,67.5 "
    />
  </svg>
);

export const UnreviewedIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.68661 6.31328L6.31328 9.68661C5.87995 9.25328 5.61328 8.65995 5.61328 7.99995C5.61328 6.67995 6.67995 5.61328 7.99995 5.61328C8.65995 5.61328 9.25328 5.87995 9.68661 6.31328Z"
      stroke="#858D9D"
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.8799 3.8473C10.7132 2.9673 9.3799 2.4873 7.9999 2.4873C5.64656 2.4873 3.45323 3.87397 1.92656 6.27397C1.32656 7.21397 1.32656 8.79397 1.92656 9.73397C2.45323 10.5606 3.06656 11.274 3.73323 11.8473"
      stroke="#858D9D"
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.61328 13.0195C6.37328 13.3395 7.17995 13.5128 7.99995 13.5128C10.3533 13.5128 12.5466 12.1261 14.0733 9.72613C14.6733 8.78613 14.6733 7.20613 14.0733 6.26613C13.8533 5.91947 13.6133 5.5928 13.3666 5.28613"
      stroke="#858D9D"
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.34 8.46582C10.1666 9.40582 9.39996 10.1725 8.45996 10.3458"
      stroke="#858D9D"
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.31301 9.68555L1.33301 14.6655"
      stroke="#858D9D"
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.6655 1.33301L9.68555 6.31301"
      stroke="#858D9D"
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const ReviewedIcon = () => (
  <svg
    width="19"
    height="19"
    viewBox="0 0 19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.041 9.7324C12.041 11.262 10.805 12.498 9.27537 12.498C7.74579 12.498 6.50977 11.262 6.50977 9.7324C6.50977 8.20282 7.74579 6.9668 9.27537 6.9668C10.805 6.9668 12.041 8.20282 12.041 9.7324Z"
      stroke="#78BD4F"
      strokeWidth="1.55"
    />
    <path
      d="M9.27587 16.1201C12.0028 16.1201 14.5444 14.5133 16.3135 11.7322C17.0088 10.643 17.0088 8.8121 16.3135 7.72285C14.5444 4.94179 12.0028 3.33496 9.27587 3.33496C6.54888 3.33496 4.00731 4.94179 2.23825 7.72285C1.54298 8.8121 1.54298 10.643 2.23825 11.7322C4.00731 14.5133 6.54888 16.1201 9.27587 16.1201Z"
      stroke="#78BD4F"
      strokeWidth="1.55"
    />
  </svg>
);

export const PreviewDeleteIcon = ({ onClick }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17"
    height="17"
    viewBox="0 0 17 17"
    fill="none"
    className="absolute top-2 right-2 w-[1.063rem] h-[1.063rem] cursor-pointer"
    onClick={onClick}
  >
    <circle cx="8.5" cy="8.5" r="8.5" fill="black" fillOpacity="0.7" />
    <rect
      x="4.99951"
      y="5.73633"
      width="1.04201"
      height="8.85709"
      rx="0.521006"
      transform="rotate(-45 4.99951 5.73633)"
      fill="white"
    />
    <rect
      x="11.2627"
      y="5"
      width="1.04201"
      height="8.85709"
      rx="0.521006"
      transform="rotate(45 11.2627 5)"
      fill="white"
    />
  </svg>
);
