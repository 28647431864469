import moment from 'moment';
import React from 'react';

const PayrollStep1 = ({
  selectedPayrole,
  setSelectedPayrole,
  upcomingPayrolls,
  onSubmit,
  loading,
}) => (
  <div className="flex flex-col gap-10">
    <h2 className="text-slate-600 font-semibold text-xl">
      Select a pay schedule for this payroll
    </h2>
    <div className="flex flex-col gap-4">
      {upcomingPayrolls?.length > 0 ? (
        upcomingPayrolls?.map((payRoll) => (
          <label
            htmlFor={payRoll?.payroll_uuid}
            className="flex items-center gap-4 cursor-pointer w-fit"
            key={payRoll?.payroll_uuid}
            onClick={() => setSelectedPayrole(payRoll)}
          >
            <input
              type="radio"
              name={payRoll?.payroll_uuid}
              id={payRoll?.payroll_uuid}
              className="form-radio"
              checked={payRoll?.payroll_uuid === selectedPayrole?.payroll_uuid}
            />
            <div className="flex flex-col">
              <p className="text-lg font-medium capitalize">
                {payRoll?.paySchedule?.name || payRoll?.paySchedule?.customName}
                {payRoll?.pay_period?.start_date && (
                  <span className="text-base text-slate-500 font-normal">
                    {' '}
                    (
                    {moment(payRoll?.pay_period?.start_date)?.format(
                      'MMM Do, YYYY',
                    )}
                    -
                    {moment(payRoll?.pay_period?.end_date)?.format(
                      'MMM Do, YYYY',
                    )}
                    )
                  </span>
                )}
              </p>
              <p className="capitalize">{payRoll?.paySchedule?.frequency}</p>
              <p className="text-sm">
                Payday: {moment(payRoll?.check_date)?.format('MMM Do, YYYY')}
              </p>
            </div>
          </label>
        ))
      ) : (
        <h3>No Payroll Found</h3>
      )}
    </div>
    <div className="w-full flex justify-end">
      <button
        className="bg-indigo-500 h-10 border border-indigo-500 rounded-[0.313rem] px-[1.125rem] py-2.5 shadow text-white text-base leading-6 flex items-center justify-center gap-2 font-normal
          disabled:!bg-indigo-300 disabled:text-white disabled:cursor-not-allowed"
        onClick={onSubmit}
        disabled={!selectedPayrole || loading}
        type="button"
      >
        {loading && (
          <svg
            className="animate-spin w-4 h-4 fill-current shrink-0 mr-2"
            viewBox="0 0 16 16"
          >
            <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z" />
          </svg>
        )}
        Next
      </button>
    </div>
  </div>
);

export default PayrollStep1;
