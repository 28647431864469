import React from 'react';
import AvatarImage from '../../images/custom/empty_bill.svg';

const NoBillBox = ({ handleCreateBill }) => (
  <div className="flex-1 flex flex-col justify-center items-center">
    {/* Avatar Image  */}
    <img src={AvatarImage} alt="" className="h-[12.5rem]" />
    <h3 className="mb-6 mt-4 leading-tight text-[36px] font-medium text-[#00000099] ">
      Follow The Money
    </h3>
    <p className="text-sm text-[#667085] max-w-[425px] text-center mb-8 leading-tight">
      Monitor your cash flow. Create bills from vendors and mark them as paid to
      track your upcoming expenses. A bill is money that your business owes but
      will pay at a later date.
    </p>
    <button
      onClick={handleCreateBill}
      className="h-11 px-6 text-white bg-indigo-500 shadow-button rounded-[5px]"
      type="button"
    >
      Create Your First Bill
    </button>
  </div>
);

export default NoBillBox;
