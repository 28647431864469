import {
  Background,
  Controls,
  ReactFlow,
  useOnViewportChange,
  useReactFlow,
} from 'reactflow';
import React, { useState, useCallback, useEffect, useRef } from 'react';
import NodePanel from './NodePanel';
import SaveModal from './SaveModal';
import ResetModal from './ResetModal';
import { StartHereArrow } from './svg';

const Canvas = ({
  nodeTypes,
  nodes,
  edges,
  edgeTypes,
  onNodesChange,
  onNodesDelete,
  onEdgesChange,
  onEdgesDelete,
  onConnect,
  handleReset,
  handleSave,
  handleNavigate,
  setActiveInstance,
  panelOpen,
  setPanelOpen,
  panelStep,
  setPanelStep,
  createNewNode,
  vendors,
  customers,
  accounts,
  categories,
  tags,
  members,
  updateNode,
  setIsEditing,
  isEditing,
  setActiveNodeId,
  isInBetween,
  setIsInBetween,
  createNodeBetween,
  deleteNode,
  currencies,
  team,
  displayStartHere,
  setDisplayStartHere,
  localAutomation,
  automationType,
  setAutomationType,
  checkError,
  type,
  setActiveHoverNodeId,
}) => {
  const leaveDelay = useRef();
  // const reactFlow = useReactFlow();
  const ref = useRef(null);

  const [saveModalOpen, setSaveModalOpen] = useState(false);
  const [resetModalOpen, setResetModalOpen] = useState(false);
  const [menu, setMenu] = useState(null);

  useEffect(() => {
    if (!panelOpen) {
      setIsInBetween({});
    }
  }, [panelOpen]);

  const onNodeMouseEnter = useCallback(
    (event, node) => {
      if (leaveDelay.current) {
        clearTimeout(leaveDelay.current);
        leaveDelay.current = null;
      }
      setActiveHoverNodeId(node.id);
    },
    [setActiveHoverNodeId],
  );

  const onNodeMouseLeave = useCallback(() => {
    leaveDelay.current = setTimeout(() => {
      setActiveHoverNodeId(null);
    }, 300);
  }, [setActiveHoverNodeId]);

  const handlePaneClick = () => {
    if (panelOpen) {
      setPanelOpen(false);
      setIsEditing(false);
      setActiveNodeId(null);
      setIsInBetween({});
    }
    setDisplayStartHere(false);
    if (menu) {
      setMenu(null);
    }
  };

  useOnViewportChange({
    onChange: () => {
      setDisplayStartHere(false);
    },
  });

  return (
    <div className="h-full w-full">
      <SaveModal
        isOpen={saveModalOpen}
        setIsOpen={setSaveModalOpen}
        handleSave={handleSave}
        localAutomation={localAutomation}
        nodes={nodes}
        automationType={automationType}
      />
      <ResetModal
        isOpen={resetModalOpen}
        setIsOpen={setResetModalOpen}
        handleReset={handleReset}
      />
      {panelOpen && (
        <NodePanel
          panelOpen={panelOpen}
          setPanelOpen={setPanelOpen}
          panelStep={panelStep}
          setPanelStep={setPanelStep}
          createNewNode={createNewNode}
          nodes={nodes}
          vendors={vendors}
          customers={customers}
          accounts={accounts}
          updateNode={updateNode}
          categories={categories}
          tags={tags}
          members={members}
          setIsEditing={setIsEditing}
          isEditing={isEditing}
          isInBetween={isInBetween}
          setIsInBetween={setIsInBetween}
          createNodeBetween={createNodeBetween}
          setActiveNodeId={setActiveNodeId}
          currencies={currencies}
          team={team}
          automationType={automationType}
          setAutomationType={setAutomationType}
          deleteNode={deleteNode}
        />
      )}
      <ReactFlow
        ref={ref}
        nodeTypes={nodeTypes}
        edgeTypes={edgeTypes}
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onNodesDelete={onNodesDelete}
        onEdgesChange={onEdgesChange}
        onEdgesDelete={onEdgesDelete}
        onConnect={onConnect}
        proOptions={{ hideAttribution: true }}
        className="h-2/3 relative"
        onPaneClick={handlePaneClick}
        onInit={setActiveInstance}
        nodesDraggable={false}
        onNodeMouseEnter={onNodeMouseEnter}
        onNodeMouseLeave={onNodeMouseLeave}
      >
        <Background
          gap={50}
          size={5}
          color="#D9D9D9"
          className="bg-[#F5F5F5]"
        />
        {nodes?.length === 1 && displayStartHere && (
          <div
            className="absolute"
            style={{
              top: nodes[0]?.position?.y + 60,
              left: nodes[0]?.position?.x - 40,
            }}
          >
            <StartHereArrow />
          </div>
        )}
        {/* Top Bar */}
        <Controls
          position="top-center"
          showZoom={false}
          showFitView={false}
          showInteractive={false}
          className="flex overflow-hidden gap-2.5 border border-[#D0D5DD] shadow justify-between self-stretch items-center p-5 bg-white rounded-[24px] w-[80%] h-[4.875rem]"
        >
          <div className="flex items-center gap-5 flex-1 overflow-hidden">
            <svg
              width="33"
              height="32"
              viewBox="0 0 33 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              onClick={() => handleNavigate()}
              className="cursor-pointer"
            >
              <rect
                width="32"
                height="33"
                transform="translate(33) rotate(90)"
                fill="#FBF3ED"
              />
              <path
                d="M19 10.5L13 16.5L19 22.5"
                stroke="#E48642"
                strokeWidth="3.6"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <h2 className="flex-1  text-slate-600 overflow-hidden text-ellipsis text-[25px] font-medium leading-[2.375rem] hidden sm:block">
              {localAutomation?.name || 'Automation Builder'}
            </h2>
          </div>
          <div className="flex items-center gap-2.5 justify-end">
            <button
              onClick={() => setSaveModalOpen(true)}
              className="flex h-10 px-[1.125rem] py-2.5 justify-center items-center gap-2 rounded-[0.313rem] shadow-sm text-white bg-indigo-500 border border-indigo-500
              disabled:bg-indigo-300 disabled:cursor-not-allowed"
              type="button"
              disabled={checkError()}
            >
              Save
            </button>
            <button
              onClick={() => setResetModalOpen(true)}
              className="flex h-10 px-[1.125rem] py-2.5 justify-center items-center gap-2 rounded-[0.313rem] shadow-sm text-indigo-500 border border-indigo-500"
              type="button"
            >
              Reset
            </button>
          </div>
        </Controls>
        <Controls
          position="bottom-right"
          className="flex gap-2.5 w-auto border border-[#D0D5DD] shadow justify-center items-center p-5 bg-white rounded-[24px]"
          showInteractive={false}
          fitViewOptions={{ padding: 1 }}
        />
      </ReactFlow>
    </div>
  );
};

export default Canvas;
