import React, { useEffect, useState } from "react";
import ModalBasic from "../../../components/ModalBasic";
import DropdownFull from "../../../components/DropdownFull";
import { plaidSyncStart } from "../../../API/backend_helper";

const ChooseAccountsModal = ({ isOpen, setIsOpen, accessToken,afterSuccess }) => {
  const [selectedAccounts, setSelectedAccounts] = useState([]);
  const [transactionRange, setTransactionRange] = useState("jan1");
  const [isLoading, setIsLoading] = useState(false);

  const onChecked = (id) => {
    let temp = [...selectedAccounts];
    if (temp?.includes(id)) {
      temp = temp.filter((t) => t !== id);
    } else {
      temp?.push(id);
    }
    setSelectedAccounts(temp);
  };

  useEffect(()=>{
    if(isOpen?.accounts?.length>0){
      const temp=[];
      isOpen?.accounts?.forEach(acc=>{
        temp?.push(acc?.account_id)
      })
      setSelectedAccounts(temp);
    }
  },[isOpen?.accounts])

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (isOpen) {
        event.preventDefault();
        event.returnValue = ''; // This line is necessary for some browsers
        return ''; // This line is necessary for other browsers
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [isOpen]);

  const onSubmit = async () => {
    try {
      setIsLoading(true);
      const res = await plaidSyncStart(accessToken, {
        connectionId: isOpen?.id,
        transactionRange: transactionRange,
        accounts: selectedAccounts,
      });
      afterSuccess();
      setIsLoading(false);
      setTransactionRange("jan1");
      setSelectedAccounts([]);
      setIsOpen(false);
    } catch (e) {
      setIsLoading(false);
      console.log("error", e);
    }
  };

  return (
    <ModalBasic
      title="Select Accounts"
      modalOpen={isOpen}
      setModalOpen={setIsOpen}
      width="max-w-[600px]"
      noClick
      hideCross
    >
      <div className="px-[30px] py-4">
        <p className="mb-6">
          Choose the accounts from which you wish to receive transactions. You
          can enable or disable accounts in team settings later if needed.
        </p>
        <DropdownFull
          options={[
            {
              id: "none",
              name: "Do not sync any historical transactions",
            },
            {
              id: "last90",
              name: "Sync last 90 days of transactions",
            },
            {
              id: "jan1",
              name: `Sync transactions from January 1st, ${new Date().getFullYear()}`,
            },
            {
              id: "all",
              name: " Sync all historical transaction available (this is often the last 24 months)",
            },
          ]}
          className="w-full !h-fit"
          name="name"
          setSelected={(id) => {
            setTransactionRange(id);
          }}
          selected={transactionRange}
          scrollIntoView={false}
        />
        <div className="space-y-2 mt-4">
          {isOpen?.accounts?.length > 0 &&
            isOpen?.accounts?.map((account) => (
              <div className="flex justify-between gap-4 items-center cursor-pointer border-b border-dashed pb-2 last:border-none last:mb-0">
                <div  onClick={() => onChecked(account?.account_id)}>
                  <h3 className="text-[18px] font-medium">
                    {account?.name} {account?.mask && `(....${account?.mask})`}
                  </h3>
                  <p className="text-sm">
                    {account?.official_name}
                  </p>
                </div>
                <div className="form-switch">
                  <input
                    type="checkbox"
                    id={account?.account_id}
                    className="sr-only"
                    checked={selectedAccounts?.includes(account?.account_id)}
                    onChange={(e) => onChecked(e.target.id)}
                  />
                  <label
                    className="bg-white border border-gray-300 border-opacity-50 shadow-sm"
                    htmlFor={account?.account_id}
                  >
                    <span className="bg-gray-300" aria-hidden="true"></span>
                    <span className="sr-only">Switch label</span>
                  </label>
                </div>
              </div>
            ))}
        </div>
        <button
          className="btn w-full mt-4 py-[11px] px-[10px]  sm:px-[20px] lg:px-[30px] whitespace-nowrap text-base
             !bg-indigo-500 hover:!bg-indigo-600 shadow-md hover:shadow-lg text-white disabled:!bg-indigo-300 disabled:cursor-not-allowed"
          onClick={onSubmit}
          disabled={isLoading}
        >
          {isLoading && (
            <svg
              className="animate-spin w-4 h-4 fill-current shrink-0 mr-2"
              viewBox="0 0 16 16"
            >
              <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z" />
            </svg>
          )}
          Connect Accounts
        </button>
      </div>
    </ModalBasic>
  );
};

export default ChooseAccountsModal;
