import React, { useState, useEffect, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Tooltip from 'components/Tooltip';
import useClickOutside from '../../../utils/useClickOutside';
import {
  addReceipt,
  getBillById,
  getSingleTransaction,
  updateTransaction,
} from '../../../API/backend_helper';

import TaskTab from './TaskTab';
import ActionTab from './ActionTab';
import RightBar from './RightBar';
import TaskAttachments from './TaskAttachments';

const TaskFlyout = ({
  flyoutOpen,
  setFlyoutOpen,
  task,
  setTask,
  handleUpdateTask,
  loadData = null,
  findTaskType,
  statusOptions,
  categories,
  localUser,
  vendors,
  setVendors,
  allExpenseCategories,
  setTransactionsModalOpen,
  bill,
  setBill,
  transactionsModalOpen,
  statusLoading,
  assigneeLoading,
  priorityLoading,
  projects = [],
  setEditTaskModalOpen,
  setDeleteModalOpen,
  setActiveAttachment,
  deleteModalOpen,
  selectedTaskAttachments,
  setSelectedTaskAttachments,
  attachmentsLoading,
  uploadTaskAttachment,
  setReceiptModalOpen,
  receiptModalOpen,
  editTaskModalOpen,
}) => {
  const taskTitleRef = useRef();
  const [, setSearchParams] = useSearchParams();

  const flyoutContent = useRef(null);
  const [noClick, setNoClick] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [actionLoading, setActionLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [currentTab, setCurrentTab] = useState('task');
  const [transaction, setTransaction] = useState(null);
  const [taskComplete, setTaskComplete] = useState(false);
  const [displayText, setDisplayText] = useState(false);
  const [reviewedLoading, setReviewedLoading] = useState(false);
  const [files, setFiles] = useState([]);

  const [taskTitle, setTaskTitle] = useState(task?.title);
  const [isEditingTaskTitle, setIsEditingTaskTitle] = useState(false);
  const [taskTitleError, setTaskTitleError] = useState('');

  useEffect(() => {
    setTaskTitle(task?.name);
    setIsEditingTaskTitle(false);
    setTaskTitleError(false);
  }, [flyoutOpen, task]);

  const checkTaskTitleError = (value) => {
    let error = '';
    if (!value) {
      error = 'You must specify title of the task';
    }
    if (value?.length > 255) {
      error = 'Task title must be less than or equal to 255 characters';
    }
    setTaskTitleError(error);
    return error;
  };

  const handleChangeTaskTitle = (value) => {
    setTaskTitle(value);
    checkTaskTitleError(value);
  };

  const handleSaveTaskTitle = () => {
    if (checkTaskTitleError(taskTitle)) {
      return;
    }
    if (taskTitle !== task?.name) {
      handleUpdateTask(task?.id, { ...task, name: taskTitle });
    }
    setIsEditingTaskTitle(false);
  };

  useEffect(() => {
    if (taskTitleRef.current) {
      taskTitleRef.current.style.height = 'auto';
      taskTitleRef.current.style.height = `${taskTitleRef.current.scrollHeight + 5}px`;
    }
  }, [taskTitle, isEditingTaskTitle]);

  const { protocol, host } = window.location;

  const copyToClipboard = () => {
    navigator.clipboard
      .writeText(`${protocol}//${host}/inbox?id=${task?.id}`)
      .then(() => {
        toast.success('Copied');
        // setCopied(true);
        // setTimeout(() => {
        //   setCopied(false);
        // }, 3000);
        // setIsOpen(false);
      })
      .catch(() => {
        toast.error('Something went wrong');
      });
  };

  useEffect(() => {
    if (taskComplete && !actionLoading) {
      setTimeout(() => {
        setDisplayText(true);
      }, 800);
    }
  }, [taskComplete, actionLoading]);

  useEffect(() => {
    if (task?.id && !flyoutOpen) {
      setSearchParams({});
    }
  }, [flyoutOpen]);

  useClickOutside(flyoutContent, () => {
    if (
      !noClick &&
      !transactionsModalOpen &&
      !deleteModalOpen &&
      !receiptModalOpen &&
      !editTaskModalOpen
    )
      setFlyoutOpen(false);
  });

  const handleActionDropdown = (id) => {
    if (id === 'edit') {
      setEditTaskModalOpen(true);
    } else if (id === 'link') {
      copyToClipboard();
    } else if (id === 'delete') {
      setDeleteModalOpen('task');
    }
  };

  const handleStatusUpdate = async (status, loading = true) => {
    if (loading) {
      setIsLoading(true);
    }
    try {
      const res = await handleUpdateTask(task?.id, { ...task, status });
      setTask(res);
      if (loadData) loadData(false);
    } catch (error) {
      console.error(error);
    }
    if (loading) {
      setIsLoading(false);
    }
  };

  const removeFile = (targetIndex) => {
    setFiles((prevFiles) =>
      prevFiles.filter((_, index) => index !== targetIndex),
    );
  };

  const loadTransaction = async (id) => {
    try {
      setIsLoading(true);
      const res = await getSingleTransaction(id);
      setTransaction(res);
      setTimeout(() => {
        setIsLoading(false);
      }, 300);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  const loadBill = async (id) => {
    if (!transactionsModalOpen) {
      try {
        setIsLoading(true);
        const res = await getBillById(id);
        setBill(res);
        setTimeout(() => {
          setIsLoading(false);
        }, 300);
      } catch (error) {
        console.error(error);
        setIsLoading(false);
      }
    }
  };

  const handleUpdateTransaction = async (id, data) => {
    try {
      setActionLoading(true);
      const res = await updateTransaction(id, data);
      setTransaction(res);
      setActionLoading(false);
      setTaskComplete(true);
      handleStatusUpdate('completed', false);
    } catch (error) {
      console.error(error);
      setActionLoading(false);
    }
  };

  const handleReviewTransaction = async (isReviewed) => {
    try {
      setReviewedLoading(true);
      const res = await updateTransaction(transaction?.id, {
        reviewed: isReviewed,
      });
      setTransaction(res);
      setReviewedLoading(false);
      setTaskComplete(true);
      handleStatusUpdate('completed', false);
    } catch (error) {
      console.error(error);
      setReviewedLoading(false);
    }
  };

  const handleUploadAttachment = async () => {
    try {
      setActionLoading(true);
      const formData = new FormData();
      files.forEach((file) => {
        formData.append('receipt', file);
      });
      await addReceipt(transaction?.id, formData);
      setFiles([]);
      setActionLoading(false);
      handleStatusUpdate('completed', false);
      setTaskComplete(true);
    } catch (error) {
      console.error(error);
      setActionLoading(false);
    }
  };

  useEffect(() => {
    setIsEditing(false);
    setBill(null);
    setTransaction(null);
    if (task?.status === 'completed') {
      setTaskComplete(true);
      setDisplayText(true);
    } else {
      setTaskComplete(false);
      setDisplayText(false);
    }
    if (
      localUser?.id === task?.assigneeId &&
      (task?.transactionId || task?.billId)
    ) {
      if (task?.transactionId) {
        loadTransaction(task?.transactionId);
      }
      if (task?.billId) {
        loadBill(task?.billId);
      }
      setCurrentTab('action');
    } else {
      setCurrentTab('task');
    }
  }, [flyoutOpen]);

  return (
    <div
      ref={flyoutContent}
      className={`absolute inset-0 bg-white sm:left-auto shadow-xl transition-transform duration-200 ease-in-out z-40 ${
        flyoutOpen ? 'translate-x-0' : 'translate-x-full'
      }`}
    >
      <div
        className=" sticky  top-16 bg-white dark:bg-gradient-to-b dark:from-slate-800 dark:to-slate-900 overflow-x-visible overflow-y-auto no-scrollbar shrink-0 w-dvw	 lg:w-[800px] h-[calc(100dvh-64px)]"
        style={{ boxShadow: ' -10px 0px 15px 0px #D0D5DD80' }}
      >
        <button
          className="absolute inline-flex  items-center gap-1 left-6 h-4 mt-2 z-10 "
          type="button"
          onClick={() => setFlyoutOpen(false)}
        >
          <Tooltip
            content="Close"
            position="right"
            contentClassName="border-none !py-1 rounded-[10px] overflow-visible font-normal text-sm text-[#667085] "
          >
            <svg
              version="1.1"
              id="Layer_1"
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              viewBox="0 0 120.64 122.88"
              className="hover:fill-slate-500 fill-slate-400 h-4"
            >
              <g>
                <path d="M54.03,108.91c-1.55,1.63-2.31,3.74-2.28,5.85c0.03,2.11,0.84,4.2,2.44,5.79l0.12,0.12c1.58,1.5,3.6,2.23,5.61,2.2 c2.01-0.03,4.01-0.82,5.55-2.37c17.66-17.66,35.13-35.61,52.68-53.4c0.07-0.05,0.13-0.1,0.19-0.16c1.55-1.63,2.31-3.76,2.28-5.87 c-0.03-2.11-0.85-4.21-2.45-5.8l-0.27-0.26C100.43,37.47,82.98,19.87,65.46,2.36C63.93,0.82,61.93,0.03,59.92,0 c-2.01-0.03-4.03,0.7-5.61,2.21l-0.15,0.15c-1.57,1.58-2.38,3.66-2.41,5.76c-0.03,2.1,0.73,4.22,2.28,5.85l47.22,47.27 L54.03,108.91L54.03,108.91z M2.26,106.91c-1.54,1.62-2.29,3.73-2.26,5.83c0.03,2.11,0.84,4.2,2.44,5.79l0.12,0.12 c1.57,1.5,3.6,2.23,5.61,2.21c2.01-0.03,4.02-0.82,5.55-2.37C31.01,101.2,48.87,84.2,66.39,67.12c0.07-0.05,0.14-0.11,0.21-0.17 c1.55-1.63,2.31-3.76,2.28-5.87c-0.03-2.11-0.85-4.21-2.45-5.8C48.94,38.33,31.36,21.44,13.83,4.51l-0.12-0.13 c-1.53-1.54-3.53-2.32-5.54-2.35C6.16,2,4.14,2.73,2.56,4.23L2.41,4.38C0.84,5.96,0.03,8.05,0,10.14c-0.03,2.1,0.73,4.22,2.28,5.85 l47.18,45.24L2.26,106.91L2.26,106.91z" />
              </g>
            </svg>
          </Tooltip>
        </button>
        <div className="grid grid-cols-6 h-full">
          <div className="col-span-full sm:col-span-3 md:col-span-4 h-full">
            {/* Header */}
            <div className=" bg-white border-b border-gray-300">
              <div className=" w-full px-6 pt-8 relative">
                <h3
                  className={`text-black mb-2 leading-6 border border-transparent text-opacity-60 text-[18px] font-medium p-2 cursor-pointer  hover:bg-slate-100 ${isEditingTaskTitle ? 'hidden' : 'block'}`}
                  onClick={() => {
                    setIsEditingTaskTitle(true);
                    setTimeout(() => taskTitleRef.current?.focus(), 100);
                  }}
                >
                  {taskTitle || task?.name}
                </h3>
                <div
                  className={`relative mb-2 ${isEditingTaskTitle ? 'block' : 'hidden'}`}
                >
                  <textarea
                    value={taskTitle}
                    onChange={(e) => handleChangeTaskTitle(e.target.value)}
                    className={`form-textarea resize-none px-2  w-full text-black text-opacity-60 !text-[18px] font-medium
                        ${taskTitleError ? '!border-rose-400 hover:!border-rose-400' : ''}`}
                    rows={1}
                    onBlur={handleSaveTaskTitle}
                    ref={taskTitleRef}
                  />
                  {taskTitleError && (
                    <div className="absolute bottom-2 text-xs text-rose-400">
                      {taskTitleError}
                    </div>
                  )}
                  <div className="flex gap-2 justify-end">
                    {/* Tick Button  */}
                    <button
                      type="button"
                      className="inline-flex justify-center items-center border border-slate-200 h-6 w-8 shadow-md hover:shadow-lg group transition-all"
                      onClick={handleSaveTaskTitle}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 448 512"
                        className="fill-slate-400 h-4 group-hover:fill-green-500"
                      >
                        <path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" />
                      </svg>
                    </button>
                    {/* Cross Button  */}
                    <button
                      type="button"
                      className="inline-flex justify-center items-center border border-slate-200 h-6 w-8 shadow-md hover:shadow-lg group transition-all"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleChangeTaskTitle(task?.name);
                        setIsEditingTaskTitle(false);
                      }}
                    >
                      <svg
                        width="28"
                        height="29"
                        viewBox="0 0 28 29"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="text-slate-400  h-5 group-hover:text-rose-500"
                      >
                        <rect
                          x="6.17676"
                          y="8.6582"
                          width="2.3292"
                          height="19.7982"
                          rx="1.1646"
                          transform="rotate(-45 6.17676 8.6582)"
                          className="fill-current"
                        />
                        <rect
                          x="20.1768"
                          y="7.01172"
                          width="2.3292"
                          height="19.7982"
                          rx="1.1646"
                          transform="rotate(45 20.1768 7.01172)"
                          className="fill-current"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
                {/* Tabs  */}
                <div className="flex gap-8">
                  <p
                    className={`text-sm leading-6 font-normal cursor-pointer ${
                      currentTab === 'task'
                        ? 'text-black border-b-3 border-indigo-500'
                        : 'text-slate-600 '
                    }`}
                    onClick={() => setCurrentTab('task')}
                  >
                    Description
                  </p>
                  {(task?.transactionId || task?.billId) && (
                    <p
                      className={`text-sm leading-6 font-normal cursor-pointer ${
                        currentTab === 'action'
                          ? 'text-black border-b-3 border-indigo-500'
                          : 'text-slate-600'
                      }`}
                      onClick={() => {
                        setIsEditing(false);
                        if (task?.transactionId) {
                          loadTransaction(task?.transactionId);
                        } else if (task?.billId) {
                          loadBill(task?.billId);
                        }
                        setCurrentTab('action');
                      }}
                    >
                      Action
                    </p>
                  )}
                </div>
              </div>
            </div>

            {currentTab === 'task' && (
              <>
                <TaskTab task={task} handleUpdateTask={handleUpdateTask} />
                <TaskAttachments
                  task={task}
                  setActiveAttachment={setActiveAttachment}
                  setDeleteModalOpen={setDeleteModalOpen}
                  attachments={selectedTaskAttachments}
                  setAttachments={setSelectedTaskAttachments}
                  attachmentsLoading={attachmentsLoading}
                  uploadTaskAttachment={uploadTaskAttachment}
                  setReceiptModalOpen={setReceiptModalOpen}
                />
              </>
            )}

            {currentTab === 'action' && (
              <ActionTab
                isLoading={isLoading}
                task={task}
                transaction={transaction}
                actionLoading={actionLoading}
                taskComplete={taskComplete}
                categories={categories}
                allExpenseCategories={allExpenseCategories}
                handleUpdateTransaction={handleUpdateTransaction}
                vendors={vendors}
                setVendors={setVendors}
                files={files}
                removeFile={removeFile}
                setFiles={setFiles}
                handleUploadAttachment={handleUploadAttachment}
                reviewedLoading={reviewedLoading}
                handleReviewTransaction={handleReviewTransaction}
                displayText={displayText}
                bill={bill}
                setTransactionsModalOpen={setTransactionsModalOpen}
              />
            )}
          </div>
          <div className="col-span-full sm:col-span-3 md:col-span-2 h-full relative border-l border-gray-300 bg-gray-50 bg-opacity-80">
            <RightBar
              task={task}
              findTaskType={findTaskType}
              handleUpdateTask={handleUpdateTask}
              assigneeLoading={assigneeLoading}
              statusOptions={statusOptions}
              handleActionDropdown={handleActionDropdown}
              noClick={noClick}
              setNoClick={setNoClick}
              projects={projects}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TaskFlyout;
