import React from 'react';
import Slider from 'react-slick';
import Image1 from '../images/AuthSlider/Slider1';
import Slider1_1 from '../images/AuthSlider/C-symbol.svg';
import AuthSliderMask from '../images/AuthSlider/AuthMask.png';
import Image2 from '../images/AuthSlider/Slider2.png';
import Slider3 from '../images/AuthSlider/Screen3.svg';
import BottomShadow from '../images/AuthSlider/BottomShadow.png';

const AuthSlider = () => {
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 3000,
    cssEase: 'linear',
  };
  return (
    <div className="hidden overflow-auto max-h-[100vh]  bg-[#F4E8E0] rounded-[26px] m-3 md:block  fixed top-0 bottom-0 right-0 md:w-1/2">
      <img
        src={AuthSliderMask}
        alt="mask"
        className="absolute w-full h-auto max-h-[100%]"
      />
      <img
        src={BottomShadow}
        alt="shadow"
        className="absolute bottom-0 w-full h-auto max-h-[100%]"
      />
      <Slider {...settings} className=" position-relative">
        <div className="!flex flex-col items-center">
          <div className="relative w-full -mb-[7.5vh] xl:-mb-[4vh] mt-[5vh] mx-auto">
            <div className="w-fit mx-auto h-auto rounded-full bg-white p-2">
              <img
                src={Slider1_1}
                className="mx-auto max-h-[7vh] w-auto h-auto"
                alt="slider_image"
              />
            </div>
            <h3 className="text-[24px] xl:text-[35px] text-[#4F4F4F] font-medium mt-1 text-center leading-tight max-w-[70vh] mx-auto">
              Accounting software packed with features that save you time and
              money
            </h3>
            <div className="max-h-[65vh] w-auto h-auto mx-auto" />
            <Image1 className="max-h-[65vh] w-auto h-auto mx-auto" />
          </div>
        </div>
        <div className="!flex flex-col items-center">
          <div className="relative w-full -mb-[7.5vh] xl:mb-0 mt-[5vh] xl:mt-[13vh] mx-auto">
            <img
              src={Image2}
              alt="auth_image"
              className="max-h-[40vh] w-auto h-auto mx-auto"
            />
            <div className="mx-auto max-h-[40vh] w-auto h-auto flex flex-col gap-6 max-w-[90vh]">
              <h3 className="text-[#4F4F4F] text-center text-[24px] xl:text-[35px] font-medium">
                Collaborate seamlessly, achieve together
              </h3>
              <div className="flex items-center justify-center gap-6 self-stretch mx-auto">
                <svg
                  width="31"
                  height="31"
                  viewBox="0 0 31 31"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="flex-shrink-0"
                >
                  <rect width="31" height="31" rx="15.5" fill="#EFF7EA" />
                  <path
                    d="M22.2108 11.9868L13.6616 20.0868C13.5872 20.1576 13.4987 20.2138 13.4013 20.2521C13.3039 20.2904 13.1994 20.3101 13.0939 20.3101C12.9884 20.3101 12.884 20.2904 12.7866 20.2521C12.6891 20.2138 12.6007 20.1576 12.5262 20.0868L8.78594 16.543C8.71138 16.4724 8.65224 16.3885 8.6119 16.2962C8.57155 16.204 8.55078 16.105 8.55078 16.0051C8.55078 15.9052 8.57155 15.8063 8.6119 15.714C8.65224 15.6217 8.71138 15.5379 8.78594 15.4673C8.86049 15.3966 8.949 15.3406 9.04641 15.3024C9.14382 15.2641 9.24822 15.2444 9.35366 15.2444C9.45909 15.2444 9.5635 15.2641 9.66091 15.3024C9.75832 15.3406 9.84682 15.3966 9.92138 15.4673L13.0946 18.4738L21.0767 10.9123C21.2273 10.7696 21.4315 10.6895 21.6445 10.6895C21.8574 10.6895 22.0616 10.7696 22.2122 10.9123C22.3627 11.0549 22.4473 11.2484 22.4473 11.4501C22.4473 11.6519 22.3627 11.8454 22.2122 11.988L22.2108 11.9868Z"
                    fill="#64AA3A"
                  />
                </svg>
                <p className="text-[#4F4F4F] font-medium text-[1.125rem]">
                  Leave comments directly on transactions and reports, tag team
                  members or accountants for visibility, and assign tasks with
                  deadlines to ensure timely completion.{' '}
                </p>
              </div>
              <div className="flex items-center justify-center gap-6 self-stretch mx-auto">
                <svg
                  width="31"
                  height="31"
                  viewBox="0 0 31 31"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="flex-shrink-0"
                >
                  <rect width="31" height="31" rx="15.5" fill="#EFF7EA" />
                  <path
                    d="M22.2108 11.9868L13.6616 20.0868C13.5872 20.1576 13.4987 20.2138 13.4013 20.2521C13.3039 20.2904 13.1994 20.3101 13.0939 20.3101C12.9884 20.3101 12.884 20.2904 12.7866 20.2521C12.6891 20.2138 12.6007 20.1576 12.5262 20.0868L8.78594 16.543C8.71138 16.4724 8.65224 16.3885 8.6119 16.2962C8.57155 16.204 8.55078 16.105 8.55078 16.0051C8.55078 15.9052 8.57155 15.8063 8.6119 15.714C8.65224 15.6217 8.71138 15.5379 8.78594 15.4673C8.86049 15.3966 8.949 15.3406 9.04641 15.3024C9.14382 15.2641 9.24822 15.2444 9.35366 15.2444C9.45909 15.2444 9.5635 15.2641 9.66091 15.3024C9.75832 15.3406 9.84682 15.3966 9.92138 15.4673L13.0946 18.4738L21.0767 10.9123C21.2273 10.7696 21.4315 10.6895 21.6445 10.6895C21.8574 10.6895 22.0616 10.7696 22.2122 10.9123C22.3627 11.0549 22.4473 11.2484 22.4473 11.4501C22.4473 11.6519 22.3627 11.8454 22.2122 11.988L22.2108 11.9868Z"
                    fill="#64AA3A"
                  />
                </svg>
                <p className="text-[#4F4F4F] font-medium text-[1.125rem]">
                  Our 'Needs Your Attention' dashboard centralizes your tags and
                  tasks, making it easier than ever to manage responsibilities
                  and keep your team aligned.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="!flex flex-col items-center">
            <div className="relative w-full -mb-[7.5vh] xl:mb-0 mt-[5vh] mx-auto">
              <img
                src={Slider3}
                className="max-h-[65vh] w-auto h-auto mx-auto"
                alt="sliderImage"
              />
            </div>
            <h3 className="text-[24px] xl:text-[35px] text-[#4F4F4F] font-medium  text-center leading-tight max-w-[60vh] mx-auto">
              Unlock limitless potential with our extensive network of 12,000+
              financial partners
            </h3>
          </div>
        </div>
      </Slider>
    </div>
  );
};

export default AuthSlider;
