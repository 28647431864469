import React, { useState, useEffect } from 'react';
import Cropper from 'react-easy-crop';
import ModalBasic from './ModalBasic';
import { getCroppedImg, blobUrlToBlob, blobToFile } from '../utils/Utils';

const ImageCropModal = ({
  isOpen,
  setIsOpen,
  image,
  setPreviewImage,
  setFiles,
  imgName,
}) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [rotation, setRotation] = useState(0);
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);

  useEffect(() => {
    setCroppedImage(null);
    setCrop({ x: 0, y: 0 });
    setRotation(0);
    setZoom(1);
    setCroppedAreaPixels(null);
  }, [isOpen]);

  useEffect(() => {
    if (croppedImage) {
      setPreviewImage(croppedImage);
      blobUrlToBlob(croppedImage).then((blob) => {
        const file = blobToFile(blob, { imgName });
        setFiles([file]);
      });
    }
  }, [croppedImage]);

  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const handleSave = async () => {
    try {
      const croppedImage = await getCroppedImg(
        image,
        croppedAreaPixels,
        rotation,
      );
      setCroppedImage(croppedImage);
      setIsOpen(false);
    } catch (e) {
      console.log('Error: ', e);
    }
  };

  return (
    <ModalBasic
      modalOpen={isOpen}
      setModalOpen={setIsOpen}
      title="Crop Image"
      width="max-w-2xl"
    >
      <div className="relative mx-auto h-80 w-80 mt-[2.25rem]">
        <Cropper
          image={image}
          crop={crop}
          zoom={zoom}
          aspect={1 / 1}
          onCropChange={setCrop}
          onCropComplete={onCropComplete}
          onZoomChange={setZoom}
          cropShape="round"
        />
      </div>
      <div className="border-t border-[#D0D5DD] bg-white flex py-[2rem] px-12 justify-end items-center gap-4 self-stretch w-full mt-[2.25rem]">
        <div className="flex flex-wrap justify-end space-x-2">
          <button
            type="button"
            className="h-11 w-[5.625rem] py-2.5 px-[1.875rem] flex items-center justify-center rounded-[0.313rem] bg-white border border-slate-600 text-slate-600 text-base leading-6"
            onClick={(e) => {
              e.stopPropagation();
              setIsOpen(false);
            }}
          >
            Close
          </button>
          <button
            type="submit"
            className="h-11 w-[5.625rem] py-2.5 px-[1.875rem] flex items-center justify-center rounded-[0.313rem] bg-indigo-500 text-white font-normal text-base leading-6 shadow-sm
                    disabled:bg-indigo-400    disabled:cursor-not-allowed"
            onClick={handleSave}
          >
            Save
          </button>
        </div>
      </div>
    </ModalBasic>
  );
};

export default ImageCropModal;
