/* eslint-disable no-restricted-syntax */
import React, { useEffect, useState } from 'react';
import { shutdown, boot } from '@intercom/messenger-js-sdk';
import { toast } from 'react-toastify';
import { Link, useNavigate } from 'react-router-dom';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import {
  accountCsvImport,
  accountImportBulk,
  accountImportMapping,
} from '../../API/backend_helper';
import AlertBox from '../../components/AlertBox';
import AppLayout from '../../components/AppLayout';

const LoadingIcon = (
  <svg
    width="20"
    height="20"
    viewBox="0 0 29 29"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="animate-spin w-5 h-5 fill-current shrink-0"
  >
    <path
      d="M14.5 2.41699V7.25033"
      stroke="#E48642"
      strokeWidth="2.55882"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.5 21.749V26.5824"
      stroke="#E48642"
      strokeWidth="2.55882"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.95703 5.95703L9.37662 9.37662"
      stroke="#E48642"
      strokeWidth="1.58955"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.623 19.6211L23.0426 23.0407"
      stroke="#E48642"
      strokeWidth="1.58955"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.41699 14.5H7.25033"
      stroke="#E48642"
      strokeWidth="1.58955"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21.749 14.5H26.5824"
      stroke="#E48642"
      strokeWidth="1.58955"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.95703 23.0407L9.37662 19.6211"
      stroke="#E48642"
      strokeWidth="1.58955"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.623 9.37662L23.0426 5.95703"
      stroke="#E48642"
      strokeWidth="1.58955"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export const HeadersColumns = [
  'Account Number',
  'Account Name',
  'Account Type',
  'Account Sub-Type',
];

function checkHeaderDuplicates(headerList) {
  const headers = new Set();
  for (const header of headerList) {
    const { fileHeader } = header;
    if (headers.has(fileHeader)) {
      return true;
    }
    headers.add(fileHeader);
  }
  return false;
}

const cleanJson = (jsonData, headers) =>
  jsonData.map((obj) => {
    const newObj = {};
    for (const header of headers) {
      const { fileHeader } = header;
      if (Object.prototype.hasOwnProperty.call(obj, fileHeader)) {
        newObj[fileHeader] = obj[fileHeader];
      }
    }
    return newObj;
  });
const ImportAccounts = () => {
  const navigate = useNavigate();

  const [activeStep, setActiveStep] = useState(1);
  const [file, setFile] = useState(null);
  const [fileError, setFileError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [mappedData, setMappedData] = useState(null);
  const [headers, setHeaders] = useState(null);
  const [headersObj, setHeadersObj] = useState(null);
  const [accountsObj, setAccountsObj] = useState(null);
  const [tableRows, setTableRows] = useState([]);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (activeStep > 1) {
        event.preventDefault();
        event.returnValue = ''; // This line is necessary for some browsers
        return ''; // This line is necessary for other browsers
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [activeStep]);

  useEffect(() => {
    setTimeout(() => {
      shutdown();
    }, 1000);
    return () => {
      boot();
    };
  }, []);

  const csvFileUpload = async () => {
    try {
      setIsLoading(true);
      const data = new FormData();
      data.append('file', file);
      const res = await accountCsvImport(data);
      const filteredHeaders = res?.headers?.filter((h) =>
        HeadersColumns?.includes(h?.systemheader),
      );
      setHeadersObj(filteredHeaders);
      setHeaders(res?.headers);
      setAccountsObj(res?.accounts);
      setIsLoading(false);
      setActiveStep(activeStep + 1);
    } catch (e) {
      console.log('error', e);
      setIsLoading(false);
    }
  };

  const importMapping = async () => {
    try {
      setIsLoading(true);
      const res = await accountImportMapping({
        headers: headersObj,
        accounts: cleanJson(accountsObj, headersObj),
      });
      const temp = [];
      if (res?.length > 0) {
        res?.forEach((d) => {
          temp?.push({
            selected: { value: true, error: false },
            accountNumber: {
              value: d?.accountNumber,
              error: false,
            },
            name: { value: d?.name, error: !d?.name },
            type: { value: d?.type, error: !d?.type },
            subTypeId: {
              value: d?.subTypeId,
              error: !d?.subTypeId,
            },
            subTypeName: {
              value: d?.SubTypeName,
              error: !d?.SubTypeName,
            },
          });
        });
        setTableRows(temp);
      }
      setMappedData(res);
      setIsLoading(false);
      setActiveStep(activeStep + 1);
    } catch (e) {
      console.log('error', e);
      setIsLoading(false);
    }
  };

  const checkRowsError = () => {
    let isError = false;
    if (tableRows?.length === 0) {
      return true;
    }
    const tempRows = [...tableRows];
    tableRows.forEach((d, i) => {
      Object.keys(d).forEach((key) => {
        const tempRow = { ...tableRows[i] };
        if (d.selected.value) {
          // if (key === "accountNumber" && !d[key].value) {
          //   tempRow[key].error = "";
          // }
          // if (
          //   key === "accountNumber" &&
          //   d[key].value &&
          //   d[key].value?.length > 40
          // ) {
          //   tempRow[key].error = "Account number must be 40 digits or less";
          //   isError = true;
          // }
          if (key === 'name' && !d[key].value) {
            tempRow[key].error = 'Account Name should not be empty';
            isError = true;
          }
          if (key === 'name' && d[key].value && d[key].value?.length > 200) {
            tempRow[key].error = 'Account Name must be 200 characters or less';
            isError = true;
          }

          if (key === 'type' && !d[key].value) {
            tempRow[key].error = 'Select Account Type';
            isError = true;
          }
          if (key === 'subTypeId' && !d[key].value) {
            tempRow[key].error = 'Select Account Sub Type';
            isError = true;
          }
        } else {
          const tempRow = { ...tableRows[i] };
          tempRow[key].error = '';
        }
      });
    });
    setTableRows(tempRows);
    return isError;
  };

  const isDisabled = () => {
    if (activeStep === 1 && file) {
      return false;
    }
    if (
      activeStep === 2 &&
      headersObj &&
      accountsObj &&
      !checkHeaderDuplicates(headersObj)
    ) {
      return false;
    }
    if (activeStep === 3) {
      return false;
    }
    return true;
  };

  const importAccounts = async () => {
    try {
      setIsLoading(true);
      const temp = [];
      if (tableRows?.length > 0) {
        tableRows?.forEach((d) => {
          if (d?.selected?.value) {
            temp?.push({
              accountNumber: d?.accountNumber?.value || '',
              name: d?.name?.value,
              type: d?.type?.value,
              subTypeId: d?.subTypeId?.value,
              subTypeName: d?.subTypeName?.value,
            });
          }
        });
      }
      const res = await accountImportBulk(temp);
      toast.success('Accounts Imported Successfully');
      navigate(
        `/accounts?createdAccounts=${res?.createdAccounts}&skippedAccounts=${res?.skippedAccounts}&totalAccounts=${res?.totalAccounts}`,
      );
      setIsLoading(false);
    } catch (e) {
      console.log('error', e);
      setIsLoading(false);
    }
  };

  const getCheckedAccountsCount = () => {
    let count = 0;
    tableRows?.forEach((t) => {
      if (t?.selected?.value) {
        count++;
      }
    });
    return count;
  };

  const onClickNext = () => {
    if (activeStep === 1 && file) {
      csvFileUpload();
    }
    if (activeStep === 2 && headersObj && accountsObj) {
      importMapping();
    }
    if (activeStep === 3) {
      if (getCheckedAccountsCount() > 0) {
        if (!checkRowsError()) {
          importAccounts();
        }
      } else {
        toast.error('To proceed with the import, please select an account');
      }
    }
  };

  return (
    <AppLayout>
      <>
        <main
          className="grow relative overflow-auto flex flex-col"
          id="importAccountsContainer"
        >
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto pb-16">
            {isLoading && activeStep === 2 && (
              <AlertBox
                type="warning"
                heading="Account Import in Progress"
                text="This may take 2-3 minutes"
                hideActionButton
                icon={LoadingIcon}
              />
            )}
            <div className="mb-8 flex justify-between">
              {/* Title */}
              <h1 className="text-2xl md:text-[36px] text-[#00000099] dark:text-slate-100 font-medium">
                Import Accounts
              </h1>
            </div>
            <div className="flex justify-between mx-6 relative mb-8">
              <div
                className="absolute h-[1px] bg-[#667085] top-[11px] mx-auto left-1/2 -translate-x-1/2"
                style={{
                  width: 'calc(100% - 3rem)',
                }}
              />
              <div className="flex flex-col items-center z-10 relative">
                <div
                  className={`h-6 w-6 rounded-full  inline-flex
                 justify-center items-center border   text-sm ${
                   activeStep === 1
                     ? 'bg-indigo-500 text-white border-indigo-500'
                     : 'border-[#667085] bg-white'
                 }`}
                >
                  1
                </div>
                <h3>Upload</h3>
              </div>
              <div className="flex flex-col items-center z-10 relative">
                <div
                  className={`h-6 w-6 rounded-full  inline-flex
                 justify-center items-center border   text-sm ${
                   activeStep === 2
                     ? 'bg-indigo-500 text-white border-indigo-500'
                     : 'border-[#667085] bg-white'
                 }`}
                >
                  2
                </div>
                <h3>Map Data</h3>
              </div>
              <div className="flex flex-col items-center z-10 relative">
                <div
                  className={`h-6 w-6 rounded-full  inline-flex
                 justify-center items-center border   text-sm ${
                   activeStep === 3
                     ? 'bg-indigo-500 text-white border-indigo-500'
                     : 'border-[#667085] bg-white'
                 }`}
                >
                  3
                </div>
                <h3>Import</h3>
              </div>
            </div>
            {activeStep === 1 && (
              <Step1
                error={fileError}
                setError={setFileError}
                file={file}
                setFile={setFile}
              />
            )}
            {activeStep === 2 && (
              <Step2
                headersObj={headersObj}
                setHeadersObj={setHeadersObj}
                headers={headers}
                HeadersColumns={HeadersColumns}
              />
            )}
            {activeStep === 3 && (
              <Step3
                mappedData={mappedData}
                tableRows={tableRows}
                setTableRows={setTableRows}
                checkRowsError={checkRowsError}
                getCheckedAccountsCount={getCheckedAccountsCount}
              />
            )}
          </div>
        </main>
        <footer
          className="absolute bottom-0 h-16 bg-white w-full border-t border-[#D0D5DD80] px-6
        flex justify-between items-center"
        >
          {activeStep === 1 ? (
            <Link to="/accounts">
              <button
                // disabled
                className="btn h-fit border-[#667085] rounded-full px-6 font-medium disabled:opacity-80 disabled:cursor-not-allowed"
                type="button"
              >
                Back to Accounts
              </button>
            </Link>
          ) : (
            <button
              onClick={() => {
                if (activeStep > 1) {
                  setActiveStep((prev) => prev - 1);
                }
              }}
              // disabled
              className="btn h-fit border-[#667085] rounded-full px-6 font-medium disabled:opacity-80 disabled:cursor-not-allowed"
              type="button"
            >
              Back
            </button>
          )}
          <button
            disabled={isDisabled() || isLoading}
            className="z-[2000000002] btn h-fit border-[#667085] rounded-full px-6 !bg-indigo-500 text-white font-medium border-indigo-500
            disabled:!bg-indigo-400 disabled:cursor-not-allowed"
            onClick={onClickNext}
            type="button"
          >
            {isLoading && (
              <svg
                className="animate-spin w-4 h-4 fill-current shrink-0 mr-2"
                viewBox="0 0 16 16"
              >
                <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z" />
              </svg>
            )}

            {activeStep === 3 ? 'Import' : 'Next'}
          </button>
        </footer>
      </>
    </AppLayout>
  );
};

export default ImportAccounts;
