import React, { useState, useEffect } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { phone as phoneFormatter } from 'phone';
import TextInputDash from '../../components/TextInputDash';
import AlertBox from '../../components/AlertBox';
import DropdownFull from '../../components/DropdownFull';
import States from '../../data/states.json';
import UploadFile from '../../components/UploadFile';
import { BussinessDropdown1 } from '../createworkspace/data';
import { getBusinessStructuresData } from '../../API/backend_helper';
import { Loader } from '../../components/Svg';
import Tooltip from '../../components/Tooltip';
import { toast } from 'react-toastify';

const WorkspaceDetails = ({
  successAlert,
  team,
  handleSubmit,
  countries,
  currencies,
  getBusinessSubTypes,
  imgPreview,
  setImgPreview,
  detailsLoading,
  files,
  setFiles,
  setCropModalOpen,
  setCurrentImageForCrop,
  setImgName,
}) => {
  const [structures, setStructures] = useState([]);
  const [subStructures, setSubStructures] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [copied, setCopied] = useState(false);

  const loadStructures = async (id) => {
    try {
      const res = await getBusinessStructuresData(id);
      setStructures(res);
      return res;
    } catch (err) {
      console.log('err', err);
    }
  };

  const findSubStructures = async (name, arr) => {
    const localStructures = arr || structures;
    const structure = localStructures.find((d) => d.name === name);
    if (structure) {
      const subStructures = localStructures.filter(
        (d) => d.parentId === structure.id,
      );
      if (subStructures.length > 0) {
        setSubStructures(subStructures);
      } else {
        setSubStructures([]);
      }
    }
  };

  const copyToClipboard = () => {
    navigator.clipboard
      .writeText(
        team?.billEmail?.includes('@bills.getcount.com')
          ? team?.billEmail
          : `${team?.billEmail}@bills.getcount.com`,
      )
      .then(() => {
        setCopied(true);
        setTimeout(() => {
          setCopied(false);
        }, 3000);
        // setIsOpen(false);
      })
      .catch(() => {
        toast.error('Something went wrong');
      });
  };

  useEffect(() => {
    if (team) {
      loadData();
    }
  }, [team]);

  const loadData = async () => {
    const res = await loadStructures(team?.country);
    await findSubStructures(team?.type, res);
  };

  return isLoading ? (
    <div className="w-full h-full flex items-center justify-center">
      <Loader />
    </div>
  ) : (
    <section className="pb-5 border-b border-[#D0D5DD80] gap-2 w-full">
      <h3 className="text-lg font-medium text-[#101828B2] mb-4">Details</h3>
      {successAlert && (
        <AlertBox
          type="success"
          heading="Successfully updated workspace"
          text={`Workspace details for ${team?.name} have been updated.`}
          hideActionButton
        />
      )}
      <div className="flex items-end gap-2 w-full">
        <Formik
          enableReinitialize
          initialValues={{
            email: team?.email || '',
            name: team?.name || '',
            type: team?.type || '',
            subType: team?.subType || '',
            website: team?.website || '',
            country: team?.country || '',
            state: team?.state || '',
            currency: team?.currency || '',
            phone: team?.phone || '',
            billEmail:
              team?.billEmail?.replace('@bills.getcount.com', '') || '',
            businessTaxNumber: team?.businessTaxNumber || '',
            businessAddress: {
              street: team?.businessAddress?.street || '',
              street2: team?.businessAddress?.street2 || '',
              city: team?.businessAddress?.city || '',
              zipCode: team?.businessAddress?.zipCode || '',
            },
          }}
          validationSchema={Yup.object({
            name: Yup.string()
              .trim()
              .required('Please Enter Name')
              .max(100, 'Name should be less than 100 characters'),
            email: Yup.string()
              .matches(
                /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                'Please enter valid email',
              )
              .optional('Please Enter Email')
              .nullable(),
            type: Yup.string().required('Please Select Type'),
            billEmail: Yup.string()
              .test(
                'no-email',
                'Bill Email should not include email-like patterns',
                (value) => {
                  const emailPattern = /[@.]/;
                  return !emailPattern.test(value);
                },
              )
              .required('Please Enter Bill Email'),
            subType: Yup.string(),
            website: Yup.string().max(
              100,
              'Website can has maximum 100 characters',
            ),
            businessTaxNumber: Yup.string(),
            country: Yup.string().trim().required('Please Select Country'),
            state: Yup.string().optional('Please Select State'),
            currency: Yup.string(),
            businessAddress: Yup.object({
              street: Yup.string().max(
                50,
                'Address 1 can has maximum 50 characters',
              ),
              street2: Yup.string().max(
                50,
                'Address 2 can has maximum 50 characters',
              ),
              city: Yup.string().max(50, 'City  can has maximum 50 characters'),
              zipCode: Yup.string().max(
                50,
                'Zip Code  can has maximum 50 characters',
              ),
            }),
          })}
          validate={(values) => {
            const errors = {};

            if (subStructures.length > 0) {
              if (!values.subType) {
                errors.subType = 'Please Select Business Sub Type';
              }
            }
            if (values.country === 'US') {
              if (!values.state) {
                errors.state = 'Please Select State';
              }
            }
            if (structures.length > 0 && !values.type) {
              errors.type = 'Please Select Business Type';
            }
            const { phone } = values;
            if (
              phone &&
              !phoneFormatter(phone, { validateMobilePrefix: false })?.isValid
            ) {
              errors.phone = 'Phone number is not valid';
            }
            return errors;
          }}
          onSubmit={handleSubmit}
          // innerRef={formikRef}
        >
          {(validation) => (
            <Form>
              <div className="flex flex-col gap-2 w-full">
                <div className="flex flex-col sm:flex-row gap-y-2 gap-x-4 items-center w-full">
                  <div className="w-full sm:w-1/2">
                    <TextInputDash
                      label="Name"
                      id="name"
                      name="name"
                      placeholder="Name"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.name || ''}
                      error={validation.touched.name && validation.errors.name}
                      required
                    />
                  </div>
                  <div className="w-full sm:w-1/2">
                    <TextInputDash
                      label="Website"
                      id="website"
                      name="website"
                      placeholder="Website"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.website || ''}
                      error={
                        validation.touched.website && validation.errors.website
                      }
                    />
                  </div>
                </div>
                <div className="flex flex-col sm:flex-row gap-y-2 gap-x-4  gap-4 items-center w-full">
                  <div className=" w-full sm:w-1/2">
                    <TextInputDash
                      label="Business Email"
                      id="email"
                      name="email"
                      placeholder="Email"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.email || ''}
                      error={
                        validation.touched.email && validation.errors.email
                      }
                    />
                  </div>
                  <div className="w-full sm:w-1/2">
                    <TextInputDash
                      label="Phone Number"
                      id="phone"
                      name="phone"
                      placeholder="Phone"
                      value={validation.values.phone || ''}
                      error={
                        validation.touched.phone && validation.errors.phone
                      }
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                    />
                  </div>
                  <div className="w-full sm:w-1/2">
                    <TextInputDash
                      label="Business Tax Number"
                      id="businessTaxNumber"
                      name="businessTaxNumber"
                      placeholder="Business Tax Number"
                      value={validation.values.businessTaxNumber || ''}
                      error={
                        validation.touched.businessTaxNumber &&
                        validation.errors.businessTaxNumber
                      }
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                    />
                  </div>
                </div>
                <div className="flex flex-col items-start  max-w-[90vw] overflow-auto mb-[1.875rem] ">
                  <label className="text-sm font-medium text-slate-600 block">
                    Address
                  </label>
                  <div className="flex flex-row w-full h-12 border border-[#D0D5DD] px-4 py-[0.438] rounded-[0.313rem] items-center self-stretch bg-white shadow-sm justify-between overflow-x-auto scrollbar">
                    <input
                      id="businessAddress.street"
                      name="businessAddress.street"
                      placeholder="Address"
                      value={validation.values.businessAddress.street || ''}
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      className="form-input w-[12.5rem] items-center text-slate-600 placeholder:text-slate-600 placeholder:text-opacity-80  border-none shadow-none"
                    />
                    <div className="bg-[#D0D5DD] h-[2.125rem] w-px" />
                    <input
                      id="businessAddress.street2"
                      name="businessAddress.street2"
                      placeholder="Address 2 (Optional)"
                      value={validation.values.businessAddress.street2 || ''}
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      className="form-input w-[12.5rem] items-center text-slate-600 placeholder:text-slate-600 placeholder:text-opacity-80  border-none shadow-none"
                    />
                    <div className="bg-[#D0D5DD] h-[2.125rem] w-px" />
                    <input
                      id="businessAddress.city"
                      name="businessAddress.city"
                      placeholder="City"
                      value={validation.values.businessAddress.city || ''}
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      className="form-input w-[9.188rem] items-center text-slate-600 placeholder:text-slate-600 placeholder:text-opacity-80  border-none shadow-none"
                    />
                    <div className="bg-[#D0D5DD] h-[2.125rem] w-px" />
                    <input
                      id="businessAddress.zipCode"
                      name="businessAddress.zipCode"
                      placeholder="Postal"
                      value={validation.values.businessAddress.zipCode || ''}
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      className="form-input w-[9.188rem] items-center text-slate-600 placeholder:text-slate-600 placeholder:text-opacity-80  border-none shadow-none"
                    />
                  </div>
                  <div className="flex pt-2 flex-row text-sm leading-tight w-full space-x-2 px-4 self-stretch bg-white justify-around overflow-x-auto scrollbar">
                    <div className="w-[200px]  text-rose-500">
                      {validation.touched?.businessAddress?.street &&
                        validation.errors?.businessAddress?.street}
                    </div>

                    <div className="w-[200px]  text-rose-500">
                      {validation.touched?.businessAddress?.street2 &&
                        validation.errors?.businessAddress?.street2}
                    </div>
                    <div className="w-[147px]  text-rose-500">
                      {validation.touched?.businessAddress?.city &&
                        validation.errors?.businessAddress?.city}
                    </div>
                    <div className="w-[147px] text-rose-500">
                      {validation.touched?.businessAddress?.zipCode &&
                        validation.errors?.businessAddress?.zipCode}
                    </div>
                  </div>
                </div>
                <div className="flex flex-col sm:flex-row gap-y-2  gap-4 items-center w-full">
                  <div
                    className={`${
                      validation.values.country === 'US'
                        ? 'w-full sm:w-1/3'
                        : 'w-full sm:w-1/2'
                    } pb-5`}
                  >
                    <label
                      className="text-sm font-medium text-slate-600 block mb-2"
                      htmlFor="country"
                    >
                      Business Country <span className="text-red-500">*</span>
                    </label>
                    <Tooltip
                      content="For the time being, we are not permitting changes to the workspace country. Please note that the country can only be selected during workspace creation"
                      contentClassName="border-none text-wrap overflow-visible w-[300px] text-sm text-[#667085] relative"
                      position="right"
                    >
                      <div className="mb-1">
                        <DropdownFull
                          options={countries?.map((c) => ({
                            ...c,
                            id: c.isoCode,
                          }))}
                          label="Business Country"
                          id="country"
                          name="name"
                          setSelected={(id) => {
                            validation.setFieldValue('country', id);
                            // validation.setFieldValue(
                            //   "currency",
                            //   countries?.find((c) => c?.isoCode === id)
                            //     ?.defaultCurrency
                            // );
                            validation.setFieldValue('type', '');
                            validation.setFieldValue('subType', '');
                            loadStructures(id);
                            setSubStructures([]);
                          }}
                          selected={validation.values.country}
                          error={
                            !validation.values.country &&
                            validation.touched.country
                              ? validation.errors.country
                              : ''
                          }
                          onBlur={validation.handleBlur}
                          required
                          disabled
                        />
                      </div>
                    </Tooltip>
                  </div>
                  {validation.values.country === 'US' && (
                    <div className="w-full sm:w-1/3 pb-5">
                      <label
                        className="text-sm font-medium text-slate-600 block mb-2"
                        htmlFor="state"
                      >
                        State <span className="text-red-500">*</span>
                      </label>
                      <div className="mb-1">
                        <DropdownFull
                          options={States.map((s) => ({
                            id: s.abbreviation,
                            state: `${s.name} (${s.abbreviation})`,
                          }))}
                          id="state"
                          label="State"
                          name="state"
                          setSelected={(id) => {
                            validation.setFieldValue('state', id);
                          }}
                          selected={validation.values.state}
                          error={
                            !validation.values.state && validation.touched.state
                              ? validation.errors.state
                              : ''
                          }
                          onBlur={validation.handleBlur}
                          required
                        />
                      </div>
                    </div>
                  )}
                  <div
                    className={`${
                      validation.values.country === 'US'
                        ? 'w-full sm:w-1/3'
                        : 'w-full sm:w-1/2'
                    } pb-5`}
                  >
                    <label
                      className="text-sm font-medium text-slate-600 block mb-2"
                      htmlFor="currency"
                    >
                      Business Currency <span className="text-red-500">*</span>
                    </label>
                    <Tooltip
                      content="For the time being, we are not permitting changes to the workspace currency. Please note that the currency can only be selected during workspace creation"
                      contentClassName="border-none text-wrap overflow-visible w-[300px] text-sm text-[#667085] relative tooltipArrowDown"
                    >
                      <div className="mb-1">
                        <DropdownFull
                          options={currencies?.map((c) => ({
                            ...c,
                            id: c?.isoCode,
                            name: `${c?.symbol} ${c?.name}`,
                          }))}
                          id="currency"
                          label="Business Currency"
                          name="name"
                          setSelected={(id) =>
                            validation.setFieldValue('currency', id)
                          }
                          selected={validation.values.currency}
                          error={
                            validation.touched.currency
                              ? validation.errors.currency
                              : ''
                          }
                          onBlur={validation.handleBlur}
                          disabled
                        />
                      </div>
                    </Tooltip>
                  </div>
                </div>
                <div className="flex gap-4 items-center w-full">
                  {validation.values.country && structures.length > 0 && (
                    <div
                      className={`${
                        subStructures?.length === 0
                          ? 'w-full'
                          : 'w-full sm:w-1/2'
                      } pb-5`}
                    >
                      <label
                        className="text-sm font-medium text-slate-600 block mb-2"
                        htmlFor="type"
                      >
                        Business Type <span className="text-red-500">*</span>
                      </label>
                      <div
                        className={`${
                          validation.touched.subType &&
                          validation.errors.subType
                            ? 'mb-6'
                            : 'mb-1'
                        }`}
                      >
                        <DropdownFull
                          options={structures
                            .filter((d) => d.parentId === null)
                            .map((d) => ({
                              id: d.name,
                              type: d.name,
                            }))}
                          label="Business Type"
                          id="type"
                          name="type"
                          required
                          setSelected={(id) => {
                            validation.setFieldValue('type', id);
                            findSubStructures(id);
                            validation.setFieldValue('subType', '');
                          }}
                          selected={validation.values.type}
                          error={
                            !validation.values.type && validation.touched.type
                              ? validation.errors.type
                              : ''
                          }
                          onBlur={validation.handleBlur}
                        />
                      </div>
                    </div>
                  )}
                </div>
                <div className="w-full sm:w-1/2 mb-2.5">
                  <label
                    className={`text-sm font-medium text-slate-600 block mb-2 ${
                      validation.touched.billEmail &&
                      validation.errors.billEmail
                        ? '!text-rose-400'
                        : ''
                    }`}
                    htmlFor="billEmail"
                  >
                    Bill Email
                    <span className="text-rose-500 pl-1">*</span>
                  </label>
                  <div className="relative flex flex-col w-full min-h-[60px]">
                    <input
                      id="billEmail"
                      name="billEmail"
                      className={`form-input min-w-full min-h-[48px] pr-[180px] ${
                        validation.errors.billEmail &&
                        validation.touched.billEmail
                          ? '!border-rose-500'
                          : ''
                      }
                           [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none `}
                      min={0}
                      placeholder="Bill Email"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.billEmail}
                    />

                    <span className="absolute text-base text-slate-800 top-[12px] text-nowrap right-8">
                      @bills.getcount.com
                    </span>
                    <span
                      onClick={copyToClipboard}
                      role="button"
                      tabIndex="0"
                      className="absolute right-1 top-3.5"
                    >
                      <Tooltip
                        content={copied ? 'Copied' : 'Copy'}
                        contentClassName="border-none overflow-visible text-sm text-[#667085] relative tooltipArrowDown"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 448 512"
                          className="h-5 w-5 fill-slate-800 cursor-pointer"
                        >
                          <path d="M384 336l-192 0c-8.8 0-16-7.2-16-16l0-256c0-8.8 7.2-16 16-16l140.1 0L400 115.9 400 320c0 8.8-7.2 16-16 16zM192 384l192 0c35.3 0 64-28.7 64-64l0-204.1c0-12.7-5.1-24.9-14.1-33.9L366.1 14.1c-9-9-21.2-14.1-33.9-14.1L192 0c-35.3 0-64 28.7-64 64l0 256c0 35.3 28.7 64 64 64zM64 128c-35.3 0-64 28.7-64 64L0 448c0 35.3 28.7 64 64 64l192 0c35.3 0 64-28.7 64-64l0-32-48 0 0 32c0 8.8-7.2 16-16 16L64 464c-8.8 0-16-7.2-16-16l0-256c0-8.8 7.2-16 16-16l32 0 0-48-32 0z" />
                        </svg>
                      </Tooltip>
                    </span>

                    {validation.touched.billEmail &&
                      validation.errors.billEmail && (
                        <div className="absolute bottom-[-10px] mt-1 text-xs text-rose-400">
                          {validation.touched.billEmail &&
                            validation.errors.billEmail}
                        </div>
                      )}
                  </div>
                </div>
                {validation.values.type && subStructures?.length > 0 && (
                  <div className="w-1/2 pb-5">
                    <label
                      className="text-sm font-medium text-slate-600 block mb-2"
                      htmlFor="type"
                    >
                      {`What type of ${validation.values.type}`}{' '}
                      <span className="text-red-500">*</span>
                    </label>
                    <div className="mb-1">
                      <DropdownFull
                        options={subStructures.map((d) => ({
                          id: d.name,
                          subType: d.name,
                        }))}
                        required
                        id="subType"
                        label={`What type of ${validation.values.type}`}
                        onBlur={validation.handleBlur}
                        name="subType"
                        setSelected={(id) => {
                          validation.setFieldValue('subType', id);
                        }}
                        selected={validation.values.subType}
                        error={
                          validation.touched.subType
                            ? validation.errors.subType
                            : ''
                        }
                      />
                    </div>
                  </div>
                )}

                <div className="w-full">
                  <UploadFile
                    title="Logo"
                    onFilesAdded={(files) => {
                      setFiles((prevFiles) => [...prevFiles, ...files]);
                      if (files[0]) {
                        const filePreviewUrl = URL.createObjectURL(files[0]);
                        setCurrentImageForCrop(filePreviewUrl);
                        setCropModalOpen(true);
                        setImgName(files[0].name);
                      }
                    }}
                    name="logo"
                    id="logo"
                    imgPreview={imgPreview}
                    setImgPreview={setImgPreview}
                    files={files}
                    setFiles={setFiles}
                  />
                </div>
                <div>
                  <button
                    type="submit"
                    className="btn   h-fit py-[11px] mt-[7px] px-[10px] sm:px-[20px] lg:px-[30px] whitespace-nowrap text-base !bg-indigo-500
                                  hover:!bg-indigo-600 shadow-md hover:shadow-lg text-white disabled:!bg-indigo-300 disabled:cursor-not-allowed"
                    disabled={detailsLoading}
                  >
                    {detailsLoading && (
                      <svg
                        className="animate-spin w-4 h-4 fill-current shrink-0 mr-2"
                        viewBox="0 0 16 16"
                      >
                        <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z" />
                      </svg>
                    )}
                    Save Changes
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </section>
  );
};

export default WorkspaceDetails;
