import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ImageCropModal from 'components/ImageCropModal';
import Header from '../../partials/Header';
import Tabs from './Tabs';
import FirmIcon from './FirmIcon';
import Overview from './overview';
import Clients from './clients';
import { getAccountantFirms } from '../../API/backend_helper';
import EditAccountantFirmModal from './EditAccountantFirmModal';

const OVERVIEW_TAB = 'overviewTab';
const INBOX_TAB = 'inboxTab';
const CLIENTS_TAB = 'clientsTab';

const AccountantFirm = () => {
  const { tab } = useParams();

  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [activeTab, setActiveTab] = useState(OVERVIEW_TAB);
  const [loading, setLoading] = useState(false);
  const [accountantFirm, setAccountantFirm] = useState({});
  const [updateAccountantFirmModalOpen, setUpdateAccountantFirmModalOpen] =
    useState(false);
  const [isAccountingFirmFound, setIsAccountingFirmFound] = useState(false);

  // Firm Logo
  const [cropModalOpen, setCropModalOpen] = useState(false);
  const [currentImageForCrop, setCurrentImageForCrop] = useState(null);
  // File Management
  const [files, setFiles] = useState([]);
  const [imgPreview, setImgPreview] = useState('');
  const [imgName, setImgName] = useState('');

  const loadAccountantFirm = async ({ loading = false } = {}) => {
    if (loading) {
      setLoading(true);
    }
    try {
      const res = await getAccountantFirms();
      if (res?.data?.result?.accountingFirms?.length > 0) {
        setIsAccountingFirmFound(true);
        setAccountantFirm(res?.data?.result?.accountingFirms?.[0]);
      } else {
        setIsAccountingFirmFound(false);
      }
    } catch (err) {
      console.log('err', err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (tab === 'clients') {
      setActiveTab(CLIENTS_TAB);
    }
  }, [tab]);

  useEffect(() => {
    loadAccountantFirm({ loading: true });
  }, []);

  return (
    <div className="flex h-[100dvh] overflow-hidden">
      <EditAccountantFirmModal
        isOpen={updateAccountantFirmModalOpen}
        setIsOpen={setUpdateAccountantFirmModalOpen}
        accountantFirm={accountantFirm}
        loadAccountantFirm={loadAccountantFirm}
        // firm logo
        setFiles={setFiles}
        setCurrentImageForCrop={setCurrentImageForCrop}
        setCropModalOpen={setCropModalOpen}
        setImgName={setImgName}
        imgPreview={imgPreview}
        setImgPreview={setImgPreview}
        files={files}
        cropModalOpen={cropModalOpen}
      />
      <ImageCropModal
        isOpen={cropModalOpen}
        setIsOpen={setCropModalOpen}
        image={currentImageForCrop}
        setPreviewImage={setImgPreview}
        setFiles={setFiles}
        imgName={imgName}
      />
      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        {/*  Site header */}
        <Header
          showHamburger={false}
          sidebarOpen={sidebarOpen}
          setSidebarOpen={setSidebarOpen}
        />

        <main className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
          <h1 className="mb-10 text-2xl md:text-[36px] text-[#666666] dark:text-slate-100 font-medium ">
            Accountant Practice
          </h1>

          {loading ? (
            <div className="flex flex-col gap-2 items-center pb-2 justify-center mt-8">
              <svg
                width="29"
                height="29"
                viewBox="0 0 29 29"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="animate-spin w-10 h-10 fill-current shrink-0"
              >
                <path
                  d="M14.5 2.41699V7.25033"
                  stroke="#E48642"
                  strokeWidth="2.55882"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M14.5 21.749V26.5824"
                  stroke="#E48642"
                  strokeWidth="2.55882"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M5.95703 5.95703L9.37662 9.37662"
                  stroke="#E48642"
                  strokeWidth="1.58955"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M19.623 19.6211L23.0426 23.0407"
                  stroke="#E48642"
                  strokeWidth="1.58955"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M2.41699 14.5H7.25033"
                  stroke="#E48642"
                  strokeWidth="1.58955"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M21.749 14.5H26.5824"
                  stroke="#E48642"
                  strokeWidth="1.58955"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M5.95703 23.0407L9.37662 19.6211"
                  stroke="#E48642"
                  strokeWidth="1.58955"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M19.623 9.37662L23.0426 5.95703"
                  stroke="#E48642"
                  strokeWidth="1.58955"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <p className="text-slate-600 font-medium text-[0.875rem] text-center">
                Fetching data, please wait...
              </p>
            </div>
          ) : (
            <>
              {/* Accountant Header  */}
              {isAccountingFirmFound && (
                <div className="flex justify-between items-center mb-12 gap-4 flex-wrap">
                  <div className="flex items-center gap-10 pl-6 ">
                    <div className="min-h-20 min-w-20 h-20 w-20 rounded-full inline-flex justify-center items-center bg-[#EDEDED]">
                      {accountantFirm?.logoUrl ? (
                        <img
                          src={accountantFirm?.logoUrl}
                          alt="practice_logo"
                          className="h-full w-full rounded-full"
                        />
                      ) : (
                        <FirmIcon />
                      )}
                    </div>
                    <div>
                      <h3 className="text-[24px] font-medium text-[#101828] leading-tight mb-2">
                        {accountantFirm?.name}
                      </h3>
                      {/* <p className="text-sm text-[#667085] leading-tight m-0">
                      Accounts Consultant company
                    </p> */}
                    </div>
                  </div>
                  <button
                    className="h-10 ml-auto px-8 border border-indigo-500 text-sm text-indigo-500 rounded-[5px] shadow-button"
                    type="button"
                    onClick={() => setUpdateAccountantFirmModalOpen(true)}
                  >
                    Edit
                  </button>
                </div>
              )}
              {/* Tabs  */}
              <Tabs
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                OVERVIEW_TAB={OVERVIEW_TAB}
                INBOX_TAB={INBOX_TAB}
                CLIENTS_TAB={CLIENTS_TAB}
              />
              {activeTab === OVERVIEW_TAB && (
                <Overview
                  setActiveTab={setActiveTab}
                  CLIENTS_TAB={CLIENTS_TAB}
                  isAccountingFirmFound={isAccountingFirmFound}
                />
              )}
              {activeTab === CLIENTS_TAB && <Clients />}
            </>
          )}
        </main>
      </div>
    </div>
  );
};

export default AccountantFirm;
