import Tooltip from 'components/Tooltip';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { EditIcon, EmptyWorkflows, RightArrow, TrashIcon } from './svg';

const AutomationTable = ({
  handleActivateRule,
  setActivationModalOpen,
  createConditionText,
  createActionText,
  setActiveRule,
  setActiveAutomation,
  setDeleteModalOpen,
  rules,
}) => {
  const navigate = useNavigate();
  return rules?.length > 0 ? (
    <div className="w-full pb-16 items-start flex overflow-x-hidden scrollbar overflow-y-visible min-h-[50vh]">
      <table className="w-full flex flex-col items-start">
        <thead className="flex w-full items-center gap-6 rounded-[0.313rem] shadow-md bg-white h-[3.5rem] mb-1">
          <th className="text-slate-600 text-left text-[14px] font-medium leading-[1.125rem] w-[25%] pl-2 flex-shrink-0 flex-grow-0 ">
            Name
          </th>
          <th className="text-slate-600 text-left text-[14px] font-medium leading-[1.125rem] w-[40%] flex-shrink-0 flex-grow-0 ">
            Rule
          </th>
          <th className="justify-center text-slate-600 ml-auto text-left text-[14px] font-medium leading-[1.125rem] w-[5%] flex-shrink-0 flex-grow-0 ">
            Active
          </th>
          <th className="justify-center text-slate-600 ml-auto mr-6 text-left text-[14px] font-medium leading-[1.125rem] w-[5%] flex-shrink-0 flex-grow-0 ">
            Actions
          </th>
        </thead>
        <tbody className="w-full flex flex-col items-start">
          {rules?.map((rule) => (
            <tr
              key={rule?.id}
              className="flex w-full items-center gap-6 bg-white border-b border-[#D0D5DD] min-h-[7rem] h-fit py-2"
            >
              <td className=" text-slate-600 text-left text-[16px] pl-2 leading-6 w-[25%] flex-shrink-0 flex-grow-0 break-words">
                {rule?.name}
              </td>
              <td className=" whitespace-nowrap text-slate-600 text-left text-[16px] leading-6 w-[40%] flex-shrink-0 flex-grow-0">
                <div>
                  <span className="font-medium">When</span>
                  {rule?.trigger?.entity === 'transaction'
                    ? ' a transaction'
                    : rule?.trigger?.entity === 'estimateStatus'
                      ? ' an estimate  '
                      : ' a product stock'}
                  <br />
                  {rule?.conditions?.map((condition, index) => (
                    <div
                      key={index}
                      className="ml-6 flex items-center gap-1 w-fit"
                    >
                      <RightArrow />
                      <div className="flex gap-1">
                        {createConditionText(condition, rule?.trigger?.entity)}
                      </div>
                    </div>
                  ))}
                </div>
                <div>
                  <span className="font-medium">Then</span> <br />
                  {rule?.actions?.map((action, index) => (
                    <div key={index} className="ml-6 flex items-center gap-1">
                      <RightArrow />
                      <div className="flex gap-1">
                        {createActionText(action, rule?.trigger?.entity)}
                      </div>
                    </div>
                  ))}
                </div>
              </td>
              <td className="justify-center whitespace-nowrap text-slate-600 text-left text-[16px] leading-6 w-[5%] flex-shrink-0 flex-grow-0 ml-auto">
                <div className="form-switch">
                  <input
                    type="checkbox"
                    id={`switch-${rule?.id}`}
                    className="sr-only"
                    checked={rule?.enabled}
                    onChange={() => {
                      if (rule?.enabled) {
                        handleActivateRule(rule?.id, false, false);
                      } else if (rule?.trigger?.entity === 'transaction') {
                        setActivationModalOpen(true);
                        setActiveRule(rule);
                      } else {
                        handleActivateRule(rule?.id, true, false);
                      }
                    }}
                  />
                  <label
                    className="bg-white border border-gray-300 border-opacity-50 shadow-sm"
                    htmlFor={`switch-${rule?.id}`}
                  >
                    <span className="bg-gray-300" aria-hidden="true" />
                    <span className="sr-only">Switch label</span>
                  </label>
                </div>
              </td>
              <td className=" whitespace-nowrap text-slate-600 text-left text-[16px] justify-center mr-6 leading-6 w-[5%] flex-shrink-0 flex-grow-0 flex gap-2 ml-auto">
                <Tooltip
                  content="Edit Automation"
                  contentClassName="border-none overflow-visible text-sm text-[#667085] relative z-60"
                >
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                      navigate(`/automation-builder?id=${rule?.id}`);
                    }}
                    tabIndex="0"
                    role="button"
                  >
                    <EditIcon />
                  </div>
                </Tooltip>
                <Tooltip
                  content="Delete Automation"
                  contentClassName="border-none overflow-visible text-sm text-[#667085] right-[2rem] relative"
                >
                  <div
                    className="cursor-pointer"
                    onClick={(e) => {
                      e.stopPropagation();
                      setActiveAutomation(rule?.id);
                      setDeleteModalOpen(true);
                    }}
                    tabIndex="0"
                    role="button"
                  >
                    <TrashIcon />
                  </div>
                </Tooltip>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  ) : (
    <div className="w-full py-8 flex flex-col items-center gap-2 rounded-xl">
      <div className="flex-1 flex flex-col justify-center items-center">
        <EmptyWorkflows />
        <h1 className="text-black text-opacity-60 text-4xl font-medium leading-[2.375rem]">
          Automations
        </h1>
        <p className="text-sm text-[#667085] max-w-[425px] text-center mb-8 leading-tight">
          Create an automation to perform tasks with the click of a button.
        </p>
        <button
          onClick={() => {
            navigate('/automation-builder');
          }}
          className="flex h-11 py-2.5 px-[1.125rem] justify-center items-center gap-2 rounded-[0.313rem] bg-indigo-500 border border-indigo-500 shadow-sm text-white text-[1rem] leading-6"
          type="button"
        >
          <svg
            width="21"
            height="20"
            viewBox="0 0 21 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.5 4.16797V15.8346M4.66669 10.0013H16.3334"
              stroke="white"
              strokeWidth="1.66667"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <span>Add an Automation</span>
        </button>
      </div>
    </div>
  );
};

export default AutomationTable;
