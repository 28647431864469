import React, { useEffect, useState } from 'react';
import { generateStripeToConnect } from '../API/backend_helper';

const StripeWarning = ({ teamProp }) => {
  const [link, setLink] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const updateLink = async () => {
      try {
        setIsLoading(true);
        const res = await generateStripeToConnect();
        setLink(res?.data?.onboardinLink);
        setIsLoading(false);
      } catch (e) {
        setIsLoading(false);
        console.log('error', e);
      }
    };
    if (
      teamProp?.stripeAccountId &&
      (parseInt(teamProp?.stripeAlertCount) > 0 ||
        parseInt(teamProp?.missingRequiredFieldsCount) > 0 ||
        parseInt(teamProp?.pendingVerificationCount) > 0)
    ) {
      updateLink();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamProp]);

  if (
    !teamProp ||
    (teamProp?.stripeAccountId &&
      parseInt(teamProp?.stripeAlertCount) === 0 &&
      parseInt(teamProp?.missingRequiredFieldsCount) === 0 &&
      parseInt(teamProp?.pendingVerificationCount) === 0)
  ) {
    return null;
  }

  return (
    <div
      className={`w-full border  p-4 flex items-center self-stretch gap-6 rounded-xl  my-5 mt-0
    ${
      parseInt(teamProp?.stripeAlertCount) > 0
        ? 'bg-[#FFFBFA] border-[#B42318] text-[#B42318]'
        : parseInt(teamProp?.missingRequiredFieldsCount) > 0
          ? 'bg-[#FFFCF5] border-[#FEC84B] text-[#B54708]'
          : 'bg-[#D0D5DD1A] border-[#D0D5DD] text-[#667085]'
    }
    `}
    >
      <div className="flex items-center gap-4">
        {parseInt(teamProp?.missingRequiredFieldsCount) > 0 ? (
          <svg
            width="20"
            height="17"
            viewBox="0 0 20 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.0001 6.50019V9.83353M10.0001 13.1669H10.0085M8.84622 2.24329L1.99215 14.0821C1.61198 14.7388 1.42189 15.0671 1.44999 15.3366C1.47449 15.5716 1.59763 15.7852 1.78876 15.9242C2.0079 16.0835 2.38728 16.0835 3.14605 16.0835H16.8542C17.613 16.0835 17.9923 16.0835 18.2115 15.9242C18.4026 15.7852 18.5258 15.5716 18.5503 15.3366C18.5783 15.0671 18.3883 14.7388 18.0081 14.0821L11.154 2.24329C10.7752 1.58899 10.5858 1.26184 10.3387 1.15196C10.1232 1.05612 9.87709 1.05612 9.66154 1.15196C9.41443 1.26184 9.22503 1.58899 8.84622 2.24329Z"
              stroke="#B54708"
              strokeWidth="1.66667"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        ) : (
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.99935 13.3337V10.0003M9.99935 6.66699H10.0077M18.3327 10.0003C18.3327 14.6027 14.6017 18.3337 9.99935 18.3337C5.39698 18.3337 1.66602 14.6027 1.66602 10.0003C1.66602 5.39795 5.39698 1.66699 9.99935 1.66699C14.6017 1.66699 18.3327 5.39795 18.3327 10.0003Z"
              stroke={
                parseInt(teamProp?.stripeAlertCount) > 0 ? '#B42318' : '#667085'
              }
              strokeWidth="1.66667"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        )}

        <p className="text-left font-medium leading-tight">
          {parseInt(teamProp?.stripeAlertCount) > 0 ? (
            <>
              An error occurred in your connected stripe account (
              {teamProp?.stripeAccountId}) which needs to be fixed!
            </>
          ) : parseInt(teamProp?.missingRequiredFieldsCount) > 0 ? (
            <p>Please complete the steps to connect your Stripe account</p>
          ) : parseInt(teamProp?.pendingVerificationCount) > 0 ? (
            <>Your Stripe account verification is in progress... </>
          ) : null}
        </p>
      </div>
      {(parseInt(teamProp?.missingRequiredFieldsCount) > 0 ||
        parseInt(teamProp?.stripeAlertCount) > 0) && (
        <div className="flex justify-end items-center gap-5 flex-grow flex-shrink-0 flex-basis-0">
          {link ? (
            <a href={link}>
              <button
                className={`whitespace-nowrap font-normal rounded-[0.313rem] flex w-[7.875rem] h-11 py-2.5 px-[1.125rem] items-center justify-center gap-2 text-white
            ${
              parseInt(teamProp?.stripeAlertCount) > 0
                ? 'bg-[#B42318]'
                : parseInt(teamProp?.missingRequiredFieldsCount) > 0
                  ? 'bg-[#B54708]'
                  : 'bg-[#667085]'
            }

            `}
                type="button"
              >
                {isLoading ? (
                  <svg
                    className="animate-spin w-4 h-4 fill-current shrink-0 mr-2"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z" />
                  </svg>
                ) : parseInt(teamProp?.missingRequiredFieldsCount) > 0 ? (
                  'Complete'
                ) : (
                  'Resolve Now'
                )}
              </button>
            </a>
          ) : (
            <button
              className={`whitespace-nowrap font-normal rounded-[0.313rem] flex w-[7.875rem] h-11 py-2.5 px-[1.125rem] items-center justify-center gap-2 text-white
            ${
              parseInt(teamProp?.stripeAlertCount) > 0
                ? 'bg-[#B42318]'
                : parseInt(teamProp?.missingRequiredFieldsCount) > 0
                  ? 'bg-[#B54708]'
                  : 'bg-[#667085]'
            }

            `}
              type="button"
            >
              {isLoading ? (
                <svg
                  className="animate-spin w-4 h-4 fill-current shrink-0 mr-2"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z" />
                </svg>
              ) : parseInt(teamProp?.missingRequiredFieldsCount) > 0 ? (
                'Complete'
              ) : (
                'Resolve Now'
              )}
            </button>
          )}
        </div>
      )}
    </div>
  );
};

export default StripeWarning;
