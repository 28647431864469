import ModalBasic from 'components/ModalBasic';
import { Loader } from 'components/Svg';
import React, { useState } from 'react';

const DeleteModal = ({ isOpen, setIsOpen, handleDeleteTag, activeTag }) => {
  const [isLoading, setIsLoading] = useState(false);

  const onDelete = async () => {
    setIsLoading(true);
    try {
      await handleDeleteTag(activeTag?.id);
      setIsOpen(false);
    } catch (err) {
      console.log('err', err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <ModalBasic
      title="Delete Tag"
      modalOpen={isOpen}
      setModalOpen={setIsOpen}
      smallModal
    >
      <div className="w-full px-5 py-[1.875rem] flex items-center self-stretch bg-white">
        <p className="text-base leading-6 text-slate-600 text-opacity-80">
          Are you sure you want to delete this tag? This action cannot be
          undone.
        </p>
      </div>
      <div className="border-t border-gray-300 h-20 flex items-center justify-end gap-4 px-5">
        <button
          className="w-[5.625rem] h-11 flex items-center justify-center py-2.5 px-[1.875rem] rounded-[0.313rem] bg-white border border-slate-600 text-slate-600 text-base leading-6"
          onClick={() => setIsOpen(false)}
          type="button"
        >
          No
        </button>
        <button
          className="w-[5.625rem] h-11 flex items-center justify-center py-2.5 px-[1.875rem] rounded-[0.313rem] bg-indigo-500 shadow text-white leading-6
           disabled:!bg-indigo-300 disabled:cursor-not-allowed"
          onClick={onDelete}
          disabled={isLoading}
          type="button"
        >
          {isLoading ? (
            <Loader color="#ffffff" height="w-5" width="w-5" />
          ) : (
            'Yes'
          )}
        </button>
      </div>
    </ModalBasic>
  );
};

export default DeleteModal;
