import React, { useState } from 'react';
import { Cog, Cube } from '../svg';

const Component2 = ({
  setPanelStep,
  createNewNode,
  activeNodeId,
  setWorkingNodeId,
}) => {
  const [actionSelected, setActionSelected] = useState(false);
  const [conditionSelected, setConditionSelected] = useState(false);

  const handleNextClick = () => {
    if (actionSelected) {
      setPanelStep('action1');
      createNewNode(activeNodeId, {
        name: 'Then:',
        type: 'action',
        description: '',
      });
      setWorkingNodeId(activeNodeId);
    } else {
      setPanelStep('condition1');
      createNewNode(activeNodeId, {
        name: 'If:',
        type: 'condition',
        description: '',
      });
      setWorkingNodeId(activeNodeId);
    }
  };

  return (
    <>
      <div className="flex flex-col items-start gap-px self-stretch">
        <p className="text-black text-[1.375rem] font-medium">
          Turn This Rule On
        </p>
        <p className="text-slate-600 text-[14px]">
          This rule can be activated now, or add more components.
        </p>
      </div>
      <div
        className={`flex flex-col items-start gap-2.5 self-stretch rounded-[15px] p-[0.938rem] cursor-pointer hover:bg-[#fbfbfb] ${
          actionSelected ? 'bg-[#F2F2F2]' : ''
        }`}
        onClick={() => {
          setActionSelected(!actionSelected);
          setConditionSelected(false);
        }}
        role="button"
        tabIndex="0"
      >
        <div className="flex items-start gap-2.5 self-stretch">
          <div className="bg-[#FCF1E9] rounded-[10px] gap-2.5 px-2.5 py-[0.938rem] w-11 h-11 flex items-center justify-center">
            <Cog />
          </div>
          <div className="flex flex-col items-start -mt-1">
            <p className="text-black text-[1rem]">THEN: Add an Action</p>
            <p className="text-slate-600 text-[14px]">
              Actions will execute when your rule runs successfully.
            </p>
          </div>
        </div>
      </div>
      <div
        className={`flex flex-col items-start gap-2.5 self-stretch rounded-[15px] p-[0.938rem] cursor-pointer hover:bg-[#fbfbfb] ${
          conditionSelected ? 'bg-[#F2F2F2]' : ''
        }`}
        onClick={() => {
          setConditionSelected(!conditionSelected);
          setActionSelected(false);
        }}
        role="button"
        tabIndex="0"
      >
        <div className="flex items-start gap-2.5 self-stretch">
          <div className="bg-[#E7F0FE] rounded-[10px] gap-2.5 px-2.5 py-[0.938rem] w-11 h-11 flex items-center justify-center">
            <Cube />
          </div>
          <div className="flex flex-col items-start -mt-1">
            <p className="text-black text-[1rem]">IF: Add a Condition</p>
            <p className="text-slate-600 text-[14px]">
              Conditions limit the scope to specific user groups or keywords.
              You can make your rule run a specific path, depending on which
              condition is met.{' '}
            </p>
          </div>
        </div>
      </div>
      <div className="flex items-start gap-5 self-stretch">
        <button
          className="w-1/2 py-2.5 px-[1.125rem] flex items-center justify-center gap-2 rounded-[0.313rem] shadow text-white bg-indigo-500 leading-6"
          disabled={!actionSelected && !conditionSelected}
          onClick={() => {
            handleNextClick();
          }}
          type="button"
        >
          Next
        </button>
      </div>
    </>
  );
};

export default Component2;
