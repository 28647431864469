import React, { useRef, useEffect, useState } from "react";
import {
  Chart,
  LineController,
  LineElement,
  Filler,
  PointElement,
  LinearScale,
  TimeScale,
  Tooltip,
} from "chart.js";
import "chartjs-adapter-moment";
import moment from "moment";

import { chartColors } from "./ChartjsConfig";
import { formatValue } from "../utils/Utils";

Chart.register(
  LineController,
  LineElement,
  Filler,
  PointElement,
  LinearScale,
  TimeScale,
  Tooltip
);

function LineChartInsights({ data, width, height, dateRange, teamCurrency }) {
  const canvasRef = useRef(null);
  const chartInstanceRef = useRef(null);

  const { gridColor, tooltipBodyColor, tooltipBgColor, tooltipBorderColor } = chartColors;

  // Helper function to fill in missing months
  const generateCompleteDataset = (data, dateRange) => {
    const startDate = moment(dateRange.start);
    const endDate = moment(dateRange.end);
    const months = {};

    // Fill months map with data
    data.forEach(item => {
      months[item.month] = item.amount;
    });

    const completeData = [];
    let current = startDate.startOf('month');

    while (current.isBefore(endDate)) {
      const monthKey = current.format("YYYY-MM");
      completeData.push({
        x: monthKey,
        y: months[monthKey] || 0
      });
      current.add(1, 'month');
    }

    return completeData;
  };

  useEffect(() => {
    if (canvasRef.current && data && data.length) {
      const ctx = canvasRef.current.getContext("2d");

      if (chartInstanceRef.current) {
        chartInstanceRef.current.destroy();
      }

      const completeData = generateCompleteDataset(data, dateRange);

      const newChart = new Chart(ctx, {
        type: "line",
        data: {
          datasets: [
            {
              label: "Spending",
              data: completeData,
              borderColor: "#78BD4F",
              backgroundColor: "rgba(120, 189, 79, 0.2)",
              tension: 0.4,
              fill: true,
              pointBackgroundColor: "#78BD4F",
              pointBorderColor: "#78BD4F",
              pointRadius: 5,
              pointBorderWidth: 2,
            },
          ],
        },
        options: {
          animation: false,
          layout: {
            padding: {
              bottom: 50,
            },
          },
          scales: {
            x: {
              type: "time",
              time: {
                parser: "YYYY-MM",
                unit: "month",
                tooltipFormat: "MMMM YYYY",
              },
              min: dateRange.start,
              max: dateRange.end,
              grid: {
                borderDash: [5, 5],
              },
            },
            y: {
              beginAtZero: true,
              suggestedMax: Math.max(...data.map((d) => d.amount)) * 1.2,
              grid: {
                borderDash: [5, 5],
              },
              ticks: {
                callback: function (value, index, values) {
                  return `${teamCurrency ? teamCurrency?.symbol : "$"}` + value;
                },
              },
            },
          },
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              callbacks: {
                label: (context) => formatValue(context.raw.y),
              },
              titleColor: tooltipBodyColor.light,
              bodyColor: tooltipBodyColor.light,
              backgroundColor: tooltipBgColor.light,
              borderColor: tooltipBorderColor.light,
            },
          },
          maintainAspectRatio: false,
        },
      });

      chartInstanceRef.current = newChart;
    }

    return () => {
      if (chartInstanceRef.current) {
        chartInstanceRef.current.destroy();
      }
    };
  }, [data, dateRange]);

  return <canvas ref={canvasRef} width={width} height={height} />;
}

export default LineChartInsights;
