import React, { useState, useEffect, useRef, useMemo } from 'react';
import { Form, Formik, useFormikContext } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import { useSelector } from 'react-redux';
import Tooltip from 'components/Tooltip';
import ModalBasic from '../../components/ModalBasic';
import DropdownFull from '../../components/DropdownFull';
import TextInputDash from '../../components/TextInputDash';
import { Loader } from '../../components/Svg';
import DatePickerDash from '../../components/DatePickerDash';
import useClickOutside from '../../utils/useClickOutside';
import { StatusDropdownArrow } from '../inbox/Svg';
import Transition from '../../utils/Transition';
import { getCustomers } from '../../API/backend_helper';

let searchTimeout = null;

const AddProjectModal = ({
  modalOpen,
  setModalOpen,
  handleSubmit,
  customers,
  setCustomers,
  customerModalOpen,
  setCustomerModalOpen,
  selectedCustomer,
  modalLoading,
  selectedStatus,
  statusOptions,
  project,
  setDeleteModalOpen = null,
}) => {
  const formikRef = useRef();
  const statusDropdownRef = useRef();
  const trigger = useRef();
  const [noClick, setNoClick] = useState(false);
  const [statusDropdownOpen, setStatusDropdownOpen] = useState(false);
  const [dropdownCustomers, setDropdownCustomers] = useState([...customers]);
  const { team } = useSelector((state) => state.Team);

  const options = useMemo(
    () => ({
      mode: 'single',
      static: false,
      monthSelectorType: 'static',
      allowInput: true,
      dateFormat:
        team?.defaults?.dateFormat === 'dd/mm/yyyy' ? 'd/m/Y' : 'm/d/Y',
      onReady: (selectedDates, dateStr, instance) => {
        instance.calendarContainer.classList.add(`flatpickr-right`);
      },
      prevArrow:
        '<svg class="fill-current" width="7" height="11" viewBox="0 0 7 11"><path d="M5.4 10.8l1.4-1.4-4-4 4-4L5.4 0 0 5.4z" /></svg>',
      nextArrow:
        '<svg class="fill-current" width="7" height="11" viewBox="0 0 7 11"><path d="M1.4 10.8L0 9.4l4-4-4-4L1.4 0l5.4 5.4z" /></svg>',
      onOpen(selectedDates, dateStr, instance) {
        setNoClick(true);
      },
      onClose(selectedDates, dateStr, instance) {
        setNoClick(false);
      },
    }),
    [team],
  );

  useClickOutside(statusDropdownRef, (event) => {
    if (!trigger.current.contains(event.target)) setStatusDropdownOpen(false);
  });

  useEffect(() => {
    if (customerModalOpen) {
      setNoClick(true);
    } else {
      setNoClick(false);
    }
  }, [customerModalOpen]);

  useEffect(() => {
    setDropdownCustomers(customers);
  }, [customers]);

  useEffect(() => {
    if (selectedCustomer) {
      formikRef.current.setFieldValue('customerId', selectedCustomer?.id);
    }
    if (selectedStatus) {
      formikRef.current.setFieldValue('status', selectedStatus);
    }
    if (project) {
      formikRef.current.setFieldValue('name', project.name);
      formikRef.current.setFieldValue('customId', project.customId);
      formikRef.current.setFieldValue('customerId', project.customerId);
      formikRef.current.setFieldValue('status', project.status);
      project?.notes && formikRef.current.setFieldValue('notes', project.notes);
      project?.startDate &&
        formikRef.current.setFieldValue(
          'startDate',
          moment(project.startDate.slice(0, 10)).toISOString(),
        );
      project?.endDate &&
        formikRef.current.setFieldValue(
          'endDate',
          moment(project.endDate.slice(0, 10)).toISOString(),
        );
    }
    if (!modalOpen) {
      formikRef?.current.resetForm();
    }
  }, [selectedStatus, selectedCustomer, project, modalOpen]);

  const getCustomersBySearch = async ({ limit = 50, search = '' }) => {
    try {
      const res = await getCustomers({
        search,
        limit,
      });
      return res?.data;
    } catch (e) {
      console.log('error', e);
    }
  };

  const handleCustomerSearch = (value) => {
    if (value) {
      clearTimeout(searchTimeout);
      searchTimeout = setTimeout(async () => {
        const data = await getCustomersBySearch({
          search: encodeURIComponent(value || ''),
        });
        const filteredCustomers = data?.records?.filter((customer) =>
          customer.customer.toLowerCase().includes(value.toLowerCase()),
        );
        setDropdownCustomers(filteredCustomers);
      }, 500);
    } else {
      setDropdownCustomers(customers);
    }
  };

  const resetSearch = (selectedOption) => {
    const isAlreadyInCustomer =
      customers?.find((cus) => cus?.id === selectedOption?.id)?.id ===
      selectedOption?.id;
    if (isAlreadyInCustomer) {
      setDropdownCustomers([...customers]);
    } else {
      setDropdownCustomers([selectedOption, ...customers]);
      setCustomers([selectedOption, ...customers]);
    }
  };

  return (
    <ModalBasic
      title={project ? 'Edit Project' : 'Add Project'}
      modalOpen={modalOpen}
      setModalOpen={setModalOpen}
      noClick={noClick}
    >
      <Formik
        enableReinitialize={false}
        initialValues={{
          name: '',
          customId: '',
          customerId: selectedCustomer?.id || '',
          status: selectedStatus || 'Not started',
          notes: '',
          startDate: '',
          endDate: '',
        }}
        validate={(values) => {
          const errors = {};

          const { startDate, endDate } = values;
          if (startDate && !endDate) {
            errors.endDate = 'End Date is required.';
          } else if (!startDate && endDate) {
            errors.startDate = 'Start Date is required.';
          } else {
            delete errors.endDate;
            delete errors.startDate;
          }
          return errors;
        }}
        validationSchema={Yup.object({
          name: Yup.string()
            .required('Project Name is required')
            .min(3, 'Name should be atleast 3 characters')
            .max(100, 'Name should be less than 100 characters'),
          customId: project
            ? Yup.string().required('Project Id is required')
            : Yup.string(),
          // .matches(
          //   /^[A-Za-z]+$/,
          //   'In Project custom id only letters are allowed',
          // )
          // .max(10, 'Project custom id should be less than 10 characters'),
          customerId: Yup.string().required('Customer is required'),
          notes: Yup.string()
            .min(3, 'Description should be atleast 3 characters')
            .max(255, 'Description should be less than 255 characters'),
          status: Yup.string(),
          startDate: Yup.string(),
          endDate: Yup.string(),
        })}
        onSubmit={handleSubmit}
        innerRef={formikRef}
      >
        {(validation) => (
          <Form>
            <div className="bg-white border-y border-[#D0D5DD]">
              <div className="w-full">
                <div className="flex flex-col gap-6 py-6 px-10 w-full">
                  <TextInputDash
                    placeholder="Enter Project Name"
                    value={validation.values.name}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    name="name"
                    label=" Project Name"
                    required
                    error={validation.touched.name && validation.errors.name}
                    paddingBottom={
                      validation.touched.name && validation.errors.name
                        ? 'pb-5'
                        : 'pb-0'
                    }
                  />
                  {project && (
                    <div>
                      <label
                        className={`text-sm font-medium text-slate-600 mb-2 flex items-center gap-2 ${
                          validation.touched.customId &&
                          validation.errors.customId
                            ? '!text-rose-400'
                            : ''
                        }`}
                        htmlFor="customId"
                      >
                        <div>
                          Project Key
                          <span className="text-rose-500"> *</span>
                        </div>
                        <Tooltip
                          content={
                            <>
                              <p>Choose a descriptive prefix for</p>
                              <p> your project’s task keys to </p>
                              <p>recognize work from this project.</p>
                            </>
                          }
                          contentClassName="border-none overflow-visible text-xs text-[#667085] relative tooltipArrowDown"
                        >
                          <svg
                            width="16"
                            height="16"
                            viewBox="0 0 14 13"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className="mb-px"
                          >
                            <path
                              d="M7.00049 11.21C9.60146 11.21 11.71 9.10146 11.71 6.50049C11.71 3.89952 9.60146 1.79102 7.00049 1.79102C4.39952 1.79102 2.29102 3.89952 2.29102 6.50049C2.29102 9.10146 4.39952 11.21 7.00049 11.21Z"
                              stroke="#667085"
                              strokeWidth="0.784912"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M6.60938 6.10938C6.71346 6.10938 6.81328 6.15072 6.88688 6.22432C6.96048 6.29792 7.00183 6.39775 7.00183 6.50183V8.46411C7.00183 8.5682 7.04318 8.66802 7.11678 8.74162C7.19038 8.81522 7.2902 8.85657 7.39429 8.85657"
                              stroke="#667085"
                              strokeWidth="0.784912"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M6.80353 4.93127C7.12865 4.93127 7.39221 4.66771 7.39221 4.34259C7.39221 4.01747 7.12865 3.75391 6.80353 3.75391C6.47841 3.75391 6.21484 4.01747 6.21484 4.34259C6.21484 4.66771 6.47841 4.93127 6.80353 4.93127Z"
                              fill="#667085"
                            />
                          </svg>
                        </Tooltip>
                      </label>

                      <TextInputDash
                        placeholder="Project Id"
                        value={validation.values.customId}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        name="customId"
                        required
                        error={
                          validation.touched.customId &&
                          validation.errors.customId
                        }
                        inputClass="uppercase placeholder:normal-case"
                        paddingBottom={
                          validation.touched.customId &&
                          validation.errors.customId
                            ? 'pb-5'
                            : 'pb-0'
                        }
                      />
                    </div>
                  )}
                  <div className="w-full flex gap-4">
                    <div className="flex flex-col w-full">
                      <label
                        className={`${
                          validation.touched.customerId &&
                          validation.errors.customerId
                            ? 'text-rose-400'
                            : 'text-slate-600'
                        }  mb-2.5 font-medium text-[14px] leading-5`}
                      >
                        Customer <span className="text-red-600">*</span>
                      </label>
                      <DropdownFull
                        search
                        scrollIntoView={false}
                        options={dropdownCustomers}
                        selected={validation.values.customerId}
                        setSelected={(value) =>
                          validation.setFieldValue('customerId', value)
                        }
                        name="customer"
                        placeholder="Choose Customer"
                        addNewOptionButton
                        addNewOptionLabel="Create Customer"
                        handleNewOptionButton={() => {
                          setCustomerModalOpen(true);
                        }}
                        handleSearch={handleCustomerSearch}
                        resetSearch={resetSearch}
                        height="h-12 max-w-[250px]"
                        error={
                          validation.touched.customerId &&
                          validation.errors.customerId
                        }
                      />
                    </div>
                    <div className="flex flex-col gap-2.5 w-full">
                      <label className="text-slate-600 font-medium text-[14px] leading-5">
                        Project Status
                      </label>
                      <div
                        className="flex p-2 justify-center mt-1 w-[10rem] items-center gap-2.5 rounded-[22px] text-[14px] leading-6 relative cursor-pointer"
                        onClick={(e) => {
                          e.stopPropagation();
                          setStatusDropdownOpen(!statusDropdownOpen);
                        }}
                        style={{
                          backgroundColor: statusOptions.find(
                            (option) => option.id === validation.values.status,
                          )?.background,
                          color: statusOptions.find(
                            (option) => option.id === validation.values.status,
                          )?.textColor,
                        }}
                        ref={trigger}
                      >
                        <div className="flex items-center gap-2">
                          <p>
                            {
                              statusOptions.find(
                                (option) =>
                                  option.id === validation.values.status,
                              )?.name
                            }
                          </p>
                          <StatusDropdownArrow
                            fill={
                              statusOptions.find(
                                (option) =>
                                  option.id === validation.values.status,
                              )?.textColor
                            }
                          />
                          <Transition
                            show={statusDropdownOpen}
                            tag="div"
                            className="z-60 absolute top-10 left-0 w-full bg-white dark:bg-slate-800 border border-slate-200 dark:border-slate-700 py-1.5 rounded shadow-lg overflow-visible mt-1"
                            enter="transition ease-out duration-100 transform"
                            enterStart="opacity-0 -translate-y-2"
                            enterEnd="opacity-100 translate-y-0"
                            leave="transition ease-out duration-100"
                            leaveStart="opacity-100"
                            leaveEnd="opacity-0"
                          >
                            {statusDropdownOpen && (
                              <div
                                ref={statusDropdownRef}
                                className="divide-y divide-slate-200 dark:divide-slate-700 z-60 "
                              >
                                <div className="max-h-48 overflow-auto scrollbar rounded-[22px] z-60">
                                  {statusOptions.map((option, index) => (
                                    <button
                                      type="button"
                                      key={index}
                                      className="flex items-center justify-between w-[80%] rounded-[22px] px-2 my-2 mx-auto cursor-pointer"
                                      style={{
                                        backgroundColor: option.background,
                                        color: option.textColor,
                                      }}
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        validation.setFieldValue(
                                          'status',
                                          option.id,
                                        );
                                        setStatusDropdownOpen(false);
                                      }}
                                    >
                                      {option?.name}
                                    </button>
                                  ))}
                                </div>
                              </div>
                            )}
                          </Transition>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="w-full flex gap-4">
                    <div className="flex flex-col w-full">
                      <label
                        className={`${
                          !validation.values.startDate &&
                          validation.errors.startDate
                            ? 'text-rose-400'
                            : 'text-slate-600'
                        }  mb-2.5 font-medium text-[14px] leading-5 whitespace-nowrap`}
                      >
                        Project Start Date
                      </label>
                      <DatePickerDash
                        required
                        validation={validation}
                        inputClass={
                          !validation.values.startDate &&
                          validation.errors.startDate
                            ? '!border-rose-400'
                            : ''
                        }
                        placeholder="Select Date"
                        value={
                          validation.values.startDate
                            ? validation.values.startDate
                            : null
                        }
                        options={
                          validation.values.endDate
                            ? {
                                ...options,
                                maxDate: moment(
                                  validation.values.endDate,
                                ).toISOString(),
                              }
                            : options
                        }
                        onChange={(selectedDates) => {
                          validation.setFieldValue(
                            'startDate',
                            selectedDates[0],
                          );
                        }}
                        name="startDate"
                        id="startDate"
                        width="w-full"
                        crossIcon={validation.values.startDate}
                        handleCrossIcon={(e) => {
                          e.stopPropagation();
                          validation.setFieldValue('startDate', '');
                        }}
                      />

                      {!validation.values.startDate &&
                        validation.errors.startDate && (
                          <div className="mt-1 text-xs text-rose-400">
                            {validation.errors.startDate}
                          </div>
                        )}
                    </div>
                    <div className="flex flex-col  w-full">
                      <label
                        className={`${!validation.values.endDate && validation.errors.endDate ? 'text-rose-400' : 'text-slate-600'} mb-2.5 font-medium text-[14px] leading-5 whitespace-nowrap`}
                      >
                        Project End Date
                      </label>
                      <DatePickerDash
                        required
                        validation={validation}
                        inputClass={
                          !validation.values.endDate &&
                          validation.errors.endDate
                            ? '!border-rose-400'
                            : ''
                        }
                        placeholder="Select Date"
                        value={
                          validation.values.endDate
                            ? validation.values.endDate
                            : null
                        }
                        options={
                          validation.values.startDate
                            ? {
                                ...options,
                                minDate: moment(
                                  validation.values.startDate,
                                ).toISOString(),
                              }
                            : options
                        }
                        onChange={(selectedDates) => {
                          validation.setFieldValue('endDate', selectedDates[0]);
                        }}
                        name="endDate"
                        id="endDate"
                        width="w-full"
                        crossIcon={validation.values.endDate}
                        handleCrossIcon={(e) => {
                          e.stopPropagation();
                          validation.setFieldValue('endDate', '');
                        }}
                      />
                      {!validation.values.endDate &&
                        validation.errors.endDate && (
                          <div className="mt-1 text-xs text-rose-400">
                            {validation.errors.endDate}
                          </div>
                        )}
                    </div>
                  </div>
                  <div className="flex flex-col gap-2.5 w-full">
                    <label className="text-slate-600 font-medium text-[14px] leading-5">
                      Notes
                    </label>
                    <TextInputDash
                      placeholder="Details about the project"
                      value={validation.values.notes}
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      name="notes"
                      paddingBottom="pb-0"
                      type="textarea"
                    />
                    {validation.touched.notes && validation.errors.notes ? (
                      <span className="text-xs text-rose-500 h-[14px] -mt-3">
                        {validation.errors.notes}
                      </span>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
            <div className="py-8 px-10 flex w-full justify-between items-center gap-4">
              {project && (
                <button
                  className=" flex justify-center items-center shadow-none font-medium underline text-indigo-500 disabled:cursor-not-allowed"
                  type="button"
                  onClick={() => {
                    if (setDeleteModalOpen) {
                      setModalOpen(false);
                      setDeleteModalOpen(true);
                    }
                  }}
                >
                  Archive Project
                </button>
              )}
              <div className="ml-auto flex items-center gap-4">
                <button
                  type="button"
                  className="h-11 w-[90px] flex justify-center items-center rounded-[5px] bg-white text-slate-600 border border-slate-600"
                  onClick={() => setModalOpen(false)}
                >
                  Close
                </button>
                <button
                  className="h-11 w-[90px] flex justify-center items-center rounded-[5px] bg-indigo-500 text-white border border-indigo-500
                disabled:!bg-indigo-300 disabled:border-indigo-300 disabled:cursor-not-allowed"
                  type="submit"
                  disabled={modalLoading}
                >
                  {modalLoading ? (
                    <Loader color="#FFFFFF" width="w-4" height="h-4" />
                  ) : project ? (
                    'Save'
                  ) : (
                    'Add'
                  )}
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </ModalBasic>
  );
};
export default AddProjectModal;
