import React, {useState, useEffect} from "react";
import ModalBasic from "../../../components/ModalBasic";
import TextInputDash from "../../../components/TextInputDash";
import ImageCropModal from "../../../components/ImageCropModal";
import UploadFile from "../../../components/UploadFile";
import { uploadInstitutionLogo, updateInstitution } from "../../../API/backend_helper";

const InstitutionModal = ({isOpen, setIsOpen, activeRecord, loadInstitutions}) => {
    const [name, setName] = useState(activeRecord?.name);
    const [files, setFiles] = useState([]);
    const [imgPreview, setImgPreview] = useState('');
    const [cropModalOpen, setCropModalOpen] = useState(false);
    const [currentImageForCrop, setCurrentImageForCrop] = useState(null);
    const [imgName, setImgName] = useState('');

    useEffect(() => {
        return () => files.forEach(file => URL.revokeObjectURL(file.preview));
      }, []);

    useEffect(() => {
        if (!isOpen) {
            setFiles([]);
            setImgPreview('');
            setName("");
        } else {
            setName(activeRecord?.name);
            activeRecord?.logoUrl && setImgPreview(activeRecord?.logoUrl);
        }
    }, [isOpen, activeRecord]);

    const uploadLogo = async (logo, record) => {
        const formData = new FormData();
        formData.append("logo", logo);
        uploadInstitutionLogo(formData, record);
    };

    const handleSubmit = async () => {
        const logo = files[0];
        let response;
        try {
            if (activeRecord) {
                response = await updateInstitution({name: name}, activeRecord?.id);
                logo && await uploadLogo(logo, activeRecord?.id);
            }
        } catch (error) {
            console.log("error", error)
        }

        if (response || logo) {
            setIsOpen(false);
            setFiles([]);
            setImgPreview('');
            setName("");
            setTimeout(() => {
                loadInstitutions({});
            }, 3000);
        }
    };

    return (
        <>
            <ModalBasic
                modalOpen={isOpen}
                setModalOpen={setIsOpen}
                title="Institution"
                width="max-w-screen-md"
            >
            <ImageCropModal
                isOpen={cropModalOpen}
                setIsOpen={setCropModalOpen}
                image={currentImageForCrop}
                setPreviewImage={setImgPreview}
                setFiles={setFiles}
                imgName={imgName}
            />
            <div className="flex flex-col w-[90%] px-4 py-4 h-[25rem]">
                <TextInputDash
                    id="name"
                    label="Name"
                    placeholder="Name "
                    name="name"
                    required
                    onChange={(e) => {
                        setName(e.target.value);
                    }}
                    value={name || ""}
                />
                <div className="flex flex-start flex-col w-[70%] max-h-36">
                    <UploadFile
                        title="Logo"
                        onFilesAdded={(files) => {
                            setFiles(prevFiles => [...prevFiles, ...files]);
                            if (files[0]) {
                                const filePreviewUrl = URL.createObjectURL(files[0]);
                                setCurrentImageForCrop(filePreviewUrl);
                                setCropModalOpen(true);
                                setImgName(files[0].name);
                            }
                        }}
                        name="logo"
                        id="logo"
                        imgPreview={imgPreview}
                        setImgPreview={setImgPreview}
                        files={files}
                        setFiles={setFiles}
                    />
                    </div>
                    <button
                        onClick={() => handleSubmit()}
                        className="mt-12 flex items-center justify-center py-2.5 px-[1.875rem] rounded-[0.313rem] bg-indigo-500 shadow w-[6.875rem] h-11 text-white leading-6"
                    >
                        Submit
                    </button>
                </div>
            </ModalBasic>
        </>
    );
};

export default InstitutionModal;