import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import OverviewBox from './OverviewBox';
import BankStatementModal from './BankStatementModal';
import { DevEnv } from '../../../utils/Utils';

const Overview = ({ setActiveTab, CLIENTS_TAB, isAccountingFirmFound }) => {
  const navigate = useNavigate();
  const { localUser } = useSelector((state) => state.User);

  const [bankStatementModalOpen, setBankStatementModalOpen] = useState(false);

  return (
    <>
      <BankStatementModal
        isOpen={bankStatementModalOpen}
        setIsOpen={setBankStatementModalOpen}
      />
      <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-6">
        {process.env.REACT_APP_ENV === DevEnv && (
          <OverviewBox
            title="Invite Your Clients to COUNT"
            text="Get 20% of their subscription revenue share for every client you invite!"
            buttonText="Invite Now"
          />
        )}
        <OverviewBox
          title="Manage Current Clients"
          text="View a list of all your clients on COUNT and quickly access their financial data."
          buttonText="Manage Clients"
          handleClick={() => setActiveTab(CLIENTS_TAB)}
        />

        <OverviewBox
          title="Convert PDF Statements to CSV "
          text="A free tool for bookkeepers and accountants to convert PDF bank statements to CSV files."
          buttonText="Convert Now"
          isFree
          handleClick={() => setBankStatementModalOpen(true)}
        />
        {!isAccountingFirmFound && (
          <OverviewBox
            title="List Your Accounting Service"
            text="List your accounting or bookkeeper service to count users looking for help."
            buttonText="Add your profile"
            handleClick={() => navigate('/practice/new')}
          />
        )}
        {(!localUser?.teams || localUser?.teams?.length === 0) && (
          <OverviewBox
            title="Create your own COUNT workspace"
            text="As an accounting partner, we are happy to offer you a complimentary Growing tier of COUNT for your accounting business."
            buttonText="Create your free business account"
            isFree
            handleClick={() => navigate('/workspace')}
          />
        )}
      </div>
    </>
  );
};

export default Overview;
