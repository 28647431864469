import React, { useState, useRef, useEffect } from 'react';
import useClickOutside from '../../utils/useClickOutside';
import { useStore } from './store';
import Trigger1 from './panelSteps/Trigger1';
import Trigger2 from './panelSteps/Trigger2';
import Component1 from './panelSteps/Component1';
import Condition1 from './panelSteps/Condition1';
import Action1 from './panelSteps/Action1';
import Component2 from './panelSteps/Component2';

const NodePanel = ({
  setPanelOpen,
  panelStep,
  setPanelStep,
  createNewNode,
  nodes,
  vendors,
  customers,
  accounts,
  categories,
  tags,
  members,
  updateNode,
  isEditing,
  setIsEditing,
  isInBetween,
  setIsInBetween,
  createNodeBetween,
  setActiveNodeId,
  currencies,
  team,
  automationType,
  setAutomationType,
  deleteNode,
}) => {
  const panelContent = useRef(null);
  const [triggerType, setTriggerType] = useState('');
  const [workingNodeId, setWorkingNodeId] = useState(null);
  const [localNode, setLocalNode] = useState({});

  const { activeNodeId } = useStore((state) => ({
    activeNodeId: state.activeNodeId,
  }));

  useEffect(() => {
    if (isEditing && activeNodeId) {
      setLocalNode(nodes.find((node) => node.id === activeNodeId));
    } else {
      setLocalNode({});
    }
  }, [activeNodeId, isEditing]);

  useClickOutside(panelContent, () => {
    setPanelOpen(false);
    setIsEditing(false);
    setLocalNode({});
    setIsInBetween({});
  });

  return (
    <div
      ref={panelContent}
      className="absolute px-5 pb-5 pt-[1.875rem] top-[23vh] right-[1vw] bg-white shadow-xl z-40 w-full md:w-[400px] xl:w-[440px] overflow-y-auto h-full md:h-[400px] 2xl:h-[600px] flex gap-[1.563rem] flex-col items-center rounded-[24px] border border-opacity-60 border-[#D0D5DD]"
    >
      {/* Trigger Step 1 */}
      {panelStep === 'trigger1' && (
        <Trigger1
          createNewNode={createNewNode}
          setPanelStep={setPanelStep}
          activeNodeId={activeNodeId}
          triggerType={triggerType}
          setTriggerType={setTriggerType}
          isEditing={isEditing}
          updateNode={updateNode}
          localNode={localNode}
          nodes={nodes}
          setIsEditing={setIsEditing}
          setActiveNodeId={setActiveNodeId}
          setPanelOpen={setPanelOpen}
          automationType={automationType}
          setAutomationType={setAutomationType}
          deleteNode={deleteNode}
        />
      )}
      {/* Trigger Step 2 */}
      {panelStep === 'trigger2' && (
        <Trigger2
          panelStep={panelStep}
          setPanelStep={setPanelStep}
          triggerType={triggerType}
          nodes={nodes}
          accounts={accounts}
          isEditing={isEditing}
          setIsEditing={setIsEditing}
          updateNode={updateNode}
          createNewNode={createNewNode}
          localNode={localNode}
          setActiveNodeId={setActiveNodeId}
          setPanelOpen={setPanelOpen}
          activeNodeId={activeNodeId}
        />
      )}
      {/* Component Step 1 */}
      {panelStep === 'component1' && (
        <Component1
          setPanelStep={setPanelStep}
          createNewNode={createNewNode}
          activeNodeId={activeNodeId}
          setWorkingNodeId={setWorkingNodeId}
          updateNode={updateNode}
          isEditing={isEditing}
          setIsEditing={setIsEditing}
          isInBetween={isInBetween}
          setIsInBetween={setIsInBetween}
          createNodeBetween={createNodeBetween}
          nodes={nodes}
          setActiveNodeId={setActiveNodeId}
          localNode={localNode}
          setPanelOpen={setPanelOpen}
        />
      )}
      {/* Condition Step 1 OR Action Step 1 */}
      {panelStep === 'condition1' && (
        <Condition1
          setPanelStep={setPanelStep}
          nodes={nodes}
          workingNodeId={workingNodeId}
          vendors={vendors}
          customers={customers}
          updateNode={updateNode}
          activeNodeId={activeNodeId}
          isEditing={isEditing}
          setIsEditing={setIsEditing}
          localNode={localNode}
          setPanelOpen={setPanelOpen}
          currencies={currencies}
          team={team}
          automationType={automationType}
        />
      )}
      {panelStep === 'action1' && (
        <Action1
          setPanelStep={setPanelStep}
          nodes={nodes}
          workingNodeId={workingNodeId}
          vendors={vendors}
          customers={customers}
          categories={categories}
          tags={tags}
          members={members}
          updateNode={updateNode}
          activeNodeId={activeNodeId}
          isEditing={isEditing}
          setIsEditing={setIsEditing}
          localNode={localNode}
          setPanelOpen={setPanelOpen}
          automationType={automationType}
        />
      )}
      {/* Select Trigger/Component after initial setup */}
      {panelStep === 'component2' && (
        <Component2
          setPanelStep={setPanelStep}
          createNewNode={createNewNode}
          activeNodeId={activeNodeId}
          setWorkingNodeId={setWorkingNodeId}
          updateNode={updateNode}
          isEditing={isEditing}
          setIsEditing={setIsEditing}
          setPanelOpen={setPanelOpen}
        />
      )}
    </div>
  );
};

export default NodePanel;
