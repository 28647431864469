import React from 'react';

const InvoicePaymentSuccess = ({
  details,
  currencySymbol,
  amountPay,
  successLoader,
}) => (
  <div className="">
    {/* <nav className="h-[81px] w-full flex bg-[#101828] items-center justify-between px-10">
        <img
          className="h-8 cursor-pointer"
          onClick={() => navigate("/")}
          src={CountLogo}
          alt="logo"
        />
        <h3 className="text-[22px] text-white font-medium">Chris Smith</h3>
      </nav> */}
    <div className="w-[583px] mt-[100px] pb-[20px] max-w-[90vw] mx-auto grid grid-cols-12 gap-[18px]">
      <div className="col-span-12 bg-white border-[#D0D5DD] rounded-[6px]">
        <div className="mx-auto shadow-md -translate-y-1/2 h-[77px] flex justify-center items-center w-[77px] bg-white rounded-full ">
          {details?.team?.logoUrl || details?.invoice?.team?.logoUrl ? (
            <img
              src={details?.team?.logoUrl || details?.invoice?.team?.logoUrl}
              className="w-[70px] h-[70px] rounded-full"
              alt="logo"
            />
          ) : (
            <div className="w-[75px] h-[75px] rounded-full flex items-center justify-center border border-[#7425F4] bg-[#7425F41A]">
              <div className="uppercase text-[#7425F4] text-[38px]">
                {details?.team?.name?.split(' ')?.[0]?.charAt(0) ||
                  details?.invoice?.team?.name?.split(' ')?.[0]?.charAt(0)}
                {details?.team?.name?.split(' ')?.[1]?.charAt(0) ||
                  details?.invoice?.team?.name?.split(' ')?.[1]?.charAt(0)}
              </div>
            </div>
          )}
        </div>
        <div className="flex flex-col items-center">
          <h1 className="text-[#667085] mb-4 -mt-1 text-[30px] text-center font-semibold">
            {details?.team?.name || details?.invoice?.team?.name}
          </h1>
          <svg
            width="34"
            height="34"
            viewBox="0 0 34 34"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="mb-4"
          >
            <rect
              x="1.6"
              y="1.6"
              width="30.8"
              height="30.8"
              rx="15.4"
              fill="#F9FDFF"
            />
            <rect
              x="1.6"
              y="1.6"
              width="30.8"
              height="30.8"
              rx="15.4"
              stroke="#78BD4F"
              strokeWidth="2.8"
            />
            <path
              d="M23.5315 12.0977L14.5482 21.081L10.4648 16.9977"
              stroke="#78BD4F"
              strokeWidth="2.8"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <h3 className="text-[24px] text-[#667085] font-medium mb-6 text-center leading-tight">
            Your payment has been processed
          </h3>
          <p className="text-[#667085] font-medium text-sm text-center">
            Amount Paid
          </p>
          <h3 className="text-[#101828] text-[30px] font-semibold text-center mb-4">
            {currencySymbol}
            {parseFloat(amountPay || 0)?.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </h3>
          {details?.invoice?.paymentStatus === 'paid' && (
            <a
              href={details?.invoice?.receiptUrl}
              target="_blank"
              rel="noreferrer"
            >
              <button
                type="button"
                disabled={!details?.invoice?.receiptUrl}
                className="h-[53px] w-[300px] max-w-full text-white rounded-[6px] inline-flex justify-center items-center gap-2 text-[18px] font-semibold mt-3 mb-12 disabled:bg-[#0c919a95] disabled:cursor-not-allowed"
                style={{
                  background: details?.invoice?.color
                    ? `#${details?.invoice?.color}`
                    : '#0C909A',
                }}
              >
                {successLoader && (
                  <svg
                    className="animate-spin w-4 h-4 fill-current shrink-0 mr-2"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z" />
                  </svg>
                )}
                View Receipt
              </button>
            </a>
          )}
          <p className="text-xs text-[#667085] text-center mb-7">
            Copyright {new Date()?.getFullYear()} · COUNT
          </p>
        </div>
      </div>
    </div>
  </div>
);

export default InvoicePaymentSuccess;
