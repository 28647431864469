import React from 'react';

export const Loader = ({
  width = 'w-10',
  height = 'h-10',
  color = '#E48642',
}) => (
  <svg
    viewBox="0 0 29 29"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={`animate-spin ${width} ${height} fill-current shrink-0`}
  >
    <path
      d="M14.5 2.41699V7.25033"
      stroke={color}
      strokeWidth="2.55882"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.5 21.749V26.5824"
      stroke={color}
      strokeWidth="2.55882"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.95703 5.95703L9.37662 9.37662"
      stroke={color}
      strokeWidth="1.58955"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.623 19.6211L23.0426 23.0407"
      stroke={color}
      strokeWidth="1.58955"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.41699 14.5H7.25033"
      stroke={color}
      strokeWidth="1.58955"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21.749 14.5H26.5824"
      stroke={color}
      strokeWidth="1.58955"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.95703 23.0407L9.37662 19.6211"
      stroke={color}
      strokeWidth="1.58955"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.623 9.37662L23.0426 5.95703"
      stroke={color}
      strokeWidth="1.58955"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const InfoIcon = ({
  width = 'w-[1.125rem]',
  height = 'h-[1.125rem]',
}) => (
  <svg
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={`${width} ${height}`}
  >
    <circle cx="8.60522" cy="8.99976" r="8.60522" fill="#BDBDBD" />
    <rect
      x="7.17041"
      y="7.08984"
      width="2.8684"
      height="7.64908"
      rx="1.4342"
      fill="white"
    />
    <rect
      x="7.17041"
      y="3.26172"
      width="2.8684"
      height="2.8684"
      rx="1.4342"
      fill="white"
    />
  </svg>
);
