import React, { useState, useRef, useEffect } from 'react';
import Transition from '../utils/Transition';

function DropdownTransaction({
  align,
  accounts,
  allAccounts,
  selectedAccount,
  setSelectedAccount,
  getTransactionsApi = () => {},
  isDropdown = false,
  disabled = false,
}) {
  // when isDropdown===true then we are not calling getTransactionsApi as there is no need of it in Create Transaction models dropdowns
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [filteredData, setFilteredData] = useState(allAccounts);

  const trigger = useRef(null);
  const dropdown = useRef(null);
  const searchInput = useRef(null);

  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!dropdown.current) return;
      if (
        !dropdownOpen ||
        dropdown.current.contains(target) ||
        trigger.current.contains(target)
      )
        return;
      setDropdownOpen(false);
    };
    document.addEventListener('click', clickHandler);
    return () => document.removeEventListener('click', clickHandler);
  });

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!dropdownOpen || keyCode !== 27) return;
      setDropdownOpen(false);
    };
    document.addEventListener('keydown', keyHandler);
    return () => document.removeEventListener('keydown', keyHandler);
  });

  useEffect(() => {
    if (accounts) {
      setFilteredData(accounts);
    }
  }, [accounts]);

  useEffect(() => {
    if (dropdownOpen) {
      setSearchValue('');
      setFilteredData(accounts);
      searchInput.current?.focus();
    }
  }, [dropdownOpen]);

  const getAccountName = () => {
    let result = '';
    if (typeof accounts === 'object') {
      Object.keys(accounts).forEach((key) => {
        const res = accounts[key].find(
          (account) => account.id === selectedAccount,
        );
        if (res) {
          result = res?.name;
        }
      });
    }
    return result;
  };

  const handleSearch = (e) => {
    setSearchValue(e.target.value);
    if (!e.target.value) {
      setFilteredData(accounts);
      return;
    }
    const formattedValue = e.target.value.replace(/\s/g, '').toLowerCase();
    const res = allAccounts.filter((acc) => {
      const str = `${acc?.name}(${acc?.mask})`;
      return str?.replace(/\s/g, '').toLowerCase().includes(formattedValue);
    });
    setFilteredData(
      res?.reduce?.((result, item) => {
        // eslint-disable-next-line no-param-reassign
        (result[item.type.subtype] = result[item.type.subtype] || []).push(
          item,
        );
        return result;
      }, {}),
    );
  };

  return (
    <div className={`relative inline-flex ${isDropdown ? 'w-full' : ''}`}>
      <button
        ref={trigger}
        className={
          isDropdown
            ? 'btn px-4 py-2.5 h-12 rounded-[0.313rem] justify-between w-full font-normal leading-6 form-input border-[#D0D5DD] hover:border-slate-400 dark:hover:border-slate-600 text-slate-600 dark:text-slate-300 dark:hover:text-slate-200'
            : 'inline-flex justify-center items-center group'
        }
        aria-haspopup="true"
        onClick={() => setDropdownOpen(!dropdownOpen)}
        aria-expanded={dropdownOpen}
        type="button"
        disabled={disabled}
      >
        <div
          className={`flex items-center truncate ${
            isDropdown ? 'w-full justify-between' : ''
          }`}
        >
          <span
            className={`truncate font-medium text-indigo-500 group-hover:text-indigo-600 dark:group-hover:text-indigo-400 ${
              isDropdown
                ? 'text-slate-600 dark:text-slate-300 font-normal text-base tracking-normal leading-6'
                : ''
            } ${
              isDropdown && !selectedAccount
                ? 'text-slate-600 text-opacity-80'
                : ''
            }`}
          >
            {!selectedAccount
              ? isDropdown
                ? 'Select account'
                : ' All Accounts'
              : getAccountName()}
          </span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="10"
            height="5"
            viewBox="0 0 10 5"
            fill="none"
          >
            <path
              d="M9 1L5.70707 3.7559C5.31818 4.08137 4.68182 4.08137 4.29293 3.7559L1 1"
              stroke="#667085"
              strokeOpacity="0.8"
              strokeWidth="1.5"
              strokeMiterlimit="10"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      </button>

      <Transition
        className={`origin-top-right z-10 absolute top-full min-w-44 bg-white dark:bg-slate-800 border border-slate-200 dark:border-slate-700 py-1.5 rounded shadow-lg overflow-hidden mt-1 ${
          align === 'right' ? 'right-0' : 'left-0'
        }  ${isDropdown ? ' w-full' : ''}`}
        show={dropdownOpen}
        enter="transition ease-out duration-200 transform"
        enterStart="opacity-0 -translate-y-2"
        enterEnd="opacity-100 translate-y-0"
        leave="transition ease-out duration-200"
        leaveStart="opacity-100"
        leaveEnd="opacity-0"
      >
        <div
          ref={dropdown}
          onFocus={() => setDropdownOpen(true)}
          onBlur={() => setDropdownOpen(false)}
        >
          <div className="relative flex-1 px-3 mb-1">
            <input
              type="text"
              placeholder="Search..."
              className="form-input text-indigo-600  placeholder:!text-indigo-600 !border-indigo-600  my-2 pl-8 w-full inline-block"
              value={searchValue}
              onChange={handleSearch}
              ref={searchInput}
              onClick={(e) => e.stopPropagation()}
            />
            <svg
              className="absolute left-3 top-[50%] -translate-y-[50%] pl-2 w-6 h-6"
              width="16"
              height="17"
              viewBox="0 0 16 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14 14.5L11.6667 12.1667M13.3333 8.16667C13.3333 11.2963 10.7963 13.8333 7.66667 13.8333C4.53705 13.8333 2 11.2963 2 8.16667C2 5.03705 4.53705 2.5 7.66667 2.5C10.7963 2.5 13.3333 5.03705 13.3333 8.16667Z"
                stroke="#E48642"
                strokeWidth="1.2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
          <div className="max-h-60 scrollbar overflow-auto pt-2 w-full flex flex-col">
            {!isDropdown && (
              <span
                className="font-medium text-sm text-slate-600 text-opacity-60 dark:text-slate-300 hover:text-slate-800 dark:hover:text-slate-200 flex items-center py-1 px-3 cursor-pointer"
                onClick={() => {
                  setDropdownOpen(!dropdownOpen);
                  if (selectedAccount !== null) {
                    setSelectedAccount(null);
                    getTransactionsApi({
                      page: 1,
                      accounts: [],
                    });
                  }
                }}
              >
                All Accounts
              </span>
            )}
            {Object.keys(filteredData)?.map(
              (key, index) =>
                filteredData[key]?.length > 0 && (
                  <div
                    className="mb-2 cursor-default"
                    onClick={(e) => e.stopPropagation()}
                    key={index}
                  >
                    <span className="font-semibold text-[10px] uppercase tracking-wider whitespace-nowrap flex items-center pr-4  text-[#515A6C]  py-1 px-3">
                      {key}
                    </span>
                    {filteredData[key]?.map((account) => (
                      <div className="ml-2 cursor-pointer" key={account?.id}>
                        <span
                          className={`font-normal whitespace-nowrap flex items-center pr-4 text-[13px] text-[#667085]  hover:text-slate-800 dark:hover:text-slate-200 py-1 px-3
                                    ${account?.id === selectedAccount && 'text-indigo-500 hover:!text-indigo-600'}`}
                          onClick={() => {
                            setDropdownOpen(false);
                            if (selectedAccount !== account?.id) {
                              setSelectedAccount(account?.id);
                              if (!isDropdown) {
                                getTransactionsApi({
                                  page: 1,
                                  accounts: [account?.id],
                                });
                              }
                            }
                          }}
                        >
                          {account?.name}{' '}
                          {account?.mask ? `(${account?.mask})` : ''}
                          <svg
                            className={`shrink-0 ml-2 fill-current text-indigo-500 ${
                              account?.id !== selectedAccount && 'invisible'
                            }`}
                            width="12"
                            height="9"
                            viewBox="0 0 12 9"
                          >
                            <path d="M10.28.28L3.989 6.575 1.695 4.28A1 1 0 00.28 5.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28.28z" />
                          </svg>
                        </span>
                      </div>
                    ))}
                  </div>
                ),
            )}
          </div>
        </div>
      </Transition>
    </div>
  );
}

export default DropdownTransaction;
