import React, { useEffect, useState } from 'react';
import ModalBasic from '../../components/ModalBasic';
import TextInputDash from '../../components/TextInputDash';

const SaveModal = ({
  isOpen,
  setIsOpen,
  handleSave,
  localAutomation,
  nodes,
  automationType,
}) => {
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState(localAutomation?.name || '');
  const [applyRetroactively, setApplyRetroactively] = useState(false);

  useEffect(() => {
    if (!localAutomation && isOpen) {
      const actionNode = nodes.find((node) => node.type === 'action');
      if (actionNode) {
        setName(actionNode.data.name.slice(6, 60));
      }
    }
  }, [isOpen]);

  const callSave = async () => {
    setLoading(true);
    try {
      await handleSave(name, applyRetroactively);
    } catch (error) {
      console.log('Error saving automation', error);
    } finally {
      setLoading(false);
      setIsOpen(false);
    }
  };

  return (
    <ModalBasic
      id="basic-modal"
      modalOpen={isOpen}
      setModalOpen={setIsOpen}
      title="Save Automation"
      dialogClassName="!overflow-visible"
    >
      <div className="px-5 mt-4 border-b border-[#D0D5DD]">
        <TextInputDash
          label="Name"
          name="name"
          placeholder="Automation Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
        />
        {automationType === 'transaction' && (
          <div className="flex items-center gap-3 mb-4">
            <div className="flex items-center">
              <input
                id="applyRetroactively"
                name="applyRetroactively"
                type="checkbox"
                className="h-4 w-4 form-checkbox cursor-pointer"
                onChange={(e) => {
                  setApplyRetroactively(e.target.checked);
                }}
                checked={applyRetroactively}
              />
              <label
                htmlFor="applyRetroactively"
                className="ml-2 block text-sm text-gray-900 cursor-pointer"
              >
                Apply to all past transactions
              </label>
            </div>
          </div>
        )}
      </div>
      {/* Modal footer */}
      <div className="flex flex-wrap  justify-end space-x-2 px-5 py-4">
        <button
          type="submit"
          className="py-2.5 px-[1.875rem] bg-white text-indigo-500 border border-indigo-500 text-base leading-6 font-base rounded-[0.313rem]"
          disabled={loading}
          onClick={() => setIsOpen(false)}
        >
          Cancel
        </button>
        <button
          type="submit"
          className="py-2.5 px-[1.875rem] bg-indigo-500 text-white text-base leading-6 font-base rounded-[0.313rem]
            disabled:bg-indigo-300    disabled:cursor-not-allowed"
          disabled={loading || !name}
          onClick={() => {
            callSave();
          }}
        >
          {loading ? (
            <svg
              className="animate-spin w-4 h-4 fill-current shrink-0 mr-2"
              viewBox="0 0 16 16"
            >
              <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z" />
            </svg>
          ) : (
            `Save`
          )}
        </button>
      </div>
    </ModalBasic>
  );
};

export default SaveModal;
