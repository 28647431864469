import React from 'react';
import { Handle } from 'reactflow';
import { Card, CardUpdated, CardDeleted, Bullseye } from '../svg';

const TriggerNode = ({
  id,
  data,
  setActiveNodeId,
  setIsEditing,
  setPanelOpen,
  setPanelStep,
}) => {
  const handleClick = () => {
    setIsEditing(true);
    setActiveNodeId(id);
    setPanelOpen(true);
    setPanelStep('trigger1');
  };

  const findTriggerIcon = () => {
    switch (data?.name) {
      case 'Transaction Created':
        return <Card />;
      case 'Transaction Updated':
        return <CardUpdated />;
      case 'Transaction Deleted':
        return <CardDeleted />;
      default:
        return <Bullseye />;
    }
  };

  return (
    <div
      className="p-4 w-[23.625rem] flex flex-col items-start gap-2.5 rounded-[15px] border border-[#78BD4F] bg-white cursor-pointer"
      onClick={() => handleClick()}
      role="button"
      tabIndex="0"
    >
      <div className="flex items-start gap-2.5">
        <div className="w-11 h-11 gap-2.5 flex items-center justify-center py-4 px-2.5 rounded-[10px] bg-[#EFFFE5]">
          {findTriggerIcon()}
        </div>
        <div className="flex flex-col gap-1 items-start">
          <p className="text-[#0F0F0F] text-[1rem]">When: {data?.name}</p>
          <p className="text-slate-600 text-[0.875rem] leading-4">
            {data?.filters?.length > 0 && '+Filters'}
          </p>
        </div>
      </div>
      <Handle
        type="source"
        position="bottom"
        className="opacity-0 mb-2"
        isConnectable={false}
      />
    </div>
  );
};

export default TriggerNode;
