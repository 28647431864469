import React, { useEffect, useRef, useState } from "react";
import { FormatValue } from "./FormatValue";

export const TableHeader = ({ price, label, color = "", sign ="" }) => {
  const textRef = useRef(null);
  const [isOverflowing, setIsOverflowing] = useState(false);

  useEffect(() => {
    if (textRef.current) {
      setIsOverflowing(
        textRef.current.scrollWidth > textRef.current.clientWidth
      );
    }
  }, []);

  return (
    <div ref={textRef} className="flex flex-col gap-2">
      <p className="font-medium">{label}</p>
      <p
        className={`font-semibold ${color || "text-[#101828]"}   ${
          isOverflowing ? "text-[1.25rem]" : "text-[1.75rem]"
        }`}
      >
        <FormatValue value={price} sign={sign || "$"} />
      </p>
    </div>
  );
};
