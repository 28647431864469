import React from 'react';

const BagIcon = ({ color }) => (
  <svg
    width="31"
    height="30"
    viewBox="0 0 31 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={`fill-[${color}]`}
  >
    <g clipPath="url(#clip0_7486_33032)">
      <path
        d="M29.1381 16.3633C28.729 16.3633 28.4563 16.636 28.4563 17.0451V25.9087C28.4563 26.3178 28.1835 26.5905 27.7745 26.5905H3.22898C2.81988 26.5905 2.54719 26.3178 2.54719 25.9087V17.0451C2.54719 16.636 2.27443 16.3633 1.86539 16.3633C1.45635 16.3633 1.18359 16.636 1.18359 17.0451V25.9087C1.18359 27.0678 2.06994 27.9542 3.22904 27.9542H27.7745C28.9336 27.9542 29.8199 27.0678 29.8199 25.9087V17.0451C29.8199 16.636 29.5472 16.3633 29.1381 16.3633Z"
        fill="#667085"
        className="fill-current"
      />
      <path
        d="M28.4546 6.81836H2.54545C1.38635 6.81836 0.5 7.70471 0.5 8.86381V13.2275C0.5 14.182 1.1818 15.0002 2.0682 15.2047L12.7728 17.5911V19.7729C12.7728 20.182 13.0455 20.4546 13.4546 20.4546H17.5454C17.9545 20.4546 18.2272 20.1819 18.2272 19.7729V17.5911L28.9318 15.2047C29.8182 15.0002 30.5 14.182 30.5 13.2274V8.86375C30.5 7.70471 29.6137 6.81836 28.4546 6.81836ZM16.8637 19.0911H14.1364V16.3638H16.8637V19.0911ZM29.1363 13.2274C29.1363 13.5683 28.9318 13.8411 28.5909 13.9092L18.2272 16.2274V15.682C18.2272 15.2729 17.9545 15.0002 17.5454 15.0002H13.4546C13.0454 15.0002 12.7728 15.2729 12.7728 15.682V16.2274L2.4091 13.9093C2.0682 13.8411 1.86365 13.5684 1.86365 13.2275V8.86381C1.86365 8.45471 2.13641 8.18201 2.54545 8.18201H28.4546C28.8637 8.18201 29.1363 8.45477 29.1363 8.86381V13.2274Z"
        fill="#667085"
        className="fill-current"
      />
      <path
        d="M18.9105 2.04492H12.0923C10.9332 2.04492 10.0469 2.93127 10.0469 4.09037V4.77217C10.0469 5.18127 10.3196 5.45396 10.7287 5.45396C11.1377 5.45396 11.4105 5.18121 11.4105 4.77217V4.09037C11.4105 3.68127 11.6832 3.40857 12.0923 3.40857H18.9105C19.3196 3.40857 19.5923 3.68133 19.5923 4.09037V4.77217C19.5923 5.18127 19.865 5.45396 20.2741 5.45396C20.6832 5.45396 20.9559 5.18121 20.9559 4.77217V4.09037C20.956 2.93127 20.0696 2.04492 18.9105 2.04492Z"
        fill="#667085"
        className="fill-current"
      />
    </g>
    <defs>
      <clipPath id="clip0_7486_33032">
        <rect width="30" height="30" fill="white" transform="translate(0.5)" />
      </clipPath>
    </defs>
  </svg>
);

const BookIcon = ({ color }) => (
  <svg
    width="31"
    height="30"
    viewBox="0 0 31 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={`text-[${color}] fill-[${color}]`}
  >
    <path
      d="M6.52344 17.0703C6.39912 17.0703 6.27989 17.0209 6.19198 16.933C6.10407 16.8451 6.05469 16.7259 6.05469 16.6016V13.3965C6.05469 13.2722 6.10407 13.1529 6.19198 13.065C6.27989 12.9771 6.39912 12.9277 6.52344 12.9277C6.64776 12.9277 6.76699 12.9771 6.85489 13.065C6.9428 13.1529 6.99219 13.2722 6.99219 13.3965V16.6016C6.99219 16.7259 6.9428 16.8451 6.85489 16.933C6.76699 17.0209 6.64776 17.0703 6.52344 17.0703Z"
      fill="#667085"
      className="fill-current"
    />
    <path
      d="M6.86719 26.8713L6.86719 26.8714C6.86737 27.1286 6.96957 27.3752 7.15138 27.5571C7.33318 27.7391 7.57974 27.8414 7.83694 27.8418H7.83711H23.576H23.5761C24.3202 27.841 25.0337 27.545 25.5599 27.0188C26.086 26.4927 26.382 25.7792 26.3828 25.0351V25.035V4.96894V4.96881C26.382 4.22467 26.086 3.51125 25.5599 2.98507C25.0337 2.45889 24.3202 2.16292 23.5761 2.16211H23.576L7.83711 2.16211L7.83694 2.16211C7.57974 2.16247 7.33318 2.26484 7.15138 2.44677C6.96957 2.6287 6.86737 2.87533 6.86719 3.13253V3.13262V6.05762C6.86719 6.14879 6.83097 6.23622 6.76651 6.30068C6.70204 6.36515 6.61461 6.40137 6.52344 6.40137C6.43227 6.40137 6.34484 6.36515 6.28037 6.30068C6.2159 6.23622 6.17969 6.14879 6.17969 6.05762V3.13268C6.18014 2.69317 6.35489 2.27177 6.66563 1.96093C6.97638 1.65008 7.39774 1.47519 7.83727 1.47461L23.5759 1.47461C24.5024 1.47568 25.3905 1.84418 26.0456 2.49928C26.7008 3.15439 27.0693 4.04261 27.0703 4.96909V25.0348C27.0693 25.9613 26.7008 26.8495 26.0456 27.5046C25.3905 28.1597 24.5023 28.5282 23.5758 28.5293H7.83727C7.39774 28.5287 6.97638 28.3538 6.66563 28.043C6.35488 27.7321 6.18012 27.3107 6.17969 26.8712L6.17969 23.9463C6.17969 23.8551 6.2159 23.7677 6.28037 23.7032C6.34483 23.6388 6.43227 23.6025 6.52344 23.6025C6.61461 23.6025 6.70204 23.6388 6.76651 23.7032C6.83097 23.7677 6.86719 23.8551 6.86719 23.9463L6.86719 26.8713Z"
      fill="#667085"
      className="fill-current"
      stroke="#667085"
      strokeWidth="0.25"
    />
    <path
      d="M6.52344 22.3438C6.39912 22.3438 6.27989 22.2944 6.19198 22.2065C6.10407 22.1186 6.05469 21.9993 6.05469 21.875V18.6699C6.05469 18.5456 6.10407 18.4264 6.19198 18.3385C6.27989 18.2506 6.39912 18.2012 6.52344 18.2012C6.64776 18.2012 6.76699 18.2506 6.85489 18.3385C6.9428 18.4264 6.99219 18.5456 6.99219 18.6699V21.875C6.99219 21.9993 6.9428 22.1186 6.85489 22.2065C6.76699 22.2944 6.64776 22.3438 6.52344 22.3438Z"
      fill="#667085"
      className="fill-current"
    />
    <path
      d="M6.52344 11.7969C6.39912 11.7969 6.27989 11.7475 6.19198 11.6596C6.10407 11.5717 6.05469 11.4524 6.05469 11.3281V8.12305C6.05469 7.99873 6.10407 7.8795 6.19198 7.79159C6.27989 7.70368 6.39912 7.6543 6.52344 7.6543C6.64776 7.6543 6.76699 7.70368 6.85489 7.79159C6.9428 7.8795 6.99219 7.99873 6.99219 8.12305V11.3281C6.99219 11.4524 6.9428 11.5717 6.85489 11.6596C6.76699 11.7475 6.64776 11.7969 6.52344 11.7969Z"
      fill="#667085"
      className="fill-current"
    />
    <path
      d="M7.7375 24.293H5.30762C4.94217 24.293 4.59169 24.1478 4.33327 23.8894C4.07486 23.631 3.92969 23.2805 3.92969 22.915C3.92969 22.5496 4.07486 22.1991 4.33327 21.9407C4.59169 21.6823 4.94217 21.5371 5.30762 21.5371H7.7375C8.10295 21.5371 8.45343 21.6823 8.71184 21.9407C8.97025 22.1991 9.11543 22.5496 9.11543 22.915C9.11543 23.2805 8.97025 23.631 8.71184 23.8894C8.45343 24.1478 8.10295 24.293 7.7375 24.293ZM5.30762 22.2246C5.1245 22.2246 4.94889 22.2974 4.81941 22.4268C4.68993 22.5563 4.61719 22.7319 4.61719 22.915C4.61719 23.0982 4.68993 23.2738 4.81941 23.4032C4.94889 23.5327 5.1245 23.6055 5.30762 23.6055H7.7375C7.92061 23.6055 8.09623 23.5327 8.22571 23.4032C8.35519 23.2738 8.42793 23.0982 8.42793 22.915C8.42793 22.7319 8.35519 22.5563 8.22571 22.4268C8.09623 22.2974 7.92061 22.2246 7.7375 22.2246H5.30762Z"
      fill="#667085"
      className="fill-current"
      stroke="#667085"
      strokeWidth="0.25"
    />
    <path
      d="M7.7375 19.0195H5.30762C4.94217 19.0195 4.59169 18.8744 4.33327 18.6159C4.07486 18.3575 3.92969 18.0071 3.92969 17.6416C3.92969 17.2762 4.07486 16.9257 4.33327 16.6673C4.59169 16.4088 4.94217 16.2637 5.30762 16.2637H7.7375C8.10295 16.2637 8.45343 16.4088 8.71184 16.6673C8.97025 16.9257 9.11543 17.2762 9.11543 17.6416C9.11543 18.0071 8.97025 18.3575 8.71184 18.6159C8.45343 18.8744 8.10295 19.0195 7.7375 19.0195ZM5.30762 16.9512C5.1245 16.9512 4.94889 17.0239 4.81941 17.1534C4.68993 17.2829 4.61719 17.4585 4.61719 17.6416C4.61719 17.8247 4.68993 18.0003 4.81941 18.1298C4.94889 18.2593 5.1245 18.332 5.30762 18.332H7.7375C7.92061 18.332 8.09623 18.2593 8.22571 18.1298C8.35519 18.0003 8.42793 17.8247 8.42793 17.6416C8.42793 17.4585 8.35519 17.2829 8.22571 17.1534C8.09623 17.0239 7.92061 16.9512 7.7375 16.9512H5.30762Z"
      fill="#667085"
      className="fill-current"
      stroke="#667085"
      strokeWidth="0.25"
    />
    <path
      d="M7.7375 13.7461H5.30762C4.94217 13.7461 4.59169 13.6009 4.33327 13.3425C4.07486 13.0841 3.92969 12.7336 3.92969 12.3682C3.92969 12.0027 4.07486 11.6522 4.33327 11.3938C4.59169 11.1354 4.94217 10.9902 5.30762 10.9902H7.7375C8.10295 10.9902 8.45343 11.1354 8.71184 11.3938C8.97025 11.6522 9.11543 12.0027 9.11543 12.3682C9.11543 12.7336 8.97025 13.0841 8.71184 13.3425C8.45343 13.6009 8.10295 13.7461 7.7375 13.7461ZM5.30762 11.6777C5.1245 11.6777 4.94889 11.7505 4.81941 11.88C4.68993 12.0094 4.61719 12.1851 4.61719 12.3682C4.61719 12.5513 4.68993 12.7269 4.81941 12.8564C4.94889 12.9859 5.1245 13.0586 5.30762 13.0586H7.7375C7.92061 13.0586 8.09623 12.9859 8.22571 12.8564C8.35519 12.7269 8.42793 12.5513 8.42793 12.3682C8.42793 12.1851 8.35519 12.0094 8.22571 11.88C8.09623 11.7505 7.92061 11.6777 7.7375 11.6777H5.30762Z"
      fill="#667085"
      className="fill-current"
      stroke="#667085"
      strokeWidth="0.25"
    />
    <path
      d="M7.7375 8.47266H5.30762C4.94217 8.47266 4.59169 8.32748 4.33327 8.06907C4.07486 7.81066 3.92969 7.46018 3.92969 7.09473C3.92969 6.72928 4.07486 6.37879 4.33327 6.12038C4.59169 5.86197 4.94217 5.7168 5.30762 5.7168H7.7375C8.10295 5.7168 8.45343 5.86197 8.71184 6.12038C8.97025 6.37879 9.11543 6.72928 9.11543 7.09473C9.11543 7.46018 8.97025 7.81066 8.71184 8.06907C8.45343 8.32748 8.10295 8.47266 7.7375 8.47266ZM5.30762 6.4043C5.1245 6.4043 4.94889 6.47704 4.81941 6.60652C4.68993 6.736 4.61719 6.91161 4.61719 7.09473C4.61719 7.27784 4.68993 7.45345 4.81941 7.58293C4.94889 7.71241 5.1245 7.78516 5.30762 7.78516H7.7375C7.92061 7.78516 8.09623 7.71241 8.22571 7.58293C8.35519 7.45345 8.42793 7.27784 8.42793 7.09473C8.42793 6.91161 8.35519 6.736 8.22571 6.60652C8.09623 6.47704 7.92061 6.4043 7.7375 6.4043H5.30762Z"
      fill="#667085"
      className="fill-current"
      stroke="#667085"
      strokeWidth="0.25"
    />
    <path
      d="M17.7134 14.2092C17.3843 14.21 17.0615 14.1193 16.7809 13.9474C16.5003 13.7754 16.273 13.5289 16.1243 13.2354C16.0678 13.1246 16.0576 12.9959 16.0961 12.8776C16.1345 12.7593 16.2183 12.6611 16.3291 12.6046C16.4399 12.5481 16.5686 12.5379 16.6869 12.5763C16.8052 12.6148 16.9034 12.6986 16.9598 12.8094C17.0278 12.9434 17.1302 13.0569 17.2567 13.1381C17.3831 13.2194 17.5289 13.2654 17.679 13.2716C17.8292 13.2777 17.9782 13.2437 18.1108 13.173C18.2434 13.1023 18.3548 12.9976 18.4334 12.8695C18.5121 12.7415 18.5552 12.5948 18.5582 12.4445C18.5613 12.2943 18.5242 12.1459 18.4508 12.0148C18.3775 11.8837 18.2704 11.7745 18.1408 11.6985C18.0112 11.6225 17.8636 11.5824 17.7134 11.5824C17.3911 11.5826 17.0747 11.4955 16.7981 11.3302C16.5214 11.1649 16.2947 10.9277 16.1421 10.6438C15.9895 10.3599 15.9168 10.04 15.9316 9.71805C15.9464 9.3961 16.0482 9.08419 16.2262 8.81551C16.4043 8.54684 16.6518 8.33146 16.9425 8.1923C17.2332 8.05314 17.5562 7.9954 17.8771 8.02523C18.198 8.05506 18.5048 8.17134 18.7649 8.3617C19.0249 8.55206 19.2285 8.80937 19.354 9.10625C19.4024 9.22086 19.4033 9.35 19.3565 9.46527C19.3333 9.52235 19.2991 9.5743 19.2558 9.61816C19.2126 9.66202 19.1611 9.69693 19.1044 9.7209C18.9898 9.76931 18.8606 9.7702 18.7454 9.72339C18.6301 9.67658 18.5381 9.5859 18.4897 9.47129C18.4306 9.33032 18.3343 9.20804 18.2111 9.11749C18.0879 9.02693 17.9425 8.9715 17.7903 8.95708C17.6381 8.94267 17.4848 8.96983 17.3468 9.03565C17.2088 9.10148 17.0913 9.2035 17.0067 9.33086C16.9222 9.45823 16.8737 9.60615 16.8666 9.75886C16.8595 9.91157 16.8939 10.0634 16.9663 10.198C17.0386 10.3327 17.1461 10.4453 17.2774 10.5236C17.4087 10.602 17.5587 10.6433 17.7116 10.6432C18.1787 10.6516 18.6237 10.8431 18.951 11.1764C19.2783 11.5097 19.4617 11.9582 19.4617 12.4253C19.4617 12.8924 19.2783 13.3409 18.951 13.6742C18.6237 14.0075 18.1787 14.199 17.7116 14.2074L17.7134 14.2092Z"
      fill="#667085"
      className="fill-current"
    />
    <path
      d="M17.7148 8.95156C17.5905 8.95156 17.4713 8.90218 17.3834 8.81427C17.2955 8.72636 17.2461 8.60713 17.2461 8.48281V7.37305C17.2461 7.24873 17.2955 7.1295 17.3834 7.04159C17.4713 6.95368 17.5905 6.9043 17.7148 6.9043C17.8392 6.9043 17.9584 6.95368 18.0463 7.04159C18.1342 7.1295 18.1836 7.24873 18.1836 7.37305V8.48281C18.1836 8.60713 18.1342 8.72636 18.0463 8.81427C17.9584 8.90218 17.8392 8.95156 17.7148 8.95156Z"
      fill="#667085"
      className="fill-current"
    />
    <path
      d="M17.7148 15.1836C17.5905 15.1836 17.4713 15.1342 17.3834 15.0463C17.2955 14.9584 17.2461 14.8392 17.2461 14.7148V13.7363C17.2461 13.612 17.2955 13.4928 17.3834 13.4049C17.4713 13.317 17.5905 13.2676 17.7148 13.2676C17.8392 13.2676 17.9584 13.317 18.0463 13.4049C18.1342 13.4928 18.1836 13.612 18.1836 13.7363V14.7148C18.1836 14.8392 18.1342 14.9584 18.0463 15.0463C17.9584 15.1342 17.8392 15.1836 17.7148 15.1836Z"
      fill="#667085"
      className="fill-current"
    />
    <path
      d="M23.9199 19.4316H11.5039C11.3796 19.4316 11.2604 19.3823 11.1724 19.2943C11.0845 19.2064 11.0352 19.0872 11.0352 18.9629C11.0352 18.8386 11.0845 18.7193 11.1724 18.6314C11.2604 18.5435 11.3796 18.4941 11.5039 18.4941H23.9199C24.0442 18.4941 24.1635 18.5435 24.2514 18.6314C24.3393 18.7193 24.3887 18.8386 24.3887 18.9629C24.3887 19.0872 24.3393 19.2064 24.2514 19.2943C24.1635 19.3823 24.0442 19.4316 23.9199 19.4316Z"
      fill="#667085"
      className="fill-current"
    />
    <path
      d="M23.9199 22.0566H11.5039C11.3796 22.0566 11.2604 22.0073 11.1724 21.9193C11.0845 21.8314 11.0352 21.7122 11.0352 21.5879C11.0352 21.4636 11.0845 21.3443 11.1724 21.2564C11.2604 21.1685 11.3796 21.1191 11.5039 21.1191H23.9199C24.0442 21.1191 24.1635 21.1685 24.2514 21.2564C24.3393 21.3443 24.3887 21.4636 24.3887 21.5879C24.3887 21.7122 24.3393 21.8314 24.2514 21.9193C24.1635 22.0073 24.0442 22.0566 23.9199 22.0566Z"
      fill="#667085"
      className="fill-current"
    />
    <path
      d="M23.9199 24.7051H11.5039C11.3796 24.7051 11.2604 24.6557 11.1724 24.5678C11.0845 24.4799 11.0352 24.3606 11.0352 24.2363C11.0352 24.112 11.0845 23.9928 11.1724 23.9049C11.2604 23.817 11.3796 23.7676 11.5039 23.7676H23.9199C24.0442 23.7676 24.1635 23.817 24.2514 23.9049C24.3393 23.9928 24.3887 24.112 24.3887 24.2363C24.3887 24.3606 24.3393 24.4799 24.2514 24.5678C24.1635 24.6557 24.0442 24.7051 23.9199 24.7051Z"
      fill="#667085"
      className="fill-current"
    />
    <path
      d="M17.7111 6.11133H17.7109C16.7359 6.11133 15.7828 6.40045 14.9722 6.94213C14.1615 7.48381 13.5296 8.25373 13.1565 9.15451C12.7834 10.0553 12.6858 11.0465 12.876 12.0028C13.0662 12.959 13.5357 13.8374 14.2251 14.5268C14.9146 15.2163 15.7929 15.6858 16.7492 15.876C17.7055 16.0662 18.6967 15.9686 19.5975 15.5955C20.4982 15.2223 21.2681 14.5905 21.8098 13.7798C22.3515 12.9691 22.6406 12.016 22.6406 11.041V11.0409C22.6392 9.73392 22.1194 8.4809 21.1952 7.55674C20.2711 6.63258 19.018 6.11276 17.7111 6.11133ZM17.7108 16.6582C16.5999 16.6582 15.5139 16.3287 14.5902 15.7115C13.6665 15.0943 12.9465 14.217 12.5213 13.1906C12.0962 12.1642 11.9849 11.0348 12.2017 9.94516C12.4184 8.85553 12.9534 7.85465 13.739 7.06907C14.5246 6.28349 15.5255 5.7485 16.6151 5.53176C17.7047 5.31502 18.8341 5.42626 19.8605 5.85141C20.887 6.27657 21.7642 6.99653 22.3815 7.92028C22.9987 8.84398 23.3281 9.92995 23.3281 11.0409C23.3265 12.5302 22.7341 13.958 21.681 15.0111C20.6279 16.0642 19.2001 16.6565 17.7108 16.6582Z"
      fill="#667085"
      className="fill-current"
      stroke="#667085"
      strokeWidth="0.25"
    />
  </svg>
);

const Form2 = ({
  loading,
  BUSINESS_ACCOUNT,
  BOOKKEEPER_ACCOUNT,
  accountType,
  setAccountType,
  handleSignUp,
}) => (
  <div className="space-y-8">
    <div
      className={`h-[60px] w-full rounded-[12px] border  px-4 flex items-center cursor-pointer
       ${accountType === BUSINESS_ACCOUNT ? 'bg-[#F1F8EC] border-[#78BD4F] text-[#101828]' : 'border-[#D0D5DD] text-[#667085]'}
       `}
      role="button"
      tabIndex="0"
      onClick={() => setAccountType(BUSINESS_ACCOUNT)}
    >
      <BagIcon
        color={accountType === BUSINESS_ACCOUNT ? '#101828' : '#667085'}
      />
      <p className="text-[18px] pl-2.5">Business Account</p>
    </div>
    <div
      className={`h-[60px] w-full rounded-[12px] border  px-4 flex items-center cursor-pointer
    ${accountType === BOOKKEEPER_ACCOUNT ? 'bg-[#F1F8EC] border-[#78BD4F] text-[#101828]' : 'border-[#D0D5DD] text-[#667085]'}
    `}
      role="button"
      tabIndex="0"
      onClick={() => setAccountType(BOOKKEEPER_ACCOUNT)}
    >
      <BookIcon
        color={accountType === BOOKKEEPER_ACCOUNT ? '#101828' : '#667085'}
      />
      <p className="text-[18px]  pl-2.5">Accounting/Bookkeeper Practice</p>
    </div>
    <button
      className="btn w-full py-4 text-lg rounded-[10px] font-semibold !bg-indigo-500 hover:bg-indigo-600 text-white
       disabled:!bg-indigo-300 disabled:text-white disabled:cursor-not-allowed"
      type="button"
      disabled={loading}
      onClick={handleSignUp}
    >
      {loading && (
        <svg
          className="animate-spin w-4 h-4 fill-current shrink-0 mr-2"
          viewBox="0 0 16 16"
        >
          <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z" />
        </svg>
      )}
      Sign Up
    </button>
  </div>
);

export default Form2;
