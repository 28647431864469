import React, { useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import ModalBasic from '../../../components/ModalBasic';
import TextInputDash from '../../../components/TextInputDash';

const TagModal = ({
  isOpen,
  setIsOpen,
  handleAddTag,
  activeTag,
  handleUpdateTag,
}) => {
  const inputRef = useRef();
  const formikRef = useRef();
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (values, formik) => {
    const { name } = values;
    setLoading(true);
    if (activeTag) {
      await handleUpdateTag(
        {
          name,
        },
        activeTag.id,
      );
    } else {
      await handleAddTag({
        name,
      });
    }
    setLoading(false);
    setIsOpen(false);
    formik?.resetForm();
  };

  useEffect(() => {
    if (!isOpen) {
      formikRef?.current?.resetForm();
    } else {
      inputRef?.current?.focus();
    }
  }, [isOpen]);

  return (
    <ModalBasic
      id="basic-modal"
      modalOpen={isOpen}
      setModalOpen={setIsOpen}
      title={`${activeTag ? 'Edit' : 'Create'} Tag`}
    >
      <Formik
        innerRef={formikRef}
        enableReinitialize
        initialValues={{
          name: activeTag?.name || '',
        }}
        validationSchema={Yup.object({
          name: Yup.string()
            .trim()
            .required('Please Tag Enter Name')
            .max(50, 'Tag Name must be at most 50 characters'),
        })}
        onSubmit={handleSubmit}
      >
        {(validation) => (
          <Form className="flex  justify-between flex-col">
            <div className="px-5 pt-4 pb-1">
              <div className="relative flex">
                <TextInputDash
                  label="Name"
                  id="name"
                  name="name"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.name || ''}
                  error={validation.touched.name ? validation.errors.name : ''}
                  required
                  placeholder="Tag Name"
                  ref={inputRef}
                />
              </div>
            </div>

            {/* Modal footer */}
            <div className="flex flex-wrap  justify-end space-x-2 px-5 py-4 mt-3">
              <button
                type="submit"
                className="py-2.5 px-[1.875rem] h-11 w-[7rem] bg-indigo-500 text-center text-white text-base leading-6 font-base rounded-[0.313rem]
            disabled:bg-indigo-100  disabled:text-slate-400  disabled:cursor-not-allowed"
                disabled={loading}
              >
                {loading ? (
                  <div className="flex items-center justify-center">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 29 29"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="animate-spin w-6 h-6 fill-current shrink-0"
                    >
                      <path
                        d="M14.5 2.41699V7.25033"
                        stroke="#E48642"
                        strokeWidth="2.55882"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M14.5 21.749V26.5824"
                        stroke="#E48642"
                        strokeWidth="2.55882"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M5.95703 5.95703L9.37662 9.37662"
                        stroke="#E48642"
                        strokeWidth="1.58955"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M19.623 19.6211L23.0426 23.0407"
                        stroke="#E48642"
                        strokeWidth="1.58955"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M2.41699 14.5H7.25033"
                        stroke="#E48642"
                        strokeWidth="1.58955"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M21.749 14.5H26.5824"
                        stroke="#E48642"
                        strokeWidth="1.58955"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M5.95703 23.0407L9.37662 19.6211"
                        stroke="#E48642"
                        strokeWidth="1.58955"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M19.623 9.37662L23.0426 5.95703"
                        stroke="#E48642"
                        strokeWidth="1.58955"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                ) : (
                  <span>{`${activeTag ? 'Update' : 'Add'}`}</span>
                )}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </ModalBasic>
  );
};

export default TagModal;
