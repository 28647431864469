import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { usePlaidLink } from 'react-plaid-link';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import {
  exchangePlaidToken,
  getTeamById,
  removePlaidAlerts,
} from '../../../API/backend_helper';
import Tooltip from '../../../components/Tooltip';
import ChooseAccountsModal from './ChooseAccountsModal';
import { setTeam } from '../../../redux/Team/reducer';
import { TEAM_ID } from '../../../utils/Utils';

const PlaidLink = ({
  loadData,
  token,
  setIsLoading,
  accountPage = false,
  accessToken = '',
  shouldNavigate = false,
  buttonText = '',
  cb = () => {},
  buttonRef = null,
  team = {},
  buttonClass = '',
  connectionError = false,
  connectionId = '',
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const TeamId = localStorage.getItem(TEAM_ID);

  const [accounts, setAccounts] = useState(false);
  const [modalLoader, setModalLoader] = useState(false);
  const afterSuccess = () => {
    loadData();
    if (shouldNavigate) {
      navigate('/settings/banks');
    }
    setIsLoading?.(false);
  };

  const fetchTeam = async () => {
    try {
      const res = await getTeamById(TeamId);
      dispatch(setTeam(res));
      return res;
    } catch (e) {
      console.log('error', e);
    }
  };

  const onSuccess = useCallback(
    (publicToken, metadata) => {
      // send public_token to your server
      // https://plaid.com/docs/api/tokens/#token-exchange-flow
      const sendToken = async () => {
        try {
          setIsLoading?.(true);
          setModalLoader?.(true);
          const response = await exchangePlaidToken(accessToken, {
            publicToken,
          });
          setModalLoader(false);
          if (response?.accounts?.length) {
            setAccounts?.(response);
          }
          if (response && connectionError) {
            const res = await removePlaidAlerts(connectionId);
            if (res) {
              fetchTeam();
              toast.success('The Plaid issue has been fixed');
            }
          }
        } catch (e) {
          console.log('e', e);
          setModalLoader?.(false);
          setIsLoading?.(false);
        }
      };
      sendToken();
      //   console.log("on Success", publicToken, metadata);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [accessToken, connectionId],
  );
  const onEvent = useCallback((eventName, metadata) => {
    // log onEvent callbacks from Link
    // https://plaid.com/docs/link/web/#onevent
    //  console.log("on Event", eventName, metadata);
  }, []);
  const onExit = useCallback((error, metadata) => {
    // log onExit callbacks from Link, handle errors
    // https://plaid.com/docs/link/web/#onexit
    // console.log("on Event", error, metadata);
  }, []);

  const config = {
    token,
    onSuccess,
    onEvent,
    onExit,
  };

  const { open, ready, error, exit } = usePlaidLink(config);

  const plaidButtonText = () => {
    if (team?.id) {
      if (ready || team?.currency !== 'USD' || team?.currency !== 'CAD') {
        if (buttonText) {
          return buttonText;
        }
        if (accountPage) {
          return 'Connect Institution';
        } else {
          return 'New Connection';
        }
      } else {
        return 'Loading ...';
      }
    } else {
      return 'Loading ...';
    }
  };

  return (
    <>
      {modalLoader && (
        <div className="min-w-[100vw] max-w-screen min-h-screen max-h-screen flex items-center justify-center fixed left-0 top-0 z-50 bg-black/20">
          <div>
            <svg
              width="29"
              height="29"
              viewBox="0 0 29 29"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="animate-spin w-10 h-10 fill-current shrink-0"
            >
              <path
                d="M14.5 2.41699V7.25033"
                stroke="#E48642"
                stroke-width="2.55882"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M14.5 21.749V26.5824"
                stroke="#E48642"
                stroke-width="2.55882"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M5.95703 5.95703L9.37662 9.37662"
                stroke="#E48642"
                stroke-width="1.58955"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M19.623 19.6211L23.0426 23.0407"
                stroke="#E48642"
                stroke-width="1.58955"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M2.41699 14.5H7.25033"
                stroke="#E48642"
                stroke-width="1.58955"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M21.749 14.5H26.5824"
                stroke="#E48642"
                stroke-width="1.58955"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M5.95703 23.0407L9.37662 19.6211"
                stroke="#E48642"
                stroke-width="1.58955"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M19.623 9.37662L23.0426 5.95703"
                stroke="#E48642"
                stroke-width="1.58955"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
        </div>
      )}
      <ChooseAccountsModal
        isOpen={accounts}
        setIsOpen={setAccounts}
        accessToken={accessToken}
        afterSuccess={afterSuccess}
      />
      <Tooltip
        content={`Live bank feeds for ${team?.country} is currently not supported. Manually import transactions instead.`}
        contentClassName="border-none w-[250px] text-wrap overflow-visible text-sm text-[#667085] relative"
        position="left"
        size="sm"
        tooltipShow={team?.currency !== 'USD' && team?.currency !== 'CAD'}
      >
        {accountPage ? (
          <button
            onClick={() => {
              ready && open();
              cb();
            }}
            disabled={team?.currency !== 'USD' && team?.currency !== 'CAD'}
            ref={buttonRef}
          ></button>
        ) : (
          <button
            className={
              buttonClass
                ? buttonClass
                : !accountPage
                  ? 'btn text-base font-normal !bg-indigo-500 hover:!bg-indigo-600 text-white'
                  : 'h-11 w-[14.531rem] py-2.5 px-[1.875rem] flex items-center justify-center rounded-[0.313rem] shadow-sm bg-indigo-500 text-white'
            }
            onClick={() => {
              ready && open();
              cb();
            }}
            disabled={team?.currency !== 'USD' && team?.currency !== 'CAD'}
            ref={buttonRef}
          >
            {ready && !accountPage && !buttonText && (
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="mr-2"
              >
                <path
                  d="M7.00008 1.16602V12.8327M1.16675 6.99935H12.8334"
                  stroke="white"
                  stroke-width="1.66667"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            )}
            {plaidButtonText()}
          </button>
        )}
      </Tooltip>
    </>
  );
};

export default PlaidLink;
