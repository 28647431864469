import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  accessToken: null,
  refreshToken: null,
  accessType: "",
  localUser: {},
};

const UserSlice = createSlice({
  name: "User",
  initialState,
  reducers: {
    setAccessType(state, action) {
      state.accessType = action.payload;
    },
    setLocalUser(state, action) {
      state.localUser = action.payload;
    },
    setAccessToken(state, action) {
      state.accessToken = action.payload;
    },
    setRefreshToken(state, action) {
      state.refreshToken = action.payload;
    },
  },
});

export const { setAccessType, setLocalUser, setAccessToken, setRefreshToken } =
  UserSlice.actions;

export default UserSlice.reducer;
