import moment from "moment";
import { calculatePercentageDifference } from "./Utils";
import { DateTime } from 'luxon';

export const getLastData = (date, data) => {
  if (date?.split(",")?.[0]) {
    const currentMonth = DateTime.fromFormat(date?.split(",")?.[0], "MMMM").toFormat("M");
    let lastMonth = currentMonth - 1;
    if (lastMonth === 0) {
      lastMonth = 12;
    }
    if (data?.length > 0) {
      for (let i = 0; i < data?.length; i++) {
        if (data?.[i]?.month == lastMonth) {
          return data?.[i];
        }
      }
    }
  } else {
    if (date?.split(",")?.[1]) {
      const year = parseInt(date?.split(",")?.[1]);
      const lastYear = year - 1;
      if (data?.length > 0) {
        for (let i = 0; i < data?.length; i++) {
          if (parseInt(data?.[i]?.year) === lastYear) {
            return data?.[i];
          }
        }
      }
    }
  }
};

export const lastProfitsFunc = (date, data) => {
  if (date?.split(",")?.[0]) {
    const lastIncome = getLastData(date, data?.pastDataByType?.income);
    const lastExpense = getLastData(date, data?.pastDataByType?.expenses);
    let profit = (lastIncome?.total*-1) + lastExpense?.total;
    profit = profit * -1;
    let currentMonth = DateTime.fromFormat(date, "MMMM,yyyy");
    let lastMonth1 =currentMonth.minus({ months: 1 }).toFormat("MMMM,yyyy");

    const lastIncome1 = getLastData(lastMonth1, data?.pastDataByType?.income);
    const lastExpense1 = getLastData(lastMonth1, data?.pastDataByType?.expenses);
    let profit1 = (lastIncome1?.total*-1) + lastExpense1?.total;
    profit1 = profit1 * -1;

    let currentMonth2 = DateTime.fromFormat(lastMonth1, "MMMM,yyyy");
    let lastMonth2 =currentMonth2.minus({ months: 1 }).toFormat("MMMM,yyyy");

    const lastIncome2 = getLastData(lastMonth2, data?.pastDataByType?.income);
    const lastExpense2 = getLastData(lastMonth2, data?.pastDataByType?.expenses);
    let profit2 = (lastIncome2?.total*-1) + lastExpense2?.total;
    profit2 = profit2 * -1;



    let currentMonth3 =DateTime.fromFormat(lastMonth2, "MMMM,yyyy");
    let lastMonth3 =currentMonth3.minus({ months: 1 }).toFormat("MMMM,yyyy");
    const lastIncome3 = getLastData(lastMonth3, data?.pastDataByType?.income);
    const lastExpense3 = getLastData(lastMonth3, data?.pastDataByType?.expenses);
    let profit3 = (lastIncome3?.total*-1) + lastExpense3?.total;
    profit3 = profit3 * -1;



    let currentMonth4 =DateTime.fromFormat(lastMonth3, "MMMM,yyyy");
    let lastMonth4 =currentMonth4.minus({ months: 1 }).toFormat("MMMM,yyyy");
    const lastIncome4 = getLastData(lastMonth4, data?.pastDataByType?.income);
    const lastExpense4 = getLastData(lastMonth4, data?.pastDataByType?.expenses);
    let profit4 = (lastIncome4?.total*-1) + lastExpense4?.total;
    profit4 = profit4 * -1;



    let currentMonth5 = DateTime.fromFormat(lastMonth4, "MMMM,yyyy");
    let lastMonth5 = currentMonth5.minus({ months: 1 }).toFormat("MMMM,yyyy");
    const lastIncome5 = getLastData(lastMonth5, data?.pastDataByType?.income);
    const lastExpense5 = getLastData(lastMonth5, data?.pastDataByType?.expenses);
    let profit5 = (lastIncome5?.total*-1) + lastExpense5?.total;
    profit5 = profit5 * -1;



    const profits = [
      {
        date: currentMonth.minus({ months: 5 }).toFormat("MMMM yyyy"),
        amount: profit4?.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
        percentage:
          profit5 > profit4
            ? -calculatePercentageDifference(profit5, profit4)
            : calculatePercentageDifference(profit5, profit4),
        expense4: lastExpense4,
        income4: lastIncome4,
      },
      {
        date:currentMonth.minus({ months: 4 }).toFormat("MMMM yyyy"),
        amount: profit3?.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
        percentage:
          profit4 > profit3
            ? -calculatePercentageDifference(profit4, profit3)
            : calculatePercentageDifference(profit4, profit3),
        expense3: lastExpense3,
        income3: lastIncome3,
      },
      {
        date: currentMonth.minus({ months: 3 }).toFormat("MMMM yyyy"),
        amount: profit2?.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
        percentage:
          profit3 > profit2
            ? -calculatePercentageDifference(profit3, profit2)
            : calculatePercentageDifference(profit3, profit2),
        expense2: lastExpense2,
        income2: lastIncome2,
      },
      {
        date: currentMonth.minus({ months: 2 }).toFormat("MMMM yyyy"),
        amount: profit1?.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
        percentage:
          profit2 > profit1
            ? -calculatePercentageDifference(profit2, profit1)
            : calculatePercentageDifference(profit2, profit1),
        expense1: lastExpense1,
        income1: lastIncome1,
      },
      {
        date:currentMonth.minus({ months: 1 }).toFormat("MMMM yyyy"),
        amount: profit?.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
        percentage:
          profit1 > profit
            ? -calculatePercentageDifference(profit1, profit)
            : calculatePercentageDifference(profit1, profit),
        expense: lastExpense,
        income: lastIncome,
      },
    ];
    return profits?.reverse();
  } else {
    const lastIncome1 = getLastData(date, data?.pastDataByType?.income);
    const lastExpense1 = getLastData(date, data?.pastDataByType?.expenses);
    let profit1 = (lastIncome1?.total*-1) + lastExpense1?.total;
    profit1 = profit1 * -1;
    let currentYear1 =DateTime.fromFormat(date.replace(",", ""), "yyyy");
    let lastYear1 = currentYear1.minus({ years: 1 }).toFormat(",yyyy");
    const lastIncome2 = getLastData(lastYear1, data?.pastDataByType?.income);
    const lastExpense2 = getLastData(lastYear1, data?.pastDataByType?.expenses);

    let profit2 = (lastIncome2?.total*-1) + lastExpense2?.total;
    profit2 = profit2 * -1;

    let currentYear2 = DateTime.fromFormat(lastYear1.replace(",", ""), "yyyy");
    let lastYear2 = currentYear2.minus({ years: 1 }).toFormat(",yyyy");
    const lastIncome3 = getLastData(lastYear2, data?.pastDataByType?.income);
    const lastExpense3 = getLastData(lastYear2, data?.pastDataByType?.expenses);
    let profit3 = (lastIncome3?.total*-1) + lastExpense3?.total;
    profit3 = profit3 * -1;

    let currentYear3 = DateTime.fromFormat(lastYear2.replace(",", ""), "yyyy");
    let lastYear3 = currentYear3.minus({ years: 1 }).toFormat(",yyyy");
    const lastIncome4 = getLastData(lastYear3, data?.pastDataByType?.income);
    const lastExpense4 = getLastData(lastYear3, data?.pastDataByType?.expenses);
    let profit4 = (lastIncome4?.total*-1) + lastExpense4?.total;
    profit4 = profit4 * -1;

    const profits = [
      {
        date: lastYear2?.replace(",", ""),
        amount: profit2?.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
        percentage:
          profit3 > profit2
            ? -calculatePercentageDifference(profit3, profit2)
            : calculatePercentageDifference(profit3, profit2),
      },
      {
        date: lastYear1?.replace(",", ""),
        amount: profit1?.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
        percentage:
          profit2 > profit1
            ? -calculatePercentageDifference(profit2, profit1)
            : calculatePercentageDifference(profit2, profit1),
      },
    ];
    return profits?.reverse();
  }
};
