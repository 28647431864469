import { applyNodeChanges, applyEdgeChanges, MarkerType } from "reactflow";
import { nanoid } from "nanoid";
import { create } from "zustand";

const calculateYOffset = (height) => {
  if (height > 950) {
    return 350;
  }
  if (height > 900) {
    return 320;
  }
  else if (height > 700) {
    return 220;
  }
   else if (height > 600) {
    return 180;
  } else {
    return 160;
  }
}

export const useStore = create((set, get) => {
  const initialNodes = [
    {
      id: nanoid(),
      position: {
        x: window.innerWidth / 2 - 379 / 2,
        y: window.innerHeight / 2 - 50 / 2 - calculateYOffset(window.innerHeight),
      },
      type: "placeholder",
      data: { name: "Add Trigger" },
    },
  ];

  return {
    nodes: initialNodes,
    edges: [],
    activeNodeId: null,

    setActiveNodeId: (id) => set({ activeNodeId: id }),

    onNodesChange(changes) {
      set({
        nodes: applyNodeChanges(changes, get().nodes),
      });
    },

    onEdgesChange(changes) {
      set({
        edges: applyEdgeChanges(changes, get().edges),
      });
    },

    addEdge(data) {
      const id = nanoid(6);
      const edge = {
        id,
        ...data,
        type: "custom",
        style: { stroke: "#DFE2E7" },
      };

      set({ edges: [edge, ...get().edges] });
    },

    updateNode(id, data) {
      let originalHeight;
      set({
        nodes: get().nodes.map((node) =>
          node.id === id ? { ...node, data: { ...node.data, ...data } } : node
        ),
      });
      const originalNode = get().nodes.find((node) => node.id === id);
      originalHeight = originalNode.height;
      setTimeout(() => {
        const nodes = get().nodes;
        const matchingNodeIndex = nodes.findIndex((node) => node.id === id);
        const matchingNode = nodes[matchingNodeIndex];
        if (
          matchingNode &&
          matchingNode.height !== 78 &&
          originalHeight !== matchingNode.height
        ) {
          const newHeight = matchingNode.height;
          const heightDifference = newHeight - originalHeight;
          if (heightDifference !== 0) {
            const sortedNodes = [...nodes].sort(
              (a, b) => a.position.y - b.position.y
            );

            const updatedSortedNodes = sortedNodes.map((node) => {
              if (node.position.y > matchingNode.position.y) {
                return {
                  ...node,
                  position: {
                    ...node.position,
                    y: node.position.y + heightDifference,
                  },
                };
              }
              return node;
            });

            const updatedNodes = nodes.map((node) => {
              const updatedNode = updatedSortedNodes.find(
                (sortedNode) => sortedNode.id === node.id
              );
              return updatedNode || node;
            });

            set({ nodes: updatedNodes });
          }
        } else if (matchingNode.height === 78) {
          const updatedNodes = nodes.map((node, index) => {
            if (index > matchingNodeIndex) {
              return {
                ...node,
                position: {
                  ...node.position,
                  y: node.position.y - (originalHeight - 78),
                },
              };
            }
            return node;
          });

          set({ nodes: updatedNodes });
        }
      }, 100);
    },

    deleteNode(nodeId) {
      set((state) => {
        const remainingNodes = state.nodes.filter((node) => node.id !== nodeId);
        const deletedNodeIndex = state.nodes.findIndex(
          (node) => node.id === nodeId
        );
        const deletedNode = state.nodes[deletedNodeIndex];

        const updatedNodes = remainingNodes.map((node) => {
          if (node.position.y > deletedNode.position.y) {
            return {
              ...node,
              position: { ...node.position, y: node.position.y - 125 },
            };
          }
          return node;
        });

        const edges = state.edges;
        const incomingEdge = edges.find((edge) => edge.target === nodeId);
        const outgoingEdge = edges.find((edge) => edge.source === nodeId);

        const remainingEdges = edges.filter(
          (edge) => edge.source !== nodeId && edge.target !== nodeId
        );

        if (incomingEdge && outgoingEdge) {
          remainingEdges.push({
            id: nanoid(),
            source: incomingEdge.source,
            target: outgoingEdge.target,
            type: "custom",
            style: { stroke: "#DFE2E7" },
          });
        }

        return {
          ...state,
          nodes: updatedNodes,
          edges: remainingEdges,
          activeNodeId: null,
        };
      });
    },

    async removeNodes(nodes) {
      set({ nodes: initialNodes, edges: [] });
    },

    createNewNode(nodeId, data, callback) {
      set((state) => {
        const nodes = state.nodes.map((node) => {
          if (node.id === nodeId) {
            return {
              ...node,
              type: data.type,
              data: { ...node.data, ...data },
            };
          }
          return node;
        });

        const sourceNode = state.nodes.find((node) => node.id === nodeId);

        const yOffset = 75 + (sourceNode?.height || 50);
        const newNode = {
          id: nanoid(),
          type: "placeholder",
          position: {
            x: sourceNode.position.x,
            y: sourceNode.position.y + yOffset,
          },
          data: { name: "New Component" },
          height: 50,
        };
        if (callback) {
          callback(newNode.id);
        }
        return {
          ...state,
          nodes: [...nodes, newNode],
          edges: [
            ...state.edges,
            {
              id: nanoid(),
              source: nodeId,
              type: "custom",
              target: newNode.id,
              style: { stroke: "#DFE2E7" },
              data: { sourceId: newNode?.id },
            },
          ],
          activeNodeId: newNode.id,
        };
      });
    },

    createNodeBetween(sourceId, targetId, data) {
      let newNodeId;

      set((state) => {
        const sourceNode = state.nodes.find((node) => node.id === sourceId);
        const targetNode = state.nodes.find((node) => node.id === targetId);

        if (!sourceNode || !targetNode) {
          return state;
        }

        const sourceHeight = sourceNode.height || 78;
        const targetHeight = targetNode.height || 78;

        const newPositionY = sourceNode.position.y + sourceHeight + 50;

        const newPosition = {
          x: sourceNode.position.x || targetNode.position.x,
          y: newPositionY,
        };

        const newNode = {
          id: nanoid(),
          type: data.type,
          position: newPosition,
          data: { ...data },
          height: data.height || 78,
        };

        newNodeId = newNode.id;

        const updatedNodes = state.nodes.map((node) => {
          if (node.position.y > sourceNode.position.y) {
            return {
              ...node,
              position: {
                x: node.position.x,
                y: node.position.y + newNode.height + 50,
              },
            };
          }
          return node;
        });

        updatedNodes.push(newNode);

        const filteredEdges = state.edges.filter(
          (edge) => !(edge.source === sourceId && edge.target === targetId)
        );

        const newEdges = [
          ...filteredEdges,
          {
            id: nanoid(),
            source: sourceId,
            target: newNode.id,
            type: "custom",
            style: { stroke: "#DFE2E7" },
          },
          {
            id: nanoid(),
            source: newNode.id,
            target: targetId,
            type: "custom",
            style: { stroke: "#DFE2E7" },
          },
        ];

        return {
          ...state,
          nodes: updatedNodes,
          edges: newEdges,
          activeNodeId: newNode.id,
        };
      });

      return newNodeId;
    },
  };
});
