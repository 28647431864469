import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { getPeople } from '../../API/backend_helper';
import SearchForm from '../../partials/actions/SearchForm';
import PaginationClassic from '../../components/PaginationClassic';

let searchTimeout = null;

const EmployeesPaySettings = ({ gustoId = '' }) => {
  const [employees, setEmployees] = useState([]);
  const [localLimit, setLocalLimit] = useState(50);
  const [searchVal, setSearchVal] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  const loadEmployees = async ({
    limit = localLimit,
    page = employees?.page,
    id = gustoId,
    loading = false,
    search = searchVal || '',
  }) => {
    try {
      if (loading) {
        setIsLoading(true);
      }
      const res = await getPeople({ gustoId: id, page, limit, search });
      setEmployees(res);
    } catch (err) {
      console.log('err', err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (gustoId) {
      loadEmployees({});
    }
  }, [gustoId]);

  const onSearch = (value) => {
    setSearchVal(value);
    clearTimeout(searchTimeout);
    searchTimeout = null;
    searchTimeout = setTimeout(async () => {
      loadEmployees({ search: encodeURIComponent(value || '') });
    }, 900);
  };
  const { page, totalRecords, people, filters } = employees || {};

  return (
    <div className="w-full overflow-x-auto">
      {isLoading ? (
        <div className="flex flex-col gap-2 items-center pb-2 justify-center mt-4">
          <svg
            width="29"
            height="29"
            viewBox="0 0 29 29"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="animate-spin w-10 h-10 fill-current shrink-0"
          >
            <path
              d="M14.5 2.41699V7.25033"
              stroke="#E48642"
              strokeWidth="2.55882"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M14.5 21.749V26.5824"
              stroke="#E48642"
              strokeWidth="2.55882"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M5.95703 5.95703L9.37662 9.37662"
              stroke="#E48642"
              strokeWidth="1.58955"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M19.623 19.6211L23.0426 23.0407"
              stroke="#E48642"
              strokeWidth="1.58955"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M2.41699 14.5H7.25033"
              stroke="#E48642"
              strokeWidth="1.58955"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M21.749 14.5H26.5824"
              stroke="#E48642"
              strokeWidth="1.58955"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M5.95703 23.0407L9.37662 19.6211"
              stroke="#E48642"
              strokeWidth="1.58955"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M19.623 9.37662L23.0426 5.95703"
              stroke="#E48642"
              strokeWidth="1.58955"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <p className="text-slate-600 font-medium text-[0.875rem] text-center">
            Fetching data, please wait...
          </p>
        </div>
      ) : (
        <>
          <SearchForm
            placeholder="Search"
            searchVal={searchVal}
            setSearchVal={onSearch}
            transaction
          />
          <table className="w-full table-fixed">
            <thead className="w-full shadow-md rounded-[0.313rem] text-sm leading-[1.125rem] text-slate-600">
              <tr className="w-full py-[1.438rem] px-[1.375rem] h-16">
                <th className="text-left font-medium px-4">Name</th>
                <th className="text-left font-medium  ">Department</th>
                <th className="text-left font-medium  ">Job Title</th>
                <th className="text-left font-medium  ">Employment Type</th>
                <th className="text-left font-medium  ">Wage</th>
                <th className="text-left font-medium  ">Edit</th>
              </tr>
            </thead>
            <tbody className=" text-sm divide-y divide-gray-300 border-b border-gray-300">
              {people?.length > 0 ? (
                people?.map((employee) => (
                  <tr
                    key={employee?.id}
                    className="w-full px-[1.25rem] h-16 hover:bg-[#a0cd85] hover:bg-opacity-5"
                  >
                    <td className="text-left text-[14px] px-4">
                      {employee?.firstName} {employee?.lastName}
                    </td>
                    <td className="text-left text-[14px] capitalize">
                      {employee?.department?.title}
                    </td>
                    <td className="text-left text-[14px] capitalize">
                      {employee?.position}
                    </td>
                    <td className="text-left text-[14px] capitalize">
                      {employee?.type}
                    </td>
                    <td className="text-left text-[14px] capitalize">
                      {employee?.jobs?.length > 0 &&
                        employee?.jobs?.[employee?.jobs?.length - 1]
                          ?.compensations?.length > 0 && (
                          <span>
                            $
                            {parseFloat(
                              employee?.jobs?.[employee?.jobs?.length - 1]
                                ?.compensations?.[
                                employee?.jobs?.[employee?.jobs?.length - 1]
                                  ?.compensations?.length - 1
                              ]?.rate,
                            )?.toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                            /
                            {
                              employee?.jobs?.[employee?.jobs?.length - 1]
                                ?.compensations?.[
                                employee?.jobs?.[employee?.jobs?.length - 1]
                                  ?.compensations?.length - 1
                              ]?.paymentUnit
                            }
                          </span>
                        )}
                    </td>
                    <td className="text-left text-[14px]">
                      <a
                        href={`https://app.gusto-demo.com/payroll_admin/people/employees/${employee?.providerItemId}`}
                        target="_blank"
                        rel="noreferrer"
                        className="text-indigo-500 underline"
                      >
                        Edit Employee
                      </a>
                    </td>
                  </tr>
                ))
              ) : (
                <tr className="w-full py-[1.438rem] px-[1.375rem] h-16">
                  <td colSpan={6} className="text-center text-[14px] px-4">
                    No Data Found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          {totalRecords > 0 && (
            <div className="mt-10">
              <PaginationClassic
                setLimit={setLocalLimit}
                onPageChange={loadEmployees}
                pagination={{
                  limit: localLimit,
                  page: parseInt(page),
                  totalRecords,
                }}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default EmployeesPaySettings;
