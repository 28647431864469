import React, { useEffect, useMemo, useRef, useState } from 'react';
// import ActionsDropdown from "./ActionsDropdown";
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import moment from 'moment';
import TextInputDash from '../../components/TextInputDash';
import { createProduct } from '../../API/backend_helper';
import useClickOutside from '../../utils/useClickOutside';
import { getCurrencySymbolFromIso, IS_PERSON, VIEWER } from '../../utils/Utils';
import DropdownFull from '../../components/DropdownFull';
import ModalBasic from '../../components/ModalBasic';
import ProductForm from '../products/ProductForm';
import BasicCheckboxDropdown from '../component/BasicCheckboxDropdown';
import DatePickerDash from '../../components/DatePickerDash';
import Tooltip from '../../components/Tooltip';

let searchTimeoutInvoice = null;

// Helper function to round a number to 2 decimal places
const roundToTwo = (num) => Math.round(num * 100) / 100;

const CreateInvoicesTable = ({
  isPreview,
  selectedColor,
  fontFamily,
  products,
  setSelectedProducts,
  selectedProducts,
  discount,
  setDiscount,
  discountDescription,
  setDiscountDescription,
  refreshProducts,
  categories,
  isDiscountAdded,
  setIsDiscountAdded,
  type,
  currencies,
  checkProductRows,
  projects,
  selectedProject,
  setSelectedProject,
  expenseCategories,
  team,
  setSelectedTax,
  addTax,
  getTaxById,
  setTaxes,
  taxes,
  summary,
  setSummary,
  setInvoicePanelOpen,
  attachments,
  setReceiptModalOpen,
  setActiveReceipt,
  invoiceSalesRep,
  setInvoiceSalesRep,
  salesRep,
  tags,
  selectedTags,
  setSelectedTags,
  discountError,
  setDiscountError,
  isRecurring,
  setIsRecurring,
  recurrencePattern,
  setRecurrencePattern,
  occurrenceCount,
  setOccurrenceCount,
  recurrenceEndDate,
  setRecurrenceEndDate,
  RecurrencePatternOptions,
  inAdvanceCreationDays,
  setInAdvanceCreationDays,
  emailCustomer,
  setEmailCustomer,
  endRecurringType,
  setEndRecurringType,
  invoiceDueDate,
  isNewInvoice,
  isDraft,
  discInPercentage,
  setDiscInPercentage,
  percentageValue,
  setPercetageValue,
}) => {
  const IsPerson = localStorage.getItem(IS_PERSON)
    ? JSON.parse(localStorage.getItem(IS_PERSON))
    : null;

  const { accessType } = useSelector((state) => state.User);
  const options = useMemo(
    () => ({
      mode: 'single',
      static: false,
      monthSelectorType: 'static',
      allowInput: true,
      dateFormat:
        team?.defaults?.dateFormat === 'dd/mm/yyyy' ? 'd/m/Y' : 'm/d/Y',
      position: 'bottom',
      prevArrow:
        '<svg class="fill-current" width="7" height="11" viewBox="0 0 7 11"><path d="M5.4 10.8l1.4-1.4-4-4 4-4L5.4 0 0 5.4z" /></svg>',
      nextArrow:
        '<svg class="fill-current" width="7" height="11" viewBox="0 0 7 11"><path d="M1.4 10.8L0 9.4l4-4-4-4L1.4 0l5.4 5.4z" /></svg>',
      onReady: (selectedDates, dateStr, instance) => {
        instance?.calendarContainer?.classList?.add(`flatpickr-right`);
      },
      minDate: moment().toISOString(),
    }),
    [team],
  );

  const showInvoiceTableRef = useRef();
  const [productsFormOpen, setProductsFormOpen] = useState(false);
  const [addProductModalOpen, setAddProductModalOpen] = useState(false);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [changedFirstTime, setChangedFirstTime] = useState([]);

  function handleAmountChange(
    newTotal,
    currentUnitPrice,
    currentQuantity,
    lastChange,
    id,
  ) {
    let updatedUnitPrice;
    let updatedQuantity;
    let updatedTotal;
    updatedTotal = newTotal;
    if (!currentUnitPrice && !currentQuantity) {
      // If neither unitPrice nor quantity is set, set quantity to 1 and unitPrice to the new total
      updatedQuantity = 1;
      updatedUnitPrice = newTotal;
    } else if (currentUnitPrice && !currentQuantity) {
      if (!newTotal) {
        updatedTotal = currentUnitPrice;
        updatedQuantity = 1;
      } else {
        // If unitPrice is set but quantity is not, adjust the quantity
        updatedQuantity = newTotal / currentUnitPrice;
        updatedUnitPrice = currentUnitPrice;
      }
    } else if (!currentUnitPrice && currentQuantity) {
      // If quantity is set but unitPrice is not, adjust the unitPrice
      updatedUnitPrice = newTotal / currentQuantity;
      updatedQuantity = currentQuantity;
    } else if (currentUnitPrice && currentQuantity) {
      if (lastChange === 'total') {
        if (currentQuantity === 1 && false) {
          if (newTotal) {
            clearTimeout(searchTimeoutInvoice);
            searchTimeoutInvoice = null;
            searchTimeoutInvoice = setTimeout(async () => {
              const temp = [...changedFirstTime];
              temp.push(id);
              setChangedFirstTime(temp);
            }, 1500);
            updatedUnitPrice = newTotal;
            updatedQuantity = 1;
          }
        } else {
          updatedQuantity = newTotal / currentUnitPrice;
          updatedUnitPrice = currentUnitPrice;
        }
      } else if (lastChange === 'quantity') {
        updatedQuantity = currentQuantity;
        updatedUnitPrice = currentUnitPrice;
        updatedTotal = currentUnitPrice * currentQuantity;
      } else {
        updatedTotal = currentUnitPrice * currentQuantity;
        updatedUnitPrice = currentUnitPrice;
        updatedQuantity = currentQuantity;
      }
      // eslint-disable-next-line no-dupe-else-if
    } else if (!currentUnitPrice && currentQuantity && newTotal) {
      updatedUnitPrice = newTotal / currentQuantity;
      updatedQuantity = currentQuantity;
    }

    return {
      total: parseFloat(updatedTotal),
      unitPrice: parseFloat(updatedUnitPrice),
      quantity: parseFloat(updatedQuantity),
    };
  }

  const onProductFilter = (value) => {
    if (value) {
      const v = value?.toLowerCase();
      const filtered = products?.filter(
        (p) =>
          p?.name?.toLowerCase()?.includes(v) ||
          p?.description?.toLowerCase()?.includes(v),
      );
      setFilteredProducts(filtered);
    } else {
      setFilteredProducts(products);
    }
  };

  useEffect(() => {
    if (products?.length > 0) {
      setFilteredProducts(products);
    }
  }, [products]);

  const handleUpdateValue = (index, field, value) => {
    const tempRows = [...selectedProducts];
    const tempObj = { ...tempRows[index] };
    tempObj[field] = { ...tempObj[field], value };
    if (value) {
      tempObj[field] = { ...tempObj[field], error: false };
    }
    tempRows[index] = tempObj;
    if (field === 'taxes') {
      if (tempObj.taxIds.value?.includes(value)) {
        const filter = tempObj.taxIds.value?.filter((id) => id !== value);
        tempObj.taxIds.value = filter;
      } else {
        const _taxes = [...tempObj.taxIds.value, value];
        tempObj.taxIds.value = _taxes;
      }
    }
    if (field === 'quantity' || field === 'unitPrice' || field === 'total') {
      const TempArray = ['quantity', 'unitPrice', 'total'];
      const result = handleAmountChange(
        tempObj?.total?.value,
        tempObj?.unitPrice?.value,
        tempObj?.quantity?.value,
        field,
        tempObj?.localId?.value,
      );
      const filtered = TempArray?.filter((a) => a !== field);
      filtered?.forEach((f) => {
        // if (f === "total") {
        //   tempObj[f].value =
        //     result[f] +
        //       result?.unitPrice *
        //       result?.quantity;
        // } else {
        tempObj[f].value = result[f];
        // }
      });
    }
    setSelectedProducts(tempRows);
  };

  const calcSubTotal = () => {
    let sum = 0;
    selectedProducts?.forEach((d) => {
      sum +=
        parseFloat(d.unitPrice.value || 0) * parseFloat(d.quantity.value || 0);
    });
    return sum || 0;
  };

  const getTotalTaxPerItem = (b) => {
    let taxPercentage = 0;
    if (b?.taxIds?.value?.length > 0) {
      b?.taxIds?.value?.forEach((id) => {
        const t = getTaxById(id);
        taxPercentage += parseFloat(t?.percentage ?? 0);
      });
    }
    return taxPercentage;
  };

  const caclTaxPerItem = (b) => {
    const _tax = getTotalTaxPerItem(b);
    if (_tax) {
      const tax = parseFloat(b?.unitPrice?.value || 0) * (_tax / 100);
      return tax;
    }
    return 0;
  };

  const calcTotal = (excludeDiscount = false) => {
    let sum = 0;
    selectedProducts?.forEach((d) => {
      sum +=
        (parseFloat(d.unitPrice.value || 0) +
          parseFloat(caclTaxPerItem(d) || 0)) *
        +d.quantity.value;
    });
    if (!excludeDiscount) {
      if (discount) {
        sum -= discount;
      }
    }

    return sum || 0;
  };

  const calcTax = () => {
    let sum = 0;
    selectedProducts?.forEach((d) => {
      sum += parseFloat(caclTaxPerItem(d) || 0) * +d.quantity.value;
    });
    return sum || 0;
  };

  useClickOutside(showInvoiceTableRef, () => {
    if (productsFormOpen) {
      setProductsFormOpen(false);
    }
  });

  const currency = useMemo(
    () =>
      selectedProducts?.[0]?.currency?.value &&
      currencies?.find(
        (c) => c.isoCode === selectedProducts?.[0]?.currency?.value,
      ),
    [currencies, selectedProducts?.[0]?.currency?.value],
  );

  const returnTaxPercentage = (taxes) => {
    let taxPercentage = 0;
    if (taxes?.length > 0) {
      taxes?.forEach((t) => {
        taxPercentage += parseFloat(t?.percentage ?? 0);
      });
    }
    return taxPercentage;
  };

  const handleAddProduct = async (data) => {
    try {
      const response = await createProduct(data);
      refreshProducts();
      return response;
    } catch (err) {
      console.log('err', err);
    }
  };

  const onAddProduct = (data) => {
    if (
      selectedProducts?.length > 0 &&
      selectedProducts?.[0]?.currency?.value !== data?.productService?.currency
    ) {
      toast.error(
        `Oops! You cannot select products with different currencies in an ${type}`,
      );
    } else {
      let taxPercentage = 0;
      if (data?.productService?.taxes?.length > 0) {
        data?.productService?.taxes?.forEach((t) => {
          taxPercentage += parseFloat(t?.percentage ?? 0);
        });
      }
      const taxIds = [];
      if (data?.productService?.taxes?.length > 0) {
        data?.productService?.taxes?.forEach((t) => {
          taxIds?.push(t?.id);
          taxPercentage += parseFloat(t?.percentage ?? 0);
        });
      }
      setSelectedProducts([
        ...selectedProducts,
        {
          id: { value: data?.productService?.id },
          name: { value: data?.productService?.name },
          stockQuantity: data?.productService?.stockQuantity,
          pricingMethod: data?.productService?.pricingMethod,
          description: {
            value: data?.productService?.description || '',
            error: false,
          },
          // categoryId: { value: null, error: false },
          quantity: {
            value: 1,
            error: false,
          },
          unitPrice: {
            value: data?.productService?.unitPrice || 0,
            error: false,
          },
          taxPercentage: {
            value: taxPercentage || 0,
            error: false,
          },
          currency: {
            value: data?.productService?.currency,
            error: false,
          },
          total: {
            value: parseFloat(data?.productService?.unitPrice || 0).toFixed(2),
            error: false,
          },
          localId: {
            value: new Date().getTime().toString(),
            error: false,
          },
          taxIds: {
            value: taxIds,
            error: false,
          },
        },
      ]);
    }
  };

  const calcInvoicesByEndDate = () => {
    let interval = '';
    if (recurrencePattern === 'daily') {
      interval = 'days';
    } else if (recurrencePattern === 'weekly') {
      interval = 'weeks';
    } else if (recurrencePattern === 'monthly') {
      interval = 'months';
    } else if (recurrencePattern === 'yearly') {
      interval = 'years';
    }
    const count = moment(recurrenceEndDate).diff(
      moment(invoiceDueDate),
      interval,
    );
    return count;
  };

  return (
    <>
      <ModalBasic
        modalOpen={addProductModalOpen}
        setModalOpen={setAddProductModalOpen}
        title="Create Product or Service"
        width="!w-[1088px]"
      >
        <ProductForm
          setFormOpen={setAddProductModalOpen}
          setTitle={() => {}}
          currencies={currencies}
          taxes={taxes}
          _setTaxes={setTaxes}
          incomeCategories={categories}
          expenseCategories={expenseCategories}
          handleAddProduct={handleAddProduct}
          isModalView
          team={team}
          onAddProduct={onAddProduct}
          isInvoices
        />
      </ModalBasic>

      <div className="bg-transparent  w-full rounded-[5px] dark:bg-transparent flex-1 ">
        {/* Table  for Complete Form */}
        <div
          className="overflow-x-auto h-full w-full scrollbar"
          id="invoiceCreateTable"
        >
          <table className="table-auto w-full">
            {/* Table header */}
            <thead
              className={`text-sm font-semibold text-[#667085]  bg-[#A0CD850F] ${
                fontFamily && isPreview ? fontFamily : ''
              }`}
              style={
                isPreview && selectedColor
                  ? {
                      color: selectedColor,
                      background: `${selectedColor}40`,
                      fontFamily: fontFamily || '',
                    }
                  : {}
              }
            >
              <tr>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap min-w-[200px] max-w-[300px] xl:max-w-[400px] w-fit lg:w-[300px] xl:w-[400px]">
                  <div className="font-medium text-left">Items</div>
                </th>
                <th
                  className={`px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap ${
                    isPreview ? 'max-w-32 w-fit lg:w-32' : ''
                  }`}
                >
                  <div className="font-medium text-left">Tax %</div>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <div className="font-medium text-left">Description</div>
                </th>
                <th
                  className={`px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap ${
                    isPreview ? 'max-w-32 w-fit lg:w-32' : ''
                  }`}
                >
                  <div className="font-medium text-left">Qty</div>
                </th>
                <th
                  className={`px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap ${
                    isPreview ? 'max-w-32 w-fit lg:w-32' : ''
                  }`}
                >
                  <div className="font-medium text-left">Price (per unit)</div>
                </th>
                {/* <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap sm:table-cell hidden ">
                  <div className="font-medium text-left">Tax (per unit)</div>
                </th> */}
                <th
                  className={`pr-10  pl-2 first:pl-5 py-3 whitespace-nowrap ${
                    isPreview ? 'max-w-32 w-fit lg:w-32' : ''
                  }`}
                >
                  <div
                    className={`font-medium  pl-2 ${
                      isPreview ? 'text-right' : 'text-left'
                    }`}
                  >
                    Amount
                  </div>
                </th>

                {!isPreview && (
                  <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap  " />
                )}
              </tr>
            </thead>
            {/* Table body */}
            <tbody className="text-sm divide-y divide-[#D0D5DD] border-b border-[#D0D5DD] ">
              {selectedProducts?.map?.((b, i) => (
                <tr key={i} className="">
                  <td className="px-2 first:pl-5 last:pr-5 py-2.5 text-left">
                    <span
                      style={
                        isPreview && fontFamily
                          ? { fontFamily: fontFamily || '' }
                          : {}
                      }
                      className={!isPreview ? ' block pb-[22px]' : ''}
                    >
                      {b?.name?.value}
                    </span>
                  </td>
                  <td className="px-2 first:pl-5 last:pr-5 py-2.5 text-left">
                    <div
                      className={`flex gap-2 items-center w-fit ${
                        !isPreview ? 'mb-[22px]' : ''
                      }`}
                    >
                      {!isPreview && (
                        <BasicCheckboxDropdown
                          selectedOptions={b?.taxIds?.value}
                          setSelectedOptions={setSelectedTax}
                          onApply={(tax) => handleUpdateValue(i, 'taxes', tax)}
                          options={
                            taxes?.map((t) => ({
                              label: `${t?.name} (${t?.percentage}%)`,
                              value: t?.id,
                            })) || []
                          }
                          id="taxes"
                          label="No Taxes"
                          type="tag"
                          flyout
                          width="w-[200px]"
                          height="h-[38px]"
                          addOption={addTax}
                          isAddOption={!IsPerson}
                          addOptionText="+ Add A New Tax"
                          bottomOfTable
                          isTableDropdown
                          ids={['invoiceCreateTable']}
                        />
                      )}

                      <span
                        style={
                          isPreview && fontFamily
                            ? { fontFamily: fontFamily || '' }
                            : {}
                        }
                        className={!isPreview ? 'text-nowrap block' : ''}
                      >
                        {`${!isPreview ? '(' : ''} ${getTotalTaxPerItem(b)}% ${
                          !isPreview ? ')' : ''
                        }`}
                      </span>
                    </div>
                  </td>
                  <td
                    className={`px-2 first:pl-5 last:pr-5 py-2.5 text-left ${
                      isPreview ? 'max-w-[170px]' : ''
                    } `}
                  >
                    {!isPreview ? (
                      <input
                        type="text"
                        placeholder="Description"
                        value={b.description?.value}
                        className={`form-input mb-[22px]  ${
                          b.description.error ? '!border-rose-500' : ''
                        }`}
                        onChange={(e) =>
                          handleUpdateValue(i, 'description', e.target.value)
                        }
                      />
                    ) : (
                      <span
                        style={
                          isPreview && fontFamily
                            ? { fontFamily: fontFamily || '' }
                            : {}
                        }
                      >
                        {b.description?.value}
                      </span>
                    )}
                  </td>
                  <td className="px-2 first:pl-5 last:pr-5 py-2.5 text-left">
                    {!isPreview ? (
                      <div className="relative flex flex-col w-full min-h-[60px]">
                        <input
                          type="number"
                          value={b?.quantity?.value}
                          className={`form-input min-w-full  pr-9 ${
                            b?.quantity?.error
                              ? b?.quantity?.error?.includes?.('Overselling')
                                ? '!border-[#B54708]'
                                : '!border-rose-500'
                              : ''
                          }
                           [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none `}
                          min={0}
                          onChange={(e) =>
                            handleUpdateValue(i, 'quantity', e.target.value)
                          }
                          placeholder="0"
                          onBlur={checkProductRows}
                          style={{
                            paddingRight: `${`${b?.stockQuantity}`?.length * 12 + 12}px`,
                            width: `${`${b?.stockQuantity}`?.length * 12 + 96}px`,
                          }}
                        />
                        {b?.stockQuantity && (
                          <span className="absolute text-base text-slate-800 top-[7px] text-nowrap right-3">
                            {' '}
                            / {b?.stockQuantity}
                          </span>
                        )}
                        <span
                          className={`text-xs mt-1 h-[10px] ${b?.quantity?.error?.includes?.('Overselling') ? 'text-[#B54708]' : 'text-rose-500'}`}
                        >
                          {b?.quantity?.error || ''}
                        </span>
                      </div>
                    ) : (
                      <span
                        style={
                          isPreview && fontFamily
                            ? { fontFamily: fontFamily || '' }
                            : {}
                        }
                      >
                        {b?.quantity?.value || 0}
                      </span>
                    )}
                  </td>
                  <td className="px-2 first:pl-5 last:pr-5 py-2.5">
                    {!isPreview ? (
                      <div className="min-h-[60px] flex flex-col items-start">
                        <div className="relative">
                          <input
                            type="number"
                            value={b?.unitPrice?.value}
                            className={`form-input w-[8.5rem] pl-8 ${
                              b?.unitPrice?.error ? '!border-rose-500' : ''
                            }`}
                            min={0}
                            onChange={(e) =>
                              handleUpdateValue(i, 'unitPrice', e.target.value)
                            }
                            onBlur={checkProductRows}
                            placeholder="0.00"
                          />
                          <span className="absolute top-[50%] -translate-y-[50%] left-3 flex items-center gap-2 font-medium">
                            {currency?.symbol}
                            {/* <svg
                        width="9"
                        height="15"
                        viewBox="0 0 9 15"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M4.64 13.326C3.82933 13.326 3.12 13.166 2.512 12.846C1.91467 12.526 1.38133 12.062 0.912 11.454L1.92 10.446C2.26133 10.926 2.656 11.3047 3.104 11.582C3.552 11.8593 4.08 11.998 4.688 11.998C5.33867 11.998 5.86133 11.8487 6.256 11.55C6.66133 11.2513 6.864 10.8353 6.864 10.302C6.864 9.88602 6.768 9.55002 6.576 9.29402C6.384 9.03802 6.128 8.83002 5.808 8.67002C5.488 8.49935 5.136 8.35002 4.752 8.22202C4.368 8.08335 3.984 7.93935 3.6 7.79002C3.22667 7.63002 2.88 7.43802 2.56 7.21402C2.24 6.97935 1.984 6.67535 1.792 6.30202C1.6 5.92868 1.504 5.45935 1.504 4.89402C1.504 4.26468 1.65333 3.73135 1.952 3.29401C2.26133 2.84601 2.67733 2.50468 3.2 2.27001C3.72267 2.02468 4.30933 1.90201 4.96 1.90201C5.632 1.90201 6.24533 2.04601 6.8 2.33401C7.35467 2.61135 7.81333 2.97935 8.176 3.43801L7.168 4.44602C6.83733 4.06202 6.49067 3.76335 6.128 3.55002C5.76533 3.33668 5.36533 3.23001 4.928 3.23001C4.30933 3.23001 3.824 3.36335 3.472 3.63002C3.12 3.89668 2.944 4.28602 2.944 4.79802C2.944 5.17135 3.04 5.47535 3.232 5.71002C3.43467 5.93402 3.696 6.12602 4.016 6.28602C4.34667 6.44602 4.69867 6.59535 5.072 6.73402C5.456 6.86202 5.84 7.00602 6.224 7.16602C6.61867 7.32602 6.976 7.52868 7.296 7.77402C7.616 8.01935 7.872 8.33935 8.064 8.73402C8.26667 9.11802 8.368 9.60335 8.368 10.19C8.368 11.1713 8.032 11.9393 7.36 12.494C6.688 13.0487 5.78133 13.326 4.64 13.326ZM4.416 14.766V0.462015H5.472V14.766H4.416Z"
                          fill="#667085"
                        />
                      </svg> */}
                            <div className="w-[0.5px] h-[1.188rem] bg-slate-600" />
                          </span>
                        </div>

                        {b?.unitPrice?.error && (
                          <span className="text-xs mt-1 text-rose-500">
                            {b?.unitPrice?.error || ''}
                          </span>
                        )}
                      </div>
                    ) : (
                      <span
                        style={
                          isPreview && fontFamily
                            ? { fontFamily: fontFamily || '' }
                            : {}
                        }
                      >
                        {`${getCurrencySymbolFromIso(
                          selectedProducts?.[0]?.currency?.value,
                          currencies,
                        )}${b?.unitPrice?.value || 0}`}
                      </span>
                    )}
                  </td>
                  {/* <td className="px-2 first:pl-5 relative last:pr-5 py-2.5 text-left">
                    <span
                      style={
                        isPreview && fontFamily
                          ? { fontFamily: fontFamily || "" }
                          : {}
                      }
                    >
                      {getCurrencySymbolFromIso(b?.currency?.value, currencies)}
                      {caclTaxPerItem(b)?.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </span>

                    {!isPreview && (
                      <span className="absolute top-[50%] -translate-y-[50%] left-4 flex items-center gap-2">
                        <svg
                          width="9"
                          height="15"
                          viewBox="0 0 9 15"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M4.64 13.326C3.82933 13.326 3.12 13.166 2.512 12.846C1.91467 12.526 1.38133 12.062 0.912 11.454L1.92 10.446C2.26133 10.926 2.656 11.3047 3.104 11.582C3.552 11.8593 4.08 11.998 4.688 11.998C5.33867 11.998 5.86133 11.8487 6.256 11.55C6.66133 11.2513 6.864 10.8353 6.864 10.302C6.864 9.88602 6.768 9.55002 6.576 9.29402C6.384 9.03802 6.128 8.83002 5.808 8.67002C5.488 8.49935 5.136 8.35002 4.752 8.22202C4.368 8.08335 3.984 7.93935 3.6 7.79002C3.22667 7.63002 2.88 7.43802 2.56 7.21402C2.24 6.97935 1.984 6.67535 1.792 6.30202C1.6 5.92868 1.504 5.45935 1.504 4.89402C1.504 4.26468 1.65333 3.73135 1.952 3.29401C2.26133 2.84601 2.67733 2.50468 3.2 2.27001C3.72267 2.02468 4.30933 1.90201 4.96 1.90201C5.632 1.90201 6.24533 2.04601 6.8 2.33401C7.35467 2.61135 7.81333 2.97935 8.176 3.43801L7.168 4.44602C6.83733 4.06202 6.49067 3.76335 6.128 3.55002C5.76533 3.33668 5.36533 3.23001 4.928 3.23001C4.30933 3.23001 3.824 3.36335 3.472 3.63002C3.12 3.89668 2.944 4.28602 2.944 4.79802C2.944 5.17135 3.04 5.47535 3.232 5.71002C3.43467 5.93402 3.696 6.12602 4.016 6.28602C4.34667 6.44602 4.69867 6.59535 5.072 6.73402C5.456 6.86202 5.84 7.00602 6.224 7.16602C6.61867 7.32602 6.976 7.52868 7.296 7.77402C7.616 8.01935 7.872 8.33935 8.064 8.73402C8.26667 9.11802 8.368 9.60335 8.368 10.19C8.368 11.1713 8.032 11.9393 7.36 12.494C6.688 13.0487 5.78133 13.326 4.64 13.326ZM4.416 14.766V0.462015H5.472V14.766H4.416Z"
                            fill="#667085"
                          />
                        </svg>
                        <div className="w-[0.5px] h-[1.188rem] bg-slate-600"></div>
                      </span>
                    )}
                  </td> */}

                  <td className="px-2 pl-2 first:pl-5 last:pr-5 py-2.5 text-left">
                    {!isPreview ? (
                      <div className="min-h-[60px] flex flex-col items-start">
                        <div className="relative">
                          <input
                            type="number"
                            value={b?.total?.value}
                            className={`form-input w-[8.5rem] pl-8 ${
                              b?.total?.error ? '!border-rose-500' : ''
                            }`}
                            min={0}
                            onChange={(e) =>
                              handleUpdateValue(i, 'total', e.target.value)
                            }
                            onBlur={checkProductRows}
                            placeholder="0.00"
                          />
                          <span className="absolute top-[50%] -translate-y-[50%] left-3 flex items-center gap-2 font-medium">
                            {currency?.symbol}
                            {/* <svg
                        width="9"
                        height="15"
                        viewBox="0 0 9 15"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M4.64 13.326C3.82933 13.326 3.12 13.166 2.512 12.846C1.91467 12.526 1.38133 12.062 0.912 11.454L1.92 10.446C2.26133 10.926 2.656 11.3047 3.104 11.582C3.552 11.8593 4.08 11.998 4.688 11.998C5.33867 11.998 5.86133 11.8487 6.256 11.55C6.66133 11.2513 6.864 10.8353 6.864 10.302C6.864 9.88602 6.768 9.55002 6.576 9.29402C6.384 9.03802 6.128 8.83002 5.808 8.67002C5.488 8.49935 5.136 8.35002 4.752 8.22202C4.368 8.08335 3.984 7.93935 3.6 7.79002C3.22667 7.63002 2.88 7.43802 2.56 7.21402C2.24 6.97935 1.984 6.67535 1.792 6.30202C1.6 5.92868 1.504 5.45935 1.504 4.89402C1.504 4.26468 1.65333 3.73135 1.952 3.29401C2.26133 2.84601 2.67733 2.50468 3.2 2.27001C3.72267 2.02468 4.30933 1.90201 4.96 1.90201C5.632 1.90201 6.24533 2.04601 6.8 2.33401C7.35467 2.61135 7.81333 2.97935 8.176 3.43801L7.168 4.44602C6.83733 4.06202 6.49067 3.76335 6.128 3.55002C5.76533 3.33668 5.36533 3.23001 4.928 3.23001C4.30933 3.23001 3.824 3.36335 3.472 3.63002C3.12 3.89668 2.944 4.28602 2.944 4.79802C2.944 5.17135 3.04 5.47535 3.232 5.71002C3.43467 5.93402 3.696 6.12602 4.016 6.28602C4.34667 6.44602 4.69867 6.59535 5.072 6.73402C5.456 6.86202 5.84 7.00602 6.224 7.16602C6.61867 7.32602 6.976 7.52868 7.296 7.77402C7.616 8.01935 7.872 8.33935 8.064 8.73402C8.26667 9.11802 8.368 9.60335 8.368 10.19C8.368 11.1713 8.032 11.9393 7.36 12.494C6.688 13.0487 5.78133 13.326 4.64 13.326ZM4.416 14.766V0.462015H5.472V14.766H4.416Z"
                          fill="#667085"
                        />
                      </svg> */}
                            <div className="w-[0.5px] h-[1.188rem] bg-slate-600" />
                          </span>
                        </div>
                        <span className="text-xs mt-1 text-rose-500 h-[10px]">
                          {b?.total?.error || ''}
                        </span>
                      </div>
                    ) : (
                      <div className="text-right">
                        <div
                          className="min-w-[4rem] w-fit ml-auto text-left"
                          style={
                            isPreview && fontFamily
                              ? { fontFamily: fontFamily || '' }
                              : {}
                          }
                        >
                          {`${getCurrencySymbolFromIso(
                            selectedProducts?.[0]?.currency?.value,
                            currencies,
                          )}${b?.total?.value || 0}`}
                        </div>
                      </div>
                    )}
                  </td>

                  {!isPreview && (
                    <td className="px-2 first:pl-5 last:pr-5 py-2.5 text-center">
                      <button
                        onClick={() =>
                          setSelectedProducts((prev) =>
                            prev.filter((d, index) => index !== i),
                          )
                        }
                        className="border-[#D0D5DD99] border mb-[22px] rounded-[5px] h-[26px] w-[26px] shadow-sm inline-flex justify-center items-center"
                        type="button"
                      >
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M13.6059 3.87474C11.4483 3.66093 9.27778 3.55078 7.11373 3.55078C5.83086 3.55078 4.54798 3.61557 3.26511 3.74516L1.94336 3.87474"
                            stroke="#E48642"
                            strokeWidth="1.3"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M5.50781 3.22021L5.65035 2.37144C5.75402 1.75592 5.83177 1.2959 6.92675 1.2959H8.62429C9.71927 1.2959 9.8035 1.78184 9.90069 2.37792L10.0432 3.22021"
                            stroke="#E48642"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M12.2124 5.92188L11.7913 12.4464C11.72 13.4636 11.6617 14.2541 9.85398 14.2541H5.69435C3.88667 14.2541 3.82835 13.4636 3.75708 12.4464L3.33594 5.92188"
                            stroke="#E48642"
                            strokeWidth="1.3"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M6.69336 10.6904H8.85092"
                            stroke="#E48642"
                            strokeWidth="1.3"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M6.15625 8.09863H9.39583"
                            stroke="#E48642"
                            strokeWidth="1.3"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </button>
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
          {/* {isLineItemsError && (
            <p className="text-rose-500 text-center font-semibold mb-4">
              {isLineItemsError
                ? selectedProducts?.length === 0
                  ? "At least one Line Item is required."
                  : "Please Fill Line Items Properly"
                : ""}
            </p>
          )}
          {!selectedProducts?.length > 0 && (
            <h6 className="text-center mt-4">Please Add a New Line.</h6>
          )} */}
        </div>

        {/* Table for Last Two Rows */}
        <div className=" h-full w-full overflow-auto max-w-full scrollbar">
          <table className="table-auto w-full">
            {/* Table body */}
            <tbody className="text-sm divide-y divide-[#D0D5DD] ">
              {!isPreview && (
                <tr>
                  <td
                    colSpan={6}
                    className="px-2 first:pl-5 last:pr-5 py-5 text-left "
                  >
                    {!productsFormOpen && (
                      <button
                        type="button"
                        className="btn h-9 px-[14px] gap-2 text-indigo-500 text-base border border-indigo-500 cursor-pointer"
                        onClick={() => {
                          onProductFilter('');
                          setProductsFormOpen(true);
                        }}
                      >
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M8.00004 14.6673C11.6667 14.6673 14.6667 11.6673 14.6667 8.00065C14.6667 4.33398 11.6667 1.33398 8.00004 1.33398C4.33337 1.33398 1.33337 4.33398 1.33337 8.00065C1.33337 11.6673 4.33337 14.6673 8.00004 14.6673Z"
                            stroke="#E48642"
                            strokeWidth="1.2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M5.33337 8H10.6667"
                            stroke="#E48642"
                            strokeWidth="1.2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M8 10.6663V5.33301"
                            stroke="#E48642"
                            strokeWidth="1.2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                        Add products & services
                      </button>
                    )}
                    {productsFormOpen && (
                      <div
                        className="pt-3 border border-dashed border-indigo-500 rounded-[10px]"
                        ref={showInvoiceTableRef}
                      >
                        <div className="px-4 relative  mb-1 w-full">
                          <input
                            type="text"
                            placeholder="Search..."
                            className={`form-input text-indigo-600  placeholder:!text-indigo-600 !border-indigo-600  my-2 pl-8 w-full inline-block  `}
                            autoFocus
                            onChange={(e) => onProductFilter(e.target.value)}
                            onClick={(e) => e.stopPropagation()}
                          />
                          <svg
                            className="absolute left-5 top-[50%] -translate-y-[50%] pl-2 w-6 h-6"
                            width="16"
                            height="17"
                            viewBox="0 0 16 17"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M14 14.5L11.6667 12.1667M13.3333 8.16667C13.3333 11.2963 10.7963 13.8333 7.66667 13.8333C4.53705 13.8333 2 11.2963 2 8.16667C2 5.03705 4.53705 2.5 7.66667 2.5C10.7963 2.5 13.3333 5.03705 13.3333 8.16667Z"
                              stroke="#E48642"
                              strokeWidth="1.2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </div>
                        <div className="divide-y divide-[#D0D5DD] max-h-[200px] overflow-auto scrollbar">
                          {filteredProducts?.length > 0 ? (
                            filteredProducts?.map?.(
                              (p, index) =>
                                !p?.isDeleted &&
                                p?.categoryAccountId && (
                                  <div
                                    key={index}
                                    onClick={() => {
                                      if (!p?.categoryAccountId) {
                                        toast.error(
                                          'Invoice product must have a category',
                                        );
                                        return;
                                      }
                                      if (selectedProducts?.length > 0) {
                                        if (
                                          selectedProducts?.[
                                            selectedProducts?.length - 1
                                          ]?.currency?.value !== p?.currency
                                        ) {
                                          toast.error(
                                            `Oops! You cannot select products with different currencies in an ${type}`,
                                          );
                                          return;
                                        }
                                      }
                                      let taxPercentage = 0;
                                      const taxIds = [];
                                      if (p?.taxes?.length > 0) {
                                        p?.taxes?.forEach((t) => {
                                          taxIds?.push(t?.id);
                                          taxPercentage += parseFloat(
                                            t?.percentage ?? 0,
                                          );
                                        });
                                      }
                                      setSelectedProducts([
                                        ...selectedProducts,
                                        {
                                          id: { value: p?.id },
                                          stockQuantity: p?.stockQuantity,
                                          pricingMethod: p?.pricingMethod,
                                          name: { value: p?.name },
                                          description: {
                                            value: p?.description,
                                            error: false,
                                          },
                                          // categoryId: { value: null, error: false },
                                          quantity: {
                                            value: 1,
                                            error: false,
                                          },
                                          unitPrice: {
                                            value: p?.unitPrice,
                                            error: false,
                                          },
                                          taxPercentage: {
                                            value: taxPercentage,
                                            error: false,
                                          },
                                          currency: {
                                            value: p?.currency,
                                            error: false,
                                          },
                                          total: {
                                            value: parseFloat(
                                              p?.unitPrice,
                                            ).toFixed(2),
                                            error: false,
                                          },
                                          localId: {
                                            value: new Date()
                                              .getTime()
                                              .toString(),
                                            error: false,
                                          },
                                          taxIds: {
                                            value: taxIds,
                                            error: false,
                                          },
                                        },
                                      ]);
                                      setProductsFormOpen(false);
                                    }}
                                    className="py-2 px-4 cursor-pointer"
                                    role="button"
                                    tabIndex={0}
                                  >
                                    <h3 className="mb-1 text-base font-medium text-[#667085] leading-tight">
                                      {p?.name}{' '}
                                      <span className="text-xs font-medium">
                                        (
                                        {getCurrencySymbolFromIso(
                                          p?.currency,
                                          currencies,
                                        )}
                                        {p?.unitPrice} per{' '}
                                        <span className="first:capitalize">
                                          {p?.pricingMethod}
                                        </span>{' '}
                                        | {returnTaxPercentage(p?.taxes)}% Tax |
                                        Stock{' '}
                                        {p?.stockQuantity !== null
                                          ? p?.stockQuantity
                                          : '--'}
                                        )
                                      </span>
                                    </h3>
                                    <p className="text-base  text-[#667085CC] leading-tight">
                                      {p?.description || ''}
                                    </p>
                                  </div>
                                ),
                            )
                          ) : (
                            <div className="py-2 px-4 cursor-pointer">
                              <h3 className="mb-1 text-base font-medium text-[#667085] leading-tight">
                                No Data Found
                              </h3>
                              <p className="text-base  text-[#667085CC] leading-tight" />
                            </div>
                          )}
                        </div>
                        {!IsPerson && (
                          <button
                            type="button"
                            className="btn h-9 w-full px-[14px] gap-2 text-indigo-500 text-base border-t border-x-0 border-b-0 border-[#D0D5DD] cursor-pointer"
                            onClick={() => {
                              setAddProductModalOpen(true);
                            }}
                          >
                            <svg
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              className="h-6 w-6"
                            >
                              <path
                                d="M8.00004 14.6673C11.6667 14.6673 14.6667 11.6673 14.6667 8.00065C14.6667 4.33398 11.6667 1.33398 8.00004 1.33398C4.33337 1.33398 1.33337 4.33398 1.33337 8.00065C1.33337 11.6673 4.33337 14.6673 8.00004 14.6673Z"
                                stroke="#E48642"
                                strokeWidth="1.2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M5.33337 8H10.6667"
                                stroke="#E48642"
                                strokeWidth="1.2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M8 10.6663V5.33301"
                                stroke="#E48642"
                                strokeWidth="1.2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                            Create a New Product or Service
                          </button>
                        )}
                      </div>
                    )}
                  </td>
                </tr>
              )}
              <tr>
                <td colSpan={6} className="px-5 py-4 text-left">
                  <div className="flex justify-between gap-6 ">
                    {/* left Content  */}
                    {!isPreview && (
                      <div className="flex flex-col gap-2.5 flex-1 ">
                        <div className="flex flex-col  gap-1 ">
                          <label className="text-sm font-medium text-[#667085] text-nowrap">
                            Memo
                          </label>
                          <textarea
                            placeholder="Memo"
                            className={`form-textarea  ${
                              isDiscountAdded ? 'w-full' : 'w-2/3'
                            } `}
                            rows={3}
                            cols={40}
                            maxLength={1000}
                            value={summary}
                            onChange={(e) => setSummary(e.target.value)}
                            style={
                              fontFamily ? { fontFamily: fontFamily || '' } : {}
                            }
                          />
                          {summary?.length >= 1000 && (
                            <p className="text-rose-500">
                              Maximum 1000 characters are allowed
                            </p>
                          )}
                        </div>
                        <div className="mt-2 ">
                          <p className="text-sm font-medium text-nowrap">
                            Attachments
                          </p>
                          {attachments?.map((attachment, index) => (
                            <p
                              key={index}
                              className="text-sm mt-1 break-all w-fit text-nowrap text-[#667085] cursor-pointer hover:underline"
                              onClick={() => {
                                setActiveReceipt(attachment);
                                setReceiptModalOpen(true);
                              }}
                            >
                              {attachment?.title || attachment?.name}
                            </p>
                          ))}
                        </div>
                        <div
                          onClick={() => setInvoicePanelOpen('attachments')}
                          className="btn w-fit h-9 px-[14px] gap-2 text-indigo-500 text-base border border-indigo-500 cursor-pointer"
                          role="button"
                          tabIndex="0"
                        >
                          Add Attachments
                        </div>
                      </div>
                    )}
                    {isPreview && (
                      <div className="flex-1">
                        <div
                          className="flex flex-col items-start max-w-[90%]  gap-1"
                          style={
                            fontFamily ? { fontFamily: fontFamily || '' } : {}
                          }
                        >
                          {summary && (
                            <label
                              className={` text-sm font-medium text-[#667085] text-nowrap`}
                              style={{
                                color: selectedColor || '',
                              }}
                            >
                              Memo
                            </label>
                          )}
                          <p
                            style={
                              isPreview && fontFamily
                                ? { fontFamily: fontFamily || '' }
                                : {}
                            }
                            className=" text-sm text-[#667085]"
                            dangerouslySetInnerHTML={{
                              __html: summary?.replace(/\n/g, '<br>'),
                            }}
                          />
                        </div>
                        {attachments?.length > 0 && (
                          <div
                            style={
                              fontFamily
                                ? {
                                    fontFamily: fontFamily || '',
                                    color: selectedColor || '',
                                  }
                                : {}
                            }
                            className="mt-2"
                          >
                            <p
                              className="text-sm font-medium text-nowrap mb-1"
                              style={{
                                color: selectedColor || '',
                              }}
                            >
                              Attachments
                            </p>
                            {attachments.map((attachment, index) => (
                              <p
                                key={index}
                                className="text-sm w-fit max-w-[90%] break-all text-[#667085] cursor-pointer hover:underline"
                                onClick={() => {
                                  setActiveReceipt(attachment);
                                  setReceiptModalOpen(true);
                                }}
                              >
                                {attachment?.title}
                              </p>
                            ))}
                          </div>
                        )}
                      </div>
                    )}
                    {/* Right Content  */}
                    <div className="flex  gap-4">
                      {selectedProducts?.length > 0 && (
                        <div className="flex flex-col items-end gap-2 text-[#667085]">
                          <span
                            style={
                              isPreview && fontFamily
                                ? { fontFamily: fontFamily || '' }
                                : {}
                            }
                          >
                            Subtotal:
                          </span>
                          <span
                            style={
                              isPreview && fontFamily
                                ? { fontFamily: fontFamily || '' }
                                : {}
                            }
                          >
                            Tax:
                          </span>

                          {!isPreview ? (
                            !isDiscountAdded ? (
                              <span
                                onClick={() => setIsDiscountAdded(true)}
                                className="text-indigo-500 underline cursor-pointer"
                                role="button"
                                tabIndex="0"
                              >
                                Add a discount
                              </span>
                            ) : (
                              <div className="flex gap-4 w-full">
                                <label className="text-sm text-[#667085] pr-4 mt-2">
                                  Discount
                                </label>
                                <TextInputDash
                                  placeholder="Write Description"
                                  inputClass="placeholder:italic h-9"
                                  paddingBottom="pb-0"
                                  width="flex-1 min-w-[150px]"
                                  value={discountDescription}
                                  onChange={(e) =>
                                    setDiscountDescription(e.target.value)
                                  }
                                />

                                <div className="relative ">
                                  <div className="relative">
                                    <input
                                      type="number"
                                      value={
                                        discInPercentage
                                          ? percentageValue
                                          : discount
                                      }
                                      className={`form-input w-36 pl-10 h-9 ${discountError ? '!border-rose-400' : ''}`}
                                      min={0}
                                      onChange={(e) => {
                                        if (e.target.value < 0) {
                                          setDiscountError(
                                            'Discount should be positive',
                                          );
                                        } else if (
                                          discInPercentage
                                            ? e.target.value > 100
                                            : e.target.value > calcTotal(true)
                                        ) {
                                          setDiscountError(
                                            discInPercentage
                                              ? 'Discount should not be greater than 100%'
                                              : 'Discount should be less than Total',
                                          );
                                        } else {
                                          setDiscountError('');
                                        }
                                        if (e.target.value === '') {
                                          setDiscount('');
                                          setPercetageValue('');
                                        } else {
                                          const totalAmount =
                                            calcTotal(true) || 0;
                                          if (discInPercentage) {
                                            const discountAmount =
                                              totalAmount *
                                              (parseFloat(
                                                e.target.value ?? 0,
                                              )?.toFixed(2) /
                                                100);
                                            setPercetageValue(e.target.value);
                                            setDiscount(
                                              roundToTwo(discountAmount),
                                            );
                                          } else {
                                            const discountPercentage =
                                              (parseFloat(
                                                e.target.value ?? 0,
                                              )?.toFixed(2) /
                                                totalAmount) *
                                              100;
                                            setPercetageValue(
                                              roundToTwo(discountPercentage),
                                            );
                                            setDiscount(e.target.value);
                                          }
                                        }
                                      }}
                                      onBlur={(e) => {
                                        if (discount) {
                                          setDiscount(roundToTwo(discount));
                                        }
                                        if (percentageValue) {
                                          setPercetageValue(
                                            roundToTwo(percentageValue),
                                          );
                                        }
                                      }}
                                      placeholder={
                                        !discInPercentage ? '0.00' : '0'
                                      }
                                    />
                                    <span className="absolute top-[50%] -translate-y-[50%] left-2 flex items-center gap-2 font-medium">
                                      {discInPercentage
                                        ? '%'
                                        : currency?.symbol}
                                      <div className="w-[0.5px] h-[1.188rem] bg-slate-600" />
                                    </span>
                                  </div>
                                  {discountError && (
                                    <p className="absolute whitespace-nowrap  text-xs text-rose-400">
                                      {discountError}
                                    </p>
                                  )}
                                  <label
                                    className={`${discountError ? ' mt-5' : 'mt-1'} cursor-pointer text-xs font-medium text-nowrap inline-flex justify-center items-center`}
                                  >
                                    <input
                                      type="checkbox"
                                      checked={discInPercentage}
                                      className="form-checkbox mr-2 !h-4 !w-4"
                                      onChange={() =>
                                        setDiscInPercentage(!discInPercentage)
                                      }
                                    />
                                    In Percentage
                                  </label>
                                </div>
                              </div>
                            )
                          ) : (
                            <span
                              style={
                                isPreview && fontFamily
                                  ? { fontFamily: fontFamily || '' }
                                  : {}
                              }
                            >
                              Discount:
                            </span>
                          )}
                          <span
                            className="text-nowrap"
                            style={
                              isPreview && fontFamily
                                ? { fontFamily: fontFamily || '' }
                                : {}
                            }
                          >
                            Total (
                            {selectedProducts?.[0]?.currency?.value ||
                              team?.currency}
                            ):
                          </span>
                        </div>
                      )}
                      {selectedProducts?.length > 0 && (
                        <div
                          className="flex gap-6 justify-end"
                          style={
                            isPreview && fontFamily
                              ? { fontFamily: fontFamily || '' }
                              : {}
                          }
                        >
                          <div className="flex flex-col gap-2 text-[#667085] font-medium">
                            <span className="min-w-[4rem]">
                              {getCurrencySymbolFromIso(
                                selectedProducts?.[0]?.currency?.value,
                                currencies,
                              )}
                              {parseFloat(calcSubTotal())?.toLocaleString(
                                undefined,
                                {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                },
                              )}
                            </span>
                            <span className="min-w-[4rem]">
                              {getCurrencySymbolFromIso(
                                selectedProducts?.[0]?.currency?.value,
                                currencies,
                              )}
                              {parseFloat(calcTax())?.toLocaleString(
                                undefined,
                                {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                },
                              )}
                            </span>

                            <span
                              className={` ${
                                isDiscountAdded && !isPreview
                                  ? `${discountError ? 'h-20' : 'h-16'} pt-2 flex gap-4  `
                                  : ''
                              } `}
                            >
                              <span className="min-w-[7rem]">
                                {getCurrencySymbolFromIso(
                                  selectedProducts?.[0]?.currency?.value,
                                  currencies,
                                )}
                                {parseFloat(discount || 0)?.toLocaleString(
                                  undefined,
                                  {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  },
                                )}{' '}
                                {!isPreview && `(${percentageValue || 0}%)`}
                              </span>

                              {!isPreview && isDiscountAdded && (
                                // Delete Discount Button
                                <button
                                  onClick={() => {
                                    setIsDiscountAdded(false);
                                    setDiscount(0);
                                    setDiscountDescription('');
                                    setPercetageValue(0);
                                  }}
                                  className=" border-[#D0D5DD99] border rounded-[5px] h-[26px] w-[26px] shadow-sm inline-flex justify-center items-center"
                                  type="button"
                                >
                                  <svg
                                    width="16"
                                    height="16"
                                    viewBox="0 0 16 16"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M13.6059 3.87474C11.4483 3.66093 9.27778 3.55078 7.11373 3.55078C5.83086 3.55078 4.54798 3.61557 3.26511 3.74516L1.94336 3.87474"
                                      stroke="#E48642"
                                      strokeWidth="1.3"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                    <path
                                      d="M5.50781 3.22021L5.65035 2.37144C5.75402 1.75592 5.83177 1.2959 6.92675 1.2959H8.62429C9.71927 1.2959 9.8035 1.78184 9.90069 2.37792L10.0432 3.22021"
                                      stroke="#E48642"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                    <path
                                      d="M12.2124 5.92188L11.7913 12.4464C11.72 13.4636 11.6617 14.2541 9.85398 14.2541H5.69435C3.88667 14.2541 3.82835 13.4636 3.75708 12.4464L3.33594 5.92188"
                                      stroke="#E48642"
                                      strokeWidth="1.3"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                    <path
                                      d="M6.69336 10.6904H8.85092"
                                      stroke="#E48642"
                                      strokeWidth="1.3"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                    <path
                                      d="M6.15625 8.09863H9.39583"
                                      stroke="#E48642"
                                      strokeWidth="1.3"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                </button>
                              )}
                            </span>
                            <span className="min-w-[4rem]">
                              {getCurrencySymbolFromIso(
                                selectedProducts?.[0]?.currency?.value,
                                currencies,
                              )}
                              {parseFloat(calcTotal())?.toLocaleString(
                                undefined,
                                {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                },
                              )}
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </td>
              </tr>
              {!isPreview && (
                <>
                  <tr>
                    <td colSpan={6} className="px-5 text-left">
                      {/* {!IsPerson && ( */}
                      <section className="mt-4 pb-4">
                        <p className="text-base font-medium text-nowrap">
                          Additional Options
                        </p>
                        <div className="flex flex-wrap gap-x-4 gap-y-2 pb-4">
                          <div className="w-[300px]">
                            <p className="text-sm font-medium text-nowrap mt-1">
                              Project
                              {selectedProject && (
                                <span
                                  className="text-indigo-500 pl-2 cursor-pointer"
                                  onClick={() => setSelectedProject(null)}
                                  role="button"
                                  tabIndex="0"
                                >
                                  Clear
                                </span>
                              )}
                            </p>
                            <DropdownFull
                              options={projects}
                              selected={selectedProject}
                              setSelected={(value) => {
                                setSelectedProject(value);
                              }}
                              name="name"
                              scrollIntoView={false}
                              // search
                              height="h-10 w-[300px]"
                              flyout
                              placeholder="Attach to Project"
                              bottomOfTable
                              className="whitespace-nowrap truncate !h-fit"
                              ids={['invoiceEstimateContainer']}
                              isTableDropdown
                            />
                          </div>
                          {!IsPerson && (
                            <div className="w-[300px]">
                              <p className="text-sm font-medium text-nowrap mt-1">
                                Sales Representative{' '}
                                {invoiceSalesRep && (
                                  <span
                                    className="text-indigo-500 pl-2 cursor-pointer"
                                    onClick={() => setInvoiceSalesRep(null)}
                                    role="button"
                                    tabIndex="0"
                                  >
                                    Clear
                                  </span>
                                )}
                              </p>
                              <DropdownFull
                                options={salesRep?.map((d) => ({
                                  ...d,
                                  name: `${d?.firstName} ${d?.lastName}`,
                                  id: d?.id,
                                }))}
                                selected={invoiceSalesRep}
                                setSelected={(value) => {
                                  setInvoiceSalesRep(value);
                                }}
                                name="name"
                                scrollIntoView={false}
                                // search
                                height="h-10 w-[300px]"
                                flyout
                                placeholder="Assign to Sales Representative"
                                bottomOfTable
                                ids={['invoiceEstimateContainer']}
                                className="whitespace-nowrap truncate !h-fit"
                                isTableDropdown
                              />{' '}
                            </div>
                          )}
                          <div className="w-[300px]">
                            <p className="text-sm font-medium text-nowrap mt-1">
                              Tags
                            </p>
                            <BasicCheckboxDropdown
                              selectedOptions={selectedTags}
                              setSelectedOptions={setSelectedTags}
                              options={
                                tags?.map((t) => ({
                                  label: t?.name,
                                  value: t?.id,
                                })) || []
                              }
                              label="Use Tags"
                              type="tag"
                              flyout
                              width="w-[300px]"
                              disabled={accessType === VIEWER}
                              bottomOfTable
                              ids={['invoiceEstimateContainer']}
                              isTableDropdown
                              dropdownBoxWidth="min-w-44 w-full max-w-[300px]"
                            />
                          </div>
                        </div>
                      </section>
                      {/* )} */}
                    </td>
                  </tr>
                  {(isDraft || isNewInvoice) && (
                    <tr className="!border-t-0">
                      <td colSpan={6} className="px-5 text-left">
                        {/* {!IsPerson && ( */}
                        <section className={`pb-4 ${IsPerson ? 'mt-5' : ''}`}>
                          <label className="cursor-pointer text-base font-medium text-nowrap inline-flex justify-center items-center">
                            <input
                              type="checkbox"
                              checked={isRecurring}
                              className="form-checkbox mr-2 h-5 w-5"
                              onChange={() => setIsRecurring(!isRecurring)}
                            />
                            Recurring
                          </label>
                          {isRecurring && (
                            <div className="flex flex-wrap gap-x-4 gap-y-2 pb-4">
                              <div className="w-[300px]">
                                <p className="text-sm font-medium text-nowrap mt-1">
                                  Repeats
                                </p>
                                <DropdownFull
                                  options={RecurrencePatternOptions}
                                  selected={recurrencePattern}
                                  setSelected={(value) => {
                                    setRecurrencePattern(value);
                                  }}
                                  name="name"
                                  scrollIntoView={false}
                                  // search
                                  height="h-10 w-[300px]"
                                  flyout
                                  placeholder="Repeats"
                                  bottomOfTable
                                  className="whitespace-nowrap truncate !h-fit"
                                  ids={['invoiceEstimateContainer']}
                                  isTableDropdown
                                />
                              </div>
                              <div className="w-[300px]">
                                <p className="text-sm font-medium text-nowrap mt-1">
                                  End
                                </p>
                                <DropdownFull
                                  options={[
                                    {
                                      name: 'Never',
                                      id: '',
                                    },
                                    {
                                      name: 'By',
                                      id: 'by',
                                    },
                                    {
                                      name: 'After',
                                      id: 'after',
                                    },
                                  ]}
                                  selected={endRecurringType}
                                  setSelected={(value) => {
                                    setEndRecurringType(value);
                                  }}
                                  name="name"
                                  scrollIntoView={false}
                                  height="h-10 w-[300px]"
                                  flyout
                                  placeholder="Repeats"
                                  bottomOfTable
                                  className="whitespace-nowrap truncate !h-fit"
                                  ids={['invoiceEstimateContainer']}
                                  isTableDropdown
                                />
                              </div>
                              {endRecurringType === 'after' && (
                                <div className="w-[300px]">
                                  <label
                                    htmlFor="recurrencePattern"
                                    className="text-sm block font-medium text-nowrap mt-1"
                                  >
                                    Number of occurrences{' '}
                                  </label>
                                  <TextInputDash
                                    inputClass="!h-10"
                                    paddingBottom="0"
                                    id="occurrenceCount"
                                    value={occurrenceCount}
                                    onChange={(e) =>
                                      setOccurrenceCount(e.target.value)
                                    }
                                    min={1}
                                    type="number"
                                    placeholder="Number of occurrences"
                                  />
                                </div>
                              )}
                              {endRecurringType === 'by' && (
                                <div className="w-[300px]">
                                  <p className="text-sm font-medium text-nowrap mt-1">
                                    Recurrence End Date
                                  </p>
                                  <DatePickerDash
                                    placeholder="Recurrence End Date"
                                    value={
                                      recurrenceEndDate
                                        ? moment(
                                            recurrenceEndDate,
                                          ).toISOString()
                                        : null
                                    }
                                    onChange={(selectedDates) => {
                                      setRecurrenceEndDate(
                                        moment(selectedDates[0]).format(
                                          'YYYY-MM-DD',
                                        ),
                                      );
                                    }}
                                    options={options}
                                    inputClass="w-full !h-10"
                                    align="left"
                                  />
                                  {invoiceDueDate && recurrenceEndDate && (
                                    <p>
                                      {calcInvoicesByEndDate()} invoices will be
                                      created
                                    </p>
                                  )}
                                </div>
                              )}
                              {recurrencePattern !== 'daily' && (
                                <div className="w-[300px]">
                                  <Tooltip
                                    content={
                                      <>
                                        <p>This is when the invoice will be </p>
                                        <p>created in your invoice list.</p>
                                      </>
                                    }
                                    className="w-fit"
                                    contentClassName="border-none overflow-visible text-sm text-[#667085] relative tooltipArrowDown"
                                  >
                                    <label
                                      htmlFor="inAdvanceCreationDays"
                                      className="text-sm block font-medium w-fit text-nowrap mt-1 underline"
                                    >
                                      Create number of days in advance
                                    </label>
                                  </Tooltip>
                                  <TextInputDash
                                    inputClass="!h-10"
                                    paddingBottom="0"
                                    id="inAdvanceCreationDays"
                                    value={inAdvanceCreationDays}
                                    onChange={(e) =>
                                      setInAdvanceCreationDays(e.target.value)
                                    }
                                    type="number"
                                    placeholder="Advance Days"
                                  />
                                </div>
                              )}
                              <div className="w-[300px] flex items-center pt-2 min-h-16">
                                <label className="mt-4 cursor-pointer text-base font-medium text-nowrap inline-flex justify-center items-center">
                                  <input
                                    type="checkbox"
                                    checked={emailCustomer}
                                    className="form-checkbox mr-2 h-5 w-5"
                                    onChange={() =>
                                      setEmailCustomer(!emailCustomer)
                                    }
                                  />
                                  Email invoice to customer automatically
                                </label>
                              </div>
                            </div>
                          )}
                        </section>
                        {/* )} */}
                      </td>
                    </tr>
                  )}
                </>
              )}
            </tbody>
          </table>
          {/* {isLineItemsError && (
            <p className="text-rose-500 text-center font-semibold mb-4">
              {isLineItemsError
                ? selectedProducts?.length === 0
                  ? "At least one Line Item is required."
                  : "Please Fill Line Items Properly"
                : ""}
            </p>
          )}
          {!selectedProducts?.length > 0 && (
            <h6 className="text-center mt-4">Please Add a New Line.</h6>
          )} */}
        </div>
      </div>
    </>
  );
};

export default CreateInvoicesTable;
