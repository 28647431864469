import React, { useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { resetPassword } from '../../API/backend_helper';
import Logo from '../../images/custom/count-logo.png';
import AuthSlider from '../../components/AuthSlider';
import TextInput from '../../components/TextInput';

function ResetPassword() {
  const navigate = useNavigate();

  const { token } = useParams();
  const [loading, setLoading] = useState(false);

  document.title = 'COUNT | Reset Password';

  const onSubmit = async (values, formikHandler) => {
    setLoading(true);
    try {
      const response = await resetPassword(token, values);
      if (typeof response === 'string') {
        toast.error(response);
      } else {
        formikHandler.resetForm();
        navigate('/signin');
      }
    } catch (e) {
      console.log('error', e);
    } finally {
      setLoading(false);
    }
  };
  return (
    <main className="bg-white dark:bg-slate-900">
      <div className="relative md:flex">
        {/* Content */}
        <div className="md:w-1/2">
          <div className="min-h-[100dvh] h-full flex flex-col after:flex-1">
            {/* Header */}
            <div className="flex-1">
              <div className="flex items-center justify-between h-16 px-4 sm:px-6 lg:px-8">
                {/* Logo */}
                <Link className="block" to="/">
                  <img src={Logo} alt="logo" className="h-8" />
                </Link>
              </div>
            </div>

            <div className="max-w-[476px] min-w-[50%] mx-auto w-full px-4 py-8">
              <h1 className="text-[40px] font-medium text-[#232323CC] leading-tight mb-10 capitalize">
                get back in: reset your password
              </h1>

              {/* Form */}
              <Formik
                enableReinitialize
                initialValues={{
                  password: '',
                  passwordConfirm: '',
                }}
                validationSchema={Yup.object({
                  password: Yup.string()
                    .min(8, 'Password must be at least 8 characters')
                    .matches(/(.*[a-z].*)/, 'At least lowercase letter')
                    .matches(/(.*[A-Z].*)/, 'At least uppercase letter')
                    .matches(/(.*[0-9].*)/, 'At least one number')
                    .required('Please Enter Password'),
                  passwordConfirm: Yup.string()
                    .oneOf([Yup.ref('password'), null], 'Passwords must match')
                    .required('Confirm password is required'),
                })}
                onSubmit={onSubmit}
              >
                {(validation) => (
                  <Form>
                    <div className="space-y-8">
                      <TextInput
                        label="Password"
                        type="password"
                        placeholder=" "
                        id="password"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.password || ''}
                        error={
                          validation.touched.password
                            ? validation.errors.password
                            : ''
                        }
                        required
                      />
                      <TextInput
                        label="Confirm Password"
                        type="password"
                        placeholder=" "
                        id="passwordConfirm"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.passwordConfirm || ''}
                        error={
                          validation.touched.passwordConfirm
                            ? validation.errors.passwordConfirm
                            : ''
                        }
                        required
                      />
                    </div>

                    <button
                      className="btn mt-12 w-full py-4 text-lg rounded-[10px] font-semibold !bg-indigo-500 hover:bg-indigo-600 text-white
                      disabled:!bg-indigo-300 disabled:text-white disabled:cursor-not-allowed"
                      type="submit"
                      disabled={loading}
                    >
                      {loading && (
                        <svg
                          className="animate-spin w-4 h-4 fill-current shrink-0 mr-2"
                          viewBox="0 0 16 16"
                        >
                          <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z" />
                        </svg>
                      )}
                      Save
                    </button>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>

        {/* Auth Slider */}

        <AuthSlider />
      </div>
    </main>
  );
}

export default ResetPassword;
