import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PayrollForm from './PayrollForm';
import { CloseButton } from './Svg';
import AppLayout from '../../components/AppLayout';
import TobTabs from './TopTabs';
import PayrollHistoryTable from './PayrollHistoryTable';
import StartPayrollStep from './StartPayrollStep';
import ConfirmationModal from './ConfirmationModal';
import {
  getAppIntegrations,
  getGustoById,
  getGustoUpcomingPayroll,
  getPayrolls,
  getPeople,
} from '../../API/backend_helper';
import AvatarImage from '../../images/custom/payment.png';
import PayScheduleTable from './PayScheduleTable';
import EmployeesPaySettings from './EmployeesPaySettings';
import { useNavigate } from 'react-router-dom';

const PAYROLL_HISTORY_TAB = 'payrollHistoryTab';
const PAY_SETTINGS_TAB = 'paySettingsTab';
const RUN_PAYROLL_TAB = 'runPayrollTab';
const PAYROLL_SCHEDULE = 'PayrollScheduleTab';

const Payroll = () => {
  const { team } = useSelector((state) => state.Team);
  const navigate = useNavigate();
  const [formOpen, setFormOpen] = useState(false);
  const [title, setTitle] = useState('Payroll');
  const [activeTab, setActiveTab] = useState(RUN_PAYROLL_TAB);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [payrollHistory, setPayrollHistory] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [activeStep, setActiveStep] = useState(1);

  const [payrolls, setPayrolls] = useState([]);
  const [upcomingPayrolls, setUpcomingPayrolls] = useState([]);

  const handleGetStarted = (e) => {
    e.stopPropagation();
    setTitle('Run Payroll');
    setFormOpen(true);
  };

  const loadUpcomingPayrolls = async (
    id = payrollHistory?.[0]?.gusto?.[0]?.id,
  ) => {
    try {
      const res = await getGustoUpcomingPayroll(id);
      setUpcomingPayrolls(res?.data?.upcomingPayrolls);
    } catch (err) {
      console.log('err', err);
    }
  };

  const getPayrollHistory = async ({ isLoading = false } = {}) => {
    if (isLoading) {
      setIsLoading(true);
    }
    try {
      const res = await getAppIntegrations({});
      setPayrollHistory(res?.data?.apps);
      if (res?.data?.apps?.[0]?.gusto?.[0]?.id) {
        loadUpcomingPayrolls(res?.data?.apps?.[0]?.gusto?.[0]?.id);
      }
    } catch (e) {
      console.log('error', e);
    } finally {
      setIsLoading(false);
    }
  };

  const loadPayrolls = async () => {
    try {
      const res = await getPayrolls();
      setPayrolls(res?.data?.processedPayrolls);
    } catch (err) {
      console.log('err', err);
    }
  };

  useEffect(() => {
    getPayrollHistory({ isLoading: true });
    loadPayrolls();
  }, []);

  return (
    <>
      <ConfirmationModal
        isOpen={confirmationModalOpen}
        setIsOpen={setConfirmationModalOpen}
        handleDone={() => {
          setActiveStep(1);
          setFormOpen(false);
          setTitle('Payroll');
          setActiveTab(RUN_PAYROLL_TAB);
          setConfirmationModalOpen(false);
        }}
      />
      <AppLayout>
        <main className="relative grow ">
          {/* Content */}
          <div className=" px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto h-full flex flex-col">
            <div className="sm:flex justify-between mb-10">
              <h1 className="text-black text-opacity-60 text-4xl font-medium leading-[2.375rem]">
                {title}
              </h1>
              {activeStep !== 4 && formOpen && (
                <button
                  onClick={() => {
                    setConfirmationModalOpen(true);
                  }}
                  className=""
                  type="button"
                >
                  <CloseButton />
                </button>
              )}
            </div>
            {!formOpen && payrollHistory?.[0]?.gusto?.[0]?.id && (
              <TobTabs
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                PAYROLL_HISTORY_TAB={PAYROLL_HISTORY_TAB}
                PAY_SETTINGS_TAB={PAY_SETTINGS_TAB}
                RUN_PAYROLL_TAB={RUN_PAYROLL_TAB}
                PAYROLL_SCHEDULE={PAYROLL_SCHEDULE}
              />
            )}
            {isLoading ? (
              <div className="flex flex-col gap-2 items-center pb-2 justify-center">
                <svg
                  width="29"
                  height="29"
                  viewBox="0 0 29 29"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="animate-spin w-10 h-10 fill-current shrink-0"
                >
                  <path
                    d="M14.5 2.41699V7.25033"
                    stroke="#E48642"
                    strokeWidth="2.55882"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M14.5 21.749V26.5824"
                    stroke="#E48642"
                    strokeWidth="2.55882"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M5.95703 5.95703L9.37662 9.37662"
                    stroke="#E48642"
                    strokeWidth="1.58955"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M19.623 19.6211L23.0426 23.0407"
                    stroke="#E48642"
                    strokeWidth="1.58955"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M2.41699 14.5H7.25033"
                    stroke="#E48642"
                    strokeWidth="1.58955"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M21.749 14.5H26.5824"
                    stroke="#E48642"
                    strokeWidth="1.58955"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M5.95703 23.0407L9.37662 19.6211"
                    stroke="#E48642"
                    strokeWidth="1.58955"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M19.623 9.37662L23.0426 5.95703"
                    stroke="#E48642"
                    strokeWidth="1.58955"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <p className="text-slate-600 font-medium text-[0.875rem] text-center">
                  Fetching data, please wait...
                </p>
              </div>
            ) : !payrollHistory?.[0]?.gusto?.[0]?.id ? (
              <div className="flex-1 flex flex-col justify-center items-center">
                {/* Avatar Image  */}
                <img src={AvatarImage} alt="" className="h-[12.5rem]" />
                <h3 className="mb-6 mt-4 leading-tight text-[24px] font-medium text-[#00000099] ">
                  Please Connect Gusto to Run Payroll
                </h3>
                <button
                  className="bg-indigo-500 h-10 border border-indigo-500 rounded-[0.313rem] px-[1.125rem] py-2.5 shadow text-white text-base leading-6 flex items-center justify-center gap-2 font-normal"
                  onClick={() => navigate(`/settings/integrations`)}
                  type="button"
                >
                  Connect
                </button>
              </div>
            ) : formOpen ? (
              <PayrollForm
                setFormOpen={setFormOpen}
                upcomingPayrolls={upcomingPayrolls}
                gustoId={payrollHistory?.[0]?.gusto?.[0]?.id}
                loadUpcomingPayrolls={loadUpcomingPayrolls}
                loadPayrolls={loadPayrolls}
                activeStep={activeStep}
                setActiveStep={setActiveStep}
                gusto={payrollHistory?.[0]?.gusto?.[0]}
              />
            ) : (
              <>
                {/* Payroll History Tab  */}
                {activeTab === PAYROLL_HISTORY_TAB && (
                  <PayrollHistoryTable
                    payrollHistory={payrollHistory}
                    payrolls={payrolls}
                  />
                )}
                {activeTab === PAY_SETTINGS_TAB && (
                  <EmployeesPaySettings
                    payrollHistory={payrollHistory}
                    payrolls={payrolls}
                    gustoId={payrollHistory?.[0]?.gusto?.[0]?.id}
                  />
                )}

                {activeTab === RUN_PAYROLL_TAB && (
                  <StartPayrollStep handleGetStarted={handleGetStarted} />
                )}
                {activeTab === PAYROLL_SCHEDULE && (
                  <PayScheduleTable paySchedule={upcomingPayrolls} />
                )}
              </>
            )}
          </div>
        </main>
      </AppLayout>
    </>
  );
};

export default Payroll;
