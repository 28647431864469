import React, { useState } from "react";
import TextInputDash from "../../components/TextInputDash";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import ModalBasic from "../../components/ModalBasic";
import { phone as phoneFormatter } from "phone";
import { updateTeamById } from "../../API/backend_helper";

const TeamInfoModal = ({ isOpen, setIsOpen, team, fetchTeam }) => {
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (values, formik) => {
    setLoading(true);
    try {
      const res = await updateTeamById(team?.id, values);
      await fetchTeam();
      setIsOpen(false);
    } catch (err) {
      console.log("err", err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <ModalBasic
      modalOpen={isOpen}
      setModalOpen={setIsOpen}
      title={`Edit Team Information`}
      width="!w-[850px]"
    >
      <Formik
        enableReinitialize={true}
        initialValues={{
          email: team?.email || "",
          phone: team?.phone || "",
          businessAddress: {
            street: team?.businessAddress?.street || "",
            street2: team?.businessAddress?.street2 || "",
            city: team?.businessAddress?.city || "",
            zipCode: team?.businessAddress?.zipCode || "",
          },
        }}
        validationSchema={Yup.object({
          email: Yup.string().email("Invalid email address"),
          businessAddress: Yup.object({
            street: Yup.string(),
            street2: Yup.string(),
            city: Yup.string(),
            zipCode: Yup.string(),
          }),
        })}
        validate={(values) => {
          const errors = {};
          const { phone } = values;
          if (
            phone &&
            !phoneFormatter(phone, { validateMobilePrefix: false })?.isValid
          ) {
            errors.phone = "Phone number is not valid";
          }

          return errors;
        }}
        onSubmit={handleSubmit}
      >
        {(validation) => (
          <Form className="px-4 py-6">
            <div className="flex items-start gap-4 w-[100%]">
              <div className="flex items-start w-full sm:w-[50%]">
                <TextInputDash
                  label="Business Email"
                  id="email"
                  name="email"
                  placeholder="Business Email"
                  value={validation.values.email || ""}
                  error={validation.touched.email && validation.errors.email}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                />
              </div>
              <div className="flex items-start w-full sm:w-[50%]">
                <TextInputDash
                  label="Phone Number"
                  id="phone"
                  name="phone"
                  placeholder="Phone"
                  value={validation.values.phone || ""}
                  error={validation.touched.phone && validation.errors.phone}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                />
              </div>
            </div>
            <div className="flex flex-col items-start gap-2.5 w-[100%] mb-[1.875rem] ">
              <label className="text-sm font-medium text-slate-600 block">
                Address
              </label>
              <div className="flex flex-row w-full h-12 border border-[#D0D5DD] px-4 py-[0.438] rounded-[0.313rem] items-center self-stretch bg-white shadow-sm justify-between overflow-x-auto scrollbar">
                <input
                  id="businessAddress.street"
                  name="businessAddress.street"
                  placeholder="Address"
                  value={validation.values.businessAddress.street || ""}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  className="form-input w-[12.5rem] items-center placeholder:text-slate-600 placeholder:text-opacity-80 placeholder:leading-6   border-none shadow-none"
                />
                <div className="bg-[#D0D5DD] h-[2.125rem] w-px"></div>
                <input
                  id="businessAddress.street2"
                  name="businessAddress.street2"
                  placeholder="Address 2 (Optional)"
                  value={validation.values.businessAddress.street2 || ""}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  className="form-input w-[12.5rem] items-center placeholder:text-slate-600 placeholder:text-opacity-80 placeholder:leading-6   border-none shadow-none"
                />
                <div className="bg-[#D0D5DD] h-[2.125rem] w-px"></div>
                <input
                  id="businessAddress.city"
                  name="businessAddress.city"
                  placeholder="City"
                  value={validation.values.businessAddress.city || ""}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  className="form-input w-[9.188rem] items-center placeholder:text-slate-600 placeholder:text-opacity-80 placeholder:leading-6   border-none shadow-none"
                />
                <div className="bg-[#D0D5DD] h-[2.125rem] w-px"></div>
                <input
                  id="businessAddress.zipCode"
                  name="businessAddress.zipCode"
                  placeholder="Postal"
                  value={validation.values.businessAddress.zipCode || ""}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  className="form-input w-[9.188rem] items-center placeholder:text-slate-600 placeholder:text-opacity-80 placeholder:leading-6   border-none shadow-none"
                />
              </div>
            </div>

            {/* Close/Add Buttons */}
            <div className="w-full flex justify-end items-center gap-4 mt-[1.875rem]">
              <button
                className="flex items-center justify-center py-2.5 px-[1.875rem] rounded-[0.313rem]  bg-white border border-slate-600 w-[6.875rem] h-11 text-slate-600 leading-6"
                onClick={(event) => {
                  event.stopPropagation();
                  setIsOpen(false);
                }}
              >
                Close
              </button>
              <button
                className="flex items-center justify-center py-2.5 px-[1.875rem] rounded-[0.313rem] bg-indigo-500 shadow w-[6.875rem] h-11 text-white leading-6
                disabled:!bg-indigo-300 disabled:cursor-not-allowed"
                type="submit"
                disabled={
                  loading ||
                  (!validation.values.email &&
                    !validation.values.phone &&
                    !validation.values.businessAddress.street &&
                    !validation.values.businessAddress.street2 &&
                    !validation.values.businessAddress.city &&
                    !validation.values.businessAddress.zipCode)
                }
              >
                {loading && (
                  <svg
                    className="animate-spin w-4 h-4 fill-current shrink-0 mr-2"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z" />
                  </svg>
                )}
                Update
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </ModalBasic>
  );
};

export default TeamInfoModal;
