import React from 'react';
import CategoriesDropdown from '../transactions/CategoriesDropdown';
import TextInputDash from '../../components/TextInputDash';
import Tooltip from '../../components/Tooltip';

const MapExpenseTypes = ({
  headersObj,
  setHeadersObj,
  accounts,
  onSubmit,
  checkRowsError,
  isEmptyRowsError,
  loading = false,
}) => (
  <div className="">
    <h3 className="text-2xl mb-2 font-semibold">
      Please configure your expense
    </h3>
    <div className="bg-transparent  w-full rounded-[5px]  my-5 dark:bg-transparent border border-[#D0D5DD] max-w-[800px] ">
      {/* Table */}
      <div
        className="overflow-x-auto scrollbar h-full w-full "
        id="createBillTableContainer"
      >
        <table className="table-auto w-full  ">
          {/* Table header */}
          <thead className="text-base font-semibold text-[#667085] border-b border-[#D0D5DD] bg-[#A0CD850F]">
            <tr>
              <th className="px-2 first:pl-5 last:pr-5 py-3 max-w-[220px] min-w-[220px] md:w-full whitespace-nowrap">
                <div className="font-medium text-left">Expense</div>
              </th>
              <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap  lg:w-[300px]  lg:min-w-[300px]  lg:max-w-[300px] max-w-[220px] w-[220px] ">
                <div className="font-medium text-left">GL Account</div>
              </th>
              <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap max-w-24 min-w-24">
                <div className="font-medium text-left">Action</div>
              </th>
            </tr>
          </thead>
          {/* Table body */}
          <tbody className="text-sm divide-y divide-[#D0D5DD] ">
            {headersObj?.map((obj, i) => (
              <tr key={i}>
                <td className="px-2 first:pl-5 last:pr-5  pt-2 pb-1 text-left text-base">
                  <TextInputDash
                    paddingBottom="pb-4"
                    placeholder="Expense"
                    onChange={(e) => {
                      const tempHeader = [...headersObj];
                      tempHeader[i].name = e.target.value;
                      setHeadersObj(tempHeader);
                    }}
                    onBlur={obj?.nameError && checkRowsError}
                    value={obj?.name}
                    error={
                      obj?.nameError !== 'Expense should not be empty'
                        ? obj?.nameError
                        : !obj?.name && obj?.nameError
                          ? obj?.nameError
                          : ''
                    }
                  />
                </td>
                <td className="px-2 first:pl-5 last:pr-5 pt-2 pb-1  text-left">
                  <div className="pb-4 relative">
                    <CategoriesDropdown
                      allCategories={accounts || []}
                      selectedCategoryId={obj?.accountId}
                      setSelectedCategoryId={(id) => {
                        const tempHeader = [...headersObj];
                        tempHeader[i].accountId = id;
                        setHeadersObj(tempHeader);
                      }}
                      isError={!obj?.accountId && obj?.error}
                      onBlur={obj?.error && checkRowsError}
                      isSetCategoryStyle
                      id="accountId"
                      name="accountId"
                      height="h-12 !w-full lg:!max-w-[300px] max-w-[200px]"
                      type="bills"
                      isTableDropdown
                      bottomOfTable
                      ids={['expenseReportingContainer']}
                    />
                    {!obj?.accountId && obj?.error && (
                      <div className="absolute -bottom-[2px] mt-1 text-xs text-rose-400">
                        {obj?.error}
                      </div>
                    )}
                  </div>
                </td>
                <td className="whitespace-nowrap">
                  <div className="w-[4rem] inline-flex justify-center items-center gap-3.5">
                    <Tooltip
                      content="Delete Row"
                      contentClassName="border-none overflow-visible text-sm text-[#667085] relative tooltipArrowDown"
                    >
                      <div className="w-[1.625rem] h-[1.625rem] cursor-pointer">
                        {/* Delete SVG  */}
                        <svg
                          width="28"
                          height="28"
                          viewBox="0 0 28 28"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          onClick={(e) => {
                            e.stopPropagation();
                            const tempHeaders = [...headersObj];
                            tempHeaders.splice(i, 1);
                            setHeadersObj(tempHeaders);
                          }}
                        >
                          <path
                            d="M10.1 26.667H17.9C24.4 26.667 27 24.067 27 17.567V9.76699C27 3.26699 24.4 0.666992 17.9 0.666992H10.1C3.6 0.666992 1 3.26699 1 9.76699V17.567C1 24.067 3.6 26.667 10.1 26.667Z"
                            fill="white"
                            stroke="#D0D5DD"
                            strokeOpacity="0.6"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M19.6063 9.54173C17.4487 9.32792 15.2782 9.21777 13.1141 9.21777C11.8313 9.21777 10.5484 9.28257 9.26551 9.41215L7.94376 9.54173"
                            stroke="#667085"
                            strokeOpacity="0.8"
                            strokeWidth="1.3"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M11.5073 8.8872L11.6498 8.03843C11.7535 7.42291 11.8313 6.96289 12.9262 6.96289H14.6238C15.7188 6.96289 15.803 7.44883 15.9002 8.04491L16.0427 8.8872"
                            stroke="#858D9D"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M18.2133 11.5889L17.7921 18.1134C17.7208 19.1306 17.6625 19.9211 15.8548 19.9211H11.6952C9.88752 19.9211 9.82921 19.1306 9.75794 18.1134L9.33679 11.5889"
                            stroke="#667085"
                            strokeOpacity="0.8"
                            strokeWidth="1.3"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M12.693 16.3574H14.8505"
                            stroke="#667085"
                            strokeOpacity="0.8"
                            strokeWidth="1.3"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M12.1552 13.7656H15.3948"
                            stroke="#667085"
                            strokeOpacity="0.8"
                            strokeWidth="1.3"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                    </Tooltip>
                  </div>
                </td>
              </tr>
            ))}
            <tr>
              <td
                colSpan={3}
                className="px-2 first:pl-5 last:pr-5 py-2.5 text-left"
              >
                <button
                  type="button"
                  className="btn h-9 px-[14px] gap-2 text-indigo-500 text-base border border-indigo-500 cursor-pointer"
                  onClick={() =>
                    setHeadersObj([
                      ...headersObj,
                      {
                        name: '',
                        accountId: null,
                      },
                    ])
                  }
                >
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.00004 14.6673C11.6667 14.6673 14.6667 11.6673 14.6667 8.00065C14.6667 4.33398 11.6667 1.33398 8.00004 1.33398C4.33337 1.33398 1.33337 4.33398 1.33337 8.00065C1.33337 11.6673 4.33337 14.6673 8.00004 14.6673Z"
                      stroke="#E48642"
                      strokeWidth="1.2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M5.33337 8H10.6667"
                      stroke="#E48642"
                      strokeWidth="1.2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M8 10.6663V5.33301"
                      stroke="#E48642"
                      strokeWidth="1.2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  Add A Row
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    {isEmptyRowsError && (
      <p className="text-rose-500 font-semibold mb-4">
        {headersObj.length === 0 ? 'At least one Row is required.' : ''}
      </p>
    )}
    <button
      className="w-fit min-w-[140px] bg-indigo-500 text-white rounded-[5px] h-11 py-2.5 px-[18px] flex items-center justify-center leading-6 text-[16px] font-base"
      onClick={onSubmit}
      disabled={loading}
      type="button"
    >
      {loading && (
        <svg
          className="animate-spin w-4 h-4 fill-current shrink-0 mr-2"
          viewBox="0 0 16 16"
        >
          <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z" />
        </svg>
      )}
      Submit{' '}
    </button>
  </div>
);

export default MapExpenseTypes;
