import React from "react";

export const EmptyInfoIcon = () => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.99935 13.8337V10.5003M9.99935 7.16699H10.0077M18.3327 10.5003C18.3327 15.1027 14.6017 18.8337 9.99935 18.8337C5.39698 18.8337 1.66602 15.1027 1.66602 10.5003C1.66602 5.89795 5.39698 2.16699 9.99935 2.16699C14.6017 2.16699 18.3327 5.89795 18.3327 10.5003Z"
        stroke="#667085"
        stroke-width="1.66667"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
