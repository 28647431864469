import React from 'react';
import Header from '../partials/Header';
import { Link, useNavigate } from 'react-router-dom';

const PrivacyPolicy = () => {
  const navigate = useNavigate();

  return (
    <div className="flex h-[100dvh] overflow-hidden">
      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        {/*  Site header */}
        <Header showHamburger={false} showUserMenu={false} />

        <main className="grow px-10 py-8 w-full max-w-9xl mx-auto">
          <div>
            <div className="flex gap-4 items-center mb-4">
              <button
                onClick={() => {
                  navigate('/');
                }}
                className="h-8 w-8 bg-[#FBF3ED] rounded inline-flex justify-center items-center"
              >
                <svg
                  width="10"
                  height="17"
                  viewBox="0 0 10 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8 2.5L2 8.5L8 14.5"
                    stroke="#E48642"
                    stroke-width="3.6"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </button>
              <h3 className="text-2xl font-medium leading-tight text-[#101828] ">
                Privacy Policy
              </h3>
            </div>

            <div className="space-y-4">
              <p>Last Updated: August 4, 2024</p>
              <p>
                This is the Privacy Policy of COUNT, an online SaaS accounting
                software, accessible via the domain getcount.com (referred to as
                “COUNT,” “we,” “us,” or “our”). COUNT is owned and operated by
                Not All Talk LLC, a Washington LLC, along with its affiliates
                and subsidiaries. This Privacy Policy describes how we collect,
                use, and secure your information as part of the operation of our
                services on the getcount.com domain. By using COUNT, you consent
                to the collection, use, and disclosure of your personal
                information as described in this Privacy Policy. This Privacy
                Policy is subject to our Terms of Service.
              </p>
              <h3 className="text-lg font-medium text-[#101828]">
                Definitions:
              </h3>
              <div>
                <p>“Subscribers” are our customers.</p>
                <p>“Customers” are the clients of our Subscribers.</p>
                <p>“Vendors” are the suppliers to our Subscribers.</p>
                <p>
                  “Team Members” refers to individuals granted access to COUNT
                  by Subscribers.
                </p>
                <p>
                  “Users” collectively refer to Subscribers, Customers, Vendors,
                  Team Members, and registered users of interactive areas on the
                  site.
                </p>
              </div>
              <h3 className="text-lg font-medium text-[#101828]">
                What Information Do We Collect?
              </h3>
              <p>
                Our primary purpose in collecting personal information is to
                provide you with a seamless accounting experience. We collect
                information necessary for providing and customizing our
                services. You may browse our site without revealing personal
                information. However, upon becoming a User, we may require
                various contact, identity, and billing information.
              </p>
              <p>
                Additionally, as you use COUNT, you may input personal
                information such as timesheets, billing details, and customer
                information. You have the option to not provide information by
                choosing not to become a User or by refraining from using
                certain features.
              </p>
              <p>
                We utilize technologies like cookies for various purposes
                including analytics, authentication, and improving user
                experience. You can manage cookie usage in your browser
                settings.
              </p>
              <h3 className="text-lg font-medium text-[#101828]">
                How We Use Your Information
              </h3>
              <p>
                We use your personal information to provide services, resolve
                disputes, bill amounts due, inform you about offers and updates,
                customize your experience, detect fraud, and enforce our Terms
                of Service. We may also use your information for marketing and
                analytical purposes to improve our services and tailor them to
                your needs.
              </p>
              <h3 className="text-lg font-medium text-[#101828]">
                Sharing of Your Information
              </h3>
              <p>
                We share personal information with third parties only as
                described in this Privacy Policy. This includes sharing
                subscriber timesheets and invoices with customers, and vice
                versa. We may share payment information with our payment
                processors for billing purposes. Additionally, we may share
                aggregated data with third parties for analytical purposes.
              </p>
              <h3 className="text-lg font-medium text-[#101828]">
                Your Use of Other Persons’ Information
              </h3>
              <p>
                COUNT allows Users to give limited access to personal
                information of other persons. By using this feature, you agree
                to have necessary consents and rights for collecting, using, and
                disclosing such information as described in this Privacy Policy.
              </p>
              <h3 className="text-lg font-medium text-[#101828]">
                Data Retention
              </h3>
              <p>
                We will retain your information for as long as your account is
                active or as needed to provide you services.
              </p>
              <p>
                Our goal is to retain your information as necessary to comply
                with our legal obligations and accepted accounting principles,
                resolve disputes, and enforce our agreements; this retention
                period may extend past the point at which you close your
                account.
              </p>
              <p>
                The criteria that will be for determining periods of retention
                will be based on the type of data. For example, data relevant to
                accounting information will be retained for at least 7 years,
                whereas temporary cookie data of a web visitor may not be
                retained at all.
              </p>
              <h3 className="text-lg font-medium text-[#101828]">
                Information from Connected Financial Accounts
              </h3>
              <p>
                When you connect your financial accounts to COUNT, we may
                collect certain financial information directly from these
                accounts. This includes transaction details, account balances,
                account numbers, and other related financial data. This
                information is collected securely via industry-standard
                encryption protocols and in compliance with applicable laws and
                regulations.
              </p>
              <p>
                <b className="font-semibold">Collection: </b> We collect
                financial information from your connected accounts to provide
                comprehensive accounting services, such as automated transaction
                categorization, financial reporting, and expense tracking. The
                collection of this data occurs only with your explicit consent
                and authorization during the account connection process.
              </p>
              <p>
                <b className="font-semibold"> Use: </b> The financial
                information obtained from your connected accounts is used to
                enhance the accuracy and efficiency of the services provided by
                COUNT. This includes reconciling transactions, generating
                financial reports, providing insights, and facilitating payment
                processing. We may also use this information to improve our
                services and tailor them to your specific needs.
              </p>
              <p>
                <b className="font-semibold">Retention: </b> We retain financial
                information for as long as necessary to provide our services and
                fulfill the purposes described in this Privacy Policy. The
                retention period may vary depending on the nature of the data
                and legal requirements. For example, transaction data relevant
                to financial reporting may be retained for up to seven years to
                comply with accounting and auditing standards.
              </p>
              <p>
                <b className="font-semibold">Deletion: </b> You may request the
                deletion of your connected financial account data at any time by
                contacting us at privacy@getcount.com. Upon receiving your
                request, we will promptly delete your financial information,
                unless retention is required by law or for legitimate business
                purposes. Additionally, if you disconnect your financial
                accounts from COUNT, we will cease collecting new data from
                those accounts.
              </p>

              <h3 className="text-lg font-medium text-[#101828]">
                California Residents
              </h3>
              <p>
                As a California resident, you have certain rights under the
                California Consumer Privacy Act (“CCPA”). For more information,
                please refer to our supplemental privacy policy below.
              </p>
              <h3 className="text-lg font-medium text-[#101828]">
                European Economic Area (EEA) and United Kingdom (UK) Visitors
              </h3>
              <p>
                If you are from the EEA or UK, your personal information is
                collected and used based on legal grounds such as contract
                performance, legitimate interests, or consent. For details,
                contact us at privacy@getcount.com.
              </p>
              <h3 className="text-lg font-medium text-[#101828]">
                Notification of Privacy Statement Changes
              </h3>
              <p>
                We may update this privacy statement. Material changes will be
                notified via email or a notice on our site. Please review this
                page periodically for updates.
              </p>
              <h3 className="text-lg font-medium text-[#101828]">Contact Us</h3>
              <p>
                For questions or detailed information about our privacy
                practices, email us at{' '}
                <Link
                  className="text-[#101828] underline"
                  to="mailto:privacy@getcount.com"
                >
                  privacy@getcount.com
                </Link>
                .
              </p>
              <h3 className="text-lg font-medium text-[#101828]">
                Supplemental Policy Information
              </h3>
              <h4 className="italic font-medium text-[#101828]">
                Invitations:
              </h4>
              <p>
                You have the option to invite collaborators to join you in using
                COUNT. To facilitate this, COUNT may ask you to import or
                manually enter your contacts’ email addresses. Upon your
                direction, we will send them invitations or other notices
                reflecting changes you make to their status in your COUNT
                account.
              </p>
              <h4 className="italic font-medium text-[#101828]">
                Third-Party Links and Services:
              </h4>
              <p>
                Clicking on links to third-party sites will redirect you away
                from the COUNT platform to the selected site. If you choose to
                use third-party products or services alongside COUNT, you enter
                into a separate agreement with that third party for their
                product or service and their use of your data. Since we cannot
                control third-party activities, we cannot be responsible for
                their use of your personal information, nor can we guarantee
                adherence to the same privacy practices as COUNT. We encourage
                you to review the privacy policies of any third-party service
                provider before engaging with them.
              </p>
              <h4 className="italic font-medium text-[#101828]">
                Testimonials:
              </h4>
              <p>
                With your consent, we may showcase your testimonials alongside
                your name. If you wish to have your testimonial removed, please
                contact us at privacy@getcount.com.
              </p>
              <h4 className="italic font-medium text-[#101828]">
                Social Media Features:
              </h4>
              <p>
                Our website may include Social Media Features like Facebook and
                Twitter buttons, as well as Widgets such as the Share this
                button or interactive mini-programs. These Features may collect
                your IP address, page visits, and set cookies to enable their
                functionality. Social Media Features and Widgets may be hosted
                by third parties or directly on our site, and your interactions
                with them are governed by the privacy policy of the providing
                company.
              </p>
              <h4 className="italic font-medium text-[#101828]">
                Residents of Canada:
              </h4>
              <p>
                Personal information, as defined in the Personal Information
                Protection and Electronic Documents Act of Canada (PIPEDA), is
                collected, stored, used, and processed by COUNT in compliance
                with our obligations under PIPEDA.
              </p>
              <h4 className="italic font-medium text-[#101828]">
                California Residents:
              </h4>
              <p>
                The California Consumer Privacy Act (CCPA) regulates how we
                handle personal information of California residents and grants
                certain rights to them regarding their personal information. As
                both a “business” and a “service provider” under CCPA, we
                interact directly with you in our role as a business.
              </p>
              <p>
                When we act as a service provider (for example, by providing our
                services to another company that you interact with), we follow
                the instructions of the business that engaged us with respect to
                how we process your personal information. If you would like more
                information about how your personal information is processed by
                other companies, including companies that engage us as a service
                provider, please contact those companies directly.
              </p>
              <p>
                For more information on how your personal information is
                processed, please contact us at{' '}
                <Link
                  className="text-[#101828] underline"
                  to="mailto:privacy@getcount.com"
                >
                  privacy@getcount.com
                </Link>
                . This policy applies specifically to California residents and
                may be subject to change. Any terms capitalized in this
                supplemental policy have the same meaning as in our general
                privacy policy.
              </p>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
