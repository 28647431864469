import React from 'react';
import { Handle } from 'reactflow';
import { Cross } from '../svg';

const PlaceholderNode = ({
  id,
  data,
  setActiveNodeId,
  setPanelOpen,
  setPanelStep,
  setIsEditing,
}) => {
  const handleClick = () => {
    setActiveNodeId(id);
    if (data.name === 'New Component') {
      setPanelStep('component1');
      setIsEditing(false);
      setPanelOpen(true);
    } else {
      setPanelStep('trigger1');
      setIsEditing(false);
      setPanelOpen(true);
    }
  };

  return (
    <div
      className="w-[12.5rem] h-[3.125rem] flex rounded-[10px] bg-white border border-[#D0D5DD] p-5 mx-[5.6rem] cursor-pointer"
      onClick={() => handleClick()}
      role="button"
      tabIndex="0"
    >
      <Handle
        type="target"
        position="top"
        className="opacity-0 mt-2"
        isConnectable={false}
      />
      <div className="flex gap-4 items-center w-full h-full">
        <Cross />
        <p className="text-[1rem] text-slate-600 leading-[2.375rem]">
          {data.name}
        </p>
      </div>
      <Handle
        type="source"
        position="bottom"
        className="opacity-0 mb-2"
        isConnectable={false}
      />
    </div>
  );
};

export default PlaceholderNode;
