import React, { useEffect, useState } from "react";
import { getAllInstitutions } from "../../../API/backend_helper";
import PaginationClassic from "../../../components/PaginationClassic";
import moment from "moment";
import InstitutionModal from "./InstitutionModal";

const AdminInstitution = () => {
  const [institutions, setInstitutions] = useState([]);
  const [localLimit, setLocalLimit] = useState(20);
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});

  const loadInstitutions = async ({
    limit = localLimit,
    page = 1,
    search = "  "
  }) => {
    try {
      const res = await getAllInstitutions({
        limit,
        page,
        search,
      });
      setInstitutions(res?.institutions);
    } catch (err) {
      console.log("err", err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadInstitutions({});
  }, []);

  const totalRecords = []

  return (
    <div>
      <InstitutionModal activeRecord={selectedRow} isOpen={isModalOpen} setIsOpen={setIsModalOpen} loadInstitutions={loadInstitutions} />
      {!isLoading ? (
        <div className="bg-white flex-1 dark:bg-slate-800  rounded-sm   overflow-auto">
          {/* First Table */}
          {institutions?.filter((record) => !record?.logoUrl).length > 0 && (
            <>
            <h2 className="text-xl font-medium">All Institutions without a logo</h2>
            <table className="table-auto w-full dark:text-slate-300 divide-y divide-slate-200 dark:divide-slate-700">
              {/* Table header */}
              <thead className="text-sm text-[#667085] font-medium dark:text-slate-400 shadow-md border-none">
                <tr>
                  {/* <th className="px-2 first:pl-5 last:pr-5 py-4 whitespace-nowrap"></th> */}
                  <th className="px-2 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                    <div className=" text-left font-medium">Date</div>
                  </th>
                  <th className="px-2 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                    <div className=" text-left font-medium">Name</div>
                  </th>
                  <th className="px-2 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                    <div className=" text-left font-medium">Actions</div>
                  </th>
                </tr>
              </thead>
              {/* Table body */}
              <tbody className="text-sm divide-y divide-slate-200 dark:divide-slate-700 pb-5">
                {/* Row */}
                {institutions?.length > 0 &&
                  institutions?.filter((record) => !record?.logoUrl).map((record) => (
                    <tr
                      onClick={(e) => {
                        e.stopPropagation();
                        setSelectedRow(record);
                        setIsModalOpen(true);
                      }}
                      className="border-b border-slate-300 hover:bg-[#A0CD850F] cursor-pointer"
                      key={record?.id}
                    >
                      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                        <div className="text-sm text-[#667085] leading-tight mr-1">
                          {moment.utc(record?.createdAt).format("MMM DD, YYYY, HH:mm:ss")}
                        </div>
                      </td>
                      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                        <div
                          className={`mx-auto py-2 rounded-[5px] text-sm leading-tight capitalize font-medium`}
                        >
                          {record?.name}
                        </div>
                      </td>
                      <td className="px-2 first:pl-5 last:pr-5 py-3">
                        <div className="text-sm text-[#667085] capitalize ">
                          <div className="w-[1.625rem] h-[1.625rem] cursor-pointer">
                            <svg
                                width="28"
                                height="28"
                                viewBox="0 0 28 28"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                onClick={() => {
                                setSelectedRow(record);
                                setIsModalOpen(true);
                                }}
                            >
                                <path
                                d="M10.1 26.667H17.9C24.4 26.667 27 24.067 27 17.567V9.76699C27 3.26699 24.4 0.666992 17.9 0.666992H10.1C3.6 0.666992 1 3.26699 1 9.76699V17.567C1 24.067 3.6 26.667 10.1 26.667Z"
                                fill="white"
                                stroke="#D0D5DD"
                                strokeOpacity="0.6"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                />
                                <path
                                d="M13.416 7.83398H12.2493C9.33268 7.83398 8.16602 9.00065 8.16602 11.9173V15.4173C8.16602 18.334 9.33268 19.5007 12.2493 19.5007H15.7493C18.666 19.5007 19.8327 18.334 19.8327 15.4173V14.2507"
                                stroke="#667085"
                                strokeOpacity="0.8"
                                stroke-width="1.3"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                />
                                <path
                                d="M16.3561 8.42905L11.7594 13.0257C11.5844 13.2007 11.4094 13.5449 11.3744 13.7957L11.1236 15.5515C11.0303 16.1874 11.4794 16.6307 12.1153 16.5432L13.8711 16.2924C14.1161 16.2574 14.4603 16.0824 14.6411 15.9074L19.2378 11.3107C20.0311 10.5174 20.4044 9.59572 19.2378 8.42905C18.0711 7.26238 17.1494 7.63572 16.3561 8.42905Z"
                                stroke="#667085"
                                strokeOpacity="0.8"
                                stroke-width="1.3"
                                stroke-miterlimit="10"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                />
                                <path
                                d="M15.6973 9.08789C16.0881 10.4821 17.1789 11.5729 18.5789 11.9696"
                                stroke="#858D9D"
                                stroke-miterlimit="10"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                />
                            </svg>
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
            </>
          )}
          {/* Second Table */}
          <h2 className="text-xl font-medium mt-1">All Institutions</h2>
          <table className="table-auto w-full dark:text-slate-300 divide-y divide-slate-200 dark:divide-slate-700">
            {/* Table header */}
            <thead className="text-sm text-[#757f93] font-medium dark:text-slate-400 shadow-md border-none">
              <tr>
                {/* <th className="px-2 first:pl-5 last:pr-5 py-4 whitespace-nowrap"></th> */}
                <th className="px-2 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                  <div className=" text-left font-medium">Date</div>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                  <div className=" text-left font-medium">Name</div>
                </th>
                <th className="px-5 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                  <div className=" text-left font-medium">Logo</div>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                  <div className=" text-left font-medium">Actions</div>
                </th>
              </tr>
            </thead>
            {/* Table body */}
            <tbody className="text-sm divide-y divide-slate-200 dark:divide-slate-700 pb-5">
              {/* Row */}
              {institutions?.length > 0 &&
                institutions.map((record) => (
                  <tr
                    onClick={(e) => {
                      e.stopPropagation();
                      setSelectedRow(record);
                      setIsModalOpen(true);
                    }}
                    className="border-b border-slate-300 hover:bg-[#A0CD850F] cursor-pointer"
                    key={record?.id}
                  >
                    <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                      <div className="text-sm text-[#667085] leading-tight mr-1">
                        {moment.utc(record?.createdAt).format("MMM DD, YYYY, HH:mm:ss")}
                      </div>
                    </td>
                    <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                      <div
                        className={`mx-auto py-2 rounded-[5px] text-sm leading-tight capitalize font-medium`}
                      >
                        {record?.name}
                      </div>
                    </td>
                    <td className="px-2 first:pl-5 last:pr-5 py-3">
                      <div className="text-sm text-[#667085] capitalize">
                        {record?.logoUrl && (
                          <img src={record?.logoUrl} alt="" className="w-10 h-10 rounded-full"/>
                        )}
                      </div>
                    </td>
                    <td className="px-2 first:pl-5 last:pr-5 py-3">
                      <div className="text-sm text-[#667085] capitalize ">
                        <div className="w-[1.625rem] h-[1.625rem] cursor-pointer">
                          <svg
                              width="28"
                              height="28"
                              viewBox="0 0 28 28"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              onClick={() => {
                              setSelectedRow(record);
                              setIsModalOpen(true);
                              }}
                          >
                              <path
                              d="M10.1 26.667H17.9C24.4 26.667 27 24.067 27 17.567V9.76699C27 3.26699 24.4 0.666992 17.9 0.666992H10.1C3.6 0.666992 1 3.26699 1 9.76699V17.567C1 24.067 3.6 26.667 10.1 26.667Z"
                              fill="white"
                              stroke="#D0D5DD"
                              strokeOpacity="0.6"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              />
                              <path
                              d="M13.416 7.83398H12.2493C9.33268 7.83398 8.16602 9.00065 8.16602 11.9173V15.4173C8.16602 18.334 9.33268 19.5007 12.2493 19.5007H15.7493C18.666 19.5007 19.8327 18.334 19.8327 15.4173V14.2507"
                              stroke="#667085"
                              strokeOpacity="0.8"
                              stroke-width="1.3"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              />
                              <path
                              d="M16.3561 8.42905L11.7594 13.0257C11.5844 13.2007 11.4094 13.5449 11.3744 13.7957L11.1236 15.5515C11.0303 16.1874 11.4794 16.6307 12.1153 16.5432L13.8711 16.2924C14.1161 16.2574 14.4603 16.0824 14.6411 15.9074L19.2378 11.3107C20.0311 10.5174 20.4044 9.59572 19.2378 8.42905C18.0711 7.26238 17.1494 7.63572 16.3561 8.42905Z"
                              stroke="#667085"
                              strokeOpacity="0.8"
                              stroke-width="1.3"
                              stroke-miterlimit="10"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              />
                              <path
                              d="M15.6973 9.08789C16.0881 10.4821 17.1789 11.5729 18.5789 11.9696"
                              stroke="#858D9D"
                              stroke-miterlimit="10"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              />
                          </svg>
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
          {institutions?.records?.length === 0 && (
            <h6 className="text-sm text-[#667085B2] text-center mt-4 pb-3">
              No Data Found
            </h6>
          )}
        </div>
      ) : (
        <p>Loading...</p>
      )}
      <div className="mt-8">
        {totalRecords > 0 && (
          <PaginationClassic
            pagination={{
              limit: +localLimit,
              page: 1,
              totalRecords: +totalRecords,
            }}
            onPageChange={loadInstitutions}
            setLimit={setLocalLimit}
          />
        )}
      </div>
    </div>
  );
};

export default AdminInstitution;
