import React, { useRef, useState } from 'react';
import useClickOutside from '../../utils/useClickOutside';
import Transition from '../../utils/Transition';
import { trimText } from '../../utils/Utils';

const TagsBoard = ({ tags, tagsCharLength }) => {
  const tagsBoardRef = useRef(null);
  const [tagsBoardOpen, setTagsBoardOpen] = useState(false);

  useClickOutside(tagsBoardRef, () => {
    setTagsBoardOpen(false);
  });

  return (
    <div className=" relative">
      <div
        onClick={(e) => {
          e.stopPropagation();
          if (!tagsBoardOpen) {
            setTagsBoardOpen(true);
          }
        }}
        role="button"
        tabIndex="0"
        className="ml-auto w-fit cursor-pointer h-full justify-end flex gap-1 items-center  min-h-[30px]   text-sm font-medium "
      >
        <div className="text-xs font-medium cursor-pointer  leading-tight  bg-[#6670851A]  px-2.5 py-1.5 rounded-md">
          {tags?.[0]?.name?.length > tagsCharLength
            ? trimText(tags?.[0]?.name, tagsCharLength)
            : tags?.[0]?.name}{' '}
        </div>
        {tags?.length > 1 && (
          <div className="text-xs font-medium cursor-pointer  leading-tight  bg-[#FCF1E9] text-indigo-500  px-2.5 py-1.5 rounded-md">
            {`${tags?.length - 1}+`}
          </div>
        )}
      </div>

      <Transition
        show={tagsBoardOpen}
        tag="div"
        className="z-60 absolute w-[260px] top-9 sm:right-0  bg-white dark:bg-slate-800 p-6 rounded shadow-button overflow-visible"
        enter="transition ease-out duration-100 transform"
        enterStart="opacity-0 -translate-y-2"
        enterEnd="opacity-100 translate-y-0"
        leave="transition ease-out duration-100"
        leaveStart="opacity-100"
        leaveEnd="opacity-0"
      >
        {tagsBoardOpen && (
          <div
            ref={tagsBoardRef}
            className="divide-y divide-slate-200 dark:divide-slate-700 z-60 "
          >
            <div className="">
              {/* header  */}
              <header className="flex justify-between items-center mb-6">
                <p className="text-base font-medium text-[#667085] leading-tight">
                  Tags
                </p>
                <button type="button" onClick={() => setTagsBoardOpen(false)}>
                  <svg
                    width="23"
                    height="23"
                    viewBox="0 0 23 23"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect
                      x="5.36328"
                      y="6.32031"
                      width="1.88235"
                      height="16"
                      rx="0.941177"
                      transform="rotate(-45 5.36328 6.32031)"
                      fill="#667085"
                    />
                    <rect
                      x="16.6787"
                      y="4.99219"
                      width="1.88235"
                      height="16"
                      rx="0.941177"
                      transform="rotate(45 16.6787 4.99219)"
                      fill="#667085"
                    />
                  </svg>
                </button>
              </header>

              <div className="flex flex-wrap gap-2.5 ">
                {tags?.map((tag) => (
                  <span
                    key={tag?.id}
                    className="text-xs rounded-[5px] font-medium text-[#667085] py-1 px-2.5  bg-[#6670851A] leading-tight"
                  >
                    {tag?.name}
                  </span>
                ))}
              </div>
            </div>
          </div>
        )}
      </Transition>
    </div>
  );
};

export default TagsBoard;
