import React, { useState } from 'react';
import ModalBasic from '../../../components/ModalBasic';
import { createAkahuUrl } from '../../../API/backend_helper';

const AkahuLinkModal = ({ isOpen, setIsOpen }) => {
  const [loading, setLoading] = useState(false);

  const onInitiateAkahu = async () => {
    setLoading(true);
    try {
      const response = await createAkahuUrl();
      if (response?.authenticationUrl) {
        const randomNumber = Math.floor(
          1000000000 + Math.random() * 9000000000,
        );
        localStorage.setItem('Akahu_Random_Number', randomNumber);
        window.location = `${response?.authenticationUrl}&state=${randomNumber}`;
      }
      setLoading(false);
    } catch (e) {
      console.log('error', e);
      setLoading(false);
    }
  };
  return (
    <ModalBasic
      id="basic-modal"
      modalOpen={isOpen}
      setModalOpen={setIsOpen}
      title={'Connect Account'}
      dialogClassName="!overflow-visible"
    >
      <div className="p-6">
        <div className="mb-6">
          Connect to your financial institution to automatically sync your bank
          accounts with COUNT. No more manual data entry! <br />
          Using Akahu, New Zealand's open finance platform, we fetch transaction
          data from your connected bank account daily to provide you with
          up-to-date information.
          <br />
          Akahu works with more than 50 Government, corporate, and fintech
          organisations.
          <br /> Learn{' '}
          <a
            href="https://www.akahu.nz/consumer"
            target="_blank"
            className="font-medium mr-px text-indigo-500 hover:text-indigo-600 dark:hover:text-indigo-400"
          >
            {' '}
            more about Akahu here
            <span className="px-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                width="16"
                zoomAndPan="magnify"
                viewBox="0 0 375 374.999991"
                height="16"
                preserveAspectRatio="xMidYMid meet"
                version="1.0"
                className="inline"
              >
                <defs>
                  <clipPath id="62409bbace">
                    <path
                      d="M 187.5 0 C 83.945312 0 0 83.945312 0 187.5 C 0 291.054688 83.945312 375 187.5 375 C 291.054688 375 375 291.054688 375 187.5 C 375 83.945312 291.054688 0 187.5 0 "
                      clip-rule="nonzero"
                    />
                  </clipPath>
                  <clipPath id="ea0cb58aa7">
                    <path
                      d="M 244 261.085938 L 273.738281 261.085938 L 273.738281 290.988281 L 244 290.988281 Z M 244 261.085938 "
                      clip-rule="nonzero"
                    />
                  </clipPath>
                  <clipPath id="5776c8b8e6">
                    <path
                      d="M 244 122 L 273.738281 122 L 273.738281 256.242188 L 244 256.242188 Z M 244 122 "
                      clip-rule="nonzero"
                    />
                  </clipPath>
                  <clipPath id="bad1f3b57c">
                    <path
                      d="M 139.292969 87.539062 L 238.941406 87.539062 L 238.941406 117.152344 L 139.292969 117.152344 Z M 139.292969 87.539062 "
                      clip-rule="nonzero"
                    />
                  </clipPath>
                  <clipPath id="533e54ff62">
                    <path
                      d="M 104.546875 157 L 134.449219 157 L 134.449219 290.988281 L 104.546875 290.988281 Z M 104.546875 157 "
                      clip-rule="nonzero"
                    />
                  </clipPath>
                  <clipPath id="96b73233d3">
                    <path
                      d="M 104.546875 122 L 134.449219 122 L 134.449219 152 L 104.546875 152 Z M 104.546875 122 "
                      clip-rule="nonzero"
                    />
                  </clipPath>
                </defs>
                <g clip-path="url(#62409bbace)">
                  <rect
                    x="-37.5"
                    width="450"
                    fill="#b484ff"
                    y="-37.499999"
                    height="449.999989"
                    fill-opacity="1"
                  />
                </g>
                <g clip-path="url(#ea0cb58aa7)">
                  <path
                    fill="#ffffff"
                    d="M 258.847656 261.402344 C 254.710938 261.402344 251.167969 262.839844 248.316406 265.6875 C 245.464844 268.53125 244.019531 272.066406 244.019531 276.195312 C 244.019531 280.324219 245.460938 283.863281 248.316406 286.699219 C 251.167969 289.535156 254.703125 290.988281 258.847656 290.988281 C 262.988281 290.988281 266.527344 289.546875 269.378906 286.703125 C 272.230469 283.855469 273.679688 280.324219 273.679688 276.195312 C 273.679688 272.066406 272.230469 268.542969 269.378906 265.6875 C 266.527344 262.832031 262.980469 261.402344 258.847656 261.402344 Z M 258.847656 261.402344 "
                    fill-opacity="1"
                    fill-rule="nonzero"
                  />
                </g>
                <g clip-path="url(#5776c8b8e6)">
                  <path
                    fill="#ffffff"
                    d="M 273.679688 137.109375 C 273.679688 132.988281 272.230469 129.457031 269.378906 126.601562 C 266.527344 123.746094 262.980469 122.3125 258.851562 122.3125 C 254.722656 122.3125 251.171875 123.753906 248.316406 126.605469 C 245.460938 129.449219 244.019531 132.984375 244.019531 137.109375 L 244.019531 241.425781 C 244.019531 245.570312 245.460938 249.089844 248.316406 251.929688 C 251.167969 254.769531 254.707031 256.21875 258.847656 256.21875 C 262.988281 256.21875 266.527344 254.773438 269.378906 251.929688 C 272.230469 249.089844 273.679688 245.550781 273.679688 241.425781 Z M 273.679688 137.109375 "
                    fill-opacity="1"
                    fill-rule="nonzero"
                  />
                </g>
                <path
                  fill="#ffffff"
                  d="M 223.996094 191.855469 L 154.289062 191.855469 C 150.152344 191.855469 146.605469 193.300781 143.753906 196.144531 C 140.902344 198.992188 139.460938 202.527344 139.460938 206.652344 C 139.460938 210.777344 140.902344 214.320312 143.753906 217.15625 C 146.605469 219.992188 150.144531 221.445312 154.289062 221.445312 L 223.996094 221.445312 C 228.132812 221.445312 231.675781 220.003906 234.527344 217.160156 C 237.375 214.316406 238.828125 210.777344 238.828125 206.652344 C 238.828125 202.527344 237.378906 198.996094 234.527344 196.144531 C 231.671875 193.296875 228.128906 191.855469 223.996094 191.855469 Z M 223.996094 191.855469 "
                  fill-opacity="1"
                  fill-rule="nonzero"
                />
                <g clip-path="url(#bad1f3b57c)">
                  <path
                    fill="#ffffff"
                    d="M 223.996094 87.539062 L 154.289062 87.539062 C 150.152344 87.539062 146.605469 88.984375 143.753906 91.832031 C 140.902344 94.675781 139.460938 98.199219 139.460938 102.34375 C 139.460938 106.488281 140.902344 110.011719 143.753906 112.851562 C 146.605469 115.691406 150.144531 117.148438 154.289062 117.148438 L 223.996094 117.148438 C 228.132812 117.148438 231.675781 115.703125 234.527344 112.859375 C 237.375 110.011719 238.828125 106.460938 238.828125 102.34375 C 238.828125 98.226562 237.378906 94.691406 234.527344 91.835938 C 231.671875 88.980469 228.128906 87.539062 223.996094 87.539062 Z M 223.996094 87.539062 "
                    fill-opacity="1"
                    fill-rule="nonzero"
                  />
                </g>
                <g clip-path="url(#533e54ff62)">
                  <path
                    fill="#ffffff"
                    d="M 129.964844 161.375 C 127.113281 158.527344 123.566406 157.085938 119.441406 157.085938 C 115.316406 157.085938 111.765625 158.527344 108.914062 161.375 C 106.0625 164.21875 104.605469 167.753906 104.605469 171.882812 L 104.605469 276.195312 C 104.605469 280.339844 106.054688 283.863281 108.902344 286.699219 C 111.75 289.535156 115.289062 290.988281 119.441406 290.988281 C 123.597656 290.988281 127.121094 289.546875 129.976562 286.703125 C 132.832031 283.855469 134.28125 280.324219 134.28125 276.195312 L 134.28125 171.882812 C 134.28125 167.773438 132.820312 164.21875 129.964844 161.375 Z M 129.964844 161.375 "
                    fill-opacity="1"
                    fill-rule="nonzero"
                  />
                </g>
                <g clip-path="url(#96b73233d3)">
                  <path
                    fill="#ffffff"
                    d="M 119.441406 122.3125 C 115.308594 122.3125 111.765625 123.753906 108.914062 126.605469 C 106.0625 129.449219 104.605469 132.984375 104.605469 137.109375 C 104.605469 141.230469 106.054688 144.78125 108.902344 147.613281 C 111.75 150.449219 115.289062 151.90625 119.441406 151.90625 C 123.597656 151.90625 127.121094 150.464844 129.976562 147.613281 C 132.832031 144.765625 134.28125 141.234375 134.28125 137.109375 C 134.28125 132.980469 132.835938 129.457031 129.980469 126.601562 C 127.125 123.746094 123.566406 122.3125 119.441406 122.3125 Z M 119.441406 122.3125 "
                    fill-opacity="1"
                    fill-rule="nonzero"
                  />
                </g>
              </svg>
            </span>
          </a>
        </div>
        <div className="flex justify-center ">
          <button
            disabled={loading}
            onClick={onInitiateAkahu}
            className="btn text-base font-normal !bg-indigo-500 hover:!bg-indigo-600 text-white"
          >
            {loading && (
              <svg
                className="animate-spin w-4 h-4 fill-current shrink-0 mr-2"
                viewBox="0 0 16 16"
              >
                <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z" />
              </svg>
            )}
            Connect Account
          </button>
        </div>
      </div>
    </ModalBasic>
  );
};

export default AkahuLinkModal;
