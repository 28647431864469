import React from 'react';
import { Navigate, Route } from 'react-router-dom';
import { IS_PERSON } from '../utils/Utils';

const PersonAuthProtected = ({ children, location }) => {
  const token = localStorage.getItem('accessToken');
  const isPerson = localStorage.getItem(IS_PERSON)
    ? JSON.parse(localStorage.getItem(IS_PERSON))
    : null;

  if (!token || !isPerson) {
    return (
      <Navigate
        to={{ pathname: '/person/signin', state: { from: location } }}
      />
    );
  }

  return children;
};

const PersonAccessRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => (
      <>
        <Component {...props} />{' '}
      </>
    )}
  />
);

export { PersonAuthProtected, PersonAccessRoute };
