import React, { useEffect } from 'react';
import { Navigate, Route } from 'react-router-dom';
import {
  IS_ACCOUNTANT,
  IS_PERSON,
  STAFF,
  SUPPORT,
  TEAM_ID,
} from '../utils/Utils';
import { useSelector } from 'react-redux';
// import { setAuthorization } from "../helpers/api_helper";
// import { useDispatch } from "react-redux";

// import { useProfile } from "../Components/Hooks/UserHooks";

// import { logoutUser } from "../slices/auth/login/thunk";

const AuthProtected = (props) => {
  // const dispatch = useDispatch();
  const token = localStorage.getItem('accessToken');
  const teamId = localStorage.getItem(TEAM_ID);
  const isPerson = localStorage.getItem(IS_PERSON)
    ? JSON.parse(localStorage.getItem(IS_PERSON))
    : null;
  const { localUser } = useSelector((state) => state.User);
  const IsAccountant = localStorage.getItem(IS_ACCOUNTANT)
    ? JSON.parse(localStorage.getItem(IS_ACCOUNTANT))
    : null;

  // useEffect(() => {
  // if (userProfile && !loading && token) {
  //   setAuthorization(token);
  // }
  //  else if (!userProfile && loading && !token) {
  //   dispatch(logoutUser());
  // }
  // }, [token]);

  /*
    Navigate is un-auth access protected routes via url
    */

  // if (!userProfile && loading && !token) {

  if (token && isPerson) {
    return <Navigate to="/person/invoices?tab=invoices" />;
  }

  if (!token) {
    return (
      <Navigate to={{ pathname: '/signin', state: { from: props.location } }} />
    );
  }

  if (
    !teamId &&
    props?.path !== '/workspace' &&
    !props?.path?.includes('/manage-workspaces') &&
    !props?.path?.includes('/accountant') &&
    !props?.path?.includes('/practice/:id') &&
    props?.path !== '/workspace/:type' &&
    props?.path !== '/practice/:id' &&
    localUser?.realm !== STAFF &&
    localUser?.realm !== SUPPORT &&
    !(localUser?.teams?.length > 0)
  ) {
    return (
      <Navigate
        to={{
          pathname: IsAccountant ? '/accountant' : '/manage-workspaces',
          state: { from: props.location },
        }}
      />
    );
  }

  if (
    !teamId &&
    !props?.path?.includes('/manage-workspaces') &&
    !props?.path?.includes('/accountant') &&
    localUser?.teams?.length > 0 &&
    props?.path !== '/workspace/:type'
  ) {
    return (
      <Navigate
        to={{
          pathname: IsAccountant ? '/accountant' : '/manage-workspaces',
          state: { from: props.location },
        }}
      />
    );
  }

  return <>{props.children}</>;
};

const AccessRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        return (
          <>
            {' '}
            <Component {...props} />{' '}
          </>
        );
      }}
    />
  );
};

export { AuthProtected, AccessRoute };