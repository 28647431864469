import React from 'react';
import { Routes, Route } from 'react-router-dom';

// routes
import { authProtectedRoutes, personRoutes, publicRoutes } from './allRoutes';
import { AuthProtected } from './AuthProtected';
import { PersonAuthProtected } from './PersonAuthProtected';
import { PublicRoute } from './Public';

const Index = ({ isAuthenticated }) => (
  <Routes>
    <Route>
      {publicRoutes.map((route, idx) => (
        <Route
          path={route.path}
          element={
            <PublicRoute path={route.path}>{route.component}</PublicRoute>
          }
          key={idx}
          exact
        />
      ))}
    </Route>

    <Route>
      {personRoutes.map((route, idx) => (
        <Route
          path={route.path}
          element={
            <PersonAuthProtected
              isAuthenticated={isAuthenticated}
              path={route.path}
            >
              {route.component}
              {/* <VerticalLayout>{route.component}</VerticalLayout> */}
            </PersonAuthProtected>
          }
          key={idx}
          exact
        />
      ))}
    </Route>

    <Route>
      {authProtectedRoutes.map((route, idx) => (
        <Route
          path={route.path}
          element={
            <AuthProtected isAuthenticated={isAuthenticated} path={route.path}>
              {route.component}
              {/* <VerticalLayout>{route.component}</VerticalLayout> */}
            </AuthProtected>
          }
          key={idx}
          exact
        />
      ))}
    </Route>
  </Routes>
);

export default Index;
