import React, { useEffect, useMemo, useRef, useState } from 'react';
import ModalBasic from '../../components/ModalBasic';

import ExpenseIncomeTransaction from './CreateTransaction/ExpenseIncomeTransaction';
import TransferTransaction from './CreateTransaction/TransferTransaction';
import {
  Expense,
  Income,
  Transfer,
  TEAM_ID,
  getCurrencySymbolFromIso,
} from '../../utils/Utils';
import { all } from 'axios';
import { Loader } from '../../components/Svg';
const AddTransactionModal = ({
  isOpen,
  setIsOpen,
  allCategories,
  accounts,
  allAccounts,
  getTransactionsApi,
  vendors,
  setVendors,
  transType,
  setTransType,
  currencies,
  team,
  customers,
  setCustomers,
  allExpenseCategories,
  accountId,
  minDate,
  maxDate,
  projects,
}) => {
  const [filteredCategories, setFilteredCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const teamCurrency = useMemo(() => {
    let currency = '--';
    if (team?.currency && currencies?.length > 0) {
      currency = currencies?.find?.(
        (currency) => currency?.isoCode === team?.currency,
      );
    }
    return currency;
  }, [team, currencies]);

  useEffect(() => {
    if (allCategories?.length > 0) {
      let sortedCategories = [...allCategories];

      if (
        transType === Income ||
        transType === Expense ||
        transType === Transfer
      ) {
        sortedCategories.sort((a, b) => {
          if (a.type === b.type) {
            return 0;
          } else if (transType === Income) {
            return a.type === Income ? -1 : 1;
          } else {
            return a.type === Expense ? -1 : 1;
          }
        });
        sortedCategories = sortedCategories.filter(
          (category) => category.type !== Transfer,
        );
        setFilteredCategories(sortedCategories);
      }
      {
        /*else if (transType === Transfer) {
        const cat = allCategories.filter(c => c.type === "Transfer");
        const updatedCat = cat.map(group => {
          if (group.name === "Transfers") {
            const updatedCategories = group.categories.filter(category => category.name !== "Transfer In");
            const finalCategories = updatedCategories.map(category => {
              if (category.name === "Transfer Out") {
                return { ...category, name: "Transfer Between Two Accounts" };
              }
              return category;
            });
            return { ...group, categories: finalCategories };
          }
          return group;
        });
        setFilteredCategories(updatedCat);

      } else {
        const cat = allCategories?.filter((c) => c?.type === transType);
        setFilteredCategories(cat);
      }
      */
      }
    }
  }, [allCategories, transType]);

  return (
    <ModalBasic
      modalOpen={isOpen}
      setModalOpen={setIsOpen}
      title={`Add ${
        transType.charAt(0).toUpperCase() + transType.slice(1)
      } Transaction`}
      width="!w-[45.125rem]"
    >
      {/* Modal content */}
      <div className="pt-[1.875rem] bg-white">
        <div className="relative mb-6 mx-10">
          <div
            className="absolute bottom-0 w-full h-0.5 bg-gray-300 bg-opacity-50"
            aria-hidden="true"
          ></div>
          <ul className="relative text-sm font-medium flex flex-nowrap -mx-4 sm:-mx-6 lg:-mx-8 overflow-x-scroll no-scrollbar">
            <li
              className="mr-6 last:mr-0 first:pl-4 sm:first:pl-6 lg:first:pl-8 last:pr-4 sm:last:pr-6 lg:last:pr-8"
              onClick={() => setTransType(Income)}
            >
              <span
                className={`cursor-pointer block pb-3 text-black whitespace-nowrap ${
                  transType === Income
                    ? 'border-b-2 border-indigo-500'
                    : 'text-slate-500 dark:text-slate-400 hover:text-slate-600 dark:hover:text-slate-300'
                }`}
              >
                Income
              </span>
            </li>
            <li
              className="mr-6 last:mr-0 first:pl-4 sm:first:pl-6 lg:first:pl-8 last:pr-4 sm:last:pr-6 lg:last:pr-8"
              onClick={() => setTransType(Expense)}
            >
              <span
                className={`cursor-pointer block pb-3 text-black whitespace-nowrap ${
                  transType === Expense
                    ? 'border-b-2 border-indigo-500'
                    : 'text-slate-500 dark:text-slate-400 hover:text-slate-600 dark:hover:text-slate-300'
                }`}
              >
                Expense
              </span>
            </li>
            <li
              className="mr-6 last:mr-0 first:pl-4 sm:first:pl-6 lg:first:pl-8 last:pr-4 sm:last:pr-6 lg:last:pr-8"
              onClick={() => setTransType(Transfer)}
            >
              <span
                className={`cursor-pointer block pb-3 text-black whitespace-nowrap ${
                  transType === Transfer
                    ? 'border-b-2 border-indigo-500'
                    : 'text-slate-500 dark:text-slate-400 hover:text-slate-600 dark:hover:text-slate-300'
                }`}
              >
                Transfer
              </span>
            </li>
          </ul>
        </div>
        {isLoading ? (
          <div>
            <Loader />
          </div>
        ) : (
          <>
            {transType === Expense || transType === Income ? (
              <ExpenseIncomeTransaction
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                allCategories={filteredCategories}
                accounts={accounts}
                allAccounts={allAccounts}
                getTransactionsApi={getTransactionsApi}
                vendors={vendors}
                setVendors={setVendors}
                type={transType}
                teamCurrency={teamCurrency}
                currencies={currencies}
                customers={customers}
                setCustomers={setCustomers}
                allExpenseCategories={allExpenseCategories}
                accountId={accountId}
                minDate={minDate}
                maxDate={maxDate}
                projects={projects}
              />
            ) : (
              <TransferTransaction
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                allCategories={filteredCategories}
                accounts={accounts}
                allAccounts={allAccounts}
                getTransactionsApi={getTransactionsApi}
                vendors={vendors}
                setVendors={setVendors}
                type={transType}
                teamCurrency={teamCurrency}
                currencies={currencies}
                accountId={accountId}
              />
            )}
          </>
        )}
      </div>
    </ModalBasic>
  );
};

export default AddTransactionModal;
