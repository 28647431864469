import React, { useEffect, useState } from 'react';

import moment from 'moment';
import AlertBox from '../../components/AlertBox';

function transformData(input) {
  // Deep copy the input object
  const deepCopy = JSON.parse(JSON.stringify(input));

  // Get the last job's itemId
  const lastJob = deepCopy.employee.jobs[deepCopy.employee.jobs.length - 1];
  const lastJobItemId = lastJob.itemId;
  const lastCompensation = lastJob.compensations[0]; // Assuming there's at least one compensation
  // Initialize the result object
  const result = {
    name: `${deepCopy?.employee?.firstName} ${deepCopy?.employee?.lastName}`,
    job_uuid: lastJobItemId,
    paid_time_off: deepCopy.paid_time_off,
    employee_uuid: deepCopy?.employee_uuid,
    compensations: lastCompensation,
    payment_method: deepCopy?.payment_method,
    regular_hours: null,
    overtime: null,
    double_overtime: null,
    vacation_hours: null,
    sick_hours: null,
  };

  // Map the hourly_compensations to the appropriate keys
  deepCopy.hourly_compensations.forEach((compensation) => {
    const tempComp = { ...compensation };
    tempComp.hours =
      parseFloat(compensation?.hours) === 0
        ? parseFloat(compensation?.hours)?.toFixed(0)
        : parseFloat(compensation?.hours)?.toFixed(2);
    if (compensation.name === 'Regular Hours') {
      result.regular_hours = tempComp;
    } else if (compensation.name === 'Overtime') {
      result.overtime = tempComp;
    } else if (compensation.name === 'Double overtime') {
      result.double_overtime = tempComp;
    }
  });

  // Map the paid_time_off to the appropriate keys
  deepCopy.paid_time_off.forEach((paidTimeOff) => {
    const tempTimeOff = { ...paidTimeOff };
    tempTimeOff.hours =
      parseFloat(tempTimeOff?.hours) === 0
        ? parseFloat(tempTimeOff?.hours)?.toFixed(0)
        : parseFloat(tempTimeOff?.hours)?.toFixed(2);
    if (paidTimeOff.name === 'Vacation Hours') {
      result.vacation_hours = tempTimeOff;
    } else if (paidTimeOff.name === 'Sick Hours') {
      result.sick_hours = tempTimeOff;
    }
  });

  return result;
}

const PayrollStep2 = ({
  setActiveStep,
  preparedPayroll,
  setPreparedPayroll,
  onSubmit,
  loading,
}) => {
  const [localData, setLocalData] = useState([]);

  const settleObject = () => {
    const temp = JSON.parse(JSON.stringify(preparedPayroll));
    localData?.forEach((newEmp) => {
      const index = temp?.employee_compensations?.findIndex(
        (emp) => emp?.employee_uuid === newEmp?.employee_uuid,
      );
      if (index >= 0) {
        const employee = temp?.employee_compensations?.[index];
        const hourlyCompensation = [];
        const paidTimeOff = [];
        if (newEmp?.regular_hours) {
          hourlyCompensation?.push(newEmp?.regular_hours);
        }
        if (newEmp?.overtime) {
          hourlyCompensation?.push(newEmp?.overtime);
        }
        if (newEmp?.double_overtime) {
          hourlyCompensation?.push(newEmp?.double_overtime);
        }
        employee.hourly_compensations = hourlyCompensation;
        if (newEmp?.sick_hours) {
          paidTimeOff?.push(newEmp?.sick_hours);
        }
        if (newEmp?.vacation_hours) {
          paidTimeOff?.push(newEmp?.vacation_hours);
        }
        employee.paid_time_off = paidTimeOff;
      }
    });
    setPreparedPayroll(temp);
    onSubmit(temp);
  };

  useEffect(() => {
    const result = [];
    if (preparedPayroll?.employee_compensations?.length > 0) {
      preparedPayroll?.employee_compensations?.forEach((employee) => {
        if (
          employee?.employee?.jobs?.[employee?.employee?.jobs?.length - 1]
            ?.compensations?.[
            employee?.employee?.jobs?.[employee?.employee?.jobs?.length - 1]
              ?.compensations?.length - 1
          ]?.paymentUnit === 'Hour'
        ) {
          result?.push(transformData(employee));
        }
      });
      setLocalData(result);
    }
  }, [preparedPayroll?.employee_compensations]);

  const onChangeHours = (index, key, value) => {
    const temp = JSON.parse(JSON.stringify(localData));
    temp[index][key].hours = value;
    setLocalData(temp);
  };

  const calculatePay = (obj) => {
    const rate = parseFloat(obj?.compensations?.rate || 0);
    const regularPay =
      parseFloat(obj?.regular_hours?.hours || 0) *
      parseFloat(obj?.regular_hours?.compensation_multiplier) *
      rate;
    const overtimePay =
      parseFloat(obj?.overtime?.hours || 0) *
      parseFloat(obj?.overtime?.compensation_multiplier) *
      rate;

    const doubleOvertimePay =
      parseFloat(obj?.double_overtime?.hours || 0) *
      parseFloat(obj?.double_overtime?.compensation_multiplier) *
      rate;

    const PtoPay = parseFloat(obj?.vacation_hours?.hours || 0) * rate;

    const sickPay = parseFloat(obj?.sick_hours?.hours || 0) * rate;

    const sum = regularPay + overtimePay + doubleOvertimePay + PtoPay + sickPay;

    return sum;
  };

  return (
    <div className="flex flex-col gap-10">
      {preparedPayroll?.payroll_status_meta?.payroll_late && (
        <AlertBox
          type="warning"
          heading="This Payroll is late"
          text={`Your original pay date was ${moment(preparedPayroll?.payroll_deadline?.split('T')?.[0]).format('DD MMM, YYYY')}. If  you run payroll by 4:00pm PDT today, the team will be paid on ${moment(
            preparedPayroll?.payroll_status_meta?.expected_check_date,
          ).format('DD MMM, YYYY')}.`}
          hideActionButton
        />
      )}
      <div className="flex gap-4 items-center">
        <div className="flex">
          <div className="flex flex-col w-1/6 min-w-[225px] h-20">
            <p>Run by </p>
            {moment(
              preparedPayroll?.payroll_status_meta?.expected_debit_time?.split(
                'T',
              )?.[0],
            )
              ?.local()
              ?.format('DD MMM, YYYY')}
          </div>
          <div className="flex flex-col w-1/6 min-w-[225px] h-20">
            <p>Payday On</p>
            {moment(
              preparedPayroll?.payroll_status_meta?.expected_check_date,
            ).format('DD MMM, YYYY')}
          </div>
        </div>
        {/* <div>
          <div className="flex flex-col w-1/6 min-w-[225px] h-20">
            <p>Total time off hours</p>
            {moment().format('DD MMM, YYYY')}
          </div>
          <div className="flex flex-col w-1/6 min-w-[225px] h-20">
            <p>Total hours worked</p>
            {moment().format('DD MMM, YYYY')}
          </div>
          <div className="flex flex-col w-1/6 min-w-[225px] h-20">
            <p>Total earnings</p>
            {moment().format('DD MMM, YYYY')}
          </div>
        </div> */}
      </div>
      <h1 className="text-2xl  text-[#101828B2] dark:text-slate-100 font-medium">
        Confirm Hourly Employee Pay
      </h1>
      {/* Table */}
      <div className="w-full items-start flex overflow-x-auto scrollbar min-h-[20dvh]">
        <table className="min-w-max w-full flex flex-col items-start">
          <thead className="flex w-full gap-1 items-center justify-around rounded-[0.313rem] shadow-md bg-white min-h-[3.5rem] mb-1">
            {/*  <th className="text-slate-600 text-left text-[14px] font-medium leading-[1.125rem] w-[80px] flex-shrink-0 flex-grow-0 whitespace-nowrap">
              <div className="flex items-center gap-1">
                <label className="inline-flex ">
                  <span className="sr-only">Select</span>
                  <input
                    id={1}
                    className="form-checkbox cursor-pointer h-5 w-5"
                    type="checkbox"
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                    onChange={() => {}}
                    checked={true}
                  />
                </label>
                Select All
              </div>
            </th> */}
            <th className="text-slate-600 text-left text-[14px] font-medium leading-[1.125rem] w-[150px] flex-shrink-0 flex-grow-0 whitespace-nowrap">
              Employee
            </th>
            <th className="text-slate-600 text-left text-[14px] font-medium leading-[1.125rem] w-[100px] flex-shrink-0 flex-grow-0 whitespace-nowrap ">
              Rate
            </th>
            <th className="text-slate-600 text-left text-[14px] font-medium leading-[1.125rem] w-[100px] flex-shrink-0 flex-grow-0 whitespace-nowrap ">
              Regular hrs
            </th>
            <th className="text-slate-600 text-left text-[14px] font-medium leading-[1.125rem] w-[100px] flex-shrink-0 flex-grow-0 whitespace-nowrap ">
              Overtime hrs
            </th>
            <th className="text-slate-600 text-left text-[14px] font-medium leading-[1.125rem] w-[100px] flex-shrink-0 flex-grow-0  ">
              Double overtime hrs
            </th>
            <th className="text-slate-600 text-left text-[14px] font-medium leading-[1.125rem] w-[100px] flex-shrink-0 flex-grow-0 whitespace-nowrap ">
              PTO hrs
            </th>
            <th className="text-slate-600 text-left text-[14px] font-medium leading-[1.125rem] w-[100px] flex-shrink-0 flex-grow-0 whitespace-nowrap ">
              Sick hrs
            </th>
            <th className="text-slate-600 text-left text-[14px] font-medium leading-[1.125rem] w-[100px] flex-shrink-0 flex-grow-0 whitespace-nowrap ">
              Total Pay
            </th>
            <th className="text-slate-600 text-left text-[14px] font-medium leading-[1.125rem] w-[100px] flex-shrink-0 flex-grow-0 whitespace-nowrap ">
              Payment Method
            </th>
            {/* <th className="text-slate-600 text-left text-[14px] font-medium leading-[1.125rem] w-[100px] flex-shrink-0 flex-grow-0 whitespace-nowrap ">
              Actions
            </th> */}
          </thead>
          {localData?.length > 0 && (
            <tbody className="min-w-max w-full flex flex-col items-start">
              {localData?.map((employee, i) => (
                <tr
                  key={employee?.id}
                  className="flex w-full items-center justify-around gap-1  bg-white min-h-[3.5rem] border-b border-[#D0D5DD] hover:bg-[#a0cd85] hover:bg-opacity-5"
                >
                  <td className="text-left text-[14px] font-medium leading-[1.125rem] w-[150px] flex-shrink-0 flex-grow-0 whitespace-nowrap">
                    <p>{employee?.name}</p>
                    <p className="text-[10px] font-semibold">Hour</p>
                  </td>

                  <td className="text-left text-[14px] font-medium leading-[1.125rem] w-[100px] flex-shrink-0 flex-grow-0 whitespace-nowrap">
                    $
                    {parseFloat(employee?.compensations?.rate)?.toLocaleString(
                      undefined,
                      {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      },
                    )}
                  </td>
                  <td className="text-left text-[14px] font-medium leading-[1.125rem] w-[100px] flex-shrink-0 flex-grow-0 whitespace-nowrap">
                    <input
                      value={employee?.regular_hours?.hours}
                      disabled={!employee?.regular_hours?.job_uuid}
                      placeholder="Regular hrs"
                      className="form-input w-[80px]"
                      onChange={(e) =>
                        onChangeHours(i, 'regular_hours', e.target.value)
                      }
                    />
                  </td>
                  <td className="text-left text-[14px] font-medium leading-[1.125rem] w-[100px] flex-shrink-0 flex-grow-0 whitespace-nowrap">
                    <input
                      value={employee?.overtime?.hours}
                      disabled={!employee?.overtime?.job_uuid}
                      placeholder="Overtime hrs"
                      className="form-input w-[80px]"
                      onChange={(e) =>
                        onChangeHours(i, 'overtime', e.target.value)
                      }
                    />
                  </td>
                  <td className="text-left text-[14px] font-medium leading-[1.125rem] w-[100px] flex-shrink-0 flex-grow-0 whitespace-nowrap">
                    <input
                      value={employee?.double_overtime?.hours}
                      disabled={!employee?.double_overtime?.job_uuid}
                      placeholder="Double overtime hrs"
                      className="form-input w-[80px]"
                      onChange={(e) =>
                        onChangeHours(i, 'double_overtime', e.target.value)
                      }
                    />
                  </td>
                  <td className="text-left text-[14px] font-medium leading-[1.125rem] w-[100px] flex-shrink-0 flex-grow-0 whitespace-nowrap">
                    <input
                      value={employee?.vacation_hours?.hours}
                      disabled={!employee?.vacation_hours?.name}
                      placeholder=" PTO hrs"
                      className="form-input w-[80px]"
                      onChange={(e) =>
                        onChangeHours(i, 'vacation_hours', e.target.value)
                      }
                    />
                  </td>
                  <td className="text-left text-[14px] font-medium leading-[1.125rem] w-[100px] flex-shrink-0 flex-grow-0 whitespace-nowrap">
                    <input
                      value={employee?.sick_hours?.hours}
                      disabled={!employee?.sick_hours?.name}
                      placeholder="Sick hrs"
                      className="form-input w-[80px]"
                      onChange={(e) =>
                        onChangeHours(i, 'sick_hours', e.target.value)
                      }
                    />
                  </td>
                  <td className="text-left text-[14px] font-medium leading-[1.125rem] w-[100px] flex-shrink-0 flex-grow-0 whitespace-nowrap">
                    $
                    {calculatePay(employee)?.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </td>
                  <td className="text-left text-[14px] font-medium leading-[1.125rem] w-[100px] flex-shrink-0 flex-grow-0 whitespace-nowrap">
                    {employee?.payment_method}
                  </td>
                  {/* <td className="text-left text-[14px] font-medium leading-[1.125rem] w-[100px] flex-shrink-0 flex-grow-0 whitespace-nowrap">
                    <ActionsDropdown />
                  </td> */}
                </tr>
              ))}
            </tbody>
          )}
        </table>
      </div>
      <div className="w-full flex justify-between">
        <button
          className="bg-white h-10 border border-indigo-500 rounded-[0.313rem] px-[1.125rem] py-2.5 shadow text-indigo-500 text-base leading-6 flex items-center justify-center gap-2 font-normal"
          onClick={() => {
            setActiveStep(1);
          }}
          type="button"
        >
          Back
        </button>
        <button
          className="bg-indigo-500 h-10 border border-indigo-500 rounded-[0.313rem] px-[1.125rem] py-2.5 shadow text-white text-base leading-6 flex items-center justify-center gap-2 font-normal
          disabled:!bg-indigo-300 disabled:text-white disabled:cursor-not-allowed"
          onClick={settleObject}
          disabled={!preparedPayroll || loading}
          type="button"
        >
          {loading && (
            <svg
              className="animate-spin w-4 h-4 fill-current shrink-0 mr-2"
              viewBox="0 0 16 16"
            >
              <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z" />
            </svg>
          )}
          Next
        </button>
      </div>
    </div>
  );
};

export default PayrollStep2;
