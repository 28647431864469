import React, { useEffect, useRef, useState } from 'react';
import ModalBasic from '../../components/ModalBasic';
import PlaidLink from '../settings/PlaidLink';
import { createPlaidToken, getPopularBanks } from '../../API/backend_helper';
import PlaidConnect from '../../images/plaid-connect.png';

const InstitutionsModal = ({
  isOpen,
  setIsOpen,
  loadData,
  team,
  setIsLoading,
}) => {
  const PlaidButtonRef = useRef();

  const [token, setToken] = useState(null);
  const [banks, setBanks] = useState([]);

  const getPopularBanksApi = async () => {
    try {
      const response = await getPopularBanks();
      setBanks(response);
    } catch (e) {
      console.log('error', e);
    }
  };

  useEffect(() => {
    getPopularBanksApi();
  }, []);

  const onBankClick = async (bank) => {
    try {
      const response = await createPlaidToken(
        null,
        {},
        bank?.routingNumbers?.[0] || '',
      );
      const { linkToken } = response;
      setToken(linkToken);
      setTimeout(() => {
        PlaidButtonRef.current.click();
      }, 1000);
    } catch (e) {
      console.log('error', e);
    }
  };

  return (
    <ModalBasic
      modalOpen={isOpen}
      setModalOpen={setIsOpen}
      title="Connect Institution"
      width="!w-[1022px] max-w-[90vw]"
    >
      <div className="py-4 sm:py-9 px-9 sm:px-[56px]">
        <p className="text-[18px] font-medium text-[#667085] leading-tight mb-3">
          Popular Banks
        </p>
        <div className="grid grid-cols-12 gap-5 gap-y-3">
          {banks?.map((bank, index) => (
            <div
              key={index}
              onClick={() => onBankClick(bank)}
              className="col-span-full sm:col-span-6 lg:col-span-4 border border-[#BBBFC6] rounded-[6px] flex items-center p-1 min-h-[60px] gap-2.5 cursor-pointer"
              tabIndex="0"
              role="button"
              onKeyDown={null}
            >
              <div
                className={`w-10 h-10  shrink-0 rounded-full  mr-2 ml-4
                          ${
                            bank?.color && !bank?.logoUrl
                              ? 'bg-gradient-to-tr from-slate-500 to-slate-300'
                              : ''
                          }
                          `}
                style={
                  bank?.color && !bank?.logoUrl
                    ? { background: bank?.color }
                    : {}
                }
              >
                {bank?.logoUrl ? (
                  <img
                    src={`${bank?.logoUrl}`}
                    alt="logo"
                    className="w-10 h-10 fill-current text-white"
                  />
                ) : (
                  <div className="flex items-center justify-center w-10 h-10">
                    <div
                      style={{ textTransform: 'uppercase' }}
                      className="text-white font-bold text-lg "
                    >
                      {bank?.name?.charAt?.(0)}
                    </div>
                  </div>
                )}
              </div>

              <p className="text-[18px] text-[#667085] leading-tight">
                {bank?.name}
              </p>
            </div>
          ))}
        </div>
        <button
          className="mt-9 flex gap-2.5 items-center p-4 border border-indigo-500 rounded-[6px] w-full min-h-[94px]"
          onClick={onBankClick}
          type="button"
        >
          <img className="h-9" src={PlaidConnect} alt="plaid" />
          <div>
            <p className="text-left text-[18px] font-semibold text-[#667085] leading-tight mb-[2px]">
              Do not see your institution above?
            </p>
            <p className="text-sm text-[#667085] text-left">
              Connect to other banks securely via Plaid
            </p>
          </div>
        </button>
        <PlaidLink
          loadData={loadData}
          setIsLoading={setIsLoading}
          token={token}
          team={team}
          accountPage
          buttonRef={PlaidButtonRef}
          cb={() => setIsOpen(false)}
          shouldNavigate
        />
      </div>
    </ModalBasic>
  );
};

export default InstitutionsModal;
