import React, { useState } from 'react';
import {
  PaymentElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import { toast } from 'react-toastify';

export default function InvoiceCheckoutForm({
  details,
  currencySymbol,
  setSuccess,
  amountPay,
  getInvoiceIntentApi,
  setSuccessLoader,
  invoiceId,
}) {
  const stripe = useStripe();
  const elements = useElements();

  const [isLoading, setIsLoading] = useState(false);

  const onPayCallBack = async () => {
    // const cardElement = elements.getElement(CardElement);
    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      toast.error('Stripe did not load');
      return;
    }
    const res = await stripe.confirmPayment({
      // `Elements` instance that was used to create the Payment Element
      elements,
      redirect: 'if_required',
      confirmParams: {
        // return_url: 'https://example.com/order/123/complete',
      },
    });
    const { error } = res;

    // const res = await stripe.confirmPayment({
    //   type: "card",
    //   card: cardElement,
    // });
    // if (res?.paymentMethod?.id) {
    if (error) {
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Show error to your customer (for example, payment
      // details incomplete)
      // setErrorMessage(error.message);
      setIsLoading(false);
      toast.error('Please Check Your Card Information Again');
    } else {
      toast.success('Your payment was successful');
      setSuccessLoader(true);
      setTimeout(() => getInvoiceIntentApi(invoiceId, true), 5000);
      setSuccess(true);
      setIsLoading(false);

      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      //
      //   try{
      //   const resSubscribe = await stripeSubsMethod({
      //     methodId: res?.paymentMethod?.id,
      //     customer,
      //   });
      //   if (resSubscribe?.data) {
      //     loadSubscriptions();
      //     loadData();
      //     setIsLoading(false);
      //     toast.success("Your subscription was successful");
      //   } else {
      //     setIsLoading(false);
      //     toast.error(
      //       "An issue has occurred. Please reach out to support for assistance."
      //     );
      //   }
      // }catch(e){
      //   setIsLoading(false);
      //   console.log("error",e);

      // }
      // }
    }
  };

  const getClientSecret = async () => {
    try {
      onPayCallBack();
    } catch (e) {
      setIsLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    getClientSecret();
  };

  return (
    <form id="payment-form" className="p-4 " onSubmit={handleSubmit}>
      <div className="border border-[#D0D5DD] h-100 pt-3 px-2 pb-3">
        {/* <Elements
          options={cardElemoptionsentOpts}
          className="pay2-main2-input-1"

        /> */}
        <PaymentElement />
      </div>
      <button
        className="h-[53px] w-full text-white rounded-[6px] inline-flex justify-center items-center gap-2 text-[18px] font-semibold mt-3 mb-0"
        style={{
          background: details?.invoice?.color
            ? `#${details?.invoice?.color}`
            : '#0C909A',
        }}
        disabled={isLoading || !stripe || !elements}
        id="submit"
        type="submit"
      >
        {isLoading ? (
          <svg
            className="animate-spin w-4 h-4 fill-current shrink-0 mr-2"
            viewBox="0 0 16 16"
          >
            <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z" />
          </svg>
        ) : (
          <svg
            width="18"
            height="17"
            viewBox="0 0 18 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13.6576 7.79297H3.74089C2.95848 7.79297 2.32422 8.42723 2.32422 9.20964V14.168C2.32422 14.9504 2.95848 15.5846 3.74089 15.5846H13.6576C14.44 15.5846 15.0742 14.9504 15.0742 14.168V9.20964C15.0742 8.42723 14.44 7.79297 13.6576 7.79297Z"
              stroke="white"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M5.1582 7.79297V4.95964C5.1582 4.02033 5.53134 3.11949 6.19553 2.4553C6.85972 1.79111 7.76056 1.41797 8.69987 1.41797C9.63918 1.41797 10.54 1.79111 11.2042 2.4553C11.8684 3.11949 12.2415 4.02033 12.2415 4.95964V7.79297"
              stroke="white"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        )}
        <span className="text-[18px] font-semibold">
          Pay {currencySymbol}
          {parseFloat(amountPay || 0)?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </span>
      </button>
      {/* Show any error or success messages */}
    </form>
  );
}
