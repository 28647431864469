import React, { useState } from 'react';
import { DateTime } from 'luxon';
import PaginationClassic from '../../components/PaginationClassic';
import {
  getCurrencySymbolFromIso,
  getInvoiceStatusColor,
} from '../../utils/Utils';
import ActionsDropdown from './ActionsDropdown';
import ConvertEstimateModal from './ConvertEstimateModal';
import { getInvoices } from '../../API/backend_helper';

const EstimatesTable = ({
  getData,
  page,
  totalRecords,
  estimates,
  localLimit,
  setLocalLimit,
  setEditInvoice,
  setIsPreview,
  setDeleteModalOpen,
  convertEstimateToInvoice,
  setAddType,
  sendEmailAction,
  setIsAssignedTransTab,
  currencies,
  setSearchParams,
  projects = [],
}) => {
  const [convertEstimateModalOpen, setConvertEstimateModalOpen] =
    useState(false);
  const [activeEstimate, setActiveEstimate] = useState(false);

  const handleConvertToInvoice = (obj) => {
    setConvertEstimateModalOpen(true);
    setActiveEstimate(obj);
  };

  const onActionSelection = (option, obj) => {
    setAddType('Estimate');
    if (option?.id === 'edit') {
      setIsPreview(false);
      setEditInvoice(obj);
    }
    if (option?.id === 'view') {
      setEditInvoice(obj);
      setIsPreview(true);
    }
    if (option?.id === 'delete') {
      setDeleteModalOpen(obj);
    }
    if (option?.id === 'convertToInvoice') {
      handleConvertToInvoice(obj);
    }
    if (option?.id === 'sendToCustomer') {
      sendEmailAction(obj);
    }
    if (option?.id === 'approveToInvoice') {
      convertEstimateToInvoice(obj?.id, 'estimate', false);
    }
  };

  return (
    <>
      <ConvertEstimateModal
        isOpen={convertEstimateModalOpen}
        setIsOpen={setConvertEstimateModalOpen}
        activeEstimate={activeEstimate}
        getData={getData}
        navigateToInvoices
        sendEmailAction={sendEmailAction}
        projects={projects}
      />
      {/* Table */}
      <div className="w-full overflow-auto" id="estimatesTableContainer">
        <table className="table-fixed w-full dark:text-slate-300 divide-y divide-slate-200">
          {/* Table header */}
          <thead className="w-full shadow-md rounded-[0.313rem] text-sm leading-[1.125rem] text-slate-600">
            <tr className="w-full py-[1.438rem] px-[1.375rem] h-16">
              <th className="px-2 first:pl-5 last:pr-5 py-4 w-[7rem] max-w-[7rem] whitespace-nowrap sm:table-cell hidden ">
                <div className="font-medium text-left">#</div>
              </th>
              <th className="px-2 first:pl-5 last:pr-5 py-4 w-[7rem] max-w-[7rem] whitespace-nowrap">
                <div className="font-medium text-left">Status</div>
              </th>
              <th className="px-2 first:pl-5 last:pr-5 py-4 w-[7rem] max-w-[7rem] whitespace-nowrap">
                <div className="font-medium text-left">Date</div>
              </th>
              <th className="px-2 first:pl-5 last:pr-5 py-4 w-[7rem] max-w-[7rem] whitespace-nowrap">
                <div className="font-medium text-left">Valid Until</div>
              </th>

              <th className="px-2 first:pl-5 last:pr-5 py-4 w-[17rem] max-w-[17rem] whitespace-nowrap sm:table-cell hidden">
                <div className="font-medium text-left">Customer</div>
              </th>
              <th className="px-2 first:pl-5 last:pr-5 py-4 w-[6rem] max-w-[8rem] whitespace-nowrap sm:table-cell hidden ">
                <div className="font-medium text-center">Amount Due</div>
              </th>
              <th className=" px-2 first:pl-5 last:pr-5 w-[200px] max-w-[200px] py-4 whitespace-nowrap sm:table-cell hidden ">
                <div className="font-medium text-center">Actions</div>
              </th>
            </tr>
          </thead>
          {/* Table body */}
          {estimates?.length ? (
            <tbody className="text-sm divide-y text-[#667085] divide-slate-200 dark:divide-slate-700">
              {estimates?.map((b) => (
                <tr
                  key={b?.id}
                  onClick={(e) => {
                    e.stopPropagation();
                    setAddType('Estimate');
                    setEditInvoice(b);
                    setIsPreview(true);
                    setIsAssignedTransTab(false);
                    setSearchParams({
                      tab: 'estimates',
                      id: b?.id,
                    });
                  }}
                  className={`h-20 cursor-pointer  hover:bg-[#A0CD850F]  `}
                >
                  <td className="px-2 font-semibold first:pl-5 last:pr-5 py-2.5 text-left sm:table-cell hidden ">
                    {b?.invoiceNumber?.toString()?.padStart(4, '0')}
                  </td>
                  <td className="px-2 text-sm first:pl-5 last:pr-5 py-2.5 text-left whitespace-nowrap">
                    <div
                      className={`capitalize  px-2.5 py-1 w-fit rounded-md  text-sm font-medium
                    ${getInvoiceStatusColor(
                      b?.isDraft ? 'Draft' : b?.paymentStatus,
                    )}
                  `}
                    >
                      {b?.isDraft ? 'Draft' : b?.paymentStatus}
                    </div>
                  </td>
                  <td className="px-2 first:pl-5 last:pr-5 py-2.5 text-left">
                    {/* {moment(b?.date).format("MMM DD, YYYY")} */}
                    {DateTime.fromISO(b?.date, { setZone: true }).toFormat(
                      'MMM dd, yyyy',
                    )}
                  </td>
                  <td className="px-2 first:pl-5 last:pr-5 py-2.5 text-left ">
                    {/* {moment(b?.dueDate).format("MMM DD, YYYY")} */}
                    {DateTime.fromISO(b?.dueDate, { setZone: true }).toFormat(
                      'MMM dd, yyyy',
                    )}
                  </td>

                  {/* <td className="px-2 first:pl-5 last:pr-5 py-2.5 text-center">
                    <div className="px-3 mx-auto py-1 w-fit rounded-md bg-slate-100 text-sm font-semibold dark:bg-slate-700">
                      {b.upcoming}
                    </div>
                  </td> */}
                  <td className="px-2 first:pl-5 last:pr-5 py-2.5 text-left  sm:table-cell hidden ">
                    {b?.customer?.customer}
                  </td>
                  <td className="px-2 first:pl-5 last:pr-5 py-2.5 text-center  sm:table-cell hidden ">
                    {getCurrencySymbolFromIso(b?.currency, currencies)}
                    {parseFloat(b?.total ?? 0)?.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </td>
                  <td className="px-2 first:pl-5 last:pr-5 py-2.5 text-center  sm:table-cell hidden ">
                    <div className="flex justify-end min-w-[175px] ">
                      {!b?.isSent &&
                        b?.paymentStatus === 'unpaid' &&
                        !b?.isDraft && (
                          <button
                            className="btn-sm h-[30px] text-sm text-indigo-500 whitespace-nowrap bg-[#E486420D] px-2.5 border border-[#E48642] mr-3"
                            onClick={(e) => {
                              e.stopPropagation();
                              sendEmailAction(b);
                            }}
                            type="button"
                          >
                            Send Estimate
                          </button>
                        )}
                      {b?.paymentStatus === 'accepted' && (
                        <button
                          className="btn-sm h-[30px] text-sm text-indigo-500 whitespace-nowrap bg-[#E486420D] px-2.5 border border-[#E48642] mr-3"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleConvertToInvoice(b);
                          }}
                          type="button"
                        >
                          Convert To Invoice
                        </button>
                      )}
                      <ActionsDropdown
                        type="estimate"
                        onChange={onActionSelection}
                        isDraft={b?.isDraft}
                        obj={b}
                        bottomOfTable
                        ids={[
                          'estimatesTableContainer',
                          'invoiceEstimateContainer',
                        ]}
                      />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          ) : null}
        </table>
        {!estimates?.length > 0 && (
          <h6 className="text-sm text-[#667085B2] text-center mt-4 pb-3">
            No Estimates Found
          </h6>
        )}
      </div>
      <div className="mt-8">
        {/* {!isLoading && page >= 0 && ( */}
        <PaginationClassic
          pagination={{
            limit: localLimit,
            page,
            totalRecords,
          }}
          onPageChange={getInvoices}
          setLimit={setLocalLimit}
        />
        {/* )} */}
      </div>
    </>
  );
};

export default EstimatesTable;
