import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { useSelector } from 'react-redux';
import SearchForm from '../../../partials/actions/SearchForm';
import { OWNER, STAFF, SUPPORT } from '../../../utils/Utils';
import { getAllTeamsForUser } from '../../../API/backend_helper';
import PaginationClassic from '../../../components/PaginationClassic';
import Tooltip from '../../../components/Tooltip';

let searchTimeout = null;

const TeamsTable = ({
  setIsOpenModal,
  setLeaveTeam,
  user,
  onTeamChange,
  setDeleteWorkspace,
  accountantView = false,
}) => {
  const navigate = useNavigate();
  const { localUser } = useSelector((state) => state.User);

  const [filteredData, setFilteredData] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [allTeams, setAllTeams] = useState([]);
  const [localLimit, setLocalLimit] = useState(20);

  const getMembers = async ({ limit = localLimit, page = 1, search = '' }) => {
    try {
      const res = await getAllTeamsForUser({ limit, page, search });
      setAllTeams(res);
      setFilteredData(res?.teams);
    } catch (err) {
      console.log('err', err);
    }
  };

  useEffect(() => {
    if (user?.realm === STAFF || user?.realm === SUPPORT) {
      getMembers({});
    } else if (user?.teams?.length > 0) {
      const tempTeams = [...user?.teams];
      const sortedTeams = tempTeams?.sort?.(
        (a, b) =>
          moment(b?.createdAt, 'YYYY-MM-DD').toDate() -
          moment(a?.createdAt, 'YYYY-MM-DD').toDate(),
      );
      setFilteredData(sortedTeams);
    }
  }, [user?.teams]);

  const handleSearch = (value) => {
    setSearchValue(value);
    if (!value) {
      setFilteredData(user?.teams || []);
      return;
    }
    const formattedValue = value.replace(/\s/g, '').toLowerCase();
    const filtered = user?.teams?.filter((team) =>
      team?.name?.replace(/\s/g, '')?.toLowerCase()?.includes(formattedValue),
    );

    setFilteredData(filtered);
  };

  const onSearch = (val) => {
    setSearchValue(val);
    clearTimeout(searchTimeout);
    searchTimeout = null;
    searchTimeout = setTimeout(async () => {
      getMembers({
        search: encodeURIComponent(val || ''),
        page: 1,
      });
    }, 900);
  };

  const { page, totalRecords } = allTeams;

  return (
    <div>
      <div className="flex flex-wrap  mb-5 justify-end gap-5">
        <SearchForm
          searchVal={searchValue}
          setSearchVal={
            user?.realm === STAFF || user?.realm === SUPPORT
              ? onSearch
              : handleSearch
          }
          placeholder={accountantView ? 'Search Client' : 'Search Workspace'}
        />
        {!accountantView && (
          <button
            className="btn whitespace-nowrap !bg-indigo-500 font-normal px-5 text-base hover:!bg-indigo-600 text-white"
            aria-controls="basic-modal"
            onClick={(e) => {
              e.preventDefault();
              navigate('/workspace/new');
            }}
            type="button"
          >
            <svg
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="mr-2"
            >
              <path
                d="M7.00008 1.16602V12.8327M1.16675 6.99935H12.8334"
                stroke="white"
                strokeWidth="1.66667"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            Create A New Workspace
          </button>
        )}
      </div>
      {/* <div className="bg-[#A0CD850A] h-[60px] px-[22px] flex items-center gap-8">
        <span className="text-sm font-medium text-[#667085]">
          <span className="min-w-[10px] inline-flex">
            {selectedTeams?.length}
          </span>{" "}
          Selected
        </span>
        <hr className="w-[1px] h-[33px] bg-[#D9D9D980]" />
         <button
          disabled={selectedTeams?.length === 0}
          className="btn border border-[#FF4B4B] text-[#FF4B4B] px-4 disabled:border-[#D0D5DD] disabled:cursor-not-allowed rounded-[5px] h-[44px] text-base font-medium disabled:text-[#D0D5DD]"
        >
          Leave Team(s)
        </button>
      </div> */}
      <div className="bg-white flex-1 dark:bg-slate-800  rounded-sm overflow-auto">
        <table className="table-auto w-full dark:text-slate-300 divide-y divide-slate-200 dark:divide-slate-700">
          {/* Table header */}
          <thead className="text-sm text-[#667085] font-medium dark:text-slate-400 shadow-md border-none">
            <tr>
              {/* <th className="px-2 first:pl-5 last:pr-5 py-4 whitespace-nowrap"></th> */}
              <th className="px-2 first:pl-5 last:pr-5 py-4 whitespace-nowrap  w-[25.696rem]">
                <div className=" text-left font-medium">
                  {accountantView ? 'Client' : 'Workspace'}
                </div>
              </th>
              <th className="px-2 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                <div className=" text-left font-medium">Type</div>
              </th>
              {(user?.realm === SUPPORT || user?.realm === STAFF) && (
                <th className="px-5 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                  <div className=" text-left font-medium">Country</div>
                </th>
              )}
              {(user?.realm === SUPPORT || user?.realm === STAFF) && (
                <th className="px-5 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                  <div className=" text-left font-medium">
                    Connected Bank Account
                  </div>
                </th>
              )}
              {(user?.realm === SUPPORT || user?.realm === STAFF) && (
                <th className="px-5 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                  <div className=" text-left font-medium">Owner's Name</div>
                </th>
              )}
              {(user?.realm === SUPPORT || user?.realm === STAFF) && (
                <th className="px-5 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                  <div className=" text-left font-medium">Owner's Email</div>
                </th>
              )}
              {(user?.realm === SUPPORT || user?.realm === STAFF) && (
                <th className="px-5 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                  <div className=" text-left font-medium">Promo Code</div>
                </th>
              )}
              {(user?.realm === SUPPORT || user?.realm === STAFF) && (
                <th className="px-5 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                  <div className=" text-left font-medium">Last Login</div>
                </th>
              )}
              {user?.realm !== SUPPORT && user?.realm !== STAFF && (
                <th className="px-5 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                  <div className=" text-left font-medium">Access Type</div>
                </th>
              )}
              <th className="px-5 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                <div className=" text-left font-medium">Subscription</div>
              </th>
              <th className="px-5 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                <div className=" text-left font-medium">Employee Seats</div>
              </th>
              <th className="px-5 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                <div className=" text-left font-medium">
                  Expiration/Renewal Date
                </div>
              </th>
              {user?.realm !== SUPPORT && (
                <th className="px-2 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                  <div className=" text-left font-medium">Actions</div>
                </th>
              )}
            </tr>
          </thead>
          {/* Table body */}
          <tbody className="text-sm divide-y divide-slate-200 dark:divide-slate-700 pb-5">
            {/* Row */}
            {filteredData?.length > 0 &&
              filteredData.map((team, index) => (
                <tr className="border-b border-slate-300" key={team?.id}>
                  <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                    <div className="flex items-center">
                      <div className="w-7 h-7 shrink-0 mr-2.5">
                        {team?.logoUrl ? (
                          <img
                            src={team?.logoUrl}
                            alt={`${team.name} logo`}
                            className="w-7 h-7 rounded-full"
                          />
                        ) : (
                          <div className="w-7 h-7 rounded-full flex items-center justify-center border border-[#7425F4] bg-[#7425F41A]">
                            <div className="uppercase text-[#7425F4] text-xs">
                              {team?.name?.split(' ')?.[0]?.charAt(0)}
                              {team?.name?.split(' ')?.[1]?.charAt(0)}
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="text-sm text-[#667085] leading-tight mr-1">
                        {team?.name?.length > 60 ? (
                          <Tooltip
                            content={
                              team?.name?.length > 50
                                ? (() => {
                                    const segmentLength = 75;
                                    const segments = [];
                                    for (
                                      let i = 0;
                                      i < team?.name?.length;
                                      i += segmentLength
                                    ) {
                                      segments.push(
                                        team?.name?.substring(
                                          i,
                                          i + segmentLength,
                                        ),
                                      );
                                    }
                                    return segments.map((segment, index) => (
                                      <p key={index}>{segment}</p>
                                    ));
                                  })()
                                : team?.name
                            }
                            contentClassName={`border-none rounded-[10px] overflow-visible text-sm text-[#667085] relative tooltipArrowDown ${
                              team?.name?.length > 50 ? '' : ''
                            }`}
                          >
                            <span>{`${team?.name?.slice(0, 60)}...`}</span>
                          </Tooltip>
                        ) : (
                          <span>{team?.name}</span>
                        )}
                      </div>
                      <div className="h-[19px] w-[19px] !hidden inline-flex justify-center items-center bg-[#E48642] rounded-full text-[9px] text-white font-semibold">
                        41
                      </div>
                    </div>
                  </td>
                  <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                    <div className="text-sm text-[#667085] ">
                      {team?.subType && team?.subType !== "I'm not sure"
                        ? team?.subType
                        : team?.type}
                    </div>
                  </td>
                  {(user?.realm === SUPPORT || user?.realm === STAFF) && (
                    <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap text-center">
                      <div className="text-sm text-[#667085] ">
                        {team?.country}
                      </div>
                    </td>
                  )}
                  {(user?.realm === SUPPORT || user?.realm === STAFF) && (
                    <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap text-center">
                      <div className="text-sm text-[#667085] ">
                        {team?.launchpadStatus?.accountConnections?.has
                          ? 'Yes'
                          : 'No'}
                      </div>
                    </td>
                  )}

                  {(user?.realm === SUPPORT || user?.realm === STAFF) && (
                    <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                      <div className="text-sm text-[#667085] ">
                        {team?.users?.[0]?.firstName}{' '}
                        {team?.users?.[0]?.lastName}
                      </div>
                    </td>
                  )}
                  {(user?.realm === SUPPORT || user?.realm === STAFF) && (
                    <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                      <div className="text-sm text-[#667085] ">
                        {team?.users?.[0]?.email}
                      </div>
                    </td>
                  )}
                  {(user?.realm === SUPPORT || user?.realm === STAFF) && (
                    <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                      <div className="text-sm text-[#667085] ">
                        {team?.promoCode === null
                          ? '--'
                          : `${team?.promoCode?.value}`}{' '}
                        {team?.promoCode !== null && (
                          <span className="text-xs">
                            ({team?.promoCode?.trialLengthDays} days)
                          </span>
                        )}
                      </div>
                    </td>
                  )}
                  {(user?.realm === SUPPORT || user?.realm === STAFF) && (
                    <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                      <div className="text-sm text-[#667085] ">
                        {team?.users?.[0]?.credentials?.lastLogin
                          ? moment(
                              team?.users?.[0]?.credentials?.lastLogin,
                            ).format('MMM Do, YYYY')
                          : '--'}
                      </div>
                    </td>
                  )}

                  {user?.realm !== SUPPORT && user?.realm !== STAFF && (
                    <td className="px-5 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                      <div className="text-sm text-[#667085]   capitalize">
                        {user?.realm === STAFF
                          ? 'Staff'
                          : user?.realm === SUPPORT
                            ? 'Staff Support'
                            : team?.credentials?.role}
                      </div>
                    </td>
                  )}
                  <td className="px-5 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                    <div className="text-sm text-[#667085]   capitalize">
                      {team?.id && team?.isOnComplimentary ? (
                        'Complimentary'
                      ) : (
                        <span className="capitalize">
                          {team?.currentSubscription?.name} ({team?.subStatus})
                        </span>
                      )}
                    </div>
                  </td>
                  <td className="px-5 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                    <div className="text-sm text-[#667085]   capitalize">
                      {team?.employeeSeat || 0}
                    </div>
                  </td>
                  <td className="px-5 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                    <div className="text-sm text-[#667085]   capitalize">
                      {team?.teamPlan?.[0]?.isOnComplimentary
                        ? '--'
                        : team?.currentPeriodEnd
                          ? moment(team?.currentPeriodEnd).format(
                              'MMM Do, YYYY',
                            )
                          : '--'}
                    </div>
                  </td>
                  {user?.realm !== SUPPORT && (
                    <td className="px-2 first:pl-5 last:pr-5 py-2.5 whitespace-nowrap ">
                      <div className="flex gap-4">
                        <button
                          className="btn h-10 border-[#E48642] bg-[#E486420D] text-[#E48642] text-sm font-normal px-2.5"
                          onClick={() => onTeamChange(team?.id, team)}
                        >
                          Go To Workspace
                        </button>
                        {team?.credentials?.role === OWNER ? (
                          <Tooltip
                            content={`${
                              localUser?.realm === STAFF
                                ? 'Cannot leave Workspace. You are the staff.'
                                : 'Cannot leave Workspace. You are the owner.'
                            }`}
                            contentClassName="border-none overflow-visible text-sm text-[#667085] relative"
                            size="sm"
                            tooltipShow={false}
                          >
                            <button
                              disabled={team?.credentials?.role !== OWNER}
                              onClick={(e) => {
                                e.stopPropagation();
                                setDeleteWorkspace({
                                  id: team?.id,
                                  team,
                                  index,
                                  name: team?.name,
                                });

                                setIsOpenModal(true);
                              }}
                              className="btn h-10 border-[#FF4B4B] bg-[#FF4B4B0D] text-[#FF4B4B] text-sm font-normal px-2.5"
                            >
                              Delete Workspace
                            </button>
                          </Tooltip>
                        ) : (
                          <Tooltip
                            content={`${
                              localUser?.realm === STAFF
                                ? 'Cannot leave Workspace. You are the staff.'
                                : 'Cannot leave Workspace. You are the owner.'
                            }`}
                            contentClassName="border-none overflow-visible text-sm text-[#667085] relative"
                            size="sm"
                            tooltipShow={
                              team?.credentials?.role === OWNER ||
                              localUser?.realm === STAFF
                            }
                          >
                            <button
                              disabled={
                                team?.credentials?.role === OWNER ||
                                localUser?.realm === STAFF
                              }
                              onClick={(e) => {
                                e.stopPropagation();
                                setLeaveTeam({
                                  value: team?.id,
                                  team,
                                  index,
                                });
                                setIsOpenModal(true);
                              }}
                              className="btn h-10 border-[#FF4B4B] bg-[#FF4B4B0D] text-[#FF4B4B] text-sm font-normal px-2.5"
                            >
                              Leave Workspace
                            </button>
                          </Tooltip>
                        )}
                      </div>
                    </td>
                  )}
                </tr>
              ))}
          </tbody>
        </table>
        {filteredData?.length === 0 && (
          <h6 className="text-sm text-[#667085B2] text-center mt-4 pb-3">
            No {accountantView ? 'Clients' : 'Workspaces'} Found
          </h6>
        )}
        <div className="mt-8">
          {page >= 0 && (
            <PaginationClassic
              pagination={{
                limit: +localLimit,
                page: +page,
                totalRecords: +totalRecords,
              }}
              onPageChange={getMembers}
              setLimit={setLocalLimit}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default TeamsTable;
