import { getAllAccountantFirms } from 'API/backend_helper';
import PaginationClassic from 'components/PaginationClassic';
import FirmIcon from 'pages/accountantFirm/FirmIcon';
import React, { useEffect, useState } from 'react';

const AccountingFirmsTab = () => {
  const [loading, setLoading] = useState(true);

  const [bookkeepers, setBookkeepers] = useState({});
  const [localLimit, setLocalLimit] = useState(50);

  const loadAllFirms = async ({
    page = bookkeepers?.page,
    limit = localLimit,
  } = {}) => {
    try {
      const res = await getAllAccountantFirms({ page, limit });
      setBookkeepers(res?.data?.result);
    } catch (err) {
      console.log('err', err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadAllFirms({});
  }, []);

  return (
    <div>
      <div className="flex items-center justify-between mb-4  ">
        <h1 className="text-2xl  text-[#101828B2] dark:text-slate-100 font-medium">
          Accounting Firms
        </h1>
      </div>
      {loading ? (
        <div className="flex flex-col gap-2 items-center pb-2 justify-center mt-6">
          <svg
            width="29"
            height="29"
            viewBox="0 0 29 29"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="animate-spin w-10 h-10 fill-current shrink-0"
          >
            <path
              d="M14.5 2.41699V7.25033"
              stroke="#E48642"
              strokeWidth="2.55882"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M14.5 21.749V26.5824"
              stroke="#E48642"
              strokeWidth="2.55882"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M5.95703 5.95703L9.37662 9.37662"
              stroke="#E48642"
              strokeWidth="1.58955"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M19.623 19.6211L23.0426 23.0407"
              stroke="#E48642"
              strokeWidth="1.58955"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M2.41699 14.5H7.25033"
              stroke="#E48642"
              strokeWidth="1.58955"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M21.749 14.5H26.5824"
              stroke="#E48642"
              strokeWidth="1.58955"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M5.95703 23.0407L9.37662 19.6211"
              stroke="#E48642"
              strokeWidth="1.58955"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M19.623 9.37662L23.0426 5.95703"
              stroke="#E48642"
              strokeWidth="1.58955"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <p className="text-slate-600 font-medium text-[0.875rem] text-center">
            Fetching data, please wait...
          </p>
        </div>
      ) : (
        <>
          <div className="bg-white flex-1">
            {/* Table */}
            <div className="overflow-x-auto h-full">
              <table className="table-fixed w-full dark:text-slate-300">
                {/* Table header */}
                <thead className="w-full shadow-md rounded-[0.313rem] text-sm leading-[1.125rem] text-slate-600">
                  <tr className="w-full py-[1.438rem] px-[1.375rem] h-16">
                    <th className="px-4 w-[18rem] max-w-[18rem]">
                      <div className="text-left w-[6.25rem] font-medium">
                        Name
                      </div>
                    </th>
                    <th className="w-[14rem] max-w-[14rem]">
                      <div className="text-left w-[8.75rem] font-medium">
                        Email
                      </div>
                    </th>
                    <th className="w-[9rem] max-w-[9rem]">
                      <div className="text-left font-medium">Phone</div>
                    </th>
                    <th className="w-[16rem] max-w-[16rem]">
                      <div className="text-left font-medium">Website</div>
                    </th>
                  </tr>
                </thead>
                {/* Table body */}
                {bookkeepers?.accountingFirms?.length > 0 && (
                  <tbody className="text-sm divide-y divide-gray-300 border-b border-gray-300">
                    {bookkeepers?.accountingFirms?.map((firm) => (
                      <tr
                        key={firm?.id}
                        className=" w-full pl-[1.375rem]  pr-[1.125rem] h-20 hover:bg-[#a0cd85] hover:bg-opacity-5"
                      >
                        <td className="px-4">
                          <div className=" flex items-center gap-2 text-left text-slate-600">
                            <div className="min-h-8 min-w-8 h-8 w-8  inline-flex justify-center items-center overflow-hidden rounded-full border border-gray-300 shadow-md">
                              {firm?.logoUrl ? (
                                <img
                                  src={firm?.logoUrl}
                                  alt="firm_logo"
                                  className="h-full w-full rounded-full object-contain"
                                />
                              ) : (
                                <FirmIcon className="h-6 w-6" />
                              )}
                            </div>
                            <p className="text-sm">{firm?.name}</p>
                          </div>
                        </td>
                        <td className="pr-4">
                          <div className=" text-left text-slate-600 text-[0.938rem] leading-6 break-words">
                            {firm?.email || '--'}
                          </div>
                        </td>
                        <td className="whitespace-nowrap">
                          <div className="text-left">
                            <p className="text-slate-600 text-[0.938rem] leading-6">
                              {firm?.phone || '--'}
                            </p>
                          </div>
                        </td>
                        <td className="pr-4">
                          <div className="text-left ">
                            {firm?.website ? (
                              <a
                                href={firm?.website}
                                target="_blank"
                                className="text-indigo-500 underline"
                                rel="noreferrer"
                              >
                                {firm?.website}
                              </a>
                            ) : (
                              '--'
                            )}
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                )}
              </table>
            </div>
          </div>
          <div className="mt-10">
            <PaginationClassic
              setLimit={setLocalLimit}
              onPageChange={loadAllFirms}
              pagination={{
                limit: localLimit,
                page: bookkeepers?.page,
                totalRecords: bookkeepers?.totalCount,
              }}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default AccountingFirmsTab;
