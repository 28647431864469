import React, { useRef, useEffect } from 'react';
import Transition from '../utils/Transition';
import useClickOutside from '../utils/useClickOutside';

function ModalBasic({
  children,
  id,
  title,
  modalOpen,
  setModalOpen,
  width = '',
  smallModal,
  dialogClassName = '',
  noClick = false,
  contentId = '',
  svg = null,
  hideCross = false,
}) {
  const modalContent = useRef(null);
  const noClickRef = useRef(noClick);

  useClickOutside(modalContent, () => {
    if (noClickRef.current) return;
    setModalOpen(false);
  });

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!modalOpen || keyCode !== 27) return;
      setModalOpen(false);
    };
    document.addEventListener('keydown', keyHandler);
    return () => document.removeEventListener('keydown', keyHandler);
  });

  useEffect(() => {
    noClickRef.current = noClick;
  }, [noClick]);

  return (
    <>
      {/* Modal backdrop */}
      <Transition
        className="fixed inset-0 bg-slate-900 bg-opacity-30 z-60 transition-opacity"
        show={modalOpen}
        enter="transition ease-out duration-200"
        enterStart="opacity-0"
        enterEnd="opacity-100"
        leave="transition ease-out duration-100"
        leaveStart="opacity-100"
        leaveEnd="opacity-0"
        aria-hidden="true"
      />
      {/* Modal dialog */}
      <Transition
        id={id}
        className={`fixed inset-0 z-60 overflow-hidden flex items-center my-4 justify-center px-4 sm:px-6 `}
        role="dialog"
        aria-modal="true"
        show={modalOpen}
        enter="transition ease-in-out duration-200"
        enterStart="opacity-0 translate-y-4"
        enterEnd="opacity-100 translate-y-0"
        leave="transition ease-in-out duration-200"
        leaveStart="opacity-100 translate-y-0"
        leaveEnd="opacity-0 translate-y-4"
      >
        <div
          ref={modalContent}
          className={`bg-white dark:bg-slate-800 rounded-xl shadow-lg overflow-auto overflow-x-hidden ${
            width || ' max-w-lg'
          } w-full max-h-full  ${dialogClassName}`}
          id={contentId}
        >
          {/* Modal header */}
          <div
            className={`border-b border-gray-300 dark:border-slate-700 py-[1.313rem] ${
              smallModal ? 'px-5' : 'px-[30px]'
            }`}
          >
            <div className="flex justify-between items-center">
              {svg ? (
                <div className="flex gap-2 items-center">
                  <div className="font-medium text-[1.625rem] leading-[2.375rem] text-black text-opacity-60">
                    {title}
                  </div>
                  {svg}
                </div>
              ) : (
                <div className="font-medium text-[1.625rem] leading-[2.375rem] text-black text-opacity-60">
                  {title}
                </div>
              )}
              {!hideCross && (
                <button
                  className="text-slate-400 dark:text-slate-500 hover:text-slate-500 dark:hover:text-slate-400"
                  onClick={(e) => {
                    e.stopPropagation();
                    setModalOpen(false);
                  }}
                  type="button"
                >
                  <div className="sr-only">Close</div>
                  <svg
                    width="28"
                    height="29"
                    viewBox="0 0 28 29"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect
                      x="6.17676"
                      y="8.6582"
                      width="2.3292"
                      height="19.7982"
                      rx="1.1646"
                      transform="rotate(-45 6.17676 8.6582)"
                      fill="#667085"
                    />
                    <rect
                      x="20.1768"
                      y="7.01172"
                      width="2.3292"
                      height="19.7982"
                      rx="1.1646"
                      transform="rotate(45 20.1768 7.01172)"
                      fill="#667085"
                    />
                  </svg>
                </button>
              )}
            </div>
          </div>
          {children}
        </div>
      </Transition>
    </>
  );
}

export default ModalBasic;
