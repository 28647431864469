import React from 'react';
import ModalBasic from '../../components/ModalBasic';
import { Loader } from '../../components/Svg';

const DeleteReporterModal = ({
  isOpen,
  setIsOpen,
  handleDeleteReporter,
  activeReporter,
  errMessage,
  title = '',
  type = '',
  loading,
}) => (
  <ModalBasic
    title={errMessage ? 'Error' : title}
    modalOpen={isOpen}
    setModalOpen={setIsOpen}
    smallModal
  >
    <div className="w-full px-5 py-[1.875rem] flex items-center self-stretch bg-white">
      {errMessage ? (
        <p className="text-base leading-6 text-slate-600 text-opacity-80">
          {errMessage.message}
        </p>
      ) : (
        <p className="text-base leading-6 text-slate-600 text-opacity-80">
          Are you sure you want to delete this {type}? This action cannot be
          undone.
        </p>
      )}
    </div>
    <div className="border-t border-gray-300 h-20 flex items-center justify-end gap-4 px-5">
      {errMessage ? (
        <button
          className="w-[5.625rem] h-11 flex items-center justify-center py-2.5 px-[1.875rem] rounded-[0.313rem] bg-indigo-500 shadow text-white leading-6 "
          onClick={() => setIsOpen(false)}
          type="button"
        >
          Close
        </button>
      ) : (
        <>
          <button
            className="w-[5.625rem] h-11 flex items-center justify-center py-2.5 px-[1.875rem] rounded-[0.313rem] bg-white border border-slate-600 text-slate-600 text-base leading-6"
            onClick={() => setIsOpen(false)}
            type="button"
          >
            No
          </button>
          <button
            className="w-[5.625rem] h-11 flex items-center justify-center py-2.5 px-[1.875rem] rounded-[0.313rem] bg-indigo-500 shadow text-white leading-6"
            onClick={() => handleDeleteReporter(activeReporter)}
            type="button"
          >
            {loading ? (
              <div className="flex items-center justify-center">
                <Loader width="w-6" height="h-6" color="#FFFFFF" />
              </div>
            ) : (
              'Yes'
            )}
          </button>
        </>
      )}
    </div>
  </ModalBasic>
);

export default DeleteReporterModal;
