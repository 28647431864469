import React, { useRef, useEffect, useState } from "react";
import {
  Chart,
  CategoryScale,
  BarController,
  BarElement,
  LinearScale,
  Tooltip,
} from "chart.js";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import "chartjs-adapter-moment";

import { chartColors } from "./ChartjsConfig";
import { formatValue } from "../utils/Utils";

Chart.register(BarController, BarElement, LinearScale, CategoryScale, Tooltip);

function BarChartInsights({ datasets, width, height, dateRange, goToTransactions, teamCurrency }) {
  const canvasRef = useRef(null);
  const chartInstanceRef = useRef(null);
  const { gridColor, tooltipBodyColor, tooltipBgColor, tooltipBorderColor } = chartColors;
  const navigate = useNavigate();

  useEffect(() => {
    if (canvasRef.current && datasets && datasets.length) {
      const ctx = canvasRef.current.getContext("2d");

      if (chartInstanceRef.current) {
        chartInstanceRef.current.destroy();
      }

      const newChart = new Chart(ctx, {
        type: "bar",
        data: {
          labels:
            datasets[0]?.data.length > 0
              ? datasets[0].data.map((item) =>
                  moment(item.month, "YYYY-MM").format("MMM YYYY")
                )
              : datasets[1]?.data.map((item) =>
                  moment(item.month, "YYYY-MM").format("MMM YYYY")
                ),
          datasets: datasets.map((dataset) => ({
            label: dataset.label,
            data: dataset.data.map((d) => d.amount),
            backgroundColor: dataset.backgroundColor,
            borderColor: dataset.borderColor,
            borderWidth: 1,
            barThickness: 6,
          })),
        },
        options: {
          scales: {
            x: {
              beginAtZero: true,
              grid: {
                display: false,
              },
            },
            y: {
              beginAtZero: true,
              suggestedMax:
                Math.max(
                  ...datasets.map((d) =>
                    Math.max(...d.data.map((dd) => dd.amount))
                  )
                ) * 1.1,
              grid: {
                borderDash: [5, 5],
              },
              ticks: {
                callback: function (value) {
                  return formatValue(value);
                },
              },
            },
          },
          onClick: (event, elements) => {
            if (elements.length > 0) {
              const label = newChart.data.labels[elements[0].index];

              const monthMoment = moment(label, "MMM YYYY");
              const startDate = monthMoment.startOf('month').toDate().toString();
              let endDate;

              const adjustToStartOfDay = (date) => {
                date.setHours(0, 0, 0, 0);
                return date;
              };

              const today = new Date();
              if (monthMoment.year() === today.getFullYear() && monthMoment.month() === today.getMonth()) {
                endDate = adjustToStartOfDay(new Date()).toString(); 
              } else {
                endDate = monthMoment.endOf('month').toDate().toString(); 
              }

              goToTransactions(startDate, endDate);
            }
          },
          plugins: {
            tooltip: {
              titleFont: {
                weight: "600",
              },
              callbacks: {
                title: (context) => context?.label,
                label: (context) =>
                  `${teamCurrency ? teamCurrency?.symbol : "$"}${context.parsed.y?.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}`,
              },
              titleColor: tooltipBodyColor.light,
              bodyColor: tooltipBodyColor.light,
              backgroundColor: tooltipBgColor.light,
              borderColor: tooltipBorderColor.light,
            },
          },
          maintainAspectRatio: false,
        },
      });

      chartInstanceRef.current = newChart;
    }

    return () => {
      if (chartInstanceRef.current) {
        chartInstanceRef.current.destroy();
      }
    };
  }, [datasets, dateRange, goToTransactions, teamCurrency]);

  return <canvas ref={canvasRef} width={width} height={height} />;
}

export default BarChartInsights;
