import React, { useRef } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import ModalBasic from '../../components/ModalBasic';
import AccountsDropdown from '../expenseReporting/AccountsDropdown';

const ExpenseReportingAccountsModal = ({
  open,
  setOpen,
  activeReporterId = null,
  setPoepleSelectExpAcc,
  expenseReporterAccounts,
  notAssignedAccounts,
  localSelectedAccounts,
  setLocalSelectedAccounts,
  peopleSelectedExpAcc,
}) => {
  const formikRef = useRef(null);

  const handleSubmit = async (values) => {
    setPoepleSelectExpAcc(values.accounts);
    setOpen(false);
  };

  return (
    <ModalBasic
      title={`${activeReporterId ? 'Edit' : 'Add'} Expense Reporter Accounts`}
      smallModal
      modalOpen={open}
      setModalOpen={setOpen}
    >
      <Formik
        enableReinitialize
        initialValues={{
          accounts: peopleSelectedExpAcc || [],
        }}
        validationSchema={Yup.object({
          accounts: Yup.array()
            .required('Please Select Account')
            .min(1, 'Please Select Account'),
        })}
        onSubmit={handleSubmit}
        innerRef={formikRef}
      >
        {(validation) => (
          <Form>
            <div className="flex justify-between flex-col min-h-[450px]">
              <div className="px-5 pt-4 pb-1">
                <div className="flex flex-col items-start w-full mt-4">
                  <label
                    className={`block text-sm mb-1 text-slate-600 font-medium leading-5 ${
                      validation.touched.accounts && validation.errors.accounts
                        ? '!text-rose-400'
                        : ''
                    }`}
                    htmlFor="accounts"
                  >
                    Account(s) <span className="text-rose-500">*</span>
                  </label>
                  <AccountsDropdown
                    allCategories={
                      !activeReporterId
                        ? notAssignedAccounts
                        : expenseReporterAccounts
                    }
                    selectedCategoryId={validation.values.accounts}
                    setSelectedCategoryId={(value) => {
                      validation.setFieldValue('accounts', value);
                    }}
                    apiFilter={validation.values.accounts}
                    type="account"
                    localSelected={localSelectedAccounts}
                    setLocalSelected={setLocalSelectedAccounts}
                    isSetCategoryStyle
                  />

                  {validation.touched.accounts && validation.errors.accounts ? (
                    <div className="text-xs mt-1 text-rose-400">
                      {validation.errors.accounts}
                    </div>
                  ) : null}
                </div>
              </div>
              {/* Modal footer */}
              <div className="flex flex-wrap justify-end space-x-2 px-5 py-4 mt-3 border-t border-[#D0D5DD]">
                <button
                  type="button"
                  className="py-2.5 px-[1.875rem] h-11 w-[7rem] text-center flex justify-center items-center text-base leading-6 font-base rounded-[0.313rem] bg-white text-indigo-500 border border-indigo-500"
                  onClick={() => {
                    validation.resetForm();
                    setOpen(false);
                  }}
                >
                  Close
                </button>
                <button
                  type="submit"
                  className="py-2.5 px-[1.875rem] h-11 w-[7rem] bg-indigo-500 text-center text-white text-base leading-6 font-base rounded-[0.313rem] disabled:cursor-not-allowed"
                >
                  <span>{`${activeReporterId ? 'Update' : 'Add'}`}</span>
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </ModalBasic>
  );
};

export default ExpenseReportingAccountsModal;
