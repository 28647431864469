import React from "react";

export const FormatValue = ({ sign, value, reverse = false }) => {
  const isPercent = sign == "%";

  const addCommasToNumber = (number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  return (
    <p>
      {!isPercent && (value >= 0 ? sign : "-" + sign)}
      {isPercent && (value >= 0 ? "" : "-")}
      {addCommasToNumber(Math.abs(value).toFixed(2))}
      {isPercent && sign}
    </p>
  );
};
