import React, { useEffect, useState } from "react";
import {  getTeamLogs } from "../../../API/backend_helper";
import PaginationClassic from "../../../components/PaginationClassic";
import moment from "moment";
import EnrichLogsModal from "./enrichLogsModal";

const EnrichTeamLogs = () => {
  const [teamLogs, setTeamLogs] = useState({});
  const [localLimit, setLocalLimit] = useState(20);
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});

  const loadTeamLogs = async ({
    limit = localLimit,
    page = 1,
    isLoading = false,
  }) => {
    if (isLoading) {
      setIsLoading(true);
    }
    try {
      const res = await getTeamLogs({
        limit,
        page,
      });
      setTeamLogs(res?.data);
    } catch (err) {
      console.log("err", err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadTeamLogs({ isLoading: true });
  }, []);

  const getStatusColor = (status) => {
    switch (status) {
      case "skipped":
        return "text-[#E48642] bg-[#E486421A]";
      case "error":
        return "text-[#FF4B4B] bg-[#FF4B4B1A]";
      case "success":
        return "text-[#40AA00] bg-[#40AA001A]";
      default:
        return "text-slate-500 bg-slate-100";
    }
  };

  const { records, page, limit, enrichs } = teamLogs||{};

  return (
    <div>
      <EnrichLogsModal
        selectedRow={selectedRow}
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
      />
      {!isLoading ? (
        <div className="bg-white flex-1 dark:bg-slate-800  rounded-sm   overflow-auto">
          <table className="table-auto w-full dark:text-slate-300 divide-y divide-slate-200 dark:divide-slate-700">
            {/* Table header */}
            <thead className="text-sm text-[#667085] font-medium dark:text-slate-400 shadow-md border-none">
              <tr>
                {/* <th className="px-2 first:pl-5 last:pr-5 py-4 whitespace-nowrap"></th> */}
                <th className="px-2 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                  <div className=" text-left font-medium">Date</div>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                  <div className=" text-left font-medium">Bank Description</div>
                </th>
                <th className="px-5 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                  <div className=" text-left font-medium">Memo</div>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                  <div className=" text-left font-medium">Category Name</div>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                  <div className=" text-left font-medium">Vendor Name</div>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                  <div className=" text-left font-medium">Total Tokens</div>
                </th>
              </tr>
            </thead>
            {/* Table body */}
            <tbody className="text-sm divide-y divide-slate-200 dark:divide-slate-700 pb-5">
              {/* Row */}
              {enrichs?.length > 0 &&
                enrichs.map((record, index) => (
                  <tr
                    onClick={(e) => {
                      e.stopPropagation();
                      setSelectedRow(record);
                      setIsModalOpen(true);
                    }}
                    className="border-b border-slate-300 hover:bg-[#A0CD850F] cursor-pointer"
                  >
                    <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                      <div className="text-sm text-[#667085] leading-tight mr-1">
                        {moment
                          .utc(record?.createdAt)
                          .format("MMM DD, YYYY, HH:mm:ss")}
                      </div>
                    </td>
                    <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                    <div className="text-sm text-[#667085] capitalize">
                        {record?.bankDescription}
                      </div>
                    </td>
                    <td className="px-2 first:pl-5 last:pr-5 py-3">
                      <div className="text-sm text-[#667085] capitalize">
                        {record?.memo}
                      </div>
                    </td>
                    <td className="px-2 first:pl-5 last:pr-5 py-3">
                      <div className="text-sm text-[#667085] capitalize ">
                        {record?.output?.categoryName||"--"}
                      </div>
                    </td>
                    <td className="px-2 first:pl-5 last:pr-5 py-3">
                      <div className="text-sm text-[#667085] capitalize ">
                        {record?.output?.vendorName||"--"}
                      </div>
                    </td>
                    <td className="px-2 first:pl-5 last:pr-5 py-3">
                      <div className="text-sm text-[#667085] capitalize ">
                        {record?.openAIUsage?.totalTokens}
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
          {enrichs?.length === 0 && (
            <h6 className="text-sm text-[#667085B2] text-center mt-4 pb-3">
              No Data Found
            </h6>
          )}
        </div>
      ) : (
        <p>Loading...</p>
      )}
      <div className="mt-8">
        {records > 0 && (
          <PaginationClassic
            pagination={{
              limit: +localLimit,
              page: +page,
              totalRecords: +records,
            }}
            onPageChange={loadTeamLogs}
            setLimit={setLocalLimit}
          />
        )}
      </div>
    </div>
  );
};

export default EnrichTeamLogs;
