import React, { useEffect, useState } from 'react';
import { MentionsInput, Mention } from 'react-mentions';
import DropdownFull from '../../../components/DropdownFull';
import TextInputDash from '../../../components/TextInputDash';
import Tooltip from '../../../components/Tooltip';
import BasicCheckboxDropdown from '../../component/BasicCheckboxDropdown';
import CategoriesDropdown from '../../transactions/CategoriesDropdown';
import { CloseBtn } from '../svg';

const Action1 = ({
  setPanelStep,
  nodes,
  workingNodeId,
  vendors,
  customers,
  tags,
  categories,
  members,
  updateNode,
  isEditing,
  activeNodeId,
  localNode,
  setPanelOpen,
  automationType,
}) => {
  const [actionType, setActionType] = useState('');
  const [action, setAction] = useState(null);
  const [actionSelected, setActionSelected] = useState(false);
  const [actionTags, setActionTags] = useState([]);
  const [comment, setComment] = useState('');
  const [mentionIds, setMentionIds] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [descriptionText, setDescriptionText] = useState('');

  const [email, setEmail] = useState('');
  const [emailBody, setEmailBody] = useState(
    'Heads Up! Product {name} only has {quantity} stock remaining.',
  );

  useEffect(() => {
    if (isEditing && localNode) {
      setActionType(localNode?.data?.actionType);
      setSelectedCategory(localNode?.data?.selectedCategory || null);
      setMentionIds(localNode?.data?.mentionIds);
      setComment(localNode?.data?.comment);
      setAction(localNode?.data?.action);
      setDescriptionText(localNode?.data?.descriptionText || '');
      setActionTags(
        localNode?.data?.actionTags
          ? localNode?.data?.actionTags.map(
              (tag) => tags.find((t) => t.id === tag)?.name,
            )
          : [],
      );
      setEmail(localNode?.data?.email);
      setEmailBody(localNode?.data?.emailBody);
    }
  }, [localNode, isEditing]);

  useEffect(() => {
    if (!isEditing) {
      setAction(null);
      setActionTags([]);
      setComment('');
      setMentionIds([]);
      setDescriptionText('');
    }
  }, [actionType]);

  useEffect(() => {
    const isActionValid = () => {
      switch (actionType) {
        case 'vendor':
        case 'customer':
          return !!action;
        case 'tag':
          return actionTags;
        case 'category':
          return !!selectedCategory;
        case 'comment':
          return comment;
        case 'excluded':
          return true;
        case 'reviewed':
          return true;
        case 'description':
          return !!descriptionText;
        default:
          return false;
      }
    };

    setActionSelected(isActionValid());
  }, [
    actionType,
    action,
    actionTags,
    selectedCategory,
    comment,
    descriptionText,
  ]);

  const generateNodeName = () => {
    if (automationType === 'transaction') {
      // eslint-disable-next-line default-case
      switch (actionType) {
        case 'excluded':
          return `Mark Transaction as Excluded`;
        case 'reviewed':
          return `Mark Transaction as Reviewed`;
        case 'category':
          return `Categorize Transaction as ${action}`;
        case 'description':
          return `Update Transaction Description: ${descriptionText}`;
        case 'vendor':
          return `Assign Transaction to Vendor: ${
            vendors.find((vendor) => vendor.id === action)?.name
          }`;
        case 'customer':
          return `Assign Transaction to Customer: ${
            customers.find((customer) => customer.id === action)?.customer
          }`;
        case 'tag':
          return `Add Tag(s) to Transaction: ${actionTags.join(' and ')}`;
        case 'comment':
          const formattedComment = comment.replace(
            /@\[([^\]]+)\]\(\d+\)/g,
            '@$1',
          );
          return `Add Comment: ${formattedComment}`;
      }
    } else if (
      automationType === 'stockOnHand' ||
      automationType === 'estimateStatus'
    ) {
      return `Send Email to ${email}`;
    }
    return '';
  };

  const handleNextClick = () => {
    const nodeName = generateNodeName();
    if (automationType === 'transaction') {
      if (isEditing) {
        updateNode(activeNodeId, {
          name: `Then: ${nodeName}`,
          actionType,
          action,
          actionTags: actionTags
            ? actionTags.map((tag) => tags.find((t) => t.name === tag)?.id)
            : null,
          comment,
          mentionIds,
          selectedCategory,
          descriptionText,
        });
        setPanelStep('component1');
      } else {
        updateNode(workingNodeId, {
          name: `Then: ${nodeName}`,
          actionType,
          action,
          actionTags: actionTags
            ? actionTags.map((tag) => tags.find((t) => t.name === tag)?.id)
            : null,
          comment,
          mentionIds,
          selectedCategory,
          descriptionText,
        });
        setPanelStep('component2');
      }
    } else if (
      automationType === 'stockOnHand' ||
      automationType === 'estimateStatus'
    ) {
      if (isEditing) {
        updateNode(activeNodeId, {
          name: `Then: ${nodeName}`,
          email,
          emailBody,
        });
        setPanelStep('component1');
      } else {
        updateNode(workingNodeId, {
          name: `Then: ${nodeName}`,
          email,
          emailBody,
        });
        setPanelStep('component2');
      }
    }

    setPanelOpen(false);
  };

  const findDropdownOptions = () => {
    // eslint-disable-next-line default-case
    switch (actionType) {
      case 'vendor':
        return vendors.map((vendor) => ({
          label: vendor?.name,
          value: vendor?.name,
          id: vendor?.id,
        }));
      case 'customer':
        const modifiedCustomers = customers.map((customer) => ({
          label: customer?.customer,
          value: customer?.customer,
          id: customer?.id,
        }));
        return modifiedCustomers;
      case 'tag':
        const modifiedTags = tags.map((tag) => ({
          label: tag?.name,
          value: tag?.name,
          id: tag?.id,
        }));
        return modifiedTags;
      case 'category':
        const modifiedCategories = categories.map((category) => ({
          label: category?.name,
          value: category?.name,
        }));
        return modifiedCategories;
    }
  };

  const handleCommentChange = (event, value, newPlainTextValue, mentions) => {
    setComment(value);
    setMentionIds(mentions.map((mention) => Number(mention.id)));
  };

  const handleCategoryClick = (id) => {
    const allCategories = categories.flatMap((item) => item.categories);
    const category = allCategories.find((category) => category.id === id);
    setAction(category.name);
  };

  const vendorSelected = nodes.some(
    (node) => node.data.actionType === 'vendor',
  );
  const customerSelected = nodes.some(
    (node) => node.data.actionType === 'customer',
  );
  const selectedActionTypes = nodes.map((node) => node.data.actionType);

  const filterOptions = (options) =>
    options.filter(
      (option) =>
        option.id === actionType || !selectedActionTypes.includes(option.id),
    );

  const dropdownActionsOptions = vendorSelected
    ? filterOptions([
        { name: 'Mark Transaction as Excluded', id: 'excluded' },
        { name: 'Mark Transaction as Reviewed', id: 'reviewed' },
        { name: 'Assign Transaction to Category', id: 'category' },
        { name: 'Assign Transaction to Vendor', id: 'vendor' },
        { name: 'Assign Tag(s) to Transaction', id: 'tag' },
        {
          name: 'Update Transaction Description',
          id: 'description',
        },
      ])
    : customerSelected
      ? filterOptions([
          { name: 'Mark Transaction as Excluded', id: 'excluded' },
          { name: 'Mark Transaction as Reviewed', id: 'reviewed' },
          { name: 'Assign Transaction to Category', id: 'category' },
          { name: 'Assign Transaction to Customer', id: 'customer' },
          { name: 'Assign Tag(s) to Transaction', id: 'tag' },
          {
            name: 'Update Transaction Description',
            id: 'description',
          },
        ])
      : filterOptions([
          { name: 'Mark Transaction as Excluded', id: 'excluded' },
          { name: 'Mark Transaction as Reviewed', id: 'reviewed' },
          { name: 'Assign Transaction to Category', id: 'category' },
          { name: 'Assign Transaction to Vendor', id: 'vendor' },
          { name: 'Assign Transaction to Customer', id: 'customer' },
          { name: 'Assign Tag(s) to Transaction', id: 'tag' },
          {
            name: 'Update Transaction Description',
            id: 'description',
          },
        ]);

  return (
    <>
      <div className="flex flex-col items-start gap-[0.938rem] self-stretch">
        <div className="w-full flex justify-between">
          <div className="flex flex-col items-start gap-px self-stretch">
            <p className="text-black text-[1.375rem] font-medium">
              Add an Action
            </p>
            <p className="text-slate-600 text-[14px]">
              Executes if prior conditions are met
            </p>
          </div>
          <CloseBtn
            onClick={() => setPanelOpen(false)}
            className="mt-[.7rem]"
          />
        </div>

        {automationType === 'transaction' ? (
          <>
            <div className="w-full self-stretch flex items-center justify-center">
              <DropdownFull
                options={dropdownActionsOptions}
                name="name"
                height="h-10"
                placeholder="Select Action Type"
                selected={actionType}
                setSelected={setActionType}
                scrollIntoView={false}
              />
            </div>
            {dropdownActionsOptions?.length === 0 ? (
              <span className=" text-xs text-rose-400">
                No other actions are available
              </span>
            ) : null}
            <div className="w-full max-w-full self-stretch flex items-center justify-center">
              {(actionType === 'vendor' || actionType === 'customer') && (
                <DropdownFull
                  options={findDropdownOptions()}
                  name="label"
                  height="h-10"
                  placeholder={`Select ${actionType}`}
                  selected={action}
                  setSelected={setAction}
                  scrollIntoView={false}
                />
              )}
              {actionType === 'category' && (
                <CategoriesDropdown
                  allCategories={categories}
                  selectedCategoryId={selectedCategory}
                  setSelectedCategoryId={(id) => {
                    setSelectedCategory(id);
                    handleCategoryClick(id);
                  }}
                  isSetCategoryStyle
                />
              )}
              {actionType === 'tag' && (
                <BasicCheckboxDropdown
                  options={findDropdownOptions()}
                  name="name"
                  height="h-10"
                  selectedOptions={actionTags}
                  onClear={() => setAction([])}
                  setSelectedOptions={(action) => setActionTags(action)}
                  keyValue="value"
                  apiFunc={() => {}}
                  label="Select Tags"
                  flyout
                  type="workflows"
                  disabled={!actionType}
                />
              )}
              {actionType === 'comment' && (
                <div className="rounded-[5px] w-full pl-4 min-h-10 flex items-center gap-2.5 justify-between border border-[#D0D5DD]">
                  <div className="w-full min-h-10 pt-2 rounded-[5px]">
                    <MentionsInput
                      value={comment}
                      onChange={handleCommentChange}
                      style={{
                        input: {
                          border: 'none',
                          padding: '0 0.313rem 0 0.313rem',
                          width: '100%',
                          minHeight: '2.5rem',
                        },
                        suggestions: {
                          list: {
                            backgroundColor: 'white',
                            borderRadius: '0px 10px 10px 10px',
                            border: '1px solid #D0D5DD',
                            boxShadow: '0px 4px 14px 0px rgba(0, 0, 0, 0.06)',
                            display: 'inline-flex',
                            padding: '0.313rem',
                            flexGap: '1rem',
                            paddingTop: '0.25rem',
                            flexDirection: 'column',
                          },
                          highlighter: {},
                          item: {
                            padding: '0.188rem 0.625rem',
                            fontColor: '#000',
                            lineHeight: '1.5rem',
                            '&focused': {
                              backgroundColor: '#F1F1F1',
                              borderRadius: '0.313rem',
                            },
                          },
                        },
                      }}
                    >
                      <Mention
                        trigger="@"
                        data={members}
                        className="bg-indigo-500 bg-opacity-20 rounded-[1.25rem] mx-0.5"
                        renderSuggestion={(
                          suggestion,
                          search,
                          highlightedDisplay,
                        ) => (
                          <div className="flex flex-row gap-2.5 items-center">
                            {suggestion.avatarUrl ? (
                              <img
                                src={suggestion.avatarUrl}
                                alt="User avatar"
                                className="w-6 h-6 rounded-full"
                              />
                            ) : (
                              <div className="bg-indigo-500 text-white w-6 h-6 rounded-full flex items-center justify-center">
                                {suggestion.display[0].toUpperCase()}
                              </div>
                            )}
                            <span>{highlightedDisplay}</span>
                          </div>
                        )}
                        displayTransform={(id, display) => `@${display}  `}
                      />
                    </MentionsInput>
                  </div>
                </div>
              )}
              {actionType === 'description' && (
                <input
                  id="description"
                  type="description"
                  placeholder="Enter Description"
                  name="description"
                  className={`btn text-base h-10 outline-none w-full rounded-[0.313rem] border border-[#D0D5DD] text-slate-600 leading-6 font-normal px-4 py-3.5 bg-white
                            placeholder:text-base placeholder:text-slate-600 placeholder:text-opacity-80 placeholder:leading-6 placeholder:font-normal`}
                  onChange={(e) => setDescriptionText(e.target.value)}
                  value={descriptionText || ''}
                />
              )}
            </div>
          </>
        ) : automationType === 'stockOnHand' ||
          automationType === 'estimateStatus' ? (
          <div className="w-full">
            <TextInputDash
              label="Send email alert to"
              id="email"
              name="email"
              required
              placeholder="Email"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              paddingBottom="0"
              reports
            />
            {/* <TextInputDash
              label="Email Body"
              id="email"
              name="email"
              placeholder="Email"
              onChange={(e) => setEmailBody(e.target.value)}
              value={emailBody}
              paddingBottom="0"
              reports
              type="textarea"
              width="mt-2"
              rows="3"
            /> */}
          </div>
        ) : null}
      </div>
      <div className="flex items-start gap-5 self-stretch w-1/3">
        <Tooltip
          content={`Please ${automationType === 'transaction' ? 'select an action' : 'enter email'}  to proceed.`}
          tooltipShow={
            automationType === 'transaction' ? !actionSelected : !email
          }
          position="top"
          contentClassName="border-none overflow-visible text-sm text-[#667085] relative tooltipArrowDown ml-20"
          className="w-full"
        >
          <button
            className="w-full py-[5px] flex items-center justify-center gap-2 rounded-[0.313rem] shadow text-white bg-indigo-500 leading-6 disabled:cursor-not-allowed"
            disabled={
              automationType === 'transaction' ? !actionSelected : !email
            }
            onClick={() => handleNextClick()}
            type="button"
          >
            {isEditing ? 'Save' : 'Add'}
          </button>
        </Tooltip>
      </div>
    </>
  );
};

export default Action1;
