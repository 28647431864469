import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Form, Formik } from 'formik';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';
import moment from 'moment';
import ModalBasic from '../../components/ModalBasic';
import DropdownFull from '../../components/DropdownFull';
import TextInputDash from '../../components/TextInputDash';
import DatePickerDash from '../../components/DatePickerDash';
import Tooltip from '../../components/Tooltip';
import { Loader } from '../../components/Svg';
import { updateRecurringTemplates } from '../../API/backend_helper';

const RecurrencePatternOptions = [
  {
    id: 'daily',
    name: 'Daily',
  },
  {
    id: 'weekly',
    name: 'Weekly',
  },
  {
    id: 'monthly',
    name: 'Monthly',
  },
  {
    id: 'yearly',
    name: 'Yearly',
  },
];

const RecurrenceTemplateModal = ({
  modalOpen,
  setModalOpen,
  activeTemplate,
  loadRecurringTemplates,
}) => {
  const formikRef = useRef();
  const { team } = useSelector((state) => state.Team);

  const [noClick, setNoClick] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);

  const options = useMemo(
    () => ({
      mode: 'single',
      static: false,
      monthSelectorType: 'static',
      allowInput: true,
      dateFormat:
        team?.defaults?.dateFormat === 'dd/mm/yyyy' ? 'd/m/Y' : 'm/d/Y',
      position: 'bottom',
      prevArrow:
        '<svg class="fill-current" width="7" height="11" viewBox="0 0 7 11"><path d="M5.4 10.8l1.4-1.4-4-4 4-4L5.4 0 0 5.4z" /></svg>',
      nextArrow:
        '<svg class="fill-current" width="7" height="11" viewBox="0 0 7 11"><path d="M1.4 10.8L0 9.4l4-4-4-4L1.4 0l5.4 5.4z" /></svg>',
      onReady: (selectedDates, dateStr, instance) => {
        instance?.calendarContainer?.classList?.add(`flatpickr-right`);
      },
      minDate: moment().toISOString(),
    }),
    [team],
  );

  useEffect(() => {
    if (modalOpen) {
      setNoClick(true);
    } else {
      formikRef?.current?.resetForm();
      setNoClick(false);
    }
  }, [modalOpen]);

  const handleSubmit = async (values) => {
    setModalLoading(true);
    const data = {
      recurrencePattern: values.recurrencePattern,
      emailCustomer: values.emailCustomer,
    };
    if (values.endRecurringType === 'by') {
      data.recurrenceEndDate = moment(values.recurrenceEndDate).format(
        'YYYY-MM-DD',
      );
      data.occurrenceCount = 0;
    } else if (values.endRecurringType === 'after') {
      data.occurrenceCount = values.occurrenceCount;
      data.recurrenceEndDate = null;
    } else {
      data.occurrenceCount = 0;
      data.recurrenceEndDate = null;
    }

    if (values.recurrencePattern !== 'daily') {
      data.inAdvanceCreationDays = parseInt(values.inAdvanceCreationDays) || 0;
    } else {
      data.inAdvanceCreationDays = 0;
    }

    try {
      const res = await updateRecurringTemplates(activeTemplate?.id, data);
      if (res?.data?.result?.id) {
        await loadRecurringTemplates({});
        setModalOpen(false);
      }
    } catch (err) {
      console.log('err', err);
    } finally {
      setModalLoading(false);
    }
  };

  return (
    <ModalBasic
      title="Edit Template"
      modalOpen={modalOpen}
      setModalOpen={setModalOpen}
      noClick={noClick}
    >
      <Formik
        enableReinitialize
        initialValues={{
          recurrencePattern: activeTemplate?.recurrencePattern || '',
          endRecurringType:
            activeTemplate?.occurrenceCount > 0
              ? 'after'
              : activeTemplate?.recurrenceEndDate
                ? 'by'
                : '',
          occurrenceCount: activeTemplate?.occurrenceCount || 1,
          recurrenceEndDate: activeTemplate?.recurrenceEndDate || '',
          inAdvanceCreationDays: activeTemplate?.inAdvanceCreationDays || '',
          emailCustomer: activeTemplate?.emailCustomer || false,
        }}
        validate={(values) => {
          const errors = {};

          const { endRecurringType, occurrenceCount, recurrenceEndDate } =
            values;
          if (endRecurringType === 'after' && !occurrenceCount) {
            errors.occurrenceCount = 'Number of occurrences is required';
          } else {
            delete errors.occurrenceCount;
          }
          if (endRecurringType === 'by' && !recurrenceEndDate) {
            errors.recurrenceEndDate = 'Recurrence end date is required';
          } else {
            delete errors.recurrenceEndDate;
          }

          return errors;
        }}
        validationSchema={Yup.object({
          recurrencePattern: Yup.string().required(
            'Recurrence Pattern is required',
          ),
          endRecurringType: Yup.string(),
          occurrenceCount: Yup.number().min(
            1,
            'Number of occurrences should be greater than 0',
          ),
          inAdvanceCreationDays: Yup.number(),
          recurrenceEndDate: Yup.string(),
        })}
        onSubmit={handleSubmit}
        innerRef={formikRef}
      >
        {(validation) => (
          <Form>
            <div className="space-y-4 py-6 px-8">
              <div className="pb-3">
                <p className="text-sm font-medium text-slate-600 block mb-2 ">
                  Repeats <span className="text-rose-500">*</span>
                </p>
                <DropdownFull
                  options={RecurrencePatternOptions}
                  selected={validation.values.recurrencePattern}
                  setSelected={(value) => {
                    validation.setFieldValue('recurrencePattern', value);
                  }}
                  name="name"
                  scrollIntoView={false}
                  // search
                  height="h-12"
                  flyout
                  placeholder="Repeats"
                  bottomOfTable
                  className="whitespace-nowrap truncate !h-fit"
                  ids={['invoiceEstimateContainer']}
                  isTableDropdown
                />
              </div>

              <div className="pb-3">
                <p className="text-sm font-medium text-slate-600 block mb-2 ">
                  End <span className="text-rose-500">*</span>
                </p>
                <DropdownFull
                  options={[
                    {
                      name: 'Never',
                      id: '',
                    },
                    {
                      name: 'By',
                      id: 'by',
                    },
                    {
                      name: 'After',
                      id: 'after',
                    },
                  ]}
                  selected={validation.values.endRecurringType}
                  setSelected={(value) => {
                    validation.setFieldValue('endRecurringType', value);
                  }}
                  name="name"
                  scrollIntoView={false}
                  height="h-12"
                  flyout
                  placeholder="Repeats"
                  bottomOfTable
                  className="whitespace-nowrap truncate !h-fit"
                  ids={['invoiceEstimateContainer']}
                  isTableDropdown
                />
              </div>
              {validation.values.endRecurringType === 'after' && (
                <div className="">
                  <TextInputDash
                    id="occurrenceCount"
                    min={1}
                    paddingBottom="pb-4"
                    type="number"
                    placeholder="Number of occurrences"
                    value={validation.values.occurrenceCount}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    name="occurrenceCount"
                    label="Number of occurrences"
                    required
                    error={
                      validation.touched.occurrenceCount &&
                      validation.errors.occurrenceCount
                    }
                  />
                </div>
              )}
              {validation.values.endRecurringType === 'by' && (
                <div className="pb-3">
                  <DatePickerDash
                    placeholder="Recurrence End Date"
                    value={
                      validation.values.recurrenceEndDate
                        ? moment(
                            validation.values.recurrenceEndDate,
                          ).toISOString()
                        : null
                    }
                    label="Recurrence End Date"
                    required
                    onChange={(selectedDates) => {
                      validation.setFieldValue(
                        'recurrenceEndDate',
                        moment(selectedDates[0]).format('YYYY-MM-DD'),
                      );
                    }}
                    options={{
                      ...options,
                      onOpen() {
                        setNoClick(true);
                      },
                      onClose() {
                        setNoClick(false);
                      },
                    }}
                    inputClass="w-full"
                    align="left"
                    error={validation.errors.recurrenceEndDate}
                  />
                </div>
              )}
              {validation.values.recurrencePattern !== 'daily' && (
                <div className="pb-3">
                  <Tooltip
                    content={
                      <>
                        <p>This is when the invoice will be </p>
                        <p>created in your invoice list.</p>
                      </>
                    }
                    className="w-fit"
                    contentClassName="border-none overflow-visible text-sm text-[#667085] relative tooltipArrowDown"
                  >
                    <label
                      htmlFor="inAdvanceCreationDays"
                      className="text-sm font-medium text-slate-600 block mb-2  underline"
                    >
                      Create number of days in advance
                    </label>
                  </Tooltip>
                  <TextInputDash
                    paddingBottom="0"
                    id="inAdvanceCreationDays"
                    type="number"
                    placeholder="Advance Days"
                    min={0}
                    value={validation.values.inAdvanceCreationDays}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    name="inAdvanceCreationDays"
                    error={
                      validation.touched.inAdvanceCreationDays &&
                      validation.errors.inAdvanceCreationDays
                    }
                  />
                </div>
              )}
              <div className=" flex items-center pt-2 min-h-10">
                <label className="cursor-pointer text-base font-medium text-nowrap inline-flex justify-center items-center">
                  <input
                    type="checkbox"
                    checked={validation.values.emailCustomer}
                    className="form-checkbox mr-2 h-5 w-5"
                    onChange={validation.handleChange}
                    id="emailCustomer"
                    name="emailCustomer"
                  />
                  Email invoice to customer automatically
                </label>
              </div>
            </div>
            <div className="py-6 px-8 border-t border-[#D0D5DD] flex w-full justify-end items-center gap-4">
              <button
                type="button"
                className="h-11 w-[90px] flex justify-center items-center rounded-[5px] bg-white text-slate-600 border border-slate-600"
                onClick={() => setModalOpen(false)}
              >
                Close
              </button>
              <button
                className="h-11 w-[90px] flex justify-center items-center rounded-[5px] bg-indigo-500 text-white border border-indigo-500
                disabled:!bg-indigo-300 disabled:border-indigo-300 disabled:cursor-not-allowed"
                type="submit"
                disabled={modalLoading}
              >
                {modalLoading ? (
                  <Loader color="#FFFFFF" width="w-4" height="h-4" />
                ) : (
                  'Save'
                )}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </ModalBasic>
  );
};

export default RecurrenceTemplateModal;
