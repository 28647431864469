import React, { useRef } from 'react';
import moment from 'moment';
import PaginationClassic from 'components/PaginationClassic';
import useClickOutside from '../../utils/useClickOutside';
import { Loader } from '../../components/Svg';

const ProductsPanel = ({
  panelOpen,
  setPanelOpen,
  productHistory,
  productHistoryLoading,
  setProductHistoryLimit,
  loadProductHistory,
  productHistoryLimit,
}) => {
  const panelContent = useRef(null);

  const onPanelClose = () => {
    setProductHistoryLimit(50);
  };

  useClickOutside(panelContent, () => {
    setPanelOpen(false);
    onPanelClose();
  });

  return (
    <div
      ref={panelContent}
      className={`absolute inset-0 bg-white sm:left-auto shadow-xl transition-transform duration-200 ease-in-out z-40 ${
        panelOpen ? 'translate-x-0' : 'translate-x-full'
      }`}
    >
      <div
        className="sticky top-16 bg-white dark:bg-gradient-to-b dark:from-slate-800 dark:to-slate-900 overflow-x-hidden overflow-y-auto no-scrollbar shrink-0  w-full sm:w-[510px] h-[calc(100dvh-64px)]"
        style={{ boxShadow: ' -10px 0px 15px 0px #D0D5DD80' }}
      >
        <div className="sticky top-0 h-[4.875rem] bg-white z-10">
          <div className="flex justify-end pt-2.5 pr-2.5">
            <button
              className="ml-auto group"
              onClick={() => {
                onPanelClose();
                setPanelOpen(false);
              }}
              type="button"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 384 512"
                className="h-5 w-5 fill-[#667085] group-hover:fill-slate-700"
              >
                <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
              </svg>
            </button>
          </div>
          <h3 className="px-4 text-black text-opacity-60 text-[24px] font-medium leading-[2.375rem]">
            Product Stock History
          </h3>
        </div>
        {productHistoryLoading ? (
          <div className="w-full flex items-center justify-center mt-10">
            <Loader />
          </div>
        ) : (
          <>
            <div className="px-4 flex flex-col gap-2 divide-y divide-[#D0D5DD]">
              {productHistory?.stockHistory?.length > 0 ? (
                productHistory?.stockHistory?.map((p) => (
                  <div key={p?.id} className="pt-2">
                    <div>
                      <span className="font-semibold">Date: </span>{' '}
                      {moment(p?.updatedAt)?.format('MMM DD , yyyy')}
                    </div>
                    <div>
                      <span className="font-semibold">Adjustment: </span>{' '}
                      <span
                        className={`${p?.adjustment > 0 ? 'text-[#40AA00]' : 'text-rose-400'}`}
                      >
                        {p?.adjustment > 0 && '+'}
                        {p?.adjustment}
                      </span>{' '}
                    </div>
                    <div>
                      <span className="font-semibold">Total: </span>{' '}
                      <span>{p?.totalProduct || '--'}</span>{' '}
                    </div>
                    <div className="">
                      <span className="font-semibold">Reason: </span>{' '}
                      {p?.reason}{' '}
                      {p?.note && (
                        <span>
                          <span className="text-slate-800 mx-2"> | </span>{' '}
                          {p?.note}
                        </span>
                      )}
                    </div>
                  </div>
                ))
              ) : (
                <p className="text-center">History Not Found</p>
              )}
            </div>
            <div className="mt-10 px-4 pb-6">
              <PaginationClassic
                setLimit={setProductHistoryLimit}
                onPageChange={loadProductHistory}
                pagination={{
                  limit: productHistoryLimit,
                  page: productHistory?.page,
                  totalRecords: productHistory?.totalCount,
                }}
                pageLoad
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ProductsPanel;
