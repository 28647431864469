export const CloseButton = () => (
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="6.17688"
      y="7.82422"
      width="2.3292"
      height="19.7982"
      rx="1.1646"
      transform="rotate(-45 6.17688 7.82422)"
      fill="#667085"
    />
    <rect
      x="20.1771"
      y="6.17676"
      width="2.3292"
      height="19.7982"
      rx="1.1646"
      transform="rotate(45 20.1771 6.17676)"
      fill="#667085"
    />
  </svg>
);
