import React, { useState } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { DateTime } from 'luxon';
import { getCurrencySymbolFromIso } from '../../utils/Utils';
import { sendInvoiceEmail } from '../../API/backend_helper';
import TextInputDash from '../../components/TextInputDash';
import ModalBasic from '../../components/ModalBasic';

const SendInvoiceModal = ({
  isOpen,
  setIsOpen,
  type,
  getData,
  team,
  currencies,
}) => {
  const [loading, setLoading] = useState(false);

  const onSubmit = async (values) => {
    setLoading(true);
    try {
      await sendInvoiceEmail(isOpen?.id, values);
      await getData({ updateEdit: true });
      setIsOpen(null);
      toast.success('Email sent successfully!');
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log('err', err);
    }
  };

  return (
    <ModalBasic
      modalOpen={isOpen}
      setModalOpen={setIsOpen}
      title={`Send ${type} ${isOpen?.isSent ? 'Reminder' : ''}`}
      width="!w-[600px]"
    >
      <Formik
        enableReinitialize
        initialValues={{
          // from: "",
          to: isOpen?.customer?.contacts?.[0]?.email || '',
          subject:
            isOpen?.invoiceType === 'invoice'
              ? `New payment request from ${team?.name} - Invoice ${isOpen?.invoiceNumber?.toString()?.padStart(4, '0')}`
              : `Estimate ${isOpen?.invoiceNumber?.toString()?.padStart(4, '0')} from ${team?.name}`,
          message: `Dear ${
            isOpen?.customer?.contacts?.[0]?.firstName ||
            isOpen?.customer?.customer
          },

We appreciate your business. Please find your ${type?.toLowerCase?.()} details here. Feel free to contact us if you have any questions.

Have a great day!
${isOpen?.firstName}`,
          sendCopy: false,
          // attachPdf: false,
        }}
        validationSchema={Yup.object({
          // from: Yup.string()
          //   .email("Enter Correct Email")
          //   .required("From Email is required"),
          to: Yup.string()
            .email('Enter Correct Email')
            .required('To Email is required'),
          subject: Yup.string().required('Subject is required'),
          message: Yup.string().required('Message is required'),
        })}
        onSubmit={onSubmit}
      >
        {(validation) => (
          <Form>
            <div className="px-10 py-8 space-y-2">
              {/* <TextInputDash
                  label="From"
                  id="from"
                  name="from"
                  placeholder="From"
                  value={validation.values.from || ""}
                  error={validation.touched.from && validation.errors.from}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  required
                /> */}
              <TextInputDash
                label="To"
                id="to"
                name="to"
                placeholder="To"
                value={validation.values.to || ''}
                error={validation.touched.to && validation.errors.to}
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                required
              />
              <TextInputDash
                label="Subject"
                id="subject"
                name="subject"
                placeholder="Subject"
                value={validation.values.subject || ''}
                error={validation.touched.subject && validation.errors.subject}
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                required
              />
              <div className=" flex flex-col items-start gap-2.5">
                <label
                  className="text-sm font-medium text-slate-600 block"
                  htmlFor="message"
                >
                  Message
                </label>
                <textarea
                  id="message"
                  name="message"
                  placeholder="Message"
                  value={validation.values.message || ''}
                  rows="8"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  className="form-input w-full px-4 text-slate-600 placeholder:text-slate-600 placeholder:text-opacity-80 border-[#D0D5DD] shadow-sm mb-[2px] rounded-[5px] "
                />
              </div>
              {/* <label
                htmlFor="sendCopy"
                className="flex items-center mt-5 cursor-pointer"
              >
                <input
                  id="sendCopy"
                  type="checkbox"
                  className="form-checkbox h-5 w-5 mr-5"
                  checked={validation.values.sendCopy}
                  onChange={() =>
                    validation.setFieldValue(
                      'sendCopy',
                      !validation.values.sendCopy,
                    )
                  }
                />
                Send a copy to myself at {isOpen?.email}
              </label> */}
              {/* <label htmlFor="attachPdf" className="flex items-center mt-3">
                  <input
                    id="attachPdf"
                    type="checkbox"
                    className="form-checkbox h-5 w-5 mr-5"
                    checked={validation.values.attachPdf}
                    onChange={() =>
                      validation.setFieldValue(
                        "attachPdf",
                        !validation.values.attachPdf
                      )
                    }
                  />
                  Attach the invoice as PDF
                </label> */}
            </div>

            {/* Close/Add Buttons */}
            <div className="w-full flex justify-end items-center gap-4 py-6 px-10 border-t border-[#D0D5DD]">
              <button
                className="flex items-center justify-center py-2.5 px-[1.875rem] rounded-[0.313rem]  bg-white border border-gray-300 border-opacity-40 shadow w-[6.875rem] h-11 text-slate-600 leading-6 whitespace-nowrap"
                onClick={(event) => {
                  event.preventDefault();
                  setIsOpen(false);
                }}
                type="button"
              >
                Send Later
              </button>
              <button
                className="flex items-center justify-center py-2.5 px-[1.875rem] rounded-[0.313rem] bg-indigo-500 shadow w-[6.875rem] h-11 text-white leading-6
             disabled:!bg-indigo-300 disabled:text-white disabled:cursor-not-allowed"
                type="submit"
                disabled={loading}
              >
                {loading && (
                  <svg
                    className="animate-spin w-4 h-4 fill-current shrink-0 mr-2"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z" />
                  </svg>
                )}
                Send
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </ModalBasic>
  );
};

export default SendInvoiceModal;
