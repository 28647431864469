import React, { useEffect, useState } from 'react';
import receiptIcon from '../../images/receipts-icon.png';

const TransactionsReceiptPanel = ({
  setReceiptModalOpen,
  setActiveReceipt,
  activeTransaction,
}) => {
  const [localReceipts, setLocalReceipts] = useState([]);

  const [formOpen] = useState(false);

  const [isLoading] = useState(false);

  const handleOpenModal = (receipt) => {
    setActiveReceipt(receipt);
    setReceiptModalOpen(true);
  };

  useEffect(() => {
    if (
      activeTransaction?.pendingReceipt?.receiptUrl ||
      activeTransaction?.transaction?.receipts?.[0]?.images?.[0]?.receiptUrl
    ) {
      setLocalReceipts([
        activeTransaction?.transaction?.receipts?.[0]?.images?.[0]
          ?.receiptUrl || activeTransaction?.pendingReceipt?.receiptUrl,
      ]);
    }
    return () => {
      setLocalReceipts([]);
    };
  }, [activeTransaction]);

  return (
    <div className="mx-[1.563rem] mt-10 bg-white border border-black border-opacity-5 rounded-xl p-2.5 drop-shadow-sm ">
      <div className="flex justify-between items-center self-stretch">
        <p className="text-slate-600 leading-5 font-medium text-base">
          Your Attachments ({localReceipts?.length})
        </p>
      </div>
      {isLoading ? (
        <div className="flex flex-col items-center justify-center">
          <svg
            width="32"
            height="32"
            viewBox="0 0 29 29"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="animate-spin w-8 h-8 fill-current shrink-0 mt-4"
          >
            <path
              d="M14.5 2.41699V7.25033"
              stroke="#E48642"
              strokeWidth="2.55882"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M14.5 21.749V26.5824"
              stroke="#E48642"
              strokeWidth="2.55882"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M5.95703 5.95703L9.37662 9.37662"
              stroke="#E48642"
              strokeWidth="1.58955"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M19.623 19.6211L23.0426 23.0407"
              stroke="#E48642"
              strokeWidth="1.58955"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M2.41699 14.5H7.25033"
              stroke="#E48642"
              strokeWidth="1.58955"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M21.749 14.5H26.5824"
              stroke="#E48642"
              strokeWidth="1.58955"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M5.95703 23.0407L9.37662 19.6211"
              stroke="#E48642"
              strokeWidth="1.58955"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M19.623 9.37662L23.0426 5.95703"
              stroke="#E48642"
              strokeWidth="1.58955"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <p className="text-slate-600 font-medium text-[0.875rem] text-center">
            Fetching data, please wait...
          </p>
        </div>
      ) : (
        <>
          {localReceipts.length < 1 && !formOpen && (
            <div className="w-full flex justify-center items-center mt-[1.875rem] gap-5">
              <img src={receiptIcon} alt="receipt icon" />
              <p>No attachment(s) to display here</p>
            </div>
          )}
          {localReceipts.length > 0 &&
            localReceipts.map((receipt) => (
              <div
                key={receipt.id}
                className="border border-slate-600 rounded-md w-full h-[3.125rem] flex justify-between items-center bg-gray-300 bg-opacity-10 pl-[0.313rem] pr-3 py-3 mb-2.5 mt-6"
              >
                <div className="flex items-center gap-2">
                  {receipt?.endsWith('.pdf') ? (
                    <div className="border border-slate-600 rounded-[0.313rem] w-[2.438rem] h-[2.438rem] flex items-center justify-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="30"
                        width="30"
                        viewBox="0 0 512 512"
                      >
                        <path
                          fill="#e48642"
                          d="M64 464l48 0 0 48-48 0c-35.3 0-64-28.7-64-64L0 64C0 28.7 28.7 0 64 0L229.5 0c17 0 33.3 6.7 45.3 18.7l90.5 90.5c12 12 18.7 28.3 18.7 45.3L384 304l-48 0 0-144-80 0c-17.7 0-32-14.3-32-32l0-80L64 48c-8.8 0-16 7.2-16 16l0 384c0 8.8 7.2 16 16 16zM176 352l32 0c30.9 0 56 25.1 56 56s-25.1 56-56 56l-16 0 0 32c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-48 0-80c0-8.8 7.2-16 16-16zm32 80c13.3 0 24-10.7 24-24s-10.7-24-24-24l-16 0 0 48 16 0zm96-80l32 0c26.5 0 48 21.5 48 48l0 64c0 26.5-21.5 48-48 48l-32 0c-8.8 0-16-7.2-16-16l0-128c0-8.8 7.2-16 16-16zm32 128c8.8 0 16-7.2 16-16l0-64c0-8.8-7.2-16-16-16l-16 0 0 96 16 0zm80-112c0-8.8 7.2-16 16-16l48 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-32 0 0 32 32 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-32 0 0 48c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-64 0-64z"
                        />
                      </svg>
                    </div>
                  ) : receipt?.endsWith('.png') ||
                    receipt?.endsWith('.jpg') ||
                    receipt?.endsWith('.jpeg') ||
                    receipt?.endsWith('.svg') ? (
                    <img
                      src={receipt}
                      className="border border-slate-600 rounded-[0.313rem] w-[2.438rem] h-[2.438rem]"
                      alt="receipt"
                    />
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                      className="w-[2.2rem] h-[2.2rem]"
                    >
                      <path d="M448 80c8.8 0 16 7.2 16 16V415.8l-5-6.5-136-176c-4.5-5.9-11.6-9.3-19-9.3s-14.4 3.4-19 9.3L202 340.7l-30.5-42.7C167 291.7 159.8 288 152 288s-15 3.7-19.5 10.1l-80 112L48 416.3l0-.3V96c0-8.8 7.2-16 16-16H448zM64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64zm80 192a48 48 0 1 0 0-96 48 48 0 1 0 0 96z" />
                    </svg>
                  )}
                </div>
                <div className="flex justify-end items-center gap-4">
                  {receipt?.endsWith('.png') ||
                  receipt?.endsWith('.jpg') ||
                  receipt?.endsWith('.jpeg') ||
                  receipt?.endsWith('.svg') ? (
                    <svg
                      width="19"
                      height="20"
                      viewBox="0 0 19 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="cursor-pointer"
                      onClick={() => handleOpenModal(receipt)}
                    >
                      <path
                        d="M12.4956 10.0019C12.4956 11.5315 11.2595 12.7675 9.72996 12.7675C8.20038 12.7675 6.96436 11.5315 6.96436 10.0019C6.96436 8.47235 8.20038 7.23633 9.72996 7.23633C11.2595 7.23633 12.4956 8.47235 12.4956 10.0019Z"
                        stroke="#667085"
                        strokeWidth="1.55"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M9.73046 16.3887C12.4574 16.3887 14.999 14.7818 16.7681 12.0008C17.4633 10.9115 17.4633 9.08065 16.7681 7.99141C14.999 5.21035 12.4574 3.60352 9.73046 3.60352C7.00347 3.60352 4.4619 5.21035 2.69284 7.99141C1.99757 9.08065 1.99757 10.9115 2.69284 12.0008C4.4619 14.7818 7.00347 16.3887 9.73046 16.3887Z"
                        stroke="#667085"
                        strokeWidth="1.55"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  ) : (
                    <a
                      className=""
                      target="_blank"
                      href={receipt}
                      download="receipt"
                      rel="noreferrer"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 384 512"
                        className="h-5 w-5 cursor-pointer"
                      >
                        <path d="M169.4 470.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 370.8 224 64c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 306.7L54.6 265.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                      </svg>
                    </a>
                  )}
                </div>
              </div>
            ))}
        </>
      )}
    </div>
  );
};

export default TransactionsReceiptPanel;
