import React, { useRef, useState } from 'react';
import { HexColorInput, HexColorPicker } from 'react-colorful';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import colorPickerImg from '../../../images/custom/colorpicker.png';
import FontsDropdown from '../../../components/FontsDropdown';
import useClickOutside from '../../../utils/useClickOutside';
import { Loader } from '../../../components/Svg';
import TextInputDash from '../../../components/TextInputDash';
import { GoogleFonts } from '../../../utils/Fonts';

const colorOptions = [
  '#667085',
  '#47ab6c',
  '#3d73de',
  '#8471d8',
  '#d85fb4',
  '#e99745',
  '#6bbe1d',
  '#39abbc',
  '#f75e5e',
];

const ColorPicker = ({ colorOptions, selectedColor, setSelectedColor }) => {
  const [colorPickerOpen, setColorPickerOpen] = useState(false);

  const colorPicker = useRef();

  useClickOutside(colorPicker, () => {
    setColorPickerOpen(false);
  });

  const handleColorSelect = (color) => {
    setSelectedColor(color);
  };

  return (
    <div className="flex flex-row gap-[0.524rem] relative">
      <div className="flex flex-row gap-[0.524rem]">
        <img
          src={colorPickerImg}
          alt="color picker"
          className="cursor-pointer"
          onClick={() => {
            setColorPickerOpen(true);
          }}
        />
        <HexColorInput
          color={selectedColor}
          onChange={handleColorSelect}
          prefixed
          className="h-5 w-[4.1rem] border-[1.2px] border-[#D0D5DD] rounded-md text-slate-600 !text-xs px-1"
        />
      </div>
      {colorOptions.map((color, index) => (
        <button
          key={index}
          type="button"
          onClick={() => handleColorSelect(color)}
          className={`cursor-pointer w-[1.088rem] h-[1.088rem] rounded-full border-2 box-border ${
            color === selectedColor ? 'border-white' : 'border-transparent'
          }`}
          style={{
            background: color,
            boxShadow:
              color === selectedColor
                ? '0px 0px 10.482px rgba(0, 0, 0, 0.30)'
                : 'none',
          }}
        />
      ))}
      {colorPickerOpen && (
        <div ref={colorPicker} className="absolute z-50 top-8 left-0">
          <HexColorPicker color={selectedColor} onChange={handleColorSelect} />
        </div>
      )}
    </div>
  );
};

const InvoiceSettingsTab = ({
  selectedColor,
  setSelectedColor,
  fontFamily,
  setFontFamily,
  handleUpdateTeam,
  configLoading,
  team,
  handleInvoiceTitleUpdate,
}) => (
  <div>
    {' '}
    <div className="flex justify-between gap-5 rounded-lg mb-3 flex-wrap border px-5 py-4 border-slate-200 dark:border-slate-700">
      <div className="flex gap-3 items-center flex-wrap">
        <h3 className="text-sm font-medium ">Default Invoice Color</h3>
      </div>
      <div className="flex gap-3 items-center justify-center">
        <div>
          <ColorPicker
            colorOptions={colorOptions}
            selectedColor={selectedColor}
            setSelectedColor={setSelectedColor}
          />
        </div>
      </div>
    </div>
    <div className="flex justify-between sm:h-[56px] items-center gap-5 rounded-lg mb-3 flex-wrap border px-5 py-4 sm:py-0 border-slate-200 dark:border-slate-700">
      <div className="flex gap-3 items-center flex-wrap">
        <h3 className="text-sm font-medium ">Default Invoice Font</h3>
      </div>
      <div className="flex gap-3 items-center justify-center w-[14rem]">
        {configLoading['Default Invoice Font'] ? (
          <Loader width="w-6" height="h-6" />
        ) : (
          <FontsDropdown
            options={GoogleFonts}
            name="name"
            setSelected={(id) => {
              setFontFamily(id);
              handleUpdateTeam(
                team?.id,
                {
                  font: id,
                },
                'Default Invoice Font',
              );
            }}
            selected={fontFamily}
            scrollIntoView={false}
            fontFamily
            noSearch={false}
            height="h-10"
          />
        )}
      </div>
    </div>
    <div className="py-4 rounded-lg mb-3 flex-wrap border px-5 border-slate-200 dark:border-slate-700">
      <div>
        <Formik
          enableReinitialize
          initialValues={{
            invoiceDefaultTitle: team?.defaults?.invoiceDefaultTitle || '',
            invoiceDefaultSummary: team?.defaults?.invoiceDefaultSummary || '',
            estimateDefaultTitle: team?.defaults?.estimateDefaultTitle || '',
          }}
          validationSchema={Yup.object({
            invoiceDefaultTitle: Yup.string(),
            invoiceDefaultSummary: Yup.string(),
          })}
          onSubmit={handleInvoiceTitleUpdate}
        >
          {(validation) => (
            <Form>
              <div className="flex justify-between flex-col">
                <div className="pb-1 grid sm:grid-cols-2 gap-4">
                  <TextInputDash
                    id="invoiceDefaultTitle"
                    label="Default Invoice Title"
                    name="invoiceDefaultTitle"
                    type="text"
                    paddingBottom="pb-0"
                    placeholder="Default Invoice Title"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.invoiceDefaultTitle || ''}
                    error={
                      validation.touched.invoiceDefaultTitle &&
                      validation.errors.invoiceDefaultTitle
                    }
                  />
                  <TextInputDash
                    id="estimateDefaultTitle"
                    label="Default Estimate Title"
                    name="estimateDefaultTitle"
                    type="text"
                    paddingBottom="pb-0"
                    placeholder="Default Estimate Title"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.estimateDefaultTitle || ''}
                    error={
                      validation.touched.estimateDefaultTitle &&
                      validation.errors.estimateDefaultTitle
                    }
                  />
                  <TextInputDash
                    id="invoiceDefaultSummary"
                    label="Default Memo"
                    name="invoiceDefaultSummary"
                    paddingBottom="pb-0"
                    placeholder="Default Memo"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.invoiceDefaultSummary || ''}
                    error={
                      validation.touched.invoiceDefaultSummary &&
                      validation.errors.invoiceDefaultSummary
                    }
                    type="textarea"
                  />
                </div>
                <button
                  type="submit"
                  className="py-2.5 mt-2 min-w-[115px] px-[1.875rem] h-11 w-[7rem] bg-indigo-500 text-center text-white text-base leading-6 font-base rounded-[0.313rem] disabled:cursor-not-allowed"
                  disabled={configLoading['Invoice Default Titles']}
                >
                  {configLoading['Invoice Default Titles'] ? (
                    <div className="flex items-center justify-center">
                      <Loader width="w-6" height="h-6" color="#FFFFFF" />
                    </div>
                  ) : (
                    <span>Update</span>
                  )}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  </div>
);

export default InvoiceSettingsTab;
