import React from 'react';
import ModalBasic from '../../components/ModalBasic';
import { Loader } from '../../components/Svg';

const DeleteTransactionModal = ({
  isOpen,
  setIsOpen,
  handleDeleteTransaction,
  parentSplit,
  setTransactionPanelOpen,
  setSelectedRow,
  failedEditCount,
  failedVendorEditCount,
  failedCustomerEditCount,
  deleteModalLoading,
  setDeleteModalLoading,
}) => {
  const openParentTransaction = () => {
    setSelectedRow(parentSplit);
    setTransactionPanelOpen(true);
    setIsOpen('');
  };

  return (
    <>
      <ModalBasic
        title={
          isOpen === 'error'
            ? 'Error'
            : isOpen === 'editFail' || isOpen === 'editFailReview'
              ? 'Could Not Edit All Transactions'
              : 'Delete Transaction'
        }
        modalOpen={isOpen}
        setModalOpen={setIsOpen}
        smallModal
      >
        <div className="w-full px-5 py-[1.875rem] flex items-center self-stretch bg-white">
          {isOpen === 'error' ? (
            <p className="text-base leading-6 text-slate-600 text-opacity-80">
              Deleting selected transaction(s) is not permitted
            </p>
          ) : isOpen === 'editFail' ? (
            <div>
              {failedEditCount > 0 && (
                <p className="text-base leading-6 text-slate-600 text-opacity-80">
                  {failedEditCount} of the selected transactions could not be
                  edited because they are already reviewed or reconciled.
                </p>
              )}
              {failedVendorEditCount > 0 && (
                <p className="text-base leading-6 text-slate-600 text-opacity-80 mt-2">
                  {failedVendorEditCount} of the selected transactions could not
                  apply a new vendor because they already have a customer.
                </p>
              )}
              {failedCustomerEditCount > 0 && (
                <p className="text-base leading-6 text-slate-600 text-opacity-80 mt-2">
                  {failedCustomerEditCount} of the selected transactions could not
                  apply a new customer because they already have a vendor.
                </p>
              )}
            </div>
          ) : isOpen === 'editFailReview' ? (
            <p className="text-base leading-6 text-slate-600 text-opacity-80">
              {failedEditCount} of the selected transactions could not be edited
              because they have been reconciled, are pending, or were attached
              to a bill or invoice.
            </p>
          ) : isOpen === 'split' ? (
            <p className="text-base leading-6 text-slate-600 text-opacity-80">
              A split transaction can only be deleted by editing the split
              details inside the{' '}
              <span
                onClick={(e) => {
                  e.stopPropagation();
                  openParentTransaction();
                }}
                className="text-indigo-500 cursor-pointer"
              >
                parent transaction
              </span>
              .
            </p>
          ) : (
            <p className="text-base leading-6 text-slate-600 text-opacity-80">
              Are you sure you want to delete the selected transaction(s)? This
              action cannot be undone.
            </p>
          )}
        </div>
        <div className="border-t border-gray-300 h-20 flex items-center justify-end gap-4 px-5">
          {isOpen === 'error' ||
          isOpen === 'split' ||
          isOpen === 'editFail' ||
          isOpen === 'editFailReview' ? (
            <button
              className="w-[5.625rem] h-11 flex items-center justify-center py-2.5 px-[1.875rem] rounded-[0.313rem] bg-indigo-500 shadow text-white leading-6"
              onClick={() => setIsOpen('')}
            >
              Close
            </button>
          ) : (
            <>
              <button
                className="w-[5.625rem] h-11 flex items-center justify-center py-2.5 px-[1.875rem] rounded-[0.313rem] border border-gray-300 border-opacity-40 shadow bg-white text-slate-600 leading-6"
                onClick={() => setIsOpen('')}
                disabled={deleteModalLoading}
              >
                No
              </button>
              <button
                className="w-[5.625rem] h-11 flex items-center justify-center py-2.5 px-[1.875rem] rounded-[0.313rem] bg-indigo-500 shadow text-white leading-6"
                onClick={() => handleDeleteTransaction(isOpen)}
                disabled={deleteModalLoading}
              >
                {deleteModalLoading ? (
                  <Loader width="w-6" height="h-6" color="#FFFFFF" />
                ) : (
                  'Yes'
                )}
              </button>
            </>
          )}
        </div>
      </ModalBasic>
    </>
  );
};

export default DeleteTransactionModal;
