import React from 'react';
import ModalBasic from '../../../../components/ModalBasic';
import TextInputDash from '../../../../components/TextInputDash';

const SalesRepReportTitleModal = ({
  isOpen,
  setIsOpen,
  reportTitle,
  setReportTitle,
  isLoading,
}) => (
  <ModalBasic
    modalOpen={isOpen}
    setModalOpen={setIsOpen}
    title="Edit Report Title"
    smallModal
  >
    <div className="p-5 border-b border-[#D0D5DD]">
      <TextInputDash
        width="w-full"
        required
        id="reportTitle"
        label="Report Title"
        value={reportTitle}
        onChange={(e) => setReportTitle(e.target.value)}
        paddingBottom=""
      />
    </div>
    <div className="p-5 flex gap-5 items-center justify-end">
      <button
        className="w-[5.625rem] h-11 flex items-center justify-center py-2.5 px-[1.875rem] rounded-[0.313rem] bg-white border border-slate-600 text-slate-600 text-base leading-6"
        onClick={() => setIsOpen(false)}
        type="button"
      >
        Cancel
      </button>
      <button
        className="w-[5.625rem] h-11 flex items-center justify-center py-2.5 px-[1.875rem] rounded-[0.313rem] bg-indigo-500 shadow text-white leading-6"
        disabled={isLoading}
        type="submit"
      >
        {isLoading ? (
          <svg
            className="animate-spin w-4 h-4 fill-current shrink-0 mr-2"
            viewBox="0 0 16 16"
          >
            <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z" />
          </svg>
        ) : (
          'Submit'
        )}
      </button>
    </div>
  </ModalBasic>
);

export default SalesRepReportTitleModal;
