/* eslint-disable no-restricted-syntax */
import React from 'react';
import DropdownFull from '../../components/DropdownFull';

const checkHeaderDuplicates = (headerList, fileHeaderValue) => {
  let count = 0;
  for (const header of headerList) {
    if (header.fileHeader === fileHeaderValue) {
      count++;
      if (count > 1) {
        return true;
      }
    }
  }
  return false;
};

const Step2 = ({ headersObj, setHeadersObj, headers, HeadersColumns }) => (
  <div className="">
    <h3 className="text-2xl mb-2 font-semibold">Map your fields</h3>
    <div className="bg-transparent  w-full rounded-[5px]  my-5 dark:bg-transparent border border-[#D0D5DD] max-w-[600px] ">
      {/* Table */}
      <div
        className="overflow-x-auto h-full w-full "
        id="createBillTableContainer"
      >
        <table className="table-auto w-full  ">
          {/* Table header */}
          <thead className="text-base font-semibold text-[#667085] border-b border-[#D0D5DD] bg-[#A0CD850F]">
            <tr>
              <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                <div className="font-medium text-left">COUNT Field</div>
              </th>
              <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                <div className="font-medium text-left">Your Field</div>
              </th>
            </tr>
          </thead>
          {/* Table body */}
          <tbody className="text-sm divide-y divide-[#D0D5DD] ">
            {headers?.map(
              (obj, i) =>
                HeadersColumns?.includes(obj?.systemheader) && (
                  <tr key={i}>
                    <td className="px-2 first:pl-5 last:pr-5  py-2 text-left text-base">
                      {obj?.systemheader}
                    </td>
                    <td className="px-2 first:pl-5 last:pr-5  py-2 text-left">
                      <DropdownFull
                        options={headers?.map((d) => ({
                          id: d?.fileHeader,
                          name: d?.fileHeader,
                        }))}
                        className="!w-fit !h-fit"
                        name="name"
                        bottomOfTable
                        setSelected={(id) => {
                          const temp = JSON.parse(JSON.stringify(headersObj));
                          temp[i].fileHeader = id;
                          setHeadersObj(temp);
                        }}
                        selected={headersObj?.[i]?.fileHeader}
                        ids={['importAccountsContainer']}
                        isTableDropdown
                        height="h-[38px] min-w-[175px] max-w-[175px] text-nowrap overflow-hidden text-ellipsis"
                        error={checkHeaderDuplicates(
                          headersObj,
                          headersObj?.[i]?.fileHeader,
                        )}
                        buttonTextClass="max-w-[90%] overflow-hidden text-ellipsis"
                        buttonTextClassSub="max-w-[100%] overflow-hidden text-ellipsis"
                      />
                    </td>
                  </tr>
                ),
            )}
          </tbody>
        </table>
      </div>
    </div>
  </div>
);

export default Step2;
