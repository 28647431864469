import moment from 'moment';
import React from 'react';

const PayrollHistoryTable = ({ payrolls }) => (
  <div className="w-full overflow-x-auto">
    <table className="w-full table-fixed">
      <thead className="w-full shadow-md rounded-[0.313rem] text-sm leading-[1.125rem] text-slate-600">
        <tr className="w-full py-[1.438rem] px-[1.375rem] h-16">
          <th className="text-left font-medium px-4">Check Date</th>
          <th className="text-left font-medium  ">Payroll Type</th>
          <th className="text-left font-medium  ">Description</th>
          <th className="text-center font-medium  ">Total Debited</th>
          <th className="text-center font-medium  ">View</th>
        </tr>
      </thead>
      <tbody className=" text-sm divide-y divide-gray-300 border-b border-gray-300">
        {payrolls?.length > 0 ? (
          payrolls?.map((payroll) => (
            <tr
              key={payroll?.id}
              className="w-full px-[1.25rem] h-16 hover:bg-[#a0cd85] hover:bg-opacity-5"
            >
              <td className="text-left text-[14px] px-4">
                {moment(payroll?.checkDate).format('DD MMM, YYYY')}
              </td>
              <td className="text-left text-[14px] capitalize">Regular</td>
              <td className="text-left text-[14px] capitalize">
                {moment(payroll?.payPeriodStartDate)?.format('MMM Do, YYYY')} -{' '}
                {moment(payroll?.payPeriodEndDate)?.format('MMM Do, YYYY')}
              </td>
              <td className="text-center text-[14px]">
                $
                {parseFloat(payroll?.companyDebit)?.toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </td>
              <td className="text-center text-[14px]">
                <a
                  href={`https://app.gusto-demo.com/payroll_admin/pay/payrolls/${payroll?.itemId}/details`}
                  target="_blank"
                  rel="noreferrer"
                  className="text-indigo-500 underline"
                >
                  View Details
                </a>
              </td>
            </tr>
          ))
        ) : (
          <tr className="w-full py-[1.438rem] px-[1.375rem] h-16">
            <td colSpan={4} className="text-center text-[14px] px-4">
              No Data Found
            </td>
          </tr>
        )}
      </tbody>
    </table>
  </div>
);

export default PayrollHistoryTable;
