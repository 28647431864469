import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import AppLayout from '../../../components/AppLayout';
import {
  getQuickBookCustomers,
  getQuickBookVendors,
  getTeamById,
} from '../../../API/backend_helper';
import ImportBox from './ImportBox';
import { setTeam } from '../../../redux/Team/reducer';

const QuickBooksSettings = () => {
  const { team } = useSelector((state) => state.Team);
  const dispatch = useDispatch();

  const [customerLoading, setCustomerLoading] = useState(false);
  const [vendorLoading, setVendorLoading] = useState(false);

  const loadTeam = async () => {
    try {
      const res = await getTeamById(team?.id);
      dispatch(setTeam(res));
    } catch (error) {
      console.error(error);
    }
  };

  const syncCustomers = async () => {
    setCustomerLoading(true);
    try {
      await getQuickBookCustomers();
      loadTeam();
      toast.success('Customers have been successfully imported');
      setCustomerLoading(false);
    } catch (err) {
      setCustomerLoading(false);
      console.log('err', err);
    }
  };

  const syncVendors = async () => {
    setVendorLoading(true);
    try {
      await getQuickBookVendors();
      loadTeam();
      toast.success('Vendors have been successfully imported');
      setVendorLoading(false);
    } catch (err) {
      setVendorLoading(false);
      console.log('err', err);
    }
  };

  return (
    <AppLayout>
      <main className="relative grow ">
        {/* Content */}
        <div className=" px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto h-full flex flex-col">
          {/* Page header */}
          <div className="flex flex-col sm:flex-row gap-2 sm:gap-0 justify-between mb-10">
            <h1 className="text-black text-opacity-60 text-4xl font-medium leading-[2.375rem]">
              Quickbooks Settings
            </h1>
          </div>
          <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-6">
            <ImportBox
              title="Import Customers"
              text="Import all of your customers from Quickbooks with a single click."
              buttonText="Import Customers"
              handleClick={syncCustomers}
              loading={customerLoading}
              lastSync={team?.app?.quickbooks?.[0]?.customerLastImportedAt}
            />
            <ImportBox
              title="Import Vendors"
              text="Import all of your vendors from Quickbooks with a single click"
              buttonText="Import Vendors"
              handleClick={syncVendors}
              loading={vendorLoading}
              lastSync={team?.app?.quickbooks?.[0]?.vendorLastImportedAt}
            />
          </div>
        </div>
      </main>
    </AppLayout>
  );
};

export default QuickBooksSettings;
