import React, { useState, useRef, useEffect } from 'react';
import Transition from '../utils/Transition';
import useClickOutside from '../utils/useClickOutside';

// eslint-disable-next-line no-unused-vars
let searchTimeout = null;

function FontsDropdown({
  options,
  selected,
  setSelected,
  name,
  scrollIntoView = true,
  noSearch,
  fontFamily,
  className = '',
  error = '',
  disabled = false,
  placeholder = '',
  height = '',
}) {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const trigger = useRef(null);
  const dropdown = useRef(null);

  const [allOptions, setAllOptions] = useState(options);
  const [filteredOptions, setFilteredOptions] = useState(options);

  useEffect(() => {
    setAllOptions(options);
  }, [options]);

  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!dropdown.current) return;
      if (
        !dropdownOpen ||
        dropdown.current.contains(target) ||
        trigger.current.contains(target)
      )
        return;
      setDropdownOpen(false);
      setSearchValue('');
    };
    document.addEventListener('click', clickHandler);
    return () => document.removeEventListener('click', clickHandler);
  });

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!dropdownOpen || keyCode !== 27) return;
      setDropdownOpen(false);
      setSearchValue('');
    };
    document.addEventListener('keydown', keyHandler);
    return () => document.removeEventListener('keydown', keyHandler);
  });

  useClickOutside(dropdown, () => {
    if (dropdownOpen) {
      setDropdownOpen(false);
      setSearchValue('');
      setFilteredOptions(options);
    }
  });

  useEffect(() => {
    if (scrollIntoView) {
      if (dropdownOpen && scrollIntoView) {
        dropdown.current?.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [dropdownOpen]);

  const onFilter = (e) => {
    const text = e.target.value;
    setSearchValue(e.target.value);
    searchTimeout = null;
    searchTimeout = setTimeout(() => {
      const filter = allOptions?.filter((o) =>
        o?.name?.toLowerCase()?.includes(text?.toLowerCase()),
      );
      setFilteredOptions(filter);
    }, 500);
  };

  return (
    <>
      <div className={`relative inline-flex h-full w-full ${className}`}>
        <button
          type="button"
          ref={trigger}
          className={`
                  ${height || 'h-12'}
                 px-3 text-base tracking-normal max-h-full btn font-normal w-full justify-between   bg-white dark:bg-slate-800 border-[#D0D5DD] dark:border-slate-700 hover:border-slate-400
          dark:hover:border-slate-600 text-slate-600 hover:text-slate-600 dark:text-slate-300
          dark:hover:text-slate-200
          ${error ? '!border-rose-400 !text-rose-400' : ''}
          ${disabled ? '!border-[#66708533] cursor-not-allowed' : ''}

          `}
          aria-label="Select date range"
          aria-haspopup="true"
          onClick={() => setDropdownOpen(!dropdownOpen)}
          aria-expanded={dropdownOpen}
          disabled={disabled}
        >
          <span className="flex items-center">
            <span
              className={`${
                options?.find((opt) => opt.id === selected)
                  ? 'text-slate-600'
                  : 'text-slate-600 text-opacity-80'
              } ${selected || ''}
             `}
            >
              {options?.find((opt) => opt.id === selected)?.[name] ||
                (placeholder && !disabled ? placeholder : 'Select...')}
            </span>
          </span>
          <svg
            className={`shrink-0 ml-1 text-[#667085cc] ${
              error ? 'fill-rose-400' : ''
            } ${dropdownOpen ? 'rotate-180' : ''}`}
            width="10"
            height="6"
            viewBox="0 0 10 6"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9 1.83398L5.70707 4.58988C5.31818 4.91535 4.68182 4.91535 4.29293 4.58988L1 1.83398"
              stroke="#667085"
              strokeOpacity={`${disabled ? '0.8' : ''}`}
              strokeWidth="1.5"
              strokeMiterlimit="10"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>
        <Transition
          show={dropdownOpen}
          tag="div"
          className={`z-10 absolute top-full
           left-0 w-full bg-white dark:bg-slate-800 border border-slate-200 dark:border-slate-700 py-1.5 rounded shadow-lg overflow-hidden mt-1`}
          enter="transition ease-out duration-100 transform"
          enterStart="opacity-0 -translate-y-2"
          enterEnd="opacity-100 translate-y-0"
          leave="transition ease-out duration-100"
          leaveStart="opacity-100"
          leaveEnd="opacity-0"
        >
          {dropdownOpen && (
            <div
              ref={dropdown}
              className={`font-medium ${
                fontFamily ? 'max-h-fit' : 'max-h-48'
              } scrollbar text-sm text-slate-600 `}
              // onFocus={() => setDropdownOpen(true)}
              // onBlur={() => setDropdownOpen(false)}
            >
              {!noSearch && (
                <div className="relative flex-1 px-3 mb-1">
                  <input
                    type="text"
                    placeholder="Search..."
                    className={`form-input text-indigo-600  placeholder:!text-indigo-600 !border-indigo-600 my-2 pl-8 w-full inline-block font-normal `}
                    value={searchValue}
                    onChange={(e) => onFilter(e)}
                    autoFocus
                    // ref={searchInput}
                    // onClick={(e) => e.stopPropagation()}
                  />
                  <svg
                    className="absolute left-3 top-[50%] -translate-y-[50%] pl-2 w-6 h-6"
                    width="16"
                    height="17"
                    viewBox="0 0 16 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M14 14.5L11.6667 12.1667M13.3333 8.16667C13.3333 11.2963 10.7963 13.8333 7.66667 13.8333C4.53705 13.8333 2 11.2963 2 8.16667C2 5.03705 4.53705 2.5 7.66667 2.5C10.7963 2.5 13.3333 5.03705 13.3333 8.16667Z"
                      stroke="#E48642"
                      strokeWidth="1.2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
              )}
              <div className="overflow-auto max-h-48 scrollbar">
                {filteredOptions?.map((option) => (
                  <button
                    type="button"
                    key={option.id}
                    tabIndex="0"
                    className={`flex border-none items-center gap-2 text-[13px] hover:text-slate-800 w-full dark:hover:bg-slate-700/20 py-1 px-3 cursor-pointer ${
                      option.id === selected &&
                      'text-indigo-500 hover:!text-indigo-600'
                    }`}
                    onClick={() => {
                      setSelected(option.id);
                      setDropdownOpen(false);
                    }}
                    style={fontFamily ? { fontFamily: option[name] } : {}}
                  >
                    <span
                      className={`text-slate-600 hover:text-slate-800 ${
                        option.id === selected &&
                        '!text-indigo-500 !hover:text-indigo-600'
                      }`}
                    >
                      {option[name]}
                    </span>
                    <svg
                      className={`shrink-0 mr-2 fill-current text-indigo-500 ${
                        option.id !== selected && 'invisible'
                      }`}
                      width="12"
                      height="9"
                      viewBox="0 0 12 9"
                    >
                      <path d="M10.28.28L3.989 6.575 1.695 4.28A1 1 0 00.28 5.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28.28z" />
                    </svg>
                  </button>
                ))}
              </div>
            </div>
          )}
        </Transition>
      </div>
      {error && <div className=" text-xs mt-1 text-rose-400">{error}</div>}
    </>
  );
}

export default FontsDropdown;
