import React, { useEffect, useRef, useState } from "react";
import ModalBasic from "../../../components/ModalBasic";
import MembersDropdown from "./MembersDropdown";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { addTeamMember } from "../../../API/backend_helper";
import { ADMIN, ACCOUNTANT, VIEWER } from "../../../utils/Utils";
const MembersModal = ({ isOpen, setIsOpen, getMembers, localInvite, setLocalInvite }) => {
  const [loading, setLoading] = useState(false);
  const formikRef = useRef(null);
  const handleSubmit = async (values, formikHandler) => {
    const { email, role } = values;
    setLoading(true);
    try {
      const res = await addTeamMember({
        email:email?.toLowerCase(),
        role: role,
      });
      await getMembers();
      setLoading(false);
      formikHandler.resetForm();
      setIsOpen(false);
    } catch (err) {
      console.log("err", err);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!isOpen) {
      setLoading(false);
      setLocalInvite({});
      formikRef.current?.resetForm();
    }
  }, [isOpen]);

  return (
    <ModalBasic
      id="basic-modal"
      modalOpen={isOpen}
      setModalOpen={setIsOpen}
      title='Send Invite'
    >
      <Formik
        enableReinitialize={true}
        initialValues={{
          email: localInvite?.email || "",
          role: localInvite?.role || ADMIN,
        }}
        validationSchema={Yup.object({
          email: Yup.string()
            .required("Please Enter Email")
            .email("Must be valid Email"),
          role: Yup.string().required("Please Select Access"),
        })}
        onSubmit={handleSubmit}
        innerRef={formikRef}
      >
        {(validation) => (
          <Form>
            <div className="px-5 pt-4 pb-1 space-y-3 min-h-[250px]">
              <div>
                <label
                  className="block text-sm font-medium mb-1"
                  htmlFor="email"
                >
                  Email <span className="text-rose-500">*</span>
                </label>
                <input
                  id="email"
                  type="text"
                  placeholder="Email"
                  name="email"
                  className={`form-input w-full ${
                    validation.touched.email &&
                    validation.errors.email &&
                    "border-rose-300"
                  }`}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.email || ""}
                  invalid={
                    validation.touched.email && validation.errors.email
                      ? true
                      : false
                  }
                />
                {validation.touched.email && validation.errors.email ? (
                  <div className="text-xs mt-1 text-rose-500">
                    {validation.errors.email}
                  </div>
                ) : null}
              </div>
              <div>
                <label className="block text-sm font-medium mb-1">
                  Access <span className="text-rose-500">*</span>
                </label>
                <MembersDropdown
                  fullWidth
                  onChangeValue={(_, value) => {
                    validation.setFieldValue("role", value);
                  }}
                  value={validation.values.role}
                />
                {validation.touched.role && validation.errors.role ? (
                  <div className="text-xs mt-1 text-rose-500">
                    {validation.errors.role}
                  </div>
                ) : null}
              </div>{" "}
            </div>

            {/* Modal footer */}
            <div className="flex flex-wrap  justify-end space-x-2 px-5 py-4 mt-3">
              <button
                type="submit"
                className="btn-sm bg-indigo-500 hover:bg-indigo-600 text-white
            disabled:!bg-indigo-100  disabled:text-slate-400  disabled:cursor-not-allowed"
                disabled={loading}
              >
                {loading && (
                  <svg
                    className="animate-spin w-4 h-4 fill-current shrink-0 mr-2"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z" />
                  </svg>
                )}
                Send Invite
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </ModalBasic>
  );
};

export default MembersModal;
