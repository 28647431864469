import React, { useState, useEffect, useRef } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { phone as phoneFormatter } from 'phone';
import TextInputDash from '../../components/TextInputDash';
import DropdownFull from '../../components/DropdownFull';
import {
  getCurrencySymbol,
  PEOPLE_TYPES,
  transformAccounts,
} from '../../utils/Utils';
import WarningBox from '../../components/WarningBox';
import FormBox from './FormBox';
import ExpenseReportingAccountsModal from './ExpenseRepAccountsModal';
import {
  createExpenseReporter,
  createSubPaymentMethod,
  fetchSubscriptions,
  getAccounts,
  getAccountsByExpenseReporter,
} from '../../API/backend_helper';
import AlertBox from '../../components/AlertBox';
import AddressBar from '../../components/AddressBar';

const PeopleForm = ({
  setActivePeople = () => {},
  activePeople = null,
  handleAddPeople,
  handleUpdatePeople = null,
  setFormOpen,
  setTitle = () => {},
  isModalView = false,
  team = {},
  preSelectExpenseReport = false,
}) => {
  const { subscription } = useSelector((state) => state.Subscription);

  const formikRef = useRef(null);
  const navigate = useNavigate();
  const [params] = useSearchParams();

  const createPersonParam = params.get('addPerson') || false;
  const _firstName = decodeURI(params.get('firstName') || '');
  const _lastName = decodeURI(params.get('lastName') || '');
  const _email = decodeURI(params.get('email') || '');
  const _position = decodeURI(params.get('position') || '');
  const _type = decodeURI(params.get('type') || '');
  const _phone = decodeURI(params.get('phone') || '');
  const _street = decodeURI(params.get('street') || '');
  const _street2 = decodeURI(params.get('street2') || '');
  const _state = decodeURI(params.get('state') || '');
  const _country = decodeURI(params.get('country') || '');
  const _city = decodeURI(params.get('city') || '');
  const _zipCode = decodeURI(params.get('zipCode') || '');
  const _role = decodeURI(params.get('roles') || '');

  const [loading, setLoading] = useState(false);
  const [role, setRole] = useState([]);
  const [expenseModal, setExpenseModal] = useState(false);
  const [peopleSelectedExpAcc, setPoepleSelectExpAcc] = useState([]);
  const [expenseReporterAccounts, setExpenseReporterAccounts] = useState([]);
  const [notAssignedAccounts, setNotAssignedAccounts] = useState([]);
  const [localSelectedAccounts, setLocalSelectedAccounts] = useState([]);
  const [subscriptions, setSubscriptions] = useState(null);
  const [paymentMethodLoader, setPaymentMehtodLoader] = useState(false);
  const [loadingSubsription, setLoadingSubscription] = useState(false);
  const [allAccounts, setAllAccounts] = useState([]);

  useEffect(() => {
    if (createPersonParam === 'true' && formikRef?.current?.setFieldValue) {
      formikRef?.current?.setFieldValue('firstName', _firstName);
      formikRef?.current?.setFieldValue('lastName', _lastName);
      formikRef?.current?.setFieldValue('email', _email);
      formikRef?.current?.setFieldValue('position', _position);
      formikRef?.current?.setFieldValue('type', _type);
      formikRef?.current?.setFieldValue('phone', _phone);
      formikRef?.current?.setFieldValue('address.street', _street);
      formikRef?.current?.setFieldValue('address.street2', _street2);
      formikRef?.current?.setFieldValue('address.state', _state);
      formikRef?.current?.setFieldValue('address.country', _country);
      formikRef?.current?.setFieldValue('address.city', _city);
      formikRef?.current?.setFieldValue('address.zipCode', _zipCode);
      if (_role) {
        const tempRole = _role ? JSON.parse(_role) : [];
        setRole(tempRole);
      }
    }
  }, [createPersonParam, formikRef?.current?.setFieldValue]);

  const loadAccountsByExpenseReporter = async () => {
    try {
      const accountsRes = await getAccountsByExpenseReporter(activePeople?.id);
      const accounts = [
        ...accountsRes?.data?.myAccounts,
        ...accountsRes?.data?.notAssignedAccounts,
      ];
      if (accountsRes?.data?.myAccounts?.length > 0) {
        setPoepleSelectExpAcc(
          accountsRes?.data?.myAccounts?.map((acc) => acc?.id),
        );
      }
      setAllAccounts(accounts);
      if (accountsRes) {
        setExpenseReporterAccounts(
          transformAccounts(accounts, 'PHYSICAL_ACCOUNT', 'expense'),
        );
      }
    } catch (err) {
      console.log('err', err);
    }
  };

  const loadSubscriptions = async () => {
    try {
      setLoadingSubscription(true);
      const res = await fetchSubscriptions();
      setSubscriptions(res?.data?.perSeat);
      setLoadingSubscription(false);
    } catch (err) {
      setLoadingSubscription(false);
      console.log('err', err);
    }
  };

  const loadNotAssignedAccounts = async () => {
    try {
      const accountsRes = await getAccounts({ notAssignedToReporter: true });
      if (accountsRes) {
        setAllAccounts(accountsRes);
        setNotAssignedAccounts(
          transformAccounts(accountsRes, 'PHYSICAL_ACCOUNT', 'expense'),
        );
      }
    } catch (err) {
      console.log('err', err);
    }
  };

  useEffect(() => {
    if (team?.featureExpenseReport) {
      if (!activePeople?.id) {
        loadNotAssignedAccounts();
      } else {
        loadAccountsByExpenseReporter();
      }
    }
  }, [activePeople, team]);

  useEffect(() => {
    if (preSelectExpenseReport) {
      setRole(['expense reporting']);
    }
  }, [preSelectExpenseReport]);

  useEffect(() => {
    if (activePeople?.role) {
      setRole(activePeople?.role);
    }
  }, [activePeople]);

  useEffect(() => {
    loadSubscriptions();
  }, []);

  const handleSubmit = async (values) => {
    const temp = { ...values };
    if (values?.phone) {
      temp.phone = phoneFormatter(values?.phone)?.phoneNumber || values?.phone;
    }
    temp.role = preSelectExpenseReport
      ? role?.filter((r) => r !== 'expense reporting')
      : role;
    try {
      setLoading(true);
      if (activePeople) {
        await handleUpdatePeople(temp, activePeople?.id);
        if (role?.includes('expense reporting')) {
          await createExpenseReporter(activePeople?.id, {
            accountIDs: peopleSelectedExpAcc,
          });
          setLocalSelectedAccounts([]);
          setPoepleSelectExpAcc([]);
        }
      } else {
        if (role?.includes('expense reporting') && !preSelectExpenseReport) {
          temp.accountIDs = peopleSelectedExpAcc;
          temp.addToExpenseReporter = true;
        }
        await handleAddPeople(temp);
        setLocalSelectedAccounts([]);
        setPoepleSelectExpAcc([]);
      }
      setLoading(false);
      if (activePeople) {
        toast.success('Person updated successfully');
      } else {
        toast.success('Person created successfully');
      }
      setTitle('Peoples');
      formikRef.current?.resetForm();
      setRole([]);
      setFormOpen(false);
      if (createPersonParam === 'true') {
        navigate('/people');
      }
    } catch (e) {
      console.log('error', e);
      setLoading(false);
    }
  };

  const onRoleChange = (val) => {
    const tempRole = [...role];
    if (tempRole?.includes(val)) {
      if (val === 'expense reporting' && expenseModal) {
        setExpenseModal(false);
      }
      const filter = tempRole?.filter((r) => r !== val);
      setRole(filter);
    } else {
      if (val === 'expense reporting' && !expenseModal) {
        setExpenseModal(true);
      }
      tempRole?.push(val);
      setRole(tempRole);
    }
  };

  const onConnectPaymentMethod = async () => {
    try {
      setPaymentMehtodLoader(true);
      const v = formikRef?.current?.values;
      const redirectUrl = `${window?.location?.href}?addPerson=true&firstName=${encodeURIComponent(v?.firstName || '')}&lastName=${encodeURIComponent(v?.lastName || '')}&email=${encodeURIComponent(v?.email || '')}&position=${encodeURIComponent(v?.position || '')}&type=${encodeURIComponent(v?.type || '')}&phone=${encodeURIComponent(v?.phone || '')}&street=${encodeURIComponent(v?.address?.street || '')}&street2=${encodeURIComponent(v?.address?.street2 || '')}&country=${encodeURIComponent(v?.address?.country || '')}&city=${encodeURIComponent(v?.address?.city || '')}&state=${encodeURIComponent(v?.address?.state || '')}&zipCode=${encodeURIComponent(v?.address?.zipCode || '')}&roles=${JSON.stringify(role)}`;
      const res = await createSubPaymentMethod(encodeURIComponent(redirectUrl));
      if (res?.data?.session?.url) {
        window.location = res?.data?.session?.url;
      } else {
        toast.error(
          "'Oops! Something unexpected occurred. Please contact support for assistance.",
        );
      }
      // setSubscriptions(res?.data?.perSeat);
      setPaymentMehtodLoader(false);
    } catch (err) {
      setPaymentMehtodLoader(false);
      console.log('err', err);
    }
  };

  const getAccountNameById = (id) => {
    const filter = allAccounts?.filter?.((acc) => acc?.id === id);
    if (filter?.length > 0) {
      return filter?.[0]?.name;
    }
    return '';
  };

  if (loadingSubsription) {
    return (
      <div className="flex flex-col gap-2 items-center pb-2 justify-center mt-8 min-h-fit">
        <svg
          width="29"
          height="29"
          viewBox="0 0 29 29"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="animate-spin w-10 h-10 fill-current shrink-0"
        >
          <path
            d="M14.5 2.41699V7.25033"
            stroke="#E48642"
            strokeWidth="2.55882"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M14.5 21.749V26.5824"
            stroke="#E48642"
            strokeWidth="2.55882"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M5.95703 5.95703L9.37662 9.37662"
            stroke="#E48642"
            strokeWidth="1.58955"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M19.623 19.6211L23.0426 23.0407"
            stroke="#E48642"
            strokeWidth="1.58955"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M2.41699 14.5H7.25033"
            stroke="#E48642"
            strokeWidth="1.58955"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M21.749 14.5H26.5824"
            stroke="#E48642"
            strokeWidth="1.58955"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M5.95703 23.0407L9.37662 19.6211"
            stroke="#E48642"
            strokeWidth="1.58955"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M19.623 9.37662L23.0426 5.95703"
            stroke="#E48642"
            strokeWidth="1.58955"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        <p className="text-slate-600 font-medium text-[0.875rem] text-center">
          Fetching data, please wait...
        </p>
      </div>
    );
  }

  return (
    <>
      <ExpenseReportingAccountsModal
        setOpen={setExpenseModal}
        open={expenseModal}
        activeReporterId={activePeople?.id}
        peopleSelectedExpAcc={peopleSelectedExpAcc}
        setPoepleSelectExpAcc={setPoepleSelectExpAcc}
        expenseReporterAccounts={expenseReporterAccounts}
        setExpenseReporterAccounts={setExpenseReporterAccounts}
        notAssignedAccounts={notAssignedAccounts}
        setNotAssignedAccounts={setNotAssignedAccounts}
        localSelectedAccounts={localSelectedAccounts}
        setLocalSelectedAccounts={setLocalSelectedAccounts}
      />
      <Formik
        enableReinitialize
        initialValues={{
          firstName: activePeople?.firstName || '',
          lastName: activePeople?.lastName || '',
          email: activePeople?.email || '',
          position: activePeople?.position || '',
          type: activePeople?.type || '',
          phone: activePeople?.phone || '',
          address: {
            street: activePeople?.address?.street || '',
            street2: activePeople?.address?.street2 || '',
            country: activePeople?.address?.country || '',
            city: activePeople?.address?.city || '',
            state: activePeople?.address?.state || '',
            zipCode: activePeople?.address?.zipCode || '',
          },
        }}
        validate={(values) => {
          const errors = {};
          const { phone } = values;
          if (
            phone &&
            !phoneFormatter(phone, { validateMobilePrefix: false })?.isValid
          ) {
            errors.phone = 'Phone number is not valid';
          }

          return errors;
        }}
        validationSchema={Yup.object({
          email: Yup.string()
            .email('Invalid email format')
            .required('Please Enter Email')
            .max(100, 'Email must be at most 100 characters'),
          firstName: Yup.string()
            .trim()
            .required('Please Enter First Name')
            .min(3, 'First Name must be at least 3 characters')
            .max(20, 'First Name must be at most 20 characters'),
          lastName: Yup.string()
            .trim()
            .required('Please Enter Last Name')
            .min(3, 'Last Name must be at least 3 characters')
            .max(20, 'Last Name must be at most 20 characters'),
          type: Yup.string().required('Please Select Type'),
          // phone: Yup.string()
          // .matches(phoneRegex, 'Phone number is not valid')
          // .nullable().optional(),
          position: Yup.string()
            .optional()
            .min(3, 'Position must be at least 3 characters')
            .max(30, 'Position must be at most 30 characters'),
          address: Yup.object({
            street: Yup.string().max(100, 'Max 100 characters are allowed'),
            street2: Yup.string().max(100, 'Max 100 characters are allowed'),
            country: Yup.string().max(50, 'Max 50 characters are allowed'),
            city: Yup.string().max(50, 'Max 50 characters are allowed'),
            state: Yup.string().max(50, 'Max 50 characters are allowed'),
            zipCode: Yup.string().max(50, 'Max 50 characters are allowed'),
          }),
        })}
        onSubmit={handleSubmit}
        innerRef={formikRef}
      >
        {(validation) => (
          <Form>
            {activePeople?.provider === 'gusto' && (
              <WarningBox
                content={`
                You can only edit the Phone Number and Role of the ${activePeople?.provider?.charAt(0)?.toUpperCase() + activePeople?.provider?.slice(1)} imported person.`}
              />
            )}
            <div
              className={`flex flex-col items-start gap-2.5 w-[100%] ${
                isModalView ? 'p-4' : ''
              } ${activePeople?.provider ? 'mt-6' : ''}`}
            >
              <div className="grid grid-cols-12 gap-4 w-full">
                {activePeople?.provider !== 'gusto' && (
                  <>
                    <div className="col-span-full sm:col-span-6 md:col-span-4">
                      <TextInputDash
                        label="First Name"
                        id="firstName"
                        name="firstName"
                        placeholder="First Name"
                        value={validation.values.firstName || ''}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        paddingBottom="pb-5"
                        required
                        error={
                          validation.touched.firstName &&
                          validation.errors.firstName
                        }
                        disabled={activePeople?.provider}
                      />
                    </div>
                    <div className="col-span-full sm:col-span-6 md:col-span-4">
                      <TextInputDash
                        label="Last Name"
                        id="lastName"
                        name="lastName"
                        placeholder="Last Name"
                        value={validation.values.lastName || ''}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        paddingBottom="pb-5"
                        error={
                          validation.touched.lastName &&
                          validation.errors.lastName
                        }
                        required
                        disabled={activePeople?.provider}
                      />
                    </div>
                    <div className="col-span-full sm:col-span-6 md:col-span-4">
                      <label
                        className={`block text-sm font-medium mb-2 ${
                          validation.touched.type && validation.errors.type
                            ? 'text-rose-500 '
                            : ''
                        } `}
                        htmlFor="currency"
                      >
                        Type <span className="text-rose-500">*</span>
                      </label>
                      <DropdownFull
                        options={PEOPLE_TYPES}
                        scrollIntoView={false}
                        name="name"
                        setSelected={(id) =>
                          validation.setFieldValue('type', id)
                        }
                        selected={validation.values.type}
                        error={
                          validation.touched.type && validation.errors.type
                        }
                        accountStyle
                        className="!h-fit"
                        disabled={activePeople?.provider}
                      />
                    </div>
                    <div className="col-span-full sm:col-span-6 md:col-span-4">
                      <TextInputDash
                        label="Email Address"
                        id="email"
                        name="email"
                        required
                        placeholder="Email Address"
                        value={validation.values.email || ''}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        paddingBottom="pb-5"
                        error={
                          validation.touched.email && validation.errors.email
                        }
                        disabled={activePeople?.provider}
                      />
                    </div>
                  </>
                )}
                <div className="col-span-full sm:col-span-6 md:col-span-4">
                  <TextInputDash
                    label="Phone"
                    id="phone"
                    name="phone"
                    type="text"
                    placeholder="Phone"
                    value={validation.values.phone || ''}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    paddingBottom="pb-5"
                    error={validation.touched.phone && validation.errors.phone}
                  />
                </div>
                {activePeople?.provider !== 'gusto' && (
                  <div className="col-span-full sm:col-span-6 md:col-span-4">
                    <TextInputDash
                      label="Position"
                      id="position"
                      name="position"
                      placeholder="Position / Title"
                      value={validation.values.position || ''}
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      error={
                        validation.touched.position &&
                        validation.errors.position
                      }
                      disabled={activePeople?.provider}
                    />
                  </div>
                )}
              </div>
            </div>

            {activePeople?.provider !== 'gusto' && (
              <div
                className={`flex flex-col items-start gap-2.5 w-[100%] mb-[1.875rem] mb-4 ${
                  isModalView ? 'px-4' : ''
                }`}
              >
                <label className="text-sm font-medium text-slate-600 block">
                  Address
                </label>
                <AddressBar
                  disabled={activePeople?.provider}
                  validation={validation}
                  values={validation.values.address}
                  streetId="address.street"
                  streetValue={validation.values.address.street}
                  streetError={
                    validation.touched?.address?.street &&
                    validation.errors?.address?.street
                  }
                  street2Id="address.street2"
                  street2Value={validation.values.address.street2}
                  street2Error={
                    validation.touched?.address?.street2 &&
                    validation.errors?.address?.street2
                  }
                  cityId="address.city"
                  cityValue={validation.values.address.city}
                  cityError={
                    validation.touched?.address?.city &&
                    validation.errors?.address?.city
                  }
                  stateId="address.state"
                  stateValue={validation.values.address.state}
                  stateError={
                    validation.touched?.address?.state &&
                    validation.errors?.address?.state
                  }
                  zipCodeId="address.zipCode"
                  zipCodeValue={validation.values.address.zipCode}
                  zipCodeError={
                    validation.touched?.address?.zipCode &&
                    validation.errors?.address?.zipCode
                  }
                  countryId="address.country"
                  countryValue={validation.values.address.country}
                  countryError={
                    validation.touched?.address?.country &&
                    validation.errors?.address?.country
                  }
                />
              </div>
            )}

            {subscriptions?.id && (
              <div
                className={`border-t border-[#D0D5DD] pt-6  ${
                  isModalView ? 'm-4' : ''
                }`}
              >
                {activePeople?.seatStatus !== 'confirmed' &&
                  activePeople?.seatStatus !== 'confiriming' && (
                    <>
                      <h2 className="font-medium text-[#6D7585] mb-2 text-xl">
                        Unlock powerful management features for employees and
                        contractors
                      </h2>
                      <p className="font-medium text-[#6D7585] mb-4">
                        For just{' '}
                        {getCurrencySymbol(
                          subscriptions?.prices?.[0]?.currency,
                        )}
                        {parseFloat(
                          subscriptions?.prices?.[0]?.unit_amount / 100,
                        )?.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                        /per person per month, you can streamline your business
                        operations with Payroll (in partnership with Gusto),
                        Time Tracking, Expense Claim Reporting, and Sales Rep
                        Tracking, all accessible through our intuitive employee
                        mobile app.
                      </p>
                    </>
                  )}
                <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-8">
                  <FormBox
                    checked={role?.includes('time tracking')}
                    objKey="time tracking"
                    onRoleChange={onRoleChange}
                    title="Time Tracking"
                    details="Allow this employee to clock in/out and track their hours effortlessly through the COUNT mobile app, providing clear visibility into work hours."
                  />
                  <FormBox
                    checked={role?.includes('sales rep')}
                    onRoleChange={onRoleChange}
                    title="Sales Rep Tracking"
                    objKey="sales rep"
                    details="Assign this person as a sales rep to customers and invoices, track their performance, and generate insightful reports to compare and optimize your sales team's effectiveness."
                  />

                  <FormBox
                    checked={role?.includes('expense reporting')}
                    onRoleChange={onRoleChange}
                    title="Expense Claim Reporting"
                    objKey="expense reporting"
                    expenseModal={expenseModal}
                    setExpenseModal={setExpenseModal}
                    hideAccounts={preSelectExpenseReport}
                    enabledExpenseReporting={team?.featureExpenseReport}
                    getAccountNameById={getAccountNameById}
                    peopleSelectedExpAcc={peopleSelectedExpAcc}
                    details="Simplify expense management and streamline the reimbursement process by enabling this person to upload receipts and submit expenses for approval directly through the COUNT mobile app."
                  />
                </div>
              </div>
            )}
            {/* {!subscription?.default_payment_method && role?.length > 0 && ( */}
            {!subscription?.default_payment_method &&
              !team?.hasPaymentMethod &&
              role?.length > 0 && (
                <div className={isModalView ? 'px-4' : ''}>
                  <AlertBox
                    type="warning"
                    heading="Connect Payment Method"
                    text="Please connect a payment method to enable assigning roles to people"
                    hideActionButton={
                      subscription?.default_payment_method ||
                      team?.hasPaymentMethod
                    }
                    actionButtonText="Connect Payment Method"
                    onActionButton={onConnectPaymentMethod}
                    actionLoading={paymentMethodLoader}
                  />
                </div>
              )}

            {/* Close/Add Buttons */}
            <div
              className={`w-full flex justify-end items-center gap-4 mt-[1.875rem] ${
                isModalView ? 'p-4 pb-8' : 'pb-4'
              }`}
            >
              <button
                className="flex items-center justify-center py-2.5 px-[1.875rem] rounded-[0.313rem]  bg-white border border-slate-600 w-[6.875rem] h-11 text-slate-600 leading-6"
                onClick={(event) => {
                  event.preventDefault();
                  formikRef.current?.resetForm();
                  setActivePeople(null);
                  setTitle('Peoples');
                  setFormOpen(false);
                  setRole([]);
                }}
                type="button"
              >
                {activePeople ? 'Cancel' : 'Close'}
              </button>
              <button
                className="flex items-center justify-center py-2.5 px-[1.875rem] rounded-[0.313rem] bg-indigo-500 shadow w-fit h-11 text-white leading-6
                        disabled:!bg-indigo-300 disabled:text-white disabled:cursor-not-allowed"
                type="submit"
                disabled={
                  loading ||
                  (!subscription?.default_payment_method &&
                    !team?.hasPaymentMethod &&
                    role?.length > 0) ||
                  (peopleSelectedExpAcc?.length === 0 &&
                    role?.includes('expense reporting') &&
                    !preSelectExpenseReport)
                }
              >
                {loading && (
                  <svg
                    className="animate-spin w-4 h-4 fill-current shrink-0 mr-2"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z" />
                  </svg>
                )}
                {`${activePeople ? `Update` : `Add`} ${
                  (!preSelectExpenseReport || role?.length > 1) &&
                  (subscription?.default_payment_method ||
                    team?.hasPaymentMethod) &&
                  activePeople?.seatStatus !== 'confirmed' &&
                  activePeople?.seatStatus !== 'confiriming' &&
                  role?.length > 0
                    ? `& Pay ${getCurrencySymbol(subscriptions?.prices?.[0]?.currency)}${parseFloat(
                        subscriptions?.prices?.[0]?.unit_amount / 100,
                      )?.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}/month`
                    : ''
                }`}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default PeopleForm;
