import React, { useEffect } from 'react';

const FontLoader = ({ font }) => {
  useEffect(() => {
    if (!font) return;

    // Create a <link> element and append it to the document head
    const link = document.createElement('link');
    link.href = `https://fonts.googleapis.com/css2?family=${font.replace(/\s/g, '+')}&display=swap`;
    link.rel = 'stylesheet';
    document.head.appendChild(link);

    // Cleanup function to remove the link element when component unmounts
    return () => {
      document.head.removeChild(link);
    };
  }, [font]);

  return null;
};

export default FontLoader;
