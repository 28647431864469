import React from "react";

export const IncreaseIcon = () => {
  return (
    <svg
      width='17'
      height='17'
      viewBox='0 0 17 17'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clip-path='url(#clip0_2843_77792)'>
        <path
          d='M16.2913 4.25L9.56217 10.9792L6.02051 7.4375L0.708008 12.75'
          stroke='#667085'
          stroke-width='1.5'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M12.041 4.25L16.291 4.25V8.5'
          stroke='#667085'
          stroke-width='1.5'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_2843_77792'>
          <rect width='17' height='17' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};
