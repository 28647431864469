import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { updateConnectionLink } from '../API/backend_helper';
import PlaidLink from '../pages/settings/PlaidLink';

const BankWarning = ({ teamProp, parentCB }) => {
  const { team } = useSelector((state) => state.Team);

  const [token, setToken] = useState(null);

  const getTeamApi = async () => {
    if (parentCB) {
      parentCB();
    }
  };

  useEffect(() => {
    // if (isAuthenticated && localUser?.firstName && authToken) {
    const updateLinkToken = async (id) => {
      try {
        const response = await updateConnectionLink(id);
        const { linkToken } = response.data;
        setToken(linkToken);
      } catch (e) {
        console.log('error', e);
      }
    };
    if (
      team?.connectionAlerts?.[0]?.status === 'active' &&
      team?.connectionAlerts?.[0]?.requiredAction === 'UPDATE_PLAID_AUTH'
    ) {
      updateLinkToken(team?.connectionAlerts?.[0]?.connectionId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [team]);

  if (
    !teamProp ||
    !(
      team?.connectionAlerts?.[0]?.status === 'active' &&
      team?.connectionAlerts?.[0]?.requiredAction === 'UPDATE_PLAID_AUTH'
    )
  ) {
    return null;
  }

  return (
    <div className="w-full border border-[#FF4B4B]  p-4 flex items-center self-stretch gap-6 rounded-xl bg-[#FFFBFA] my-5 mt-0">
      <div className="flex items-center gap-4">
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_3759_2938)">
            <path
              d="M9.99935 6.66699V10.0003M9.99935 13.3337H10.0077M18.3327 10.0003C18.3327 14.6027 14.6017 18.3337 9.99935 18.3337C5.39698 18.3337 1.66602 14.6027 1.66602 10.0003C1.66602 5.39795 5.39698 1.66699 9.99935 1.66699C14.6017 1.66699 18.3327 5.39795 18.3327 10.0003Z"
              stroke="#D92D20"
              strokeWidth="1.66667"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
          <defs>
            <clipPath id="clip0_3759_2938">
              <rect width="20" height="20" fill="white" />
            </clipPath>
          </defs>
        </svg>

        <p className="text-[#B42318] text-left font-medium leading-tight">
          An error occurred in your connected bank account (
          {team?.connectionAlerts?.[0]?.connection?.institution?.name}) which
          needs to be fixed!
        </p>
      </div>
      <div className="flex justify-end items-center gap-5 flex-grow flex-shrink-0 flex-basis-0">
        {/* <button className="whitespace-nowrap font-normal rounded-[0.313rem] flex w-[7.875rem] h-11 py-2.5 px-[1.125rem] items-center justify-center gap-2 border border-indigo-500 bg-indigo-500 shadow-sm text-white leading-6">
          Resolve Now
        </button> */}
        <PlaidLink
          loadData={getTeamApi}
          token={token}
          buttonText="Resolve Now"
          team={team}
          buttonClass="h-11 bg-[#B42318] text-white rounded-[5px] px-[18px]"
          connectionError
          connectionId={team?.connectionAlerts?.[0]?.connectionId}
        />
      </div>
    </div>
  );
};

export default BankWarning;
