import React from 'react';
import { useNavigate } from 'react-router-dom';
import { getSingleTransaction } from '../../API/backend_helper';

const NotificationBox = ({ Icon, text, handleView }) => (
  <div className="flex justify-between items-center pb-4 pt-5 border-b border-[#D0D5DD] pr-5">
    <div className="flex items-center gap-4">
      <div className="h-[26px] w-[26px] rounded-md border border-[#D0D5DD99] inline-flex justify-center items-center">
        <Icon />
      </div>
      <p className="text-sm font-medium text-[#667085]">{text}</p>
    </div>
    <button
      onClick={(e) => {
        e.stopPropagation();
        handleView();
      }}
      type="button"
      className="btn border-none px-0 py-0 outline-none shadow-none text-[#E48642] text-sm font-medium"
    >
      View
    </button>
  </div>
);

const Notifications = ({
  setSelectedRow,
  setRowDetailsOpen,
  commentsMentioned,
  setCurrentTransDetailTab,
  reloadComments,
}) => {
  const navigate = useNavigate();
  const handleCommentView = async (transactionId, currentTab = 'comments') => {
    try {
      const res = await getSingleTransaction(transactionId);
      setSelectedRow(res);
      setRowDetailsOpen(true);
      setCurrentTransDetailTab(currentTab);
      setTimeout(() => reloadComments({ loading: false }), 1000);
    } catch (err) {
      console.log('err', err);
    }
  };

  return (
    <div>
      {commentsMentioned?.length > 0 ? (
        commentsMentioned?.map(
          (d, i) =>
            i < 6 && (
              <NotificationBox
                key={d?.id}
                text={
                  d?.notificationType === 'transaction'
                    ? `You were tagged in a comment in Transaction: ${d?.description}`
                    : `${d?.message}`
                }
                // eslint-disable-next-line react/no-unstable-nested-components
                Icon={() => (
                  // Comment Icon
                  <svg
                    width="14"
                    height="15"
                    viewBox="0 0 14 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12.899 6.90608L7.15521 12.6498C6.45156 13.3535 5.4972 13.7488 4.50209 13.7488C3.50697 13.7488 2.55261 13.3535 1.84896 12.6498C1.14531 11.9462 0.75 10.9918 0.75 9.9967C0.75 9.00159 1.14531 8.04723 1.84896 7.34358L7.59271 1.59983C8.06181 1.13073 8.69805 0.867187 9.36146 0.867188C10.0249 0.867188 10.6611 1.13073 11.1302 1.59983C11.5993 2.06893 11.8629 2.70517 11.8629 3.36858C11.8629 4.03199 11.5993 4.66823 11.1302 5.13733L5.38021 10.8811C5.14566 11.1156 4.82754 11.2474 4.49584 11.2474C4.16413 11.2474 3.84601 11.1156 3.61146 10.8811C3.37691 10.6465 3.24514 10.3284 3.24514 9.9967C3.24514 9.665 3.37691 9.34688 3.61146 9.11233L8.91771 3.81233"
                      stroke="#667085"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                )}
                handleView={() => {
                  if (d?.notificationType === 'transaction') {
                    handleCommentView(d?.associationId);
                  } else if (d?.notificationType === 'task') {
                    navigate(`/inbox`, { state: { filter: 'to-me' } });
                  }
                }}
              />
            ),
        )
      ) : (
        <p className="text-center">No Notification Found.</p>
      )}
      {/* <NotificationBox
        Icon={() => (
          <svg
            width="17"
            height="16"
            viewBox="0 0 17 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15.166 3.99967V5.61301C15.166 6.66634 14.4993 7.33301 13.446 7.33301H11.166V2.67301C11.166 1.93301 11.7727 1.33301 12.5127 1.33301C13.2393 1.33967 13.906 1.63301 14.386 2.11301C14.866 2.59967 15.166 3.26634 15.166 3.99967Z"
              stroke="#667085"
              stroke-width="1.09091"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M1.83398 4.66634V13.9997C1.83398 14.553 2.46065 14.8663 2.90065 14.533L4.04065 13.6797C4.30732 13.4797 4.68065 13.5063 4.92065 13.7463L6.02732 14.8597C6.28732 15.1197 6.71398 15.1197 6.97398 14.8597L8.09398 13.7397C8.32732 13.5063 8.70065 13.4797 8.96065 13.6797L10.1007 14.533C10.5407 14.8597 11.1673 14.5463 11.1673 13.9997V2.66634C11.1673 1.93301 11.7673 1.33301 12.5007 1.33301H5.16732H4.50065C2.50065 1.33301 1.83398 2.52634 1.83398 3.99967V4.66634Z"
              stroke="#667085"
              stroke-width="1.09091"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M6.5 8.67285H8.5"
              stroke="#667085"
              stroke-width="1.09091"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M6.5 6.00684H8.5"
              stroke="#667085"
              stroke-width="1.09091"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M4.49805 8.66699H4.50403"
              stroke="#667085"
              stroke-width="1.45455"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M4.49805 6H4.50403"
              stroke="#667085"
              stroke-width="1.45455"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        )}
        text="Uncategorized transaction for Account Ending 5678"
      />
      <NotificationBox
        Icon={() => (
          <svg
            width="16"
            height="15"
            viewBox="0 0 16 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.5 2.5H11.75C12.0815 2.5 12.3995 2.6317 12.6339 2.86612C12.8683 3.10054 13 3.41848 13 3.75V12.5C13 12.8315 12.8683 13.1495 12.6339 13.3839C12.3995 13.6183 12.0815 13.75 11.75 13.75H4.25C3.91848 13.75 3.60054 13.6183 3.36612 13.3839C3.1317 13.1495 3 12.8315 3 12.5V3.75C3 3.41848 3.1317 3.10054 3.36612 2.86612C3.60054 2.6317 3.91848 2.5 4.25 2.5H5.5"
              stroke="#667085"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M9.875 1.25H6.125C5.77982 1.25 5.5 1.52982 5.5 1.875V3.125C5.5 3.47018 5.77982 3.75 6.125 3.75H9.875C10.2202 3.75 10.5 3.47018 10.5 3.125V1.875C10.5 1.52982 10.2202 1.25 9.875 1.25Z"
              stroke="#667085"
              stroke-width="0.931803"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        )}
        text="Overdue bill for Account Ending 8908"
      />

      <NotificationBox
        Icon={() => (
          <svg
            width="18"
            height="17"
            viewBox="0 0 18 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M3.6669 8.08894L2.4344 6.85645L1.20898 8.08894"
              stroke="#667085"
              stroke-width="1.15909"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M14.334 8.91113L15.5665 10.1436L16.799 8.91113"
              stroke="#667085"
              stroke-width="1.15909"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M15.5593 10.1439V8.50058C15.5593 4.87391 12.6197 1.94141 9.00016 1.94141C6.93183 1.94141 5.08307 2.90476 3.87891 4.39935"
              stroke="#667085"
              stroke-width="1.15909"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M2.43945 6.85645V8.49978C2.43945 12.1265 5.37904 15.059 8.99862 15.059C11.067 15.059 12.9157 14.0956 14.1199 12.601"
              stroke="#667085"
              stroke-width="1.15909"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M7.0332 9.87507C7.0332 10.6365 7.61758 11.2504 8.34362 11.2504H9.82522C10.4568 11.2504 10.9704 10.7133 10.9704 10.0521C10.9704 9.33201 10.6575 9.07819 10.1912 8.91291L7.81237 8.08652C7.34605 7.92125 7.0332 7.66743 7.0332 6.94729C7.0332 6.28618 7.54674 5.74902 8.17834 5.74902H9.65994C10.386 5.74902 10.9704 6.36291 10.9704 7.12437"
              stroke="#667085"
              stroke-width="1.15909"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M8.99609 4.95801V12.0413"
              stroke="#667085"
              stroke-width="1.15909"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        )}
        text="Invoice #8998 is past due now"
      />
      <NotificationBox
        Icon={() => (
          <svg
            width="14"
            height="15"
            viewBox="0 0 14 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.899 6.90608L7.15521 12.6498C6.45156 13.3535 5.4972 13.7488 4.50209 13.7488C3.50697 13.7488 2.55261 13.3535 1.84896 12.6498C1.14531 11.9462 0.75 10.9918 0.75 9.9967C0.75 9.00159 1.14531 8.04723 1.84896 7.34358L7.59271 1.59983C8.06181 1.13073 8.69805 0.867187 9.36146 0.867188C10.0249 0.867188 10.6611 1.13073 11.1302 1.59983C11.5993 2.06893 11.8629 2.70517 11.8629 3.36858C11.8629 4.03199 11.5993 4.66823 11.1302 5.13733L5.38021 10.8811C5.14566 11.1156 4.82754 11.2474 4.49584 11.2474C4.16413 11.2474 3.84601 11.1156 3.61146 10.8811C3.37691 10.6465 3.24514 10.3284 3.24514 9.9967C3.24514 9.665 3.37691 9.34688 3.61146 9.11233L8.91771 3.81233"
              stroke="#667085"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        )}
        text="Sarah K. tagged you in a comment in Bills"
      />
      <NotificationBox
        Icon={() => (
          <svg
            width="17"
            height="16"
            viewBox="0 0 17 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15.166 3.99967V5.61301C15.166 6.66634 14.4993 7.33301 13.446 7.33301H11.166V2.67301C11.166 1.93301 11.7727 1.33301 12.5127 1.33301C13.2393 1.33967 13.906 1.63301 14.386 2.11301C14.866 2.59967 15.166 3.26634 15.166 3.99967Z"
              stroke="#667085"
              stroke-width="1.09091"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M1.83398 4.66634V13.9997C1.83398 14.553 2.46065 14.8663 2.90065 14.533L4.04065 13.6797C4.30732 13.4797 4.68065 13.5063 4.92065 13.7463L6.02732 14.8597C6.28732 15.1197 6.71398 15.1197 6.97398 14.8597L8.09398 13.7397C8.32732 13.5063 8.70065 13.4797 8.96065 13.6797L10.1007 14.533C10.5407 14.8597 11.1673 14.5463 11.1673 13.9997V2.66634C11.1673 1.93301 11.7673 1.33301 12.5007 1.33301H5.16732H4.50065C2.50065 1.33301 1.83398 2.52634 1.83398 3.99967V4.66634Z"
              stroke="#667085"
              stroke-width="1.09091"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M6.5 8.67285H8.5"
              stroke="#667085"
              stroke-width="1.09091"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M6.5 6.00684H8.5"
              stroke="#667085"
              stroke-width="1.09091"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M4.49805 8.66699H4.50403"
              stroke="#667085"
              stroke-width="1.45455"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M4.49805 6H4.50403"
              stroke="#667085"
              stroke-width="1.45455"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        )}
        text="Uncategorized transaction for Account Ending 5678"
      /> */}
    </div>
  );
};

export default Notifications;
