import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import InstanceBody from './InstanceBody';
import {
  assignTransactionToBill,
  unassignTransactionToBill,
} from '../../API/backend_helper';
import ModalBasic from '../../components/ModalBasic';
import TextInputDash from '../../components/TextInputDash';
import { getWarningMessage, VIEWER } from '../../utils/Utils';

const InstancesModal = ({
  isOpen,
  setIsOpen,
  instances,
  billId = null,
  activeBill = {},
  transaction = null,
  reloadData,
  setActiveInstance,
  activeInstance = null,
  setTransactionsModalOpen = null,
  currencies,
}) => {
  const navigate = useNavigate();
  const modalContent = useRef(null);
  const { accessType } = useSelector((state) => state.User);

  const [loading, setLoading] = useState(false);
  const [localInstances, setLocalInstances] = useState();
  const [unassignLoader, setUnassignLoader] = useState(false);

  useEffect(() => {
    if (instances?.length > 0) {
      const temp = instances.sort(
        (a, b) => new Date(a.dueDate) - new Date(b.dueDate),
      );
      setLocalInstances(temp);
    } else {
      setLocalInstances(instances);
    }
  }, [instances]);

  // useEffect(() => {
  //   if (!isOpen) {
  //     setActiveInstance(null);
  //   }
  // }, [isOpen]);

  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!isOpen || modalContent?.current?.contains?.(target)) return;
      setIsOpen(false);
    };
    document.addEventListener('click', clickHandler);
    return () => document.removeEventListener('click', clickHandler);
  });

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!isOpen || keyCode !== 27) return;
      setIsOpen(false);
    };
    document.addEventListener('keydown', keyHandler);
    return () => document.removeEventListener('keydown', keyHandler);
  });

  const handleTransactionAssignment = async () => {
    setLoading(true);
    try {
      const res = await assignTransactionToBill(billId, activeInstance?.id, {
        transactionId: transaction?.id,
      });
      if (res?.id) {
        setIsOpen(false);
        navigate('/bills');
      }
    } catch (err) {
      console.log('err', err);
    } finally {
      setLoading(false);
    }
  };

  const handleTransactionUnassign = async (instanceId, transId) => {
    setUnassignLoader(transId);
    try {
      await unassignTransactionToBill(billId, instanceId, {
        transactionId: transId,
      });
      reloadData();
      setUnassignLoader('');
      setIsOpen(false);
      toast.success('Transaction unassigned successfully');
    } catch (err) {
      console.log('err', err);
      setUnassignLoader('');
    }
  };

  return (
    <ModalBasic
      modalOpen={isOpen}
      setModalOpen={setIsOpen}
      title="Bill Instances"
      width="max-w-[792px]"
    >
      <div className="px-10 py-[30px]">
        <div className="flex justify-between items-center">
          {/* Warning Box  */}
          {/* Warning If Bill amount is high and Transaction amount is low  */}
          {parseFloat(transaction?.amount) <
            parseFloat(activeInstance?.total) && (
            <div className="p-3 py-1 h-fit rounded-md border w-fit font-medium text-indigo-500 bg-indigo-100 border-indigo-500 ">
              <div className="  flex items-center justify-center shrink-0  dark:bg-indigo-500/30">
                <svg
                  className="w-4 h-4 shrink-0 fill-current text-indigo-500 mr-3"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm1 12H7V7h2v5zM8 6c-.6 0-1-.4-1-1s.4-1 1-1 1 .4 1 1-.4 1-1 1z" />
                </svg>
                This bill will be partially paid for this ${transaction?.amount}{' '}
                transaction
              </div>
            </div>
          )}
          {/* Error If Bill amount is low and Transaction amount is high  */}
          {parseFloat(transaction?.amount) >
            parseFloat(activeInstance?.total) && (
            <div
              className="p-3 py-1 h-fit rounded-md border w-fit font-medium text-rose-500 bg-rose-100 dark:bg-rose-500/30 border-rose-500

              "
            >
              <div className="  flex items-center justify-center shrink-0  dark:bg-rose-500/30">
                <svg
                  className="w-4 h-4 shrink-0 fill-current text-rose-500 mr-3"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm1 12H7V7h2v5zM8 6c-.6 0-1-.4-1-1s.4-1 1-1 1 .4 1 1-.4 1-1 1z" />
                </svg>
                This bill cannot be paid for this ${transaction?.amount}{' '}
                transaction
              </div>
            </div>
          )}
          {/* Actions Buttons for Split Transaction and Update Instance  */}
          {/* {activeInstance && (
            <div className="flex flex-wrap justify-center w-fit space-x-2">
              {parseFloat(transaction?.amount) >
                parseFloat(activeInstance?.total) && (
                <button
                  onClick={() => {
                    setIsOpen(false);
                    handleSplitTransaction(transaction);
                  }}
                  className="btn-sm border-slate-200 dark:border-slate-700 hover:border-slate-300 dark:hover:border-slate-600 text-slate-600 dark:text-slate-300"
                >
                  Split Transaction
                </button>
              )}
              {parseFloat(transaction?.amount) !==
                parseFloat(activeInstance?.total) && (
                <button
                  onClick={() => {
                    setIsOpen(false);
                    handleOpenInstaceModal(activeInstance);
                  }}
                  className="btn-sm bg-indigo-500 hover:bg-indigo-600 text-white"
                >
                  Update Instance
                </button>
              )}
            </div>
          )} */}
        </div>
        <div className="grid grid-cols-12 gap-x-7 gap-y-0">
          <div className="col-span-full md:col-span-6">
            <TextInputDash
              label="Vendor Name"
              value={activeBill?.vendor?.name}
              readOnly
              inputClass="bg-[#D0D5DD30]"
              paddingBottom="pb-0"
            />
          </div>
          <div className="col-span-full md:col-span-6">
            {activeBill?.billNumber && (
              <TextInputDash
                label="Bill Number"
                value={activeBill?.billNumber}
                readOnly
                inputClass="bg-[#D0D5DD30]"
                paddingBottom="pb-0"
              />
            )}
          </div>
        </div>
        <div className="my-2 text-sm text-indigo-500">
          {activeBill?.recurring &&
            getWarningMessage(
              activeBill?.firstDueDate,
              activeBill?.recurringFrequency,
              activeBill?.recurringLastDate
                ? 'on'
                : activeBill?.recurringOccurrences > 0
                  ? 'after'
                  : ' ',
              activeBill?.recurringLastDate,
              activeBill?.recurringOccurrences,
            )}
        </div>
        <div>
          {/* Table */}
          {localInstances?.length > 0 ? (
            <>
              {transaction?.id && (
                <p className="my-3">Please Select Bill Instance</p>
              )}
              <div className="overflow-x-auto">
                <table className="table-auto w-full ">
                  {/* Table header */}
                  <thead className="text-sm text-[#667085] font-medium shadow-md border-none">
                    <tr>
                      {transaction?.id && (
                        <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                          <div className="font-medium  text-left">Select</div>
                        </th>
                      )}
                      <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                        <div className="font-medium  text-left">Due Date</div>
                      </th>

                      <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                        <div className="font-medium  text-left">Total</div>
                      </th>
                      <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                        <div className="font-medium  text-left">Status</div>
                      </th>
                      <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                        <span className="sr-only" />
                      </th>
                      <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                        <span className="sr-only">Menu</span>
                      </th>
                    </tr>
                  </thead>
                  {localInstances.map((instance, index) => (
                    <InstanceBody
                      key={index}
                      instance={instance}
                      activeInstance={activeInstance}
                      setActiveInstance={setActiveInstance}
                      transactionId={transaction?.id}
                      handleTransactionUnassign={handleTransactionUnassign}
                      instanceIndex={index}
                      setIsOpen={setIsOpen}
                      setTransactionsModalOpen={setTransactionsModalOpen}
                      activeBill={activeBill}
                      currencies={currencies}
                      unassignLoader={unassignLoader}
                    />
                  ))}
                </table>
              </div>
            </>
          ) : (
            <h3 className="text-base font-semibold text-center">
              No Instance Found.
            </h3>
          )}
        </div>

        {transaction?.id && accessType !== VIEWER && (
          <div className="flex justify-end my-3 w-full">
            <button
              className="btn-sm bg-indigo-500 hover:bg-indigo-600 text-white
                  disabled:bg-indigo-400    disabled:cursor-not-allowed "
              disabled={
                !activeInstance ||
                loading ||
                parseFloat(transaction?.amount) >
                  parseFloat(activeInstance?.total)
              }
              onClick={handleTransactionAssignment}
              type="button"
            >
              {loading && (
                <svg
                  className="animate-spin w-4 h-4 fill-current shrink-0 mr-2"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z" />
                </svg>
              )}
              Assign Transaction
            </button>
          </div>
        )}
      </div>
      <footer className="px-10 py-[27px] border-t border-[#D0D5DD] flex justify-end gap-4">
        <button
          onClick={() => {
            setIsOpen(false);
          }}
          type="button"
          className="h-[42px] border border-[#667085] text-[#667085] rounded-[5px] font-medium w-[110px]"
        >
          Cancel
        </button>
      </footer>
    </ModalBasic>
  );
};

export default InstancesModal;
