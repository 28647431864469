import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import TransactionsTable from './TransactionsTable';
import TransactionDetails from '../../transactions/TransactionPanel';
import {
  getAccounts,
  getAllProjects,
  getCustomers,
  getMerchants,
  getSingleTransaction,
  getTags,
} from '../../../API/backend_helper';
import { transformAccounts } from '../../../utils/Utils';
import ReceiptModal from '../../transactions/ReceiptModal';

const Transactions = ({
  transactions,
  loadTransactions,
  transactionsLocalLimit,
  setTransactionLocalLimit,
  isTransactionsLoaded,
  setIsTransactionsLoaded,
  overviewData,
}) => {
  const { accessType } = useSelector((state) => state.User);
  const { team } = useSelector((state) => state.Team);
  const { currencies } = useSelector((state) => state.Currencies);

  const [loading, setLoading] = useState(false);
  // Transaction Panel
  const [transactionsPanelOpen, setTransactionsPanelOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [tags, setTags] = useState([]);
  const [vendors, setVendors] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [allCategories, setAllCategories] = useState([]);
  const [allExpenseCategories, setAllExpenseCategories] = useState([]);
  const [receiptModalOpen, setReceiptModalOpen] = useState(false);
  const [activeReceipt, setActiveReceipt] = useState(null);
  const [currentTransDetailTab, setCurrentTransDetailTab] = useState('details');
  const [fromDropdown, setFromDropdown] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [projects, setProjects] = useState([]);

  const getTransSupportData = async () => {
    try {
      const [res, resTags, accountsRes] = await Promise.all([
        getMerchants({}),
        getTags(),
        getAccounts(),
      ]);
      setVendors(res?.vendors);
      setTags(resTags);
      setAccounts(transformAccounts(accountsRes, 'PHYSICAL_ACCOUNT'));
      setAllCategories(
        transformAccounts(accountsRes, 'CATEGORY_PHYSICAL_ACCOUNT'),
      );
      setAllExpenseCategories(
        transformAccounts(accountsRes, 'CATEGORY_PHYSICAL_ACCOUNT', 'expense'),
      );
    } catch (e) {
      console.log('error', e);
    }
  };

  const getCustomersApi = async () => {
    try {
      const res = await getCustomers({});
      setCustomers(res?.data?.records);
    } catch (e) {
      console.log('error', e);
    }
  };

  const findSplitParent = async (transaction) => {
    const res = await getSingleTransaction(transaction?.splitParentId);
    setSelectedRow(res);
    return res;
  };

  const getProjectsApi = async () => {
    try {
      const res = await getAllProjects({});
      const filteredProjects = res?.filter(
        (project) =>
          project?.status === 'Not started' ||
          project?.status === 'In progress',
      );
      setProjects(filteredProjects);
    } catch (e) {
      console.log('error', e);
    }
  };

  const loadData = async () => {
    setLoading(true);
    try {
      await loadTransactions({});
    } catch (err) {
      console.log('err', err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!isTransactionsLoaded) {
      loadData();
      getTransSupportData();
      getCustomersApi();
      getProjectsApi();
      setIsTransactionsLoaded(true);
    }
  }, []);

  return (
    <>
      <ReceiptModal
        isOpen={receiptModalOpen}
        setIsOpen={setReceiptModalOpen}
        receipt={activeReceipt}
      />
      {selectedRow?.account?.name && (
        <TransactionDetails
          transactionPanelOpen={transactionsPanelOpen}
          setTransactionPanelOpen={setTransactionsPanelOpen}
          transaction={selectedRow}
          setTransaction={setSelectedRow}
          reloadTransaction={loadTransactions}
          tags={tags}
          allCategories={allCategories}
          setAllCategories={setAllCategories}
          vendors={vendors || []}
          setVendors={setVendors}
          accessType={accessType}
          setReceiptModalOpen={setReceiptModalOpen}
          setActiveReceipt={setActiveReceipt}
          currentTab={currentTransDetailTab}
          setCurrentTab={setCurrentTransDetailTab}
          accounts={accounts}
          setFromDropdown={setFromDropdown}
          fromDropdown={fromDropdown}
          allExpenseCategories={allExpenseCategories}
          customers={customers}
          team={team}
          currencies={currencies}
          projects={projects}
          findSplitParent={findSplitParent}
        />
      )}
      {loading ? (
        <div className="flex flex-col gap-2 items-center pb-2 justify-center mt-8">
          <svg
            width="29"
            height="29"
            viewBox="0 0 29 29"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="animate-spin w-10 h-10 fill-current shrink-0"
          >
            <path
              d="M14.5 2.41699V7.25033"
              stroke="#E48642"
              strokeWidth="2.55882"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M14.5 21.749V26.5824"
              stroke="#E48642"
              strokeWidth="2.55882"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M5.95703 5.95703L9.37662 9.37662"
              stroke="#E48642"
              strokeWidth="1.58955"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M19.623 19.6211L23.0426 23.0407"
              stroke="#E48642"
              strokeWidth="1.58955"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M2.41699 14.5H7.25033"
              stroke="#E48642"
              strokeWidth="1.58955"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M21.749 14.5H26.5824"
              stroke="#E48642"
              strokeWidth="1.58955"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M5.95703 23.0407L9.37662 19.6211"
              stroke="#E48642"
              strokeWidth="1.58955"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M19.623 9.37662L23.0426 5.95703"
              stroke="#E48642"
              strokeWidth="1.58955"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <p className="text-slate-600 font-medium text-[0.875rem] text-center">
            Fetching data, please wait...
          </p>
        </div>
      ) : (
        <div className="space-y-10">
          {/* Transaction Summary  */}
          <div className="p-6 border border-[#D0D5DD] rounded-[12px]">
            <h3 className="text-[22px] font-medium text-[#00000099] mb-3">
              Transaction Summary
            </h3>
            <div className="grid gap-4 grid-cols-1 sm:grid-cols-3 divide-y sm:divide-y-0 sm:divide-x divide-[#D0D5DD]">
              <div className="">
                <p className="text-[#667085] font-medium mb-2">
                  Incoming Transactions
                </p>
                <h3 className="text-[28px] font-semibold text-[#101828]">
                  {overviewData?.incomingTransaction}
                </h3>
              </div>
              <div className="pt-6 sm:pt-0 sm:pl-[15%]">
                <p className=" text-[#667085] font-medium mb-2">
                  Outgoing Transactions
                </p>
                <h3 className="text-[28px] font-semibold text-[#101828]">
                  {overviewData?.outgoingTransaction}
                </h3>
              </div>
              <div className="pt-6 sm:pt-0 sm:pl-[15%]">
                <p className=" text-[#667085] font-medium mb-2">
                  Total Transacted
                </p>
                <h3 className="text-[28px] font-semibold text-[#101828]">
                  {overviewData?.incomingTransaction +
                    overviewData?.outgoingTransaction}
                </h3>
              </div>
            </div>
          </div>
          <div className="p-6 border border-[#D0D5DD] rounded-[12px]">
            <h3 className="text-[22px] font-medium text-[#00000099] mb-3">
              Transactions
            </h3>
            <TransactionsTable
              transactions={transactions}
              transactionsLocalLimit={transactionsLocalLimit}
              setTransactionLocalLimit={setTransactionLocalLimit}
              loadTransactions={loadTransactions}
              setSelectedRow={setSelectedRow}
              setTransactionsPanelOpen={setTransactionsPanelOpen}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default Transactions;
