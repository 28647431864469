import React from "react";
import ModalBasic from "../../../components/ModalBasic";

const KeyValuePair = ({ option, value }) => {
  return (
    <div className="flex gap-2 w-full max-w-full overflow-hidden">
      <span className="font-semibold whitespace-nowrap text-slate-600">
        {option}:
      </span>
      <div className=" flex-1 overflow-hidden w-fit">
        <span className="max-w-full w-full overflow-hidden break-words">
          {value}
        </span>
      </div>
    </div>
  );
};

const WebhookEventsModal = ({ isOpen, setIsOpen, selectedRow }) => {
  return (
    <ModalBasic
      modalOpen={isOpen}
      setModalOpen={setIsOpen}
      title="Details"
      smallModal
      width="max-w-screen-md"
    >
      <div className="py-[1.875rem] px-5 ">
        <h3 className="text-xl font-semibold mb-1 text-black text-opacity-60">
          Headers:
        </h3>
        {typeof selectedRow?.headers === "object" &&
          Object.keys(selectedRow?.headers)?.map((key) =>
            selectedRow?.headers[key] !== null &&
            typeof selectedRow?.headers[key] === "object" &&
            Object.keys(selectedRow?.headers[key])?.length > 0 ? (
              <div>
                <span className="font-semibold whitespace-nowrap text-slate-600">
                  {key}:
                </span>

                <div className="ml-4">
                  {Object.keys(selectedRow?.headers[key])?.map((key2) =>
                    selectedRow?.headers[key][key2] !== "object" ? (
                      <KeyValuePair
                        option={key2}
                        value={JSON.stringify(selectedRow?.headers[key][key2])}
                      />
                    ) : (
                      <></>
                    )
                  )}
                </div>
              </div>
            ) : typeof selectedRow?.headers[key] === "string" ? (
              <KeyValuePair option={key} value={selectedRow?.headers[key]} />
            ) : (
              <>
                <span className="font-semibold whitespace-nowrap text-slate-600">
                  {key} :
                </span>{" "}
                {JSON.stringify(selectedRow?.headers[key])}
              </>
            )
          )}
        <h3 className="text-xl mt-5 font-semibold mb-1 text-black text-opacity-60">
          Payload:
        </h3>
        {typeof selectedRow?.payload === "object" &&
          Object.keys(selectedRow?.payload)?.map((key) =>
            selectedRow?.payload[key] !== null &&
            typeof selectedRow?.payload[key] === "object" &&
            Object.keys(selectedRow?.payload[key])?.length > 0 ? (
              <div>
                <span className="font-semibold whitespace-nowrap text-slate-600">
                  {key}:
                </span>

                <div className="ml-4">
                  {Object.keys(selectedRow?.payload[key])?.map((key2) =>
                    selectedRow?.payload[key][key2] !== "object" ? (
                      <KeyValuePair
                        option={key2}
                        value={JSON.stringify(selectedRow?.payload[key][key2])}
                      />
                    ) : (
                      <></>
                    )
                  )}
                </div>
              </div>
            ) : typeof selectedRow?.payload[key] === "string" ? (
              <KeyValuePair option={key} value={selectedRow?.payload[key]} />
            ) : (
              <>
                <span className="font-semibold whitespace-nowrap text-slate-600">
                  {key} :
                </span>{" "}
                {JSON.stringify(selectedRow?.payload[key])}
              </>
            )
          )}
      </div>
    </ModalBasic>
  );
};

export default WebhookEventsModal;
