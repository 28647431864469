import React, { useEffect } from 'react';
import { Card, CloseBtn, EstimateIcon, StocksIcon } from '../svg';

const Trigger1 = ({
  triggerType,
  setTriggerType,
  setPanelStep,
  createNewNode,
  activeNodeId,
  isEditing,
  setIsEditing,
  updateNode,
  localNode,
  nodes,
  setActiveNodeId,
  setPanelOpen,
  automationType,
  setAutomationType,
  deleteNode,
}) => {
  useEffect(() => {
    if (isEditing && localNode) {
      setTriggerType(localNode?.data?.triggerType);
    }
  }, [localNode, isEditing]);

  useEffect(() => {
    if (nodes[0]?.type === 'trigger') {
      setTriggerType(nodes[0]?.data?.triggerType);
      setActiveNodeId(nodes[0]?.id);
      setIsEditing(true);
    }
  }, []);

  const createTriggerData = (type, newAutomationType) => {
    if (newAutomationType === 'transaction') {
      switch (type) {
        case 'created':
          return {
            name: 'Transaction is Added',
            triggerType: 'created',
            type: 'trigger',
            description: 'Rule is run when a transaction is added.',
          };
        case 'updated':
          return {
            name: 'Transaction is Updated',
            triggerType: 'updated',
            type: 'trigger',
            description: 'Rule is run when a transaction is updated.',
          };
        case 'deleted':
          return {
            name: 'Transaction is Deleted',
            triggerType: 'deleted',
            type: 'trigger',
            description: 'Rule is run when a transaction is deleted.',
          };

        default:
          return {};
      }
    } else if (newAutomationType === 'stockOnHand') {
      return {
        name: 'Product Stock Changes',
        triggerType: 'stockOnHand',
        type: 'trigger',
        description: 'Rule is run when a stock is changed.',
      };
    } else if (newAutomationType === 'estimateStatus') {
      return {
        name: 'Estimate Status Changes',
        triggerType: 'estimateStatus',
        type: 'trigger',
        description: 'Rule is run when an estimate status is changed.',
      };
    }
  };

  const handleNext = (type, newAutomationType) => {
    if (automationType !== newAutomationType) {
      nodes.forEach((n, i) => {
        if (i !== 0 && i !== nodes.length - 1) {
          deleteNode(n.id);
        }
      });
    }
    setTriggerType(type);
    const data = createTriggerData(type, newAutomationType);

    if (newAutomationType !== 'transaction') {
      data.filters = [];
    }
    if (isEditing) {
      updateNode(activeNodeId, data);
      setIsEditing(false);
    } else {
      createNewNode(activeNodeId, data);
    }

    if (newAutomationType === 'transaction') {
      setPanelStep('trigger2');
    } else {
      setPanelStep('component1');
    }
  };

  return (
    <div className="flex flex-col items-start gap-2.5 self-stretch">
      <div className="w-full flex justify-between items-center">
        <h3 className="text-black font-medium text-[1.375rem] leading-[2.375rem]">
          Add A Trigger
        </h3>
        <CloseBtn onClick={() => setPanelOpen(false)} />
      </div>

      {/* Transaction  */}
      <h4 className="text-black font-medium text-[1rem] leading-[2.375rem]">
        Transaction
      </h4>
      <div
        className={`flex flex-col items-start gap-2.5 self-stretch cursor-pointer hover:bg-[#a0cd85] hover:bg-opacity-10 ${
          triggerType !== 'stockOnHand' &&
          triggerType !== 'estimateStatus' &&
          'bg-[#a0cd85] bg-opacity-10'
        }`}
        onClick={() => {
          handleNext('created', 'transaction');
          setAutomationType('transaction');
        }}
        tabIndex="0"
        role="button"
      >
        <div className="flex items-center gap-2 self-stretch">
          <div className="w-9 h-9 rounded-[0.313rem] bg-[#EFFFE5] flex items-center justify-center">
            <Card />
          </div>
          <p className="text-slate-600 text-[14px] leading-[2.375rem]">
            Transaction is Created
          </p>
        </div>
      </div>

      {/* Estimate Status Changes */}
      <h4 className="text-black font-medium text-[1rem] leading-[2.375rem]">
        Invoices & Estimates
      </h4>
      <div
        className={`flex flex-col items-start gap-2.5 self-stretch cursor-pointer hover:bg-[#a0cd85] hover:bg-opacity-10 ${
          triggerType === 'estimateStatus' && 'bg-[#a0cd85] bg-opacity-10'
        }`}
        onClick={() => {
          handleNext('estimateStatus', 'estimateStatus');
          setAutomationType('estimateStatus');
        }}
        tabIndex="0"
        role="button"
      >
        <div className="flex items-center gap-2 self-stretch">
          <div className="w-9 h-9 rounded-[0.313rem] bg-[#EFFFE5] flex items-center justify-center">
            <EstimateIcon />
          </div>
          <p className="text-slate-600 text-[14px] leading-[2.375rem]">
            Estimate Status Changes
          </p>
        </div>
      </div>

      {/* Product & Services   */}
      <h4 className="text-black font-medium text-[1rem] leading-[2.375rem]">
        Product & Services
      </h4>
      <div
        className={`flex flex-col items-start gap-2.5 self-stretch cursor-pointer hover:bg-[#a0cd85] hover:bg-opacity-10 ${
          triggerType === 'stockOnHand' && 'bg-[#a0cd85] bg-opacity-10'
        }`}
        onClick={() => {
          handleNext('stockOnHand', 'stockOnHand');
          setAutomationType('stockOnHand');
        }}
        tabIndex="0"
        role="button"
      >
        <div className="flex items-center gap-2 self-stretch">
          <div className="w-9 h-9 rounded-[0.313rem] bg-[#EFFFE5] flex items-center justify-center">
            <StocksIcon />
          </div>
          <p className="text-slate-600 text-[14px] leading-[2.375rem]">
            Product Stock Changes
          </p>
        </div>
      </div>

      {/*   <div className="flex flex-col items-start gap-2.5 self-stretch">
          <div
            className={`flex items-center gap-2 self-stretch cursor-pointer hover:bg-[#a0cd85] hover:bg-opacity-10 ${
              triggerType === "updated" && "bg-[#a0cd85] bg-opacity-10"
            }`}
            onClick={() => handleNext("updated")}
          >
            <div className="w-9 h-9 rounded-[0.313rem] bg-[#EFFFE5] flex items-center justify-center">
              <CardUpdated />
            </div>
            <p className="text-slate-600 text-[14px] leading-[2.375rem]">
              Transaction is Updated
            </p>
          </div>
        </div>
        <div className="flex flex-col items-start gap-2.5 self-stretch">
          <div
            className={`flex items-center gap-2 self-stretch cursor-pointer hover:bg-[#a0cd85] hover:bg-opacity-10 ${
              triggerType === "deleted" && "bg-[#a0cd85] bg-opacity-10"
            }`}
            onClick={() => handleNext("deleted")}
          >
            <div className="w-9 h-9 flex items-center justify-center rounded-[0.313rem] bg-[#EFFFE5]">
              <CardDeleted />
            </div>
            <p className="text-slate-600 text-[14px] leading-[2.375rem]">
              Transaction is Deleted
            </p>
          </div>
        </div> */}
    </div>
  );
};

export default Trigger1;
