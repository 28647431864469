import React from 'react';
import Flatpickr from 'react-flatpickr';

const DatePickerDash = ({
  error,
  id,
  label,
  inputClass = '',
  required = false,
  width = '',
  icon = false,
  crossIcon = false,
  handleCrossIcon = () => {},
  crossIconClass = '',
  ...rest
}) => (
  <div className={`${width} relative `}>
    {label && (
      <label
        className={`text-sm font-medium text-slate-600 block mb-2 ${
          error ? '!text-rose-400' : ''
        }`}
        htmlFor={id}
      >
        {label}
        {required && <span className="text-rose-500">*</span>}
      </label>
    )}
    <div className="flex items-center">
      {' '}
      <Flatpickr
        className={`form-input w-full  border-[#D0D5DD]  appearance-none h-12 ${inputClass} ${
          error ? '!border-rose-400' : ''
        }`}
        autoComplete="off"
        {...rest}
      />
      {/* Cross Icon  */}
      {crossIcon && (
        <svg
          className={`h-5 w-5 absolute right-3 top-[14px] fill-slate-400 group-hover:fill-slate-600 cursor-pointer z-10 ${crossIconClass}`}
          viewBox="0 0 16 16"
          xmlns="http://www.w3.org/2000/svg"
          onClick={handleCrossIcon}
        >
          <path d="m7.95 6.536 4.242-4.243a1 1 0 1 1 1.415 1.414L9.364 7.95l4.243 4.242a1 1 0 1 1-1.415 1.415L7.95 9.364l-4.243 4.243a1 1 0 0 1-1.414-1.415L6.536 7.95 2.293 3.707a1 1 0 0 1 1.414-1.414L7.95 6.536Z" />
        </svg>
      )}
      {/* Date Icon  */}
      {icon && (
        <svg
          className="h-5 w-5 absolute right-3 top-3 pointer-events-none"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.75 3V5"
            stroke="#667085"
            strokeOpacity="0.8"
            strokeWidth="1.2"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M12.75 3V5"
            stroke="#667085"
            strokeOpacity="0.8"
            strokeWidth="1.2"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M3.75 8H15.75"
            stroke="#667085"
            strokeOpacity="0.8"
            strokeWidth="1.2"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M16.75 7.51351V13.4865C16.75 15.5946 15.6667 17 13.1389 17H7.36111C4.83333 17 3.75 15.5946 3.75 13.4865V7.51351C3.75 5.40541 4.83333 4 7.36111 4H13.1389C15.6667 4 16.75 5.40541 16.75 7.51351Z"
            stroke="#667085"
            strokeOpacity="0.8"
            strokeWidth="1.2"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M13.2455 11.5H13.2545"
            stroke="#667085"
            strokeOpacity="0.8"
            strokeWidth="1.2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M10.2455 11.5H10.2545"
            stroke="#667085"
            strokeOpacity="0.8"
            strokeWidth="1.2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M7.2455 11.5H7.2545"
            stroke="#667085"
            strokeOpacity="0.8"
            strokeWidth="1.2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M12.9955 14H13.0045"
            stroke="#667085"
            strokeOpacity="0.8"
            strokeWidth="1.2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M9.9955 14H10.0045"
            stroke="#667085"
            strokeOpacity="0.8"
            strokeWidth="1.2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M7.2455 14.5H7.2545"
            stroke="#667085"
            strokeOpacity="0.8"
            strokeWidth="1.2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      )}
    </div>

    {error && <div className="mt-1 text-xs text-rose-400">{error}</div>}
  </div>
);

export default DatePickerDash;
