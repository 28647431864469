import React, { useState } from 'react';
import moment from 'moment';
import Tooltip from '../../components/Tooltip';
import PaginationClassic from '../../components/PaginationClassic';
import {
  getExpenseReportStatusColor,
  getStringSegments,
  trimText,
} from '../../utils/Utils';
import BasicCheckboxDropdown from '../component/BasicCheckboxDropdown';

const REPORT_TYPES = [
  {
    label: 'Awaiting Approval',
    value: 'Submitted',
  },
  {
    label: 'Approved',
    value: 'Approved',
  },
  {
    label: 'Denied',
    value: 'Denied',
  },
];

const ReporterTransactionsTable = ({
  reporters,
  activeTransaction,
  selectAllTransactions,
  handleSelectAllTransactions,
  selectedTransactions,
  handleTransactionCheckbox,
  setTransactionFlyoutOpen,
  setActiveTransaction,
  team,
  updateReportStatus,
  statusLoader,
  handleBulkStatusUpdate,
  bulkStatusLoading,
  reportsTypesFilter,
  setReportsTypesFilter,
  getData,
  findCurrencySymbol,
  setLocalExpenseReportLimit,
}) => {
  const { expenseReport, page, limit, totalCount } = reporters;
  const [tagsLength, setTagsLength] = useState(20);

  return (
    <>
      <div className="bg-white flex-1">
        <div className="w-[300px] flex max-w-full mb-3">
          <BasicCheckboxDropdown
            selectedOptions={reportsTypesFilter}
            setSelectedOptions={setReportsTypesFilter}
            options={REPORT_TYPES}
            // apiFunc={getData}
            onApply={(v) => {
              if (reportsTypesFilter?.includes(v)) {
                getData({
                  type: reportsTypesFilter?.filter((val) => val !== v),
                  page: 1,
                  loading: true,
                });
              } else {
                getData({
                  loading: true,
                  type: [...reportsTypesFilter, v],
                  page: 1,
                });
              }
            }}
            onClear={() => {
              setReportsTypesFilter([]);
              getData({});
            }}
            label="Choose Type"
            type="type"
            apiFilter={reportsTypesFilter}
          />
        </div>
        <div className="bg-[#A0CD850F] py-4 gap-2 items-center w-full px-5 flex-wrap hidden sm:flex">
          <div className="flex items-center">
            <label className="inline-flex hover:text-slate-800">
              <input
                className="form-checkbox h-5 w-5 disabled:cursor-not-allowed disabled:opacity-50"
                type="checkbox"
                checked={
                  selectAllTransactions ||
                  (selectedTransactions?.length !== 0 &&
                    selectedTransactions?.length === expenseReport?.length)
                }
                onChange={handleSelectAllTransactions}
                disabled={
                  expenseReport?.filter((t) => t?.status === 'Submitted')
                    ?.length === 0
                }
              />
              <span className="text-sm ml-5 text-slate-600 leading-[18px] font-medium">
                Select All{' '}
                {selectedTransactions.length > 0 &&
                  ` : ${selectedTransactions.length} Selected`}
              </span>
            </label>
          </div>
          <hr className="h-8 w-[1px] bg-slate-300 mx-4" />
          {bulkStatusLoading ? (
            <div className="flex items-center justify-center">
              <svg
                className="animate-spin w-4 h-4 fill-current shrink-0 mr-2"
                viewBox="0 0 16 16"
              >
                <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z" />
              </svg>
            </div>
          ) : (
            <>
              <Tooltip
                content="Approve Selected Transactions"
                contentClassName="border-none overflow-visible text-sm text-[#667085] relative tooltipArrowDown"
              >
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    handleBulkStatusUpdate('Approved');
                  }}
                  disabled={selectedTransactions.length === 0}
                  className=" h-[26px] w-[26px] border border-[#D0D5DD99] rounded-md shadow-sm bg-white inline-flex justify-center items-center disabled:cursor-not-allowed"
                  type="button"
                >
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className={`h-4 w-4   ${
                      selectedTransactions.length > 0
                        ? 'stroke-[#78BD4F] text-[#78BD4F]'
                        : 'stroke-[#66708533] text-[#66708533]'
                    }`}
                  >
                    <path
                      d="M11.6673 3.5L5.25065 9.91667L2.33398 7"
                      stroke="#78BD4F"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="stroke-current"
                    />
                  </svg>
                </button>
              </Tooltip>
              <Tooltip
                content="Deny Selected Transactions"
                contentClassName="border-none overflow-visible text-sm text-[#667085] relative tooltipArrowDown"
              >
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    handleBulkStatusUpdate('Denied');
                  }}
                  className=" h-[26px] w-[26px] border border-[#D0D5DD99] rounded-md shadow-sm bg-white inline-flex justify-center items-center disabled:cursor-not-allowed"
                  disabled={selectedTransactions.length === 0}
                  type="button"
                >
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className={`${
                      selectedTransactions.length > 0
                        ? 'stroke-[#FF4B4B] text-[#FF4B4B]'
                        : 'stroke-[#66708533] text-[#66708533]'
                    }`}
                  >
                    <rect
                      x="1"
                      y="1"
                      width="14"
                      height="14"
                      rx="7"
                      strokeWidth="1.3"
                      className="stroke-current"
                    />
                    <path
                      d="M3.05026 3.05025L12.9498 12.9497"
                      strokeWidth="1.3"
                      strokeLinecap="round"
                      className="stroke-current"
                    />
                  </svg>
                </button>
              </Tooltip>
            </>
          )}
        </div>
        <div className="overflow-auto h-full">
          <table className="table-auto w-full dark:text-slate-300">
            {/* Table header */}
            <thead className="w-full shadow-md rounded-[0.313rem] text-sm leading-[1.125rem] text-slate-600">
              <tr className="w-full py-[1.438rem] px-[1.375rem] h-16">
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-12 max-w-12 hidden sm:table-cell" />
                <th className="pl-4">
                  <div className="text-left w-[7rem] font-medium">Date</div>
                </th>
                <th className="">
                  <div className="text-left  w-[12rem] font-medium">
                    Reporter Name
                  </div>
                </th>
                <th className="">
                  <div className="text-left  w-[12rem] font-medium">
                    Description
                  </div>
                </th>
                <th className="">
                  <div className="text-left font-medium w-[12rem]">Expense</div>
                </th>
                <th className="">
                  <div className="pl-2 text-left  w-[10rem] font-medium">
                    Tags
                  </div>
                </th>
                <th className="">
                  <div className="text-left w-[7rem] font-medium"> Amount</div>
                </th>
                <th className="">
                  <div className="text-left w-[7rem] font-medium">Actions</div>
                </th>
              </tr>
            </thead>
            {/* Table body */}
            {expenseReport?.length > 0 && (
              <tbody className="text-sm divide-y divide-gray-300 border-b border-gray-300">
                {expenseReport?.map((reporter) => (
                  <tr
                    key={reporter?.id}
                    className={` pl-[1.375rem] pr-[1.125rem] h-20 hover:bg-[#a0cd85] hover:bg-opacity-5 cursor-pointer ${
                      activeTransaction?.id === reporter?.id
                        ? 'bg-[#a0cd85] bg-opacity-5'
                        : ''
                    }`}
                    onClick={(e) => {
                      e.stopPropagation();
                      setActiveTransaction(reporter);
                      setTransactionFlyoutOpen(true);
                    }}
                  >
                    <td className="pl-5 pr-4 last:pr-5 pt-2.5 pb-3 whitespace-nowrap w-12 max-w-12 hidden sm:table-cell">
                      <label className="inline-flex w-fit mt-2">
                        <span className="sr-only">Select</span>
                        <input
                          id={reporter?.id}
                          className="form-checkbox cursor-pointer h-5 w-5 disabled:cursor-not-allowed disabled:opacity-50"
                          type="checkbox"
                          onClick={(e) => e.stopPropagation()}
                          onChange={handleTransactionCheckbox}
                          checked={selectedTransactions.includes(reporter?.id)}
                          disabled={reporter?.status !== 'Submitted'}
                        />
                      </label>
                    </td>
                    <td className="whitespace-nowrap pl-4">
                      <div className="text-left">
                        {moment
                          ?.utc(
                            reporter?.pendingReceiptId
                              ? reporter?.pendingReceipt?.date
                              : reporter?.transaction?.authorizedDate,
                          )
                          ?.format('MMM DD, YYYY')}
                      </div>
                    </td>
                    <td className="  ">
                      <div className="flex items-center">
                        <div className="text-sm font-medium leading-6 text-left text-slate-600">
                          {reporter?.people?.firstName}{' '}
                          {reporter?.people?.lastName}
                        </div>
                      </div>
                    </td>
                    <td className="  ">
                      <div className="flex items-center">
                        <div className="text-sm font-medium leading-6 text-left text-slate-600">
                          {reporter?.pendingReceipt?.description ||
                            reporter?.transaction?.description}
                        </div>
                      </div>
                    </td>
                    <td className="">
                      <div className="text-left ">
                        {reporter?.transaction?.expenseReportType?.name}
                      </div>
                    </td>
                    <td className="pl-2">
                      <div className="text-left">
                        <div className="flex items-center whitespace-nowrap ">
                          {reporter?.transaction?.tags?.length > 0 && (
                            <Tooltip
                              tooltipShow={
                                reporter?.transaction?.tags?.[0]?.name?.length >
                                  tagsLength ||
                                reporter?.transaction?.tags?.length > 1
                              }
                              content={reporter?.transaction?.tags?.map(
                                (t, i) => {
                                  if (t?.name?.length > 30) {
                                    const segments = getStringSegments(
                                      t?.name,
                                      30,
                                    );
                                    return segments.map((segment, index) => (
                                      <p
                                        key={index}
                                        className="text-sm text-center leading-tight"
                                        onClick={(e) => e.stopPropagation()}
                                      >
                                        {segment}{' '}
                                        {index === segments?.length - 1 &&
                                          reporter?.transaction?.tags?.length -
                                            1 !==
                                            i &&
                                          ','}
                                      </p>
                                    ));
                                  }
                                  return (
                                    <p
                                      key={t?.id}
                                      className="text-sm text-center"
                                      onClick={(e) => e.stopPropagation()}
                                    >
                                      {t?.name}
                                      {reporter?.transaction?.tags?.length -
                                        1 !==
                                        i && ','}
                                    </p>
                                  );
                                },
                              )}
                              contentClassName="border-none rounded-[10px] p-3 overflow-visible text-sm text-[#667085] relative"
                              position="bottom"
                            >
                              <span className="">
                                {trimText(
                                  reporter?.transaction?.tags?.[0]?.name,
                                  tagsLength,
                                )}{' '}
                                {reporter?.transaction?.tags?.length > 1 &&
                                  `+${reporter?.transaction?.tags?.length - 1}`}
                              </span>
                            </Tooltip>
                          )}
                        </div>
                      </div>
                    </td>

                    <td className="whitespace-nowrap">
                      <div className="text-left">
                        <p className="text-slate-600 text-sm leading-6 font-medium">
                          {findCurrencySymbol(
                            reporter?.pendingReceiptId
                              ? reporter?.pendingReceipt?.currency
                              : reporter?.transaction?.currency,
                          )}
                          {parseFloat(
                            reporter?.pendingReceiptId
                              ? reporter?.pendingReceipt?.amount
                              : reporter?.transaction?.amount
                                  ?.toString()
                                  .replace('-', ''),
                          )?.toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                          {(reporter?.pendingReceiptId
                            ? reporter?.pendingReceipt?.currency
                            : reporter?.transaction?.currency) !==
                            team?.currency &&
                            ` (${
                              reporter?.pendingReceiptId
                                ? reporter?.pendingReceipt?.currency
                                : reporter?.transaction?.currency
                            })`}
                        </p>
                      </div>
                    </td>
                    <td className="whitespace-nowrap">
                      <div className="inline-flex items-center gap-3.5">
                        {statusLoader === reporter?.id ? (
                          <div className="flex items-center justify-center ml-4">
                            <svg
                              className="animate-spin w-4 h-4 fill-current shrink-0 mr-2"
                              viewBox="0 0 16 16"
                            >
                              <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z" />
                            </svg>
                          </div>
                        ) : reporter?.status === 'Submitted' ? (
                          <>
                            <Tooltip
                              content="Approve Transaction"
                              contentClassName="border-none overflow-visible text-sm text-[#667085] relative tooltipArrowDown"
                            >
                              <button
                                onClick={(e) => {
                                  e.stopPropagation();
                                  updateReportStatus(reporter?.id, 'Approved');
                                }}
                                className=" h-[26px] w-[26px] border border-[#D0D5DD99] rounded-md shadow-sm bg-white inline-flex justify-center items-center"
                                type="button"
                              >
                                <svg
                                  width="18"
                                  height="18"
                                  viewBox="0 0 14 14"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M11.6673 3.5L5.25065 9.91667L2.33398 7"
                                    stroke="#78BD4F"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                              </button>
                            </Tooltip>
                            <Tooltip
                              content="Deny Transaction"
                              contentClassName="border-none overflow-visible text-sm text-[#667085] relative tooltipArrowDown"
                            >
                              <button
                                onClick={(e) => {
                                  e.stopPropagation();
                                  updateReportStatus(reporter?.id, 'Denied');
                                }}
                                className=" h-[26px] w-[26px] border border-[#D0D5DD99] rounded-md shadow-sm bg-white inline-flex justify-center items-center"
                                type="button"
                              >
                                <svg
                                  width="16"
                                  height="16"
                                  viewBox="0 0 16 16"
                                  className="cursor-pointer"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <rect
                                    x="1"
                                    y="1"
                                    width="14"
                                    height="14"
                                    rx="7"
                                    stroke="#FF4B4B"
                                    strokeWidth="1.3"
                                  />
                                  <path
                                    d="M3.05026 3.05025L12.9498 12.9497"
                                    stroke="#FF4B4B"
                                    strokeWidth="1.3"
                                    strokeLinecap="round"
                                  />
                                </svg>
                              </button>
                            </Tooltip>
                          </>
                        ) : (
                          <div
                            className={`capitalize cursor-default  px-2.5 py-1 w-fit rounded-md  text-sm font-medium
                          ${getExpenseReportStatusColor(reporter?.status)}
                        `}
                          >
                            {reporter?.status}
                          </div>
                        )}
                      </div>
                      {/* <ElipsisMenu
             align="right"
             className="relative inline-flex"
             dropdownOpen={dropdownOpen}
             setDropdownOpen={setDropdownOpen}
           >
             <li
               onClick={() => {
                 setActiveReporter(merchant);
                 setModalOpen(true);
                 setDropdownOpen(false);
               }}
               className="font-medium text-sm text-slate-600 dark:text-slate-300 hover:text-slate-800 dark:hover:text-slate-200 flex py-1 px-3 cursor-pointer"
             >
               Update
             </li>
           </ElipsisMenu> */}
                    </td>
                  </tr>
                ))}
              </tbody>
            )}
          </table>
          {!(expenseReport?.length > 0) && (
            <div className="flex flex-col gap-5 items-center justify-center w-full">
              <h6 className="text-center mt-6">
                No Transactions found{' '}
                {reportsTypesFilter?.length === 1 &&
                  reportsTypesFilter[0] === 'Submitted' && (
                    <>that need review</>
                  )}
              </h6>
            </div>
          )}
        </div>
      </div>
      <div className="mt-10">
        <PaginationClassic
          setLimit={setLocalExpenseReportLimit}
          onPageChange={getData}
          pagination={{ limit, page, totalRecords: totalCount }}
        />
      </div>
    </>
  );
};

export default ReporterTransactionsTable;
