import React, { useEffect, useState } from 'react';
// import ActionsDropdown from "./ActionsDropdown";
import {
  getCustomerById,
  getCustomers,
  getSingleMerchant,
} from 'API/backend_helper';
import MerchantsDropdown from 'components/MerchantsDropdown';
import DropdownFull from 'components/DropdownFull';
import BasicCheckboxDropdown from 'pages/component/BasicCheckboxDropdown';
import Tooltip from 'components/Tooltip';
import CategoriesDropdown from '../transactions/CategoriesDropdown';

let searchTimeout = null;

const CreateJournalEntryTable = ({
  allCategories,
  entryRows,
  setEntryRows,
  TABLE_ROW_SCHEMA,
  isLineItemsError = false,
  currency,
  checkEntryRowsError,
  calcDebitTotal,
  calcCreditTotal,

  vendors,
  setVendors,
  tags,
  customers,
  projects,
  products,
  dropdownCustomers,
  setDropdownCustomers,
}) => {
  const customerId = '';
  const [searchCustomerLoading, setSearchCustomerLoading] = useState(false);

  const getCustomersBySearch = async ({ limit = 50, search = '' }) => {
    try {
      const res = await getCustomers({
        search,
        limit,
      });
      return res?.data;
    } catch (e) {
      console.log('error', e);
    }
  };

  const loadCustomerById = async (id) => {
    const selectedCustomer = customers?.find(
      (customer) => +customer?.id === +id,
    );
    if (selectedCustomer?.id) {
      setDropdownCustomers([selectedCustomer]);
      return;
    }
    try {
      const res = await getCustomerById(id);
      if (res?.data?.customer) {
        setDropdownCustomers([res?.data?.customer]);
      }
    } catch (err) {
      console.log('err', err);
    }
  };

  const handleCustomerSearch = (value) => {
    clearTimeout(searchTimeout);
    if (value) {
      searchTimeout = setTimeout(async () => {
        setSearchCustomerLoading(true);
        const data = await getCustomersBySearch({
          search: encodeURIComponent(value || ''),
        });
        setSearchCustomerLoading(false);
        const filteredCustomers = data?.records?.filter((customer) =>
          customer.customer.toLowerCase().includes(value.toLowerCase()),
        );
        setDropdownCustomers(filteredCustomers);
      }, 500);
    } else {
      let selectedCustomer = null;
      selectedCustomer = dropdownCustomers?.find((c) => c?.id === customerId);
      if (customerId && selectedCustomer) {
        setDropdownCustomers([selectedCustomer]);
      } else if (customerId && !selectedCustomer) {
        loadCustomerById(customerId);
      } else {
        setDropdownCustomers([]);
      }
    }
  };

  const resetSearch = (selectedOption) => {
    setDropdownCustomers([selectedOption]);
  };

  const handleUpdateValue = (index, field, value) => {
    const tempRows = [...entryRows];
    const tempObj = { ...tempRows[index] };
    tempObj[field] = { ...tempObj[field], value };
    if (value) {
      tempObj[field] = { ...tempObj[field], error: false };
      if (field === 'credit' || field === 'debit') {
        tempObj.credit = { ...tempObj.credit, error: false };
        tempObj.debit = { ...tempObj.debit, error: false };
      }
    } else {
      if (field === 'credit' || field === 'debit') {
        if (!tempObj.credit.value && !tempObj.debit.value) {
          tempObj[field] = { ...tempObj[field], error: true };
        }
        if (tempObj.credit.value || tempObj.debit.value) {
          tempObj.credit = { ...tempObj.credit, error: false };
          tempObj.debit = { ...tempObj.debit, error: false };
        }
      }
    }
    tempRows[index] = tempObj;
    setEntryRows(tempRows);
  };

  return (
    <div className="bg-transparent  w-full rounded-[5px]  my-5 dark:bg-transparent border border-[#D0D5DD] flex-1 ">
      {/* Table */}
      <div
        className="overflow-x-auto h-full w-full"
        id="createJournalEntryTableContainer"
      >
        <table className="table-auto w-full">
          {/* Table header */}
          <thead className="text-sm font-semibold text-[#667085] border-b border-[#D0D5DD] bg-[#A0CD850F]">
            <tr>
              <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                <div className="font-medium text-left">Description</div>
              </th>
              <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                <div className="font-medium text-left">Account</div>
              </th>
              <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                <div className="font-medium text-left">Vendor</div>
              </th>
              <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                <div className="font-medium text-left">Customer</div>
              </th>
              <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                <div className="font-medium text-left">Project</div>
              </th>
              <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                <div className="font-medium text-left">Product</div>
              </th>
              <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                <div className="font-medium text-left">Tags</div>
              </th>
              <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap sm:table-cell hidden ">
                <div className="font-medium text-left">Debit</div>
              </th>
              <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap sm:table-cell hidden ">
                <div className="font-medium text-left">Credit</div>
              </th>
              <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap sm:table-cell hidden "></th>
            </tr>
          </thead>
          {/* Table body */}
          <tbody className="text-sm divide-y divide-[#D0D5DD] ">
            {entryRows?.map((b, i) => (
              <tr className="" key={i}>
                <td className="px-2 first:pl-5 last:pr-5 pt-5 pb-[2px] text-left">
                  <div className="h-[60px] flex flex-col">
                    <input
                      type="text"
                      placeholder="Description"
                      value={b.description.value}
                      className={`form-input  ${
                        b.description.error ? '!border-rose-500' : ''
                      }`}
                      onChange={(e) =>
                        handleUpdateValue(i, 'description', e.target.value)
                      }
                      onBlur={
                        b.description.error ? checkEntryRowsError : () => {}
                      }
                    />
                    <span className="h-[10px] text-xs mt-1 text-rose-500 ">
                      {b?.description?.error || ''}
                    </span>
                  </div>
                </td>
                <td className="px-2 first:pl-5 last:pr-5 pt-5 pb-[2px] text-left">
                  <div className="h-[60px] flex flex-col">
                    <div className="w-[200px] ">
                      <CategoriesDropdown
                        allCategories={allCategories || []}
                        selectedCategoryId={b?.categoryAccountId?.value || null}
                        setSelectedCategoryId={(value) => {
                          handleUpdateValue(i, 'categoryAccountId', value);
                        }}
                        isError={b?.categoryAccountId?.error}
                        isSetCategoryStyle
                        isTableDropdown
                        type="account"
                        onBlur={
                          b?.categoryAccountId?.error
                            ? checkEntryRowsError
                            : () => {}
                        }
                        bottomOfTable
                        ids={[
                          'journalEntriesContainer',
                          'createJournalEntryTableContainer',
                        ]}
                        journalEntry
                      />
                    </div>
                    <span className="text-xs mt-1 text-rose-500 h-[10px]">
                      {b?.categoryAccountId?.error || ''}
                    </span>
                  </div>
                </td>
                <td className="px-2 first:pl-5 last:pr-5 pt-5 pb-[2px] text-left">
                  <div className="h-[60px] flex flex-col">
                    <div className="w-[200px] ">
                      <MerchantsDropdown
                        vendors={vendors}
                        setVendors={setVendors}
                        selectedMerchant={b?.vendorId?.value || null}
                        setSelectedMerchant={(value, merchant) => {
                          handleUpdateValue(i, 'vendorId', value);
                          // onChangeMerchant(value);
                        }}
                        isSetCategoryStyle
                        // addNewOptionButton
                        // handleNewOptionButton={() => {
                        //   setVendorModalOpen(true);
                        // }}
                        bottomOfTable
                        ids={[
                          'journalEntriesContainer',
                          'createJournalEntryTableContainer',
                        ]}
                      />
                    </div>
                    <span className="text-xs mt-1 text-rose-500 h-[10px]">
                      {/* {b?.categoryAccountId?.error || ''} */}
                    </span>
                  </div>
                </td>
                <td className="px-2 first:pl-5 last:pr-5 pt-5 pb-[2px] text-left">
                  <div className="h-[60px] flex flex-col">
                    <div className="w-[200px] ">
                      <Tooltip
                        content="You can either assign a customer or a project to this entry, but not both."
                        className="w-full"
                        contentClassName="border-none overflow-visible text-sm text-[#667085] relative"
                        size="sm"
                        tooltipShow={b?.projectId?.value}
                      >
                        <DropdownFull
                          options={dropdownCustomers}
                          disabled={b?.projectId?.value}
                          selected={b?.customerId?.value || null}
                          setSelected={(value) => {
                            handleUpdateValue(i, 'customerId', value);
                          }}
                          name="customer"
                          scrollIntoView={false}
                          search
                          height="h-10"
                          flyout
                          placeholder="Choose customer"
                          // addNewOptionButton
                          // addNewOptionLabel="Create A New Customer"
                          // handleNewOptionButton={() => {
                          //   setCustomerModalOpen(true);
                          // }}
                          handleSearch={handleCustomerSearch}
                          resetSearch={resetSearch}
                          searchPlaceholder="Type To Search"
                          searchLoading={searchCustomerLoading}
                          bottomOfTable
                          ids={[
                            'journalEntriesContainer',
                            'createJournalEntryTableContainer',
                          ]}
                        />
                      </Tooltip>
                    </div>
                    <span className="text-xs mt-1 text-rose-500 h-[10px]">
                      {/* {b?.categoryAccountId?.error || ''} */}
                    </span>
                  </div>
                </td>
                <td className="px-2 first:pl-5 last:pr-5 pt-5 pb-[2px] text-left">
                  <div className="h-[60px] flex flex-col">
                    <div className="w-[200px] ">
                      <Tooltip
                        content="You can either assign a customer or a project to this entry, but not both."
                        className="w-full"
                        contentClassName="border-none overflow-visible text-sm text-[#667085] relative"
                        size="sm"
                        tooltipShow={b?.customerId?.value}
                      >
                        <DropdownFull
                          options={projects}
                          selected={b?.projectId?.value || null}
                          setSelected={(value) => {
                            handleUpdateValue(i, 'projectId', value);
                          }}
                          name="name"
                          scrollIntoView={false}
                          // search
                          height="h-10 w-[300px]"
                          flyout
                          placeholder="Attach to Project"
                          bottomOfTable
                          disabled={b?.customerId?.value}
                          className="whitespace-nowrap truncate !h-fit"
                          isTableDropdown
                          ids={[
                            'journalEntriesContainer',
                            'createJournalEntryTableContainer',
                          ]}
                        />
                      </Tooltip>
                    </div>
                    <span className="text-xs mt-1 text-rose-500 h-[10px]">
                      {/* {b?.categoryAccountId?.error || ''} */}
                    </span>
                  </div>
                </td>
                <td className="px-2 first:pl-5 last:pr-5 pt-5 pb-[2px] text-left">
                  <div className="h-[60px] flex flex-col">
                    <div className="w-[200px] ">
                      <DropdownFull
                        options={products}
                        selected={b?.productId?.value || null}
                        setSelected={(value) => {
                          handleUpdateValue(i, 'productId', value);
                        }}
                        name="name"
                        scrollIntoView={false}
                        search
                        height="h-10 w-[300px]"
                        flyout
                        placeholder="Select Product"
                        bottomOfTable
                        className="whitespace-nowrap truncate !h-fit"
                        ids={[
                          'journalEntriesContainer',
                          'createJournalEntryTableContainer',
                        ]}
                        isTableDropdown
                      />
                    </div>
                    <span className="text-xs mt-1 text-rose-500 h-[10px]">
                      {/* {b?.categoryAccountId?.error || ''} */}
                    </span>
                  </div>
                </td>
                <td className="px-2 first:pl-5 last:pr-5 pt-5 pb-[2px] text-left">
                  <div className="h-[60px] flex flex-col">
                    <div className="w-[200px] ">
                      <BasicCheckboxDropdown
                        isUseStateHook={false}
                        selectedOptions={b?.tags?.value || []}
                        setSelectedOptions={(v) => {
                          handleUpdateValue(i, 'tags', v);
                        }}
                        options={
                          tags?.map((t) => ({
                            label: t?.name,
                            value: t?.id,
                          })) || []
                        }
                        label="Use Tags"
                        type="tag"
                        flyout
                        width="w-[300px]"
                        // disabled={accessType === VIEWER}
                        bottomOfTable
                        ids={[
                          'journalEntriesContainer',
                          'createJournalEntryTableContainer',
                        ]}
                        isTableDropdown
                        dropdownBoxWidth="min-w-44 w-full max-w-[300px]"
                      />
                    </div>
                    <span className="text-xs mt-1 text-rose-500 h-[10px]">
                      {/* {b?.categoryAccountId?.error || ''} */}
                    </span>
                  </div>
                </td>
                <td className="px-2 first:pl-5 last:pr-5 pt-5 pb-[2px] text-left">
                  <div className="h-[60px] flex flex-col">
                    <div className="relative">
                      <input
                        type="number"
                        value={b.debit.value}
                        className={`form-input w-[200px] pl-8 ${
                          b.debit.error ? '!border-rose-500' : ''
                        } disabled:cursor-not-allowed`}
                        min={0}
                        disabled={b?.credit?.value}
                        onChange={(e) => {
                          handleUpdateValue(i, 'debit', e.target.value);
                        }}
                        placeholder="0.00"
                        step="any"
                        // onBlur={(b.debit.error||b.credit.error) && checkEntryRowsError}
                      />

                      <span className="absolute top-[50%] -translate-y-[50%] left-3 flex items-center gap-2">
                        {currency?.symbol}
                        <div className="w-[0.5px] h-[1.188rem] bg-slate-600"></div>
                      </span>
                    </div>
                    <span className="text-xs mt-1 text-rose-500 h-[10px]">
                      {b?.debit?.error || ''}
                    </span>
                  </div>
                </td>
                <td className="px-2 first:pl-5 relative last:pr-5 pt-5 pb-[2px] text-left">
                  <div className="h-[60px] flex flex-col">
                    <div className="relative">
                      <input
                        type="number"
                        value={b?.credit?.value}
                        disabled={b?.debit?.value}
                        className={`form-input w-[200px] pl-8 ${
                          b.credit.error ? '!border-rose-500' : ''
                        }  disabled:cursor-not-allowed`}
                        min={0}
                        onChange={(e) => {
                          handleUpdateValue(i, 'credit', e.target.value);
                        }}
                        // onBlur={(b.debit.error||b.credit.error)  && checkEntryRowsError}
                        placeholder="0.00"
                        step="any"
                      />
                      <span className="absolute top-[50%] -translate-y-[50%] left-3 flex items-center gap-2">
                        {currency?.symbol}
                        <div className="w-[0.5px] h-[1.188rem] bg-slate-600"></div>
                      </span>
                    </div>
                    <span className="text-xs mt-1 text-rose-500 h-[10px]">
                      {b?.credit?.error || ''}
                    </span>
                  </div>
                </td>

                <td className="px-2 first:pl-5 last:pr-5 py-2.5 text-center">
                  {i !== 0 && i !== 1 ? (
                    <button
                      onClick={() =>
                        setEntryRows((prev) =>
                          prev.filter((d, index) => index !== i),
                        )
                      }
                      className="border-[#D0D5DD99] border rounded-[5px] h-[26px] w-[26px] shadow-sm inline-flex justify-center items-center"
                      type="button"
                    >
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M13.6059 3.87474C11.4483 3.66093 9.27778 3.55078 7.11373 3.55078C5.83086 3.55078 4.54798 3.61557 3.26511 3.74516L1.94336 3.87474"
                          stroke="#E48642"
                          stroke-width="1.3"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M5.50781 3.22021L5.65035 2.37144C5.75402 1.75592 5.83177 1.2959 6.92675 1.2959H8.62429C9.71927 1.2959 9.8035 1.78184 9.90069 2.37792L10.0432 3.22021"
                          stroke="#E48642"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M12.2124 5.92188L11.7913 12.4464C11.72 13.4636 11.6617 14.2541 9.85398 14.2541H5.69435C3.88667 14.2541 3.82835 13.4636 3.75708 12.4464L3.33594 5.92188"
                          stroke="#E48642"
                          stroke-width="1.3"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M6.69336 10.6904H8.85092"
                          stroke="#E48642"
                          stroke-width="1.3"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M6.15625 8.09863H9.39583"
                          stroke="#E48642"
                          stroke-width="1.3"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </button>
                  ) : (
                    <div className="h-[26px] w-[26px]" />
                  )}
                </td>
              </tr>
            ))}
            <tr className="">
              <td className="px-2 first:pl-5 last:pr-5 py-2.5 text-left">
                <button
                  type="button"
                  className="btn h-9 px-[14px] gap-2 text-indigo-500 text-base border border-indigo-500 cursor-pointer"
                  onClick={() =>
                    setEntryRows([
                      ...entryRows,
                      JSON.parse(JSON.stringify(TABLE_ROW_SCHEMA)),
                    ])
                  }
                >
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.00004 14.6673C11.6667 14.6673 14.6667 11.6673 14.6667 8.00065C14.6667 4.33398 11.6667 1.33398 8.00004 1.33398C4.33337 1.33398 1.33337 4.33398 1.33337 8.00065C1.33337 11.6673 4.33337 14.6673 8.00004 14.6673Z"
                      stroke="#E48642"
                      stroke-width="1.2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M5.33337 8H10.6667"
                      stroke="#E48642"
                      stroke-width="1.2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M8 10.6663V5.33301"
                      stroke="#E48642"
                      stroke-width="1.2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  Add A Line
                </button>
              </td>
              <td></td>
              <td></td>
              <td
                colSpan={2}
                className="px-2 first:pl-5 last:pr-5 py-4 text-left mt-5"
              >
                {entryRows?.length > 0 && (
                  <div className="flex justify-end">
                    <div className="w-fit">
                      <div className="flex items-end gap-2">
                        <div>
                          <p>Total Debits</p>
                          <h3 className="text-[24px] font-semibold">
                            {currency?.symbol}
                            {parseFloat(calcDebitTotal())?.toLocaleString(
                              undefined,
                              {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              },
                            )}
                          </h3>
                        </div>
                        <h3 className="text-[24px] font-semibold">=</h3>
                        <div>
                          <p>Total Credits</p>
                          <h3 className="text-[24px] font-semibold">
                            {currency?.symbol}
                            {parseFloat(calcCreditTotal())?.toLocaleString(
                              undefined,
                              {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              },
                            )}
                          </h3>
                        </div>
                      </div>
                      <div className="flex justify-center items-center gap-4">
                        <p>Difference: </p>
                        <h3
                          className={`text-[24px] font-semibold ${
                            calcCreditTotal() - calcDebitTotal() !== 0
                              ? 'text-rose-500'
                              : 'text-[#78BD4F]'
                          }`}
                        >
                          {currency?.symbol}
                          {parseFloat(
                            calcCreditTotal() - calcDebitTotal(),
                          )?.toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                        </h3>
                      </div>
                    </div>
                  </div>
                )}
              </td>
            </tr>
          </tbody>
        </table>
        {/* {(calcCreditTotal() - calcDebitTotal() !== 0) && (
          <h6 className="text-rose-500 text-end mt-0 pr-5">Difference Should be 0</h6>
        )} */}
        {isLineItemsError && (
          <p className="text-rose-500 text-center font-semibold mb-4">
            {isLineItemsError
              ? calcCreditTotal() - calcDebitTotal() !== 0
                ? 'Total Debits should be equal to Total Credits'
                : isLineItemsError || ''
              : ''}
          </p>
        )}
        {!entryRows?.length > 0 && (
          <h6 className="text-center mt-4">Please Add a New Line.</h6>
        )}
      </div>
    </div>
  );
};

export default CreateJournalEntryTable;
