import React, { useEffect, useState } from "react";
import CountLogo from "../../images/custom/count-logo.png";
import CustomerStatementBox from "./CustomerStatementBox";
import { useNavigate, useParams } from "react-router-dom";
import emptySearchImg from "../../images/custom/empty-search.svg";
import { getCountriesData, getCurrenciesData, getCustomerInvoice } from "../../API/backend_helper";
import { shutdown } from "@intercom/messenger-js-sdk";

const PublicViewCustomerStatement = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [loading, setLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [data,setData]=useState(null);
  const [countries, setCountries] = useState([]);
  const [currencies, setCurrencies] = useState([]);

  useEffect(() => {
    setTimeout(() => {
      shutdown();
    }, 1000);
  }, []);

  const getCountryName = () => {
    let country = null;
    if (countries?.length > 0) {
      country = countries?.find((cnt) => cnt?.isoCode === data?.team?.country);
    }
    return country?.name;
  };


  const loadCountries = async () => {
    try {
      const res = await getCountriesData();
      setCountries(res);
    } catch (err) {
      console.log("err", err);
    }
  };

  const loadCurrencies = async () => {
    try {
      const res = await getCurrenciesData();
      setCurrencies(res);
    } catch (err) {
      console.log("err", err);
    }
  };


  const getData = async () => {
    try {
      setLoading(true);
      const res = await getCustomerInvoice(id);
      setData(res?.data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setIsError(true);
      console.log("err", err);
    }
  };

  useEffect(() => {
    if (id) {
      loadCurrencies();
      loadCountries();
      getData();
    }
  }, [id]);

  return (
    <>
      <div className="min-h-dvh	bg-[#efefef]">
        <nav className="h-[81px] w-full flex bg-[#101828] items-center justify-between px-10">
          <img
            className="h-8 cursor-pointer"
            onClick={() => navigate("/")}
            src={CountLogo}
            alt="logo"
          />
          <h3 className="text-[22px] text-white font-medium">{data?.team?.name}</h3>
        </nav>
        {/* <button onClick={printInvoice}>Print Invoice</button> */}
        {!loading && isError ? (
          <div className="flex flex-col gap-5 items-center justify-center w-full">
            <img
              src={emptySearchImg}
              alt="No vendors found"
              className="h-[12.5rem]"
            />
            <h6 className="text-center mt-6 text-xl">
              This is an invalid Customer Statement link. Please ask the person
              who shared this link with you to provide a new one.
            </h6>
          </div>
        ) : loading ? (
          <div className="flex flex-col gap-2 items-center pb-2 justify-center mt-8">
            <svg
              width="29"
              height="29"
              viewBox="0 0 29 29"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="animate-spin w-10 h-10 fill-current shrink-0"
            >
              <path
                d="M14.5 2.41699V7.25033"
                stroke="#E48642"
                stroke-width="2.55882"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M14.5 21.749V26.5824"
                stroke="#E48642"
                stroke-width="2.55882"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M5.95703 5.95703L9.37662 9.37662"
                stroke="#E48642"
                stroke-width="1.58955"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M19.623 19.6211L23.0426 23.0407"
                stroke="#E48642"
                stroke-width="1.58955"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M2.41699 14.5H7.25033"
                stroke="#E48642"
                stroke-width="1.58955"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M21.749 14.5H26.5824"
                stroke="#E48642"
                stroke-width="1.58955"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M5.95703 23.0407L9.37662 19.6211"
                stroke="#E48642"
                stroke-width="1.58955"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M19.623 9.37662L23.0426 5.95703"
                stroke="#E48642"
                stroke-width="1.58955"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <p className="text-slate-600 font-medium text-[0.875rem] text-center">
              Fetching data, please wait...
            </p>
          </div>
        ) : (
          <div className="w-[1300px] mt-[100px] pb-[20px] max-w-[90vw] mx-auto ">
            <CustomerStatementBox data={data} customer={data?.invoices?.[0]?.customer}
             countryName={getCountryName()}
             currency={currencies?.find(
              (curr) => curr?.isoCode === data?.invoices?.[0]?.currency
            )}
             team={data?.team}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default PublicViewCustomerStatement;
