import React, { useEffect, useState } from "react";
import { getAdminWebHooks } from "../../../API/backend_helper";
import PaginationClassic from "../../../components/PaginationClassic";
import { TEAM_ID } from "../../../utils/Utils";
import moment from "moment";
import WebhookEventsModal from "./WebhookEventModal";

const WebhookEvents = () => {
  const [webHooks, setWebHooks] = useState({});
  const [localLimit, setLocalLimit] = useState(20);
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});

  const loadWebHooks = async ({
    limit = localLimit,
    page = 1,
    isLoading = false,
  }) => {
    if (isLoading) {
      setIsLoading(true);
    }
    try {
      const res = await getAdminWebHooks({
        limit,
        page,
        teamId: localStorage.getItem(TEAM_ID),
      });
      setWebHooks(res?.data?.events);
    } catch (err) {
      console.log("err", err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadWebHooks({ isLoading: true });
  }, []);

  const totalRecords = []
  const getStatusColor = (status) => {
    switch (status) {
      case "skipped":
        return "text-[#E48642] bg-[#E486421A]";
      case "error":
        return "text-[#FF4B4B] bg-[#FF4B4B1A]";
      case "success":
        return "text-[#40AA00] bg-[#40AA001A]";
      default:
        return "text-slate-500 bg-slate-100";
    }
  };

  return (
    <div>
      <WebhookEventsModal selectedRow={selectedRow} isOpen={isModalOpen} setIsOpen={setIsModalOpen} />
      {!isLoading ? (
        <div className="bg-white flex-1 dark:bg-slate-800  rounded-sm   overflow-auto">
          <table className="table-auto w-full dark:text-slate-300 divide-y divide-slate-200 dark:divide-slate-700">
            {/* Table header */}
            <thead className="text-sm text-[#667085] font-medium dark:text-slate-400 shadow-md border-none">
              <tr>
                {/* <th className="px-2 first:pl-5 last:pr-5 py-4 whitespace-nowrap"></th> */}
                <th className="px-2 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                  <div className=" text-left font-medium">Date</div>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                  <div className=" text-left font-medium">Status</div>
                </th>
                <th className="px-5 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                  <div className=" text-left font-medium">Message</div>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                  <div className=" text-left font-medium">Webhook Code</div>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                  <div className=" text-left font-medium">Webhook Type</div>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                  <div className=" text-left font-medium">Item Id</div>
                </th>
              </tr>
            </thead>
            {/* Table body */}
            <tbody className="text-sm divide-y divide-slate-200 dark:divide-slate-700 pb-5">
              {/* Row */}
              {webHooks?.records?.length > 0 &&
                webHooks.records.map((record, index) => (
                  <tr
                    onClick={(e) => {
                      e.stopPropagation();
                      setSelectedRow(record);
                      setIsModalOpen(true);
                    }}
                    className="border-b border-slate-300 hover:bg-[#A0CD850F] cursor-pointer"
                  >
                    <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                      <div className="text-sm text-[#667085] leading-tight mr-1">
                        {moment.utc(record?.createdAt).format("MMM DD, YYYY, HH:mm:ss")}
                      </div>
                    </td>
                    <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                      <div
                        className={` w-fit mx-auto p-2.5 py-2 rounded-[5px] text-sm leading-tight ${getStatusColor(
                          record?.status
                        )} capitalize font-medium`}
                      >
                        {record?.status}
                      </div>
                    </td>
                    <td className="px-2 first:pl-5 last:pr-5 py-3">
                      <div className="text-sm text-[#667085] capitalize">
                        {record?.message}
                      </div>
                    </td>
                    <td className="px-2 first:pl-5 last:pr-5 py-3">
                      <div className="text-sm text-[#667085] capitalize ">
                        {record?.payload?.webhook_code}
                      </div>
                    </td>
                    <td className="px-2 first:pl-5 last:pr-5 py-3">
                      <div className="text-sm text-[#667085] capitalize ">
                        {record?.payload?.webhook_type}
                      </div>
                    </td>
                    <td className="px-2 first:pl-5 last:pr-5 py-3">
                      <div className="text-sm text-[#667085] capitalize ">
                        {record?.payload?.item_id}
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
          {webHooks?.records?.length === 0 && (
            <h6 className="text-sm text-[#667085B2] text-center mt-4 pb-3">
              No Data Found
            </h6>
          )}
        </div>
      ) : (
        <p>Loading...</p>
      )}
      <div className="mt-8">
        {totalRecords > 0 && (
          <PaginationClassic
            pagination={{
              limit: +localLimit,
              page: 1,
              totalRecords: +totalRecords,
            }}
            onPageChange={loadWebHooks}
            setLimit={setLocalLimit}
          />
        )}
      </div>
    </div>
  );
};

export default WebhookEvents;
