import React, { useEffect } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const CustomToast = ({ category, openModal }) => (
  <div className="flex items-center gap-1">
    <p className="text-white leading-5 text-[16px] font-medium w-[105%]">
      Category changed to {category?.name}
    </p>
    <button
      type="button"
      className="bg-white text-[#78BD4F] flex items-center justify-center h-[32px] w-[100px] rounded-[5px] gap-2 py-2.5 px-[1.875rem] whitespace-nowrap"
      onClick={() => openModal(true)}
    >
      <span className="leading-5 text-[16px]">+ Add Rule</span>
    </button>
  </div>
);

const CreateRulePopup = ({ open, setOpen, category, openModal }) => {
  useEffect(() => {
    if (open) {
      const id = toast(
        <CustomToast category={category} openModal={openModal} />,
        {
          position: 'top-right',
          autoClose: 15000,
          onClose: () => setOpen(false),
          type: 'success',
          className: 'categoryToast',
        },
      );

      return () => {
        toast.dismiss(id);
      };
    }
  }, [open]);

  return null;
};

export default CreateRulePopup;
