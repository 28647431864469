import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';

import { Formik, Form } from 'formik';
import Axios from 'axios';
import * as Yup from 'yup';
import { useSelector, useDispatch } from 'react-redux';
import Logo from '../../images/custom/count-logo.png';
import { IS_ACCOUNTANT, TEAM_ID, TEAM_NAME } from '../../utils/Utils';
import AuthSlider from '../../components/AuthSlider';
import TextInput from '../../components/TextInput';
import {
  checkPromoCodeValidity,
  createTeam,
  fetchSubscriptions,
  getAllTeamsForUser,
  getBusinessStructuresData,
  getCountriesData,
  getUser,
  getUserCountry,
  uploadTeamLogo,
} from '../../API/backend_helper';
import {
  BussinessDropdownCorporation,
  BussinessDropdownLLC,
  BussinessDropdownOther,
  CustomerPay,
  IndustryData,
  OnlinePayment,
} from './data';
import States from '../../data/states.json';
import DropdownInput from '../../components/DropdownInput';
// import TrialStep from "./TrialStep";
import ImageCropModal from '../../components/ImageCropModal';
import { setAccessType, setLocalUser } from '../../redux/User/reducer';
import SubscriptionPlan from './SubscriptionPlan/SubscriptionPlan';
import { SUBSCRIPTION_CHECKOUT } from '../../API/url_helper';
import { setSubscription } from '../../redux/Subscription/reducer';

const Workspace = () => {
  const dispatch = useDispatch();
  const formikRef = useRef(null);
  const navigate = useNavigate();
  const { type } = useParams();

  const { localUser } = useSelector((state) => state.User);
  const { currencies } = useSelector((state) => state.Currencies);

  const IsAccountant = localStorage.getItem(IS_ACCOUNTANT)
    ? JSON.parse(localStorage.getItem(IS_ACCOUNTANT))
    : null;

  const [loading, setLoading] = useState(false);
  const [subscriptions, setSubscriptions] = useState();
  const [selectedSubscription, setSelectedSubscription] = useState(null);
  const [imgPreview, setImgPreview] = useState('');
  const [currentStep, setCurrentStep] = useState(1);
  const [step1Values, setStep1Values] = useState({});
  const [step2Values, setStep2Values] = useState({});
  const [countries, setCountries] = useState([]);
  const [structures, setStructures] = useState([]);
  const [subStructures, setSubStructures] = useState([]);
  const [showCurrencyDropdown, setShowCurrencyDropdown] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState('');
  const [selectedCurrency, setSelectedCurrency] = useState('');
  const [displayReferralInput, setDisplayReferralInput] = useState(false);
  const [referralCode, setReferralCode] = useState('');
  const [promoCodeResponse, setPromoCodeResponse] = useState('');
  const [promoLoader, setPromoLoader] = useState(false);
  const [teams, setTeams] = useState([]);

  const [subscriptionLoading, setSubscribtionLoading] = useState(false);

  document.title = 'COUNT | Workspace';
  // Find user's country
  useEffect(() => {
    const fetchCountry = async () => {
      try {
        const res = await getUserCountry();
        const country = res?.country;
        if (country === 'US' || country === 'CA' || country === 'NZ') {
          setSelectedCountry(country);
          if (country === 'US') {
            setSelectedCurrency('USD');
          }
          if (country === 'NZ') {
            setSelectedCurrency('NZD');
          }
          if (country === 'CA') {
            setSelectedCurrency('CAD');
          }
          loadStructures(country);
        }
      } catch (err) {
        console.log('err', err);
      }
    };
    fetchCountry();
  }, []);

  const fetchSubscriptionsApi = async () => {
    setSubscribtionLoading(true);
    try {
      const res = await fetchSubscriptions(step1Values?.country);
      if (res?.data?.plans?.length > 0) {
        for (let i = 0; i < res?.data?.plans?.length; i++) {
          if (res?.data?.plans?.[i]?.prices?.[0]?.unit_amount === 0) {
            setSelectedSubscription(res?.data?.plans?.[i]?.prices?.[0]);
            break;
          }
        }
      }
      setSubscriptions(res?.data?.plans);
      setSubscribtionLoading(false);
    } catch (err) {
      setSubscribtionLoading(false);
      console.log('err', err);
    }
  };

  useEffect(() => {
    if (step1Values?.country) {
      fetchSubscriptionsApi();
    }
  }, [step1Values?.country]);

  const getBusinessSubTypes = (type) => {
    if (type === 'Self-employed' || type === 'Sole Proprietorship') {
      return [];
    }
    if (type === 'Limited Liability Company') {
      return BussinessDropdownLLC;
    }
    if (type === 'Corporation') {
      return BussinessDropdownCorporation;
    }
    return BussinessDropdownOther;
  };

  const loadCountries = async () => {
    try {
      const res = await getCountriesData();
      setCountries(res);
    } catch (err) {
      console.log('err', err);
    }
  };

  const loadStructures = async (id) => {
    try {
      const res = await getBusinessStructuresData(id);
      setStructures(res);
    } catch (err) {
      console.log('err', err);
    }
  };

  const findSubStructures = (name) => {
    const structure = structures.find((d) => d.name === name);
    if (structure) {
      const subStructures = structures.filter(
        (d) => d.parentId === structure.id,
      );
      setSubStructures(subStructures);
    }
  };

  const getTeams = async () => {
    try {
      const res = await getAllTeamsForUser({});
      setTeams(res?.teams);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    loadCountries();
    if (IsAccountant) {
      getTeams();
    }
  }, []);

  const getUserApi = async (id) => {
    try {
      const response = await getUser(null, id);
      dispatch(setLocalUser(response));
      if (response) {
        dispatch(setLocalUser(response));
      }
    } catch (e) {
      console.log('error', e);
    }
  };

  const onStartFreeTrial = () => {
    navigate(`/`);
    window.location.reload();
  };

  const checkReferrelCode = async () => {
    setPromoLoader(true);
    setPromoCodeResponse('');
    try {
      const response = await checkPromoCodeValidity(referralCode);
      setPromoCodeResponse(response?.trialLengthDays);
      setPromoLoader(false);
    } catch (e) {
      setPromoCodeResponse('error');
      setPromoLoader(false);
      console.log('error', e);
    }
  };

  const onCreateCompany = async (
    name,
    industry,
    type,
    subType,
    country,
    state,
    howBusinessGetsPaid,
    currency,
    website,
    logo,
    email,
  ) => {
    setLoading(true);
    try {
      const data = {
        name,
        industry,
        type,
        subType,
        country,
        state,
        howBusinessGetsPaid,
        currency,
      };
      if (email) {
        data.email = email;
      }

      if (website) {
        data.website = website;
      }
      if (
        promoCodeResponse !== '' &&
        promoCodeResponse !== 'error' &&
        referralCode
      ) {
        data.promoCode = referralCode;
      }

      const response = await createTeam(data);
      if (
        response !== `invalid input value for enum team_sub_types: ""` &&
        response?.id
      ) {
        if (logo && response?.id) {
          const formData = new FormData();
          formData.append('logo', logo);
          await uploadTeamLogo(formData, response.id);
        }
        await getUserApi(response?.id);
        dispatch(setSubscription(''));
        if (response?.id && !localUser?.teams?.length) {
          localStorage.setItem(TEAM_ID, response?.id);
          localStorage.setItem(TEAM_NAME, response?.name);
          dispatch(setAccessType(response?.credentials?.role));
          // navigate('/welcome');
          // setCurrentStep(3);
        } else {
          // setCurrentStep(3);
          localStorage.setItem(TEAM_ID, response?.id);
          localStorage.setItem(TEAM_NAME, response?.name);
          // navigate('/welcome');
          // navigate(`/manage-workspace/2`);
        }
        if (!(IsAccountant && teams?.length === 0)) {
          Axios.get(
            `${process.env.REACT_APP_API_BASE_URL}${SUBSCRIPTION_CHECKOUT}/${selectedSubscription?.id}`,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
                'Content-Type': 'application/json',
                'count-workspace-id': response?.id,
              },
            },
          )
            .then((response) => {
              if (response?.data?.data?.session?.url) {
                window.location = response?.data?.data?.session?.url;
              } else {
                navigate(`/`);
                window.location.reload();
              }
              setLoading(false);
            })
            .catch((error) => {
              setLoading(false);
              console.error('Error making GET request:', error);
            });
        } else {
          navigate(`/`);
          window.location.reload();
          setLoading(false);
        }

        formikRef.current?.resetForm();
        setImgPreview('');
        // onStartFreeTrial();
      }
    } catch (e) {
      console.log('error', e);
      setLoading(false);
    }
  };

  return (
    <main className="bg-white dark:bg-slate-900">
      <ImageCropModal
        isOpen={modalOpen}
        setIsOpen={setModalOpen}
        image={imgPreview}
        setPreviewImage={setImgPreview}
      />
      <div className="relative md:flex">
        {/* Content */}
        <div className={currentStep !== 3 ? 'md:w-1/2' : 'w-full'}>
          <div className="min-h-[100dvh] h-full flex flex-col after:flex-1">
            {/* Header */}
            <div className="flex-1">
              <div className="relative flex items-center justify-between h-16 px-4 sm:px-6 lg:px-8">
                {/* Logo */}
                <Link className="block" to="/">
                  <img src={Logo} alt="logo" className="h-8" />
                </Link>

                {(currentStep === 2 ||
                  (type === 'new' && currentStep !== 3)) && (
                  <button
                    onClick={() => {
                      if (currentStep === 1) {
                        navigate('/manage-workspaces');
                        return;
                      }
                      setCurrentStep(+currentStep - 1);
                    }}
                    className="absolute top-16 xl:top-24 left-4 md:left-8 h-8 w-8 bg-[#FBF3ED] rounded inline-flex justify-center items-center"
                    type="button"
                  >
                    <svg
                      width="10"
                      height="17"
                      viewBox="0 0 10 17"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8 2.5L2 8.5L8 14.5"
                        stroke="#E48642"
                        strokeWidth="3.6"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </button>
                )}
              </div>
            </div>

            <div
              className={
                currentStep !== 3
                  ? 'max-w-[510px] min-w-[50%] mx-auto w-full px-4 py-8'
                  : 'max-w-[1500px] mx-auto w-full px-5 py-8'
              }
            >
              {currentStep !== 3 && (
                <>
                  <h1 className="text-[40px] font-medium text-[#232323CC] mb-3 leading-tight">
                    {currentStep === 1
                      ? 'Tell Us About Your Business'
                      : 'Configure Your Business'}
                  </h1>
                  <p className="text-lg text-[#667085CC] mb-9 ">
                    {currentStep === 1
                      ? 'Let’s put the finishing touches on your profile together.'
                      : 'Let’s put the finishing touches on your profile together.'}
                  </p>
                </>
              )}
              {/** *******  Step 1   ********* */}

              {currentStep === 1 && (
                <Formik
                  enableReinitialize
                  initialValues={{
                    name: step1Values?.name || '',
                    email: step1Values?.email || '',
                    type: step1Values?.type || '',
                    subType: step1Values?.subType || '',
                    country: step1Values?.country || selectedCountry || '',
                    state: step1Values?.state || '',
                    currency: step1Values?.currency || selectedCurrency || '',
                  }}
                  validationSchema={Yup.object({
                    name: Yup.string()
                      .trim()
                      .required('Please Enter Name')
                      .max(100, 'Name should be less than 100 characters'),
                    email: Yup.string()
                      .matches(
                        /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        'Please enter valid email',
                      )
                      .optional('Please Enter Email')
                      .nullable(),
                    type: Yup.string().optional('Please Select Type'),
                    country: Yup.string()
                      .trim()
                      .required('Please Select Country'),
                    state: Yup.string().optional('Please Select State'),
                  })}
                  validate={(values) => {
                    const errors = {};

                    if (subStructures.length > 0) {
                      if (!values.subType) {
                        errors.subType = 'Please Select Business Sub Type';
                      }
                    }
                    if (values.country === 'US') {
                      if (!values.state) {
                        errors.state = 'Please Select State';
                      }
                    }
                    if (structures.length > 0 && !values.type) {
                      errors.type = 'Please Select Business Type';
                    }
                    return errors;
                  }}
                  onSubmit={(values, formikHandler) => {
                    setStep1Values(values);
                    setCurrentStep(2);
                  }}
                >
                  {(validation) => (
                    <Form>
                      <div className="space-y-8">
                        <TextInput
                          label="Business / Workspace Name"
                          placeholder=" "
                          id="name"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.name || ''}
                          error={
                            validation.touched.name
                              ? validation.errors.name
                              : ''
                          }
                          required
                        />
                        <TextInput
                          label="Business / Workspace Email"
                          placeholder=" "
                          id="email"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ''}
                          error={
                            validation.touched.email
                              ? validation.errors.email
                              : ''
                          }
                        />
                        <DropdownInput
                          options={countries?.map((c) => ({
                            ...c,
                            id: c.isoCode,
                          }))}
                          label="Business Country"
                          id="country"
                          name="name"
                          setSelected={(id) => {
                            validation.setFieldValue('country', id);
                            validation.setFieldValue(
                              'currency',
                              countries?.find((c) => c?.isoCode === id)
                                ?.defaultCurrency,
                            );
                            validation.setFieldValue('type', '');
                            validation.setFieldValue('subType', '');
                            loadStructures(id);
                          }}
                          selected={validation.values.country}
                          error={
                            !validation.values.country &&
                            validation.touched.country
                              ? validation.errors.country
                              : ''
                          }
                          onBlur={validation.handleBlur}
                          countries
                          required
                        />
                        {validation.values.currency && (
                          <div className="text-sm font-medium text-[#667085] !mt-3">
                            Your default currency is{' '}
                            {validation.values.currency}.{' '}
                            <span
                              className="text-[#E48642] cursor-pointer"
                              onClick={() => {
                                if (showCurrencyDropdown) {
                                  validation.setFieldValue(
                                    'currency',
                                    countries?.find(
                                      (c) =>
                                        c?.isoCode ===
                                        validation.values.country,
                                    )?.defaultCurrency,
                                  );
                                }
                                setShowCurrencyDropdown(!showCurrencyDropdown);
                              }}
                            >
                              {showCurrencyDropdown
                                ? 'Cancel'
                                : 'Change Currency'}
                            </span>
                          </div>
                        )}
                        {showCurrencyDropdown && (
                          <DropdownInput
                            options={currencies?.map((c) => ({
                              ...c,
                              id: c?.isoCode,
                              name: `${c?.symbol} ${c?.name}`,
                            }))}
                            id="currency"
                            label="Business Currency"
                            name="name"
                            setSelected={(id) => {
                              validation.setFieldValue('currency', id);
                            }}
                            selected={validation.values.currency}
                            error={
                              validation.touched.currency
                                ? validation.errors.currency
                                : ''
                            }
                            onBlur={validation.handleBlur}
                            required
                          />
                        )}
                        {validation.values.country === 'US' && (
                          <DropdownInput
                            options={States.map((s) => ({
                              id: s.abbreviation,
                              state: `${s.name} (${s.abbreviation})`,
                            }))}
                            id="state"
                            label="State"
                            name="state"
                            setSelected={(id) => {
                              validation.setFieldValue('state', id);
                            }}
                            selected={validation.values.state}
                            error={
                              !validation.values.state &&
                              validation.touched.state
                                ? validation.errors.state
                                : ''
                            }
                            onBlur={validation.handleBlur}
                            searchable
                            required
                          />
                        )}
                        {validation.values.country && structures.length > 0 && (
                          <div>
                            <DropdownInput
                              options={structures
                                .filter((d) => d.parentId === null)
                                .map((d) => ({
                                  id: d.name,
                                  type: d.name,
                                }))}
                              label="Business Type"
                              id="type"
                              name="type"
                              disabled={
                                !validation.values.country ||
                                structures.length === 0
                              }
                              setSelected={(id) => {
                                validation.setFieldValue('type', id);
                                findSubStructures(id);
                                if (
                                  id === 'Self-employed' ||
                                  id === 'Sole Proprietorship'
                                ) {
                                  validation.setFieldValue(
                                    'subType',
                                    "I'm not sure",
                                  );
                                } else {
                                  validation.setFieldValue('subType', '');
                                }
                              }}
                              selected={validation.values.type}
                              error={
                                !validation.values.type &&
                                validation.touched.type
                                  ? validation.errors.type
                                  : ''
                              }
                              onBlur={validation.handleBlur}
                              required
                            />
                            {validation.values.type &&
                              subStructures?.length > 0 && (
                                <div className="pt-6 pl-8 relative">
                                  <div className="absolute top-0 left-[16px] w-[1.5px] h-[68%] bg-[#D0D5DD]">
                                    <div className="absolute bottom-0 h-[1.5px] w-[16px] bg-[#D0D5DD]" />
                                  </div>
                                  <DropdownInput
                                    options={subStructures.map((d) => ({
                                      id: d.name,
                                      subType: d.name,
                                    }))}
                                    id="subType"
                                    label={`What type of ${validation.values.type}`}
                                    onBlur={validation.handleBlur}
                                    name="subType"
                                    setSelected={(id) => {
                                      validation.setFieldValue('subType', id);
                                    }}
                                    selected={validation.values.subType}
                                    error={
                                      validation.touched.subType
                                        ? validation.errors.subType
                                        : ''
                                    }
                                    required
                                  />
                                </div>
                              )}
                          </div>
                        )}
                        <button
                          className="btn w-full  py-4 text-lg rounded-[10px] font-semibold !bg-indigo-500 hover:bg-indigo-600 text-white
                           disabled:!bg-indigo-300 disabled:text-white disabled:cursor-not-allowed"
                          type="submit"
                        >
                          Next
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>
              )}

              {/** *******  Step 2    ********* */}

              <Formik
                enableReinitialize
                initialValues={{
                  industryName: '',
                  industry: '',
                  onlinePayment: '',
                  howBusinessGetsPaid: '',
                  website: '',
                }}
                validationSchema={Yup.object({
                  industry: Yup.string().required('Please Select Industry'),
                  industryName: Yup.string()
                    .trim()
                    .max(
                      100,
                      'Industry Name should be less than 100 characters',
                    ),
                  onlinePayment: Yup.string()
                    .trim()
                    .optional('Please Select Value'),
                  howBusinessGetsPaid: Yup.string().trim().optional(''),
                  website: Yup.string()
                    .trim()
                    .nullable()
                    .matches(
                      /^(https?:\/\/)?(www\.)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/\S*)?$/,
                      'Must be a valid URL',
                    ),
                })}
                validate={(values) => {
                  const errors = {};
                  if (values.industry === 'Other (please specify)') {
                    if (!values.industryName || !values.industryName.trim()) {
                      errors.industryName = 'Please Enter Industry Name';
                    }
                  }
                  return errors;
                }}
                onSubmit={async (values) => {
                  setStep2Values(values);
                  setCurrentStep(3);
                }}
                innerRef={formikRef}
              >
                {(validation) => (
                  <Form>
                    {currentStep > 1 && (
                      <>
                        {currentStep === 2 && (
                          <>
                            <div className="space-y-8">
                              <DropdownInput
                                options={IndustryData.map((d) => ({
                                  id: d,
                                  industry: d,
                                }))}
                                id="industry"
                                label="Industry"
                                name="industry"
                                setSelected={(id) =>
                                  validation.setFieldValue('industry', id)
                                }
                                selected={validation.values.industry}
                                error={
                                  validation.touched.industry
                                    ? validation.errors.industry
                                    : ''
                                }
                                onBlur={validation.handleBlur}
                                searchable
                                required
                              />
                              {validation.values.industry ===
                                'Other (please specify)' && (
                                <TextInput
                                  label="Industry Name"
                                  placeholder=" "
                                  id="industryName"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.industryName || ''}
                                  error={
                                    validation.touched.industryName
                                      ? validation.errors.industryName
                                      : ''
                                  }
                                  required
                                />
                              )}
                              <div>
                                <DropdownInput
                                  options={[
                                    {
                                      name: 'Yes',
                                      id: 'yes',
                                    },
                                    {
                                      name: 'No',
                                      id: 'no',
                                    },
                                  ]}
                                  id="onlinePayment"
                                  label="Do you accept payment online"
                                  name="name"
                                  setSelected={(id) =>
                                    validation.setFieldValue(
                                      'onlinePayment',
                                      id,
                                    )
                                  }
                                  selected={validation.values.onlinePayment}
                                  error={
                                    validation.touched.onlinePayment
                                      ? validation.errors.onlinePayment
                                      : ''
                                  }
                                  onBlur={validation.handleBlur}
                                />
                                {validation.values.onlinePayment && (
                                  <div className="pt-6 pl-8 relative">
                                    <div className="absolute top-0 left-[16px] w-[1.5px] h-[68%] bg-[#D0D5DD]">
                                      <div className="absolute bottom-0 h-[1.5px] w-[16px] bg-[#D0D5DD]" />
                                    </div>
                                    <DropdownInput
                                      options={
                                        validation.values.onlinePayment ===
                                        'yes'
                                          ? OnlinePayment
                                          : CustomerPay
                                      }
                                      id="howBusinessGetsPaid"
                                      label={
                                        validation.values.onlinePayment ===
                                        'yes'
                                          ? 'What is your primary mode of online payments?'
                                          : 'How do customers pay now?'
                                      }
                                      name="howBusinessGetsPaid"
                                      setSelected={(id) =>
                                        validation.setFieldValue(
                                          'howBusinessGetsPaid',
                                          id,
                                        )
                                      }
                                      selected={
                                        validation.values.howBusinessGetsPaid
                                      }
                                      error={
                                        validation.touched.howBusinessGetsPaid
                                          ? validation.errors
                                              .howBusinessGetsPaid
                                          : ''
                                      }
                                      onBlur={validation.handleBlur}
                                    />
                                  </div>
                                )}
                              </div>
                              <TextInput
                                label="Website"
                                placeholder=" "
                                id="website"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.website || ''}
                                error={
                                  validation.touched.website
                                    ? validation.errors.website
                                    : ''
                                }
                              />
                              <div>
                                <label
                                  className="block text-sm font-medium mb-1 text-slate-700 dark:text-slate-100 "
                                  htmlFor="logo"
                                >
                                  Logo
                                </label>
                                <div className="flex items-center flex-row-reverse justify-end">
                                  <input
                                    id="logo"
                                    type="file"
                                    name="logo"
                                    accept=".jpg, .svg, .png, .webp, .gif"
                                    onChange={(event) => {
                                      if (event.currentTarget.files) {
                                        validation.setFieldValue(
                                          'logo',
                                          event.currentTarget.files[0],
                                        );
                                        setModalOpen(true);
                                        setImgPreview(
                                          URL.createObjectURL(
                                            event.currentTarget.files[0],
                                          ),
                                        );
                                      }
                                    }}
                                    onBlur={validation.handleBlur}
                                    className="hidden"
                                  />
                                  <label
                                    htmlFor="logo"
                                    className="cursor-pointer inline-flex items-center justify-center btn border-slate-200 dark:border-slate-700 hover:border-slate-300 dark:hover:border-slate-600 shadow-sm text-indigo-500"
                                  >
                                    Choose File
                                  </label>
                                  {imgPreview && (
                                    <img
                                      alt={`${imgPreview} Preview`}
                                      src={imgPreview}
                                      width="40"
                                      height="40"
                                      className="h-10 w-10 mb-1 mr-2 rounded-full object-cover object-center"
                                    />
                                  )}
                                </div>
                              </div>{' '}
                            </div>

                            <button
                              className="btn w-full mt-6 py-4 text-lg rounded-[10px] font-semibold !bg-indigo-500 hover:bg-indigo-600 text-white
                            disabled:!bg-indigo-300 disabled:text-white disabled:cursor-not-allowed"
                              type="submit"
                              disabled={loading}
                            >
                              {loading && (
                                <svg
                                  className="animate-spin w-4 h-4 fill-current shrink-0 mr-2"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z" />
                                </svg>
                              )}
                              Next
                            </button>
                          </>
                        )}
                        {currentStep === 3 && (
                          <div className="flex flex-col items-center justify-center">
                            <div className="h-[70px] w-[70px] bg-[#FFF2EA] border-[8.5px] border-[#FFFAF6] rounded-full inline-flex justify-center items-center">
                              <svg
                                width="22"
                                height="23"
                                viewBox="0 0 25 27"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M22.7708 7.79513L12.5 13.5011M12.5 13.5011L2.22913 7.79513M12.5 13.5011L12.5 24.9803M23.375 18.4053V8.59709C23.375 8.18307 23.375 7.97605 23.314 7.79142C23.26 7.62808 23.1718 7.47815 23.0552 7.35165C22.9235 7.20866 22.7425 7.10813 22.3806 6.90706L13.4389 1.93946C13.0962 1.74908 12.9249 1.65389 12.7434 1.61657C12.5828 1.58354 12.4172 1.58354 12.2566 1.61657C12.0751 1.65389 11.9038 1.74908 11.5611 1.93946L2.61942 6.90706C2.2575 7.10813 2.07654 7.20866 1.94476 7.35165C1.82819 7.47815 1.73997 7.62809 1.686 7.79142C1.625 7.97606 1.625 8.18307 1.625 8.5971V18.4053C1.625 18.8193 1.625 19.0263 1.686 19.2109C1.73997 19.3743 1.82819 19.5242 1.94477 19.6507C2.07654 19.7937 2.2575 19.8942 2.61942 20.0953L11.5611 25.0629C11.9038 25.2533 12.0751 25.3485 12.2566 25.3858C12.4172 25.4188 12.5828 25.4188 12.7434 25.3858C12.9249 25.3485 13.0962 25.2533 13.4389 25.0629L22.3806 20.0953C22.7425 19.8942 22.9235 19.7937 23.0552 19.6507C23.1718 19.5242 23.26 19.3743 23.314 19.2109C23.375 19.0263 23.375 18.8193 23.375 18.4053Z"
                                  stroke="#E48642"
                                  strokeWidth="2.41667"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </div>
                            <h3 className="text-[18px] max-w-[900px] mx-auto mb-5 md:text-[24px] text-[#232323CC] font-medium text-center leading-tight mt-4">
                              {IsAccountant && teams?.length === 0
                                ? ` As an accounting partner, we are happy to offer you a complimentary Growing tier of COUNT for your accounting business.`
                                : `COUNT offers a range of subscription plans
                              tailored to meet the needs of businesses at every
                              stage. All plans come with many unlimited options
                              and are backed by our AI engine to make accounting
                              painless.`}
                              {/* See more details on our pricing page. */}
                            </h3>
                            {/* <p className="text-lg text-[#667085CC] leading-tight mt-2 text-center">
                                Get a free one month on us!
                              </p> */}
                            {!(IsAccountant && teams?.length === 0) &&
                            subscriptionLoading ? (
                              <div className="flex flex-col gap-2 items-center pb-2 justify-center mt-8">
                                <svg
                                  width="29"
                                  height="29"
                                  viewBox="0 0 29 29"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="animate-spin w-10 h-10 fill-current shrink-0"
                                >
                                  <path
                                    d="M14.5 2.41699V7.25033"
                                    stroke="#E48642"
                                    strokeWidth="2.55882"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                  <path
                                    d="M14.5 21.749V26.5824"
                                    stroke="#E48642"
                                    strokeWidth="2.55882"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                  <path
                                    d="M5.95703 5.95703L9.37662 9.37662"
                                    stroke="#E48642"
                                    strokeWidth="1.58955"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                  <path
                                    d="M19.623 19.6211L23.0426 23.0407"
                                    stroke="#E48642"
                                    strokeWidth="1.58955"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                  <path
                                    d="M2.41699 14.5H7.25033"
                                    stroke="#E48642"
                                    strokeWidth="1.58955"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                  <path
                                    d="M21.749 14.5H26.5824"
                                    stroke="#E48642"
                                    strokeWidth="1.58955"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                  <path
                                    d="M5.95703 23.0407L9.37662 19.6211"
                                    stroke="#E48642"
                                    strokeWidth="1.58955"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                  <path
                                    d="M19.623 9.37662L23.0426 5.95703"
                                    stroke="#E48642"
                                    strokeWidth="1.58955"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                                <p className="text-slate-600 font-medium text-[0.875rem] text-center">
                                  Please wait...
                                </p>
                              </div>
                            ) : (
                              <SubscriptionPlan
                                subscriptions={subscriptions}
                                selectedSubscription={selectedSubscription?.id}
                                setSelectedSubscription={
                                  setSelectedSubscription
                                }
                                country={step1Values?.country}
                                teams={teams}
                              />
                            )}

                            {/* <div className="w-full border border-[#D0D5DD] rounded-[10px] py-6 mt-6 shadow-lg">
                              <div className="px-6 border-b border-[#D0D5DD] pb-4">
                                <div className="h-10 px-4 bg-[#FBEFE6] rounded-full text-[20px] text-indigo-500 font-medium inline-flex items-center leading-tight">
                                  {promoCodeResponse !== '' &&
                                  promoCodeResponse !== 'error'
                                    ? promoCodeResponse
                                    : 14}{' '}
                                  days free trial
                                </div>
                                <p className="text-base leading-tight text-[#667085] mt-2">
                                  No credit card required. Free for{' '}
                                  {promoCodeResponse !== '' &&
                                  promoCodeResponse !== 'error'
                                    ? promoCodeResponse
                                    : 14}{' '}
                                  days
                                </p>
                              </div>
                              <div className="px-6 pt-6">
                                <h3 className="text-[20px] font-medium text-[#101828] mb-6 leading-tight">
                                  Features
                                </h3>
                                <div className="flex gap-3 mb-4">
                                  <svg
                                    width="22"
                                    height="22"
                                    viewBox="0 0 22 22"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M6.5 11L9.5 14L15.5 8M21 11C21 16.5228 16.5228 21 11 21C5.47715 21 1 16.5228 1 11C1 5.47715 5.47715 1 11 1C16.5228 1 21 5.47715 21 11Z"
                                      stroke="#E48642"
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                  <p className="text-base text-[#667085] leading-tight">
                                    Review & categorize transactions
                                    effortlessly
                                  </p>
                                </div>
                                <div className="flex gap-3 mb-4">
                                  <svg
                                    width="22"
                                    height="22"
                                    viewBox="0 0 22 22"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M6.5 11L9.5 14L15.5 8M21 11C21 16.5228 16.5228 21 11 21C5.47715 21 1 16.5228 1 11C1 5.47715 5.47715 1 11 1C16.5228 1 21 5.47715 21 11Z"
                                      stroke="#E48642"
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                  <p className="text-base text-[#667085] leading-tight">
                                    Simplify complex payments with split options
                                  </p>
                                </div>
                                <div className="flex gap-3 mb-3">
                                  <svg
                                    width="22"
                                    height="22"
                                    viewBox="0 0 22 22"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M6.5 11L9.5 14L15.5 8M21 11C21 16.5228 16.5228 21 11 21C5.47715 21 1 16.5228 1 11C1 5.47715 5.47715 1 11 1C16.5228 1 21 5.47715 21 11Z"
                                      stroke="#E48642"
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                  <p className="text-base text-[#667085] leading-tight">
                                    Collaborate with teammates in your workspace
                                  </p>
                                </div>
                                <div className="flex gap-3 mb-3">
                                  <svg
                                    width="22"
                                    height="22"
                                    viewBox="0 0 22 22"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M6.5 11L9.5 14L15.5 8M21 11C21 16.5228 16.5228 21 11 21C5.47715 21 1 16.5228 1 11C1 5.47715 5.47715 1 11 1C16.5228 1 21 5.47715 21 11Z"
                                      stroke="#E48642"
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                  <p className="text-base text-[#667085] leading-tight">
                                    Optimize spending by monitoring
                                    category-level insights
                                  </p>
                                </div>
                              </div>
                            </div> */}
                            {/* {displayReferralInput ? (
                              <div className="w-full flex justify-start items-center mt-3 h-8 gap-x-2.5 ">
                                <div className="relative w-fit flex">
                                  <input
                                    id="referral"
                                    type="text"
                                    placeholder="Promo Code"
                                    name="referral"
                                    className={`btn text-base pr-8 w-[200px] h-8  rounded-[0.313rem] border  text-slate-600 leading-6 font-normal px-2 py-2 bg-white
                              placeholder:text-base placeholder:text-slate-600 placeholder:text-opacity-80 placeholder:leading-6 placeholder:font-normal ${
                                promoCodeResponse === ''
                                  ? 'border-[#D0D5DD]'
                                  : promoCodeResponse === 'error'
                                    ? 'border-[#FF4B4B]'
                                    : 'border-[#40AA00]'
                              }`}
                                    value={referralCode}
                                    onChange={(e) => {
                                      setPromoCodeResponse('');
                                      setReferralCode(e.target.value);
                                    }}
                                  />
                                  <span className="absolute top-1/2 right-1 -translate-y-1/2 min-w-[20px]">
                                    {promoCodeResponse === 'error' && (
                                      <svg
                                        width="20"
                                        height="17"
                                        viewBox="0 0 20 17"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M10.0001 6.50019V9.83353M10.0001 13.1669H10.0085M8.84622 2.24329L1.99215 14.0821C1.61198 14.7388 1.42189 15.0671 1.44999 15.3366C1.47449 15.5716 1.59763 15.7852 1.78876 15.9242C2.0079 16.0835 2.38728 16.0835 3.14605 16.0835H16.8542C17.613 16.0835 17.9923 16.0835 18.2115 15.9242C18.4026 15.7852 18.5258 15.5716 18.5503 15.3366C18.5783 15.0671 18.3883 14.7388 18.0081 14.0821L11.154 2.24329C10.7752 1.58899 10.5858 1.26184 10.3387 1.15196C10.1232 1.05612 9.87709 1.05612 9.66154 1.15196C9.41443 1.26184 9.22503 1.58899 8.84622 2.24329Z"
                                          stroke="#B42318"
                                          strokeWidth="1.66667"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                      </svg>
                                    )}

                                    {promoCodeResponse !== 'error' &&
                                      promoCodeResponse !== '' && (
                                        <svg
                                          width="20"
                                          height="20"
                                          viewBox="0 0 20 20"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            d="M6.24935 10.0003L8.74935 12.5003L13.7493 7.50033M18.3327 10.0003C18.3327 14.6027 14.6017 18.3337 9.99935 18.3337C5.39698 18.3337 1.66602 14.6027 1.66602 10.0003C1.66602 5.39795 5.39698 1.66699 9.99935 1.66699C14.6017 1.66699 18.3327 5.39795 18.3327 10.0003Z"
                                            stroke="#78BD4F"
                                            strokeWidth="1.66667"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          />
                                        </svg>
                                      )}
                                  </span>
                                </div>
                                <div
                                  className="text-sm text-indigo-500 font-medium text-left mt-3 cursor-pointer h-8 flex items-center"
                                  onClick={checkReferrelCode}
                                >
                                  {promoLoader ? (
                                    <svg
                                      className="animate-spin w-4 h-4 fill-current shrink-0 mb-2"
                                      viewBox="0 0 16 16"
                                    >
                                      <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z" />
                                    </svg>
                                  ) : (
                                    <span className="mb-2">Validate</span>
                                  )}
                                </div>
                              </div>
                            ) : (
                              <div className="w-full flex items-start">
                                <p
                                  className="text-sm text-indigo-500 text-left mt-3 cursor-pointer underline h-8"
                                  onClick={() => setDisplayReferralInput(true)}
                                >
                                  Have a promo code?
                                </p>
                              </div>
                            )} */}
                            <button
                              className={`btn ${
                                displayReferralInput ? 'mt-3' : 'mt-5'
                              } w-fit  py-4 text-lg px-8 rounded-[10px] font-semibold !bg-indigo-500 hover:bg-indigo-600 text-white
                           disabled:!bg-indigo-300 disabled:text-white disabled:cursor-not-allowed`}
                              onClick={() => {
                                const {
                                  name,
                                  type,
                                  subType,
                                  country,
                                  state,
                                  currency,
                                  email,
                                } = step1Values;
                                const {
                                  industryName,
                                  industry,
                                  howBusinessGetsPaid,
                                  website,
                                  logo,
                                } = step2Values;
                                onCreateCompany(
                                  name,
                                  industry === 'Other (please specify)'
                                    ? industryName
                                    : industry,
                                  type,
                                  subType,
                                  country,
                                  state,
                                  howBusinessGetsPaid,
                                  currency,
                                  website,
                                  logo,
                                  email,
                                );
                              }}
                              type="button"
                              disabled={
                                loading ||
                                subscriptionLoading ||
                                (IsAccountant && teams?.length === 0
                                  ? false
                                  : !selectedSubscription)
                              }
                            >
                              {loading && (
                                <svg
                                  className="animate-spin w-4 h-4 fill-current shrink-0 mr-2"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z" />
                                </svg>
                              )}
                              {IsAccountant && teams?.length === 0
                                ? 'Subscribe for Free!'
                                : selectedSubscription?.unit_amount === 0
                                  ? 'Subscribe Free Plan'
                                  : 'Start 14 Day Free Trial'}
                            </button>
                          </div>
                        )}
                      </>
                    )}
                  </Form>
                )}
              </Formik>
              {/** *******  Step 3    ********* */}
              {/* {currentStep === 3 && <TrialStep />} */}
            </div>
          </div>
        </div>

        {/* Auth Slider */}

        {currentStep !== 3 && <AuthSlider />}
      </div>
    </main>
  );
};

export default Workspace;
