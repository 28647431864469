import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import TeamsTable from '../../manageWorkspaces/manage/TeamsTable';
import { setAccessType, setLocalUser } from '../../../redux/User/reducer';
import {
  OWNER,
  STAFF,
  SUPPORT,
  TEAM_ID,
  TEAM_NAME,
} from '../../../utils/Utils';
import {
  deleteTeamApi,
  getUser,
  leaveTeamApi,
} from '../../../API/backend_helper';
import LeaveModal from '../../manageWorkspaces/manage/LeaveModal';

const Clients = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { localUser } = useSelector((state) => state.User);
  const TEAMId = localStorage.getItem(TEAM_ID);

  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState({});
  const [TeamId, setTeamId] = useState('');
  const [deleteWorkspace, setDeleteWorkspace] = useState(null);
  const [leaveTeam, setLeaveTeam] = useState(null);
  const [isOpenModal, setIsOpenModal] = useState(false);

  useEffect(() => {
    if (TEAMId) {
      setTeamId(parseInt(TEAMId));
    }
  }, [TEAMId]);

  useEffect(() => {
    if (localUser) {
      setUser(localUser);
    }
  }, [localUser]);

  const getUserApi = async (selectTeam) => {
    try {
      const response = await getUser();
      dispatch(setLocalUser(response));
      setUser(response);

      if (selectTeam && response?.teams?.length > 0) {
        if (response?.realm === STAFF) {
          dispatch(setAccessType(OWNER));
        } else {
          dispatch(setAccessType(response?.teams?.[0]?.credentials?.role));
        }
        localStorage.setItem(TEAM_ID, response?.teams?.[0]?.id);
        localStorage.setItem(TEAM_NAME, response?.teams?.[0]?.name);
        setTeamId(response?.teams?.[0]?.id);
      }
    } catch (err) {
      console.log('err', err);
    }
  };

  const onDeleteWorkspace = async () => {
    setLoading(true);
    try {
      await deleteTeamApi(deleteWorkspace?.id);
      localStorage.setItem(TEAM_ID, '');
      localStorage.setItem(TEAM_NAME, '');
      setTeamId('');
      getUserApi(true);
      setDeleteWorkspace(null);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setDeleteWorkspace(false);
      console.log('err', err);
    }
  };

  const onTeamLeave = async () => {
    setLoading(true);
    try {
      const { team, index } = leaveTeam;
      await leaveTeamApi(team?.id);
      if (index !== localUser?.teams?.length - 1) {
        if (team?.id === TeamId) {
          const teamOwner = localUser?.teams?.find((t) => t?.role === OWNER);
          if (teamOwner?.id) {
            if (user?.realm === STAFF || user?.realm === SUPPORT) {
              dispatch(setAccessType(OWNER));
            } else {
              dispatch(setAccessType(teamOwner?.credentials?.role));
            }
            localStorage.setItem(TEAM_ID, teamOwner?.id);
            localStorage.setItem(TEAM_NAME, teamOwner?.name);
            setTeamId(teamOwner?.id);
          }
        } else {
          localStorage.setItem(TEAM_ID, '');
          localStorage.setItem(TEAM_NAME, '');

          setTeamId('');
        }
      } else {
        localStorage.setItem(TEAM_ID, '');
        localStorage.setItem(TEAM_NAME, '');
        setTeamId('');
      }
      getUserApi(true);
      setIsOpenModal(false);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setIsOpenModal(false);
      console.log('err', err);
    }
  };

  const onTeamChange = (id, teamObj = null) => {
    if (id) {
      localStorage.setItem(TEAM_ID, id);
      localStorage.setItem(TEAM_NAME, teamObj?.name);
      setTeamId(id);
      if (user?.realm === STAFF || user?.realm === SUPPORT) {
        dispatch(setAccessType(OWNER));
      }
      navigate('/');
      // window.location.reload();
    }
  };

  useEffect(() => {
    getUserApi();
  }, []);

  return (
    <div>
      <LeaveModal
        isOpen={deleteWorkspace}
        title="Delete Workspace"
        description={`Are you sure you want to delete "${deleteWorkspace?.name}"? This action cannot be undone.`}
        setIsOpen={setDeleteWorkspace}
        onCompletion={onDeleteWorkspace}
        loading={loading}
        team={deleteWorkspace}
      />
      <LeaveModal
        isOpen={isOpenModal}
        title="Leave Workspace"
        description="Are you sure you want to leave selected worksapce(s) ? This action cannot be undone."
        setIsOpen={setIsOpenModal}
        onCompletion={onTeamLeave}
        loading={loading}
        team={leaveTeam}
      />
      <TeamsTable
        setLeaveTeam={setLeaveTeam}
        user={user}
        onTeamChange={onTeamChange}
        setDeleteWorkspace={setDeleteWorkspace}
        setIsOpenModal={setIsOpenModal}
        accountantView
      />
    </div>
  );
};

export default Clients;
