import React from "react";

export const CalendarIcon = () => {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M7.75 3V5'
        stroke='#667085'
        stroke-opacity='0.8'
        stroke-width='1.2'
        stroke-miterlimit='10'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M12.75 3V5'
        stroke='#667085'
        stroke-opacity='0.8'
        stroke-width='1.2'
        stroke-miterlimit='10'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M3.75 8H15.75'
        stroke='#667085'
        stroke-opacity='0.8'
        stroke-width='1.2'
        stroke-miterlimit='10'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M16.75 7.51351V13.4865C16.75 15.5946 15.6667 17 13.1389 17H7.36111C4.83333 17 3.75 15.5946 3.75 13.4865V7.51351C3.75 5.40541 4.83333 4 7.36111 4H13.1389C15.6667 4 16.75 5.40541 16.75 7.51351Z'
        stroke='#667085'
        stroke-opacity='0.8'
        stroke-width='1.2'
        stroke-miterlimit='10'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M13.2455 11.5H13.2545'
        stroke='#667085'
        stroke-opacity='0.8'
        stroke-width='1.2'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M10.2455 11.5H10.2545'
        stroke='#667085'
        stroke-opacity='0.8'
        stroke-width='1.2'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M7.2455 11.5H7.2545'
        stroke='#667085'
        stroke-opacity='0.8'
        stroke-width='1.2'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M12.9955 14H13.0045'
        stroke='#667085'
        stroke-opacity='0.8'
        stroke-width='1.2'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M9.9955 14H10.0045'
        stroke='#667085'
        stroke-opacity='0.8'
        stroke-width='1.2'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M7.2455 14.5H7.2545'
        stroke='#667085'
        stroke-opacity='0.8'
        stroke-width='1.2'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  );
};
