import React, { useEffect, useRef, useState } from 'react';
import { HexColorPicker, HexColorInput } from 'react-colorful';
import { useFormikContext } from 'formik';
import colorPickerImg from '../../images/custom/colorpicker.png';
import useClickOutside from '../../utils/useClickOutside';

const ColorPicker = ({ ColorOptions, selectedColor, setSelectedColor }) => {
  const { setFieldValue, values } = useFormikContext();
  const [colorPickerOpen, setColorPickerOpen] = useState(false);
  const colorPicker = useRef();

  useEffect(() => {
    if (values) {
      setSelectedColor(values.color);
    } else {
      setSelectedColor('#D2D6DB');
    }
  }, [values]);

  useEffect(() => {
    if (!selectedColor) {
      setSelectedColor('#D2D6DB');
    }
  }, [colorPickerOpen]);

  useClickOutside(colorPicker, () => {
    setColorPickerOpen(false);
  });

  const handleColorSelect = (color) => {
    setFieldValue('color', color);
    setSelectedColor(color);
  };

  return (
    <div className="flex flex-row gap-[0.524rem] relative">
      <div className="flex flex-row gap-[0.524rem]">
        <img
          src={colorPickerImg}
          alt="color picker"
          className="cursor-pointer"
          onClick={() => {
            setColorPickerOpen(true);
          }}
        />
        <HexColorInput
          color={selectedColor}
          onChange={handleColorSelect}
          prefixed
          className="h-5 w-[4.1rem] border-[1.2px] border-[#D0D5DD] rounded-md text-slate-600 !text-xs px-1"
        />
      </div>
      {ColorOptions.map((color, index) => (
        <button
          key={index}
          type="button"
          onClick={() => handleColorSelect(color)}
          className={`cursor-pointer w-[1.088rem] h-[1.088rem] rounded-full border-2 box-border ${color === selectedColor ? 'border-white' : 'border-transparent'}`}
          style={{
            background: color,
            boxShadow:
              color === selectedColor
                ? '0px 0px 10.482px rgba(0, 0, 0, 0.30)'
                : 'none',
          }}
        />
      ))}
      {colorPickerOpen && (
        <div ref={colorPicker} className="absolute z-50 top-8 left-0">
          <HexColorPicker color={selectedColor} onChange={handleColorSelect} />
        </div>
      )}
    </div>
  );
};

export default ColorPicker;
