import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';

const fetchImageAsDataUrl = async (url) => {
  try {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error('Failed to fetch image');
    }
    const blob = await response.blob();
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  } catch (error) {
    console.error(error);
    return url; // Fallback to original URL if proxy fails
  }
};

const downloadPdf = async (
  elementId,
  fileName="download.pf",
  pdfSize = 'a4',
  orientation = 'portrait',
) => {
  const element = document.getElementById(elementId);

  // Convert all images within the element to data URLs
  const images = element.querySelectorAll('img');
  for (const img of images) {
    const imgUrl = img.src;
    img.src = await fetchImageAsDataUrl(imgUrl);
  }

  // Ensure images are fully loaded
  await new Promise((resolve) => {
    let loadedImages = 0;
    if (images.length === 0) {
      resolve();
      return;
    }

    images.forEach((img) => {
      if (img.complete) {
        loadedImages++;
        if (loadedImages === images.length) resolve();
      } else {
        img.onload = () => {
          loadedImages++;
          if (loadedImages === images.length) resolve();
        };
        img.onerror = () => {
          loadedImages++;
          if (loadedImages === images.length) resolve();
        };
      }
    });
  });

  // Set up the PDF dimensions based on orientation
  const pdf = new jsPDF(orientation, 'mm', pdfSize);
  const pdfWidth = pdf.internal.pageSize.getWidth();
  const pdfHeight = pdf.internal.pageSize.getHeight();

  // Convert PDF dimensions to pixels (1 mm = 3.7795275591 px)
  const pdfWidthPx = pdfWidth * 3.7795275591;
  const pdfHeightPx = pdfHeight * 3.7795275591 * 2.205;

  // Render the element to a canvas
  const canvas = await html2canvas(element, {
    scale: 2,
    allowTaint: true,
    useCors: true,
  });
  const imgData = canvas.toDataURL('image/png');

  const imgWidth = canvas.width;
  const imgHeight = canvas.height;

  // Calculate the number of pages needed
  const numPages = Math.ceil(imgHeight / pdfHeightPx);

  for (let i = 0; i < numPages; i++) {
    const startY = i > 0 ? i * pdfHeightPx : i * pdfHeightPx;
    const endY = Math.min((i + 1) * pdfHeightPx, imgHeight);

    // Create a temporary canvas for each page
    const pageCanvas = document.createElement('canvas');
    pageCanvas.width = imgWidth;
    pageCanvas.height = endY - startY;
    const pageCtx = pageCanvas.getContext('2d');

    // Draw the current page section onto the temporary canvas
    pageCtx.drawImage(
      canvas,
      0,
      startY,
      imgWidth,
      endY - startY,
      0,
      0,
      imgWidth,
      endY - startY,
    );

    const pageImgData = pageCanvas.toDataURL('image/png');
    if (i > 0) {
      pdf.addPage();
    }

    // Add the image to the PDF page with exact positioning
    pdf.addImage(
      pageImgData,
      'PNG',
      0,
      0,
      pdfWidth,
      (pageCanvas.height * pdfWidth) / pageCanvas.width,
    );
  }

  // Save the PDF
  pdf.save(fileName);
};

export default downloadPdf;
