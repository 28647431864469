import React from "react";
import Header from "../partials/Header";
import { Link, useNavigate } from "react-router-dom";

const TermsOfServices = () => {
  const navigate = useNavigate();

  return (
    <div className="flex h-[100dvh] overflow-hidden">

      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        {/*  Site header */}
        <Header showHamburger={false} showUserMenu={false} />

        <main className="grow px-10 py-8 w-full max-w-9xl mx-auto">
          <div>
            <div className="flex gap-4 items-center mb-4">
              <button
                onClick={() => {
                  navigate("/");
                }}
                className="h-8 w-8 bg-[#FBF3ED] rounded inline-flex justify-center items-center"
              >
                <svg
                  width="10"
                  height="17"
                  viewBox="0 0 10 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8 2.5L2 8.5L8 14.5"
                    stroke="#E48642"
                    stroke-width="3.6"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </button>
              <h3 className="text-2xl font-medium leading-tight text-[#101828] ">
                Terms of Service
              </h3>
            </div>

            <div className="space-y-4">
              <p>Last Updated: March 14, 2024</p>
              <p>
                These Terms of Service ("Terms") govern your use of COUNT, an
                online SaaS accounting software provided by Not All Talk LLC
                ("COUNT," "we," "our," or "us"), accessible via the domain
                getcount.com. By accessing or using COUNT, you agree to these
                Terms.
              </p>
              <p>
                Within these Terms, the term "Site" refers to www.getcount.com
                and any successor websites, while "Application" refers to the
                software provided by COUNT, including any use of the COUNT API.
                The "Services" encompass all features, tools, and applications
                provided through the Site and the Application, including any
                third-party services integrated or utilized by COUNT to enhance
                your user experience. By agreeing to these Terms, you authorize
                COUNT to manage payment transactions, including storing payment
                credentials and executing payment instructions on your behalf
                for both one-time and recurring transactions.
              </p>
              <p>
                COUNT might also offer additional websites and software
                solutions that facilitate or complement our core services, all
                of which fall under the scope of these Terms and the COUNT
                Privacy Policy.
              </p>
              <p>
                Please ensure you read these Terms carefully before utilizing
                our Services. Accessing or using our Services signifies your
                acknowledgment and agreement to be legally bound by these Terms,
                including all referenced policies and guidelines. Should you
                find any aspect of these Terms unacceptable, you must refrain
                from using our Services.
              </p>
              <p>
                COUNT reserves the right to modify these Terms at our sole
                discretion, with changes communicated through the email address
                you provide upon registration or via a notification within the
                Application. Such modifications become effective immediately
                upon their posting on the Site, and your continued use of our
                Services thereafter signifies your agreement to the revised
                Terms. It's advisable to review these Terms periodically to stay
                informed about your rights and obligations as a user of our
                Services.
              </p>
              <p>
                If you disagree with the revised Terms, you must stop using our
                Services immediately. You may cancel your account at any time;
                however, cancellation does not exempt you from paying any
                outstanding balances for the current billing period.
              </p>
              <p>
                Our Services are designed for use by individuals and entities
                that seek to optimize their accounting and financial management
                processes. The use of our Services is restricted to registered
                users ("Subscribers") and their designated "Customers,"
                alongside any other user engaging with the Site or Services,
                including contributors to forums or comment sections.
              </p>
              <p>
                COUNT Services are intended for your personal and business use.
                Resale, lease, or unauthorized distribution of our services is
                strictly prohibited, except as explicitly permitted through the
                Application. Our Services require users to be at least eighteen
                (18) years old, and we will terminate the access of any user
                identified as being under this age limit.
              </p>
              <h3 className="text-lg font-medium text-[#101828]">
                1. Privacy Policy
              </h3>
              <p>
                Refer to COUNT’s Privacy Policy on our website
                (www.getcount.com) for detailed information on how your personal
                and business data is collected, used, and protected when you use
                our services.
              </p>
              <h3 className="text-lg font-medium text-[#101828]">
                2. Registration Data; Account Security
              </h3>
              <p>
                You agree to provide accurate, current, and complete information
                during the registration process and to update such information
                to keep it accurate, current, and complete. COUNT reserves the
                right to suspend or terminate accounts with incomplete or
                inaccurate information. You are responsible for safeguarding
                your password and for all activities that occur under your
                account.
              </p>

              <h3 className="text-lg font-medium text-[#101828]">
                3. Fees; Charges; Taxes
              </h3>
              <p>
                COUNT charges subscription fees for access to certain services.
                All fees are exclusive of taxes, levies, or duties imposed by
                taxing authorities, and you shall be responsible for payment of
                all such taxes, levies, or duties associated with your
                subscription.
              </p>

              <h3 className="text-lg font-medium text-[#101828]">
                4. Ownership, Copyright and Trademarks
              </h3>
              <p>
                All materials provided by COUNT, including software, services,
                and related graphics, are owned by or licensed to Not All Talk
                LLC and are protected by copyright and trademark laws. COUNT and
                its logo are trademarks of Not All Talk LLC.
              </p>
              <h3 className="text-lg font-medium text-[#101828]">
                5. Your Limited License of Your User Content to COUNT
              </h3>
              <p>
                By posting, uploading, or submitting content to COUNT, you grant
                COUNT a worldwide, non-exclusive, royalty-free license to use,
                reproduce, and display such content in connection with providing
                the Services.
              </p>

              <h3 className="text-lg font-medium text-[#101828]">
                6. Our Limited License of Content to You
              </h3>
              <p>
                COUNT grants you a limited, revocable, non-exclusive license to
                access and use the Services and Content provided by COUNT for
                your business purposes, subject to the terms of these Terms of
                Service.
              </p>
              <h3 className="text-lg font-medium text-[#101828]">
                7. Using the COUNT API
              </h3>
              <p>
                If you use the COUNT API, you agree to use it responsibly and in
                compliance with these Terms, including not using it in a manner
                that exceeds reasonable request volumes, constitutes excessive
                or abusive usage, or otherwise fails to comply with the Terms.
              </p>

              <h3 className="text-lg font-medium text-[#101828]">
                8. Use of Interactive Areas and the Services
              </h3>
              <p>
                COUNT may provide interactive areas, such as forums or blogs.
                You are solely responsible for your use of such areas and use
                them at your own risk.
              </p>
              <h3 className="text-lg font-medium text-[#101828]">
                9. Providing a Reliable and Secure Service
              </h3>
              <p>
                COUNT aims to provide a secure and reliable service. However, we
                cannot guarantee that our service will be secure or
                uninterrupted at all times.
              </p>
              <h3 className="text-lg font-medium text-[#101828]">
                10. No Responsibility for Third-Party Services, Sites or Content
              </h3>
              <p>
                COUNT is not responsible for the content, services, or practices
                of third-party sites, services, or content linked to or from the
                COUNT service.
              </p>
              <h3 className="text-lg font-medium text-[#101828]">
                11. Advertisements and Promotions
              </h3>
              <p>
                COUNT may run advertisements and promotions from third parties
                on the site. Your dealings with advertisers found on or through
                the Services are solely between you and such advertisers.
              </p>
              <h3 className="text-lg font-medium text-[#101828]">
                12. Electronic Signature
              </h3>
              <p>
                By using COUNT’s services, you consent to use electronic
                signatures, acknowledging that they are legally binding in the
                same manner as a handwritten signature.
              </p>
              <h3 className="text-lg font-medium text-[#101828]">
                13. Warranty Disclaimer
              </h3>
              <p>
                YOUR USE OF THE SITE, SERVICES, SOFTWARE (INCLUDING WITHOUT
                LIMITATION, THE APPLICATION AND THE COUNT API), AND CONTENT
                (COLLECTIVELY, THE “COUNT SOLUTION”), AND ANY THIRD PARTY
                MATERIALS OR THIRD PARTY SERVICES IS ENTIRELY AT YOUR OWN RISK,
                AND EXCEPT AS DESCRIBED IN THIS AGREEMENT, ARE PROVIDED “AS IS.”
                TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, COUNT, ITS
                SUBSIDIARIES AND AFFILIATES, AND THEIR THIRD PARTY PROVIDERS,
                LICENSORS, DISTRIBUTORS OR SUPPLIERS (COLLECTIVELY “SUPPLIERS”)
                DISCLAIM ALL WARRANTIES AND CONDITIONS, EXPRESS OR IMPLIED,
                INCLUDING ANY WARRANTY OR CONDITION THAT THE COUNT SOLUTION OR
                THIRD PARTY MATERIALS OR THIRD PARTY SERVICES ARE OR WILL (A) BE
                FIT FOR A PARTICULAR PURPOSE, (B) BE OF GOOD TITLE, (C) BE OF
                MERCHANTABLE QUALITY; OR THEY DO NOT OR WILL NOT INTERFERE WITH
                OR INFRINGE OR MISAPPROPRIATE ANY INTELLECTUAL PROPERTY RIGHTS.
                FURTHERMORE, THE SUPPLIERS DISCLAIM ALL WARRANTIES AND
                CONDITIONS, EXPRESS OR IMPLIED AS TO THE ACCURACY, RELIABILITY,
                QUALITY OF CONTENT IN OR LINKED TO THE COUNT SOLUTION. COUNT,
                ITS SUBSIDIARIES AND ITS AFFILIATES AND SUPPLIERS DO NOT WARRANT
                THAT THE COUNT SOLUTION IS OR WILL BE SECURE, FREE FROM BUGS,
                VIRUSES, INTERRUPTION, DATA LOSS, ERRORS, THEFT OR DESTRUCTION.
              </p>
              <p>
                SOME PROVINCES DO NOT ALLOW THE EXCLUSION OF IMPLIED WARRANTIES
                OR CONDITIONS, SO THE ABOVE EXCLUSIONS MAY NOT APPLY TO YOU.
                THIS WARRANTY GIVES YOU SPECIFIC LEGAL RIGHTS, AND YOU MAY HAVE
                OTHER RIGHTS THAT VARY FROM PROVINCE TO PROVINCE.
              </p>
              <p>
                COUNT, ITS SUBSIDIARIES AFFILIATES AND SUPPLIERS DISCLAIM ANY
                REPRESENTATIONS OR WARRANTIES THAT YOUR USE OF THE SERVICES WILL
                SATISFY OR ENSURE COMPLIANCE WITH ANY LEGAL OBLIGATIONS OR LAWS
                OR REGULATIONS.
              </p>
              <h3 className="text-lg font-medium text-[#101828]">
                14. Limitation of Liability; Indemnity
              </h3>
              <p>
                You waive and shall not assert any claims or allegations of any
                nature whatsoever against COUNT, its affiliates or subsidiaries,
                their sponsors, contractors, advertisers, vendors or other
                partners, any of their successors or assigns, or any of their
                respective officers, directors, agents or employees
                (collectively, the “Released Parties”) arising out of or in any
                way relating to your use of the COUNT Solution or any Third
                Party Materials or Third Party Services, including, without
                limitation, any claims or allegations relating to infringement
                of proprietary rights, or allegations that any Released Party
                has or should indemnify, defend or hold harmless you or any
                third party from any claim or allegation arising from your use
                or other exploitation of the COUNT Solution or any Third Party
                Materials or Third Party Services. You use the COUNT Solution or
                any Third Party Materials or Third Party Services at your own
                risk.
              </p>
              <p>
                Without limitation of the foregoing, neither COUNT nor any other
                Released Party shall be liable for any direct, special,
                indirect, incidental, exemplary, punitive, or consequential
                damages, or any other damages of any kind, including but not
                limited to loss of use, loss of profits or loss of data, whether
                in an action in contract, tort (including but not limited to
                negligence) or otherwise, arising out of or in any way connected
                with the use of or inability to use the COUNT Solution or any
                Third Party Materials or Third Party Services, including without
                limitation any damages caused by or resulting from your reliance
                on the COUNT Solution or any Third Party Materials or Third
                Party Services or other information obtained from COUNT or any
                other Released Party or accessible via the COUNT Solution or any
                Third Party Materials or Third Party Services, or that result
                from mistakes, errors, omissions, interruptions, deletion of
                files or email, defects, viruses, delays in operation or
                transmission or any failure of performance, whether or not
                resulting from acts of god, communications failure, theft,
                destruction or unauthorized access to COUNT or any other
                Released Party’s records, programs or services.
              </p>
              <p>
                In no event shall the aggregate liability of COUNT, whether in
                contract, warranty, tort (including negligence, whether active,
                passive or imputed), product liability, strict liability or
                other theory, arising out of or relating to the use of the COUNT
                Solution exceed any compensation paid by you for access to or
                use of the COUNT Solution during the three months prior to the
                date of any claim. In no event shall COUNT have or incur any
                liability, whether in contract, warranty, tort (including
                negligence, whether active, passive or imputed), product
                liability, strict liability or other theory, arising out of or
                relating to the use any Third Party Materials or Third Party
                Services.
              </p>
              <p>
                You shall defend, indemnify and hold harmless COUNT and the
                other Released Parties from any loss, damages, liabilities,
                costs, expenses, claims and proceedings arising out of your use
                of the COUNT Solution or any Third Party Materials or Third
                Party Services, and if you are a Subscriber, from your
                Customers’ use of the Services and from the use of the COUNT
                Solution or any Third Party Materials or Third Party Services by
                any person to whom you give access to your account (including
                staff or advisors), including any claims made by any person that
                any of your, and if you are a Subscriber, your Customers’, User
                Content infringes the rights, including the intellectual
                property rights, of any third party (collectively, “Indemnified
                Claims”). COUNT reserves the right, in its sole discretion and
                at its own expense, to assume the exclusive defense and control
                of any Indemnified Claims. You agree to reasonably cooperate as
                requested by COUNT in the defense of any Indemnified Claims.
              </p>

              <h3 className="text-lg font-medium text-[#101828]">
                15. Communications
              </h3>
              <p>
                By using COUNT, you consent to receive electronic communications
                from us regarding your account and the Services.
              </p>
              <h3 className="text-lg font-medium text-[#101828]">
                16. Applicable Law and Venue
              </h3>
              <p>
                These Terms shall be governed by the laws of the State of
                Washington, without regard to its conflict of law provisions.
              </p>
              <h3 className="text-lg font-medium text-[#101828]">
                17. Termination/Modification of License and Site Offerings
              </h3>
              <p>
                COUNT reserves the right, at its sole discretion, to modify or
                discontinue, temporarily or permanently, the Services (or any
                part thereof) with or without notice.
              </p>
              <h3 className="text-lg font-medium text-[#101828]">
                18. Inactive Accounts; Termination of Agreement
              </h3>
              <p>
                Accounts that are inactive for an extended period may be closed.
                Either you or COUNT may terminate this agreement at any time
                upon notice to the other party.
              </p>
              <h3 className="text-lg font-medium text-[#101828]">
                19. Miscellaneous
              </h3>
              <p>
                These Terms constitute the entire agreement between you and
                COUNT concerning the Services. If any provision of these Terms
                is deemed invalid, the remainder of the Terms shall continue in
                effect.
              </p>
              <h3 className="text-lg font-medium text-[#101828]">
                20. Questions and Comments
              </h3>
              <p>
                For questions or comments regarding these Terms or the Services,
                please contact COUNT’s support team at{" "}
                <Link
                  className="text-[#101828] underline"
                  to="mailto:support@getcount.com"
                >
                  support@getcount.com
                </Link>{" "}
                .
              </p>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default TermsOfServices;
