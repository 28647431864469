/* eslint-disable react/no-unstable-nested-components */
import React, { useState, useEffect, useRef } from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Logo from '../../images/custom/count-logo.png';

import ManageTeamDropdown from '../../components/ManageTeamDropdown';
import SidebarTab from './SidebarTab';

function PersonSidebar({ sidebarOpen, setSidebarOpen }) {
  const location = useLocation();
  const { pathname } = location;

  const trigger = useRef(null);
  const sidebar = useRef(null);
  const { team } = useSelector((state) => state.Team);

  const storedSidebarExpanded = localStorage.getItem('sidebar-expanded');
  const [sidebarExpanded] = useState(
    storedSidebarExpanded === null ? true : storedSidebarExpanded === 'true',
  );

  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!sidebar.current || !trigger.current) return;
      if (
        !sidebarOpen ||
        sidebar.current.contains(target) ||
        trigger.current.contains(target)
      )
        return;
      setSidebarOpen(false);
    };
    document.addEventListener('click', clickHandler);
    return () => document.removeEventListener('click', clickHandler);
  });

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!sidebarOpen || keyCode !== 27) return;
      setSidebarOpen(false);
    };
    document.addEventListener('keydown', keyHandler);
    return () => document.removeEventListener('keydown', keyHandler);
  });

  useEffect(() => {
    localStorage.setItem('sidebar-expanded', sidebarExpanded);
    if (sidebarExpanded) {
      document.querySelector('body').classList.add('sidebar-expanded');
    } else {
      document.querySelector('body').classList.remove('sidebar-expanded');
    }
  }, [sidebarExpanded]);

  return (
    <div className="min-w-fit">
      {/* Sidebar backdrop (mobile only) */}
      <div
        className={`fixed inset-0 bg-slate-900 bg-opacity-30 z-40 lg:hidden lg:z-auto transition-opacity duration-200 ${
          sidebarOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'
        }`}
        aria-hidden="true"
      />

      {/* Sidebar */}
      <div
        id="sidebar"
        ref={sidebar}
        className={` flex flex-col absolute z-40 left-0 top-0 lg:static lg:left-auto lg:top-auto lg:translate-x-0 h-[100dvh] overflow-y-scroll lg:overflow-y-auto no-scrollbar w-64 lg:w-20 lg:sidebar-expanded:!w-64 2xl:!w-64 shrink-0 bg-[#ffffff] transition-all duration-200 ease-in-out ${
          sidebarOpen ? 'translate-x-0' : '-translate-x-64'
        }`}
      >
        {/* Sidebar header */}

        <div className="h-16 bg-white  inline-flex pl-10   items-center border-b border-slate-200">
          <NavLink end to="/person/invoices?tab=invoices" className="block">
            <img src={Logo} alt="logo" className="h-8" />
          </NavLink>
        </div>

        {/* Links */}
        <div className="flex flex-col justify-between space-y-8 lg:border-r  border-[#D0D5DD80] pt-5 flex-1 pb-4 overflow-auto">
          {/* Pages group */}
          <div>
            <div className=" mt-[17px] w-[185px] mx-auto">
              <ManageTeamDropdown
                options={[{ id: team?.id, name: team?.name }]}
                className="w-full"
                name="name"
                setSelected={() => {}}
                selected={team?.id}
                disabled
              />
            </div>
            <ul className="mt-12 space-y-4 w-full">
              <li className="group  relative">
                <SidebarTab
                  to="/person/invoices?tab=invoices"
                  isActive={pathname.includes('/person/invoices')}
                  Icon={() => (
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 17 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className={`${
                        pathname.includes('/person/invoices')
                          ? ''
                          : 'text-[#667085] group-hover:text-slate-700'
                      }`}
                    >
                      <path
                        d="M16 11.1667L12.4286 11.1667"
                        stroke="#667085"
                        strokeWidth="1.3"
                        strokeLinecap="round"
                        className="stroke-current"
                      />
                      <path
                        d="M13.1428 9.7381L11.7143 11.1667L13.1428 12.5952"
                        stroke="#667085"
                        strokeWidth="1.3"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="stroke-current"
                      />
                      <path
                        d="M15.2857 8.20748V6.30952C15.2857 4.65267 13.9426 3.30952 12.2857 3.30952H4C2.34315 3.30952 1 4.65267 1 6.30952V8.88095C1 10.5378 2.34315 11.881 4 11.881H9.44156"
                        stroke="#667085"
                        strokeWidth="1.3"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="stroke-current"
                      />
                      <circle
                        cx="8.14287"
                        cy="7.59524"
                        r="1.42857"
                        stroke="#667085"
                        strokeWidth="1.3"
                        className="stroke-current"
                      />
                    </svg>
                  )}
                  text="Invoices & Estimates"
                />
              </li>
            </ul>
          </div>
          {/* Footer  */}
          <div className="w-[185px] mx-auto mt-auto">
            <a
              href="https://support.getcount.com/en/"
              target="_blank"
              className="text-xs block w-fit leading-tight cursor-pointer text-indigo-500 mb-2"
              rel="noreferrer"
            >
              Help Center
            </a>
            <div className="flex items-center gap-1">
              <Link
                to="/privacy-policy"
                className="text-xs block w-fit leading-tight cursor-pointer text-indigo-500 mb-5"
              >
                Privacy Policy
              </Link>
              <p className="text-xs block w-fit leading-tight text-slate-500 mb-5">
                &
              </p>
              <Link
                to="/terms"
                className="text-xs block w-fit leading-tight cursor-pointer text-indigo-500 mb-5"
              >
                Terms of Service
              </Link>
            </div>
            <div className="text-xs text-[#667085] leading-tight">
              Copyright {new Date()?.getFullYear()} · COUNT
            </div>
            <div className="text-xs mt-1 text-[#667085] leading-tight">
              All rights reserved
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PersonSidebar;
