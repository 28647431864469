import React, { useState } from "react";
import Onboarding1 from "./onboarding1";
import Onboarding2 from "./Onboarding04";

const Onboardings = () => {
  const [step, setStep] = useState(1);
  return (
    <>
      {step === 1 && <Onboarding1 onNextStep={() => setStep(2)} />}
      {step === 2 && <Onboarding2 />}
    </>
  );
};

export default Onboardings;
