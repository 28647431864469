import moment from 'moment';
import React from 'react';

const PayScheduleTable = ({ paySchedule }) => (
  <div className="w-full overflow-x-auto">
    <table className="w-full table-fixed">
      <thead className="w-full shadow-md rounded-[0.313rem] text-sm leading-[1.125rem] text-slate-600">
        <tr className="w-full py-[1.438rem] px-[1.375rem] h-16">
          <th className="text-left font-medium px-4 w-[15rem] max-w-[15rem]">
            Name
          </th>
          <th className="text-left font-medium  w-[13rem] max-w-[13rem] ">
            Pay Period
          </th>
          <th className="text-left font-medium w-[10rem] max-w-[10rem] ">
            Pay Day
          </th>
          <th className="text-left font-medium w-[7rem] max-w-[7rem] ">
            Due In
          </th>
          <th className="text-left font-medium w-[7rem] max-w-[7rem] ">View</th>
        </tr>
      </thead>
      <tbody className=" text-sm divide-y divide-gray-300 border-b border-gray-300">
        {paySchedule?.length > 0 ? (
          paySchedule?.map((paySch) => (
            <tr
              key={paySch?.id}
              className="w-full px-[1.25rem] h-16 hover:bg-[#a0cd85] hover:bg-opacity-5"
            >
              <td className="text-left text-[14px] px-4">
                <div>
                  <h3 className="text-[16px] capitalize leading-tight m-0">
                    {paySch?.paySchedule?.customName}
                  </h3>
                  <p className="text-sm leading-tight">
                    {paySch?.paySchedule?.frequency}
                  </p>
                </div>
              </td>
              <td className="text-left text-[14px]">
                {moment(paySch?.pay_period?.start_date).format('DD MMM, YYYY')}{' '}
                - {moment(paySch?.pay_period?.end_date).format('DD MMM, YYYY')}
              </td>
              <td className="text-left text-[14px] capitalize">
                {moment(paySch?.check_date).format('DD MMM, YYYY')}
              </td>
              <td
                className={`text-left text-[14px] capitalize-first ${
                  moment(paySch?.payroll_deadline?.split('T')?.[0]).diff(
                    moment(),
                    'days',
                  ) > 0
                    ? 'text-[#40AA00]'
                    : 'text-[#FF4B4B]'
                }`}
              >
                {moment(paySch?.payroll_deadline?.split('T')?.[0]).diff(
                  moment(),
                  'days',
                ) === 0
                  ? 'Due Today'
                  : `${moment(paySch?.payroll_deadline?.split('T')?.[0]).diff(
                      moment(),
                      'days',
                    )} Day(s) ${
                      moment(paySch?.payroll_deadline?.split('T')?.[0]).diff(
                        moment(),
                        'days',
                      ) < 0
                        ? ' (Overdue)'
                        : ''
                    }`}
              </td>
              <td className="text-left text-[14px] capitalize">
                <a
                  href={`https://app.gusto-demo.com/payroll_admin/pay/payrolls/${paySch?.payroll_uuid}/details`}
                  target="_blank"
                  rel="noreferrer"
                  className="text-indigo-500 underline"
                >
                  View Details
                </a>
              </td>
            </tr>
          ))
        ) : (
          <tr className="w-full py-[1.438rem] px-[1.375rem] h-16">
            <td colSpan={4} className="text-center text-[14px] px-4">
              No Data Found
            </td>
          </tr>
        )}
      </tbody>
    </table>
  </div>
);

export default PayScheduleTable;
