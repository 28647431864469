/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState, useRef } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useSelector } from 'react-redux';
import DropdownFull from '../../components/DropdownFull';
import ModalBasic from '../../components/ModalBasic';
import { createAccount, updateAccountById } from '../../API/backend_helper';
import { getContrast } from '../../utils/Utils';
import ColorPicker from '../component/ColorPicker';

const ColorOptions = [
  '#D2D6DB',
  '#AAF0C4',
  '#B2CCFF',
  '#DDD6FE',
  '#FCCEEE',
  '#FFD6AE',
  '#D0F8AA',
  '#A5F0FC',
  '#F7B27A',
];

const AccountsModal = ({
  isOpen,
  setIsOpen,
  accountTypes,
  accountSubTypes,
  loadData,
  account,
  edit,
  currencies,
  subType,
  mainType,
}) => {
  const formikRef = useRef();

  const { team } = useSelector((state) => state.Team);

  const [loading, setLoading] = useState(false);
  const [localAccount, setLocalAccount] = useState(account);
  const [mainTypeId, setMainTypeId] = useState('');
  const [subTypeId, setSubTypeId] = useState('');
  const [selectedColor, setSelectedColor] = useState(
    mainType ? mainType?.color : '#D2D6DB',
  );
  const [renderCurrency, setRenderCurrency] = useState(false);

  useEffect(() => {
    if (subType) {
      formikRef.current.setFieldValue('subTypeId', subType);
      setSubTypeId(subType);
    }
  }, [subType, isOpen]);

  useEffect(() => {
    if (!isOpen) {
      formikRef.current.resetForm();
      setMainTypeId('');
      setSubTypeId('');
      setLocalAccount(null);
      setSelectedColor('#D2D6DB');
      setRenderCurrency(false);
    }
  }, [isOpen]);

  useEffect(() => {
    if (account) {
      setLocalAccount(account);
    } else {
      setLocalAccount(null);
    }
  }, [edit]);

  useEffect(() => {
    if (localAccount) {
      setSubTypeId(localAccount?.subType?.name);
      setMainTypeId(localAccount?.type);
    }
  }, [localAccount, isOpen]);

  useEffect(() => {
    if (isOpen && !account) {
      if (mainType) {
        setMainTypeId(mainType?.name);
      } else {
        setMainTypeId('');
      }
      if (subType) {
        setSubTypeId(subType);
      } else {
        setSubTypeId('');
      }
    }
  }, [isOpen]);

  const handleSubmit = async (values, formikHelper) => {
    setLoading(true);
    const subTypeInt = accountSubTypes?.find(
      (subType) => subType.name === values.subTypeId,
    )?.id;
    const data = { ...values, subTypeId: subTypeInt };
    try {
      const res = !edit
        ? await createAccount(data)
        : await updateAccountById(localAccount.id, data);

      if (res?.id) {
        await loadData(true);
        formikHelper.resetForm();
        setIsOpen(false);
        setSubTypeId('');
        setMainTypeId('');
      }
    } catch (err) {
      console.log('err', err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <ModalBasic
      modalOpen={isOpen}
      setModalOpen={setIsOpen}
      title={edit ? 'Edit Account' : 'Create Account'}
      width="max-w-5xl"
    >
      {/* Modal content */}
      <div className="bg-white w-full overflow-x-auto">
        <Formik
          enableReinitialize
          initialValues={{
            type: mainTypeId || '',
            subTypeId: subTypeId || '',
            name: localAccount?.name || '',
            color: localAccount?.color || mainType?.color || '#D2D6DB',
            currency: localAccount?.currency || team?.currency || '',
            accountNumber: localAccount?.accountNumber || '',
            description: localAccount?.description || '',
          }}
          validationSchema={Yup.object({
            type: Yup.string().required('Please Enter a Type'),
            subTypeId: Yup.string().required('Please Enter a Sub Type'),
            name: Yup.string()
              .required('Please Enter Name')
              .max(100, 'Name must be 100 characters or less'),
            color: Yup.string().required('Please Select Color'),
            currency: renderCurrency
              ? Yup.string().required('Please Select Currency')
              : Yup.string(),
            accountNumber: Yup.string().max(
              100,
              'Account Number must be 100 characters or less',
            ),
            description: Yup.string().max(
              500,
              'Description must be 500 characters or less',
            ),
          })}
          onSubmit={handleSubmit}
          innerRef={formikRef}
        >
          {(validation) => {
            useEffect(() => {
              if (subTypeId) {
                validation.setFieldValue('subTypeId', subTypeId);
              }
            }, [subTypeId]);

            useEffect(() => {
              if (validation?.values && validation?.values?.subTypeId) {
                const isCurrencyRendered = accountSubTypes?.find(
                  (subType) => subType.name === validation?.values.subTypeId,
                )?.anchorEligible;
                setRenderCurrency(isCurrencyRendered);
              }
              if (!validation?.values?.subTypeId) {
                setRenderCurrency(false);
              }
            }, [validation?.values?.subTypeId, validation?.values?.type]);

            useEffect(() => {
              if (!localAccount && validation?.values?.type && accountTypes) {
                const selectedType = accountTypes?.find(
                  (type) => type?.name === validation.values.type,
                );
                setSelectedColor(selectedType?.color);
                validation.setFieldValue('color', selectedType?.color);
              }
            }, [validation?.values?.type]);
            return (
              <Form>
                <div className="flex flex-col items-center justify-center gap-3 sm:gap-9 self-stretch py-[2.25rem] px-12">
                  <div className="sm:flex items-start gap-[1.2rem] self-stretch">
                    <div className="flex flex-col items-start gap-2.5 w-[18.438rem]">
                      <label
                        className="block text-sm text-slate-600 font-medium leading-5"
                        htmlFor="type"
                      >
                        Account Type<span className="text-rose-500">*</span>
                      </label>
                      <DropdownFull
                        options={accountTypes?.map((d) => ({
                          ...d,
                          name: d.name,
                          id: d.name,
                        }))}
                        name="name"
                        setSelected={(name) =>
                          validation.setFieldValue('type', name)
                        }
                        selected={validation.values.type}
                        error={
                          validation.touched.type && validation.errors.type
                        }
                        accounts
                        accountStyle
                        placeholder="Select"
                      />
                    </div>
                    <div className="flex flex-col items-start gap-2.5 w-[18.438rem]">
                      <label
                        className="block text-sm text-slate-600 font-medium leading-5"
                        htmlFor="subTypeId"
                      >
                        Account Sub-Type<span className="text-rose-500">*</span>
                      </label>
                      <DropdownFull
                        options={accountSubTypes
                          ?.filter((d) => {
                            const selectedType = accountTypes?.find(
                              (type) => type?.name === validation.values.type,
                            );
                            return d.type === selectedType?.name;
                          })
                          .map((d) => ({
                            ...d,
                            subTypeId: d.name,
                            id: d.name,
                          }))}
                        name="subTypeId"
                        setSelected={(option) => {
                          validation.setFieldValue('subTypeId', option);
                        }}
                        selected={validation.values.subTypeId}
                        error={
                          validation.touched.subTypeId &&
                          validation.errors.subTypeId
                        }
                        accounts
                        accountStyle
                        subType
                        placeholder="Select"
                        disabled={!validation.values.type}
                      />
                    </div>
                    <div className="flex flex-col items-start gap-2.5 w-[18.438rem]">
                      <label
                        className="block text-sm text-slate-600 font-medium leading-5"
                        htmlFor="name"
                      >
                        Account Name<span className="text-rose-500">*</span>
                      </label>
                      <input
                        id="name"
                        type="text"
                        placeholder="Name"
                        name="name"
                        className={`btn text-base h-12 w-full rounded-[0.313rem] border border-[#D0D5DD] text-slate-600leading-6 font-normal px-4 py-3.5 bg-white
                                    placeholder:text-base placeholder:text-slate-600 placeholder:text-opacity-80 placeholder:leading-6 placeholder:font-normal ${
                                      validation.touched.name &&
                                      validation.errors.name &&
                                      'border-rose-500'
                                    }`}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.name || ''}
                      />
                      {validation.touched.name && validation.errors.name ? (
                        <div className="text-xs mt-1 text-rose-500">
                          {validation.errors.name}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="flex flex-row w-full items-center justify-start">
                    <div className="flex flex-col gap-2.5 items-start">
                      <label
                        className="block text-sm text-slate-600 font-medium leading-5"
                        htmlFor="color"
                      >
                        Select Display Color
                        <span className="text-rose-500">*</span>
                      </label>
                      <div className="relative h-[7.375rem] w-full px-4 sm:px-0 sm:w-[28.337rem] py-3 flex flex-col justify-center items-center gap-2.5 self-stretch rounded-[10px] border border-dashed border-indigo-500 shadow-md">
                        <div className="flex flex-col justify-center items-center gap-2.5">
                          <div
                            className="w-[1.875rem] h-[1.875rem] rounded-full flex items-center justify-center text-black"
                            style={{
                              backgroundColor: selectedColor,
                              border: !selectedColor
                                ? '1px solid black'
                                : 'none',
                            }}
                          >
                            <div
                              className={`${getContrast(selectedColor, 'text-black', 'text-white')} text-xs uppercase font-normal`}
                            >
                              {validation.values.name
                                ? validation.values.name
                                    .split(' ')
                                    .slice(0, 2)
                                    .map((word) => word.charAt(0))
                                    .join('')
                                : ''}
                            </div>
                          </div>
                          <p className="text-slate-600 text-sm">
                            Click to select the color
                          </p>
                          <ColorPicker
                            ColorOptions={ColorOptions}
                            selectedColor={selectedColor}
                            setSelectedColor={setSelectedColor}
                          />
                          {validation.touched.color &&
                          validation.errors.color ? (
                            <span className="text-xs absolute -bottom-9 text-rose-500">
                              {validation.errors.color}
                            </span>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex items-start self-stretch gap-[1.2rem]">
                    <div className="flex flex-col items-start gap-2.5 w-[28.337rem]">
                      <label
                        className="block text-sm text-slate-600 font-medium leading-5"
                        htmlFor="accountNumber"
                      >
                        Account Number
                      </label>
                      <input
                        id="accountNumber"
                        type="text"
                        placeholder="Optional"
                        name="accountNumber"
                        className={`btn text-base h-12 w-full rounded-[0.313rem] border border-[#D0D5DD] text-slate-600 leading-6 font-normal px-4 py-3.5 bg-white
                                    placeholder:text-base placeholder:text-slate-600 placeholder:text-opacity-80 placeholder:leading-6 placeholder:font-normal ${
                                      validation.touched.accountNumber &&
                                      validation.errors.accountNumber &&
                                      'border-rose-500'
                                    }`}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.accountNumber || ''}
                      />
                      {validation.touched.accountNumber &&
                      validation.errors.accountNumber ? (
                        <div className="text-xs mt-1 text-rose-500">
                          {validation.errors.accountNumber}
                        </div>
                      ) : null}
                    </div>
                    <div className="flex flex-col items-start gap-2.5 w-[28.337rem]">
                      <label
                        className="block text-sm text-slate-600 font-medium leading-5"
                        htmlFor="description"
                      >
                        Description
                      </label>
                      <input
                        id="description"
                        type="text"
                        placeholder="Optional"
                        name="description"
                        className={`btn text-base h-12 w-full rounded-[0.313rem] border border-[#D0D5DD] text-slate-600 leading-6 font-normal px-4 py-3.5 bg-white
                                    placeholder:text-base placeholder:text-slate-600 placeholder:text-opacity-80 placeholder:leading-6 placeholder:font-normal ${
                                      validation.touched.description &&
                                      validation.errors.description &&
                                      'border-rose-300'
                                    }`}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.description || ''}
                      />
                      {validation.touched.description &&
                      validation.errors.description ? (
                        <div className="text-xs mt-1 text-rose-500">
                          {validation.errors.description}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="flex items-start self-stretch gap-[1.2rem]">
                    <div className="flex flex-col items-start gap-2.5 w-[28.337rem]">
                      {renderCurrency && (
                        <>
                          <label
                            className="block text-sm text-slate-600 font-medium leading-5"
                            htmlFor="currency"
                          >
                            Currency<span className="text-rose-500">*</span>
                          </label>
                          <DropdownFull
                            options={currencies?.map((c) => ({
                              ...c,
                              id: c?.isoCode,
                              name: `${c?.symbol} ${c?.name}`,
                            }))}
                            name="name"
                            setSelected={(id) =>
                              validation.setFieldValue('currency', id)
                            }
                            selected={validation.values.currency}
                            error={
                              validation.touched.currency &&
                              validation.errors.currency
                            }
                            accountStyle
                          />
                          {validation.touched.currency &&
                          validation.errors.currency ? (
                            <div className="text-xs mt-1 text-rose-500">
                              {validation.errors.currency}
                            </div>
                          ) : null}
                        </>
                      )}
                    </div>
                  </div>
                </div>
                {/* Modal footer */}
                <div className="border-t border-[#D0D5DD] bg-white flex py-[2rem] px-12 justify-end items-center gap-4 self-stretch w-full ">
                  <div className="flex flex-wrap justify-end space-x-2">
                    <button
                      type="button"
                      className="h-11 w-[5.625rem] py-2.5 px-[1.875rem] flex items-center justify-center rounded-[0.313rem] bg-white border border-slate-600 text-slate-600 text-base leading-6"
                      onClick={(e) => {
                        e.stopPropagation();
                        setIsOpen(false);
                      }}
                    >
                      {edit ? 'Discard' : 'Close'}
                    </button>
                    <button
                      type="submit"
                      className="h-11 w-[5.625rem] py-2.5 px-[1.875rem] flex items-center justify-center rounded-[0.313rem] bg-indigo-500 text-white font-normal text-base leading-6 shadow-sm
                      disabled:bg-indigo-400    disabled:cursor-not-allowed"
                      disabled={loading}
                    >
                      {loading && (
                        <svg
                          className="animate-spin w-4 h-4 fill-current shrink-0 mr-2"
                          viewBox="0 0 16 16"
                        >
                          <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z" />
                        </svg>
                      )}
                      {edit ? 'Save' : 'Create'}
                    </button>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </ModalBasic>
  );
};

export default AccountsModal;
