import React, { useState, useEffect, useRef, useMemo } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Tooltip from 'components/Tooltip';
import ModalBasic from '../../components/ModalBasic';
import DropdownFull from '../../components/DropdownFull';
import TextInputDash from '../../components/TextInputDash';
import DatePickerDash from '../../components/DatePickerDash';
import { createTask } from '../../API/backend_helper';
import { Loader } from '../../components/Svg';

const AddTaskModal = ({
  modalOpen,
  setModalOpen,
  loadData = null,
  transactions = false,
  bills = false,
  selectedBill = null,
  selectedOption = '',
  setSelectedOption = null,
  selectedTransaction = null,
  projects = [],
}) => {
  const formikRef = useRef(null);
  const { team } = useSelector((state) => state.Team);

  const [noClick, setNoClick] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const taskTypes = transactions
    ? [
        { name: 'Review Transaction', id: 'reviewTransaction' },
        { name: 'Set Transaction Vendor', id: 'transactionVendor' },
        { name: 'Set Transaction Category', id: 'transactionCategory' },
        { name: 'Upload Transaction Attachment', id: 'transactionAttachment' },
      ]
    : bills
      ? [{ name: 'Pay Bill', id: 'payBill' }]
      : [{ name: 'To-Do', id: 'toDo' }];

  const options = useMemo(
    () => ({
      mode: 'single',
      static: false,
      monthSelectorType: 'static',
      allowInput: true,
      dateFormat:
        team?.defaults?.dateFormat === 'dd/mm/yyyy' ? 'd/m/Y' : 'm/d/Y',
      position: 'bottom',
      prevArrow:
        '<svg class="fill-current" width="7" height="11" viewBox="0 0 7 11"><path d="M5.4 10.8l1.4-1.4-4-4 4-4L5.4 0 0 5.4z" /></svg>',
      nextArrow:
        '<svg class="fill-current" width="7" height="11" viewBox="0 0 7 11"><path d="M1.4 10.8L0 9.4l4-4-4-4L1.4 0l5.4 5.4z" /></svg>',
      onOpen() {
        setNoClick(true);
      },
      onClose() {
        setNoClick(false);
      },
    }),
    [team],
  );

  useEffect(() => {
    if (bills) {
      formikRef.current.setFieldValue('taskType', 'payBill');
    }
  }, [bills]);

  useEffect(() => {
    if (!modalOpen) {
      formikRef.current.resetForm();
    }
  }, [modalOpen]);

  const handleSubmit = async (values) => {
    setIsLoading(true);
    const formattedDate = moment(values.deadlineDate).format('yyyy-MM-DD');
    const taskType = values.taskType || selectedOption;
    const transactionId = selectedTransaction ? selectedTransaction.id : null;
    const billId = selectedBill ? selectedBill.id : null;
    if (!values.assigneeId) {
      values.assigneeId = null;
    }
    try {
      await createTask({
        ...values,
        deadlineDate: formattedDate,
        taskType,
        transactionId,
        billId,
      });
      toast.success('Task created successfully');
      if (loadData) {
        await loadData({});
      }
      setModalOpen(false);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <ModalBasic
      title="Add Task"
      modalOpen={modalOpen}
      setModalOpen={setModalOpen}
      noClick={noClick}
      width="!w-[800px]"
    >
      <Formik
        enableReinitialize={false}
        initialValues={{
          taskName: '',
          taskType: transactions ? selectedOption : bills ? 'payBill' : 'toDo',
          description: '',
          assigneeId: null,
          taskStatus: 'notStarted',
          deadlineDate: '',
          priority: 'low',
          projectId: null,
        }}
        validationSchema={Yup.object({
          taskName: Yup.string()
            .required('Task Title is required')
            .max(
              255,
              'Task title must be less than or equal to 255 characters',
            ),
          taskType: Yup.string(),
          description: Yup.string(),
          taskStatus: Yup.string(),
          deadlineDate: Yup.string(),
          priority: Yup.string().required('Priority is required'),
        })}
        onSubmit={handleSubmit}
        innerRef={formikRef}
      >
        {(validation) => (
          <Form>
            <div className="bg-white border-y border-[#D0D5DD]">
              <div className="w-full">
                <div className=" gap-6 grid grid-cols-1 md:grid-cols-2 py-6 px-10 w-full">
                  {transactions && (
                    <div className="flex flex-col gap-2.5 w-full">
                      <label className="text-slate-600 font-medium text-[14px] leading-5">
                        Task Type <span className="text-red-600">*</span>
                      </label>
                      <DropdownFull
                        options={taskTypes}
                        placeholder="Select Task Type"
                        name="name"
                        selected={validation.values.taskType || selectedOption}
                        setSelected={(value) => {
                          validation.setFieldValue('taskType', value);
                          setSelectedOption(value);
                        }}
                        height="h-10"
                        scrollIntoView={false}
                      />
                    </div>
                  )}
                  <div className="flex flex-col gap-2.5 w-full">
                    <label className="text-slate-600 font-medium text-[14px] leading-5">
                      Task Title <span className="text-red-600">*</span>
                    </label>
                    <TextInputDash
                      placeholder="Enter Task Title"
                      value={validation.values.taskName}
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      paddingBottom="pb-0"
                      name="taskName"
                      inputClass="!h-10 !mb-0"
                    />
                    {validation.touched.taskName &&
                    validation.errors.taskName ? (
                      <span className="text-xs text-rose-500 h-[14px] -mt-1">
                        {validation.errors.taskName}
                      </span>
                    ) : null}
                  </div>
                  <div className="flex flex-col gap-2.5 w-full">
                    <label className="text-slate-600 font-medium text-[14px] leading-5">
                      Assignee
                    </label>
                    <DropdownFull
                      options={team?.users}
                      selected={validation.values.assigneeId}
                      setSelected={(value) =>
                        validation.setFieldValue('assigneeId', value)
                      }
                      users
                      height="h-10"
                      scrollIntoView={false}
                    />
                    {validation.touched.assigneeId &&
                    validation.errors.assigneeId ? (
                      <span className="text-xs text-rose-500 h-[14px] -mt-1">
                        {validation.errors.assigneeId}
                      </span>
                    ) : null}
                  </div>
                  <div className="flex flex-col gap-2.5 w-full">
                    <label className="text-slate-600 font-medium text-[14px] leading-5">
                      Task Priority <span className="text-red-600">*</span>
                    </label>
                    <DropdownFull
                      options={[
                        { name: 'High Priority', id: 'high' },
                        { name: 'Medium Priority', id: 'medium' },
                        { name: 'Low Priority', id: 'low' },
                      ]}
                      placeholder="Select Priority"
                      name="name"
                      selected={validation.values.priority}
                      setSelected={(value) =>
                        validation.setFieldValue('priority', value)
                      }
                      height="h-10"
                      scrollIntoView={false}
                      priorityDot
                    />
                  </div>
                  <div className="flex flex-col gap-2.5 w-full">
                    <label className="text-slate-600 font-medium text-[14px] leading-5 flex gap-2 items-center">
                      Project
                    </label>
                    <DropdownFull
                      options={projects}
                      selected={validation.values.projectId}
                      setSelected={(value) =>
                        validation.setFieldValue('projectId', value)
                      }
                      name="name"
                      height="h-10"
                      scrollIntoView={false}
                    />
                    {validation.touched.projectId &&
                    validation.errors.projectId ? (
                      <span className="text-xs text-rose-500 h-[14px] -mt-1">
                        {validation.errors.projectId}
                      </span>
                    ) : null}
                  </div>
                  <div className="flex flex-col gap-2.5 w-full">
                    <p className="text-slate-600 text-[14px] font-medium leading-5">
                      Task Status <span className="text-red-500">*</span>
                    </p>
                    <DropdownFull
                      options={[
                        { name: 'Not Started', id: 'notStarted' },
                        { name: 'In Progress', id: 'inProgress' },
                        { name: 'Review', id: 'review' },
                        { name: 'Completed', id: 'completed' },
                      ]}
                      placeholder="Select Status"
                      name="name"
                      selected={validation.values.taskStatus}
                      setSelected={(value) =>
                        validation.setFieldValue('taskStatus', value)
                      }
                      scrollIntoView={false}
                      height="h-10"
                    />
                  </div>
                  <div className="flex flex-col gap-2.5 w-full">
                    <label className="text-slate-600 font-medium text-[14px] leading-5 whitespace-nowrap">
                      Task Deadline
                    </label>
                    <DatePickerDash
                      required
                      validation={validation}
                      placeholder="Select Date"
                      value={
                        validation.values.deadlineDate
                          ? validation.values.deadlineDate
                          : null
                      }
                      options={options}
                      onChange={(selectedDates) => {
                        validation.setFieldValue(
                          'deadlineDate',
                          selectedDates[0],
                        );
                      }}
                      name="deadlineDate"
                      id="deadlineDate"
                      error={
                        validation.touched.deadlineDate &&
                        validation.errors.deadlineDate
                          ? validation.errors.deadlineDate
                          : ''
                      }
                      width="w-full"
                      inputClass="!h-10"
                    />
                  </div>
                  <div className="col-span-full flex flex-col gap-2.5 w-full">
                    <label className="text-slate-600 font-medium text-[14px] leading-5">
                      Task Description
                    </label>
                    <TextInputDash
                      placeholder="Details about the task"
                      value={validation.values.description}
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      name="description"
                      paddingBottom="pb-0"
                      type="textarea"
                    />
                    {validation.touched.description &&
                    validation.errors.description ? (
                      <span className="text-xs text-rose-500 h-[14px] -mt-3">
                        {validation.errors.description}
                      </span>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
            <div className="py-8 px-10 flex w-full justify-end items-center gap-4">
              <button
                type="button"
                className="h-11 w-[90px] flex justify-center items-center rounded-[5px] bg-white text-slate-600 border border-slate-600"
                onClick={() => setModalOpen(false)}
              >
                Close
              </button>
              <button
                className="h-11 w-[90px] flex justify-center items-center rounded-[5px] bg-indigo-500 text-white border border-indigo-500"
                type="submit"
                disabled={isLoading}
              >
                {isLoading ? (
                  <Loader color="#FFFFFF" width="w-4" height="h-4" />
                ) : (
                  'Add'
                )}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </ModalBasic>
  );
};

export default AddTaskModal;
