export const FavoriteIcon = () => {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.5 1.66406L13.075 6.88073L18.8334 7.7224L14.6667 11.7807L15.65 17.5141L10.5 14.8057L5.35002 17.5141L6.33335 11.7807L2.16669 7.7224L7.92502 6.88073L10.5 1.66406Z"
        fill="#E48642"
        stroke="#E48642"
        stroke-width="1.11816"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export const EmptyFavoriteIcon = () => {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.75 1.66406L13.325 6.88073L19.0834 7.7224L14.9167 11.7807L15.9 17.5141L10.75 14.8057L5.60002 17.5141L6.58335 11.7807L2.41669 7.7224L8.17502 6.88073L10.75 1.66406Z"
        stroke="#667085"
        stroke-width="1.11816"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export const FavoriteBookImg = () => {
  return (
    <svg
      width="193"
      height="127"
      viewBox="0 0 193 127"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_2815_62382)">
        <path
          d="M79.5581 143.116C117.974 143.116 149.116 111.974 149.116 73.5581C149.116 35.1422 117.974 4 79.5581 4C41.1422 4 10 35.1422 10 73.5581C10 111.974 41.1422 143.116 79.5581 143.116Z"
          fill="#F9EAE0"
        />
        <path
          d="M83.9758 232.027C82.9744 232.026 81.9879 231.785 81.0994 231.323C80.2108 230.861 79.4464 230.192 78.8704 229.373L70.875 217.933L265.236 95.2539L274.472 101.32C275.339 101.89 276.05 102.668 276.54 103.584C277.029 104.499 277.282 105.522 277.274 106.56C277.267 107.598 277 108.617 276.498 109.525C275.995 110.434 275.274 111.202 274.398 111.759L87.3114 231.053C86.3151 231.689 85.1577 232.027 83.9758 232.027Z"
          fill="white"
        />
        <path
          d="M265.224 96.1701L274.045 101.965C274.81 102.457 275.436 103.136 275.866 103.938C276.295 104.739 276.513 105.637 276.499 106.546C276.495 107.456 276.262 108.351 275.822 109.147C275.382 109.944 274.749 110.617 273.98 111.105L86.8974 230.403C85.7127 231.157 84.2829 231.425 82.9056 231.151C81.5283 230.877 80.3096 230.082 79.5034 228.933L71.9669 218.154L265.224 96.1701ZM265.24 94.3379L69.7756 217.709L78.2391 229.813C79.273 231.289 80.8366 232.309 82.6039 232.66C84.3712 233.012 86.2061 232.668 87.7261 231.7L274.81 112.406C275.794 111.779 276.605 110.916 277.17 109.895C277.734 108.874 278.034 107.728 278.043 106.562C278.051 105.395 277.767 104.245 277.217 103.217C276.667 102.188 275.868 101.313 274.893 100.673L265.242 94.332L265.24 94.3379Z"
          fill="#081F2D"
        />
        <path
          d="M154.192 16.615C158.87 13.5988 164.461 12.3265 169.984 13.0212C175.507 13.716 180.608 16.3335 184.394 20.4144L190.46 26.954L41.2555 89.4296L154.192 16.615Z"
          fill="white"
        />
        <path
          d="M167.019 13.6069C170.174 13.61 173.295 14.2623 176.187 15.5231C179.08 16.784 181.682 18.6265 183.832 20.936L189.148 26.6669L49.0143 85.3455L154.609 17.2639C158.307 14.8752 162.616 13.6053 167.019 13.6069ZM167.019 12.0636C162.321 12.0624 157.722 13.4179 153.776 15.9672L33.4966 93.5158L191.78 27.2365L184.963 19.8874C182.673 17.4161 179.897 15.4449 176.809 14.0976C173.721 12.7504 170.388 12.0562 167.019 12.0586V12.0636Z"
          fill="#081F2D"
        />
        <path
          d="M69.7757 217.707L62.0593 206.674C61.5081 205.886 61.1255 204.992 60.9353 204.049C60.7452 203.107 60.7517 202.135 60.9544 201.195C61.1571 200.255 61.5517 199.366 62.1134 198.586C62.6751 197.805 63.3919 197.149 64.2189 196.658L237.623 93.7462C240.636 91.9582 243.997 90.8382 247.48 90.4619C250.963 90.0855 254.486 90.4617 257.811 91.565L263.405 93.4206C264.661 93.8373 265.772 94.6019 266.611 95.6256C267.449 96.6492 267.98 97.8899 268.141 99.2032C268.302 100.517 268.087 101.849 267.521 103.045C266.955 104.241 266.061 105.251 264.943 105.959L83.975 220.56C81.6957 222.002 78.9481 222.511 76.3036 221.979C73.6591 221.448 71.321 219.918 69.7757 217.707Z"
          fill="#78BD4F"
        />
        <path
          d="M64.2189 196.662L24.2111 127.066C22.5971 124.259 21.5567 121.159 21.1501 117.946C20.7436 114.733 20.979 111.472 21.8428 108.351C22.7066 105.23 24.1815 102.311 26.182 99.7645C28.1825 97.2179 30.6688 95.0939 33.4966 93.5157L161.511 22.0636C166.389 19.3407 172.052 18.3694 177.559 19.311C183.066 20.2527 188.084 23.0506 191.78 27.2397C209.88 47.7454 222.49 72.5014 228.435 99.1982L64.2189 196.662Z"
          fill="#78BD4F"
        />
        <path
          d="M212.815 108.475C203.98 82.9641 190.406 59.3517 172.807 38.8789"
          stroke="#081F2D"
          stroke-width="1.54244"
          stroke-miterlimit="10"
        />
        <path
          d="M203.037 114.271C194.201 88.7612 180.628 65.1489 163.029 44.6758"
          stroke="#081F2D"
          stroke-width="1.54244"
          stroke-miterlimit="10"
        />
        <path
          d="M194.888 119.115C186.051 93.6051 172.478 69.9929 154.88 49.5195"
          stroke="#081F2D"
          stroke-width="1.54244"
          stroke-miterlimit="10"
        />
        <path
          d="M127.002 159.401C118.166 133.891 104.592 110.278 86.9933 89.8047"
          stroke="#081F2D"
          stroke-width="1.54244"
          stroke-miterlimit="10"
        />
        <path
          d="M64.2189 196.66L228.435 99.1992"
          stroke="#081F2D"
          stroke-width="1.54244"
          stroke-miterlimit="10"
        />
      </g>
      <defs>
        <clipPath id="clip0_2815_62382">
          <rect width="289" height="254.02" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
