import React from 'react';
import moment from 'moment';

const PayrollStep3 = ({ setActiveStep, payroll, onSubmit, loading, gusto }) => {
  const getCompanyContribution = (taxes) => {
    let total = 0;
    taxes?.forEach((tax) => {
      if (tax?.employer) {
        total += parseFloat(tax?.amount);
      }
    });
    return total;
  };

  const getCompanyBenifits = (benifits) => {
    let total = 0;
    benifits?.forEach((benifit) => {
      if (!benifit?.imputed) {
        total += parseFloat(benifit?.company_contribution);
      }
    });
    return total;
  };

  const getReimbursements = (reimbursements) => {
    let total = 0;
    reimbursements?.forEach((reimbursement) => {
      if (reimbursement?.name === 'Reimbursement') {
        total += parseFloat(reimbursement?.amount);
      }
    });
    return total;
  };

  return (
    <div className="flex flex-col gap-10">
      <div className="w-full 2xl:w-2/3 p-4 flex flex-col rounded-lg border-2 border-[#D0D5DD] gap-2">
        <p className="text-lg font-semibold">Total Payroll Cost</p>
        <div className="flex gap-6 items-start">
          <div className="w-[40%] 2xl:w-1/3 bg-[#D0D5DD] bg-opacity-20 rounded-lg p-6 flex flex-col gap-3">
            <p className="text-[#333333] font-semibold text-2xl">
              $
              {parseFloat(
                parseFloat(payroll?.totals?.gross_pay) +
                  parseFloat(payroll?.totals?.employer_taxes) +
                  parseFloat(payroll?.totals?.benefits) +
                  parseFloat(payroll?.totals?.reimbursements),
              )?.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </p>
            <div className="w-full h-px bg-[#D0D5DD]" />
            <div className="flex justify-between items-center">
              <p className="max-w-[60%]">Withdrawal amount</p>
              <p className="text-[#333333] font-semibold">
                ${' '}
                {parseFloat(payroll?.totals?.company_debit)?.toLocaleString(
                  undefined,
                  {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  },
                )}
              </p>
            </div>
            {gusto?.companyAccounts?.[0]?.id && (
              <div className="flex flex-col">
                <p className="text-[#333333] font-medium">
                  {gusto?.companyAccounts?.[0]?.name}
                  <div>
                    ({gusto?.companyAccounts?.[0]?.accountType}{' '}
                    {gusto?.companyAccounts?.[0]?.mask})
                  </div>
                </p>
              </div>
            )}
          </div>
          <div className="flex flex-col gap-4 w-[40%]">
            <div className="flex flex-col">
              <p className="">
                Pay Period:{' '}
                <span className="text-[#333333] font-medium">
                  {moment(payroll?.pay_period?.start_date).format(
                    'DD MMM, YYYY',
                  )}{' '}
                  to{' '}
                  {moment(payroll?.pay_period?.end_date).format('DD MMM, YYYY')}
                </span>
              </p>
            </div>
            <div className="flex flex-col">
              <p className="">
                Withdrawal date:{' '}
                <span className="text-[#333333] font-medium">
                  {moment(payroll?.payroll_deadline?.split('T')?.[0]).format(
                    'DD MMM, YYYY',
                  )}
                </span>
              </p>
            </div>
            <div className="flex flex-col">
              <p className="">
                Employee payday:{' '}
                <span className="text-[#333333] font-medium">
                  {moment(payroll?.check_date).format('DD MMM, YYYY')}
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* Table */}

      <div className="bg-white flex-1">
        {/* Table */}
        <div className="overflow-x-auto h-full scrollbar">
          <table className="table-fixed w-full dark:text-slate-300">
            {/* Table header */}
            <thead className="w-full shadow-md rounded-[0.313rem] text-sm leading-[1.125rem] text-slate-600">
              <tr className="w-full py-[1.438rem] px-[1.375rem] h-16">
                <th className="px-4 w-[15rem] max-w-[15rem]">
                  <div className="text-left w-[6.25rem] font-medium">
                    Employees
                  </div>
                </th>
                <th className="w-[8rem] max-w-[8rem]">
                  <div className="text-left w-[8.75rem] font-medium">
                    Reimbursements
                  </div>
                </th>
                <th className="w-[7rem] max-w-[7rem]">
                  <div className="text-left font-medium"> Gross pay</div>
                </th>
                <th className="w-[7rem] max-w-[7rem]">
                  <div className="text-left font-medium">Company taxes</div>
                </th>
                <th className="w-[8rem] max-w-[8rem]">
                  <div className="text-left font-medium"> Company benefits</div>
                </th>
                <th className="w-[7rem] max-w-[7rem]">
                  <div className="text-left font-medium"> Subtotal</div>
                </th>
              </tr>
            </thead>
            {/* Table body */}
            {payroll?.employee_compensations?.length > 0 && (
              <tbody className="text-sm divide-y divide-gray-300 border-b border-gray-300">
                {payroll?.employee_compensations?.map((comp, index) => (
                  <tr
                    key={comp?.employee_uuid}
                    className={`w-full pl-[1.375rem]  pr-[1.125rem] h-16 hover:bg-[#a0cd85] hover:bg-opacity-5`}
                  >
                    <td className="px-4">
                      <div className=" break-words text-[0.938rem] font-medium leading-6 text-left text-slate-600">
                        {comp?.employee?.firstName} {comp?.employee?.lastName}
                      </div>
                    </td>
                    <td className="pr-4">
                      <div className=" text-left text-slate-600 text-[0.938rem] leading-6 break-words">
                        $
                        {getReimbursements(
                          comp?.fixed_compensations,
                        )?.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </div>
                    </td>
                    <td className="whitespace-nowrap">
                      <div className="text-left">
                        <p className="text-slate-600 text-[0.938rem] leading-6">
                          $
                          {parseFloat(comp?.gross_pay)?.toLocaleString(
                            undefined,
                            {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            },
                          )}
                        </p>
                      </div>
                    </td>
                    <td className="pr-4">
                      <div className="text-left ">
                        $
                        {getCompanyContribution(comp?.taxes)?.toLocaleString(
                          undefined,
                          {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          },
                        )}
                      </div>
                    </td>
                    <td className="pr-4">
                      <div className="text-left ">
                        $
                        {getCompanyBenifits(comp?.benefits)?.toLocaleString(
                          undefined,
                          {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          },
                        )}
                      </div>
                    </td>
                    <td className="pr-4">
                      <div className="text-left ">
                        $
                        {parseFloat(
                          parseFloat(comp?.gross_pay) +
                            getCompanyContribution(comp?.taxes) +
                            getCompanyBenifits(comp?.benefits) +
                            getReimbursements(comp?.fixed_compensations),
                        )?.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </div>
                    </td>
                  </tr>
                ))}
                <tr
                  className={`w-full pl-[1.375rem]  pr-[1.125rem] h-20 hover:bg-[#a0cd85] hover:bg-opacity-5`}
                >
                  <td className="px-4">
                    <div className=" break-words text-[0.938rem] font-bold leading-6 text-left text-slate-600">
                      TOTAL
                    </div>
                  </td>
                  <td className="pr-4">
                    <div className="font-bold text-left text-slate-600 text-[0.938rem] leading-6 break-words">
                      $
                      {parseFloat(
                        payroll?.totals?.reimbursements,
                      )?.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </div>
                  </td>
                  <td className="whitespace-nowrap">
                    <div className="text-left">
                      <p className="font-bold text-slate-600 text-[0.938rem] leading-6">
                        $
                        {parseFloat(payroll?.totals?.gross_pay)?.toLocaleString(
                          undefined,
                          {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          },
                        )}
                      </p>
                    </div>
                  </td>
                  <td className="pr-4">
                    <div className="font-bold text-left ">
                      $
                      {parseFloat(
                        payroll?.totals?.employer_taxes,
                      )?.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </div>
                  </td>
                  <td className="pr-4">
                    <div className="font-bold text-left ">
                      $
                      {parseFloat(payroll?.totals?.benefits)?.toLocaleString(
                        undefined,
                        {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        },
                      )}
                    </div>
                  </td>
                  <td className="pr-4">
                    <div className="text-left font-bold">
                      $
                      {parseFloat(
                        parseFloat(payroll?.totals?.gross_pay) +
                          parseFloat(payroll?.totals?.employer_taxes) +
                          parseFloat(payroll?.totals?.benefits),
                      )?.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </div>
                  </td>
                </tr>
              </tbody>
            )}
          </table>
        </div>
      </div>
      <div className="w-full flex justify-between">
        <button
          className="bg-white h-10 border border-indigo-500 rounded-[0.313rem] px-[1.125rem] py-2.5 shadow text-indigo-500 text-base leading-6 flex items-center justify-center gap-2 font-normal"
          onClick={() => {
            setActiveStep(2);
          }}
          type="button"
        >
          Back
        </button>
        <button
          className="bg-indigo-500 h-10 border border-indigo-500 rounded-[0.313rem] px-[1.125rem] py-2.5 shadow text-white text-base leading-6 flex items-center justify-center gap-2 font-normal
          disabled:!bg-indigo-300 disabled:text-white disabled:cursor-not-allowed"
          onClick={onSubmit}
          disabled={!payroll || loading}
          type="button"
        >
          {loading && (
            <svg
              className="animate-spin w-4 h-4 fill-current shrink-0 mr-2"
              viewBox="0 0 16 16"
            >
              <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z" />
            </svg>
          )}
          Submit Payroll
        </button>
      </div>
    </div>
  );
};

export default PayrollStep3;
