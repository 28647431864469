import React from 'react';

const FirmIcon = ({ className = '' }) => (
  <svg
    width="40"
    height="41"
    viewBox="0 0 40 41"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M16.5882 35.977C16.5882 35.6241 16.353 35.3888 16 35.3888H3.52942C2.88237 35.3888 2.35295 34.8594 2.35295 34.2123V17.4476C2.35295 17.0947 2.11766 16.8594 1.76472 16.8594C1.41178 16.8594 1.17648 17.0947 1.17648 17.4476V34.2711C1.17648 35.5653 2.23531 36.6241 3.52942 36.6241H16C16.2941 36.5653 16.5882 36.33 16.5882 35.977Z"
      fill="#667085"
      stroke="#667085"
      strokeWidth="0.25"
    />
    <path
      d="M38.8235 16.562C38.8235 15.2679 37.7647 14.2091 36.4706 14.2091H28.7647V11.2679C28.7647 11.1503 28.7059 10.9738 28.5882 10.8562C28.4706 10.7385 19.7059 2.03263 19.5294 1.85616C19.4706 1.73851 19.2941 1.67969 19.1765 1.67969H3.52942C2.23531 1.67969 1.17648 2.73851 1.17648 4.03263V14.2091C1.17648 14.562 1.41178 14.7973 1.76472 14.7973C2.11766 14.7973 2.35295 14.562 2.35295 14.2091V4.03263C2.35295 3.38557 2.88237 2.85616 3.52942 2.85616H18.5882V9.50322C18.5882 10.7973 19.6471 11.8562 20.9412 11.8562H27.6471V14.2091H20.4706C19.1765 14.2091 18.1177 15.2679 18.1177 16.562V36.9738C18.1177 38.2679 19.1765 39.3267 20.4706 39.3267H36.4706C37.7647 39.3267 38.8235 38.2679 38.8235 36.9738V16.562ZM20.9412 10.6209C20.2941 10.6209 19.7647 10.0915 19.7647 9.44439V3.67969L26.8235 10.6797H20.9412V10.6209ZM37.6471 36.9738C37.6471 37.6209 37.1177 38.1503 36.4706 38.1503H20.4706C19.8235 38.1503 19.2941 37.6209 19.2941 36.9738V16.562C19.2941 15.915 19.8235 15.3856 20.4706 15.3856H36.4706C37.1177 15.3856 37.6471 15.915 37.6471 16.562V36.9738Z"
      fill="#667085"
      stroke="#667085"
      strokeWidth="0.25"
    />
    <path
      d="M34.4706 17.3281H22.5294C21.8824 17.3281 21.2941 17.8575 21.2941 18.5634V21.034C21.2941 21.6811 21.8235 22.2693 22.5294 22.2693H34.4706C35.1177 22.2693 35.7059 21.7399 35.7059 21.034V18.5634C35.7059 17.8575 35.1177 17.3281 34.4706 17.3281ZM34.4706 21.034H22.5294C22.5294 21.034 22.4706 21.034 22.4706 20.9752L22.5294 18.5046L34.5294 18.5634L34.4706 21.034Z"
      fill="#667085"
      stroke="#667085"
      strokeWidth="0.25"
    />
    <path
      d="M35.1176 23.9141H32.5882C32.2353 23.9141 32 24.1494 32 24.5023V26.6199C32 26.9729 32.2353 27.2082 32.5882 27.2082H35.1176C35.4706 27.2082 35.7059 26.9729 35.7059 26.6199V24.5023C35.7059 24.2082 35.4118 23.9141 35.1176 23.9141ZM34.5294 26.0317H33.1765V25.0905H34.5294V26.0317Z"
      fill="#667085"
      stroke="#667085"
      strokeWidth="0.25"
    />
    <path
      d="M24.4118 23.9141H21.8824C21.5294 23.9141 21.2941 24.1494 21.2941 24.5023V26.6199C21.2941 26.9729 21.5294 27.2082 21.8824 27.2082H24.4118C24.7647 27.2082 25 26.9729 25 26.6199V24.5023C25 24.2082 24.7059 23.9141 24.4118 23.9141ZM23.8235 26.0317H22.4706V25.0905H23.8235V26.0317Z"
      fill="#667085"
      stroke="#667085"
      strokeWidth="0.25"
    />
    <path
      d="M29.7647 23.9141H27.2353C26.8824 23.9141 26.6471 24.1494 26.6471 24.5023V26.6199C26.6471 26.9729 26.8824 27.2082 27.2353 27.2082H29.7647C30.1177 27.2082 30.3529 26.9729 30.3529 26.6199V24.5023C30.3529 24.2082 30.0588 23.9141 29.7647 23.9141ZM29.1765 26.0317H27.8235V25.0905H29.1765V26.0317Z"
      fill="#667085"
      stroke="#667085"
      strokeWidth="0.25"
    />
    <path
      d="M24.4118 28.5625H21.8824C21.5294 28.5625 21.2941 28.7978 21.2941 29.1507V31.2684C21.2941 31.6213 21.5294 31.8566 21.8824 31.8566H24.4118C24.7647 31.8566 25 31.6213 25 31.2684V29.1507C25 28.7978 24.7059 28.5625 24.4118 28.5625ZM23.8235 30.6213H22.4706V29.6801H23.8235V30.6213Z"
      fill="#667085"
      stroke="#667085"
      strokeWidth="0.25"
    />
    <path
      d="M29.7647 28.5625H27.2353C26.8824 28.5625 26.6471 28.7978 26.6471 29.1507V31.2684C26.6471 31.6213 26.8824 31.8566 27.2353 31.8566H29.7647C30.1177 31.8566 30.3529 31.6213 30.3529 31.2684V29.1507C30.3529 28.7978 30.0588 28.5625 29.7647 28.5625ZM29.1765 30.6213H27.8235V29.6801H29.1765V30.6213Z"
      fill="#667085"
      stroke="#667085"
      strokeWidth="0.25"
    />
    <path
      d="M24.4118 33.1484H21.8824C21.5294 33.1484 21.2941 33.3837 21.2941 33.7367V35.8543C21.2941 36.2073 21.5294 36.4426 21.8824 36.4426H24.4118C24.7647 36.4426 25 36.2073 25 35.8543V33.7367C25 33.4426 24.7059 33.1484 24.4118 33.1484ZM23.8235 35.2661H22.4706V34.3249H23.8235V35.2661Z"
      fill="#667085"
      stroke="#667085"
      strokeWidth="0.25"
    />
    <path
      d="M29.7647 33.1484H27.2353C26.8824 33.1484 26.6471 33.3837 26.6471 33.7367V35.8543C26.6471 36.2073 26.8824 36.4426 27.2353 36.4426H29.7647C30.1177 36.4426 30.3529 36.2073 30.3529 35.8543V33.7367C30.3529 33.4426 30.0588 33.1484 29.7647 33.1484ZM29.1765 35.2661H27.8235V34.3249H29.1765V35.2661Z"
      fill="#667085"
      stroke="#667085"
      strokeWidth="0.25"
    />
    <path
      d="M35.1177 28.5625H32.2353C31.8824 28.5625 31.6471 28.7978 31.6471 29.1507V35.8566C31.6471 36.2096 31.8824 36.4449 32.2353 36.4449H35.1177C35.4706 36.4449 35.7059 36.2096 35.7059 35.8566V29.1507C35.7059 28.7978 35.4118 28.5625 35.1177 28.5625ZM34.5294 35.2684H32.8235V29.739H34.5294V35.2684Z"
      fill="#667085"
      stroke="#667085"
      strokeWidth="0.25"
    />
    <path
      d="M9.82353 10.2073C9.52941 10.2073 9.29412 9.97197 9.29412 9.67785C9.29412 9.38373 9.52941 9.14844 9.82353 9.14844C10.0588 9.14844 10.2941 9.32491 10.3529 9.50138C10.4706 9.7955 10.7647 9.97197 11.1176 9.85432C11.4118 9.73667 11.5882 9.44256 11.4706 9.08961C11.2941 8.5602 10.8824 8.14844 10.4118 7.97197V7.73667C10.4118 7.38373 10.1765 7.14844 9.82353 7.14844C9.47059 7.14844 9.23529 7.38373 9.23529 7.73667V8.03079C8.58823 8.26608 8.11765 8.91314 8.11765 9.61903C8.11765 10.619 8.88235 11.3249 9.82353 11.3249C10.1176 11.3249 10.3529 11.5602 10.3529 11.8543C10.3529 12.1484 10.1176 12.3837 9.82353 12.3837C9.58823 12.3837 9.35294 12.2073 9.29412 12.0308C9.17647 11.7367 8.88235 11.5602 8.52941 11.6778C8.23529 11.7955 8.05882 12.0896 8.17647 12.4426C8.35294 12.972 8.7647 13.3837 9.23529 13.5602V13.7955C9.23529 14.1484 9.47059 14.3837 9.82353 14.3837C10.1765 14.3837 10.4118 14.1484 10.4118 13.7955V13.5014C11.0588 13.2661 11.5294 12.619 11.5294 11.9131C11.5294 10.972 10.8235 10.2073 9.82353 10.2073Z"
      fill="#667085"
      stroke="#667085"
      strokeWidth="0.25"
    />
    <path
      d="M9.82353 5.09375C6.64706 5.09375 4.11765 7.68199 4.11765 10.8585C4.11765 14.0349 6.70588 16.5643 9.82353 16.5643C13 16.5643 15.5294 13.9761 15.5294 10.8585C15.5882 7.68199 13 5.09375 9.82353 5.09375ZM9.82353 15.3879C7.29412 15.3879 5.29412 13.329 5.29412 10.8585C5.29412 8.32904 7.35294 6.27022 9.82353 6.27022C12.3529 6.27022 14.3529 8.32904 14.3529 10.8585C14.4118 13.329 12.3529 15.3879 9.82353 15.3879Z"
      fill="#667085"
      stroke="#667085"
      strokeWidth="0.25"
    />
    <path
      d="M5.29414 20.3874H8.76473C9.11767 20.3874 9.35296 20.1521 9.35296 19.7992C9.35296 19.4462 9.11767 19.2109 8.76473 19.2109H5.29414C4.9412 19.2109 4.7059 19.4462 4.7059 19.7992C4.7059 20.1521 4.9412 20.3874 5.29414 20.3874Z"
      fill="#667085"
      stroke="#667085"
      strokeWidth="0.25"
    />
    <path
      d="M15 23.2656H5.29414C4.9412 23.2656 4.7059 23.5009 4.7059 23.8539C4.7059 24.2068 4.9412 24.4421 5.29414 24.4421H15C15.353 24.4421 15.5883 24.2068 15.5883 23.8539C15.5883 23.5009 15.2941 23.2656 15 23.2656Z"
      fill="#667085"
      stroke="#667085"
      strokeWidth="0.25"
    />
    <path
      d="M5.29414 32.6218H15C15.353 32.6218 15.5883 32.3865 15.5883 32.0335C15.5883 31.6806 15.353 31.4453 15 31.4453H5.29414C4.9412 31.4453 4.7059 31.6806 4.7059 32.0335C4.7059 32.3865 4.9412 32.6218 5.29414 32.6218Z"
      fill="#667085"
      stroke="#667085"
      strokeWidth="0.25"
    />
    <path
      d="M15 19.2109H12.4118C12.0588 19.2109 11.8235 19.4462 11.8235 19.7992C11.8235 20.1521 12.0588 20.3874 12.4118 20.3874H15C15.353 20.3874 15.5883 20.1521 15.5883 19.7992C15.5883 19.4462 15.2941 19.2109 15 19.2109Z"
      fill="#667085"
      stroke="#667085"
      strokeWidth="0.25"
    />
    <path
      d="M5.29414 28.5046H8.76473C9.11767 28.5046 9.35296 28.2693 9.35296 27.9164C9.35296 27.5634 9.11767 27.3281 8.76473 27.3281H5.29414C4.9412 27.3281 4.7059 27.5634 4.7059 27.9164C4.7059 28.2693 4.9412 28.5046 5.29414 28.5046Z"
      fill="#667085"
      stroke="#667085"
      strokeWidth="0.25"
    />
    <path
      d="M15 27.3281H12.4118C12.0588 27.3281 11.8235 27.5634 11.8235 27.9164C11.8235 28.2693 12.0588 28.5046 12.4118 28.5046H15C15.353 28.5046 15.5883 28.2693 15.5883 27.9164C15.5883 27.5634 15.2941 27.3281 15 27.3281Z"
      fill="#667085"
      stroke="#667085"
      strokeWidth="0.25"
    />
  </svg>
);

export default FirmIcon;
