import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  currencies: [],
};

const CurrenciesSlice = createSlice({
  name: "Currencies",
  initialState,
  reducers: {
    setCurrencies(state, action) {
      state.currencies = action.payload;
    },
  },
});

export const { setCurrencies } = CurrenciesSlice.actions;

export default CurrenciesSlice.reducer;
