import { combineReducers } from 'redux';

import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import UserReducer from './User/reducer';
import SubscriptionReducer from './Subscription/reducer';
import TeamReducer from './Team/reducer';
import CurrenciesReducer from './Currencies/reducer';

// Redux persist configiration
const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['User', 'Currencies'],
};

const rootReducer = combineReducers({
  User: UserReducer,
  Subscription: SubscriptionReducer,
  Team: TeamReducer,
  Currencies: CurrenciesReducer,
});

export default persistReducer(persistConfig, rootReducer);
