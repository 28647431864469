import { useContext, useEffect, useState, act } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import SearchForm from '../../partials/actions/SearchForm';
import PaginationClassic from '../../components/PaginationClassic';
import {
  deleteReport,
  favoriteReport,
  getFavoriteReports,
  removeFavoriteReport,
  reportHistory,
} from '../../API/backend_helper';
import { DevEnv, VIEWER, trimText } from '../../utils/Utils';
import { EmptyFavoriteIcon, FavoriteBookImg, FavoriteIcon } from './svg';
import { InfoIcon, Loader } from '../../components/Svg';
import Tooltip from '../../components/Tooltip';
import AppLayout from '../../components/AppLayout';

let searchTimeout = null;

function Reports() {
  const { accessType } = useSelector((state) => state.User);

  const [searchVal, setSearchVal] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [descriptionLength, setDescriptionLength] = useState(45);
  const [localLimit, setLocalLimit] = useState(50);
  const [activeTab, setActiveTab] = useState('create');
  const [reports, setReports] = useState([]);
  const [favoriteReports, setFavoriteReports] = useState([]);
  const navigate = useNavigate();

  document.title = 'COUNT | Reports';

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 1800) {
        setDescriptionLength(80);
      }
      if (window.innerWidth > 1700) {
        setDescriptionLength(70);
      } else if (window.innerWidth > 992) {
        setDescriptionLength(40);
      } else {
        setDescriptionLength(30);
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const loadReport = async ({
    page = reports?.page,
    limit = localLimit,
    loading = true,
  }) => {
    if (loading) {
      setIsLoading(true);
    }
    try {
      const favoriteRes = await getFavoriteReports();
      if (favoriteRes?.data?.favorites?.length > 0) {
        const favoritesArray = favoriteRes.data.favorites.split(',');
        setFavoriteReports(favoritesArray);
      } else {
        setFavoriteReports([]);
      }
      const res = await reportHistory({ page, limit });
      if (res?.data) {
        setReports(res?.data);
      }
    } catch (err) {
      console.log('err', err);
    } finally {
      setIsLoading(false);
    }
  };

  const { page, totalRecords, records } = reports;

  useEffect(() => {
    loadReport({ loading: true });
  }, []);

  const onSearch = (val) => {
    setSearchVal(val);
    clearTimeout(searchTimeout);
    searchTimeout = null;
    searchTimeout = setTimeout(async () => {}, 900);
  };

  const viewReportDetails = (data) => {
    if (data.subType === 'Profit & Loss') {
      navigate(
        `/reports/profit-loss?accounts=${data.accounts || []}&startDate=${
          data.startDate
        }&endDate=${data.endDate}&tags=${data.tags || []}&type=${
          data.reportType
        }&secondStartDate=${data?.compareWith?.startDate || ''}&secondEndDate=${
          data?.compareWith?.endDate || ''
        }&currency=${data.currency}`,
      );
    } else if (data.subType === 'Account Transactions') {
      navigate(
        `/reports/account-transaction?accountId=${
          data?.categoryAccounts || ''
        }&startDate=${data.startDate}&endDate=${data.endDate}&currency=${
          data.currency
        }&tags=${data.tags || []}`,
      );
    } else if (data.subType === 'Balance Sheet') {
      navigate(
        `/reports/balance-sheet?endDate=${data.endDate}&type=${data.reportType}&currency=${data.currency}`,
      );
    } else if (data.subType === 'Income by customers') {
      navigate(
        `/reports/customer-income?startDate=${data.startDate}&endDate=${data.endDate}&currency=${data.currency}`,
      );
    } else if (data.subType === 'Aged Receivables') {
      navigate(
        `/reports/aged-receivables?startDate=${data.endDate}&currency=${data.currency}`,
      );
    } else if (data.subType === 'Category Breakdown') {
      navigate(
        `/reports/category-breakdown?year=${data.endDate.slice(
          0,
          4,
        )}${`&accountId=${data.categoryAccounts}`}&type=${
          data.reportTimeType
        }&currency=${data.currency}&tag=${data.tags || []}`,
      );
    } else if (data.subType === 'Sales Rep Report') {
      navigate(
        `/reports/sales-rep-invoices?startDate=${data?.startDate}&endDate=${data?.endDate}&currency=${data?.currency}&reportTimeType=${data?.reportTimeType}`,
      );
    } else if (data.subType === 'Expense Reporter') {
      navigate(
        `/reports/expense-claim?accountId=${data?.accounts}&startDate=${data?.startDate}&endDate=${data?.endDate}&expenseReporterId=${data?.expenseReporterId}`,
      );
    }
  };

  const handleDelete = async (reportId) => {
    try {
      await deleteReport(reportId);
      await loadReport({ loading: false });
      toast.success('Report Deleted');
    } catch (error) {
      console.log('err', error);
    }
  };

  const handleFavorite = async (reportId) => {
    try {
      if (favoriteReports?.includes?.(reportId)) {
        await removeFavoriteReport([reportId]);
        toast.success('Favorite successfully removed');
      } else {
        await favoriteReport([reportId]);
        toast.success('Report favorited successfully');
      }
      await loadReport({ loading: false });
    } catch (error) {
      console.log('err', error);
    }
  };

  return (
    <AppLayout>
      <main className="relative grow">
        <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
          <div className="flex justify-between flex-wrap items-center mb-10">
            <h1 className="text-3xl md:text-4xl text-black opacity-60  font-medium">
              Reports
            </h1>
            {activeTab !== 'create' && (
              <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-4">
                <div className="hidden md:block">
                  <SearchForm
                    searchVal={searchVal}
                    setSearchVal={onSearch}
                    placeholder="Search"
                    transaction
                  />
                </div>
              </div>
            )}
          </div>
          {isLoading ? (
            <div className="flex flex-col gap-2 items-center pb-2 justify-center mt-8">
              <Loader />
              <p className="text-slate-600 font-medium text-[0.875rem] text-center">
                Fetching data, please wait...
              </p>
            </div>
          ) : (
            <>
              <div className="flex border-b-[2px] border-[#D0D5DD] gap-x-3 sm:gap-8 mb-10 flex-wrap">
                <div
                  onClick={() => setActiveTab('create')}
                  className={`text-sm border-b-[3px] translate-y-[2px] border-transparent  cursor-pointer text-[#667085] pb-3
                        ${
                          activeTab === 'create' &&
                          ' !border-indigo-500  font-medium !text-[#101828]'
                        } `}
                >
                  Create Reports
                </div>
                <div
                  onClick={() => setActiveTab('saved')}
                  className={`text-sm border-b-[3px] translate-y-[2px] border-transparent  cursor-pointer text-[#667085] pb-3
                        ${
                          activeTab === 'saved' &&
                          ' !border-indigo-500  font-medium !text-[#101828]'
                        } `}
                >
                  Saved Reports
                </div>
              </div>
              {activeTab === 'create' && (
                <div className="w-full flex flex-col gap-6 items-start">
                  <div className="relative flex flex-col items-center self-stretch gap-3 px-6 pt-6 pb-9 rounded-[12px] bg-white border border-[#D0D5DD]">
                    <p className="text-slate-600 text-[1.25rem] font-semibold leading-6 self-stretch">
                      Favorites
                    </p>
                    {favoriteReports?.length === 0 ? (
                      <>
                        <p className="self-stretch text-slate-600 text-[1rem] leading-6 text-center">
                          You don't have any favorite reports
                        </p>
                        <div className="flex justify-center items-center gap-3 self-stretch">
                          <FavoriteIcon />
                          <p className="text-[#101828] text-center leading-6 text-[1rem]">
                            Simply click the star on each report to add it here!
                          </p>
                        </div>
                        <div className="absolute right-0 bottom-0 hidden sm:block">
                          <FavoriteBookImg />
                        </div>
                      </>
                    ) : (
                      <div className="flex flex-wrap justify-center sm:justify-start items-center gap-6 self-stretch w-full">
                        {favoriteReports?.includes?.('profit & loss') && (
                          <div
                            className="relative flex p-6 flex-col justify-center items-center gap-3 rounded-[12px] bg-white border border-[#D0D5DD] w-[20rem] cursor-pointer"
                            onClick={() => {
                              if (accessType === VIEWER) {
                                return;
                              }
                              navigate('/reports/profit-loss');
                            }}
                          >
                            <div
                              className="absolute top-3 right-4 cursor-pointer"
                              onClick={(e) => {
                                e.stopPropagation();
                                handleFavorite('profit & loss');
                              }}
                            >
                              {favoriteReports?.includes?.('profit & loss') ? (
                                <FavoriteIcon />
                              ) : (
                                <EmptyFavoriteIcon />
                              )}
                            </div>
                            <p className="text-indigo-500 text-[1.25rem] self-stretch font-semibold leading-9 text-left">
                              Profit & Loss Statement
                            </p>
                            <p className="leading-6 text-slate-600 self-stretch">
                              Shows your business's net profit and summarizes
                              your revenue.
                            </p>
                          </div>
                        )}

                        {favoriteReports?.includes?.('pnl_tags') && (
                          <div
                            className="relative flex p-6 flex-col justify-center items-center gap-3 rounded-[12px] bg-white border border-[#D0D5DD] w-[20rem] cursor-pointer"
                            onClick={() => {
                              if (accessType === VIEWER) {
                                return;
                              }
                              navigate('/reports/profit-loss-tags');
                            }}
                          >
                            <div
                              className="absolute top-3 right-4 cursor-pointer"
                              onClick={(e) => {
                                e.stopPropagation();
                                handleFavorite('pnl_tags');
                              }}
                            >
                              {favoriteReports?.includes?.('pnl_tags') ? (
                                <FavoriteIcon />
                              ) : (
                                <EmptyFavoriteIcon />
                              )}
                            </div>
                            <p className="text-indigo-500 text-[1.25rem] self-stretch font-semibold leading-9 text-left">
                              Profit & Loss by Tags
                            </p>
                            <p className="leading-6 text-slate-600 self-stretch">
                              Analyze profitability by Tags e.g. teams or
                              departments.
                            </p>
                            <div className="absolute bottom-3 right-4">
                              <Tooltip
                                content="The Profit & Loss by Tags report offers a side-by-side comparison of profitability across different parts of your business, such as teams, departments, divisions, or profit centers. Tags provide a flexible way to capture transactional data, allowing you to see how each area contributes to overall business performance. This report gives you a clear view of financial health by breaking down revenue and expenses by tagged segments."
                                contentClassName="w-[300px] max-w-[300px] !text-wrap break-words border-none overflow-visible text-xs text-[#667085] text-center"
                                position="left"
                                size="lg"
                              >
                                <InfoIcon />
                              </Tooltip>
                            </div>
                          </div>
                        )}

                        {favoriteReports?.includes?.('customer statment') && (
                          <div
                            className="relative flex p-6 flex-col justify-center items-center gap-3 rounded-[12px] bg-white border border-[#D0D5DD] w-[20rem] cursor-pointer"
                            onClick={() => {
                              if (accessType === VIEWER) {
                                return;
                              }
                              navigate('/reports/profit-loss');
                            }}
                          >
                            <div
                              className="absolute top-3 right-4 cursor-pointer"
                              onClick={(e) => {
                                e.stopPropagation();
                                handleFavorite('customer statment');
                              }}
                            >
                              {favoriteReports?.includes?.(
                                'customer statment',
                              ) ? (
                                <FavoriteIcon />
                              ) : (
                                <EmptyFavoriteIcon />
                              )}
                            </div>
                            <p className="text-indigo-500 text-[1.25rem] self-stretch font-semibold leading-9 text-left">
                              Customer Statment
                            </p>
                            <p className="leading-6 text-slate-600 self-stretch">
                              Shows your business's net profit and summarizes
                              your revenue.
                            </p>
                          </div>
                        )}

                        {favoriteReports?.includes?.('balance sheet') && (
                          <div
                            className="relative flex p-6 flex-col justify-center items-center gap-3 rounded-[12px] bg-white border border-[#D0D5DD] w-[20rem] cursor-pointer"
                            onClick={() => {
                              if (accessType === VIEWER) {
                                return;
                              }
                              navigate('/reports/balance-sheet');
                            }}
                          >
                            <div
                              className="absolute top-3 right-4 cursor-pointer"
                              onClick={(e) => {
                                e.stopPropagation();
                                handleFavorite('balance sheet');
                              }}
                            >
                              {favoriteReports?.includes?.('balance sheet') ? (
                                <FavoriteIcon />
                              ) : (
                                <EmptyFavoriteIcon />
                              )}
                            </div>
                            <p className="text-indigo-500 text-[1.25rem] self-stretch font-semibold leading-9 text-left">
                              Balance Sheet
                            </p>
                            <p className="leading-6 text-slate-600 self-stretch">
                              A snapshot of finances on any given day!
                            </p>
                            <div className="absolute bottom-3 right-4">
                              <Tooltip
                                content={
                                  <>
                                    <p>
                                      A snapshot of your finances at a given
                                      timeframe. The
                                    </p>
                                    <p>
                                      balance sheet calculates your
                                      business&acute;s worth
                                    </p>
                                    <p>
                                      (equity) by subtracting what you owe
                                      (liabilities) from what
                                    </p>
                                    <p>you own (assets).</p>
                                  </>
                                }
                                contentClassName="border-none overflow-visible text-sm text-[#667085] text-center"
                                position="top"
                                size="lg"
                              >
                                <InfoIcon />
                              </Tooltip>
                            </div>
                          </div>
                        )}

                        {favoriteReports?.includes?.('category breakdown') && (
                          <div
                            className="relative flex p-6 flex-col justify-center items-center gap-3 rounded-[12px] bg-white border border-[#D0D5DD] w-[20rem] cursor-pointer"
                            onClick={() => {
                              if (accessType === VIEWER) {
                                return;
                              }
                              navigate('/reports/category-breakdown');
                            }}
                          >
                            <div
                              className="absolute top-3 right-4 cursor-pointer"
                              onClick={(e) => {
                                e.stopPropagation();
                                handleFavorite('category breakdown');
                              }}
                            >
                              {favoriteReports?.includes?.(
                                'category breakdown',
                              ) ? (
                                <FavoriteIcon />
                              ) : (
                                <EmptyFavoriteIcon />
                              )}
                            </div>
                            <p className="text-indigo-500 text-[1.25rem] self-stretch font-semibold leading-9 text-left">
                              Category Breakdown
                            </p>
                            <p className="leading-6 text-slate-600 self-stretch">
                              See how your income and expenses stack up across
                              different categories.
                            </p>
                          </div>
                        )}

                        {favoriteReports?.includes?.('income by customers') && (
                          <div
                            className="relative flex p-6 flex-col justify-center items-center gap-3 rounded-[12px] bg-white border border-[#D0D5DD] w-[20rem] cursor-pointer"
                            onClick={() => {
                              if (accessType === VIEWER) {
                                return;
                              }
                              navigate('/reports/customer-income');
                            }}
                          >
                            <div
                              className="absolute top-3 right-4 cursor-pointer"
                              onClick={(e) => {
                                e.stopPropagation();
                                handleFavorite('income by customers');
                              }}
                            >
                              {favoriteReports?.includes?.(
                                'income by customers',
                              ) ? (
                                <FavoriteIcon />
                              ) : (
                                <EmptyFavoriteIcon />
                              )}
                            </div>
                            <p className="text-indigo-500 text-[1.25rem] self-stretch font-semibold leading-9 text-left">
                              Income by Customers
                            </p>
                            <p className="leading-6 text-slate-600 self-stretch">
                              Drill down and understand which customers are
                              driving your revenue.
                            </p>
                          </div>
                        )}

                        {favoriteReports?.includes?.('aged receivables') && (
                          <div
                            className="relative flex p-6 flex-col justify-center items-center gap-3 rounded-[12px] bg-white border border-[#D0D5DD] w-[20rem] cursor-pointer"
                            onClick={() => {
                              if (accessType === VIEWER) {
                                return;
                              }
                              navigate('/reports/aged-receivables');
                            }}
                          >
                            <div
                              className="absolute top-3 right-4 cursor-pointer"
                              onClick={(e) => {
                                e.stopPropagation();
                                handleFavorite('aged receivables');
                              }}
                            >
                              {favoriteReports?.includes?.(
                                'aged receivables',
                              ) ? (
                                <FavoriteIcon />
                              ) : (
                                <EmptyFavoriteIcon />
                              )}
                            </div>
                            <p className="text-indigo-500 text-[1.25rem] self-stretch font-semibold leading-9 text-left">
                              Aged Receivables
                            </p>
                            <p className="leading-6 text-slate-600 self-stretch w-[110%] ">
                              Identify outstanding invoices and prioritize
                              collections to ensure cash flow.{' '}
                            </p>
                          </div>
                        )}

                        {favoriteReports?.includes?.(
                          'account transactions',
                        ) && (
                          <div
                            className="relative flex p-6 flex-col justify-center items-center gap-3 rounded-[12px] bg-white border border-[#D0D5DD] w-[20rem] cursor-pointer"
                            onClick={() => {
                              if (accessType === VIEWER) {
                                return;
                              }
                              navigate('/reports/account-transaction');
                            }}
                          >
                            <div
                              className="absolute top-3 right-4 cursor-pointer"
                              onClick={(e) => {
                                e.stopPropagation();
                                handleFavorite('account transactions');
                              }}
                            >
                              {favoriteReports?.includes?.(
                                'account transactions',
                              ) ? (
                                <FavoriteIcon />
                              ) : (
                                <EmptyFavoriteIcon />
                              )}
                            </div>
                            <p className="text-indigo-500 text-[1.25rem] self-stretch font-semibold leading-9 text-left">
                              Account Transactions
                            </p>
                            <p className="leading-6 text-slate-600 self-stretch">
                              This comprehensive report aids in auditing and
                              verifying transactions.
                            </p>
                            <div className="absolute bottom-3 right-4">
                              <Tooltip
                                content={
                                  <>
                                    <p>
                                      Generate an Account Transactions report to
                                      view detailed
                                    </p>
                                    <p>
                                      information on all transactions entered
                                      into your COUNT
                                    </p>
                                    <p>
                                      workspace. This comprehensive report aids
                                      in auditing and
                                    </p>
                                    <p>
                                      verifying each transaction, ensuring
                                      accuracy and
                                    </p>
                                    <p>transparency across your accounts.</p>
                                  </>
                                }
                                contentClassName="border-none overflow-visible text-sm text-[#667085] text-center"
                                position="top"
                                size="lg"
                              >
                                <InfoIcon />
                              </Tooltip>
                            </div>
                          </div>
                        )}

                        {favoriteReports?.includes?.('expense claims') && (
                          <div
                            className="relative flex p-6 flex-col justify-center items-center gap-3 rounded-[12px] bg-white border border-[#D0D5DD] w-[20rem] cursor-pointer"
                            onClick={() => {
                              if (accessType === VIEWER) {
                                return;
                              }
                              navigate('/reports/expense-claim');
                            }}
                            role="button"
                            tabIndex="0"
                          >
                            <div
                              className="absolute top-3 right-4 cursor-pointer"
                              onClick={(e) => {
                                e.stopPropagation();
                                handleFavorite('expense claims');
                              }}
                              role="button"
                              tabIndex="0"
                            >
                              {favoriteReports?.includes?.('expense claims') ? (
                                <FavoriteIcon />
                              ) : (
                                <EmptyFavoriteIcon />
                              )}
                            </div>
                            <p className="text-indigo-500 text-[1.25rem] self-stretch font-semibold leading-9 text-left">
                              Expense Claims
                            </p>
                            <p className="leading-6 text-slate-600 self-stretch">
                              View expense claims by account and reporter.
                            </p>
                            <div className="absolute bottom-3 right-4">
                              <Tooltip
                                content="This report provides a comprehensive view of expense claims, detailing all expenses for a particular account and reporter, along with their approval status. It also includes bank transactions that have not yet been submitted for approval by the reporter. Filter the data by date range, reporter, and account."
                                contentClassName="w-[400px] max-w-[400px] !text-wrap break-words border-none overflow-visible text-sm text-[#667085] text-center"
                                position="top"
                                size="lg"
                              >
                                <InfoIcon />
                              </Tooltip>
                            </div>
                          </div>
                        )}
                        {favoriteReports?.includes?.(
                          'invoices by sales rep',
                        ) && (
                          <div
                            className="relative flex p-6 flex-col justify-center items-center gap-3 rounded-[12px] bg-white border border-[#D0D5DD] w-[20rem] cursor-pointer"
                            onClick={() => {
                              if (accessType === VIEWER) {
                                return;
                              }
                              navigate('/reports/sales-rep-invoices');
                            }}
                            role="button"
                            tabIndex="0"
                          >
                            <div
                              className="absolute top-3 right-4 cursor-pointer"
                              onClick={(e) => {
                                e.stopPropagation();
                                handleFavorite('invoices by sales rep');
                              }}
                              role="button"
                              tabIndex="0"
                            >
                              {favoriteReports?.includes?.(
                                'invoices by sales rep',
                              ) ? (
                                <FavoriteIcon />
                              ) : (
                                <EmptyFavoriteIcon />
                              )}
                            </div>
                            <p className="text-indigo-500 text-[1.25rem] self-stretch font-semibold leading-9 text-left">
                              Sales Rep Performance
                            </p>
                            <p className="leading-6 text-slate-600 self-stretch">
                              Breakdown of invoices and revenue by sales reps.
                            </p>
                            <div className="absolute bottom-3 right-4">
                              <Tooltip
                                content="Provides a detailed summary of each sales rep's
                                invoices over a specified period, showing
                                payments received, outstanding payments and
                                total revenue generated. This report helps track
                                the performance and effectiveness of individual
                                sales reps, offering valuable insights into
                                their contributions to the business's income."
                                contentClassName="w-[400px] max-w-[400px] !text-wrap break-words border-none overflow-visible text-sm text-[#667085] text-center"
                                position="top"
                                size="lg"
                              >
                                <InfoIcon />
                              </Tooltip>
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                  {/* Report Type Row */}
                  <div className="flex gap-6 flex-col items-center w-full">
                    <div className="flex flex-col gap-1 items-start self-stretch justify-center">
                      <p className="text-slate-600 text-[1.25rem] font-semibold">
                        Financial Statements
                      </p>
                      <p className="text-slate-600 text-[1rem]">
                        Get a clear picture of how your business is doing.
                      </p>
                    </div>
                    {/* Report Cell Row */}
                    <div className="flex flex-wrap justify-center sm:justify-start items-center gap-6 self-stretch w-full">
                      {/* Report Cell Box */}
                      <div
                        className="relative flex p-6 flex-col justify-center items-center gap-3 rounded-[12px] bg-white border border-[#D0D5DD] w-[20rem] cursor-pointer"
                        onClick={() => {
                          if (accessType === VIEWER) {
                            return;
                          }
                          navigate('/reports/profit-loss');
                        }}
                      >
                        <div
                          className="absolute top-3 right-4 cursor-pointer"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleFavorite('profit & loss');
                          }}
                        >
                          {favoriteReports?.includes?.('profit & loss') ? (
                            <FavoriteIcon />
                          ) : (
                            <EmptyFavoriteIcon />
                          )}
                        </div>
                        <p className="text-indigo-500 text-[1.25rem] self-stretch font-semibold leading-9 text-left">
                          Profit & Loss Statement
                        </p>
                        <p className="leading-6 text-slate-600 self-stretch">
                          Shows your business's net profit and summarizes your
                          revenue.
                        </p>
                      </div>
                      {process.env.REACT_APP_ENV === DevEnv && (
                        <div
                          className="relative flex p-6 flex-col justify-center items-center gap-3 rounded-[12px] bg-white border border-[#D0D5DD] w-[20rem] cursor-pointer"
                          onClick={() => {
                            if (accessType === VIEWER) {
                              return;
                            }
                            navigate('/reports/profit-loss-tags');
                          }}
                        >
                          <div
                            className="absolute top-3 right-4 cursor-pointer"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleFavorite('pnl_tags');
                            }}
                          >
                            {favoriteReports?.includes?.('pnl_tags') ? (
                              <FavoriteIcon />
                            ) : (
                              <EmptyFavoriteIcon />
                            )}
                          </div>
                          <p className="text-indigo-500 text-[1.25rem] self-stretch font-semibold leading-9 text-left">
                            Profit & Loss by Tags
                          </p>
                          <p className="leading-6 text-slate-600 self-stretch">
                            Analyze profitability by Tags e.g. teams or
                            departments.
                          </p>
                          <div className="absolute bottom-3 right-4">
                            <Tooltip
                              content="The Profit & Loss by Tags report offers a side-by-side comparison of profitability across different parts of your business, such as teams, departments, divisions, or profit centers. Tags provide a flexible way to capture transactional data, allowing you to see how each area contributes to overall business performance. This report gives you a clear view of financial health by breaking down revenue and expenses by tagged segments."
                              contentClassName="w-[300px] max-w-[300px] !text-wrap break-words border-none overflow-visible text-xs text-[#667085] text-center"
                              position="top"
                              size="lg"
                            >
                              <InfoIcon />
                            </Tooltip>
                          </div>
                        </div>
                      )}
                      <div
                        className="relative flex p-6 flex-col justify-center items-center gap-3 rounded-[12px] bg-white border border-[#D0D5DD] w-[20rem] cursor-pointer"
                        onClick={() => {
                          if (accessType === VIEWER) {
                            return;
                          }
                          navigate('/reports/balance-sheet');
                        }}
                      >
                        <div
                          className="absolute top-3 right-4 cursor-pointer"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleFavorite('balance sheet');
                          }}
                        >
                          {favoriteReports?.includes?.('balance sheet') ? (
                            <FavoriteIcon />
                          ) : (
                            <EmptyFavoriteIcon />
                          )}
                        </div>
                        <p className="text-indigo-500 text-[1.25rem] self-stretch font-semibold leading-9 text-left">
                          Balance Sheet
                        </p>
                        <p className="leading-6 text-slate-600 self-stretch">
                          A snapshot of finances on any given day!
                        </p>
                        <div className="absolute bottom-3 right-4">
                          <Tooltip
                            content={
                              <>
                                <p>
                                  A snapshot of your finances at a given
                                  timeframe. The
                                </p>
                                <p>
                                  balance sheet calculates your business&acute;s
                                  worth
                                </p>
                                <p>
                                  (equity) by subtracting what you owe
                                  (liabilities) from what
                                </p>
                                <p>you own (assets).</p>
                              </>
                            }
                            contentClassName="border-none overflow-visible text-sm text-[#667085] text-center"
                            position="top"
                            size="lg"
                          >
                            <InfoIcon />
                          </Tooltip>
                        </div>
                      </div>
                      <div
                        className="relative flex p-6 flex-col justify-center items-center gap-3 rounded-[12px] bg-white border border-[#D0D5DD] w-[20rem] cursor-pointer"
                        onClick={() => {
                          if (accessType === VIEWER) {
                            return;
                          }
                          navigate('/reports/category-breakdown');
                        }}
                      >
                        <div
                          className="absolute top-3 right-4 cursor-pointer"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleFavorite('category breakdown');
                          }}
                        >
                          {favoriteReports?.includes?.('category breakdown') ? (
                            <FavoriteIcon />
                          ) : (
                            <EmptyFavoriteIcon />
                          )}
                        </div>
                        <p className="text-indigo-500 text-[1.25rem] self-stretch font-semibold leading-9 text-left">
                          Category Breakdown
                        </p>
                        <p className="leading-6 text-slate-600 self-stretch">
                          See how your income and expenses stack up across
                          different categories.
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* Report Type Row */}
                  <div className="flex gap-6 flex-col items-center w-full">
                    <div className="flex flex-col gap-1 items-start self-stretch justify-center">
                      <p className="text-slate-600 text-[1.25rem] font-semibold">
                        Customers
                      </p>
                      <p className="text-slate-600 text-[1rem]">
                        Manage your customer relationships and gain valuable
                        insights.{' '}
                      </p>
                    </div>
                    {/* Report Cell Row */}
                    <div className="flex flex-wrap justify-center sm:justify-start  items-center gap-6 self-stretch w-full">
                      {/* Report Cell Box */}
                      <div
                        className="relative flex p-6 flex-col justify-center items-center gap-3 rounded-[12px] bg-white border border-[#D0D5DD] w-[20rem] cursor-pointer"
                        onClick={() => {
                          if (accessType === VIEWER) {
                            return;
                          }
                          navigate('/reports/customer-income');
                        }}
                      >
                        <div
                          className="absolute top-3 right-4 cursor-pointer"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleFavorite('income by customers');
                          }}
                        >
                          {favoriteReports?.includes?.(
                            'income by customers',
                          ) ? (
                            <FavoriteIcon />
                          ) : (
                            <EmptyFavoriteIcon />
                          )}
                        </div>
                        <p className="text-indigo-500 text-[1.25rem] self-stretch font-semibold leading-9 text-left">
                          Income by Customers
                        </p>
                        <p className="leading-6 text-slate-600 self-stretch">
                          Drill down and understand which customers are driving
                          your revenue.
                        </p>
                      </div>
                      <div
                        className="relative flex p-6 flex-col justify-center items-center gap-3 rounded-[12px] bg-white border border-[#D0D5DD] w-[20rem] cursor-pointer"
                        onClick={() => {
                          if (accessType === VIEWER) {
                            return;
                          }
                          navigate('/reports/aged-receivables');
                        }}
                      >
                        <div
                          className="absolute top-3 right-4 cursor-pointer"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleFavorite('aged receivables');
                          }}
                        >
                          {favoriteReports?.includes?.('aged receivables') ? (
                            <FavoriteIcon />
                          ) : (
                            <EmptyFavoriteIcon />
                          )}
                        </div>
                        <p className="text-indigo-500 text-[1.25rem] self-stretch font-semibold leading-9 text-left">
                          Aged Receivables
                        </p>
                        <p className="leading-6 text-slate-600 self-stretch w-[110%] ">
                          Identify outstanding invoices and prioritize
                          collections to ensure cash flow.{' '}
                        </p>
                      </div>
                      {/* Report Cell Box */}
                      <div
                        className="relative flex p-6 flex-col justify-center items-center gap-3 rounded-[12px] bg-white border border-[#D0D5DD] w-[20rem] cursor-pointer"
                        onClick={() => {
                          if (accessType === VIEWER) {
                            return;
                          }
                          navigate('/reports/sales-rep-invoices');
                        }}
                        role="button"
                        tabIndex="0"
                      >
                        <div
                          className="absolute top-3 right-4 cursor-pointer"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleFavorite('invoices by sales rep');
                          }}
                          role="button"
                          tabIndex="0"
                        >
                          {favoriteReports?.includes?.(
                            'invoices by sales rep',
                          ) ? (
                            <FavoriteIcon />
                          ) : (
                            <EmptyFavoriteIcon />
                          )}
                        </div>
                        <p className="text-indigo-500 text-[1.25rem] self-stretch font-semibold leading-9 text-left">
                          Sales Rep Performance
                        </p>
                        <p className="leading-6 text-slate-600 self-stretch">
                          Breakdown of invoices and revenue by sales reps.
                        </p>
                        <div className="absolute bottom-3 right-4">
                          <Tooltip
                            content="Provides a detailed summary of each sales rep's
                                invoices over a specified period, showing
                                payments received, outstanding payments and
                                total revenue generated. This report helps track
                                the performance and effectiveness of individual
                                sales reps, offering valuable insights into
                                their contributions to the business's income."
                            contentClassName="w-[400px] max-w-[400px] !text-wrap break-words border-none overflow-visible text-sm text-[#667085] text-center"
                            position="top"
                            size="lg"
                          >
                            <InfoIcon />
                          </Tooltip>
                        </div>
                      </div>
                      <div
                        className="relative flex p-6 flex-col justify-center items-center gap-3 rounded-[12px] bg-white border border-[#D0D5DD] w-[20rem] cursor-pointer"
                        onClick={() => {
                          if (accessType === VIEWER) {
                            return;
                          }
                          navigate('/customer-statement');
                        }}
                      >
                        <div
                          className="absolute top-3 right-4 cursor-pointer"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleFavorite('customer statment');
                          }}
                        >
                          {favoriteReports?.includes?.('customer statment') ? (
                            <FavoriteIcon />
                          ) : (
                            <EmptyFavoriteIcon />
                          )}
                        </div>
                        <p className="text-indigo-500 text-[1.25rem] self-stretch font-semibold leading-9 text-left">
                          Customer Statment
                        </p>
                        <p className="leading-6 text-slate-600 self-stretch">
                          Drill down and understand which customers are driving
                          your revenue.
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* Report Type Row */}
                  <div className="flex gap-6 flex-col items-center w-full">
                    <div className="flex flex-col gap-1 items-start self-stretch justify-center">
                      <p className="text-slate-600 text-[1.25rem] font-semibold">
                        Accounting
                      </p>
                      <p className="text-slate-600 text-[1rem]">
                        Streamline your financial tasks and stay organized.
                      </p>
                    </div>
                    {/* Report Cell Row */}
                    <div className="flex flex-wrap justify-center sm:justify-start  items-center gap-6 self-stretch w-full">
                      {/* Report Cell Box */}
                      <div
                        className="relative flex p-6 flex-col justify-center items-center gap-3 rounded-[12px] bg-white border border-[#D0D5DD] w-[20rem] cursor-pointer"
                        onClick={() => {
                          if (accessType === VIEWER) {
                            return;
                          }
                          navigate('/reports/account-transaction');
                        }}
                      >
                        <div
                          className="absolute top-3 right-4 cursor-pointer"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleFavorite('account transactions');
                          }}
                        >
                          {favoriteReports?.includes?.(
                            'account transactions',
                          ) ? (
                            <FavoriteIcon />
                          ) : (
                            <EmptyFavoriteIcon />
                          )}
                        </div>
                        <p className="text-indigo-500 text-[1.25rem] self-stretch font-semibold leading-9 text-left">
                          Account Transactions
                        </p>
                        <p className="leading-6 text-slate-600 self-stretch">
                          This comprehensive report aids in auditing and
                          verifying transactions.
                        </p>
                        <div className="absolute bottom-3 right-4">
                          <Tooltip
                            content={
                              <>
                                <p>
                                  Generate an Account Transactions report to
                                  view detailed
                                </p>
                                <p>
                                  information on all transactions entered into
                                  your COUNT
                                </p>
                                <p>
                                  workspace. This comprehensive report aids in
                                  auditing and
                                </p>
                                <p>
                                  verifying each transaction, ensuring accuracy
                                  and
                                </p>
                                <p>transparency across your accounts.</p>
                              </>
                            }
                            contentClassName="border-none overflow-visible text-sm text-[#667085] text-center"
                            position="top"
                            size="lg"
                          >
                            <InfoIcon />
                          </Tooltip>
                        </div>
                      </div>
                      <div
                        className="relative flex p-6 flex-col justify-center items-center gap-3 rounded-[12px] bg-white border border-[#D0D5DD] w-[20rem] cursor-pointer"
                        onClick={() => {
                          if (accessType === VIEWER) {
                            return;
                          }
                          navigate('/reports/expense-claim');
                        }}
                        role="button"
                        tabIndex="0"
                      >
                        <div
                          className="absolute top-3 right-4 cursor-pointer"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleFavorite('expense claims');
                          }}
                          role="button"
                          tabIndex="0"
                        >
                          {favoriteReports?.includes?.('expense claims') ? (
                            <FavoriteIcon />
                          ) : (
                            <EmptyFavoriteIcon />
                          )}
                        </div>
                        <p className="text-indigo-500 text-[1.25rem] self-stretch font-semibold leading-9 text-left">
                          Expense Claims
                        </p>
                        <p className="leading-6 text-slate-600 self-stretch">
                          View expense claims by account and reporter.
                        </p>
                        <div className="absolute bottom-3 right-4">
                          <Tooltip
                            content="This report provides a comprehensive view of expense claims, detailing all expenses for a particular account and reporter, along with their approval status. It also includes bank transactions that have not yet been submitted for approval by the reporter. Filter the data by date range, reporter, and account."
                            contentClassName="w-[400px] max-w-[400px] !text-wrap break-words border-none overflow-visible text-sm text-[#667085] text-center"
                            position="top"
                            size="lg"
                          >
                            <InfoIcon />
                          </Tooltip>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {activeTab === 'saved' && (
                <>
                  {!(records?.length > 0) || isLoading ? (
                    isLoading ? (
                      <div className="flex flex-col gap-2 items-center pb-2 justify-center mt-8">
                        <Loader />
                        <p className="text-slate-600 font-medium text-[0.875rem] text-center">
                          Fetching data, please wait...
                        </p>
                      </div>
                    ) : (
                      <div className="flex justify-between flex-grow pt-5 pl-7  flex-shrink-0 basis-0 rounded-xl border border-[#D0D5DD] bg-white">
                        <p className="text-left text-[#667085] h-[2.375rem] text-[1.25rem] font-semibold leading-[2.375rem] flex  justify-start self-stretch">
                          Your Past Reports
                        </p>
                        <p className="self-stretch flex mt-6 flex-col justify-center gap-2  text-center text-slate-600 text-[1rem] leading-6 font-normal h-16">
                          <p className="text-[#667085]">
                            You don&acute;t have any past reports!
                          </p>
                          <p className="text-[#101828]">
                            You don't have any saved reports. Press save when
                            viewing a report to save it to this list!
                          </p>
                        </p>
                        <svg
                          width="224"
                          height="124"
                          viewBox="0 0 224 124"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clipPath="url(#clip0_2815_61891)">
                            <path
                              d="M69.5581 140.116C107.974 140.116 139.116 108.974 139.116 70.5581C139.116 32.1422 107.974 1 69.5581 1C31.1422 1 0 32.1422 0 70.5581C0 108.974 31.1422 140.116 69.5581 140.116Z"
                              fill="#F9EAE0"
                            />
                            <g clipPath="url(#clip1_2815_61891)">
                              <path
                                d="M224.26 160.427L131.5 213.981V106.872L224.26 53.3164V160.427Z"
                                fill="#78BD4F"
                              />
                              <path
                                d="M131.5 213.981L38.7401 160.427V53.3164L131.5 106.872V213.981Z"
                                fill="#78BD4F"
                              />
                              <path
                                d="M40.1315 53.3177L131.5 0.566406L222.869 53.3177L131.5 106.07L40.1315 53.3177Z"
                                fill="white"
                              />
                              <path
                                d="M131.5 1.3718L221.477 53.32L131.5 105.268L41.5228 53.32L131.5 1.3718ZM131.5 -0.234375L38.7401 53.32L131.5 106.875L224.26 53.32L131.5 -0.234375Z"
                                fill="#081F2D"
                              />
                              <path
                                d="M131.5 106.871V213.981"
                                stroke="#081F2D"
                                strokeWidth="1.39131"
                                strokeLinejoin="round"
                              />
                            </g>
                          </g>
                          <defs>
                            <clipPath id="clip0_2815_61891">
                              <rect width="289" height="254.02" fill="white" />
                            </clipPath>
                            <clipPath id="clip1_2815_61891">
                              <rect
                                width="193"
                                height="197"
                                fill="white"
                                transform="translate(39 -6)"
                              />
                            </clipPath>
                          </defs>
                        </svg>

                        {/* <img
                    src={reportBook}
                    alt=""
                    className="max-h-[10rem] h-auto w-auto flex items-center justify-center max-w-full  rounded-xl"
                  /> */}
                      </div>
                    )
                  ) : (
                    <div className="flex flex-col px-7 py-5 overflow-x-auto scrollbar  flex-grow flex-shrink-0 basis-0 rounded-xl border border-[#D0D5DD] bg-white">
                      <p className="pb-6 py-2 text-[#667085] font-semibold">
                        Your Past Reports
                      </p>
                      <table className="table-fixed overflow-x-auto scrollbar w-full dark:text-slate-300 min-w-max">
                        <thead className="w-full shadow-md rounded-[0.313rem] text-sm leading-[1.125rem] text-slate-600">
                          <tr className="w-full py-[1.438rem] px-[1.375rem] flex items-center justify-between">
                            <th className="">
                              <div className="text-left w-[6rem] font-medium">
                                Date
                              </div>
                            </th>
                            <th className="">
                              <div className="text-left w-[17rem] font-medium">
                                Report Title
                              </div>
                            </th>
                            <th className="">
                              <div className="text-left w-[12rem] font-medium">
                                Report Type
                              </div>
                            </th>

                            <th className="">
                              <div className="text-left w-[9rem] font-medium">
                                Action
                              </div>
                            </th>
                          </tr>
                        </thead>
                        {records && (
                          <tbody className="text-sm divide-y divide-gray-300 border-b border-gray-300">
                            {records.filter((report) =>
                              report.name
                                .toLowerCase()
                                .includes(searchVal.toLowerCase()),
                            ).length === 0 ? (
                              <tr>
                                <td colSpan="4" className="text-center py-4">
                                  No reports found
                                </td>
                              </tr>
                            ) : (
                              records
                                .filter((report) =>
                                  report.name
                                    .toLowerCase()
                                    .includes(searchVal.toLowerCase()),
                                )
                                .map((report, index) => (
                                  <tr
                                    className="w-full px-[1.375rem] flex items-center justify-between h-20 hover:bg-[#a0cd85] hover:bg-opacity-5"
                                    key={report.id}
                                  >
                                    <td className="whitespace-nowrap">
                                      <div className="w-[6rem] text-left">
                                        {moment(report.createdAt).format('ll')}
                                      </div>
                                    </td>
                                    <td className="whitespace-nowrap">
                                      <div className="w-[17rem] text-left text-[#101828]">
                                        {report?.name
                                          ? trimText(
                                              report?.name,
                                              descriptionLength,
                                            )
                                          : '---'}
                                      </div>
                                    </td>
                                    <td className="whitespace-nowrap">
                                      <div className="w-[12rem] text-left">
                                        {report?.subType || '---'}
                                      </div>
                                    </td>
                                    <td className="whitespace-nowrap text-left w-[9rem]">
                                      <div className="h-[1.875rem] w-[4rem] flex items-center justify-center p-2.5 rounded-[0.313rem] bg-opacity-10 gap-[8px]">
                                        <button
                                          className="flex items-center justify-center px-5 py-5 rounded-[0.313rem] shadow-sm leading-6 text-[0.875rem] h-8 whitespace-nowrap bg-indigo-500 text-white"
                                          onClick={() =>
                                            viewReportDetails(report)
                                          }
                                        >
                                          View Report
                                        </button>
                                        {accessType !== VIEWER && (
                                          <button
                                            onClick={() => {
                                              handleDelete(report.id);
                                            }}
                                            className="flex items-center justify-center px-5 py-5 rounded-[0.313rem] shadow-sm leading-6 text-[0.875rem] h-8 whitespace-nowrap border border-[#667085] text-[#667085]"
                                          >
                                            Delete
                                          </button>
                                        )}
                                      </div>
                                    </td>
                                  </tr>
                                ))
                            )}
                          </tbody>
                        )}
                      </table>
                      <div className="mt-10">
                        {!isLoading && records?.length > 0 && (
                          <PaginationClassic
                            pagination={{
                              limit: localLimit,
                              page,
                              totalRecords,
                            }}
                            onPageChange={loadReport}
                            setLimit={setLocalLimit}
                          />
                        )}
                      </div>
                    </div>
                  )}
                </>
              )}
            </>
          )}
        </div>
      </main>
    </AppLayout>
  );
}
export default Reports;
