import React, { useEffect, useRef, useState } from "react";
import Transition from "../../../utils/Transition";
import useClickOutside from "../../../utils/useClickOutside";
import { areArraysEqual, extractValuesByKey } from "../../../utils/Utils";

let Timeout = null;
const AccountsFilter = ({
  align,
  accounts,
  allAccounts,
  getTransactionsApi = () => {},
  selectedAccounts = [],
  setSelectedAccounts = () => {},
  apiFilter,
  setSelectedCategoryId
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [filteredData, setFilteredData] = useState(allAccounts);
  const [isResultFound, setIsResultFound] = useState(true);
  const [collapsedIds, setCollapsedIds] = useState([]);
  const [localSelected, setLocalSelected] = useState({});
  const trigger = useRef(null);
  const dropdown = useRef(null);
  const searchInput = useRef(null);

  // close on click outside
  useClickOutside(dropdown, (event) => {
    if (!trigger.current.contains(event.target)) {
      setDropdownOpen(false);
    }
  });

  useEffect(() => {
    if (accounts) {
      setFilteredData(accounts);
    }
  }, [accounts]);

  useEffect(() => {
    if (dropdownOpen) {
      setSearchValue("");
      setFilteredData(accounts);
      if (accounts?.length) {
        setIsResultFound(true);
      }
      if (window.innerWidth >= 768) {
        searchInput.current?.focus();
      }
    }
  }, [dropdownOpen]);

  const handleSearch = (e) => {
    setSearchValue(e.target.value);
    if (!e.target.value) {
      setFilteredData(accounts);
      if (allAccounts?.length) {
        setIsResultFound(true);
      }
      return;
    }
    setIsResultFound(true);
    const formattedValue = e.target.value.replace(/\s/g, "").toLowerCase();
    const res = allAccounts.filter((acc) => {
      const str = `${acc?.name}(${acc?.mask})`;
      return str?.replace(/\s/g, "").toLowerCase().includes(formattedValue);
    });
    if (!res?.length) {
      setIsResultFound(false);
    }
    setFilteredData(
      res?.reduce?.((result, item) => {
        (result[item.subtypeId] = result[item.subtypeId] || []).push(
          item
        );
        return result;
      }, {})
    );
  };

  const onApply = (accounts) => {
    clearTimeout(Timeout);
    Timeout = null;
    Timeout = setTimeout(async () => {
      getTransactionsApi({
        page: 1,
        accounts,
      });
    }, 900);

    // setDropdownOpen(false);
  };

  const onChangeValues = (val = {}) => {
    setLocalSelected(val);
    setSelectedCategoryId(extractValuesByKey(val, "id"));
    onApply(extractValuesByKey(val, "id"));
  };


  const onClear = () => {
    setSelectedAccounts([]);
    getTransactionsApi({
      page: 1,
      accounts: [],
    });
    setDropdownOpen(false);
  };

  return (
    <div
      className={`relative inline-flex w-full max-w-full min-w-[200px]`}
      ref={dropdown}
    >
      <button
        ref={trigger}
        className={`inline-flex  items-center w-full justify-center text-sm font-medium leading-6 rounded-md px-3 h-10 border ${
          selectedAccounts?.length > 0 &&
          areArraysEqual(selectedAccounts, apiFilter)
            ? "border-indigo-500 shadow-sm  text-indigo-500 duration-150 ease-in-out bg-[#E4864205]"
            : selectedAccounts?.length > 0
            ? " text-indigo-300 border-indigo-300 dark:border-white"
            : " border-[#D0D5DD]  hover:border-slate-400  shadow-sm bg-white dark:bg-slate-800 text-slate-500 dark:text-slate-400 duration-150 ease-in-out"
        }`}
        aria-haspopup="true"
        onClick={() => setDropdownOpen(!dropdownOpen)}
        aria-expanded={dropdownOpen}
      >
        <div className={`flex items-center truncate justify-between w-full`}>
          <span
            className={`text-[#667085CC] truncate font-normal ${
              selectedAccounts?.length > 0 &&
              areArraysEqual(selectedAccounts, apiFilter)
                ? "text-indigo-500"
                : selectedAccounts?.length > 0
                ? " text-indigo-300"
                : ""
            }  `}
          >
            {selectedAccounts?.length > 0
              ? `${
                  allAccounts.find((acc) => acc?.id === selectedAccounts?.[0])
                    ?.name||""
                } ${
                  allAccounts.find((acc) => acc?.id === selectedAccounts?.[0])
                    ?.mask
                    ? `(${
                        allAccounts.find(
                          (acc) => acc?.id === selectedAccounts?.[0]
                        )?.mask||""
                      })`
                    : ""
                } ${
                  selectedAccounts?.length > 1
                    ? ` +${selectedAccounts?.length - 1}`
                    : ""
                }`
              : "Choose Account"}
          </span>
          <svg
            width="10"
            height="5"
            viewBox="0 0 10 5"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={`shrink-0 ml-1 stroke-[#667085CC] ${
              selectedAccounts?.length > 0 &&
              areArraysEqual(selectedAccounts, apiFilter)
                ? "stroke-indigo-500"
                : selectedAccounts?.length > 0
                ? " stroke-indigo-300"
                : ""
            } ${dropdownOpen ? "rotate-180" : ""} `}
          >
            <path
              d="M9 1L5.70707 3.7559C5.31818 4.08137 4.68182 4.08137 4.29293 3.7559L1 1"
              stroke="#667085"
              stroke-opacity="0.8"
              stroke-width="1.5"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
              className="stroke-current"
            />
          </svg>
        </div>
      </button>

      <Transition
        className={`origin-top-right z-10 absolute top-full min-w-44 w-full bg-white dark:bg-slate-800 border border-slate-200 dark:border-slate-700 pt-1.5 rounded shadow-lg overflow-hidden ${
          align === "right" ? "right-0" : "left-0"
        }`}
        show={dropdownOpen}
        enter="transition ease-out duration-200 transform"
        enterStart="opacity-0 -translate-y-2"
        enterEnd="opacity-100 translate-y-0"
        leave="transition ease-out duration-200"
        leaveStart="opacity-100"
        leaveEnd="opacity-0"
      >
        <div
          // onFocus={() => setDropdownOpen(true)}
          // onBlur={() => setDropdownOpen(false)}
          onClick={(e) => e.stopPropagation()}
        >
          <div className="flex justify-end pt-1 pr-1.5">
            <button
              className="text-slate-400 dark:text-slate-500 hover:text-slate-500 dark:hover:text-slate-400"
              onClick={(e) => {
                e.stopPropagation();
                setDropdownOpen(false);
              }}
            >
              <div className="sr-only">Close</div>
              <svg className="w-4 h-4 fill-current">
                <path d="M7.95 6.536l4.242-4.243a1 1 0 111.415 1.414L9.364 7.95l4.243 4.242a1 1 0 11-1.415 1.415L7.95 9.364l-4.243 4.243a1 1 0 01-1.414-1.415L6.536 7.95 2.293 3.707a1 1 0 011.414-1.414L7.95 6.536z" />
              </svg>
            </button>
          </div>
          <div className="relative flex-1 px-3 mb-2">
            <input
              type="text"
              placeholder="Search..."
              className={`form-input text-indigo-600  placeholder:!text-indigo-600 !border-indigo-600  my-2 pl-8 w-full inline-block`}
              value={searchValue}
              onChange={handleSearch}
              autoFocus
              ref={searchInput}
              onClick={(e) => e.stopPropagation()}
            />
            <svg
              className="absolute left-3 top-[50%] -translate-y-[50%] pl-2 w-6 h-6"
               width="16"
              height="17"
              viewBox="0 0 16 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14 14.5L11.6667 12.1667M13.3333 8.16667C13.3333 11.2963 10.7963 13.8333 7.66667 13.8333C4.53705 13.8333 2 11.2963 2 8.16667C2 5.03705 4.53705 2.5 7.66667 2.5C10.7963 2.5 13.3333 5.03705 13.3333 8.16667Z"
                stroke="#E48642"
                stroke-width="1.2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
          <div className="max-h-60 overflow-auto scrollbar">
          {filteredData?.map(
              (group) =>
                group?.categories?.length > 0 && (
                  <div
                    key={group.id}
                    className="cursor-default	mb-4"
                    onClick={(e) => e.stopPropagation()}
                  >
                    <label className="whitespace-nowrap font-semibold text-[10px] text-[#515A6C] leading-5 uppercase flex items-center pt-1 px-3 mb-1.5 filter-label">
                      <input
                        type="checkbox"
                        checked={
                          localSelected?.[group?.id]?.length ===
                          group?.categories?.length
                        }
                        className="form-checkbox mr-2"
                        onChange={() => {
                          if (
                            localSelected?.[group?.id]?.length ===
                            group?.categories?.length
                          ) {
                            onChangeValues({
                              ...localSelected,
                              [group.id]: [],
                            });
                          } else {
                            onChangeValues({
                              ...localSelected,
                              [group.id]: group?.categories,
                            });
                          }
                        }}
                      />
                      {group?.name}
                      <svg
                        width="10"
                        height="5"
                        viewBox="0 0 10 5"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className={`w-4 shrink-0 text-current text-slate-400 ml-2 cursor-pointer ${
                          !collapsedIds?.filter((id) => id === group?.id)
                            ?.length > 0
                            ? "rotate-180"
                            : ""
                        } `}
                        onClick={(e) => {
                          e.stopPropagation();
                          e.preventDefault();
                          if (
                            collapsedIds?.filter((id) => id === group?.id)
                              ?.length > 0
                          ) {
                            setCollapsedIds(
                              collapsedIds?.filter((id) => id !== group?.id)
                            );
                          } else {
                            setCollapsedIds([...collapsedIds, group?.id]);
                          }
                        }}
                      >
                        <path
                          d="M9 1L5.70707 3.7559C5.31818 4.08137 4.68182 4.08137 4.29293 3.7559L1 1"
                          stroke="#667085"
                          stroke-opacity="0.8"
                          stroke-width="1.5"
                          stroke-miterlimit="10"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          className="stroke-current"
                        />
                      </svg>
                    </label>
                    {!collapsedIds?.filter((id) => id === group?.id)?.length >
                      0 &&
                      group?.categories?.map((category) => (
                        <div key={category.id} className="ml-2 cursor-pointer">
                          <label className="whitespace-nowrap  text-[13px] text-[#667085] hover:text-slate-800 dark:hover:text-slate-200 flex items-center py-1 px-3 cursor-pointer ml-2 font-normal leading-6">
                            <input
                              type="checkbox"
                              checked={
                                localSelected?.[group?.id]?.length ===
                                  group?.categories?.length ||
                                localSelected?.[group?.id]?.filter(
                                  (ct) => ct?.id === category?.id
                                )?.length > 0
                              }
                              onChange={(e) => {
                                e.stopPropagation();
                                if (localSelected?.hasOwnProperty(group?.id)) {
                                  if (
                                    localSelected?.[group?.id]?.filter(
                                      (ct) => ct?.id === category?.id
                                    )?.length > 0
                                  ) {
                                    onChangeValues({
                                      ...localSelected,
                                      [group?.id]: localSelected?.[
                                        group?.id
                                      ]?.filter(
                                        (ct) => ct?.id !== category?.id
                                      ),
                                    });
                                  } else {
                                    onChangeValues({
                                      ...localSelected,
                                      [group?.id]: [
                                        ...localSelected?.[group?.id],
                                        category,
                                      ],
                                    });
                                  }
                                } else {
                                  onChangeValues({
                                    ...localSelected,
                                    [group.id]: [category],
                                  });
                                }
                              }}
                              className="form-checkbox mr-2"
                            />
                            {category?.name}
                          </label>
                        </div>
                      ))}
                  </div>
                )
            )}
            {!isResultFound ? (
              <span className="font-medium block text-sm text-slate-600 dark:text-slate-300 text-center w-full">
                No Data Found
              </span>
            ) : (
              ""
            )}
          </div>
          <div className="py-2 px-3 border-t border-slate-200 dark:border-slate-700 bg-slate-50 dark:bg-slate-700/20">
            <ul className="flex items-center justify-between">
              <li>
                <button
                  onClick={onClear}
                  className="btn-xs bg-white dark:bg-slate-800 border-slate-200 dark:border-slate-700 hover:border-slate-300 dark:hover:border-slate-600 text-slate-500 dark:text-slate-300 hover:text-slate-600 dark:hover:text-slate-200"
                >
                  Clear
                </button>
              </li>
              {/* <li>
                <button
                  className="btn-xs bg-indigo-500 hover:bg-indigo-600 text-white"
                  onClick={onApply}
                >
                  Apply
                </button>
              </li> */}
            </ul>
          </div>
        </div>
      </Transition>
    </div>
  );
};

export default AccountsFilter;
