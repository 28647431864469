import React from 'react';
import ModalBasic from '../../components/ModalBasic';

const ActivationModal = ({ open, setOpen, handleActivateRule, activeRule }) => (
  <ModalBasic
    title="Activate Rule"
    smallModal
    modalOpen={open}
    setModalOpen={setOpen}
  >
    <p className="text-base text-left text-slate-600 py-6 px-5">
      Should this rule apply to all past transactions?
    </p>
    {/* Modal footer */}
    <div className="flex flex-wrap justify-end space-x-2 px-5 py-4 border-t border-[#D0D5DD]">
      <button
        type="button"
        className="py-2.5 px-[1.875rem] h-11 w-[7rem] text-center flex justify-center items-center text-base leading-6 font-base rounded-[0.313rem] bg-white text-indigo-500 border border-indigo-500"
        onClick={() => {
          setOpen(false);
          handleActivateRule(activeRule?.id, true, false);
        }}
      >
        No
      </button>
      <button
        type="button"
        className="py-2.5 px-[1.875rem] h-11 w-[7rem] bg-indigo-500 text-center text-white text-base leading-6 font-base rounded-[0.313rem] disabled:cursor-not-allowed"
        onClick={() => {
          setOpen(false);
          handleActivateRule(activeRule?.id, true, true);
        }}
      >
        <span>Yes</span>
      </button>
    </div>
  </ModalBasic>
);

export default ActivationModal;
