import React from 'react';

const Tabs = ({ activeTab, setActiveTab, AUTOMATION_TAB, TEMPLATES_TAB }) => (
  <div className="flex border-b-[2px] border-[#D0D5DD] gap-x-3 sm:gap-8 mb-6 mt-8 flex-wrap">
    <div
      onClick={() => setActiveTab(AUTOMATION_TAB)}
      className={`text-sm border-b-[3px] translate-y-[2px] border-transparent  cursor-pointer text-[#667085] pb-3
                  ${
                    activeTab === AUTOMATION_TAB &&
                    ' !border-indigo-500  font-medium !text-[#101828]'
                  } `}
      role="button"
      tabIndex="0"
    >
      Automations
    </div>
    <div
      onClick={() => setActiveTab(TEMPLATES_TAB)}
      className={`text-sm border-b-[3px] translate-y-[2px] border-transparent  cursor-pointer text-[#667085] pb-3
                  ${
                    activeTab === TEMPLATES_TAB &&
                    ' !border-indigo-500  font-medium !text-[#101828]'
                  } `}
      role="button"
      tabIndex="0"
    >
      Templates
    </div>
  </div>
);

export default Tabs;
