import React, { useEffect, useState, useMemo } from 'react';
import Flatpickr from 'react-flatpickr';
import moment from 'moment';
import { useSelector } from 'react-redux';
import CategoriesDropdown from '../transactions/CategoriesDropdown';
import { dateCategoryList, formatNumber } from '../../utils/Utils';
import {
  getTopIncomeCategories,
  getMonthlyIncome,
  getIncomeBreakdown,
} from '../../API/backend_helper';
import LineChartInsights from '../../charts/LineChartInsights';
import Tooltip from '../../components/Tooltip';

const IncomeAnalysis = ({
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  handleFlyoutOpen,
  selectedRange,
  setSelectedRange,
  generateMonthHeaders,
  teamCurrency,
  defaultStart,
  defaultEnd,
}) => {
  const { team } = useSelector((state) => state.Team);

  const [isLoading, setIsLoading] = useState(false);
  const dateFormats = useMemo(() => dateCategoryList(), []);
  const [topIncomeCategories, setTopIncomeCategories] = useState([]);
  const [monthlyIncome, setMonthlyIncome] = useState([]);
  const [incomeBreakdown, setIncomeBreakdown] = useState({});

  const onDateChange = async (start, end, rangeChange = false) => {
    // eslint-disable-next-line no-use-before-define
    loadData(start, end);
    setStartDate(start);
    setEndDate(end);
    if (!rangeChange) {
      setSelectedRange('custom');
    }
  };

  const options = useMemo(
    () => ({
      mode: 'range',
      static: true,
      monthSelectorType: 'static',
      allowInput: true,
      dateFormat:
        team?.defaults?.dateFormat === 'dd/mm/yyyy' ? 'd/m/Y' : 'm/d/Y',
      prevArrow:
        '<svg class="fill-current" width="7" height="11" viewBox="0 0 7 11"><path d="M5.4 10.8l1.4-1.4-4-4 4-4L5.4 0 0 5.4z" /></svg>',
      nextArrow:
        '<svg class="fill-current" width="7" height="11" viewBox="0 0 7 11"><path d="M1.4 10.8L0 9.4l4-4-4-4L1.4 0l5.4 5.4z" /></svg>',
      onOpen: () => {},
      onClose: (selectedDates) => {
        onDateChange(selectedDates?.[0], selectedDates?.[1]);
      },
      onChange: (selectedDates) => {
        if (selectedDates?.length > 0) {
          setStartDate(selectedDates?.[0]);
          setEndDate(selectedDates?.[1]);
        }
      },
    }),
    [team],
  );

  const fetchIncomeBreakdown = async (start, end) => {
    const res = await getIncomeBreakdown({
      startDate: start ? moment(start).format('YYYY-MM-DD') : '',
      endDate: end ? moment(end).format('YYYY-MM-DD') : '',
    });
    setIncomeBreakdown(res?.data);
  };

  const fetchMonthlyIncome = async (start, end) => {
    const res = await getMonthlyIncome({
      startDate: start ? moment(start).format('YYYY-MM-DD') : '',
      endDate: end ? moment(end).format('YYYY-MM-DD') : '',
    });
    const formattedData = Object.entries(res?.data).map(([month, amount]) => ({
      month: month.substring(0, 7),
      amount,
    }));
    setMonthlyIncome(formattedData);
  };

  const fetchTopIncomeCategories = async (start, end) => {
    const res = await getTopIncomeCategories({
      startDate: start ? moment(start).format('YYYY-MM-DD') : '',
      endDate: end ? moment(end).format('YYYY-MM-DD') : '',
    });
    setTopIncomeCategories(Object.values(res?.data));
  };

  const handleRangeSelection = async (id) => {
    const rangeChange = true;
    const range = dateFormats
      .flatMap((group) => group.categories)
      .find((category) => category.id === id);
    onDateChange(range?.startDate, range?.endDate, rangeChange);
  };

  const loadData = async (start, end) => {
    setIsLoading(true);
    await fetchIncomeBreakdown(start, end);
    await fetchMonthlyIncome(start, end);
    await fetchTopIncomeCategories(start, end);
    setIsLoading(false);
  };

  useEffect(() => {
    loadData(startDate, endDate);
  }, []);

  return isLoading ? (
    <div className="flex flex-col gap-2 items-center pb-2 justify-center mt-8">
      <svg
        width="29"
        height="29"
        viewBox="0 0 29 29"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="animate-spin w-10 h-10 fill-current shrink-0"
      >
        <path
          d="M14.5 2.41699V7.25033"
          stroke="#E48642"
          strokeWidth="2.55882"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.5 21.749V26.5824"
          stroke="#E48642"
          strokeWidth="2.55882"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.95703 5.95703L9.37662 9.37662"
          stroke="#E48642"
          strokeWidth="1.58955"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M19.623 19.6211L23.0426 23.0407"
          stroke="#E48642"
          strokeWidth="1.58955"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2.41699 14.5H7.25033"
          stroke="#E48642"
          strokeWidth="1.58955"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M21.749 14.5H26.5824"
          stroke="#E48642"
          strokeWidth="1.58955"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.95703 23.0407L9.37662 19.6211"
          stroke="#E48642"
          strokeWidth="1.58955"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M19.623 9.37662L23.0426 5.95703"
          stroke="#E48642"
          strokeWidth="1.58955"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
      <p className="text-slate-600 font-medium text-[0.875rem] text-center">
        Fetching data, please wait...
      </p>
    </div>
  ) : (
    <>
      <div className="flex flex-col sm:flex-row items-center gap-5 w-full mt-8 sm:mt-0">
        <div className="w-full min-w-[100px] max-w-[200px]">
          <CategoriesDropdown
            allCategories={[
              ...dateFormats,
              {
                name: 'Custom',
                categories: [{ name: 'Custom', id: 'custom' }],
              },
            ]}
            selectedCategoryId={selectedRange}
            setSelectedCategoryId={(value) => {
              setSelectedRange(value);
              handleRangeSelection(value);
            }}
            isSetCategoryStyle
            height="h-10"
            type="date"
          />
        </div>
        <div className="relative">
          <Flatpickr
            className={`form-input border-[#D0D5DD] hover:border-slate-400 pl-3 pr-12
                    !leading-6 py-0 h-10 placeholder:text-[#667085CC] text-[#667085CC]
                      hover:text-slate-600 min-w-[270px] cursor-pointer
                      ${
                        startDate || endDate
                          ? '!border-indigo-500   placeholder:!text-indigo-500 !text-indigo-500 bg-[#E4864205]'
                          : ''
                      }`}
            options={options}
            placeholder="Select Custom Date Range"
            value={[startDate, endDate]}
            autoComplete="off"
          />
          {(startDate !== defaultStart || endDate !== defaultEnd) && (
            <button
              onClick={() => {
                setStartDate(defaultStart);
                setEndDate(defaultEnd);
                onDateChange(defaultStart, defaultEnd);
              }}
              className="absolute top-0 right-1  h-full group p-2 flex items-center"
              type="button"
            >
              <svg
                className="w-4 h-4 fill-indigo-300 group-hover:fill-indigo-500 pointer-events-none"
                viewBox="0 0 16 16"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="m7.95 6.536 4.242-4.243a1 1 0 1 1 1.415 1.414L9.364 7.95l4.243 4.242a1 1 0 1 1-1.415 1.415L7.95 9.364l-4.243 4.243a1 1 0 0 1-1.414-1.415L6.536 7.95 2.293 3.707a1 1 0 0 1 1.414-1.414L7.95 6.536Z" />
              </svg>
            </button>
          )}
        </div>
      </div>
      <div className="w-full flex flex-col sm:flex-row items-start self-stretch gap-8 mt-6">
        <div className="flex flex-col p-6 justify-end items-center gap-6 flex-grow flex-shrink-0 basis-0 self-stretch rounded-xl border border-[#D0D5DD] bg-white">
          <p className="w-full text-left flex items-start self-stretch text-slate-600 text-[1.375rem] leading-9 font-medium">
            Sales Income
          </p>
          <p className="w-full text-black text-right text-[2.25rem] leading-9 font-medium ">
            {teamCurrency ? teamCurrency?.symbol : '$'}
            {formatNumber(incomeBreakdown?.salesIncome)}
          </p>
        </div>
        <div className="flex flex-col p-6 justify-end items-center gap-6 flex-grow flex-shrink-0 basis-0 self-stretch rounded-xl border border-[#D0D5DD] bg-white">
          <p className="w-full text-left flex items-start self-stretch text-slate-600 text-[1.375rem] leading-9 font-medium">
            Other Income
          </p>
          <p className="w-full text-black text-right text-[2.25rem] leading-9 font-medium ">
            {teamCurrency ? teamCurrency?.symbol : '$'}
            {formatNumber(incomeBreakdown?.otherIncome)}
          </p>
        </div>
        <div className="flex flex-col p-6 justify-end items-center gap-6 flex-grow flex-shrink-0 basis-0 self-stretch rounded-xl border border-[#D0D5DD] bg-white">
          <p className="w-full text-left flex items-start self-stretch text-slate-600 text-[1.375rem] leading-9 font-medium">
            Total Income
          </p>
          <p className="w-full text-black text-right text-[2.25rem] leading-9 font-medium ">
            {teamCurrency ? teamCurrency?.symbol : '$'}
            {formatNumber(incomeBreakdown?.total)}
          </p>
        </div>
      </div>
      {/* income by category table */}
      <div className="flex w-full flex-col items-center gap-5 mt-6">
        <div className="w-full flex flex-col items-center gap-6 shrink-0 self-stretch rounded-xl border border-[#D0D5DD] bg-white p-6">
          <div className="w-full flex items-start self-stretch text-slate-600 text-[1.375rem] font-medium leading-[2.375rem]">
            Income By Category
          </div>
          <div className="w-full items-start flex overflow-x-auto scrollbar">
            <table className="min-w-max w-full flex flex-col items-start">
              <thead className="flex w-full justify-around items-center gap-11 rounded-[0.313rem] shadow-md bg-white h-[3.5rem] mb-1">
                <th className="text-slate-600 text-left text-[14px] font-medium leading-[1.125rem] w-[135px] ml-4 flex-shrink-0 flex-grow-0 ">
                  Category
                </th>
                {generateMonthHeaders(startDate, endDate).map(
                  (month, index) => (
                    <th
                      key={index}
                      className="text-slate-600 text-left text-[14px] font-medium leading-[1.125rem] w-[75px] flex-shrink-0 flex-grow-0 "
                    >
                      {month}
                    </th>
                  ),
                )}
                <th className="text-slate-600 text-left text-[14px] font-bold leading-[1.125rem] w-[75px] flex-shrink-0 flex-grow-0 ">
                  Total
                </th>
              </thead>
              {!isLoading &&
                topIncomeCategories &&
                Object.values(topIncomeCategories).map((category) => (
                  <tr
                    key={category?.id}
                    className="flex w-full justify-around items-center gap-11 bg-white h-[3.5rem] border-b border-[#D0D5DD] hover:bg-[#a0cd85] hover:bg-opacity-5 cursor-pointer"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleFlyoutOpen(category, startDate, endDate);
                    }}
                  >
                    <td className="whitespace-nowrap text-slate-600 text-left text-[14px] font-medium leading-[1.125rem] w-[135px] ml-4 flex-shrink-0 flex-grow-0 ">
                      <Tooltip
                        content={category.name}
                        tooltipShow={category.name.length > 20}
                      >
                        {category.name.length > 20
                          ? `${category.name.substring(0, 20)}...`
                          : category.name}
                      </Tooltip>
                    </td>
                    {generateMonthHeaders(startDate, endDate).map(
                      (headerMonth, index) => (
                        <td
                          key={index}
                          className="text-slate-600 text-left text-[14px] font-medium leading-[1.125rem] w-[75px] flex-shrink-0 flex-grow-0 "
                        >
                          {category.months &&
                          Object.keys(category.months).some(
                            (month) =>
                              moment(month).format('MMM YYYY') === headerMonth,
                          )
                            ? `${
                                teamCurrency ? teamCurrency?.symbol : '$'
                              }${formatNumber(
                                category.months[
                                  moment(headerMonth, 'MMM YYYY').format(
                                    'YYYY-MM-DD',
                                  )
                                ].toFixed(2),
                              )}`
                            : '-'}
                        </td>
                      ),
                    )}
                    <td className="text-slate-600 text-left text-[14px] leading-[1.125rem] w-[75px] flex-shrink-0 flex-grow-0 font-bold">
                      {teamCurrency ? teamCurrency?.symbol : '$'}
                      {formatNumber(category.total.toFixed(2))}
                    </td>
                  </tr>
                ))}
              {Object.values(topIncomeCategories).length > 0 ? (
                <tr className="flex w-full justify-around items-center gap-11 bg-white h-[3.5rem] border-b border-[#D0D5DD]">
                  <td className="text-slate-600 text-left text-[14px] font-bold leading-[1.125rem] w-[135px] ml-4 flex-shrink-0 flex-grow-0">
                    Total
                  </td>
                  {generateMonthHeaders(startDate, endDate).map(
                    (month, index) => (
                      <td
                        key={index}
                        className="text-slate-600 text-left text-[14px] font-bold leading-[1.125rem] w-[75px] flex-shrink-0 flex-grow-0"
                      >
                        {teamCurrency ? teamCurrency?.symbol : '$'}
                        {formatNumber(
                          Object.values(topIncomeCategories)
                            .reduce(
                              (acc, category) =>
                                acc +
                                (category.months &&
                                category.months[
                                  moment(month, 'MMM YYYY').format('YYYY-MM-DD')
                                ]
                                  ? category.months[
                                      moment(month, 'MMM YYYY').format(
                                        'YYYY-MM-DD',
                                      )
                                    ]
                                  : 0),
                              0,
                            )
                            .toFixed(2),
                        )}
                      </td>
                    ),
                  )}
                  <td className="text-slate-600 text-left text-[14px] font-bold leading-[1.125rem] w-[75px] flex-shrink-0 flex-grow-0">
                    {teamCurrency ? teamCurrency?.symbol : '$'}
                    {formatNumber(
                      Object.values(topIncomeCategories)
                        .reduce((total, category) => total + category.total, 0)
                        .toFixed(2),
                    )}
                  </td>
                </tr>
              ) : (
                <tr className="flex w-full justify-around items-center gap-11 bg-white h-[3.5rem] ">
                  <td
                    colSpan={3}
                    className="text-slate-500 text-center text-[14px] "
                  >
                    Records not found
                  </td>
                </tr>
              )}
            </table>
          </div>
        </div>
      </div>
      {/* income by month chart */}
      <div className="flex flex-col mt-6 w-full h-[40dvh] p-6 border border-[#D0D5DD] rounded-xl gap-6">
        <div className="w-full flex items-start self-stretch text-slate-600 text-[1.375rem] font-medium leading-[2.375rem]">
          Total Income By Month
        </div>
        {monthlyIncome?.length > 0 ? (
          <LineChartInsights
            data={monthlyIncome}
            width={100}
            height={50}
            dateRange={{
              start: moment(startDate).format('YYYY-MM-DD'),
              end: moment(endDate).format('YYYY-MM-DD'),
            }}
            teamCurrency={teamCurrency}
          />
        ) : (
          <p className="text-slate-500 text-center text-[14px]">
            {' '}
            No Data Found
          </p>
        )}
      </div>
    </>
  );
};

export default IncomeAnalysis;
