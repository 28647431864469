import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { EmptyInfoIcon } from "./svg";

const MatchingTransactionWarning = ({ number, type }) => {
    const navigate = useNavigate();
  return (
    <div className="w-full p-4 flex items-center justify-center gap-3 rounded-[5px] border border-[#D0D5DD] bg-[#D0D5DD1A] h-[4.688rem] -mb-7 mt-2">
      <EmptyInfoIcon />
      <div className="flex flex-col justify-center items-start gap-3 w-full">
        <div className="flex flex-col items-start gap-2 self-stretch">
          <p className="text-slate-600 text-[0.875rem] font-medium leading-5">
            {number} Possible {type} Transactions Found
          </p>
        </div>
      </div>
      <button
        type="button"
        className="flex p-1 sm:py-4 sm:px-[1.875rem] items-center justify-center rounded-[5px] bg-slate-600 text-white sm:leading-6 text-[14px] sm:text-[1rem] h-[2.688rem] sm:whitespace-nowrap"
        onClick={() => navigate("/suggestions/", { state: { activeTab: type } })}
      >
        View {type} Transactions
      </button>
    </div>
  );
};

export default MatchingTransactionWarning;
