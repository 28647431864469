import React, { useEffect, useRef, useState } from 'react';
import useClickOutside from '../../utils/useClickOutside';

const CustomersBox = ({
  isPreview,
  selectedColor,
  setCustomerModalOpen,
  fontFamily,
  customers,
  selectedCustomer,
  setSelectedCustomer,
  setInvoiceSalesRep,
}) => {
  const showCustomersRef = useRef();

  const [showCustomers, setShowCustomers] = useState(false);
  const [filteredCustomers, setFilteredCustomers] = useState([]);

  const onCustomerFilter = (value) => {
    const v = value?.toLowerCase();
    const filtered = customers?.filter(
      (c) =>
        c?.customer?.toLowerCase()?.includes(v) ||
        c?.contacts?.[0]?.firstName?.toLowerCase()?.includes(v) ||
        c?.contacts?.[0]?.lastName?.toLowerCase()?.includes(v),
    );
    setFilteredCustomers(filtered);
  };

  useClickOutside(showCustomersRef, () => {
    if (showCustomers) {
      setShowCustomers(false);
    }
  });

  useEffect(() => {
    if (customers?.length > 0) {
      setFilteredCustomers(customers);
    }
  }, [customers]);

  return selectedCustomer || isPreview ? (
    <div
      className="text-sm text-[#667085CC] space-y-1 leading-tight"
      style={isPreview && fontFamily ? { fontFamily: fontFamily || '' } : {}}
    >
      <h3
        className="font-medium text-[#667085] mb-3"
        style={selectedColor && isPreview ? { color: selectedColor } : {}}
      >
        Bill To
      </h3>
      <p>
        {selectedCustomer?.customer
          ? selectedCustomer?.customer
          : `${selectedCustomer?.contacts?.[0]?.firstName} ${selectedCustomer?.contacts?.[0]?.lastName}`}
      </p>
      <p>
        {selectedCustomer?.billingAddress?.street}{' '}
        {selectedCustomer?.billingAddress?.street2
          ? `, ${selectedCustomer?.billingAddress?.street2}`
          : ''}
      </p>
      <p>
        {selectedCustomer?.billingAddress?.city}
        {selectedCustomer?.billingAddress?.city && ','}{' '}
        {selectedCustomer?.billingAddress?.state}{' '}
        {selectedCustomer?.billingAddress?.zipCode}
      </p>
      <p>{selectedCustomer?.billingAddress?.country}</p>
      {selectedCustomer?.taxNumber && (
        <p className="!mt-4">Tax Number: {selectedCustomer?.taxNumber}</p>
      )}
      <p className={!selectedCustomer?.taxNumber ? '!mt-4' : ''}>
        {selectedCustomer?.contacts?.[0]?.email}
      </p>
      <p>{selectedCustomer?.contacts?.[0]?.phone}</p>
      {!isPreview && (
        <div className="flex gap-4 text-indigo-500 underline">
          <button onClick={() => setCustomerModalOpen(true)} type="button">
            {' '}
            Edit {selectedCustomer?.firstName} {selectedCustomer?.lastName}
          </button>
          <button
            onClick={() => {
              setSelectedCustomer(null);
              setInvoiceSalesRep(null);
            }}
            type="button"
          >
            Choose a Different Customer
          </button>
        </div>
      )}
    </div>
  ) : (
    <div
      className=" shadow-button h-full  border border-indigo-500 border-dashed rounded-[10px]"
      onClick={() => {
        setShowCustomers(true);
        // if (customers.length === 0) {
        //   setCustomerModalOpen(true);
        // }
      }}
      role="button"
      tabIndex="0"
    >
      {!showCustomers ? (
        <div className=" cursor-pointer flex flex-col justify-center items-center h-full w-full">
          <svg
            width="31"
            height="30"
            viewBox="0 0 31 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M23.375 24.375H18.375"
              stroke="#E48642"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M20.875 26.875V21.875"
              stroke="#E48642"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M15.4502 13.5875C15.3252 13.575 15.1752 13.575 15.0377 13.5875C12.0627 13.4875 9.70024 11.05 9.70024 8.05C9.68774 4.9875 12.1752 2.5 15.2377 2.5C18.3002 2.5 20.7877 4.9875 20.7877 8.05C20.7877 11.05 18.4127 13.4875 15.4502 13.5875Z"
              stroke="#E48642"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M15.2373 27.2641C12.9623 27.2641 10.6998 26.6891 8.9748 25.5391C5.9498 23.5141 5.9498 20.2141 8.9748 18.2016C12.4123 15.9016 18.0498 15.9016 21.4873 18.2016"
              stroke="#E48642"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <p className="text-base font-medium text-indigo-500">Add Customer</p>
        </div>
      ) : (
        <div
          className="w-full pt-4 pb-0 flex flex-col h-full"
          ref={showCustomersRef}
        >
          <div className="relative px-4  mb-1 w-full">
            <input
              type="text"
              placeholder="Search..."
              className={`form-input text-indigo-600  placeholder:!text-indigo-600 !border-indigo-600  my-2 pl-8 w-full inline-block  `}
              autoFocus
              onClick={(e) => e.stopPropagation()}
              onChange={(e) => onCustomerFilter(e.target.value)}
            />
            <svg
              className="absolute left-4 top-[50%] -translate-y-[50%] pl-2 w-6 h-6"
              width="16"
              height="17"
              viewBox="0 0 16 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14 14.5L11.6667 12.1667M13.3333 8.16667C13.3333 11.2963 10.7963 13.8333 7.66667 13.8333C4.53705 13.8333 2 11.2963 2 8.16667C2 5.03705 4.53705 2.5 7.66667 2.5C10.7963 2.5 13.3333 5.03705 13.3333 8.16667Z"
                stroke="#E48642"
                strokeWidth="1.2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
          <div className="flex flex-col px-4 space-y-2 max-h-[200px] overflow-auto scrollbar">
            {filteredCustomers?.length > 0 ? (
              <>
                {filteredCustomers.map((c, index) => (
                  <p
                    key={index}
                    onClick={() => {
                      setSelectedCustomer(c);
                      if (c?.salesRepId) {
                        setInvoiceSalesRep(c?.salesRepId);
                      }
                    }}
                    className="text-[#667085] font-medium w-fit cursor-pointer"
                  >
                    {c?.customer
                      ? c?.customer
                      : `${c?.contacts?.[0]?.firstName} ${c?.contacts?.[0]?.lastName}`}
                  </p>
                ))}
              </>
            ) : (
              <p className="text-[#667085] font-medium w-fit cursor-pointer">
                No Customers Found
              </p>
            )}
          </div>

          <button
            type="button"
            className="btn h-10 border-b-0 border-x-0 !border-t border-[#D0D5DD]  gap-2 text-indigo-500 text-base w-full cursor-pointer"
            onClick={() =>
              // setInvoiceRows([...invoiceRows, { ...TABLE_ROW_SCHEMA }])
              setCustomerModalOpen(true)
            }
          >
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
            >
              <path
                d="M8.00004 14.6673C11.6667 14.6673 14.6667 11.6673 14.6667 8.00065C14.6667 4.33398 11.6667 1.33398 8.00004 1.33398C4.33337 1.33398 1.33337 4.33398 1.33337 8.00065C1.33337 11.6673 4.33337 14.6673 8.00004 14.6673Z"
                stroke="#E48642"
                strokeWidth="1.2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M5.33337 8H10.6667"
                stroke="#E48642"
                strokeWidth="1.2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M8 10.6663V5.33301"
                stroke="#E48642"
                strokeWidth="1.2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            Create A New Customer
          </button>
        </div>
      )}
    </div>
  );
};

export default CustomersBox;
