import React from 'react';
import { Link } from 'react-router-dom';
import InvoiceImage from '../../images/payment.png';

const InvoicesBanner = () => (
  <div className="px-5 flex justify-between items-center py-3 rounded-[12px] w-full bg-[#F1F8EC] mb-4">
    <div className="flex gap-4 items-center">
      <img className="h-12" src={InvoiceImage} alt="invoice-image" />
      <div>
        <h3 className="text-[24px] font-semibold text-[#101828] leading-tight">
          Finish Setting Up Online Payments
        </h3>
        <p className="text-[#101828] leading-tight">
          Let’s Get You Ready To Get Paid. Connect Your Payment Account To Get
          Paid.
        </p>
      </div>
    </div>
    <Link to="/settings/payment-setup">
      <button
        type="button"
        className="h-11 px-4  bg-indigo-500 text-white rounded-[5px] shadow-sm"
      >
        Set up payments
      </button>
    </Link>
  </div>
);

export default InvoicesBanner;
