import React, { useContext, useEffect, useState } from "react";
import {
  getInternalVendors,
  createInternalVendor,
  updateInternalVendor,
  deleteInternalVendor,
  getDefaultAccounts,
  uploadInternalVendorLogo,
} from "../../../API/backend_helper";
import PaginationClassic from "../../../components/PaginationClassic";
import emptyCustomerImg from "../../../images/custom/empty_customer.svg";
import TextInputDash from "../../../components/TextInputDash";
import SearchForm from "../../../partials/actions/SearchForm";
import InternalVendorForm from "./InternalVendorForm";
import DeleteInternalVendorModal from "./DeleteInternalVendorModal";
import CategoriesDropdown from "../../transactions/CategoriesDropdown";
import { transformAccounts } from "../../../utils/Utils";
import InternalVendorFlyout from "./InternalVendorFlyout";

let searchTimeout = null;
const InternalVendors = ({activeVendor, setActiveVendor, flyoutOpen, setFlyoutOpen}) => {
  const [isLoading, setIsLoading] = useState(true);

  const [vendors, setVendors] = useState([]);
  const [localLimit, setLocalLimit] = useState(50);
  const [modalOpen, setModalOpen] = useState(false);
  const [formOpen, setFormOpen] = useState(false);
  const [addEditLoader, setAddEditLoader] = useState(false);
  const [defaultAccounts, setDefaultAccounts] = useState([]);
  const [allAccounts, setAllAccounts] = useState([]);

  const [errMessage, setErrMessage] = useState("");

  document.title = "COUNT | Internal Vendors";

  const getData = async ({
    limit = localLimit,
    page = vendors?.page,
    search = "",
    loading = false,
  }) => {
    if (loading) {
      setIsLoading(true);
    }
    try {
      const res = await getInternalVendors({
        page: page,
        search,
        limit,
      });
      setVendors(res?.data);
      const accountRes = await getDefaultAccounts({ limit: 500 });
      setAllAccounts(accountRes?.data?.records);
      setDefaultAccounts(
        transformAccounts(accountRes?.data?.records, "allAccounts")
      );
      if (loading) {
        setIsLoading(false);
      }
    } catch (e) {
      if (loading) {
        setIsLoading(false);
      }
      console.log("error", e);
    }
  };

  const handleAddVendor = async (data) => {
    setAddEditLoader(true);
    try {
      const response = await createInternalVendor(data);
      getData({});
      setAddEditLoader(false);
      return response;
    } catch (err) {
      setAddEditLoader(false);
      console.log("err", err);
    }
  };

  const handleUpdateVendor = async (data, id) => {
    setAddEditLoader(true);
    try {
      await updateInternalVendor(data, id);
      getData({});
      setActiveVendor(null);
      setAddEditLoader(false);
    } catch (err) {
      console.log("err", err);
      setAddEditLoader(false);
    }
  };

  const handleDeleteVendor = async (vendor) => {
    try {
      await deleteInternalVendor(vendor.id);
      getData({});
      setActiveVendor(null);
      setModalOpen(false);
    } catch (err) {
      if (err.message) {
        setErrMessage(JSON.parse(err.message));
      } else {
        setModalOpen(false);
      }
    }
  };

  const handleUploadLogo = async (data, id) => {
    try {
      await uploadInternalVendorLogo(data, id);
      getData({});
      setModalOpen(false);
      setAddEditLoader(false);
      setActiveVendor(null);
    } catch (err) {
      console.log("err", err);
    }
  };

  useEffect(() => {
    getData({ loading: true });
  }, []);

  const { page, totalRecords, records } = vendors;

  return (
    <>
      <DeleteInternalVendorModal
        isOpen={modalOpen}
        setIsOpen={setModalOpen}
        errMessage={errMessage}
        activeVendor={activeVendor}
        handleDeleteVendor={handleDeleteVendor}
      />
      <InternalVendorForm
        setActiveVendor={setActiveVendor}
        activeVendor={activeVendor}
        handleUpdateVendor={handleUpdateVendor}
        handleAddVendor={handleAddVendor}
        setIsOpen={setFormOpen}
        isOpen={formOpen}
        addEditLoader={addEditLoader}
        handleUploadLogo={handleUploadLogo}
      />
      <main className="grow pb-4 w-full max-w-9xl mx-auto">
        {/* Content */}
        <div className=" px-4 sm:px-6 lg:px-4 py-8 pt-0 w-full max-w-9xl mx-auto h-full flex flex-col">
          {/* Page header */}
          <div className={`flex justify-between mb-7`}>
            <h1 className="text-2xl  text-[#101828B2] dark:text-slate-100 font-medium">
              Internal Vendors
            </h1>

            <div className="sm:flex flex-row gap-4 items-center">
              {/* <SearchForm
                  placeholder="Search"
                  searchVal={searchVal}
                  setSearchVal={onSearch}
                  transaction
                /> */}
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  setActiveVendor(null);
                  setFormOpen(true);
                }}
                className="bg-indigo-500 h-10 border border-indigo-500 rounded-[0.313rem] px-[1.125rem] py-2.5 shadow text-white text-base leading-6 flex items-center justify-center gap-2 font-normal"
              >
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  onClick={() => setFormOpen(true)}
                >
                  <path
                    d="M10 4.16699V15.8337M4.16669 10.0003H15.8334"
                    stroke="white"
                    strokeWidth="1.66667"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                Add Vendor
              </button>
            </div>
          </div>

          {isLoading && (
            <div className="flex flex-col gap-2 items-center pb-2 justify-center mt-8">
              <svg
                width="29"
                height="29"
                viewBox="0 0 29 29"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="animate-spin w-10 h-10 fill-current shrink-0"
              >
                <path
                  d="M14.5 2.41699V7.25033"
                  stroke="#E48642"
                  stroke-width="2.55882"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M14.5 21.749V26.5824"
                  stroke="#E48642"
                  stroke-width="2.55882"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M5.95703 5.95703L9.37662 9.37662"
                  stroke="#E48642"
                  stroke-width="1.58955"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M19.623 19.6211L23.0426 23.0407"
                  stroke="#E48642"
                  stroke-width="1.58955"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M2.41699 14.5H7.25033"
                  stroke="#E48642"
                  stroke-width="1.58955"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M21.749 14.5H26.5824"
                  stroke="#E48642"
                  stroke-width="1.58955"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M5.95703 23.0407L9.37662 19.6211"
                  stroke="#E48642"
                  stroke-width="1.58955"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M19.623 9.37662L23.0426 5.95703"
                  stroke="#E48642"
                  stroke-width="1.58955"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <p className="text-slate-600 font-medium text-[0.875rem] text-center">
                Fetching data, please wait...
              </p>
            </div>
          )}
          {records?.length > 0 && !isLoading && (
            <>
              <>
                <div className="bg-white flex-1">
                  {/* Table */}
                  <div className="overflow-x-auto h-full">
                    <table className="table-auto w-full dark:text-slate-300">
                      {/* Table header */}
                      <thead className="text-sm text-[#667085] font-medium dark:text-slate-400 shadow-md border-none">
                        <tr>
                          <th className="px-2 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                            <div className="text-left font-medium">Name</div>
                          </th>
                          <th className="px-2 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                            <div className="text-left font-medium">
                              Income Category
                            </div>
                          </th>
                          <th className="px-2 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                            <div className="text-left font-medium">
                              Expense Category
                            </div>
                          </th>
                          <th className="px-2 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                            <div className="text-left font-medium">Website</div>
                          </th>
                          <th className="px-2 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                            <div className="text-center font-medium">
                              Actions
                            </div>
                          </th>
                        </tr>
                      </thead>
                      {/* Table body */}
                      {records?.length > 0 && (
                        <tbody className="text-sm divide-y divide-gray-300 border-b border-gray-300">
                          {records?.map((vendor, index) => (
                            <tr
                              key={index}
                              // onClick={() => onRowClick(transaction)}
                              className="h-10 cursor-pointer  hover:bg-[#A0CD850F]  activeTransactionRow"
                              onClick={() => {
                                setActiveVendor(vendor);
                                setFlyoutOpen(true);
                              }}
                            >
                              <td className="px-2 text-sm first:pl-5 last:pr-5 py-2.5 text-left whitespace-nowrap">
                                <div className="flex items-center">
                                  <div className="w-[1.687rem] h-[1.687rem] flex-shrink-0 mr-2 sm:mr-3 ">
                                    {vendor?.logoUrl ? (
                                      <img
                                        className="rounded-full"
                                        src={vendor?.logoUrl}
                                        alt={vendor?.name}
                                      />
                                    ) : (
                                      <div className="w-[1.687rem] h-[1.687rem] rounded-full flex items-center justify-center bg-slate-400 dark:bg-slate-600">
                                        <div
                                          className={`${"text-black"} text-xs font-normal uppercase`}
                                        >
                                          {vendor?.name?.charAt(0)}
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                  {vendor?.name}
                                </div>
                              </td>
                              <td className="px-2 first:pl-5 last:pr-5 py-2.5 ">
                                <div
                                  className={`w-[160px] transactionRowCategories flex items-center gap-1`}
                                >
                                  <span className=" ps-1 inline-flex items-center gap-2 px-4">
                                    <span className={`whitespace-nowrap`}>
                                      {vendor?.incomeCategoryAccountId
                                        ? allAccounts.find(
                                            (account) =>
                                              account.id ===
                                              vendor.incomeCategoryAccountId
                                          )?.name?.length > 27
                                          ? `${allAccounts
                                              .find(
                                                (account) =>
                                                  account.id ===
                                                  vendor.incomeCategoryAccountId
                                              )
                                              ?.name.slice(0, 27)}...`
                                          : allAccounts.find(
                                              (account) =>
                                                account.id ===
                                                vendor.incomeCategoryAccountId
                                            )?.name
                                        : "Uncategorized"}
                                    </span>
                                  </span>
                                  {/* Render in Case of Category   */}
                                  <div className="hidden w-full">
                                    <CategoriesDropdown
                                      allCategories={defaultAccounts}
                                      allAccounts={defaultAccounts}
                                      selectedCategoryId={
                                        vendor?.incomeCategoryAccountId
                                      }
                                      setSelectedCategoryId={(id) =>
                                        handleUpdateVendor(
                                          { incomeCategoryAccountId: id },
                                          vendor.id
                                        )
                                      }
                                      isSetCategoryStyle
                                      isTableDropdown
                                      align="left"
                                    />
                                  </div>
                                </div>
                              </td>
                              <td className="px-2 first:pl-5 last:pr-5 py-2.5 ">
                                <div
                                  className={`w-[160px] transactionRowCategories flex items-center gap-1`}
                                >
                                  <span className=" ps-1 inline-flex items-center gap-2 px-4">
                                    <span className={`whitespace-nowrap`}>
                                      {vendor?.expenseCategoryAccountId
                                        ? allAccounts.find(
                                            (account) =>
                                              account.id ===
                                              vendor.expenseCategoryAccountId
                                          )?.name.length > 27
                                          ? `${allAccounts
                                              .find(
                                                (account) =>
                                                  account.id ===
                                                  vendor.expenseCategoryAccountId
                                              )
                                              ?.name.slice(0, 27)}...`
                                          : allAccounts.find(
                                              (account) =>
                                                account.id ===
                                                vendor.expenseCategoryAccountId
                                            )?.name
                                        : "Uncategorized"}
                                    </span>
                                  </span>
                                  {/* Render in Case of Category   */}
                                  <div className="hidden w-full">
                                    <CategoriesDropdown
                                      allCategories={defaultAccounts}
                                      allAccounts={defaultAccounts}
                                      selectedCategoryId={
                                        vendor?.expenseCategoryAccountId
                                      }
                                      setSelectedCategoryId={(id) =>
                                        handleUpdateVendor(
                                          { expenseCategoryAccountId: id },
                                          vendor.id
                                        )
                                      }
                                      isSetCategoryStyle
                                      isTableDropdown
                                      align="left"
                                    />
                                  </div>
                                </div>
                              </td>
                              <td className="px-2 text-sm first:pl-5 last:pr-5 py-2.5 text-left whitespace-nowrap">
                                {vendor?.website || "--"}
                              </td>
                              <td className="px-2 text-sm first:pl-5 last:pr-5 py-2.5 text-center whitespace-nowrap">
                                <div className="inline-flex items-center gap-3.5">
                                  <div className="w-[1.625rem] h-[1.625rem] cursor-pointer">
                                    <svg
                                      width="28"
                                      height="28"
                                      viewBox="0 0 28 28"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        setActiveVendor(vendor);
                                        setFormOpen(true);
                                      }}
                                    >
                                      <path
                                        d="M10.1 26.667H17.9C24.4 26.667 27 24.067 27 17.567V9.76699C27 3.26699 24.4 0.666992 17.9 0.666992H10.1C3.6 0.666992 1 3.26699 1 9.76699V17.567C1 24.067 3.6 26.667 10.1 26.667Z"
                                        fill="white"
                                        stroke="#D0D5DD"
                                        stroke-opacity="0.6"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      />
                                      <path
                                        d="M13.416 7.83398H12.2493C9.33268 7.83398 8.16602 9.00065 8.16602 11.9173V15.4173C8.16602 18.334 9.33268 19.5007 12.2493 19.5007H15.7493C18.666 19.5007 19.8327 18.334 19.8327 15.4173V14.2507"
                                        stroke="#667085"
                                        stroke-opacity="0.8"
                                        stroke-width="1.3"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      />
                                      <path
                                        d="M16.3561 8.42905L11.7594 13.0257C11.5844 13.2007 11.4094 13.5449 11.3744 13.7957L11.1236 15.5515C11.0303 16.1874 11.4794 16.6307 12.1153 16.5432L13.8711 16.2924C14.1161 16.2574 14.4603 16.0824 14.6411 15.9074L19.2378 11.3107C20.0311 10.5174 20.4044 9.59572 19.2378 8.42905C18.0711 7.26238 17.1494 7.63572 16.3561 8.42905Z"
                                        stroke="#667085"
                                        stroke-opacity="0.8"
                                        stroke-width="1.3"
                                        stroke-miterlimit="10"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      />
                                      <path
                                        d="M15.6973 9.08789C16.0881 10.4821 17.1789 11.5729 18.5789 11.9696"
                                        stroke="#858D9D"
                                        stroke-miterlimit="10"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      />
                                    </svg>
                                  </div>
                                  {/* <div className="w-[1.625rem] h-[1.625rem] cursor-pointer">
                                      <svg
                                        width="28"
                                        height="28"
                                        viewBox="0 0 28 28"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                        onClick={() => {
                                          setActiveVendor(vendor);
                                          setModalOpen(true);
                                        }}
                                      >
                                        <path
                                          d="M10.1 26.667H17.9C24.4 26.667 27 24.067 27 17.567V9.76699C27 3.26699 24.4 0.666992 17.9 0.666992H10.1C3.6 0.666992 1 3.26699 1 9.76699V17.567C1 24.067 3.6 26.667 10.1 26.667Z"
                                          fill="white"
                                          stroke="#D0D5DD"
                                          stroke-opacity="0.6"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                        />
                                        <path
                                          d="M19.6063 9.54173C17.4487 9.32792 15.2782 9.21777 13.1141 9.21777C11.8313 9.21777 10.5484 9.28257 9.26551 9.41215L7.94376 9.54173"
                                          stroke="#667085"
                                          stroke-opacity="0.8"
                                          stroke-width="1.3"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                        />
                                        <path
                                          d="M11.5073 8.8872L11.6498 8.03843C11.7535 7.42291 11.8313 6.96289 12.9262 6.96289H14.6238C15.7188 6.96289 15.803 7.44883 15.9002 8.04491L16.0427 8.8872"
                                          stroke="#858D9D"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                        />
                                        <path
                                          d="M18.2133 11.5889L17.7921 18.1134C17.7208 19.1306 17.6625 19.9211 15.8548 19.9211H11.6952C9.88752 19.9211 9.82921 19.1306 9.75794 18.1134L9.33679 11.5889"
                                          stroke="#667085"
                                          stroke-opacity="0.8"
                                          stroke-width="1.3"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                        />
                                        <path
                                          d="M12.693 16.3574H14.8505"
                                          stroke="#667085"
                                          stroke-opacity="0.8"
                                          stroke-width="1.3"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                        />
                                        <path
                                          d="M12.1552 13.7656H15.3948"
                                          stroke="#667085"
                                          stroke-opacity="0.8"
                                          stroke-width="1.3"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                        />
                                      </svg>
                                    </div> */}
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      )}
                    </table>
                  </div>
                </div>
                <div className="mt-10">
                  <PaginationClassic
                    setLimit={setLocalLimit}
                    onPageChange={getData}
                    pagination={{ limit: localLimit, page, totalRecords }}
                  />
                </div>
              </>
            </>
          )}
          {!formOpen && vendors?.records?.length <= 0 && !isLoading && (
            <div className="flex flex-col gap-5 items-center justify-center w-full h-full">
              <h6 className="text-center mt-4">No Customers Found</h6>
              <img
                src={emptyCustomerImg}
                alt="No Customers found"
                className="h-[12.5rem]"
              />
            </div>
          )}
        </div>
      </main>
    </>
  );
};

export default InternalVendors;
