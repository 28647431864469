export const Cross = ({ btn = true }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 4.16699V15.8337M4.16669 10.0003H15.8334"
        stroke={btn ? "white" : "#94a3b8"}
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const EditIcon = () => {
  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="w-[18px] h-[18px]"
    >
      <path
        d="M10.2731 3.20041L4.11564 9.71791C3.88314 9.96541 3.65814 10.4529 3.61314 10.7904L3.33564 13.2204C3.23814 14.0979 3.86814 14.6979 4.73814 14.5479L7.15314 14.1354C7.49064 14.0754 7.96314 13.8279 8.19564 13.5729L14.3531 7.05541C15.4181 5.93041 15.8981 4.64791 14.2406 3.08041C12.5906 1.52791 11.3381 2.07541 10.2731 3.20041Z"
        stroke="#667085"
      />
      <path
        d="M9.24561 4.28711C9.56811 6.35711 11.2481 7.93961 13.3331 8.14961"
        stroke="#667085"
      />
      <path d="M2.57812 17H16.0781" stroke="#667085" />
    </svg>
  );
};

export const TableEditIcon = () => {
  return (
    <svg
      width="28"
      height="27"
      viewBox="0 0 28 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.1 26.5H17.9C24.4 26.5 27 23.9 27 17.4V9.6C27 3.1 24.4 0.5 17.9 0.5H10.1C3.6 0.5 1 3.1 1 9.6V17.4C1 23.9 3.6 26.5 10.1 26.5Z"
        fill="white"
        stroke="#D0D5DD"
        stroke-opacity="0.6"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M13.416 7.66797H12.2493C9.33268 7.66797 8.16602 8.83464 8.16602 11.7513V15.2513C8.16602 18.168 9.33268 19.3346 12.2493 19.3346H15.7493C18.666 19.3346 19.8327 18.168 19.8327 15.2513V14.0846"
        stroke="#667085"
        stroke-opacity="0.8"
        stroke-width="1.3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M16.3561 8.26108L11.7594 12.8577C11.5844 13.0327 11.4094 13.3769 11.3744 13.6277L11.1236 15.3836C11.0303 16.0194 11.4794 16.4627 12.1153 16.3752L13.8711 16.1244C14.1161 16.0894 14.4603 15.9144 14.6411 15.7394L19.2378 11.1427C20.0311 10.3494 20.4044 9.42775 19.2378 8.26108C18.0711 7.09442 17.1494 7.46775 16.3561 8.26108Z"
        stroke="#667085"
        stroke-opacity="0.8"
        stroke-width="1.3"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M15.6973 8.91992C16.0881 10.3141 17.1789 11.4049 18.5789 11.8016"
        stroke="#858D9D"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
