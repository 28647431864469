import React, { useEffect, useState, useContext, useRef } from "react";
import DropdownFull from "../../../components/DropdownFull";
import ModalBasic from "../../../components/ModalBasic";
import { Form, Formik, useFormikContext } from "formik";
import * as Yup from "yup";
import {
  createDefaultAccount,
  updateDefaultAccountById,
} from "../../../API/backend_helper";
import { getContrast } from "../../../utils/Utils";
import ColorPicker from "../../component/ColorPicker";

const ColorOptions = [
  "#D2D6DB",
  "#AAF0C4",
  "#B2CCFF",
  "#DDD6FE",
  "#FCCEEE",
  "#FFD6AE",
  "#D0F8AA",
  "#A5F0FC",
  "#F7B27A",
];

const DefaultAccountModal = ({
  isOpen,
  setIsOpen,
  accountTypes,
  accountSubTypes,
  loadData,
  account,
  setSelectedAccount,
}) => {
  const formikRef = useRef();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!isOpen) {
      formikRef.current.resetForm();
    }
  }, [isOpen]);

  const handleSubmit = async (values, formikHelper) => {
    setLoading(true);
    const subTypeInt = accountSubTypes.find(
      (subType) => subType.name === values.subTypeId
    )?.id;

    const providerMatchingCategoryArray = values.providerMatchingCategory
    .split(',')
    .map(item => item.trim())
    .filter(item => item !== '');

    const data = { ...values, subTypeId: subTypeInt, providerMatchingCategory: providerMatchingCategoryArray };
    try {
      const res = account?.id
        ? await updateDefaultAccountById(account?.id, data)
        : await createDefaultAccount(data);

      if (res?.data) {
        await loadData(true);
        formikHelper.resetForm();
        setIsOpen(false);
      }
    } catch (err) {
      console.log("err", err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <ModalBasic
      modalOpen={isOpen}
      setModalOpen={setIsOpen}
      title={account?.id ? "Edit Account" : "Create Account"}
      width="max-w-5xl"
    >
      {/* Modal content */}
      <div className="bg-white w-full overflow-x-auto">
        <Formik
          enableReinitialize={true}
          initialValues={{
            type: account?.type || "",
            subTypeId: account?.subType?.name || "",
            name: account?.name || "",
            color: account?.color || "#D2D6DB",
            keywords: account?.keywords || "",
            description: account?.description || "",
            providerMatchingCategory: account?.providerMatchingCategory.join(", ") || "",
          }}
          validationSchema={Yup.object({
            type: Yup.string().required("Please Enter a Type"),
            subTypeId: Yup.string().required("Please Enter a Sub Type"),
            name: Yup.string()
              .required("Please Enter Name")
              .max(100, "Name must be 100 characters or less"),
            color: Yup.string().required("Please Select Color"),
            keywords: Yup.string().max(
              100,
              "Keywords must be 100 characters or less"
            ),
            description: Yup.string().max(
              500,
              "Description must be 500 characters or less"
            ),
            providerMatchingCategory: Yup.string(),
          })}
          onSubmit={handleSubmit}
          innerRef={formikRef}
        >
          {(validation) => {
            return (
              <Form>
                <div className="sm:flex flex-col items-center justify-center gap-9 self-stretch py-[2.25rem] px-12">
                  <div className="sm:flex items-start gap-[1.2rem] self-stretch">
                    <div className="flex flex-col items-start gap-3 w-[18.438rem]">
                      <label
                        className="block text-sm text-slate-600 font-medium leading-5"
                        htmlFor="type"
                      >
                        Account Type<span className="text-rose-500">*</span>
                      </label>
                      <DropdownFull
                        options={accountTypes.map((d) => ({
                          ...d,
                          name: d.name,
                          id: d.name,
                        }))}
                        name="name"
                        setSelected={(name) => {
                          validation.setFieldValue("type", name);
                          validation?.setFieldValue("subTypeId", "");
                        }}
                        selected={validation.values.type}
                        error={
                          validation.touched.type && validation.errors.type
                        }
                        accounts
                        accountStyle
                        placeholder="Select"
                      />
                    </div>
                    <div className="flex flex-col items-start gap-3 w-[18.438rem]">
                      <label
                        className="block text-sm text-slate-600 font-medium leading-5"
                        htmlFor="subTypeId"
                      >
                        Account Sub-Type<span className="text-rose-500">*</span>
                      </label>
                      <DropdownFull
                        options={accountSubTypes
                          .filter((d) => {
                            const selectedType = accountTypes.find(
                              (type) => type?.name === validation.values.type
                            );
                            return d.type === selectedType?.name;
                          })
                          .map((d) => ({
                            ...d,
                            subTypeId: d.name,
                            id: d.name,
                          }))}
                        name="subTypeId"
                        setSelected={(option) => {
                          console.log("OPtion", option);
                          validation.setFieldValue("subTypeId", option);
                        }}
                        selected={validation.values.subTypeId}
                        error={
                          validation.touched.subTypeId &&
                          validation.errors.subTypeId
                        }
                        accounts
                        accountStyle
                        subType
                        placeholder="Select"
                        disabled={!validation.values.type}
                      />
                    </div>
                    <div className="flex flex-col items-start gap-3 w-[18.438rem]">
                      <label
                        className="block text-sm text-slate-600 font-medium leading-5"
                        htmlFor="name"
                      >
                        Account Name<span className="text-rose-500">*</span>
                      </label>
                      <input
                        id="name"
                        type="text"
                        placeholder="Name"
                        name="name"
                        className={`btn text-base h-12 w-full rounded-[0.313rem] border border-[#D0D5DD] text-slate-600leading-6 font-normal px-4 py-3.5 bg-white
                                    placeholder:text-base placeholder:text-slate-600 placeholder:text-opacity-80 placeholder:leading-6 placeholder:font-normal ${
                                      validation.touched.name &&
                                      validation.errors.name &&
                                      "border-rose-300"
                                    }`}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.name || ""}
                        invalid={
                          validation.touched.name && validation.errors.name
                            ? true
                            : false
                        }
                      />
                      {validation.touched.name && validation.errors.name ? (
                        <div className="text-xs mt-1 text-rose-500">
                          {validation.errors.name}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="flex flex-row w-full items-center justify-start">
                    <div className="flex flex-col gap-3 items-start">
                      <label
                        className="block text-sm text-slate-600 font-medium leading-5"
                        htmlFor="color"
                      >
                        Select Display Color
                        <span className="text-rose-500">*</span>
                      </label>
                      <div className="relative h-[7.375rem] w-[28.337rem] py-3 flex flex-col justify-center items-center gap-2.5 self-stretch rounded-[10px] border border-dashed border-indigo-500 shadow-md">
                        <div className="flex flex-col justify-center items-center gap-3">
                          <div
                            className="w-[1.875rem] h-[1.875rem] rounded-full flex items-center justify-center text-black"
                            style={{
                              backgroundColor: validation.values.color,
                              border: !validation.values.color
                                ? "1px solid black"
                                : "none",
                            }}
                          >
                            <div
                              className={`${getContrast(
                                validation.values.color,
                                "text-black",
                                "text-white"
                              )} text-xs uppercase font-normal`}
                            >
                              {validation.values.name
                                ? validation.values.name
                                    .split(" ")
                                    .slice(0, 2)
                                    .map((word) => word.charAt(0))
                                    .join("")
                                : ""}
                            </div>
                          </div>
                          <p className="text-slate-600 text-sm">
                            Click to select the color
                          </p>
                          <ColorPicker
                            ColorOptions={ColorOptions}
                            selectedColor={validation.values.color}
                            setSelectedColor={(color) =>
                              validation.setFieldValue("color", color)
                            }
                          />
                          {validation.touched.color &&
                          validation.errors.color ? (
                            <span className="text-xs absolute -bottom-9 text-rose-500">
                              {validation.errors.color}
                            </span>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex items-start self-stretch gap-[1.2rem]">
                    <div className="flex flex-col items-start gap-3 w-[28.337rem]">
                      <label
                        className="block text-sm text-slate-600 font-medium leading-5"
                        htmlFor="keywords"
                      >
                        Keywords
                      </label>
                      <input
                        id="keywords"
                        type="text"
                        placeholder="Optional"
                        name="keywords"
                        className={`btn text-base h-12 w-full rounded-[0.313rem] border border-[#D0D5DD] text-slate-600 leading-6 font-normal px-4 py-3.5 bg-white
                                    placeholder:text-base placeholder:text-slate-600 placeholder:text-opacity-80 placeholder:leading-6 placeholder:font-normal ${
                                      validation.touched.keywords &&
                                      validation.errors.keywords &&
                                      "border-rose-300"
                                    }`}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.keywords || ""}
                        invalid={
                          validation.touched.keywords &&
                          validation.errors.keywords
                            ? true
                            : false
                        }
                      />
                      {validation.touched.keywords &&
                      validation.errors.keywords ? (
                        <div className="text-xs mt-1 text-rose-500">
                          {validation.errors.keywords}
                        </div>
                      ) : null}
                    </div>
                    <div className="flex flex-col items-start gap-3 w-[28.337rem]">
                      <label
                        className="block text-sm text-slate-600 font-medium leading-5"
                        htmlFor="description"
                      >
                        Description
                      </label>
                      <input
                        id="description"
                        type="text"
                        placeholder="Optional"
                        name="description"
                        className={`btn text-base h-12 w-full rounded-[0.313rem] border border-[#D0D5DD] text-slate-600 leading-6 font-normal px-4 py-3.5 bg-white
                                    placeholder:text-base placeholder:text-slate-600 placeholder:text-opacity-80 placeholder:leading-6 placeholder:font-normal ${
                                      validation.touched.description &&
                                      validation.errors.description &&
                                      "border-rose-300"
                                    }`}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.description || ""}
                        invalid={
                          validation.touched.description &&
                          validation.errors.description
                            ? true
                            : false
                        }
                      />
                      {validation.touched.description &&
                      validation.errors.description ? (
                        <div className="text-xs mt-1 text-rose-500">
                          {validation.errors.description}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="flex items-start self-stretch gap-[1.2rem]">
                    <div className="flex flex-col items-start gap-3 w-full">
                      <label
                        className="block text-sm text-slate-600 font-medium leading-5"
                        htmlFor="providerMatchingCategory"
                      >
                        Provider Matching Category
                      </label>
                      <input
                        id="providerMatchingCategory"
                        type="text"
                        placeholder="Optional"
                        name="providerMatchingCategory"
                        className={`btn text-base h-12 w-full rounded-[0.313rem] border border-[#D0D5DD] text-slate-600 leading-6 font-normal px-4 py-3.5 bg-white
                                    placeholder:text-base placeholder:text-slate-600 placeholder:text-opacity-80 placeholder:leading-6 placeholder:font-normal ${
                                      validation.touched
                                        .providerMatchingCategory &&
                                      validation.errors
                                        .providerMatchingCategory &&
                                      "border-rose-300"
                                    }`}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.providerMatchingCategory || ""}
                        invalid={
                          validation.touched.providerMatchingCategory &&
                          validation.errors.providerMatchingCategory
                            ? true
                            : false
                        }
                      />
                      {validation.touched.providerMatchingCategory &&
                      validation.errors.providerMatchingCategory ? (
                        <div className="text-xs mt-1 text-rose-500">
                          {validation.errors.providerMatchingCategory}
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
                {/* Modal footer */}
                <div className="border-t border-[#D0D5DD] bg-white flex py-[2rem] px-12 justify-end items-center gap-4 self-stretch w-full mt-[2.25rem]">
                  <div className="flex flex-wrap justify-end space-x-2">
                    <button
                      type="button"
                      className="h-11 w-[5.625rem] py-2.5 px-[1.875rem] flex items-center justify-center rounded-[0.313rem] bg-white border border-slate-600 text-slate-600 text-base leading-6"
                      onClick={(e) => {
                        e.stopPropagation();
                        setIsOpen(false);
                        setSelectedAccount(null);
                      }}
                    >
                      {account?.id ? "Discard" : "Close"}
                    </button>
                    <button
                      type="submit"
                      className="h-11 w-[5.625rem] py-2.5 px-[1.875rem] flex items-center justify-center rounded-[0.313rem] bg-indigo-500 text-white font-normal text-base leading-6 shadow-sm
                      disabled:bg-indigo-400    disabled:cursor-not-allowed"
                      disabled={loading}
                    >
                      {loading && (
                        <svg
                          className="animate-spin w-4 h-4 fill-current shrink-0 mr-2"
                          viewBox="0 0 16 16"
                        >
                          <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z" />
                        </svg>
                      )}
                      {account?.id ? "Save" : "Create"}
                    </button>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </ModalBasic>
  );
};

export default DefaultAccountModal;
