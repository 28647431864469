import React from 'react';
import ModalBasic from '../../../components/ModalBasic';
import { Lightbulb } from './Svg';

const AutoReconcileModal = ({ modalOpen, setModalOpen, completeReconcile }) => (
  <ModalBasic
    modalOpen={modalOpen}
    setModalOpen={setModalOpen}
    title="Congratulations"
    smallModal
    svg={<Lightbulb />}
  >
    <div className="w-full px-5 py-[1.875rem] flex items-center self-stretch bg-white">
      <p className="text-base leading-6 text-slate-600">
        Congratulations, your account can automatically be reconciled. Would you
        like to mark all of this account's transactions as reviewed and
        auto-reconcile the account?
      </p>
    </div>
    <div className="border-t border-gray-300 h-20 flex items-center justify-end gap-4 px-5">
      <button
        className="w-[5.625rem] h-11 flex items-center justify-center py-2.5 px-[1.875rem] rounded-[0.313rem] bg-white text-slate-600 border border-indigo-500 leading-6 "
        onClick={() => setModalOpen(false)}
        type="button"
      >
        Close
      </button>
      <button
        className="w-[5.625rem] h-11 flex items-center justify-center py-2.5 px-[1.875rem] rounded-[0.313rem] bg-indigo-500 shadow text-white leading-6"
        onClick={() => {
          setModalOpen(false);
          completeReconcile(true);
        }}
        type="button"
      >
        Yes
      </button>
    </div>
  </ModalBasic>
);

export default AutoReconcileModal;
