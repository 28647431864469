import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { boot, shutdown } from '@intercom/messenger-js-sdk';
import PayrollStep1 from './PayrollStep1';
import PayrollStep2 from './PayrollStep2';
import PayrollStep3 from './PayrollStep3';
import { AnimatedCheckmark } from '../inbox/Svg';
import {
  calculateGustoPayroll,
  getGustoPayrollById,
  prepareGustoPayroll,
  submitGustoPayroll,
  updateGustoPayroll,
} from '../../API/backend_helper';
import { Loader } from '../../components/Svg';

const PayrollForm = ({
  setFormOpen,
  upcomingPayrolls,
  gustoId,
  loadUpcomingPayrolls,
  loadPayrolls,
  activeStep,
  setActiveStep,
  gusto,
}) => {
  const [selectedPayrole, setSelectedPayrole] = useState(null);
  const [loading, setLoading] = useState(false);
  const [preparedPayroll, setPreparedPayroll] = useState(null);
  const [payroll, setPayroll] = useState(null);

  const preparePayroll = async () => {
    try {
      setLoading(true);
      const res = await prepareGustoPayroll(
        gustoId,
        selectedPayrole?.payroll_uuid,
      );
      setPreparedPayroll(res?.data?.preparedPayroll);
      setLoading(false);
      setActiveStep(2);
    } catch (err) {
      setLoading(false);
      console.log('err', err);
    }
  };

  useEffect(() => {
    shutdown();
    return () => {
      boot();
    };
  }, []);

  const fetchPayroll = async (gustoId, payrollUuid) => {
    try {
      const res = await getGustoPayrollById(gustoId, payrollUuid);
      return res?.data;
    } catch (err) {
      console.log('err', err);
      return null;
    }
  };

  const retryGetPayroll = async (gustoId, payrollUuid, calculatedAt, res) => {
    if (calculatedAt) {
      setPayroll(res);
      setLoading(false);
      setActiveStep(3);
    } else {
      setLoading(true);
      const data = await fetchPayroll(gustoId, payrollUuid);
      if (data) {
        setTimeout(() => {
          retryGetPayroll(
            gustoId,
            payrollUuid,
            data?.providerPayroll?.calculated_at,
            data?.providerPayroll,
          );
        }, 5000); // 5 seconds delay
      } else {
        setLoading(false);
      }
    }
  };

  const getPayrollByUuid = async () => {
    retryGetPayroll(gustoId, selectedPayrole?.payroll_uuid);
  };

  const calculatePayroll = async () => {
    try {
      const res = await calculateGustoPayroll(
        gustoId,
        selectedPayrole?.payroll_uuid,
        { employee_compensations: preparedPayroll?.employee_compensations },
      );
      // setPreparedPayroll(res?.data?.preparedPayroll);
      // setLoading(false);
      getPayrollByUuid();
      // setActiveStep(3);
    } catch (err) {
      setLoading(false);
      console.log('err', err);
    }
  };

  const updatePayroll = async (obj) => {
    try {
      setLoading(true);
      await updateGustoPayroll(gustoId, selectedPayrole?.payroll_uuid, {
        employee_compensations: obj?.employee_compensations,
      });
      calculatePayroll();
    } catch (err) {
      setLoading(false);
      console.log('err', err);
    }
  };

  const submitPayroll = async () => {
    try {
      setLoading(true);
      const res = await submitGustoPayroll(
        gustoId,
        selectedPayrole?.payroll_uuid,
      );
      setTimeout(() => {
        loadUpcomingPayrolls();
        loadPayrolls();
      }, 5000);
      setLoading(false);
      setActiveStep(4);
    } catch (err) {
      setLoading(false);
      console.log('err', err);
    }
  };

  return (
    <div className="relative">
      {loading && (
        <div className="absolute top-0 left-0 flex justify-items-center items-center h-full w-full bg-[#dddddd70] cursor-default">
          <div className="relative w-full flex flex-col items-center font-bold text-indigo-600 justify-center z-10">
            <Loader />
            {activeStep === 2 && (
              <>
                <h2>Calculation in Progress</h2>
                <p>This may take 2-3 minutes</p>
              </>
            )}
          </div>
        </div>
      )}
      {activeStep === 1 && (
        <PayrollStep1
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          selectedPayrole={selectedPayrole}
          setSelectedPayrole={setSelectedPayrole}
          upcomingPayrolls={upcomingPayrolls}
          onSubmit={preparePayroll}
          loading={loading}
        />
      )}
      {activeStep === 2 && (
        <PayrollStep2
          setActiveStep={setActiveStep}
          preparedPayroll={preparedPayroll}
          setPreparedPayroll={setPreparedPayroll}
          onSubmit={updatePayroll}
          loading={loading}
        />
      )}
      {activeStep === 3 && (
        <PayrollStep3
          setActiveStep={setActiveStep}
          payroll={payroll}
          onSubmit={submitPayroll}
          loading={loading}
          gusto={gusto}
        />
      )}
      {activeStep === 4 && (
        <div className="w-full flex flex-col gap-10 justify-center items-center">
          <div className="w-full 2xl:w-2/3 p-4 flex flex-col rounded-lg border-2 border-[#D0D5DD] gap-2">
            <p className="text-lg font-semibold">Total Payroll Cost</p>
            <div className="flex gap-6 items-start">
              <div className="w-[40%] 2xl:w-1/3 bg-[#D0D5DD] bg-opacity-20 rounded-lg p-6 flex flex-col gap-3">
                <p className="text-[#333333] font-semibold text-2xl">
                  $
                  {parseFloat(
                    parseFloat(payroll?.totals?.gross_pay) +
                      parseFloat(payroll?.totals?.employer_taxes) +
                      parseFloat(payroll?.totals?.benefits) +
                      parseFloat(payroll?.totals?.reimbursements),
                  )?.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </p>
                <div className="w-full h-px bg-[#D0D5DD]" />
                <div className="flex justify-between items-center">
                  <p className="max-w-[60%]">Withdrawal amount</p>
                  <p className="text-[#333333] font-semibold">
                    ${' '}
                    {parseFloat(payroll?.totals?.company_debit)?.toLocaleString(
                      undefined,
                      {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      },
                    )}
                  </p>
                </div>
                {gusto?.companyAccounts?.[0]?.id && (
                  <div className="flex flex-col">
                    <p className="text-[#333333] font-medium">
                      {gusto?.companyAccounts?.[0]?.name}
                      <div>
                        ({gusto?.companyAccounts?.[0]?.accountType}{' '}
                        {gusto?.companyAccounts?.[0]?.mask})
                      </div>
                    </p>
                  </div>
                )}
              </div>
              <div className="flex flex-col gap-4 w-[40%]">
                <div className="flex flex-col">
                  <p className="">
                    Pay Period:{' '}
                    <span className="text-[#333333] font-medium">
                      {moment(payroll?.pay_period?.start_date).format(
                        'DD MMM, YYYY',
                      )}{' '}
                      to{' '}
                      {moment(payroll?.pay_period?.end_date).format(
                        'DD MMM, YYYY',
                      )}
                    </span>
                  </p>
                </div>
                <div className="flex flex-col">
                  <p className="">
                    Withdrawal date:{' '}
                    <span className="text-[#333333] font-medium">
                      {moment(
                        payroll?.payroll_deadline?.split('T')?.[0],
                      ).format('DD MMM, YYYY')}
                    </span>
                  </p>
                </div>
                <div className="flex flex-col">
                  <p className="">
                    Employee payday:{' '}
                    <span className="text-[#333333] font-medium">
                      {moment(payroll?.check_date).format('DD MMM, YYYY')}
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full h-20 flex items-center justify-center gap-4">
            <AnimatedCheckmark />
            <h2 className="text-2xl font-medium">Payroll is Done!</h2>
          </div>
          {/* Table */}
          {/* <table className="w-1/3 flex flex-col items-start">
            <thead className="flex w-full gap-1 items-center justify-around rounded-[0.313rem] shadow-md bg-white min-h-[3.5rem] mb-1">
              <th className="text-slate-600 text-left text-[14px] font-medium leading-[1.125rem] w-[100px] flex-shrink-0 flex-grow-0 whitespace-nowrap">
                Name
              </th>
              <th className="text-slate-600 text-left text-[14px] font-medium leading-[1.125rem] w-[100px] flex-shrink-0 flex-grow-0 whitespace-nowrap ">
                Net Pay
              </th>
            </thead>
            <tbody className="min-w-max w-full flex flex-col items-start">
              <tr className="flex w-full items-center justify-around gap-1  bg-white min-h-[3.5rem] border-b border-[#D0D5DD] hover:bg-[#a0cd85] hover:bg-opacity-5">
                <td className="text-left text-[14px] font-medium leading-[1.125rem] w-[100px] flex-shrink-0 flex-grow-0 whitespace-nowrap">
                  Burt, Jon
                </td>
                <td className="text-left text-[14px] font-medium leading-[1.125rem] w-[100px] flex-shrink-0 flex-grow-0 whitespace-nowrap">
                  $1,111.85
                </td>
              </tr>
            </tbody>
          </table> */}
          <div className="flex items-center gap-2">
            <button
              className="bg-indigo-500 h-10 border border-indigo-500 rounded-[0.313rem] px-[1.125rem] py-2.5 shadow text-white text-base leading-6 flex items-center justify-center gap-2 font-normal"
              onClick={() => {
                setActiveStep(1);
                setFormOpen(false);
              }}
              type="button"
            >
              Back to Payroll
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default PayrollForm;
