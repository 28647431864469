import DropdownFull from 'components/DropdownFull';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Loader } from 'components/Svg';
import DatePickerDash from 'components/DatePickerDash';
import useClickOutside from 'utils/useClickOutside';
import AssigneeDropdown from '../AssigneeDropdown';
import TasksDropdown from '../TasksDropdown';
import ActionsDropdown from './ActionsDropdown';

const types = [
  { name: 'To-Do', id: 'toDo' },
  { name: 'Review Transaction', id: 'reviewTransaction' },
  { name: 'Set Transaction Vendor', id: 'transactionVendor' },
  { name: 'Set Transaction Category', id: 'transactionCategory' },
  { name: 'Pay Bill', id: 'payBill' },
  { name: 'Upload Transaction Attachment', id: 'transactionAttachment' },
];

const RightBar = ({
  task,
  findTaskType,
  handleUpdateTask,
  assigneeLoading,
  statusOptions,
  handleActionDropdown,
  setNoClick,
  noClick,
  projects = [],
}) => {
  const taskDeadlineContainerRef = useRef();
  const taskProjectIdContainerRef = useRef();
  const { team } = useSelector((state) => state.Team);

  const [isEditingProjectId, setIsEditingProjectId] = useState(false);
  const [isEditDeadline, setIsEditDeadline] = useState(false);
  const [taskDeadline, setTaskDeadline] = useState(task?.deadlineDate);

  useEffect(() => {
    if (task?.deadlineDate !== taskDeadline) {
      setTaskDeadline(task?.deadlineDate);
    }
  }, [task]);

  const options = {
    mode: 'single',
    static: false,
    monthSelectorType: 'static',
    allowInput: true,
    dateFormat: 'm/d/Y',
    position: 'bottom',
    prevArrow:
      '<svg class="fill-current" width="7" height="11" viewBox="0 0 7 11"><path d="M5.4 10.8l1.4-1.4-4-4 4-4L5.4 0 0 5.4z" /></svg>',
    nextArrow:
      '<svg class="fill-current" width="7" height="11" viewBox="0 0 7 11"><path d="M1.4 10.8L0 9.4l4-4-4-4L1.4 0l5.4 5.4z" /></svg>',
    onOpen() {
      setNoClick(true);
    },
    onClose() {
      setNoClick(false);
    },
  };

  const handleDeadlineUpdate = (date) => {
    setTaskDeadline(date);
    if (date !== taskDeadline) {
      handleUpdateTask(task?.id, { ...task, deadlineDate: date });
    }
    setIsEditDeadline(false);
  };

  useClickOutside(taskDeadlineContainerRef, () => {
    if (!noClick && isEditDeadline) {
      handleDeadlineUpdate(taskDeadline);
    }
  });

  useClickOutside(taskProjectIdContainerRef, () => {
    if (!task?.projectId) {
      setIsEditingProjectId(false);
    }
  });

  return (
    <div className=" flex flex-col px-4 py-6 space-y-4">
      <div className=" relative">
        <div className="absolute right-0 ml-auto ">
          <ActionsDropdown handleOption={handleActionDropdown} />
        </div>
        <h4 className="text-sm font-medium text-black mb-1">Task ID</h4>
        <p className="text-base text-slate-500">
          {' '}
          {task?.customId || `TODO-${task?.id}`}
        </p>
      </div>
      <div>
        <h4 className="text-sm font-medium text-black mb-1">Task Type</h4>
        <DropdownFull
          options={types}
          placeholder="Select Task Type"
          name="name"
          selected={task?.type}
          setSelected={(value) =>
            handleUpdateTask(task?.id, { ...task, type: value })
          }
          scrollIntoView={false}
          height="h-10"
        />
      </div>
      <div>
        <h4 className="text-sm font-medium text-black mb-1">Created by</h4>
        <div className="flex gap-1 items-center mt-1">
          {team?.users?.find((user) => user.id === task?.createdById)
            ?.avatarUrl ? (
            <img
              src={
                team?.users?.find((user) => user.id === task?.createdById)
                  ?.avatarUrl
              }
              className="h-6 w-6 rounded-[42px]"
              alt="avatar"
            />
          ) : (
            <div className="min-h-8 min-w-8 rounded-[42px] bg-slate-400 text-white text-sm flex items-center justify-center">
              {team?.users?.find((user) => user.id === task?.createdById)
                ?.firstName[0] +
                team?.users?.find((user) => user.id === task?.createdById)
                  ?.lastName[0]}
            </div>
          )}
          <p className="text-slate-600 leading-6 text-base">
            {`${
              team?.users?.find((user) => user.id === task?.createdById)
                ?.firstName
            } ${
              team?.users?.find((user) => user.id === task?.createdById)
                ?.lastName
            }`}
          </p>
        </div>
      </div>
      <div>
        <h4 className="text-sm font-medium text-black mb-1">Assigned To</h4>
        <div className="">
          {task?.assigneeId ? (
            assigneeLoading[task?.id] ? (
              <div className="w-full flex h-12 items-center">
                <Loader width="w-6" height="h-6" />
              </div>
            ) : (
              <AssigneeDropdown
                options={team?.users}
                selected={task?.assigneeId}
                setSelected={(value) =>
                  handleUpdateTask(
                    task?.id,
                    {
                      ...task,
                      assigneeId: value,
                    },
                    'assignee',
                  )
                }
                users
                height="h-10 !p-2"
                scrollIntoView={false}
              />
            )
          ) : (
            <p className="text-slate-600 leading-6 text-[0.875rem]">--</p>
          )}
        </div>
      </div>
      <div>
        <h4 className="text-sm font-medium text-black mb-1">Priority</h4>
        <p className="w-[120px]">
          {' '}
          <DropdownFull
            noMinW
            selected={task?.priority}
            options={[
              { id: 'high', name: 'High' },
              { id: 'medium', name: 'Medium' },
              { id: 'low', name: 'Low' },
            ]}
            name="name"
            height="!h-8 min-w-[120px]"
            priorityDot
            transitionClass="min-w-fit"
            setSelected={(value) =>
              handleUpdateTask(
                task?.id,
                {
                  ...task,
                  priority: value,
                },
                'priority',
              )
            }
            scrollIntoView={false}
          />
        </p>
      </div>
      <div>
        <h4 className="text-sm font-medium text-black mb-1">Status</h4>
        <TasksDropdown
          statusOptions={statusOptions}
          task={task}
          handleUpdateTask={handleUpdateTask}
        />
      </div>
      <div>
        <h4 className="text-sm font-medium text-black mb-1">
          Project
          {task?.projectId && (
            <span
              className="text-indigo-500 text-xs font-medium ml-2 underline cursor-pointer"
              onClick={() =>
                handleUpdateTask(task?.id, { ...task, projectId: null })
              }
              tabIndex="0"
              role="button"
            >
              Clear
            </span>
          )}
        </h4>
        {task?.projectId || isEditingProjectId ? (
          <div ref={taskProjectIdContainerRef}>
            <DropdownFull
              options={projects}
              selected={task?.projectId}
              setSelected={(value) => {
                handleUpdateTask(task?.id, { ...task, projectId: value });
              }}
              name="name"
              scrollIntoView={false}
              height="h-10"
              placeholder="Select Project"
            />
          </div>
        ) : (
          <div
            className="text-base text-slate-500 h-10 hover:bg-slate-100 p-2 inline-flex items-center w-full cursor-pointer"
            onClick={() => setIsEditingProjectId(true)}
            tabIndex="0"
            role="button"
          >
            None
          </div>
        )}
      </div>
      <div>
        <h4 className="text-sm font-medium text-black mb-1">Deadline</h4>
        {isEditDeadline ? (
          <div ref={taskDeadlineContainerRef}>
            <DatePickerDash
              required
              placeholder="Select Date"
              value={taskDeadline ? moment(taskDeadline).toISOString() : null}
              options={options}
              onChange={(selectedDates) => {
                handleDeadlineUpdate(selectedDates[0]);
              }}
              name="deadlineDate"
              id="deadlineDate"
              inputClass="!h-10"
              crossIcon
              handleCrossIcon={() => handleDeadlineUpdate(null)}
              crossIconClass="!top-[10px]"
            />
          </div>
        ) : (
          <div
            className="text-base text-slate-500 h-10 hover:bg-slate-100 p-2 inline-flex items-center w-full cursor-pointer"
            onClick={() => setIsEditDeadline(true)}
            tabIndex="0"
            role="button"
          >
            {taskDeadline
              ? moment(taskDeadline).format('MMM DD, YYYY')
              : 'None'}
          </div>
        )}
      </div>
    </div>
  );
};

export default RightBar;
