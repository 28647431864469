import React, { useState } from "react";
import ModalBasic from "../../components/ModalBasic";

const DeletePeopleModal = ({
  isOpen,
  setIsOpen,
  handleDeletePeople,
  activePeople,
  errMessage,
  loader,
}) => {
  return (
    <>
      <ModalBasic
        title={errMessage ? "Error" : "Delete Person"}
        modalOpen={isOpen}
        setModalOpen={setIsOpen}
        smallModal
      >
        <div className="w-full px-5 py-[1.875rem] flex items-center self-stretch bg-white">
          {errMessage ? (
            <p className="text-base leading-6 text-slate-600 text-opacity-80">
              {errMessage.message}
            </p>
          ) : (
            <p className="text-base leading-6 text-slate-600 text-opacity-80">
              Are you sure you want to delete this person? This action cannot be
              undone.
            </p>
          )}
        </div>
        <div className="border-t border-gray-300 h-20 flex items-center justify-end gap-4 px-5">
          {errMessage ? (
            <button
              className="w-[5.625rem] h-11 flex items-center justify-center py-2.5 px-[1.875rem] rounded-[0.313rem] bg-indigo-500 shadow text-white leading-6 "
              onClick={() => setIsOpen(false)}
            >
              Close
            </button>
          ) : (
            <>
              <button
                className="w-[5.625rem] h-11 flex items-center justify-center py-2.5 px-[1.875rem] rounded-[0.313rem] bg-white border border-slate-600 text-slate-600 text-base leading-6"
                onClick={() => setIsOpen(false)}
              >
                No
              </button>
              <button
                className="w-[5.625rem] h-11 flex items-center justify-center py-2.5 px-[1.875rem] rounded-[0.313rem] bg-indigo-500 shadow text-white leading-6"
                onClick={() => handleDeletePeople(activePeople)}
              >
                {loader && (
                  <svg
                    className="animate-spin w-4 h-4 fill-current shrink-0 mr-2"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z" />
                  </svg>
                )}
                Yes
              </button>
            </>
          )}
        </div>
      </ModalBasic>
    </>
  );
};

export default DeletePeopleModal;
