import React from 'react';
import ModalBasic from '../../components/ModalBasic';

const AccountChoiceModal = ({
  isOpen,
  setIsOpen,
  setAccountsModalOpen,
  setInstitutionModalOpen,
}) => (
  <ModalBasic
    modalOpen={isOpen}
    setModalOpen={setIsOpen}
    title="Add New Account"
    width="!w-[584px] max-w-[90vw]"
  >
    <div className="flex flex-wrap sm:justify-between justify-center gap-4 px-5 pt-[1.031rem] pb-[1.219rem]">
      <div className="w-[250px] flex flex-col gap-1">
        <button
          onClick={(e) => {
            e.stopPropagation();
            setIsOpen(false);
            setInstitutionModalOpen(true);
          }}
          className="w-[250px] h-[4.688rem] pl-1.5 pr-5 gap-2.5 bg-white border border-indigo-500 text-slate-600 leading-6 flex justify-center items-center rounded-[6px]"
          type="button"
        >
          <svg
            width="36"
            height="35"
            viewBox="0 0 36 35"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4.0813 14.3118H7.36255V22.8118H5.17505C4.88497 22.8118 4.60677 22.9238 4.40165 23.123C4.19653 23.3223 4.0813 23.5925 4.0813 23.8743C4.0813 24.1561 4.19653 24.4264 4.40165 24.6256C4.60677 24.8249 4.88497 24.9368 5.17505 24.9368H31.425C31.7151 24.9368 31.9933 24.8249 32.1984 24.6256C32.4036 24.4264 32.5188 24.1561 32.5188 23.8743C32.5188 23.5925 32.4036 23.3223 32.1984 23.123C31.9933 22.9238 31.7151 22.8118 31.425 22.8118H29.2375V14.3118H32.5188C32.7568 14.3116 32.9882 14.236 33.1779 14.0964C33.3676 13.9569 33.5054 13.761 33.5701 13.5386C33.6349 13.3161 33.6233 13.0792 33.5369 12.8638C33.4506 12.6484 33.2942 12.4662 33.0917 12.3449L18.8729 3.84489C18.7006 3.74198 18.5023 3.6875 18.3 3.6875C18.0978 3.6875 17.8995 3.74198 17.7272 3.84489L3.50845 12.3449C3.30587 12.4662 3.14955 12.6484 3.0632 12.8638C2.97684 13.0792 2.96518 13.3161 3.02996 13.5386C3.09475 13.761 3.23245 13.9569 3.42218 14.0964C3.61191 14.236 3.84332 14.3116 4.0813 14.3118ZM9.55005 14.3118H13.925V22.8118H9.55005V14.3118ZM20.4875 14.3118V22.8118H16.1125V14.3118H20.4875ZM27.05 22.8118H22.675V14.3118H27.05V22.8118ZM18.3 5.99645L28.6551 12.1868H7.94497L18.3 5.99645ZM34.7063 28.1243C34.7063 28.4061 34.5911 28.6764 34.3859 28.8756C34.1808 29.0749 33.9026 29.1868 33.6125 29.1868H2.98755C2.69747 29.1868 2.41927 29.0749 2.21415 28.8756C2.00903 28.6764 1.8938 28.4061 1.8938 28.1243C1.8938 27.8425 2.00903 27.5723 2.21415 27.373C2.41927 27.1738 2.69747 27.0618 2.98755 27.0618H33.6125C33.9026 27.0618 34.1808 27.1738 34.3859 27.373C34.5911 27.5723 34.7063 27.8425 34.7063 28.1243Z"
              fill="#667085"
            />
          </svg>
          <h3 className="text-center text-[18px] text-slate-600">
            Connect Bank
          </h3>
          {/*
            <Tooltip
              content={
                <div className="flex flex-col text-slate-600 text-[12px] text-left">
                  <p>
                    Select this option to automatically connect your bank
                    account with COUNT. Once linked, all your transactions will
                    be imported live, ensuring your financial data is always
                    up-to-date without any manual effort. Ideal for keeping
                    seamless track of your bank transactions in real time.
                  </p>
                </div>
              }
              size="sm"
              position="bottom"
            >
              <svg
                width="20"
                height="21"
                viewBox="0 0 20 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="10" cy="10.5" r="10" fill="#BDBDBD" />
                <rect
                  x="8.3335"
                  y="8.28125"
                  width="3.12047"
                  height="8.88887"
                  rx="1.56023"
                  fill="white"
                />
                <rect
                  x="8.3335"
                  y="3.83594"
                  width="3.12047"
                  height="3.33333"
                  rx="1.56023"
                  fill="white"
                />
              </svg>
            </Tooltip> */}
        </button>
      </div>
      <div className="w-[250px] flex flex-col gap-1">
        <button
          className="w-[250px] h-[4.688rem] pl-1.5 pr-5 gap-2.5 bg-white border border-indigo-500 text-slate-600 leading-6 flex justify-center items-center rounded-[6px]"
          onClick={() => setAccountsModalOpen(true)}
          type="button"
        >
          <svg
            width="36"
            height="35"
            viewBox="0 0 22 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_6001_1990)">
              <path
                d="M21.2675 12.1978H20.8034L18.4152 7.23569H18.7023C19.1069 7.23569 19.4348 6.89494 19.4348 6.47463C19.4348 6.05432 19.1069 5.71357 18.7023 5.71357H11.7103V5.04029C12.4725 4.73373 13.0151 3.96628 13.0151 3.06762C13.0151 1.90056 12.1012 0.951172 10.9778 0.951172C9.85453 0.951172 8.94074 1.90056 8.94074 3.06762C8.94074 3.96618 9.48339 4.73373 10.2453 5.04029V5.71357H3.25366C2.84901 5.71357 2.52114 6.05432 2.52114 6.47463C2.52114 6.89494 2.84901 7.23569 3.25366 7.23569H3.58485L1.19674 12.1978H0.732519C0.327876 12.1978 0 12.5385 0 12.9588C0 15.6914 2.13974 17.9145 4.76997 17.9145C7.40011 17.9145 9.53975 15.6914 9.53975 12.9588C9.53975 12.5385 9.21187 12.1978 8.80723 12.1978H8.3431L5.95519 7.23569H10.2454V17.5255H7.91902C7.51438 17.5255 7.1865 17.8662 7.1865 18.2866C7.1865 18.7069 7.51438 19.0476 7.91902 19.0476H14.1264C14.531 19.0476 14.8589 18.7069 14.8589 18.2866C14.8589 17.8662 14.531 17.5255 14.1264 17.5255H11.7104V7.23569H16.0449L13.657 12.1978H13.1929C12.7882 12.1978 12.4604 12.5385 12.4604 12.9588C12.4604 15.6914 14.6 17.9145 17.2301 17.9145C19.8604 17.9145 22.0001 15.6914 22.0001 12.9588C22 12.5385 21.6721 12.1978 21.2675 12.1978ZM10.9778 2.47319C11.2933 2.47319 11.5501 2.73986 11.5501 3.06762C11.5501 3.39539 11.2933 3.66196 10.9778 3.66196C10.6625 3.66196 10.4058 3.39539 10.4058 3.06762C10.4058 2.73986 10.6625 2.47319 10.9778 2.47319ZM4.76997 16.3924C3.19935 16.3924 1.88111 15.2481 1.54689 13.7199H1.64631L1.64875 13.72L1.65081 13.7199H7.88884L7.89089 13.72L7.89334 13.7199H7.99276C7.65873 15.2482 6.34049 16.3924 4.76997 16.3924ZM2.83446 12.1978L4.76987 8.17636L6.70509 12.1978H2.83446ZM17.23 8.17636L19.1654 12.1978H15.2947L17.23 8.17636ZM17.23 16.3924C15.6595 16.3924 14.3413 15.2481 14.0071 13.7199H14.1066L14.109 13.72L14.1111 13.7199H20.3491L20.3511 13.72L20.3536 13.7199H20.453C20.1189 15.2482 18.8006 16.3924 17.23 16.3924Z"
                fill="#667085"
              />
            </g>
            <defs>
              <clipPath id="clip0_6001_1990">
                <rect width="22" height="24" fill="white" />
              </clipPath>
            </defs>
          </svg>

          <h3 className="text-center text-[18px] text-slate-600">
            Add Manually
          </h3>
          {/**
            <Tooltip
              contentClassName="relative right-[7rem]"
              content={
                <div className="flex flex-col text-slate-600 text-[12px] text-left">
                  <p>
                    Select this option to automatically connect your bank
                    account with COUNT. Once linked, all your transactions will
                    be imported live, ensuring your financial data is always
                    up-to-date without any manual effort. Ideal for keeping
                    seamless track of your bank transactions in real time.
                  </p>
                </div>
              }
              size="small"
              position="bottom"
            >
              <svg
                width="20"
                height="21"
                viewBox="0 0 20 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="10" cy="10.5" r="10" fill="#BDBDBD" />
                <rect
                  x="8.3335"
                  y="8.28125"
                  width="3.12047"
                  height="8.88887"
                  rx="1.56023"
                  fill="white"
                />
                <rect
                  x="8.3335"
                  y="3.83594"
                  width="3.12047"
                  height="3.33333"
                  rx="1.56023"
                  fill="white"
                />
              </svg>
            </Tooltip> */}
        </button>
      </div>
    </div>
  </ModalBasic>
);

export default AccountChoiceModal;
