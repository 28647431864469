import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { skipLaunchpad } from '../../API/backend_helper';
import AkahuLink from '../settings/AkhahuLink/AkahuLink';

const AkahuLinkButton = () => (
  <button
    className="mt-2 flex items-center justify-center px-5 py-3 gap-2 rounded-[0.313rem] shadow-sm leading-6 text-[0.875rem] h-8 whitespace-nowrap bg-indigo-500 text-white"
    type="button"
  >
    Connect Account
  </button>
);

const LaunchPad = ({ team, setLaunchPadOpen, step, setStep }) => {
  const navigate = useNavigate();
  const [skipLoading, setSkipLoading] = useState(false);

  useEffect(() => {
    async function fetchStatus() {
      if (!team) return;

      const { accountConnections, users, bills, invoices } =
        team.launchpadStatus;

      const hasOrSkip = (status) => status?.has || status?.skip;

      if (!hasOrSkip(accountConnections)) {
        setStep(3);
      } else if (hasOrSkip(accountConnections) && !hasOrSkip(users)) {
        setStep(4);
      } else if (
        hasOrSkip(accountConnections) &&
        hasOrSkip(users) &&
        !hasOrSkip(bills)
      ) {
        setStep(5);
      } else if (
        hasOrSkip(accountConnections) &&
        hasOrSkip(users) &&
        hasOrSkip(bills) &&
        !hasOrSkip(invoices)
      ) {
        setStep(6);
      } else if (
        hasOrSkip(accountConnections) &&
        hasOrSkip(users) &&
        hasOrSkip(bills) &&
        hasOrSkip(invoices)
      ) {
        setStep(7);
      }
    }
    fetchStatus();
  }, [team?.id]);

  useEffect(() => {
    if (step === 7) {
      setLaunchPadOpen(false);
    }
  }, [step]);

  const skipStep = async (stepToSkip) => {
    setSkipLoading(true);
    try {
      const data = {
        [stepToSkip]: {
          skip: true,
        },
      };
      await skipLaunchpad(team?.id, data);
      setStep(step + 1);
    } catch (error) {
      console.error(error);
    }
    setSkipLoading(false);
  };

  return (
    <div className="flex-col items-start gap-4 self-stretch px-7 pb-7 pt-5 rounded-xl bg-[#FBF2EC] w-full mb-6 hidden sm:flex">
      <div className="flex items-start self-stretch gap-3">
        <div className="flex flex-col gap-3 items-start flex-grow flex-shrink-0 basis-0">
          <h2 className="text-black text-opacity-60 text-[1.5rem] font-medium leading-[2.375rem]">
            Launchpad
          </h2>
        </div>
        <p className="text-black text-opacity-60 text-center text-[1rem] font-semibold leading-[2.375rem]">
          {7 - step} more steps to complete
        </p>
      </div>
      <div className="flex items-start gap-5 self-stretch">
        {/* Step 1 */}
        <div className="flex flex-col items-start gap-[1.063rem] flex-grow flex-shrink-0 basis-0">
          <div className="h-2 self-stretch rounded-[100px] bg-[#78BD4F]" />
          <p className="text-slate-600 text-[1rem] font-medium leading-[1.125rem]">
            Welcome to COUNT
          </p>
          <p className="text-slate-600 text-[0.875rem] leading-[0.875rem]">
            You've taken the first step! Continue through the setup to fully
            activate all the powerful features of COUNT waiting for you.
          </p>
        </div>
        {/* Step 2 */}
        <div className="flex flex-col items-start gap-[1.063rem] flex-grow flex-shrink-0 basis-0 justify-between">
          <div className="h-2 self-stretch rounded-[100px] bg-[#78BD4F]" />
          <div className="flex flex-col items-start self-stretch gap-[1.063rem]">
            <p className="text-slate-600 text-[1rem] font-medium leading-[1.125rem]">
              Set Up for Success
            </p>
            <p className="text-slate-600 text-[0.875rem] leading-[0.875rem]">
              Provide your business information to unify your team under one
              accurate, professional profile, enhancing clarity across invoices
              and reports.
            </p>
          </div>
          {!(step >= 3) ? (
            <button
              className="flex items-center justify-center px-5 py-3 gap-2 rounded-[0.313rem] bg-indigo-500 shadow-sm text-white leading-6 text-[0.75rem] h-8 whitespace-nowrap"
              type="button"
            >
              Add Business Info
            </button>
          ) : (
            <div className="h-8" />
          )}
        </div>
        {/* Step 3 */}
        <div className="flex flex-col items-start flex-grow flex-shrink-0 basis-0 justify-between self-stretch">
          <div className="flex flex-col gap-[1.063rem] items-start self-stretch">
            <div
              className={`h-2 self-stretch rounded-[100px] ${
                step > 3 ? 'bg-[#78BD4F]' : 'bg-[#D9D9D9]'
              }`}
            />
            <p className="text-slate-600 text-[1rem] font-medium leading-[1.125rem]">
              Connect Your Accounts
            </p>
            <p className="text-slate-600 text-[0.875rem] leading-[0.875rem]">
              Linking your bank accounts allows for an automated flow of
              transactions, giving you a hassle-free, comprehensive view of your
              financial status.
            </p>
          </div>
          {!(step >= 4) &&
            (team?.country === 'NZ' ? (
              <div className="flex gap-2 items-center mt-2 ">
                <AkahuLink ButtonUI={AkahuLinkButton} />
                {step > 2 && (
                  <span
                    className="text-indigo-500 text-sm cursor-pointer underline mt-2"
                    onClick={(e) => {
                      e.stopPropagation();
                      if (skipLoading) return;
                      skipStep('accountConnections');
                    }}
                    role="button"
                    tabIndex="0"
                  >
                    Skip
                  </span>
                )}
              </div>
            ) : (
              <div className="flex gap-2 items-center mt-2 ">
                <button
                  className={`mt-2 flex items-center justify-center px-5 py-3 gap-2 rounded-[0.313rem] shadow-sm leading-6 text-[0.875rem] h-8 whitespace-nowrap ${
                    step > 2
                      ? 'bg-indigo-500 text-white'
                      : 'bg-[#D9D9D9] text-[#9399A3]'
                  }`}
                  disabled={step < 3}
                  onClick={() =>
                    navigate(`/settings/banks`, {
                      state: { choiceModalOpen: true },
                    })
                  }
                  type="button"
                >
                  Connect Account
                </button>
                {step > 2 && (
                  <span
                    className="text-indigo-500 text-sm cursor-pointer underline mt-2"
                    onClick={(e) => {
                      e.stopPropagation();
                      if (skipLoading) return;
                      skipStep('accountConnections');
                    }}
                    role="button"
                    tabIndex="0"
                  >
                    Skip
                  </span>
                )}
              </div>
            ))}
        </div>
        {/* Step 4 */}
        <div className="flex flex-col items-start flex-grow flex-shrink-0 basis-0 justify-between self-stretch">
          <div className="flex flex-col gap-[1.063rem] items-start self-stretch">
            <div
              className={`h-2 self-stretch rounded-[100px] ${
                step > 4 ? 'bg-[#78BD4F]' : 'bg-[#D9D9D9]'
              }`}
            />
            <p className="text-slate-600 text-[1rem] font-medium leading-[1.125rem]">
              Collaborate Efficiently
            </p>
            <p className="text-slate-600 text-[0.875rem] leading-[0.875rem]">
              Bring in your accountant and team members to share the workload.
              Our platform's collaborative features make managing finances
              easier and more effective.
            </p>
          </div>
          {!(step >= 5) && (
            <div className="flex gap-2 items-center mt-2 ">
              <button
                className={`flex items-center justify-center px-5 py-3 gap-2 rounded-[0.313rem] shadow-sm leading-6 text-[0.875rem] h-8 whitespace-nowrap ${
                  step > 3
                    ? 'bg-indigo-500 text-white'
                    : 'bg-[#D9D9D9] text-[#9399A3]'
                }`}
                disabled={step < 4}
                onClick={() => navigate(`/manage-workspace/members`)}
                type="button"
              >
                Invite Teammate
              </button>
              {step > 3 && (
                <span
                  className="text-indigo-500 text-sm cursor-pointer underline"
                  onClick={(e) => {
                    e.stopPropagation();
                    if (skipLoading) return;
                    skipStep('users');
                  }}
                  role="button"
                  tabIndex="0"
                >
                  Skip
                </span>
              )}
            </div>
          )}
        </div>
        {/* Step 5 */}
        <div className="flex flex-col items-start flex-grow flex-shrink-0 basis-0 justify-between self-stretch">
          <div className="flex flex-col gap-[1.063rem] items-start self-stretch">
            <div
              className={`h-2 self-stretch rounded-[100px] ${
                step > 5 ? 'bg-[#78BD4F]' : 'bg-[#D9D9D9]'
              }`}
            />
            <p className="text-slate-600 text-[1] font-medium leading-[1.125rem]">
              Anticipate Expenses
            </p>
            <p className="text-slate-600 text-[0.875rem] leading-[0.875rem]">
              Enter your expected expenses to maintain awareness of upcoming
              payments. This foresight helps in planning and ensures no bill
              goes unpaid.{' '}
            </p>
          </div>
          {!(step >= 6) && (
            <div className="flex gap-2 items-center mt-2 ">
              <button
                className={`flex items-center justify-center px-5 py-3 gap-2 rounded-[0.313rem] shadow-sm leading-6 text-[0.875rem] h-8 whitespace-nowrap ${
                  step > 4
                    ? 'bg-indigo-500 text-white'
                    : 'bg-[#D9D9D9] text-[#9399A3]'
                }`}
                disabled={step < 5}
                onClick={() =>
                  navigate(`/bills`, { state: { isActive: true } })
                }
                type="button"
              >
                Create a Bill
              </button>
              {step > 4 && (
                <span
                  className="text-indigo-500 text-sm cursor-pointer underline"
                  onClick={(e) => {
                    e.stopPropagation();
                    if (skipLoading) return;
                    skipStep('bills');
                  }}
                  role="button"
                  tabIndex="0"
                >
                  Skip
                </span>
              )}
            </div>
          )}
        </div>
        {/* Step 6 */}
        <div className="flex flex-col items-start flex-grow flex-shrink-0 basis-0 justify-between self-stretch">
          <div className="flex flex-col gap-[1.063rem] items-start self-stretch">
            <div
              className={`h-2 self-stretch rounded-[100px] ${
                step > 6 ? 'bg-[#78BD4F]' : 'bg-[#D9D9D9]'
              }`}
            />
            <p className="text-slate-600 text-[1rem] font-medium leading-[1.125rem]">
              Streamline Payments
            </p>
            <p className="text-slate-600 text-[0.875rem] leading-[0.875rem]">
              Generate invoices effortlessly to remind your customers about
              payments. This not only secures your cash flow but also keeps
              receivables on track.
            </p>
          </div>
          {!(step >= 7) && (
            <div className="flex gap-2 items-center mt-2 ">
              <button
                disabled={step < 6}
                className={`flex items-center justify-center px-5 py-3 gap-2 rounded-[0.313rem] shadow-sm leading-6 text-[0.875rem] h-8 whitespace-nowrap ${
                  step > 5
                    ? 'bg-indigo-500 text-white'
                    : 'bg-[#D9D9D9] text-[#9399A3]'
                }`}
                onClick={() =>
                  navigate(`/invoices`, { state: { dashboard: true } })
                }
                type="button"
              >
                Send an Invoice
              </button>
              {step > 5 && (
                <span
                  className="text-indigo-500 text-sm cursor-pointer underline"
                  onClick={(e) => {
                    e.stopPropagation();
                    if (skipLoading) return;
                    skipStep('invoices');
                  }}
                  role="button"
                  tabIndex="0"
                >
                  Skip
                </span>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default LaunchPad;
