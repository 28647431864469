import React from 'react';
import { Handle } from 'reactflow';
import { Cube } from '../svg';

const ConditionNode = ({
  id,
  data,
  setActiveNodeId,
  setPanelOpen,
  setPanelStep,
  setIsEditing,
  activeHoverNodeId,
  deleteNode,
}) => {
  const handleClick = () => {
    setIsEditing(true);
    setActiveNodeId(id);
    setPanelOpen(true);
    setPanelStep('condition1');
  };
  return (
    <div
      className="relative p-4 w-[23.625rem] flex flex-col items-start gap-2.5 rounded-[15px] border border-[#2575F4] bg-white cursor-pointer"
      onClick={() => handleClick()}
      role="button"
      tabIndex="0"
    >
      {id === activeHoverNodeId && (
        <button
          type="button"
          className="absolute top-1/2 -translate-y-1/2 -right-2 translate-x-full"
          onClick={(e) => {
            e.stopPropagation();
            setIsEditing(false);
            setActiveNodeId(null);
            setPanelOpen(false);
            deleteNode(id);
          }}
        >
          <svg
            width="37"
            height="37"
            viewBox="0 0 37 37"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13.2879 36.1084H23.8205C32.5976 36.1084 36.1084 32.5976 36.1084 23.8205V13.2879C36.1084 4.51084 32.5976 1 23.8205 1H13.2879C4.51084 1 1 4.51084 1 13.2879V23.8205C1 32.5976 4.51084 36.1084 13.2879 36.1084Z"
              fill="white"
              stroke="#D0D5DD"
              strokeOpacity="0.6"
              strokeWidth="1.35032"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M25.875 11.75H12.125C11.8764 11.75 11.6379 11.8488 11.4621 12.0246C11.2863 12.2004 11.1875 12.4389 11.1875 12.6875C11.1875 12.9361 11.2863 13.1746 11.4621 13.3504C11.6379 13.5262 11.8764 13.625 12.125 13.625H12.4375V24.25C12.4375 24.6644 12.6021 25.0618 12.8951 25.3549C13.1882 25.6479 13.5856 25.8125 14 25.8125H24C24.4144 25.8125 24.8118 25.6479 25.1049 25.3549C25.3979 25.0618 25.5625 24.6644 25.5625 24.25V13.625H25.875C26.1236 13.625 26.3621 13.5262 26.5379 13.3504C26.7137 13.1746 26.8125 12.9361 26.8125 12.6875C26.8125 12.4389 26.7137 12.2004 26.5379 12.0246C26.3621 11.8488 26.1236 11.75 25.875 11.75ZM23.6875 23.9375H14.3125V13.625H23.6875V23.9375ZM14.9375 9.5625C14.9375 9.31386 15.0363 9.0754 15.2121 8.89959C15.3879 8.72377 15.6264 8.625 15.875 8.625H22.125C22.3736 8.625 22.6121 8.72377 22.7879 8.89959C22.9637 9.0754 23.0625 9.31386 23.0625 9.5625C23.0625 9.81114 22.9637 10.0496 22.7879 10.2254C22.6121 10.4012 22.3736 10.5 22.125 10.5H15.875C15.6264 10.5 15.3879 10.4012 15.2121 10.2254C15.0363 10.0496 14.9375 9.81114 14.9375 9.5625Z"
              fill="#667085"
              fillOpacity="0.8"
            />
          </svg>
        </button>
      )}
      <Handle
        type="target"
        position="top"
        className="opacity-0 mt-2"
        isConnectable={false}
      />

      <div className="flex items-start gap-2.5">
        <div className="w-11 h-11 gap-2.5 flex items-center justify-center py-4 px-2.5 rounded-[10px] bg-[#E7F0FE]">
          <Cube />
        </div>
        <div className="flex flex-col items-start">
          <p className="text-[#0F0F0F] text-[1rem] break-words">{data?.name}</p>
          <p className="text-slate-600 text-[0.875rem] leading-4">
            {data?.description && data?.description}
          </p>
        </div>
      </div>
      <Handle
        type="source"
        position="bottom"
        className="opacity-0 mb-2"
        isConnectable={false}
      />
    </div>
  );
};

export default ConditionNode;
