import React from "react";
import { useNavigate, Link } from "react-router-dom";
import AuthSlider from "../components/AuthSlider";
import Logo from "../images/custom/count-logo.png";

const WorkspaceWelcome = () => {
  const navigate = useNavigate();

  const onStartFreeTrial = () => {
    navigate(`/`);
    window.location.reload();
  };

  return (
    <main className="bg-white dark:bg-slate-900">
      <div className="relative md:flex">
        {/* Content */}
        <div className="md:w-1/2">
          <div className="min-h-[100dvh] h-full flex flex-col after:flex-1">
            {/* Header */}
            <div className="flex-1">
              <div className="relative flex items-center justify-between h-16 px-4 sm:px-6 lg:px-8">
                {/* Logo */}
                <Link className="block" to="/">
                  <img src={Logo} alt="logo" className="h-8" />
                </Link>
              </div>
            </div>

            <div className="max-w-[510px] min-w-[50%] mx-auto w-full px-4 py-8">
              <div className="flex flex-col items-center justify-center">
                <div className="h-[70px] w-[70px] bg-[#FFF2EA] border-[8.5px] border-[#FFFAF6] rounded-full inline-flex justify-center items-center">
                  <svg
                    width="22"
                    height="23"
                    viewBox="0 0 25 27"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M22.7708 7.79513L12.5 13.5011M12.5 13.5011L2.22913 7.79513M12.5 13.5011L12.5 24.9803M23.375 18.4053V8.59709C23.375 8.18307 23.375 7.97605 23.314 7.79142C23.26 7.62808 23.1718 7.47815 23.0552 7.35165C22.9235 7.20866 22.7425 7.10813 22.3806 6.90706L13.4389 1.93946C13.0962 1.74908 12.9249 1.65389 12.7434 1.61657C12.5828 1.58354 12.4172 1.58354 12.2566 1.61657C12.0751 1.65389 11.9038 1.74908 11.5611 1.93946L2.61942 6.90706C2.2575 7.10813 2.07654 7.20866 1.94476 7.35165C1.82819 7.47815 1.73997 7.62809 1.686 7.79142C1.625 7.97606 1.625 8.18307 1.625 8.5971V18.4053C1.625 18.8193 1.625 19.0263 1.686 19.2109C1.73997 19.3743 1.82819 19.5242 1.94477 19.6507C2.07654 19.7937 2.2575 19.8942 2.61942 20.0953L11.5611 25.0629C11.9038 25.2533 12.0751 25.3485 12.2566 25.3858C12.4172 25.4188 12.5828 25.4188 12.7434 25.3858C12.9249 25.3485 13.0962 25.2533 13.4389 25.0629L22.3806 20.0953C22.7425 19.8942 22.9235 19.7937 23.0552 19.6507C23.1718 19.5242 23.26 19.3743 23.314 19.2109C23.375 19.0263 23.375 18.8193 23.375 18.4053Z"
                      stroke="#E48642"
                      stroke-width="2.41667"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
                <h3 className="text-[24px] md:text-[40px] text-[#232323CC] font-medium text-center leading-tight mt-4">
                  Start your 14 day free trial
                </h3>
                <p className="text-lg text-[#667085CC] leading-tight mt-2 text-center">
                  Get a free one month on us!
                </p>
                <div className="w-full border border-[#D0D5DD] rounded-[10px] py-6 mt-6 shadow-lg">
                  <div className="px-6 border-b border-[#D0D5DD] pb-4">
                    <div className="h-10 px-4 bg-[#FBEFE6] rounded-full text-[20px] text-indigo-500 font-medium inline-flex items-center leading-tight">
                      14 days free trial
                    </div>
                    <p className="text-base leading-tight text-[#667085] mt-2">
                      No credit card required. Free for 14 days
                    </p>
                  </div>
                  <div className="px-6 pt-6">
                    <h3 className="text-[20px] font-medium text-[#101828] mb-6 leading-tight">
                      Features
                    </h3>
                    <div className="flex gap-3 mb-4">
                      <svg
                        width="22"
                        height="22"
                        viewBox="0 0 22 22"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M6.5 11L9.5 14L15.5 8M21 11C21 16.5228 16.5228 21 11 21C5.47715 21 1 16.5228 1 11C1 5.47715 5.47715 1 11 1C16.5228 1 21 5.47715 21 11Z"
                          stroke="#E48642"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                      <p className="text-base text-[#667085] leading-tight">
                        Review & categorize transactions effortlessly
                      </p>
                    </div>
                    <div className="flex gap-3 mb-4">
                      <svg
                        width="22"
                        height="22"
                        viewBox="0 0 22 22"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M6.5 11L9.5 14L15.5 8M21 11C21 16.5228 16.5228 21 11 21C5.47715 21 1 16.5228 1 11C1 5.47715 5.47715 1 11 1C16.5228 1 21 5.47715 21 11Z"
                          stroke="#E48642"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                      <p className="text-base text-[#667085] leading-tight">
                        Simplify complex payments with split options
                      </p>
                    </div>
                    <div className="flex gap-3 mb-3">
                      <svg
                        width="22"
                        height="22"
                        viewBox="0 0 22 22"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M6.5 11L9.5 14L15.5 8M21 11C21 16.5228 16.5228 21 11 21C5.47715 21 1 16.5228 1 11C1 5.47715 5.47715 1 11 1C16.5228 1 21 5.47715 21 11Z"
                          stroke="#E48642"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                      <p className="text-base text-[#667085] leading-tight">
                        Collaborate with teammates in your workspace
                      </p>
                    </div>
                    <div className="flex gap-3 mb-3">
                      <svg
                        width="22"
                        height="22"
                        viewBox="0 0 22 22"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M6.5 11L9.5 14L15.5 8M21 11C21 16.5228 16.5228 21 11 21C5.47715 21 1 16.5228 1 11C1 5.47715 5.47715 1 11 1C16.5228 1 21 5.47715 21 11Z"
                          stroke="#E48642"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                      <p className="text-base text-[#667085] leading-tight">
                        Optimize spending by monitoring category-level insights
                      </p>
                    </div>
                  </div>
                </div>
                <button
                  className="btn mt-6 w-full  py-4 text-lg rounded-[10px] font-semibold !bg-indigo-500 hover:bg-indigo-600 text-white
                           disabled:!bg-indigo-300 disabled:text-white disabled:cursor-not-allowed"
                  onClick={onStartFreeTrial}
                >
                  Start Your Free Trial
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* Auth Slider */}

        <AuthSlider />
      </div>
    </main>
  );
};

export default WorkspaceWelcome;
