import React from "react";
import ModalBasic from "../../../components/ModalBasic";
import moment from "moment";

const KeyValuePair = ({ option, value }) => {
  return (
    <div className="flex gap-2 w-full max-w-full overflow-hidden ">
      <pre className="font-semibold whitespace-nowrap text-slate-600">
        {option}:
      </pre>
      <code className=" flex-1 overflow-hidden w-fit">
        <span className="max-w-full w-full overflow-hidden break-words">
          {value}
        </span>
      </code>
    </div>
  );
};

const EnrichLogsModal = ({ isOpen, setIsOpen, selectedRow }) => {
  return (
    <ModalBasic
      modalOpen={isOpen}
      setModalOpen={setIsOpen}
      title="Details"
      smallModal
      width="max-w-screen-md"
    >
      <div className="py-[1.875rem] pt-1 px-5 ">
        <>
          {typeof selectedRow === "object" &&
            Object.keys(selectedRow)?.map((key) =>
              selectedRow?.[key] !== null &&
              typeof selectedRow?.[key] === "object" &&
              Object.keys(selectedRow?.[key] || {})?.length > 0 ? (
                <div>
                  <span className="font-semibold whitespace-nowrap text-slate-600">
                    {key}:
                  </span>

                  <div className="ml-4">
                    {Object.keys(selectedRow?.[key])?.map?.((key2) =>
                      selectedRow?.[key]?.[key2] !== "object" ? (
                        <KeyValuePair
                          option={key2}
                          value={JSON.stringify(selectedRow?.[key]?.[key2])}
                        />
                      ) : (
                        <></>
                      )
                    )}
                  </div>
                </div>
              ) : typeof selectedRow?.[key] === "string" ? (
                <KeyValuePair option={key} value={selectedRow?.[key]} />
              ) : (
                <>
                  <span className="font-semibold whitespace-nowrap text-slate-600">
                    {key} :
                  </span>{" "}
                  {JSON.stringify(selectedRow?.[key])}
                </>
              )
            )}
        </>
      </div>
    </ModalBasic>
  );
};

export default EnrichLogsModal;
