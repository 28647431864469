import React, { useState, useEffect, useRef, useMemo } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import { useSelector } from 'react-redux';
import Tooltip from 'components/Tooltip';
import ModalBasic from '../../../components/ModalBasic';
import DropdownFull from '../../../components/DropdownFull';
import TextInputDash from '../../../components/TextInputDash';
import DatePickerDash from '../../../components/DatePickerDash';
import { Loader } from '../../../components/Svg';

const types = [
  { name: 'To-Do', id: 'toDo' },
  { name: 'Review Transaction', id: 'reviewTransaction' },
  { name: 'Set Transaction Vendor', id: 'transactionVendor' },
  { name: 'Set Transaction Category', id: 'transactionCategory' },
  { name: 'Pay Bill', id: 'payBill' },
  { name: 'Upload Transaction Attachment', id: 'transactionAttachment' },
];

const EditTaskModal = ({
  modalOpen,
  setModalOpen,
  task,
  handleUpdateTask,
  projects = [],
  setTask,
  loadData,
}) => {
  const formikRef = useRef(null);
  const { team } = useSelector((state) => state.Team);

  const [noClick, setNoClick] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const options = useMemo(
    () => ({
      mode: 'single',
      static: false,
      monthSelectorType: 'static',
      allowInput: true,
      dateFormat:
        team?.defaults?.dateFormat === 'dd/mm/yyyy' ? 'd/m/Y' : 'm/d/Y',
      position: 'bottom',
      prevArrow:
        '<svg class="fill-current" width="7" height="11" viewBox="0 0 7 11"><path d="M5.4 10.8l1.4-1.4-4-4 4-4L5.4 0 0 5.4z" /></svg>',
      nextArrow:
        '<svg class="fill-current" width="7" height="11" viewBox="0 0 7 11"><path d="M1.4 10.8L0 9.4l4-4-4-4L1.4 0l5.4 5.4z" /></svg>',
      onOpen() {
        setNoClick(true);
      },
      onClose() {
        setNoClick(false);
      },
    }),
    [team],
  );

  const handleSubmit = async (values) => {
    setIsLoading(true);
    let formattedDate;
    if (values.deadlineDate) {
      formattedDate = moment(values.deadlineDate).format('yyyy-MM-DD');
    } else {
      formattedDate = null;
    }
    try {
      const res = await handleUpdateTask(task?.id, {
        ...values,
        deadlineDate: formattedDate,
      });
      setTask(res);
      if (loadData) loadData(false);
      setModalOpen(false);
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (!modalOpen) {
      formikRef.current.resetForm();
    }
  }, [modalOpen]);

  return (
    <ModalBasic
      title="Edit Task"
      modalOpen={modalOpen}
      setModalOpen={setModalOpen}
      noClick={noClick}
      width="!w-[800px]"
    >
      <Formik
        enableReinitialize
        initialValues={{
          name: task?.name || '',
          type: task?.type || '',
          description: task?.description || '',
          assigneeId: task?.assigneeId || null,
          status: task?.status || '',
          deadlineDate: task?.deadlineDate || '',
          priority: task?.priority || '',
          projectId: task?.projectId || null,
        }}
        validationSchema={Yup.object({
          name: Yup.string()
            .required('Task title is required')
            .max(
              255,
              'Task title must be less than or equal to 255 characters',
            ),
          type: Yup.string(),
          description: Yup.string(),
          status: Yup.string(),
          deadlineDate: Yup.string(),
          priority: Yup.string().required('Priority is required'),
        })}
        onSubmit={handleSubmit}
        innerRef={formikRef}
      >
        {(validation) => (
          <Form>
            <div className="py-[1.875rem] flex flex-col items-start gap-[1rem]">
              <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-4 px-7">
                <div className="flex flex-col gap-2.5 w-full">
                  <p className="text-slate-600 text-[14px] font-medium leading-5">
                    Task Type <span className="text-red-500">*</span>
                  </p>
                  <DropdownFull
                    options={types}
                    placeholder="Select Task Type"
                    name="name"
                    selected={validation.values.type}
                    setSelected={(value) =>
                      validation.setFieldValue('type', value)
                    }
                    scrollIntoView={false}
                    height="h-10"
                  />
                </div>
                <div className="flex flex-col gap-2.5 w-full">
                  <p className="text-slate-600 text-[14px] font-medium leading-5">
                    Task Title <span className="text-red-500">*</span>
                  </p>
                  <TextInputDash
                    placeholder="Enter Task Title"
                    value={validation.values.name}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    paddingBottom="pb-0"
                    name="name"
                    inputClass="!h-10 !mb-0"
                  />
                  {validation.touched.name && validation.errors.name ? (
                    <span className="text-xs text-rose-500 h-[14px] -mt-1">
                      {validation.errors.name}
                    </span>
                  ) : null}
                </div>
                <div className="flex flex-col gap-2.5 w-full">
                  <p className="text-slate-600 text-[14px] font-medium leading-5">
                    Assigned To{' '}
                    {validation.values.assigneeId && (
                      <span
                        className="ml-2 font-medium cursor-pointer text-indigo-500 hover:underline"
                        onClick={() =>
                          validation.setFieldValue('assigneeId', null)
                        }
                        role="button"
                        tabIndex="0"
                      >
                        Clear
                      </span>
                    )}
                  </p>
                  <DropdownFull
                    options={team.users}
                    selected={validation.values.assigneeId}
                    setSelected={(value) =>
                      validation.setFieldValue('assigneeId', value)
                    }
                    users
                    height="h-10"
                    scrollIntoView={false}
                  />
                  {validation.touched.assigneeId &&
                  validation.errors.assigneeId ? (
                    <span className="text-xs text-rose-500 h-[14px] -mt-1">
                      {validation.errors.assigneeId}
                    </span>
                  ) : null}
                </div>
                <div className="flex flex-col gap-2.5 w-full">
                  <p className="text-slate-600 text-[14px] font-medium leading-5">
                    Created By <span className="text-red-500">*</span>
                  </p>
                  <DropdownFull
                    options={team.users}
                    selected={task?.createdById}
                    setSelected={(value) =>
                      validation.setFieldValue('createdById', value)
                    }
                    users
                    height="h-10"
                    disabled
                    inbox
                    scrollIntoView={false}
                  />
                </div>

                <div className="flex flex-col gap-2.5 w-full">
                  <p className="text-slate-600 text-[14px] font-medium leading-5">
                    Priority <span className="text-red-500">*</span>
                  </p>
                  <DropdownFull
                    options={[
                      { name: 'High Priority', id: 'high' },
                      { name: 'Medium Priority', id: 'medium' },
                      { name: 'Low Priority', id: 'low' },
                    ]}
                    placeholder="Select Priority"
                    name="name"
                    selected={validation.values.priority}
                    setSelected={(value) =>
                      validation.setFieldValue('priority', value)
                    }
                    scrollIntoView={false}
                    height="h-10"
                    priorityDot
                  />
                  {validation.touched.priority && validation.errors.priority ? (
                    <span className="text-xs text-rose-500 h-[14px] -mt-1">
                      {validation.errors.priority}
                    </span>
                  ) : null}
                </div>
                <div className="flex flex-col gap-2.5 w-full">
                  <label className="text-slate-600 font-medium text-[14px] leading-5 flex gap-2 items-center">
                    Project
                  </label>
                  <DropdownFull
                    options={projects}
                    selected={validation.values.projectId}
                    setSelected={(value) =>
                      validation.setFieldValue('projectId', value)
                    }
                    name="name"
                    scrollIntoView={false}
                    height="h-10"
                  />
                  {validation.touched.projectId &&
                  validation.errors.projectId ? (
                    <span className="text-xs text-rose-500 h-[14px] -mt-1">
                      {validation.errors.projectId}
                    </span>
                  ) : null}
                </div>
                <div className="flex flex-col gap-2.5 w-full">
                  <p className="text-slate-600 text-[14px] font-medium leading-5">
                    Deadline Date
                  </p>
                  <DatePickerDash
                    required
                    validation={validation}
                    placeholder="Select Date"
                    value={
                      validation.values.deadlineDate
                        ? moment(validation.values.deadlineDate).toISOString()
                        : null
                    }
                    options={options}
                    onChange={(selectedDates) => {
                      validation.setFieldValue(
                        'deadlineDate',
                        selectedDates[0],
                      );
                    }}
                    name="deadlineDate"
                    id="deadlineDate"
                    error={
                      validation.touched.deadlineDate &&
                      validation.errors.deadlineDate
                        ? validation.errors.deadlineDate
                        : ''
                    }
                    inputClass="!h-10"
                  />
                </div>
                <div className="flex flex-col gap-2.5 w-full">
                  <p className="text-slate-600 text-[14px] font-medium leading-5">
                    Current Status <span className="text-red-500">*</span>
                  </p>
                  <DropdownFull
                    options={[
                      { name: 'Not Started', id: 'notStarted' },
                      { name: 'In Progress', id: 'inProgress' },
                      { name: 'Review', id: 'review' },
                      { name: 'Completed', id: 'completed' },
                    ]}
                    placeholder="Select Status"
                    name="name"
                    selected={validation.values.status}
                    setSelected={(value) =>
                      validation.setFieldValue('status', value)
                    }
                    scrollIntoView={false}
                    height="h-10"
                  />
                </div>
                <div className="col-span-full flex flex-col gap-2.5 w-full">
                  <p className="text-slate-600 text-[14px] font-medium leading-5">
                    Task Description
                  </p>
                  <TextInputDash
                    placeholder="Details about the task"
                    value={validation.values.description}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    name="description"
                    paddingBottom="pb-0"
                    type="textarea"
                  />
                  {validation.touched.description &&
                  validation.errors.description ? (
                    <span className="text-xs text-rose-500 h-[14px] -mt-1">
                      {validation.errors.description}
                    </span>
                  ) : null}
                </div>
              </div>
              <div className="flex  justify-end w-full gap-4 px-7 pt-7 border-t border-[#D0D5DD]">
                <button
                  className="w-fit border border-indigo-500  text-indigo-500 rounded-[5px] h-11 py-2.5 px-[18px] flex items-center justify-center leading-6 text-[16px] font-base"
                  type="button"
                  onClick={() => setModalOpen(false)}
                >
                  Cancel
                </button>
                <button
                  className="w-[140px] border border-indigo-500 bg-indigo-500 text-white rounded-[5px] h-11 py-2.5 px-[18px] flex items-center justify-center leading-6 text-[16px] font-base
             disabled:bg-indigo-300 disabled:cursor-not-allowed"
                  type="submit"
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <Loader width="w-4" height="h-4" color="#FFFFFF" />
                  ) : (
                    <span>Save Changes</span>
                  )}
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </ModalBasic>
  );
};

export default EditTaskModal;
