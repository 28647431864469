import React, { useEffect, useRef, useState, useMemo } from 'react';
import Flatpickr from 'react-flatpickr';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { useSelector } from 'react-redux';
import {
  getSpendingBreakdown,
  getTopSpendingCategories,
  getTopSpendingVendors,
  getMonthlySpending,
  getTransactions,
} from '../../API/backend_helper';
import CategoriesDropdown from '../transactions/CategoriesDropdown';
import {
  dateCategoryList,
  formatNumber,
  getContrast,
  panelAmountColor,
} from '../../utils/Utils';
import PieChart from '../../charts/PieChart';
import LineChartInsights from '../../charts/LineChartInsights';
import Tooltip from '../../components/Tooltip';

const SpendingBreakdown = ({
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  topSpendingCategories,
  setTopSpendingCategories,
  handleFlyoutOpen,
  setActiveTab,
  generateMonthHeaders,
  selectedRange,
  setSelectedRange,
  teamCurrency,
  defaultStart,
  defaultEnd,
  setActiveVendorId,
  setVendorFlyoutOpen,
}) => {
  const { team } = useSelector((state) => state.Team);

  const [isLoading, setIsLoading] = useState(false);
  const firstLoad = useRef();

  const dateFormats = useMemo(() => dateCategoryList(), []);
  const [spendingData, setSpendingData] = useState([]);
  const [monthlySpending, setMonthlySpending] = useState([]);
  const [topSpendingVendors, setTopSpendingVendors] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const [chartData, setChartData] = useState([]);

  const navigate = useNavigate();

  const onDateChange = async (start, end, rangeChange = false) => {
    // eslint-disable-next-line no-use-before-define
    loadData(start, end);
    setStartDate(start);
    setEndDate(end);
    if (!rangeChange) {
      setSelectedRange('custom');
    }
  };

  const options = useMemo(
    () => ({
      mode: 'range',
      static: true,
      monthSelectorType: 'static',
      allowInput: true,
      dateFormat:
        team?.defaults?.dateFormat === 'dd/mm/yyyy' ? 'd/m/Y' : 'm/d/Y',
      prevArrow:
        '<svg class="fill-current" width="7" height="11" viewBox="0 0 7 11"><path d="M5.4 10.8l1.4-1.4-4-4 4-4L5.4 0 0 5.4z" /></svg>',
      nextArrow:
        '<svg class="fill-current" width="7" height="11" viewBox="0 0 7 11"><path d="M1.4 10.8L0 9.4l4-4-4-4L1.4 0l5.4 5.4z" /></svg>',
      onOpen: () => {},
      onClose: (selectedDates) => {
        onDateChange(selectedDates?.[0], selectedDates?.[1]);
      },
      onChange: (selectedDates) => {
        if (selectedDates?.length > 0) {
          setStartDate(selectedDates?.[0]);
          setEndDate(selectedDates?.[1]);
        }
      },
    }),
    [team],
  );

  const transformData = (originalData) => {
    const labelMap = {
      operatingExpense: 'Operating Expenses',
      costOfGoodsSold: 'Cost of Goods',
      payrollExpense: 'Payroll Spending',
      other: 'Other',
    };

    const filteredEntries = Object.entries(originalData).filter(
      ([key, value]) => key !== 'total' && value !== 0,
    );

    const labels = filteredEntries.map(([key]) => labelMap[key] || key);

    const dataValues = filteredEntries.map(([, value]) =>
      Number(((value / originalData.total) * 100).toFixed(2)),
    );

    const transformedData = {
      labels,
      datasets: [
        {
          data: dataValues,
          backgroundColor: ['#90BE6D', '#F8961E', '#F94144', '#F9C74F'],
        },
      ],
    };

    setChartData(transformedData);
  };

  const fetchSpendingBreakdown = async (start, end) => {
    const res = await getSpendingBreakdown({
      startDate: start ? moment(start).format('YYYY-MM-DD') : '',
      endDate: end ? moment(end).format('YYYY-MM-DD') : '',
    });
    setSpendingData(res?.data);
    transformData(res?.data);
  };

  const fetchTopSpendingCategories = async (start, end) => {
    const res = await getTopSpendingCategories({
      startDate: start ? moment(start).format('YYYY-MM-DD') : '',
      endDate: end ? moment(end).format('YYYY-MM-DD') : '',
    });
    setTopSpendingCategories(Object.values(res?.data));
  };

  const fetchTopSpendingVendors = async (start, end) => {
    const res = await getTopSpendingVendors({
      startDate: start ? moment(start).format('YYYY-MM-DD') : '',
      endDate: end ? moment(end).format('YYYY-MM-DD') : '',
    });
    setTopSpendingVendors(Object.values(res?.data));
  };

  const fetchMonthlySpending = async (start, end) => {
    const res = await getMonthlySpending({
      startDate: start ? moment(start).format('YYYY-MM-DD') : '',
      endDate: end ? moment(end).format('YYYY-MM-DD') : '',
    });
    const formattedData = Object.entries(res?.data).map(([month, amount]) => ({
      month: month.substring(0, 7),
      amount,
    }));
    setMonthlySpending(formattedData);
  };

  const fetchTopTransactions = async (start, end) => {
    const res = await getTransactions({
      limit: 5,
      page: 1,
      startDate: start ? moment(start).format('YYYY-MM-DD') : '',
      endDate: end ? moment(end).format('YYYY-MM-DD') : '',
      transactionTypes: 'Expense',
      orderBy: 'amount',
    });
    setTransactions(res?.transactions);
  };

  const handleRangeSelection = async (id) => {
    const rangeChange = true;
    const range = dateFormats
      .flatMap((group) => group.categories)
      .find((category) => category.id === id);
    onDateChange(range?.startDate, range?.endDate, rangeChange);
  };

  const loadData = async (start, end) => {
    setIsLoading(true);
    await fetchSpendingBreakdown(start, end);
    await fetchTopSpendingCategories(start, end);
    await fetchTopSpendingVendors(start, end);
    await fetchTopTransactions(start, end);
    await fetchMonthlySpending(start, end);
    setIsLoading(false);
  };

  useEffect(() => {
    if (startDate && endDate && !firstLoad?.current) {
      firstLoad.current = true;
      loadData(startDate, endDate);
    }
  }, [startDate, endDate]);

  return isLoading ? (
    <div className="flex flex-col gap-2 items-center pb-2 justify-center mt-8">
      <svg
        width="29"
        height="29"
        viewBox="0 0 29 29"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="animate-spin w-10 h-10 fill-current shrink-0"
      >
        <path
          d="M14.5 2.41699V7.25033"
          stroke="#E48642"
          strokeWidth="2.55882"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.5 21.749V26.5824"
          stroke="#E48642"
          strokeWidth="2.55882"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.95703 5.95703L9.37662 9.37662"
          stroke="#E48642"
          strokeWidth="1.58955"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M19.623 19.6211L23.0426 23.0407"
          stroke="#E48642"
          strokeWidth="1.58955"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2.41699 14.5H7.25033"
          stroke="#E48642"
          strokeWidth="1.58955"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M21.749 14.5H26.5824"
          stroke="#E48642"
          strokeWidth="1.58955"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.95703 23.0407L9.37662 19.6211"
          stroke="#E48642"
          strokeWidth="1.58955"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M19.623 9.37662L23.0426 5.95703"
          stroke="#E48642"
          strokeWidth="1.58955"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
      <p className="text-slate-600 font-medium text-[0.875rem] text-center">
        Fetching data, please wait...
      </p>
    </div>
  ) : (
    <>
      <div className="flex flex-col sm:flex-row items-center gap-5 w-full mt-8 sm:mt-0">
        <div className="w-full min-w-[100px] max-w-[200px]">
          <CategoriesDropdown
            allCategories={[
              ...dateFormats,
              {
                name: 'Custom',
                categories: [{ name: 'Custom', id: 'custom' }],
              },
            ]}
            selectedCategoryId={selectedRange}
            setSelectedCategoryId={(value) => {
              setSelectedRange(value);
              handleRangeSelection(value);
            }}
            isSetCategoryStyle
            height="h-10"
            type="date"
          />
        </div>
        <div className="relative">
          <Flatpickr
            className={`form-input border-[#D0D5DD] hover:border-slate-400 pl-3 pr-12
                    !leading-6 py-0 h-10 placeholder:text-[#667085CC] text-[#667085CC]
                      hover:text-slate-600 min-w-[270px] cursor-pointer
                      ${
                        startDate || endDate
                          ? '!border-indigo-500   placeholder:!text-indigo-500 !text-indigo-500 bg-[#E4864205]'
                          : ''
                      }`}
            options={options}
            placeholder="Select Custom Date Range"
            value={[startDate, endDate]}
            autoComplete="off"
          />
          {(startDate !== defaultStart || endDate !== defaultEnd) && (
            <button
              onClick={() => {
                setStartDate(defaultStart);
                setEndDate(defaultEnd);
                onDateChange(defaultStart, defaultEnd);
              }}
              className="absolute top-0 right-1  h-full group p-2 flex items-center"
              type="button"
            >
              <svg
                className="w-4 h-4 fill-indigo-300 group-hover:fill-indigo-500 pointer-events-none"
                viewBox="0 0 16 16"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="m7.95 6.536 4.242-4.243a1 1 0 1 1 1.415 1.414L9.364 7.95l4.243 4.242a1 1 0 1 1-1.415 1.415L7.95 9.364l-4.243 4.243a1 1 0 0 1-1.414-1.415L6.536 7.95 2.293 3.707a1 1 0 0 1 1.414-1.414L7.95 6.536Z" />
              </svg>
            </button>
          )}
        </div>
      </div>
      {/* top analytic area */}
      <div className="flex flex-col sm:flex-row gap-5 w-full self-stretch items-start sm:h-[60%] sm:max-h-[440px] mt-6">
        <div className="w-full sm:w-[75%] h-full flex flex-col items-start gap-5 self-stretch">
          <div className="flex flex-col sm:flex-row h-full gap-5 w-full">
            <div className="flex p-5 flex-col items-center gap-6 self-stretch rounded-xl border border-[#D0D5DD] bg-white w-full sm:max-w-[300px]">
              <p className="text-slate-600 text-left font-medium leading-9 text-[1.375rem] w-full">
                Cost of Goods Sold
              </p>
              <p
                className={`flex flex-col justify-end self-stretch flex-grow flex-shrink-0 basis-0 text-right w-full font-medium leading-9 text-[1.5rem] xl:text-[2.25rem] ${panelAmountColor(
                  spendingData?.costOfGoodsSold,
                )}`}
              >
                {`${teamCurrency?.symbol || '$'}${formatNumber(
                  spendingData?.costOfGoodsSold,
                )
                  .toString()
                  .replace('-', '')}`}
              </p>
            </div>
            <div className="flex p-5 flex-col items-center gap-6 self-stretch rounded-xl border border-[#D0D5DD] bg-white w-full sm:max-w-[300px]">
              <p className="text-slate-600 text-left font-medium leading-9 text-[1.375rem] w-full">
                Payroll Spending
              </p>
              <p
                className={`flex flex-col justify-end self-stretch flex-grow flex-shrink-0 basis-0 text-right w-full font-medium leading-9 text-[1.5rem] xl:text-[2.25rem] ${panelAmountColor(
                  spendingData?.payrollExpense,
                )}`}
              >
                {`${teamCurrency?.symbol || '$'}${formatNumber(
                  spendingData?.payrollExpense,
                )
                  .toString()
                  .replace('-', '')}`}
              </p>
            </div>
          </div>
          <div className="flex flex-col sm:flex-row h-full gap-5 w-full">
            <div className="flex p-5 flex-col items-center gap-6 self-stretch rounded-xl border border-[#D0D5DD] bg-white w-full sm:max-w-[300px]">
              <p className="text-slate-600 text-left font-medium leading-9 text-[1.375rem] w-full">
                Operating Expenses
              </p>
              <p
                className={`flex flex-col justify-end self-stretch flex-grow flex-shrink-0 basis-0 text-right w-full font-medium leading-9 text-[1.5rem] xl:text-[2.25rem] ${panelAmountColor(
                  spendingData?.operatingExpense,
                )}`}
              >
                {`${teamCurrency?.symbol || '$'}${formatNumber(
                  spendingData?.operatingExpense,
                )
                  ?.toString()
                  .replace('-', '')}`}
              </p>
            </div>
            <div className="flex p-5 flex-col items-center gap-6 self-stretch rounded-xl border border-[#D0D5DD] bg-white w-full sm:max-w-[300px]">
              <p className="text-slate-600 text-left font-medium leading-9 text-[1.375rem] w-full">
                Other
              </p>
              <p
                className={`flex flex-col justify-end self-stretch flex-grow flex-shrink-0 basis-0 text-right w-full font-medium leading-9 text-[1.5rem] xl:text-[2.25rem] ${panelAmountColor(
                  spendingData?.other,
                )}`}
              >
                {`${teamCurrency?.symbol || '$'}${formatNumber(
                  spendingData?.other,
                )
                  ?.toString()
                  .replace('-', '')}`}
              </p>
            </div>
          </div>
        </div>
        <div className="w-full h-full rounded-xl border border-[#D0D5DD] bg-white">
          <div className="w-full flex justify-between items-start p-6 border-b border-[#F0F0F0]">
            <div className="flex flex-col">
              <p className="text-slate-600 text-left font-medium leading-9 text-[1.375rem] w-full">
                Total Spending
              </p>
              <p
                className={`w-full font-medium leading-9 text-[2.25rem] ${panelAmountColor(
                  spendingData?.total,
                )}`}
              >
                {`${teamCurrency?.symbol || '$'}${formatNumber(
                  spendingData?.total,
                )
                  .toString()
                  .replace('-', '')}`}
              </p>
            </div>
          </div>
          <div className="flex items-start justify-center w-full h-[65%] p-4">
            <PieChart
              data={chartData}
              width={500}
              height={230}
              isDonut={false}
            />
          </div>
        </div>
      </div>
      {/* bottom analytic area */}
      <div className="flex flex-col sm:flex-row gap-5 self-stretch items-start mt-6 h-full">
        <div className="flex items-start flex-col w-full sm:w-[60%] h-full">
          <div className="w-full h-full p-6 flex flex-col items-center gap-4 shrink-0 self-stretch rounded-xl bg-white border border-[#D0D5DD]">
            <div className="flex w-full justify-between items-center self-stretch">
              <p className="text-slate-600 font-medium leading-[2.375rem] text-[1rem] xl:text-[1.375rem]">
                Top Vendors
              </p>
              <button
                onClick={() => setActiveTab('vendor')}
                className="h-[2.375rem] py-2 xl:py-2.5 px-4 xl:px-[1.125rem] rounded-[0.313rem] bg-indigo-500 border border-indigo-500 flex items-center justify-center shadow-sm text-[1rem] text-white leading-6"
                type="button"
              >
                View All Vendors
              </button>
            </div>
            <div className="flex flex-col gap-2 items-start self-stretch">
              {/* Vendor row */}
              {topSpendingVendors?.map((vendor) => (
                <div
                  key={vendor?.vendorId}
                  className="flex px-[1.375rem] pb-2 justify-between border-b border-[#D0D5DD] self-stretch w-full items-center cursor-pointer"
                  onClick={(e) => {
                    e.stopPropagation();
                    setActiveVendorId(vendor?.vendorId);
                    setVendorFlyoutOpen(true);
                  }}
                  role="button"
                  tabIndex="0"
                >
                  <div className="flex gap-2 items-center">
                    {vendor?.vendor?.logoUrl ? (
                      <img
                        className="h-8 w-8 rounded-full"
                        src={vendor?.vendor?.logoUrl}
                        width="32"
                        height="32"
                        alt={vendor?.vendor?.name}
                      />
                    ) : (
                      <div className="min-h-8 min-w-8 rounded-full flex items-center justify-center bg-slate-400 dark:bg-slate-700">
                        <div className="text-xs font-normal uppercase text-white">
                          {vendor?.vendor?.name
                            ? vendor?.vendor?.name
                                .replace(/[^\w\s]/gi, '')
                                .split(' ')
                                .filter(Boolean)
                                .slice(0, 2)
                                .map((word) => word.charAt(0))
                                .join('')
                            : ''}
                        </div>
                      </div>
                    )}
                    <p className="text-slate-600 leading-6 text-[1rem]">
                      {vendor?.vendor?.name}
                    </p>
                  </div>
                  <div className="flex flex-col max-h-10">
                    <p className="text-slate-600 font-bold text-right text-[1rem] leading-6">
                      {teamCurrency ? teamCurrency?.symbol : '$'}
                      {formatNumber(vendor?.total)}
                    </p>
                    <p className="text-slate-600 text-opacity-50 text-right font-normal text-[12px] leading-6 -mt-1 whitespace-nowrap">
                      Total Spend
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        {/* top transactions */}
        <div className="w-full h-full flex p-6 flex-col justify-end items-center self-stretch gap-6 rounded-xl  border border-[#D0D5DD] bg-white">
          <div className="flex w-full justify-between items-center self-stretch">
            <p className="text-slate-600 font-medium leading-[2.375rem] text-[1rem] xl:text-[1.375rem]">
              Top Transactions
            </p>
            <button
              onClick={() =>
                navigate(
                  `/transactions?page=1&startDate=${startDate}&endDate=${endDate}&transactionTypes=Expense`,
                )
              }
              className="h-[2.375rem] py-2 xl:py-2.5 px-4 xl:px-[1.125rem] rounded-[0.313rem] bg-indigo-500 border border-indigo-500 flex items-center justify-center shadow-sm text-[1rem] text-white leading-6"
              type="button"
            >
              View All Transactions
            </button>
          </div>
          <div className="w-full overflow-x-auto scrollbar">
            <table className="w-full min-w-max flex flex-col items-start overflow-x-auto scrollbar">
              <thead className="flex w-full items-center gap-5 rounded-[0.313rem] shadow-md bg-white h-[3.5rem] mb-2">
                <th className="text-slate-600 text-left text-[14px] font-medium leading-[1.125rem] w-[15%] ml-4">
                  Date
                </th>
                <th className="text-slate-600 text-left text-[14px] font-medium leading-[1.125rem] w-[40%]">
                  Description
                </th>
                <th className="text-slate-600 text-left text-[14px] font-medium leading-[1.125rem] w-[18%]">
                  Account
                </th>
                <th className="text-slate-600 text-left text-[14px] font-medium leading-[1.125rem] w-[16%]">
                  Amount
                </th>
              </thead>
              {transactions?.map((transaction, index) => (
                <tr
                  key={transaction?.id}
                  className={`flex w-full items-center gap-5 bg-white h-[3.5rem] ${
                    index === 4 ? '' : 'border-b border-[#D0D5DD]'
                  }`}
                >
                  <td className="whitespace-nowrap text-slate-600 text-[14px] leading-6 w-[15%] ml-4">
                    {moment
                      ?.utc(transaction?.authorizedDate)
                      ?.format('MMM DD, YYYY')}
                  </td>
                  <td className="text-slate-600 text-[14px] font-medium leading-6 flex items-center gap-2 w-[40%]">
                    <div className="h-[1.688rem] w-[1.688rem] min-h-[1.688rem] min-w-[1.688rem] rounded-full">
                      {transaction?.vendor?.logoUrl ||
                      transaction?.plaidCategoryIconUrl ? (
                        <img
                          className="rounded-full"
                          src={
                            transaction?.vendor?.logoUrl ||
                            transaction?.plaidCategoryIconUrl
                          }
                          alt={transaction?.name}
                        />
                      ) : (
                        <div
                          className="w-[1.687rem] h-[1.687rem] rounded-full flex items-center justify-center bg-slate-400 dark:bg-slate-600"
                          style={{
                            backgroundColor: transaction?.categoryAccount?.group
                              ?.color
                              ? transaction?.categoryAccount?.group?.color
                              : '',
                          }}
                        >
                          <div
                            className={`${
                              transaction?.categoryAccount?.group?.color
                                ? getContrast(
                                    transaction?.categoryAccount?.group?.color,
                                    'text-black',
                                    'text-white',
                                  )
                                : 'text-black'
                            } text-xs font-normal uppercase`}
                          >
                            {transaction?.description
                              ? transaction.description
                                  .replace(/[^\w\s]/gi, '')
                                  .split(' ')
                                  .filter(Boolean)
                                  .slice(0, 2)
                                  .map((word) => word.charAt(0))
                                  .join('')
                              : ''}
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="whitespace-nowrap flex flex-col">
                      <p className="leading-6 font-medium text-slate-600 text-[14px]">
                        {transaction?.description.length > 22
                          ? `${transaction?.description.substring(0, 22)}...`
                          : transaction?.description}
                      </p>
                      <p className="leading-6 text-slate-600 text-opacity-80 text-[12px]">
                        {transaction?.categoryAccount?.name}
                      </p>
                    </div>
                  </td>
                  <td className="whitespace-nowrap text-slate-600 text-[14px] font-medium leading-6 w-[18%]">
                    {`${transaction?.account?.name} ${
                      transaction?.account?.mask
                        ? `(${transaction?.account?.mask})`
                        : ''
                    }`.length > 13
                      ? `${`${transaction?.account?.name} ${
                          transaction?.account?.mask
                            ? `(...${transaction?.account?.mask})`
                            : ''
                        }`.slice(0, 13)}...`
                      : `${transaction?.account?.name} ${
                          transaction?.account?.mask
                            ? `(${transaction?.account?.mask})`
                            : ''
                        }`}
                  </td>
                  <td className="text-[#535C6E] text-[14px] font-semibold leading-6 w-[16%]">
                    {teamCurrency ? teamCurrency?.symbol : '$'}
                    {formatNumber(transaction?.amount.toFixed(2))}
                  </td>
                </tr>
              ))}
            </table>
          </div>
        </div>
      </div>
      {/* spending by category table */}
      <div className="flex w-full flex-col items-center gap-5 mt-6">
        <div className="w-full flex flex-col items-center gap-6 shrink-0 self-stretch rounded-xl border border-[#D0D5DD] bg-white p-6">
          <div className="w-full flex items-start self-stretch text-slate-600 text-[1.375rem] font-medium leading-[2.375rem]">
            Spending By Category
          </div>
          <div className="w-full items-start flex overflow-x-auto scrollbar">
            <table className="min-w-max w-full flex flex-col items-start">
              <thead className="flex w-full justify-around items-center gap-11 rounded-[0.313rem] shadow-md bg-white h-[3.5rem] mb-1">
                <th className="text-slate-600 text-left text-[14px] font-medium leading-[1.125rem] w-[135px] ml-4 flex-shrink-0 flex-grow-0 ">
                  Category
                </th>
                {generateMonthHeaders(startDate, endDate).map(
                  (month, index) => (
                    <th
                      key={index}
                      className="text-slate-600 text-left text-[14px] font-medium leading-[1.125rem] w-[75px] flex-shrink-0 flex-grow-0 "
                    >
                      {month}
                    </th>
                  ),
                )}
                <th className="text-slate-600 text-left text-[14px] font-bold leading-[1.125rem] w-[75px] flex-shrink-0 flex-grow-0 ">
                  Total
                </th>
              </thead>
              {!isLoading &&
                topSpendingCategories &&
                Object.values(topSpendingCategories).map((category) => (
                  <tr
                    key={category?.id}
                    className="flex w-full justify-around items-center gap-11 bg-white h-[3.5rem] border-b border-[#D0D5DD] hover:bg-[#a0cd85] hover:bg-opacity-5 cursor-pointer"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleFlyoutOpen(category, startDate, endDate);
                    }}
                  >
                    <td className=" whitespace-nowrap text-slate-600 text-left text-[14px] font-medium leading-[1.125rem] w-[135px] ml-4 flex-shrink-0 flex-grow-0 ">
                      <Tooltip
                        content={category.name}
                        tooltipShow={category.name.length > 20}
                      >
                        {category.name.length > 20
                          ? `${category.name.substring(0, 20)}...`
                          : category.name}
                      </Tooltip>
                    </td>
                    {generateMonthHeaders(startDate, endDate).map(
                      (headerMonth, index) => (
                        <td
                          key={index}
                          className={`text-left text-[14px] font-medium leading-[1.125rem] w-[75px] flex-shrink-0 flex-grow-0 ${
                            category.months &&
                            Object.keys(category.months).some(
                              (month) =>
                                moment(month).format('MMM YYYY') ===
                                headerMonth,
                            )
                              ? panelAmountColor(
                                  category.months[
                                    moment(headerMonth, 'MMM YYYY').format(
                                      'YYYY-MM-DD',
                                    )
                                  ].toString(),
                                )
                              : 'text-slate-600'
                          }`}
                        >
                          {category.months &&
                          Object.keys(category.months).some(
                            (month) =>
                              moment(month).format('MMM YYYY') === headerMonth,
                          )
                            ? (() => {
                                const amount =
                                  category.months[
                                    moment(headerMonth, 'MMM YYYY').format(
                                      'YYYY-MM-DD',
                                    )
                                  ].toFixed(2);
                                const formattedAmount = formatNumber(amount);
                                return formattedAmount.startsWith('-')
                                  ? `${
                                      teamCurrency ? teamCurrency?.symbol : '$'
                                    }${formattedAmount.slice(1)}`
                                  : `${
                                      teamCurrency ? teamCurrency?.symbol : '$'
                                    }${formattedAmount}`;
                              })()
                            : '-'}
                        </td>
                      ),
                    )}
                    <td
                      className={`text-left text-[14px] leading-[1.125rem] w-[75px] flex-shrink-0 flex-grow-0 font-bold ${panelAmountColor(
                        category?.total,
                      )}`}
                    >
                      {teamCurrency ? teamCurrency?.symbol : '$'}
                      {formatNumber(Math.abs(category.total).toFixed(2))}
                    </td>
                  </tr>
                ))}
              <tr className="flex w-full justify-around items-center gap-11 bg-white h-[3.5rem] border-b border-[#D0D5DD]">
                <td className="text-slate-600 text-left text-[14px] font-bold leading-[1.125rem] w-[135px] ml-4 flex-shrink-0 flex-grow-0">
                  Total
                </td>
                {generateMonthHeaders(startDate, endDate).map(
                  (month, index) => (
                    <td
                      key={index}
                      className={`text-left text-[14px] font-bold leading-[1.125rem] w-[75px] flex-shrink-0 flex-grow-0 ${panelAmountColor(
                        Object.values(topSpendingCategories).reduce(
                          (acc, category) =>
                            acc +
                            (category.months &&
                            category.months[
                              moment(month, 'MMM YYYY').format('YYYY-MM-DD')
                            ]
                              ? category.months[
                                  moment(month, 'MMM YYYY').format('YYYY-MM-DD')
                                ]
                              : 0),
                          0,
                        ),
                      )}`}
                    >
                      {teamCurrency ? teamCurrency?.symbol : '$'}
                      {formatNumber(
                        Object.values(topSpendingCategories)
                          .reduce(
                            (acc, category) =>
                              acc +
                              (category.months &&
                              category.months[
                                moment(month, 'MMM YYYY').format('YYYY-MM-DD')
                              ]
                                ? Math.abs(
                                    category.months[
                                      moment(month, 'MMM YYYY').format(
                                        'YYYY-MM-DD',
                                      )
                                    ],
                                  )
                                : 0),
                            0,
                          )
                          .toFixed(2),
                      )}
                    </td>
                  ),
                )}
                <td
                  className={`text-left text-[14px] font-bold leading-[1.125rem] w-[75px] flex-shrink-0 flex-grow-0 ${panelAmountColor(
                    Object.values(topSpendingCategories).reduce(
                      (total, category) => total + category.total,
                      0,
                    ),
                  )}`}
                >
                  {teamCurrency ? teamCurrency?.symbol : '$'}
                  {formatNumber(
                    Object.values(topSpendingCategories)
                      .reduce(
                        (total, category) => Math.abs(total + category.total),
                        0,
                      )
                      .toFixed(2),
                  )}
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
      {/* spending by month chart */}
      <div className="flex flex-col mt-6 w-full h-[40dvh] p-6 border border-[#D0D5DD] rounded-xl gap-6">
        <div className="w-full flex items-start self-stretch text-slate-600 text-[1.375rem] font-medium leading-[2.375rem]">
          Total Spending By Month
        </div>
        <LineChartInsights
          data={monthlySpending}
          width={100}
          height={50}
          dateRange={{
            start: moment(startDate).format('YYYY-MM-DD'),
            end: moment(endDate).format('YYYY-MM-DD'),
          }}
          teamCurrency={teamCurrency}
        />
      </div>
    </>
  );
};

export default SpendingBreakdown;
