import React, { useState } from "react";
import ModalBasic from "../../components/ModalBasic";
import { Loader } from "../../components/Svg";

const DeleteAutomationModal = ({
  isOpen,
  setIsOpen,
  handleDeleteAutomation,
  activeAutomation,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleDeleteClick = async () => {
    setIsLoading(true);
    await handleDeleteAutomation(activeAutomation);
    setIsLoading(false);
    setIsOpen(false);
  };

  return (
    <>
      <ModalBasic
        title={"Delete Automation"}
        modalOpen={isOpen}
        setModalOpen={setIsOpen}
        smallModal
      >
        <div className="w-full px-5 py-[1.875rem] flex items-center self-stretch bg-white">
          <p className="text-base leading-6 text-slate-600 text-opacity-80">
            Are you sure you want to delete this automation? This action cannot
            be undone.
          </p>
        </div>
        <div className="border-t border-gray-300 h-20 flex items-center justify-end gap-4 px-5">
          <>
            <button
              className="w-[5.625rem] h-11 flex items-center justify-center py-2.5 px-[1.875rem] rounded-[0.313rem] bg-white border border-slate-600 text-slate-600 text-base leading-6"
              onClick={() => setIsOpen(false)}
            >
              No
            </button>
            <button
              className="w-[5.625rem] h-11 flex items-center justify-center py-2.5 px-[1.875rem] rounded-[0.313rem] bg-indigo-500 shadow text-white leading-6"
              onClick={() => handleDeleteClick()}
              disabled={isLoading}
            >
              {isLoading ? (
                <Loader width="w-4" height="h-4" color="#FFFFFF" />
              ) : (
                "Yes"
              )}
            </button>
          </>
        </div>
      </ModalBasic>
    </>
  );
};

export default DeleteAutomationModal;
