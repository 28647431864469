import React, { useRef, useState } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import AddressBar from 'components/AddressBar';
import ModalBasic from '../../components/ModalBasic';
import TextInputDash from '../../components/TextInputDash';
import { createCustomer, updateCustomer } from '../../API/backend_helper';
import DropdownFull from '../../components/DropdownFull';
import { IS_PERSON } from '../../utils/Utils';

const AddCustomerModal = ({
  isOpen,
  setIsOpen,
  refreshCustomers = null,
  setSelectedCustomer,
  activeCustomer,
  salesRep = [],
}) => {
  const IsPerson = localStorage.getItem(IS_PERSON)
    ? JSON.parse(localStorage.getItem(IS_PERSON))
    : null;

  const [isLoading, setIsLoading] = useState(false);

  const formikRef = useRef(null);

  const handleSubmit = async (values, formikRef) => {
    setIsLoading(true);
    try {
      let newValues = { ...values };
      if (newValues?.salesRepId === '') {
        delete newValues?.salesRepId;
      }
      if (activeCustomer) {
        if (!newValues?.contacts[0]?.email) {
          newValues.contacts[0].email = null;
        }
        if (activeCustomer?.contacts[0]) {
          newValues = {
            ...values,
            contacts: [
              {
                ...newValues.contacts[0],
                id: activeCustomer?.contacts?.[0]?.id,
              },
            ],
          };
        } else {
          delete newValues.contacts;
        }
        if (newValues?.salesRepId === '') {
          delete newValues?.salesRepId;
        }
        const updateRes = await updateCustomer(newValues, activeCustomer?.id);
        setSelectedCustomer(updateRes?.data?.updatedCustomer);
      } else {
        if (!newValues?.contacts[0]?.email) {
          delete newValues.contacts[0].email;
        }
        const cusRes = await createCustomer(newValues);
        setSelectedCustomer(cusRes?.data?.customer);
      }
      if (refreshCustomers) {
        await refreshCustomers();
      }
      formikRef.resetForm();
      setIsOpen(false);
    } catch (err) {
      console.log('err', err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <ModalBasic
      modalOpen={isOpen}
      setModalOpen={setIsOpen}
      title={`${activeCustomer?.id ? 'Edit' : 'Add'} Customer`}
      width="!w-[1130px]"
    >
      <Formik
        enableReinitialize
        initialValues={{
          customer: activeCustomer?.customer || '',
          note: activeCustomer?.note || '',
          taxNumber: activeCustomer?.taxNumber || '',
          salesRepId: activeCustomer?.salesRepId || '',
          shippingAddress: {
            street: activeCustomer?.shippingAddress?.street || '',
            street2: activeCustomer?.shippingAddress?.street2 || '',
            country: activeCustomer?.shippingAddress?.country || '',
            city: activeCustomer?.shippingAddress?.city || '',
            state: activeCustomer?.shippingAddress?.state || '',
            zipCode: activeCustomer?.shippingAddress?.zipCode || '',
          },
          billingAddress: {
            street: activeCustomer?.billingAddress?.street || '',
            street2: activeCustomer?.billingAddress?.street2 || '',
            country: activeCustomer?.billingAddress?.country || '',
            city: activeCustomer?.billingAddress?.city || '',
            state: activeCustomer?.billingAddress?.state || '',
            zipCode: activeCustomer?.billingAddress?.zipCode || '',
          },
          contacts: [
            {
              firstName: activeCustomer?.contacts?.[0]?.firstName || '',
              lastName: activeCustomer?.contacts?.[0]?.lastName || '',
              email: activeCustomer?.contacts?.[0]?.email || '',
              phone: activeCustomer?.contacts?.[0]?.phone || '',
            },
          ],
        }}
        validationSchema={Yup.object({
          customer: Yup.string().required('Customer is required'),
          note: Yup.string(),
          shippingAddress: Yup.object({
            street: Yup.string(),
            street2: Yup.string(),
            country: Yup.string(),
            city: Yup.string(),
            state: Yup.string(),
            zipCode: Yup.string(),
          }),
          billingAddress: Yup.object({
            street: Yup.string(),
            street2: Yup.string(),
            country: Yup.string(),
            city: Yup.string(),
            state: Yup.string(),
            zipCode: Yup.string(),
          }),
          contacts: Yup.array().of(
            Yup.object({
              firstName: Yup.string(),
              lastName: Yup.string(),
              email: Yup.string()
                .optional()
                .nullable()
                .email('Invalid email address'),
              phone: Yup.string(),
            }),
          ),
        })}
        onSubmit={handleSubmit}
        innerRef={formikRef}
      >
        {(validation) => (
          <Form>
            <div className="px-10  py-3">
              <div className="grid grid-cols-12 gap-4">
                <div className="col-span-full md:col-span-6">
                  <TextInputDash
                    label="Customer"
                    id="customer"
                    name="customer"
                    placeholder="Business / Customer Name"
                    value={validation.values.customer || ''}
                    error={
                      validation.touched.customer && validation.errors.customer
                    }
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    // inputClass="placeholder:italic"
                    required
                  />
                </div>
              </div>
              <div className="grid grid-cols-12 gap-x-5 gap-y-1">
                <div className="col-span-full md:col-span-6">
                  <TextInputDash
                    label="First Name"
                    id="firstName"
                    name="contacts.[0].firstName"
                    placeholder="Name of Primary Contact"
                    value={validation.values.contacts[0]?.firstName || ''}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    // inputClass="placeholder:italic"
                  />
                </div>
                <div className="col-span-full md:col-span-6">
                  <TextInputDash
                    label="Last Name"
                    id="lastName"
                    name="contacts.[0].lastName"
                    placeholder="Name of Primary Contact"
                    value={validation.values.contacts[0]?.lastName || ''}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    // inputClass="placeholder:italic"
                  />
                </div>
                <div className="col-span-full md:col-span-6">
                  <TextInputDash
                    label="Contact Email"
                    id="email"
                    name="contacts.[0].email"
                    placeholder="Email Address"
                    value={validation.values.contacts[0]?.email || ''}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    error={
                      validation.errors.contacts &&
                      validation.errors.contacts[0] &&
                      validation.errors.contacts[0]?.email &&
                      validation.touched.contacts &&
                      validation.touched.contacts[0] &&
                      validation.touched.contacts[0]?.email
                        ? validation.errors.contacts[0].email
                        : ''
                    }
                  />
                </div>
                <div className="col-span-full md:col-span-6">
                  <TextInputDash
                    label="Phone Number"
                    id="phone"
                    name="contacts.[0].phone"
                    placeholder="Phone Number"
                    value={validation.values.contacts[0]?.phone || ''}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    // inputClass="placeholder:italic"
                  />
                </div>
              </div>
              <div className="flex flex-col items-start gap-2.5 w-[100%] mb-5">
                <label className="text-sm font-medium text-slate-600 block">
                  Billing Address
                </label>
                <AddressBar
                  validation={validation}
                  streetId="billingAddress.street"
                  streetValue={validation.values.billingAddress.street}
                  streetError={
                    validation.touched?.billingAddress?.street &&
                    validation.errors?.billingAddress?.street
                  }
                  street2Id="billingAddress.street2"
                  street2Value={validation.values.billingAddress.street2}
                  street2Error={
                    validation.touched?.billingAddress?.street2 &&
                    validation.errors?.billingAddress?.street2
                  }
                  cityId="billingAddress.city"
                  cityValue={validation.values.billingAddress.city}
                  cityError={
                    validation.touched?.billingAddress?.city &&
                    validation.errors?.billingAddress?.city
                  }
                  stateId="billingAddress.state"
                  stateValue={validation.values.billingAddress.state}
                  stateError={
                    validation.touched?.billingAddress?.state &&
                    validation.errors?.billingAddress?.state
                  }
                  zipCodeId="billingAddress.zipCode"
                  zipCodeValue={validation.values.billingAddress.zipCode}
                  zipCodeError={
                    validation.touched?.billingAddress?.zipCode &&
                    validation.errors?.billingAddress?.zipCode
                  }
                  countryId="billingAddress.country"
                  countryValue={validation.values.billingAddress.country}
                  countryError={
                    validation.touched?.billingAddress?.country &&
                    validation.errors?.billingAddress?.country
                  }
                />
              </div>
              <div className="flex flex-col items-start gap-2.5 w-[100%] mb-5">
                <label className="text-sm font-medium text-slate-600 block">
                  Shipping Address
                  <span
                    className="text-indigo-500 ml-2 text-[12px] cursor-pointer"
                    onClick={(e) => {
                      e.stopPropagation();
                      validation.setFieldValue(
                        'shippingAddress.street',
                        validation.values.billingAddress.street,
                      );
                      validation.setFieldValue(
                        'shippingAddress.street2',
                        validation.values.billingAddress.street2,
                      );
                      validation.setFieldValue(
                        'shippingAddress.country',
                        validation.values.billingAddress.country,
                      );
                      validation.setFieldValue(
                        'shippingAddress.city',
                        validation.values.billingAddress.city,
                      );
                      validation.setFieldValue(
                        'shippingAddress.state',
                        validation.values.billingAddress.state,
                      );
                      validation.setFieldValue(
                        'shippingAddress.zipCode',
                        validation.values.billingAddress.zipCode,
                      );
                    }}
                    role="button"
                    tabIndex="0"
                  >
                    Copy from Billing Address
                  </span>
                </label>
                <AddressBar
                  validation={validation}
                  streetId="shippingAddress.street"
                  streetValue={validation.values.shippingAddress.street}
                  streetError={
                    validation.touched?.shippingAddress?.street &&
                    validation.errors?.shippingAddress?.street
                  }
                  street2Id="shippingAddress.street2"
                  street2Value={validation.values.shippingAddress.street2}
                  street2Error={
                    validation.touched?.shippingAddress?.street2 &&
                    validation.errors?.shippingAddress?.street2
                  }
                  cityId="shippingAddress.city"
                  cityValue={validation.values.shippingAddress.city}
                  cityError={
                    validation.touched?.shippingAddress?.city &&
                    validation.errors?.shippingAddress?.city
                  }
                  stateId="shippingAddress.state"
                  stateValue={validation.values.shippingAddress.state}
                  stateError={
                    validation.touched?.shippingAddress?.state &&
                    validation.errors?.shippingAddress?.state
                  }
                  zipCodeId="shippingAddress.zipCode"
                  zipCodeValue={validation.values.shippingAddress.zipCode}
                  zipCodeError={
                    validation.touched?.shippingAddress?.zipCode &&
                    validation.errors?.shippingAddress?.zipCode
                  }
                  countryId="shippingAddress.country"
                  countryValue={validation.values.shippingAddress.country}
                  countryError={
                    validation.touched?.shippingAddress?.country &&
                    validation.errors?.shippingAddress?.country
                  }
                />
              </div>
              <div className="sm:flex items-start gap-4 w-[100%]">
                <div className="flex items-start w-full sm:w-1/2 ">
                  <TextInputDash
                    label="Tax Number"
                    id="taxNumber"
                    name="taxNumber"
                    placeholder="Tax Number"
                    value={validation.values.taxNumber || ''}
                    error={
                      validation.touched.taxNumber &&
                      validation.errors.taxNumber
                    }
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                  />
                </div>
                {!IsPerson && (
                  <div className=" w-full  sm:w-1/2 mb-5">
                    <div className="mb-2 flex gap-2">
                      <label className="text-sm font-medium text-slate-600 block ">
                        Assigned Sales Representative
                      </label>
                      {validation.values.salesRepId && (
                        <label
                          className="text-sm cursor-pointer font-medium text-indigo-500 block "
                          onClick={() =>
                            validation.setFieldValue('salesRepId', null)
                          }
                        >
                          Clear
                        </label>
                      )}
                    </div>
                    <DropdownFull
                      options={salesRep?.map((d) => ({
                        ...d,
                        name: `${d?.firstName} ${d?.lastName}`,
                        id: d?.id,
                      }))}
                      selected={validation.values.salesRepId}
                      setSelected={(value) => {
                        validation.setFieldValue('salesRepId', value);
                      }}
                      name="name"
                      scrollIntoView={false}
                      height="h-12 w-full"
                      flyout
                      placeholder="Assigned Sales Representative"
                      isTableDropdown
                    />
                  </div>
                )}
              </div>
              <div className="flex flex-col items-start gap-2.5 w-[100%]">
                <label
                  className="text-sm font-medium text-slate-600 block"
                  htmlFor="note"
                >
                  Notes
                </label>
                <textarea
                  id="note"
                  name="note"
                  placeholder="Notes"
                  value={validation.values.note || ''}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  className="form-input placeholder:italic w-full h-[4.875rem] px-4 text-[#667085] placeholder:text-slate-600 placeholder:text-opacity-80 placeholder:leading-6   border-[#D0D5DD] shadow-sm mb-[2px] rounded-[5px]"
                />
              </div>
            </div>
            {/* Close/Add Buttons */}
            <div className="w-full flex justify-end items-center gap-4 py-8 px-10 border-t border-[#D0D5DD]">
              <button
                className="flex items-center justify-center py-2.5 px-[1.875rem] rounded-[0.313rem]  bg-white border border-gray-300 border-opacity-40 shadow w-[6.875rem] h-11 text-slate-600 leading-6"
                onClick={(event) => {
                  event.preventDefault();
                  formikRef.current?.resetForm();

                  setIsOpen(false);
                }}
                type="button"
              >
                Close
              </button>
              <button
                className="flex items-center justify-center py-2.5 px-[1.875rem] rounded-[0.313rem] bg-indigo-500  shadow w-[6.875rem] h-11 text-white leading-6
                disabled:!bg-indigo-300 disabled:text-white disabled:cursor-not-allowed"
                type="submit"
                disabled={isLoading}
              >
                {isLoading && (
                  <svg
                    className="animate-spin w-4 h-4 fill-current shrink-0 mr-2"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z" />
                  </svg>
                )}
                Save
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </ModalBasic>
  );
};

export default AddCustomerModal;
