import React, { useEffect, useRef, useState } from 'react';
import Transition from '../utils/Transition';
import DropdownFull from './DropdownFull';

function PaginationClassic({
  setLimit,
  pagination,
  onPageChange,
  scrollIntoView = true,
  pageLoad = false,
}) {
  const { limit, page, totalRecords } = pagination;
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [localPage, setLocalPage] = useState('');

  const [isMobile, setIsMobile] = useState(false);

  const totalPages = Math.ceil(totalRecords / limit);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (page) {
      setLocalPage(page);
    }
  }, [page]);

  const trigger = useRef(null);
  const dropdown = useRef(null);

  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!dropdown.current) return;
      if (
        !dropdownOpen ||
        dropdown.current.contains(target) ||
        trigger.current.contains(target)
      )
        return;
      setDropdownOpen(false);
    };
    document.addEventListener('click', clickHandler);
    return () => document.removeEventListener('click', clickHandler);
  });

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!dropdownOpen || keyCode !== 27) return;
      setDropdownOpen(false);
    };
    document.addEventListener('keydown', keyHandler);
    return () => document.removeEventListener('keydown', keyHandler);
  });

  const handleLimitChange = (e) => {
    const newLimit = parseInt(e, 10);
    onPageChange({ page: 1, limit: newLimit, loading: pageLoad });
    setLocalPage(1);
    setLimit(newLimit);
  };

  const visiblePages = () => {
    const pages = [];

    // Add the first page
    if (totalPages === 1 || totalPages === 0) {
      return [1];
    }

    if (totalPages <= 10) {
      for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
      }
      return pages;
    }

    const range = isMobile ? 2 : 4;

    if (totalPages > range && localPage < range + 1) {
      for (let i = 1; i <= range; i++) {
        pages.push(i);
      }
    } else {
      pages.push(1);
    }

    // If the active page is within the first 3 pages, add those pages
    if (parseInt(localPage) > range) {
      pages.push('ellipsis');
    }

    if (localPage >= range + 1) {
      for (
        let i = parseInt(localPage) - (isMobile ? 1 : 2);
        i < parseInt(localPage);
        i++
      ) {
        if (i >= range && i <= totalPages) {
          pages.push(i);
        }
      }
    }

    if (parseInt(localPage) <= parseInt(totalPages) - range - 1) {
      for (
        let i = parseInt(localPage);
        i < parseInt(localPage) + (isMobile ? 2 : 3);
        i++
      ) {
        if (i >= range + 1 && i <= totalPages) {
          pages.push(i);
        }
      }
    }

    if (localPage < parseInt(totalPages) - range * 2) {
      pages.push('ellipsis');
    }

    for (
      let i = parseInt(totalPages) - (range - 1);
      i <= parseInt(totalPages);
      i++
    ) {
      pages.push(i);
    }

    return pages;
  };

  return (
    <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between">
      <nav
        className="mb-4 sm:mb-0 sm:order-1 flex flex-col sm:flex-row sm:items-center sm:justify-between gap-2 w-full"
        role="navigation"
        aria-label="Navigation"
      >
        {/* Rows per page */}
        <div className="flex flex-row gap-2 items-center mx-auto sm:mx-0">
          {/* <p className="text-sm font-medium leading-6 text-slate-600">Row</p> */}
          <div className="w-[72px] h-[42px]">
            <DropdownFull
              options={[
                { id: 20, name: '20' },
                { id: 50, name: '50' },
                { id: 100, name: '100' },
                { id: 200, name: '200' },
                { id: 300, name: '300' },
              ]}
              selected={limit}
              setSelected={(option) => handleLimitChange(option)}
              name="name"
              placeholder={limit}
              pagination
              scrollIntoView={scrollIntoView}
            />
          </div>
          <p className="text-sm font-medium leading-6 text-slate-600">
            {' '}
            Per Page (Total {totalRecords})
          </p>
        </div>
        {/* Center */}
        <div className="flex flex-row items-center mx-auto sm:mx-0">
          {/* Previous */}
          <button
            className="border border-gray-300 rounded-[0.3125rem] shadow-sm px-2.5 py-2 flex justify-between items-center cursor-pointer mr-2 sm:mr-10
              disabled:opacity-50 disabled:cursor-not-allowed"
            onClick={() =>
              +page !== 1 &&
              onPageChange({
                limit,
                page: parseInt(page) - 1,
                loading: pageLoad,
              })
            }
            disabled={+page === 1}
            type="button"
          >
            <svg
              width="21"
              height="20"
              viewBox="0 0 21 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className={`h-5 w-5 fill-indigo-400  ${
                +page === 1 ? 'fill-slate-400' : ''
              }`}
            >
              <path
                d="M16.0339 10.0003H4.36719M4.36719 10.0003L10.2005 15.8337M4.36719 10.0003L10.2005 4.16699"
                stroke="#667085"
                strokeWidth="1.66667"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
          {/* Individual pages */}
          <div className="flex">
            {visiblePages().map((item, index) => {
              if (typeof item === 'number') {
                return (
                  <span
                    key={index}
                    onClick={() =>
                      onPageChange({ limit, page: item, loading: pageLoad })
                    }
                    className={`w-10 h-10 flex items-center justify-center ${
                      item === +page
                        ? 'bg-white shadow-md font-bold text-yellow-500 rounded-[0.3125rem]'
                        : ''
                    }`}
                  >
                    <p className="text-sm leading-5 cursor-pointer text-center">
                      {item}
                    </p>
                  </span>
                );
              }
              if (item === 'ellipsis') {
                return (
                  <div
                    key={index}
                    className="relative w-10 h-10 flex items-center justify-center font-medium leading-5 text-sm text-gray-500"
                  >
                    ...
                    <Transition
                      className={`origin-top-right z-10 absolute min-w-44 bg-white dark:bg-slate-800
               border border-slate-200 dark:border-slate-700 py-1.5 rounded shadow-lg overflow-hidden
               mt-1 right-0 transform-y--100 `}
                      show={dropdownOpen}
                      enter="transition ease-out duration-200 transform"
                      enterStart="opacity-0 -translate-y-2"
                      enterEnd="opacity-100 translate-y-0"
                      leave="transition ease-out duration-200"
                      leaveStart="opacity-100"
                      leaveEnd="opacity-0"
                    >
                      <div
                        ref={dropdown}
                        onFocus={() => setDropdownOpen(true)}
                        onBlur={() => setDropdownOpen(false)}
                        className="flex gap-2 items-center px-3 text-sm whitespace-nowrap	"
                      >
                        Jump to
                        <input
                          type="text"
                          className="form-input h-8 w-10 px-2 p-1 !text-sm"
                          value={localPage}
                          onChange={(e) =>
                            e.target.value <= totalPages &&
                            setLocalPage(e.target.value)
                          }
                          onKeyPress={(e) => {
                            // Allow only numbers, decimal point (.), and backspace
                            const charCode = e.which ? e.which : e.keyCode;
                            const isValidChar =
                              charCode === 46 ||
                              (charCode >= 48 && charCode <= 57) ||
                              charCode === 8;
                            if (!isValidChar) {
                              e.preventDefault();
                            }
                          }}
                          step="any"
                        />
                        <button
                          className="btn btn-sm bg-indigo-500 hover:bg-indigo-600 text-white"
                          onClick={() => {
                            onPageChange({
                              limit,
                              page: parseInt(localPage),
                              loading: pageLoad,
                            });
                            setDropdownOpen(false);
                          }}
                          type="button"
                        >
                          Go
                        </button>
                      </div>
                    </Transition>
                  </div>
                );
              }
            })}
            {/* Next */}
            <button
              className="border border-gray-300 rounded-[0.3125rem] shadow-sm py-2 px-2.5 flex justify-between items-center cursor-pointer ml-2 sm:ml-10
              disabled:opacity-50 disabled:cursor-not-allowed"
              onClick={() =>
                +page !== totalPages &&
                onPageChange({
                  limit,
                  page: parseInt(page) + 1,
                  loading: pageLoad,
                })
              }
              disabled={+page === totalPages}
              type="button"
            >
              <svg
                width="21"
                height="20"
                viewBox="0 0 21 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className={`h-5 w-5 fill-indigo-400  ${
                  +page === totalPages ? 'fill-slate-400 ' : ''
                }`}
              >
                <path
                  d="M4.36654 10.0003H16.0332M16.0332 10.0003L10.1999 15.8337M16.0332 10.0003L10.1999 4.16699"
                  stroke="#667085"
                  strokeWidth="1.66667"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </div>
        </div>
        <div className="flex items-center w-[8.25rem]" />
      </nav>
    </div>
  );
}

export default PaginationClassic;
