import React from "react";
import { Loader } from "../../../components/Svg";

const AISettingsTab = ({ team, handleUpdateTeam, configLoading }) => {
  return (
    <div className="flex justify-between gap-5 rounded-lg mb-3 flex-wrap border px-5 py-4 border-slate-200 dark:border-slate-700">
      <div className="flex gap-3 items-center flex-wrap">
        <h3 className="text-sm font-medium ">AI Auto-Categorization</h3>
      </div>
      <div className="flex gap-3 items-center justify-center">
        {configLoading["AI Auto-Categorization"] ? (
          <Loader width="w-6" height="h-6" />
        ) : (
          <div className="form-switch">
            <input
              type="checkbox"
              id={`switch`}
              className="sr-only"
              checked={team?.defaults?.autoCategorizeTransactionOnSync}
              onChange={() => {
                handleUpdateTeam(
                  team?.id,
                  {
                    autoCategorizeTransactionOnSync:
                      !team?.defaults?.autoCategorizeTransactionOnSync,
                  },
                  "AI Auto-Categorization"
                );
              }}
            />
            <label
              className="bg-white border border-gray-300 border-opacity-50 shadow-sm"
              htmlFor={`switch`}
            >
              <span className="bg-gray-300" aria-hidden="true"></span>
              <span className="sr-only">Switch label</span>
            </label>
          </div>
        )}
      </div>
    </div>
  );
};

export default AISettingsTab;
