import React, { useState, useRef, useEffect, useCallback } from 'react';
import Transition from '../../utils/Transition';
import { getPeople } from '../../API/backend_helper';
import { VIEWER, debounce } from '../../utils/Utils';
import useClickOutside from '../../utils/useClickOutside';

function PeopleDropdown({
  align,
  people = [],
  selectedPeople,
  setSelectedPeople,
  isSetCategoryStyle = false,
  localPeople,
  setLocalPeople,
  inlineMenu = false,
  accessType,
  isFilter = false,
  height,
  disabled = false,
  addNewOptionButton = false,
  handleNewOptionButton = () => {},
  error = '',
}) {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [filteredData, setFilteredData] = useState([...people]);
  const [isResultFound, setIsResultFound] = useState(true);
  const [searchLoader, setSearchLoader] = useState(false);

  const trigger = useRef(null);
  const dropdown = useRef(null);
  const searchInput = useRef(null);

  useClickOutside(dropdown, () => {
    if (dropdownOpen) {
      setDropdownOpen(false);
      setSearchValue('');
    }
  });

  useEffect(() => {
    if (selectedPeople === null) {
      setLocalPeople(null);
    }
    if (selectedPeople && people?.length > 0) {
      setLocalPeople(people?.find((m) => m?.id === selectedPeople));
    }
  }, [selectedPeople, people]);

  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!dropdown.current) return;
      if (
        !dropdownOpen ||
        dropdown.current.contains(target) ||
        trigger.current.contains(target)
      )
        return;
      setDropdownOpen(false);
    };
    document.addEventListener('click', clickHandler);
    return () => document.removeEventListener('click', clickHandler);
  });

  useEffect(() => {
    if (people?.length === 0) {
      setIsResultFound(false);
    } else {
      setIsResultFound(true);
      setFilteredData([...people]);
    }
  }, [people]);

  useEffect(() => {
    if (dropdownOpen) {
      setSearchValue('');
      setFilteredData([...people]);
      if (people?.length) {
        setIsResultFound(true);
      }
      searchInput.current?.focus();
    }
  }, [dropdownOpen]);

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!dropdownOpen || keyCode !== 27) return;
      setDropdownOpen(false);
    };
    document.addEventListener('keydown', keyHandler);
    return () => document.removeEventListener('keydown', keyHandler);
  });

  const getSearchMerchants = async (value) => {
    try {
      const res = await getPeople({ search: value.replace(/\s/g, '') });
      setIsResultFound(false);

      if (res?.people?.length > 0) {
        setIsResultFound(true);
      }
      setFilteredData(res?.people);
    } catch (err) {
      console.log('err', err);
    } finally {
      setSearchLoader(false);
    }
  };

  const handleSearchCB = useCallback(
    debounce((inputVal) => getSearchMerchants(inputVal), 500),
    [],
  );

  const handleSearch = (e) => {
    setSearchLoader(true);
    setSearchValue(e.target.value);
    if (!e.target.value) {
      setFilteredData([...people]);
      setSearchLoader(false);
      if (people?.length) {
        setIsResultFound(true);
      }
      return;
    }
    handleSearchCB(e.target.value);
  };

  return (
    <>
      <div
        className={`relative flex flex-col ${
          isSetCategoryStyle ? 'w-full' : ''
        } ${isFilter ? 'max-w-full flex-1 min-w-[200px]' : ''}`}
        onClick={(e) => e.stopPropagation()}
        role="button"
        tabIndex="0"
      >
        <button
          ref={trigger}
          disabled={accessType === VIEWER || disabled}
          type="button"
          className={
            isSetCategoryStyle
              ? `form-input hover:border-slate-400 btn px-3 disabled:cursor-not-allowed disabled:opacity-50 ${
                  height || 'h-10'
                } py-2.5 justify-between w-full rounded-[0.313rem] font-normal text-base text-slate-600 text-opacity-80 leading-6 bg-white border-[#D0D5DD]`
              : inlineMenu
                ? dropdownOpen
                  ? 'rounded-full bg-slate-100 dark:bg-slate-700 text-slate-500 dark:text-slate-400'
                  : 'rounded-full text-slate-400 hover:text-slate-500 dark:text-slate-500 dark:hover:text-slate-400'
                : 'inline-flex justify-center items-center group'
          }
          aria-haspopup="true"
          onClick={(e) => {
            e.stopPropagation();
            setDropdownOpen(!dropdownOpen);
          }}
          aria-expanded={dropdownOpen}
        >
          {!inlineMenu ? (
            <div
              className={`flex items-center truncate ${
                isSetCategoryStyle ? 'w-full justify-between' : ''
              }`}
            >
              <span
                className={`truncate font-medium text-indigo-500 group-hover:text-indigo-600 dark:group-hover:text-indigo-400 ${
                  isSetCategoryStyle
                    ? 'text-slate-600 font-normal text-base tracking-normal leading-6'
                    : ''
                } ${
                  isSetCategoryStyle && localPeople === null
                    ? 'text-slate-600 text-opacity-80'
                    : ''
                }`}
              >
                {localPeople === null
                  ? 'Choose Person'
                  : `${localPeople?.firstName} ${localPeople?.lastName}`}
              </span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="10"
                height="5"
                viewBox="0 0 10 5"
                fill="none"
                className={`${
                  dropdownOpen ? 'rotate-180 translate-y-[1px]' : ''
                }`}
              >
                <path
                  d="M9 1L5.70707 3.7559C5.31818 4.08137 4.68182 4.08137 4.29293 3.7559L1 1"
                  stroke="#667085"
                  strokeOpacity="0.8"
                  strokeWidth="1.5"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          ) : (
            <>
              <span className="sr-only">Menu</span>
              <svg className="w-5 h-5 fill-current" viewBox="0 0 32 32">
                <circle cx="16" cy="16" r="2" />
                <circle cx="10" cy="16" r="2" />
                <circle cx="22" cy="16" r="2" />
              </svg>
            </>
          )}
        </button>

        <Transition
          className={`origin-top-right z-10 absolute top-full min-w-44 bg-white dark:bg-slate-800 border border-slate-200 dark:border-slate-700 py-1.5 rounded shadow-lg overflow-hidden mt-1 ${
            align === 'right' ? 'right-0' : 'left-0'
          } ${isSetCategoryStyle ? ' w-full' : ''}`}
          show={dropdownOpen}
          enter="transition ease-out duration-200 transform"
          enterStart="opacity-0 -translate-y-2"
          enterEnd="opacity-100 translate-y-0"
          leave="transition ease-out duration-200"
          leaveStart="opacity-100"
          leaveEnd="opacity-0"
        >
          <div
            ref={dropdown}
            // onFocus={() => setDropdownOpen(true)}
            // onBlur={() => setDropdownOpen(false)}
          >
            <div className="relative px-3">
              <input
                type="text"
                placeholder="Search People"
                className={`form-input text-indigo-600 pr-6 placeholder:!text-indigo-600 !border-indigo-600  my-2 pl-8 w-full inline-block ${
                  isSetCategoryStyle ? ' flex-1 height-fit' : ''
                } `}
                value={searchValue}
                onChange={handleSearch}
                autoFocus
                ref={searchInput}
                onClick={(e) => e.stopPropagation()}
              />
              {searchLoader && (
                <svg
                  className="animate-spin w-4 h-4 fill-indigo-600 shrink-0 absolute top-[33%]  right-4 "
                  viewBox="0 0 16 16"
                >
                  <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z" />
                </svg>
              )}
              <svg
                className="absolute left-3 top-[50%] -translate-y-[50%] pl-2 w-6 h-6"
                width="16"
                height="17"
                viewBox="0 0 16 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14 14.5L11.6667 12.1667M13.3333 8.16667C13.3333 11.2963 10.7963 13.8333 7.66667 13.8333C4.53705 13.8333 2 11.2963 2 8.16667C2 5.03705 4.53705 2.5 7.66667 2.5C10.7963 2.5 13.3333 5.03705 13.3333 8.16667Z"
                  stroke="#E48642"
                  strokeWidth="1.2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
            <div
              className={
                isSetCategoryStyle
                  ? 'max-h-40 min-h-8 overflow-auto w-full flex flex-col scrollbar'
                  : 'max-h-60 min-h-8 overflow-auto scrollbar'
              }
            >
              {!isSetCategoryStyle && !searchValue && !inlineMenu && (
                <span
                  className="font-normal whitespace-nowrap flex items-center pr-4 text-[13px] text-[#667085]  hover:text-slate-800 dark:hover:text-slate-200 py-1 px-3"
                  onClick={(e) => {
                    e.stopPropagation();
                    if (localPeople !== null) {
                      setSelectedPeople(null);
                    }
                    setDropdownOpen(false);
                  }}
                  role="button"
                  tabIndex="0"
                >
                  All People
                </span>
              )}
              {filteredData?.map((merch) => (
                <div
                  key={merch.id}
                  className={`pl-2 cursor-pointer border-b last:border-none text-[#667085]  hover:text-slate-800 border-[#D0D5DD]
              ${
                merch?.id === localPeople?.id &&
                'text-indigo-500 hover:!text-indigo-600'
              }`}
                  onClick={(e) => {
                    e.stopPropagation();
                    if (merch.id !== localPeople?.id) {
                      setSelectedPeople(merch.id, merch);
                      setLocalPeople(merch);
                    }
                    setDropdownOpen(false);
                  }}
                  role="button"
                  tabIndex="0"
                >
                  <span
                    className={`font-normal whitespace-nowrap flex items-center pr-4 text-[14px]  pt-1 px-3
                            ${
                              merch?.id === localPeople?.id &&
                              'text-indigo-500 hover:!text-indigo-600'
                            }`}
                  >
                    {`${merch?.firstName} ${merch?.lastName}`}
                    <svg
                      className={`shrink-0 ml-2 fill-current text-indigo-500 ${
                        merch?.id !== localPeople?.id && 'invisible'
                      }`}
                      width="12"
                      height="9"
                      viewBox="0 0 12 9"
                    >
                      <path d="M10.28.28L3.989 6.575 1.695 4.28A1 1 0 00.28 5.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28.28z" />
                    </svg>
                  </span>
                  <p
                    className={`font-normal whitespace-nowrap flex items-center pr-4 text-[12px]  px-3 pb-1
                            `}
                  >
                    {merch?.email}
                  </p>
                </div>
              ))}
              {!searchLoader && !isResultFound && searchValue && (
                <div className="ml-2 cursor-pointer">
                  <span className="px-4 text-sm my-4 min-h-6 cursor-default">
                    No Person Found
                  </span>
                </div>
              )}
            </div>
            {addNewOptionButton && (
              <button
                type="button"
                className="btn h-10 border-b-0 border-x-0 !border-t border-[#D0D5DD]  gap-2 text-indigo-500 text-base w-full cursor-pointer"
                onClick={handleNewOptionButton}
              >
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                >
                  <path
                    d="M8.00004 14.6673C11.6667 14.6673 14.6667 11.6673 14.6667 8.00065C14.6667 4.33398 11.6667 1.33398 8.00004 1.33398C4.33337 1.33398 1.33337 4.33398 1.33337 8.00065C1.33337 11.6673 4.33337 14.6673 8.00004 14.6673Z"
                    stroke="#E48642"
                    strokeWidth="1.2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M5.33337 8H10.6667"
                    stroke="#E48642"
                    strokeWidth="1.2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M8 10.6663V5.33301"
                    stroke="#E48642"
                    strokeWidth="1.2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                Create a New Person
              </button>
            )}
          </div>
        </Transition>
      </div>
      {error && <div className=" mt-1 text-xs text-rose-400">{error}</div>}
    </>
  );
}

export default PeopleDropdown;
