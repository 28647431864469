import React, { useEffect } from 'react';
import { useSearchParams, useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { gustoTokenExchange } from '../../../API/backend_helper';
import AppLayout from '../../../components/AppLayout';

const GustoAuth = () => {
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const location = useLocation();

  const code = searchParams.get('code') || '';
  const state = searchParams.get('state') || '';
  const event = searchParams.get('event') || '';
  const error_description = searchParams.get('error_description') || '';

  const tokenExchange = async () => {
    if (event === 'REVOKE') {
      toast.success('Access to accounts has been revoked');
      navigate('/settings/integrations');
      return;
    }
    if (code) {
      const localState = localStorage.getItem('Gusto_Random_Number');
      if (localState === state) {
        try {
          await gustoTokenExchange({
            integrationWith: 'gusto',
            publicToken: code,
          });
          localStorage.removeItem('Gusto_Random_Number');
          navigate('/app-integrations/gusto-settings');
        } catch (e) {
          localStorage.removeItem('Gusto_Random_Number');
          navigate('/settings/integrations');
          console.log('Error', e);
        }
      } else {
        localStorage.removeItem('Gusto_Random_Number');
        toast.error(
          'We are unable to authenticate this request. Please contact support for assistance',
        );
        navigate('/settings/integrations');
      }
    } else {
      localStorage.removeItem('Gusto_Random_Number');
      if (error_description) {
        toast.error(error_description);
      } else {
        toast.error(
          'Oops! Something unexpected occurred. Please contact support for assistance',
        );
      }
      navigate('/settings/integrations');
    }
  };

  useEffect(() => {
    if (location?.search) {
      tokenExchange();
    }
  }, [location?.search]);

  return (
    <AppLayout>
      <main className="grow">
        <div className="flex flex-col gap-2 items-center pb-2 justify-center mt-8">
          <svg
            width="29"
            height="29"
            viewBox="0 0 29 29"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="animate-spin w-10 h-10 fill-current shrink-0"
          >
            <path
              d="M14.5 2.41699V7.25033"
              stroke="#E48642"
              strokeWidth="2.55882"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M14.5 21.749V26.5824"
              stroke="#E48642"
              strokeWidth="2.55882"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M5.95703 5.95703L9.37662 9.37662"
              stroke="#E48642"
              strokeWidth="1.58955"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M19.623 19.6211L23.0426 23.0407"
              stroke="#E48642"
              strokeWidth="1.58955"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M2.41699 14.5H7.25033"
              stroke="#E48642"
              strokeWidth="1.58955"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M21.749 14.5H26.5824"
              stroke="#E48642"
              strokeWidth="1.58955"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M5.95703 23.0407L9.37662 19.6211"
              stroke="#E48642"
              strokeWidth="1.58955"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M19.623 9.37662L23.0426 5.95703"
              stroke="#E48642"
              strokeWidth="1.58955"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <p className="text-slate-600 font-medium text-[0.875rem] text-center">
            Importing your Information, please wait...
          </p>
        </div>
      </main>
    </AppLayout>
  );
};

export default GustoAuth;
