import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  teamName: "",
  teamId: -1,
  team: {},
};

const TeamSlice = createSlice({
  name: "Team",
  initialState,
  reducers: {
    setTeamName(state, action) {
      state.teamName = action.payload;
    },
    setTeamId(state, action) {
      state.teamId = action.payload;
    },
    setTeam(state, action) {
      state.team = action.payload;
    },
  },
});

export const { setTeamName, setTeamId, setTeam } = TeamSlice.actions;

export default TeamSlice.reducer;
