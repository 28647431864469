import React, { useEffect, useState } from 'react';
import ModalBasic from '../../components/ModalBasic';
import { uploadTeamLogo } from '../../API/backend_helper';
import ImageCropModal from '../../components/ImageCropModal';
import UploadFile from '../../components/UploadFile';

const WorkspaceLogoModal = ({ isOpen, setIsOpen, team, fetchTeam }) => {
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState([]);
  const [imgName, setImgName] = useState('');
  const [imgPreview, setImgPreview] = useState('');

  const [cropModalOpen, setCropModalOpen] = useState(false);
  const [currentImageForCrop, setCurrentImageForCrop] = useState(null);

  useEffect(() => {
    if (team?.logoUrl) {
      setImgPreview(team?.logoUrl);
    } else {
      setImgPreview('');
    }
  }, [team]);

  const uploadLogo = async () => {
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append('logo', files?.[0]);
      const res = await uploadTeamLogo(formData, team?.id);
      if (res) {
        fetchTeam(true);
      }
      setFiles([]);
      setImgName('');
      setImgPreview('');
      setIsOpen(false);
      setLoading(false);
    } catch (e) {
      console.log('error', e);
      setLoading(false);
    }
  };

  return (
    <ModalBasic
      modalOpen={isOpen}
      setModalOpen={setIsOpen}
      title={`${team?.logoUrl ? 'Edit' : 'Add'} Business Logo`}
      width="!w-[550px]"
    >
      <div className="p-6">
        <ImageCropModal
          isOpen={cropModalOpen}
          setIsOpen={setCropModalOpen}
          image={currentImageForCrop}
          setPreviewImage={setImgPreview}
          setFiles={setFiles}
          imgName={imgName}
        />
        <div>
          <UploadFile
            title="Logo"
            onFilesAdded={(files) => {
              setFiles((prevFiles) => [...prevFiles, ...files]);
              if (files?.[0]) {
                const filePreviewUrl = URL.createObjectURL(files?.[0]);
                setCurrentImageForCrop(filePreviewUrl);
                setCropModalOpen(true);
                setImgName(files?.[0]?.name);
              }
            }}
            name="logo"
            id="logo"
            imgPreview={imgPreview}
            setImgPreview={setImgPreview}
            files={files}
            setFiles={setFiles}
            accept={'image/*'}
          />
          <button
            type="button"
            onClick={uploadLogo}
            className="btn-sm -translate-x-1/2 ml-[50%] h-fit py-[5px] mt-2 px-[10px] whitespace-nowrap text-sm !bg-indigo-500
          hover:!bg-indigo-600 shadow-md hover:shadow-lg text-white disabled:!bg-indigo-300 disabled:cursor-not-allowed"
            disabled={loading || !files?.[0]}
          >
            {loading && (
              <svg
                className="animate-spin w-4 h-4 fill-current shrink-0 mr-2"
                viewBox="0 0 16 16"
              >
                <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z" />
              </svg>
            )}
            Upload
          </button>
        </div>
      </div>
    </ModalBasic>
  );
};

export default WorkspaceLogoModal;
