import React, { useRef, useState, useEffect, useMemo } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useSelector } from 'react-redux';
import CategoriesDropdown from '../CategoriesDropdown';
import DropdownTransaction from '../../../components/DropdownTransaction';
import MerchantsDropdown from '../../../components/MerchantsDropdown';
import SplitTransactionModal from '../SplitTransactionModal';
import { createTransaction, getCustomers } from '../../../API/backend_helper';
import { Income, TEAM_ID } from '../../../utils/Utils';
import ManageTeamDropdown from '../../../components/ManageTeamDropdown';
import DropdownFull from '../../../components/DropdownFull';
import Flatpickr from 'react-flatpickr';
import { split } from 'postcss/lib/list';
import Transition from '../../../utils/Transition';
import useClickOutside from '../../../utils/useClickOutside';
import { createMerchant, createCustomer } from '../../../API/backend_helper';
import moment from 'moment';
import { toast } from 'react-toastify';
import DatePickerDash from '../../../components/DatePickerDash';

let searchTimeout = null;

const ExpenseIncomeTransaction = ({
  isOpen,
  setIsOpen,
  allCategories,
  accounts,
  allAccounts,
  getTransactionsApi,
  vendors,
  setVendors,
  type,
  teamCurrency,
  currencies,
  customers,
  setCustomers,
  allExpenseCategories,
  accountId = null,
  minDate,
  maxDate,
  projects,
}) => {
  const formikRef = useRef(null);
  const currencyRef = useRef(null);
  const vendorRef = useRef(null);
  const customerRef = useRef(null);
  const projectRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [selectedMerchant, setSelectedMerchant] = useState(null);
  const [isSplitting, setIsSplitting] = useState(false);
  const [splitData, setSplitData] = useState(null);
  const [splitTotal, setSplitTotal] = useState(true);
  const [showCurrencyDropdown, setShowCurrencyDropdown] = useState(false);
  const [showVendorDropdown, setShowVendorDropdown] = useState(false);
  const [showCustomerDropdown, setShowCustomerDropdown] = useState(false);
  const [selectedCurrency, setSelectedCurrency] = useState(teamCurrency);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [selectedVendor, setSelectedVendor] = useState(null);
  const [searchValue, setSearchValue] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const [flatPickrKey, setFlatPickrKey] = useState(0);
  const [selectedProject, setSelectedProject] = useState(null);
  const [showProjectDropdown, setShowProjectDropdown] = useState(false);

  const { team } = useSelector((state) => state.Team);

  const teamId = localStorage.getItem(TEAM_ID);

  useClickOutside(currencyRef, () => {
    setTimeout(() => {
      setShowCurrencyDropdown(false);
    }, 100);
  });

  useClickOutside(vendorRef, () => {
    setTimeout(() => {
      setShowVendorDropdown(false);
    }, 100);
  });

  useClickOutside(customerRef, () => {
    setTimeout(() => {
      setShowCustomerDropdown(false);
    }, 100);
  });

  useEffect(() => {
    if (isSplitting && splitData) {
      let total = splitData?.splitTransactions
        ?.map((split) => parseFloat(split.amount.value))
        .reduce((a, b) => a + b, 0);
      setSplitTotal(total);
    }
  }, [isSplitting, splitData]);

  useEffect(() => {
    if (!isOpen) {
      formikRef.current?.resetForm({
        values: {
          ...formikRef.current.initialValues,
          postedDate: maxDate
            ? new Date(maxDate).toISOString().split('T')[0]
            : new Date().toISOString().split('T')[0],
        },
      });
      setFlatPickrKey((prevKey) => prevKey + 1);
      setIsSplitting(false);
      setSplitData(null);
      setSplitTotal(true);
      setSelectedMerchant(null);
      setSelectedCurrency(teamCurrency);
      setSelectedVendor(null);
      setSelectedCustomer(null);
      setShowCurrencyDropdown(false);
      setShowVendorDropdown(false);
      setShowCustomerDropdown(false);
      setSearchValue('');
      setFilteredData([]);
      setSelectedProject(null);
      setShowProjectDropdown(false);
    }
    setSelectedCurrency(teamCurrency);
  }, [isOpen]);

  const options = useMemo(
    () => ({
      mode: 'single',
      static: true,
      monthSelectorType: 'static',
      allowInput: true,
      dateFormat:
        team?.defaults?.dateFormat === 'dd/mm/yyyy' ? 'd/m/Y' : 'm/d/Y',
      maxDate: maxDate ? moment(maxDate).toISOString() : new Date(),
      prevArrow:
        '<svg class="fill-current" width="7" height="11" viewBox="0 0 7 11"><path d="M5.4 10.8l1.4-1.4-4-4 4-4L5.4 0 0 5.4z" /></svg>',
      nextArrow:
        '<svg class="fill-current" width="7" height="11" viewBox="0 0 7 11"><path d="M1.4 10.8L0 9.4l4-4-4-4L1.4 0l5.4 5.4z" /></svg>',
    }),
    [team],
  );

  const today = new Date();
  const monthNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  const formattedDate = maxDate
    ? `${moment(maxDate).format('MMM DD, YYYY')}`
    : `${
        monthNames[today.getMonth()]
      } ${today.getDate()}, ${today.getFullYear()}`;

  const handleSubmit = async (data) => {
    setLoading(true);
    let modifiedData = { ...data };
    if (isSplitting) {
      const { parentAmount, ...splits } = splitData;
      const formattedSplits = splits.splitTransactions
        .slice(1)
        .map((split) => ({
          categoryAccountId: split.categoryAccountId.value,
          amount:
            type === 'income' && parseFloat(split.amount.value) > 0
              ? parseFloat(split.amount.value) * -1
              : parseFloat(split.amount.value),
        }));

      modifiedData = {
        ...data,
        teamId: parseInt(teamId),
        amount: parseFloat(parentAmount),
        categoryAccountId: splits.splitTransactions[0].categoryAccountId.value,
        splits: formattedSplits,
      };
    }

    try {
      const res = await createTransaction({
        ...modifiedData,
        postedDate: moment(data?.postedDate).format('YYYY-MM-DD'),
        type: type,
        originalDescription: modifiedData.description,
        vendorId: selectedVendor?.id,
        customerId: selectedCustomer?.id,
        currency: selectedCurrency?.isoCode,
        projectId: selectedProject?.id,
        amount:
          type === 'income' && modifiedData.amount > 0
            ? modifiedData.amount * -1
            : modifiedData?.amount,
      });
      await getTransactionsApi({});
      formikRef.current?.resetForm();
      setIsOpen(false);
      toast.success('Transaction Created Successfully');
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const getCustomersBySearch = async ({ limit = 50, search = '' }) => {
    try {
      const res = await getCustomers({
        search,
        limit,
      });
      return res?.data;
    } catch (e) {
      console.log('error', e);
    }
  };

  const handleSearch = (e) => {
    setSearchValue(e.target.value);
    if (showVendorDropdown) {
      if (e.target.value) {
        const filteredVendors = vendors.filter((vendor) =>
          vendor.name.toLowerCase().includes(e.target.value.toLowerCase()),
        );
        setFilteredData(filteredVendors);
      } else {
        setFilteredData(vendors);
      }
    }
    if (showCustomerDropdown) {
      if (e.target.value) {
        clearTimeout(searchTimeout);
        searchTimeout = setTimeout(async () => {
          const data = await getCustomersBySearch({
            search: encodeURIComponent(e.target.value || ''),
          });
          const filteredCustomers = data?.records?.filter((customer) =>
            customer.customer
              .toLowerCase()
              .includes(e.target.value.toLowerCase()),
          );
          setFilteredData(filteredCustomers);
        }, 500);
      } else {
        setFilteredData(customers);
      }
    }
    if (showProjectDropdown) {
      if (e.target.value) {
        const filteredProjects = projects.filter((project) =>
          project.name.toLowerCase().includes(e.target.value.toLowerCase()),
        );
        setFilteredData(filteredProjects);
      } else {
        setFilteredData(projects);
      }
    }
  };

  const handleAddVendor = async () => {
    try {
      const res = await createMerchant({
        name: searchValue,
      });
      setSelectedVendor(res);
      setVendors((prev) => [...prev, res]);
      setSearchValue('');
    } catch (err) {
      console.log('err', err);
    }
  };

  const handleAddCustomer = async () => {
    try {
      const res = await createCustomer({
        customer: searchValue,
        contacts: [
          {
            firstName: '',
            lastName: '',
            phone: '',
          },
        ],
        note: '',
        billingAddress: {
          street: '',
          street2: '',
          country: '',
          city: '',
          state: '',
          zipCode: '',
        },
        shippingAddress: {
          street: '',
          street2: '',
          country: '',
          city: '',
          state: '',
          zipCode: '',
        },
      });
      setSelectedCustomer(res?.data?.customer);
      setCustomers((prev) => [...prev, res?.data?.customer]);
      setSearchValue('');
    } catch (err) {
      console.log('err', err);
    }
  };

  return (
    <Formik
      enableReinitialize={false}
      initialValues={{
        accountId: accountId ? parseInt(accountId) : null,
        vendorId: null,
        name: '',
        categoryAccountId: isSplitting ? undefined : null,
        amount: isSplitting ? undefined : '',
        postedDate: maxDate
          ? new Date(maxDate)
          : new Date()?.toISOString?.()?.split?.('T')?.[0],
        notes: '',
        type: type,
        currency: selectedCurrency?.isoCode,
        description: '',
      }}
      validationSchema={Yup.object({
        accountId: Yup.number().required('Please Select Account'),
        name: Yup.string(),
        vendorId: undefined,
        categoryAccountId: isSplitting
          ? undefined
          : Yup.string().required('Please Select Category'),
        amount: isSplitting
          ? undefined
          : Yup.string().required('Please Enter Amount'),
        postedDate: Yup.string().required('Please Enter Payment Date'),
        currency: Yup.string(),
        description: Yup.string().required('Please Enter Description'),
      })}
      onSubmit={handleSubmit}
      innerRef={formikRef}
    >
      {(validation) => (
        <Form>
          <div className="flex flex-col items-start gap-[1.875rem] w-full px-10">
            <div className="flex items-start gap-4 w-full relative">
              <div className="flex flex-col items-start gap-2.5 w-full">
                <label
                  className="block text-sm text-slate-600 font-medium leading-5"
                  htmlFor="description"
                >
                  Description <span className="text-rose-500">*</span>
                </label>
                <input
                  id="description"
                  type="description"
                  placeholder="Description"
                  name="description"
                  className={`btn text-base h-12 w-full rounded-[0.313rem] border border-[#D0D5DD] text-slate-600leading-6 font-normal px-4 py-3.5 bg-white
                              placeholder:text-base placeholder:text-slate-600 placeholder:text-opacity-80 placeholder:leading-6 placeholder:font-normal ${
                                validation.touched.description &&
                                validation.errors.description &&
                                'border-rose-300'
                              }`}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.description || ''}
                  invalid={
                    validation.touched.description &&
                    validation.errors.description
                      ? true
                      : false
                  }
                />
                {validation.touched.description &&
                validation.errors.description ? (
                  <div className="text-xs mt-1 text-rose-500">
                    {validation.errors.description}
                  </div>
                ) : null}
                <div className="w-full flex">
                  {!selectedCustomer && (
                    <>
                      <span
                        className={`text-indigo-500 text-sm ml-1 ${
                          selectedVendor ? '' : 'cursor-pointer'
                        }`}
                        onClick={(e) => {
                          e.stopPropagation();
                          e.preventDefault();
                          setFilteredData(vendors);
                          if (!selectedVendor) {
                            setShowVendorDropdown(!showVendorDropdown);
                          }
                        }}
                      >
                        {showVendorDropdown ? (
                          'Cancel'
                        ) : selectedVendor ? (
                          <span className="text-black">
                            Vendor: {selectedVendor?.name}
                          </span>
                        ) : (
                          'Add Vendor'
                        )}
                        {!selectedCustomer && !selectedVendor && (
                          <span className="text-black ml-1 mr-px">|</span>
                        )}
                      </span>
                      {selectedVendor && !showVendorDropdown && (
                        <>
                          <span className="text-black ml-2 mr-2">|</span>
                          <span
                            className="text-indigo-500 text-sm cursor-pointer"
                            onClick={() => setSelectedVendor(null)}
                          >
                            Remove Vendor
                          </span>
                        </>
                      )}
                    </>
                  )}
                  {!selectedVendor && (
                    <>
                      <span
                        className={`text-indigo-500 text-sm ml-1 ${
                          selectedCustomer ? '' : 'cursor-pointer'
                        }`}
                        onClick={(e) => {
                          e.stopPropagation();
                          e.preventDefault();
                          setFilteredData(customers);
                          if (!selectedCustomer) {
                            setShowCustomerDropdown(!showCustomerDropdown);
                          }
                        }}
                      >
                        {showCustomerDropdown ? (
                          'Cancel'
                        ) : selectedCustomer ? (
                          <span className="text-black">
                            Customer: {selectedCustomer?.customer}
                          </span>
                        ) : (
                          'Add Customer'
                        )}
                      </span>
                      {selectedCustomer && !showCustomerDropdown && (
                        <>
                          <span className="text-black ml-2 mr-2">|</span>
                          <span
                            className="text-indigo-500 text-sm cursor-pointer"
                            onClick={() => setSelectedCustomer(null)}
                          >
                            Remove Customer
                          </span>
                        </>
                      )}
                    </>
                  )}
                  <>
                    <span
                      className={`text-indigo-500 text-sm ml-1 ${
                        selectedProject ? '' : 'cursor-pointer'
                      }`}
                      onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        setFilteredData(projects);
                        if (!selectedProject) {
                          setShowProjectDropdown(!showProjectDropdown);
                        }
                      }}
                    >
                      <span className="text-black ml-px mr-1 text-sm">|</span>
                      {showProjectDropdown ? (
                        'Cancel'
                      ) : selectedProject ? (
                        <span className="text-black">
                          Project: {selectedProject?.name}
                        </span>
                      ) : (
                        'Assign to Project'
                      )}
                    </span>
                    {selectedProject && !showProjectDropdown && (
                      <>
                        <span className="text-black ml-2 mr-2">|</span>
                        <span
                          className="text-indigo-500 text-sm cursor-pointer"
                          onClick={() => setSelectedProject(null)}
                        >
                          Remove Project
                        </span>
                      </>
                    )}
                  </>
                </div>
                <Transition
                  show={showVendorDropdown}
                  tag="div"
                  className="z-60 absolute top-[7rem] left-0 w-full sm:w-1/2 bg-white dark:bg-slate-800 border border-slate-200 dark:border-slate-700 py-1.5 rounded shadow-lg overflow-hidden mt-1"
                  enter="transition ease-out duration-100 transform"
                  enterStart="opacity-0 -translate-y-2"
                  enterEnd="opacity-100 translate-y-0"
                  leave="transition ease-out duration-100"
                  leaveStart="opacity-100"
                  leaveEnd="opacity-0"
                >
                  {showVendorDropdown && (
                    <div
                      ref={vendorRef}
                      className="font-medium text-sm text-slate-600 dark:text-slate-300 divide-y divide-slate-200 dark:divide-slate-700"
                      onFocus={() => setShowVendorDropdown(true)}
                    >
                      <div className="w-full flex items-center justify-center">
                        <input
                          type="text"
                          placeholder="Type Vendor Name"
                          className={`form-input text-indigo-600 pr-6 placeholder:!text-indigo-600 !border-indigo-600 my-2 w-full mx-3 inline-block `}
                          autoFocus
                          onClick={(e) => e.stopPropagation()}
                          onChange={handleSearch}
                        />
                      </div>
                      <div className="max-h-48 overflow-auto scrollbar">
                        {filteredData?.map((vendor, index) => (
                          <button
                            type="button"
                            key={index}
                            className={`flex items-center justify-between w-full hover:bg-slate-50 dark:hover:bg-slate-700/20 py-2 px-3 cursor-pointer ${
                              vendor.id === selectedVendor?.id &&
                              'text-indigo-500'
                            }`}
                            onClick={() => {
                              setSelectedVendor(vendor);
                              if (!validation.values.description) {
                                validation.setFieldValue(
                                  'description',
                                  `${vendor?.name}`,
                                );
                              }
                              setShowVendorDropdown(false);
                            }}
                          >
                            {`${vendor?.name}`}
                          </button>
                        ))}
                        {searchValue && filteredData?.length === 0 && (
                          <button
                            type="button"
                            className={`flex items-center w-full hover:bg-slate-50 dark:hover:bg-slate-700/20 py-2 px-3 cursor-pointer`}
                            onClick={(e) => {
                              e.stopPropagation();
                              handleAddVendor();
                              setShowVendorDropdown(false);
                            }}
                          >
                            <span className="text-black font-medium mr-1">
                              + Add:
                            </span>{' '}
                            {searchValue}
                          </button>
                        )}
                      </div>
                    </div>
                  )}
                </Transition>
                <Transition
                  show={showCustomerDropdown}
                  tag="div"
                  className="z-60 absolute top-[7rem] left-0 w-full sm:w-1/2 bg-white dark:bg-slate-800 border border-slate-200 dark:border-slate-700 py-1.5 rounded shadow-lg overflow-hidden mt-1"
                  enter="transition ease-out duration-100 transform"
                  enterStart="opacity-0 -translate-y-2"
                  enterEnd="opacity-100 translate-y-0"
                  leave="transition ease-out duration-100"
                  leaveStart="opacity-100"
                  leaveEnd="opacity-0"
                >
                  {showCustomerDropdown && (
                    <div
                      ref={customerRef}
                      className="font-medium text-sm text-slate-600 dark:text-slate-300 divide-y divide-slate-200 dark:divide-slate-700"
                      onFocus={() => setShowCustomerDropdown(true)}
                    >
                      <div className="w-full flex items-center justify-center">
                        <input
                          type="text"
                          placeholder="Type Customer Name"
                          className={`form-input text-indigo-600 pr-6 placeholder:!text-indigo-600 !border-indigo-600 my-2 w-full mx-3 inline-block `}
                          autoFocus
                          onClick={(e) => e.stopPropagation()}
                          onChange={handleSearch}
                        />
                      </div>
                      <div className="max-h-48 overflow-auto scrollbar">
                        {filteredData?.map((customer, index) => (
                          <button
                            type="button"
                            key={index}
                            className={`flex items-center justify-between w-full hover:bg-slate-50 dark:hover:bg-slate-700/20 py-2 px-3 cursor-pointer ${
                              customer.id === selectedCustomer?.id &&
                              'text-indigo-500'
                            }`}
                            onClick={() => {
                              setSelectedCustomer(customer);
                              setShowCustomerDropdown(false);
                            }}
                          >
                            {`${customer?.customer}`}
                          </button>
                        ))}
                        {searchValue && filteredData?.length === 0 && (
                          <button
                            type="button"
                            className={`flex items-center w-full hover:bg-slate-50 dark:hover:bg-slate-700/20 py-2 px-3 cursor-pointer`}
                            onClick={(e) => {
                              e.stopPropagation();
                              handleAddCustomer();
                              setShowCustomerDropdown(false);
                            }}
                          >
                            <span className="text-black font-medium mr-1">
                              + Add:
                            </span>{' '}
                            {searchValue}
                          </button>
                        )}
                      </div>
                    </div>
                  )}
                </Transition>
                <Transition
                  show={showProjectDropdown}
                  tag="div"
                  className="z-60 absolute top-[7rem] left-0 w-full sm:w-1/2 bg-white dark:bg-slate-800 border border-slate-200 dark:border-slate-700 py-1.5 rounded shadow-lg overflow-hidden mt-1"
                  enter="transition ease-out duration-100 transform"
                  enterStart="opacity-0 -translate-y-2"
                  enterEnd="opacity-100 translate-y-0"
                  leave="transition ease-out duration-100"
                  leaveStart="opacity-100"
                  leaveEnd="opacity-0"
                >
                  {showProjectDropdown && (
                    <div
                      ref={projectRef}
                      className="font-medium text-sm text-slate-600 dark:text-slate-300 divide-y divide-slate-200 dark:divide-slate-700"
                      onFocus={() => setShowProjectDropdown(true)}
                    >
                      <div className="w-full flex items-center justify-center">
                        <input
                          type="text"
                          placeholder="Type Project Name"
                          className={`form-input text-indigo-600 pr-6 placeholder:!text-indigo-600 !border-indigo-600 my-2 w-full mx-3 inline-block `}
                          autoFocus
                          onClick={(e) => e.stopPropagation()}
                          onChange={handleSearch}
                        />
                      </div>
                      <div className="max-h-48 overflow-auto scrollbar">
                        {filteredData?.map((project, index) => (
                          <button
                            type="button"
                            key={index}
                            className={`flex items-center justify-between w-full hover:bg-slate-50 dark:hover:bg-slate-700/20 py-2 px-3 cursor-pointer ${
                              selectedProject?.id === project?.id &&
                              'text-indigo-500'
                            }`}
                            onClick={() => {
                              setSelectedProject(project);
                              setShowProjectDropdown(false);
                            }}
                          >
                            {`${project?.name}`}
                          </button>
                        ))}
                      </div>
                    </div>
                  )}
                </Transition>
              </div>
            </div>
            <div className="flex flex-col sm:flex-row items-start gap-4 w-full ">
              <div className="flex flex-col items-start gap-2.5 w-full sm:w-[19.563rem]">
                <label
                  className="block text-sm text-slate-600 font-medium leading-5"
                  htmlFor="accountId"
                >
                  Account <span className="text-rose-500">*</span>
                </label>
                <CategoriesDropdown
                  allCategories={allAccounts}
                  selectedCategoryId={validation.values.accountId}
                  setSelectedCategoryId={(value) => {
                    validation.setFieldValue('accountId', value);
                  }}
                  isSetCategoryStyle
                  id="accountId"
                  name="accountId"
                  height="h-12"
                  type="account"
                />
                {validation.touched.accountId && validation.errors.accountId ? (
                  <div className="text-xs mt-1 text-rose-500">
                    {validation.errors.accountId}
                  </div>
                ) : null}
              </div>
              <div className="flex flex-col items-start gap-2.5 w-full sm:w-[19.563rem]">
                <label
                  className="block text-sm text-slate-600 font-medium leading-5"
                  htmlFor="postedDate"
                >
                  Date <span className="text-rose-500">*</span>
                </label>
                <DatePickerDash
                  key={flatPickrKey}
                  inputClass={`px-4 py-2.5 h-12 rounded-[0.313rem] justify-between w-full text-base font-normal tracking-normal leading-6 text-slate-600 placeholder:text-base placeholder:font-normal placeholder:tracking-normal placeholder:text-slate-600 border-slate-600 hover:border-slate-600 cursor-pointer`}
                  onChange={(selectedDates) => {
                    validation.setFieldTouched('postedDate', true);
                    validation.setFieldValue('postedDate', selectedDates[0]);
                  }}
                  width="w-full"
                  placeholder={formattedDate}
                  value={validation.values.postedDate}
                  options={
                    minDate
                      ? {
                          ...options,
                          minDate: moment(minDate).toISOString(),
                          maxDate: moment(maxDate).toISOString(),
                        }
                      : options
                  }
                  name="postedDate"
                  id="postedDate"
                />
                {validation.errors.postedDate ? (
                  <div className="text-xs mt-1 text-rose-500">
                    {validation.errors.postedDate}
                  </div>
                ) : null}
              </div>
            </div>
            {isSplitting ? (
              <div className="col-span-full sm:col-span-12 w-full">
                <SplitTransactionModal
                  isOpen={isSplitting}
                  setIsOpen={setIsSplitting}
                  allCategories={allCategories}
                  isAddTransaction
                  setSplitData={setSplitData}
                  type={type}
                  addTransactionParent={validation.values.amount || ''}
                  addTransactionCategory={
                    validation.values.categoryAccountId || null
                  }
                  currency={selectedCurrency?.symbol}
                />
              </div>
            ) : (
              <>
                <div className="flex flex-col sm:flex-row items-start gap-4 w-full relative">
                  <div className="flex flex-col items-start gap-2.5 w-full sm:w-[19.563rem] relative">
                    <label
                      className="block text-sm text-slate-600 font-medium leading-5"
                      htmlFor="amount"
                    >
                      Amount ({selectedCurrency?.isoCode}){' '}
                      <span className="text-rose-500">*</span>
                      <span
                        className="text-indigo-500 text-sm ml-1 cursor-pointer leading-none"
                        onClick={(e) => {
                          e.stopPropagation();
                          e.preventDefault();
                          setShowCurrencyDropdown(!showCurrencyDropdown);
                        }}
                      >
                        {showCurrencyDropdown ? 'Cancel' : 'Change Currency'}
                      </span>
                    </label>
                    <input
                      id="amount"
                      type="number"
                      placeholder="0.00"
                      name="amount"
                      className={`btn pl-[3.35rem] pr-4 py-2.5 h-12 rounded-[0.313rem] justify-between w-full font-normal leading-6 form-input border-[#D0D5DD] text-slate-600 placeholder:text-slate-600 placeholder:text-opacity-80 ${
                        validation.touched.amount &&
                        validation.errors.amount &&
                        'border-rose-300'
                      }`}
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.amount || ''}
                      invalid={
                        validation.touched.amount && validation.errors.amount
                          ? true
                          : false
                      }
                    />
                    <span className="absolute top-[2.6rem] left-4 flex items-center gap-2">
                      {type === Income ? (
                        <span className="text-slate-600">
                          +{selectedCurrency?.symbol}
                        </span>
                      ) : (
                        <span className="text-slate-600">
                          -{selectedCurrency?.symbol}
                        </span>
                      )}
                      <div className="w-[0.038rem] h-[1.188rem] bg-slate-600"></div>
                    </span>
                    {validation.touched.amount && validation.errors.amount ? (
                      <div className="text-xs mt-1 text-rose-500">
                        {validation.errors.amount}
                      </div>
                    ) : null}
                    <p
                      className="text-indigo-500 text-sm underline leading-5 font-medium cursor-pointer"
                      onClick={() => setIsSplitting(true)}
                    >
                      Split Transaction
                    </p>
                    <Transition
                      show={showCurrencyDropdown}
                      tag="div"
                      className="z-60 absolute top-20 left-0 w-full bg-white dark:bg-slate-800 border border-slate-200 dark:border-slate-700 py-1.5 rounded shadow-lg overflow-hidden mt-1"
                      enter="transition ease-out duration-100 transform"
                      enterStart="opacity-0 -translate-y-2"
                      enterEnd="opacity-100 translate-y-0"
                      leave="transition ease-out duration-100"
                      leaveStart="opacity-100"
                      leaveEnd="opacity-0"
                    >
                      {showCurrencyDropdown && (
                        <div
                          ref={currencyRef}
                          className="font-medium text-sm text-slate-600 dark:text-slate-300 divide-y divide-slate-200 dark:divide-slate-700"
                          onFocus={() => setShowCurrencyDropdown(true)}
                          onBlur={() => setShowCurrencyDropdown(false)}
                        >
                          <div className="max-h-48 overflow-auto scrollbar">
                            {currencies?.map((currency, index) => (
                              <button
                                type="button"
                                key={index}
                                className={`flex items-center justify-between w-full hover:bg-slate-50 dark:hover:bg-slate-700/20 py-2 px-3 cursor-pointer ${
                                  currency.id === selectedCurrency?.id &&
                                  'text-indigo-500'
                                }`}
                                onClick={() => {
                                  setSelectedCurrency(currency);
                                  setShowCurrencyDropdown(false);
                                }}
                              >
                                {`${currency?.symbol} ${currency?.name}`}
                              </button>
                            ))}
                          </div>
                        </div>
                      )}
                    </Transition>
                  </div>
                  <div className="flex flex-col items-start gap-2.5 w-full sm:w-[19.563rem]">
                    <label
                      className="block text-sm text-slate-600 font-medium leading-5"
                      htmlFor="categoryAccountId"
                    >
                      Category <span className="text-rose-500">*</span>
                    </label>
                    <CategoriesDropdown
                      allCategories={
                        type === Income ? allCategories : allExpenseCategories
                      }
                      selectedCategoryId={validation.values.categoryAccountId}
                      setSelectedCategoryId={(value) => {
                        validation.setFieldValue('categoryAccountId', value);
                      }}
                      isSetCategoryStyle
                      id="categoryAccountId"
                      name="categoryAccountId"
                      height="h-12"
                    />
                    {validation.touched.categoryAccountId &&
                    validation.errors.categoryAccountId ? (
                      <div className="text-xs mt-1 text-rose-500">
                        {validation.errors.categoryAccountId}
                      </div>
                    ) : null}
                  </div>
                </div>
              </>
            )}
            <div className="flex flex-col items-start gap-2.5 w-full">
              <label
                className="block text-sm text-slate-600 font-medium leading-5"
                htmlFor="notes"
              >
                Note
              </label>
              <textarea
                name="notes"
                id="notes"
                placeholder="Write A Note"
                className={`form-textarea px-4 py-2.5 rounded-[0.313rem] text-slate-600 justify-between w-full font-normal leading-6 form-input border-[#D0D5DD] placeholder:text-slate-600 placeholder:text-opacity-80 placeholder:leading-6 placeholder:font-normal placeholder:text-base
                      ${
                        validation.touched.notes &&
                        validation.errors.notes &&
                        'border-rose-300'
                      }`}
                type="notes"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.notes || ''}
              />
              {validation.touched.notes && validation.errors.notes ? (
                <div className="text-xs mt-1 text-rose-500">
                  {validation.errors.notes}
                </div>
              ) : null}
            </div>
          </div>
          {/* Modal footer */}
          <div className="w-full h-[6.125rem] mt-[1.875rem] border-t border-[#D0D5DD] px-10">
            <div className="flex justify-end gap-4 items-center w-full h-full">
              <button
                type="button"
                className="h-11 w-[5.625rem] py-2.5 px-[1.875rem] flex items-center justify-center rounded-[0.313rem] bg-white border border-slate-600 text-slate-600 text-base leading-6"
                onClick={(e) => {
                  e.stopPropagation();
                  setIsOpen(false);
                }}
              >
                Close
              </button>
              <button
                type="submit"
                className="h-11 w-[5.625rem] py-2.5 px-[1.875rem] flex items-center justify-center rounded-[0.313rem] bg-indigo-500 text-white font-normal text-base leading-6 shadow-sm
                  disabled:bg-indigo-400    disabled:cursor-not-allowed"
                disabled={
                  loading ||
                  (isSplitting &&
                    splitTotal !== parseInt(splitData?.parentAmount))
                }
              >
                {loading && (
                  <svg
                    className="animate-spin w-4 h-4 fill-current shrink-0 mr-2"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z" />
                  </svg>
                )}
                Add
              </button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default ExpenseIncomeTransaction;
