import React, { useState } from "react";
import {
  BaseEdge,
  EdgeLabelRenderer,
  getBezierPath,
} from "reactflow";

function CustomEdge({
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
  data,
  nodes,
  setPanelOpen,
  setPanelStep,
  setIsEditing,
  setActiveNodeId,
  setIsInBetween,
  source,
  target,
}) {
  const [showButton, setShowButton] = useState(false);
  const sourceNode = nodes.find((node) => node.id === data?.sourceId);

  // We can move the edge more to the left by subtracting 150 from sourceX and targetX

  const edgePath = getBezierPath({
    sourceX,
    sourceY,
    targetX,
    targetY,
    sourcePosition,
    targetPosition,
  });

  const [labelX, labelY] = [(sourceX + targetX) / 2, (sourceY + targetY) / 2];

  const handleMouseEnter = () => setShowButton(true);
  const handleMouseLeave = () => setShowButton(false);

  const handleClick = () => {
    setIsEditing(false);
    setIsInBetween({source: source, target: target});
    setActiveNodeId(sourceNode?.id);
    setPanelOpen(true);
    setPanelStep("component1");
  };

  return (
    <>
      <BaseEdge path={edgePath} style={style} />
      <EdgeLabelRenderer>
        <div
          style={{
            position: "absolute",
            transform: `translate(-50%, -50%) translate(${labelX}px, ${labelY}px)`,
            pointerEvents: "all",
            width: "50px", 
            height: "50px", 
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          {showButton && (
            <button onClick={handleClick}>
              <svg
                width="37"
                height="37"
                viewBox="0 0 37 37"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.2879 36.1084H23.8205C32.5976 36.1084 36.1084 32.5976 36.1084 23.8205V13.2879C36.1084 4.51084 32.5976 1 23.8205 1H13.2879C4.51084 1 1 4.51084 1 13.2879V23.8205C1 32.5976 4.51084 36.1084 13.2879 36.1084Z"
                  fill="white"
                  stroke="#D0D5DD"
                  stroke-opacity="0.6"
                  stroke-width="1.35032"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <g clip-path="url(#clip0_3797_58515)">
                  <path
                    d="M11.7998 18.0547H25.5498"
                    stroke="#667085"
                    stroke-opacity="0.8"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M18.6748 11.1797V24.9297"
                    stroke="#667085"
                    stroke-opacity="0.8"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_3797_58515">
                    <rect
                      width="20"
                      height="20"
                      fill="white"
                      transform="translate(8.6748 8.05469)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </button>
          )}
        </div>
      </EdgeLabelRenderer>
    </>
  );
}

export default CustomEdge;
