export const IndustryData = [
  'Accounting, Bookkeeping',
  'Administration Services',
  'Advertising, Marketing, Media',
  'Agriculture, Ranching, Farming',
  'Association, Club, Charity',
  'Audio/Visual Production',
  'Automotive',
  'Chiropractic',
  'Cleaning, Janitorial Services',
  'Construction, Trades',
  'Dental',
  'Executive Coaching',
  'Fitness',
  'Food & Drink',
  'Entertainment Services',
  'Graphic Design, Development',
  'HR, Recruitment, Staffing',
  'Insurance',
  'Interiors, Architecture, Engineering',
  'IT, Technical, Software',
  'Lawn Care, Landscaping, Pool',
  'Legal Services',
  'Lodging, Hotel, Motel',
  'Manufacturing',
  'Market Research',
  'Massage',
  'Mental Health, Wellness',
  'Non-Store Retail',
  'Nutrition',
  'Occupational / Physical Therapy',
  'Performing Arts (acting, music, dance)',
  'Photography',
  'Public Relations, Communications',
  'Real Estate, Property Management',
  'Religious Organization',
  'Salon, Spa, Aesthetics',
  'Store-Based Retail',
  'Telemarketing, Telecommunications',
  'Transportation, Trucking, Delivery',
  'Visual Arts, Illustration',
  'Wholesale',
  'Writing, Editing',
  'Other (please specify)',
];

export const BussinessDropdown1 = [
  'Self-employed',
  'Sole Proprietorship',
  'Limited Liability Company',
  'Corporation',
  'Other',
];
export const BussinessDropdownLLC = [
  'Single-member LLC',
  'Multi-member LLC',
  "I'm not sure",
];

export const BussinessDropdownCorporation = [
  'C Corporation',
  'S Corporation',
  "I'm not sure",
];

export const BussinessDropdownOther = [
  'Non-profit',
  'Partnership',
  'Trust',
  "I'm not sure",
];

export const Currencies = [
  { id: 'USD', currency: 'United States Dollar' },
  { id: 'NZD', currency: 'New Zealand Dollar' },
  { id: 'CAD', currency: 'Canadian Dollar' },
  // { id: "EUR", currency: "Euro" },
  // { id: "JPY", currency: "Japanese Yen" },
  // { id: "GBP", currency: "British Pound Sterling" },
  // { id: "AUD", currency: "Australian Dollar" },
  // { id: "CHF", currency: "Swiss Franc" },
  // { id: "CNY", currency: "Chinese Yuan" },
  // { id: "SEK", currency: "Swedish Krona" },
  // { id: "INR", currency: "Indian Rupee" },
  // { id: "BRL", currency: "Brazilian Real" },
  // { id: "RUB", currency: "Russian Ruble" },
  // { id: "ZAR", currency: "South African Rand" },
  // { id: "SGD", currency: "Singapore Dollar" },
  // { id: "HKD", currency: "Hong Kong Dollar" },
  // { id: "NOK", currency: "Norwegian Krone" },
  // { id: "MXN", currency: "Mexican Peso" },
  // { id: "TRY", currency: "Turkish Lira" },
  // { id: "KRW", currency: "South Korean Won" },
  // Add more currencies as needed
];

export const OnlinePayment = [
  { id: 'PayPal', howBusinessGetsPaid: 'PayPal' },
  { id: 'Venmo', howBusinessGetsPaid: 'Venmo' },
  { id: 'Cash App', howBusinessGetsPaid: 'Cash App' },
  { id: 'Zelle', howBusinessGetsPaid: 'Zelle' },
  { id: 'Square', howBusinessGetsPaid: 'Square' },
  { id: 'Quickbooks Payments', howBusinessGetsPaid: 'Quickbooks Payments' },
  { id: 'Other', howBusinessGetsPaid: 'Other' },
];

export const CustomerPay = [
  { id: 'Cash App', howBusinessGetsPaid: 'Cash App' },
  { id: 'Venmo', howBusinessGetsPaid: 'Venmo' },
  { id: 'In-Person Credit Card', howBusinessGetsPaid: 'In-Person Credit Card' },
  { id: 'Bank Transfer/ACH', howBusinessGetsPaid: 'Bank Transfer/ACH' },
  { id: 'Cash or check', howBusinessGetsPaid: 'Cash or check' },
  { id: 'PayPal', howBusinessGetsPaid: 'PayPal' },
  { id: 'Other', howBusinessGetsPaid: 'Other' },
];
