import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setLocalUser } from '../../redux/User/reducer';

const Signout = () => {
  document.title = 'COUNT | Sign Out';
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    localStorage.clear();
    dispatch(setLocalUser(null));
    navigate('/signin');
  }, []);

  return null;
};

export default Signout;
