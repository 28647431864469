import React from 'react';

const AddressBar = ({
  validation,
  disabled = false,

  streetId = '',
  street2Id = '',
  cityId = '',
  stateId = '',
  zipCodeId = '',
  countryId = '',

  streetValue = '',
  street2Value = '',
  cityValue = '',
  stateValue = '',
  zipCodeValue = '',
  countryValue = '',

  streetError = '',
  street2Error = '',
  cityError = '',
  stateError = '',
  zipCodeError = '',
  countryError = '',
}) => (
  <div className="grid grid-cols-7 gap-2 w-full h-auto xl:h-12 border border-[#D0D5DD] py-2 xl:py-0 rounded-[0.313rem]  bg-white shadow-sm px-4 xl:px-0">
    <div className="col-span-full xl:col-span-3 gap-2 grid grid-cols-1 md:grid-cols-2 h-28 md:h-14 xl:h-auto border-b border-x-0 border-t-0 xl:border-none border-[#D0D5DD]">
      <div className="relative col-span-1 flex  items-center border-b md:border-none border-[#D0D5DD]">
        <input
          id={streetId}
          name={streetId}
          placeholder="Address"
          value={streetValue || ''}
          onChange={validation.handleChange}
          onBlur={validation.handleBlur}
          className={`form-input px-4  border-[#D0D5DD] w-full items-center placeholder:text-slate-600 placeholder:text-opacity-80 
                     placeholder:leading-6  border-l-[1.5px] border-r-0 border-y-0 md:border-none shadow-none
                     ${streetError ? '' : ''}`}
          disabled={disabled}
        />
        {streetError && (
          <p className="absolute -bottom-[3px] xl:-bottom-5 left-4 text-xs text-rose-500 font-medium whitespace-nowrap overflow-hidden text-ellipsis max-w-full">
            {streetError}
          </p>
        )}
      </div>
      <div className="relative col-span- flex items-center">
        <input
          id={street2Id}
          name={street2Id}
          placeholder="Address 2 (Optional)"
          value={street2Value || ''}
          onChange={validation.handleChange}
          onBlur={validation.handleBlur}
          className={`form-input w-full px-4 items-center placeholder:text-slate-600 placeholder:text-opacity-80 placeholder:leading-6   border-y-0 shadow-none
                      border-r-0 border-l-[1.5px] xl:border-x-[1.5px] border-[#D0D5DD]
                      ${street2Error ? '' : ''}`}
          disabled={disabled}
        />
        {street2Error && (
          <p className="absolute -bottom-[3px] xl:-bottom-5 left-4 text-xs text-rose-500 font-medium whitespace-nowrap overflow-hidden text-ellipsis max-w-full">
            {street2Error}
          </p>
        )}
      </div>
    </div>
    <div className="col-span-full xl:col-span-4 grid grid-cols-2 md:grid-cols-4 md:gap-2 h-28  md:h-14 xl:h-auto ">
      <div className="relative flex items-center border-b md:border-none border-[#D0D5DD]">
        <input
          id={cityId}
          name={cityId}
          placeholder="City"
          value={cityValue || ''}
          onChange={validation.handleChange}
          onBlur={validation.handleBlur}
          className={`form-input border-[#D0D5DD] w-full px-4 items-center placeholder:text-slate-600 placeholder:text-opacity-80 
                      placeholder:leading-6   border-l-[1.5px]  border-r-0 border-y-0 md:border-none shadow-none
                      ${cityError ? '' : ''}`}
          disabled={disabled}
        />
        {cityError && (
          <p className="absolute -bottom-[3px] xl:-bottom-5 left-4 text-xs text-rose-500 font-medium whitespace-nowrap overflow-hidden text-ellipsis max-w-full">
            {cityError}
          </p>
        )}
      </div>
      <div className="relative flex items-center border-b md:border-none">
        <input
          id={stateId}
          name={stateId}
          placeholder="State"
          value={stateValue || ''}
          onChange={validation.handleChange}
          onBlur={validation.handleBlur}
          className={`form-input border-[#D0D5DD] w-full px-4 items-center placeholder:text-slate-600 placeholder:text-opacity-80 placeholder:leading-6  shadow-none
          border-l-[1.5px]  border-r-0 md:border-x-[1.5px] border-y-0 
             ${stateError ? '' : ''}`}
          disabled={disabled}
        />
        {stateError && (
          <p className="absolute -bottom-[3px] xl:-bottom-5 left-4 text-xs text-rose-500 font-medium whitespace-nowrap overflow-hidden text-ellipsis max-w-full">
            {stateError}
          </p>
        )}
      </div>
      <div className="relative flex items-center">
        <input
          id={zipCodeId}
          name={zipCodeId}
          placeholder="Postal"
          value={zipCodeValue || ''}
          onChange={validation.handleChange}
          onBlur={validation.handleBlur}
          className={`form-input border-[#D0D5DD] w-full px-4 items-center placeholder:text-slate-600 placeholder:text-opacity-80 
                     placeholder:leading-6   border-l-[1.5px]  border-r-0 border-y-0 md:border-none shadow-none
                       ${zipCodeError ? '' : ''}`}
          disabled={disabled}
        />
        {zipCodeError && (
          <p className="absolute -bottom-[3px] xl:-bottom-5 left-4 text-xs text-rose-500 font-medium whitespace-nowrap overflow-hidden text-ellipsis max-w-full">
            {zipCodeError}
          </p>
        )}
      </div>
      <div className="relative flex items-center">
        <input
          id={countryId}
          name={countryId}
          placeholder="Country"
          value={countryValue || ''}
          onChange={validation.handleChange}
          onBlur={validation.handleBlur}
          className={`form-input border-[#D0D5DD] w-full px-4 items-center placeholder:text-slate-600 placeholder:text-opacity-80 placeholder:leading-6    shadow-none
          border-l-[1.5px]  border-y-0 border-r-0  
          ${countryError ? '' : ''}`}
          disabled={disabled}
        />
        {countryError && (
          <p className="absolute -bottom-[3px] xl:-bottom-5 left-4 text-xs text-rose-500 font-medium whitespace-nowrap overflow-hidden text-ellipsis max-w-full">
            {countryError}
          </p>
        )}
      </div>
    </div>
  </div>
);

export default AddressBar;

