import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import OnboardingImage from "../../images/onboarding-image.jpg";
import OnboardingDecoration from "../../images/auth-decoration.png";
import PlaidLink from "../settings/PlaidLink";
import { createPlaidToken } from "../../API/backend_helper";
import Logo from "../../images/custom/count-logo.png";

function Onboarding1({ onNextStep }) {
  const [token, setToken] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const accessToken = localStorage.getItem("accessToken");
  useEffect(() => {
    // if (isAuthenticated && localUser?.firstName && authToken) {
    const createLinkToken = async () => {
      try {
        const response = await createPlaidToken(accessToken);

        const { linkToken } = response;
        setToken(linkToken);
      } catch (e) {
        console.log("error", e);
      }
    };
    if (accessToken && !token) {
      createLinkToken();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken]);

  return (
    <main className="bg-white dark:bg-slate-900">
      <div className="relative flex">
        {/* Content */}
        <div className="w-full md:w-1/2">
          <div className="min-h-[100dvh] h-full flex flex-col after:flex-1">
            <div className="flex-1">
              {/* Header */}
              <div className="flex items-center justify-between h-16 px-4 sm:px-6 lg:px-8">
                {/* Logo */}
                <Link className="block">
                  <img src={Logo} alt="logo" className="h-8" />
                </Link>
              </div>

              {/* Progress bar */}
              <div className="px-4 pt-12 pb-8">
                <div className="max-w-md mx-auto w-full">
                  <div className="relative">
                    <div
                      className="absolute left-0 top-1/2 -mt-px w-full h-0.5 bg-slate-200 dark:bg-slate-700"
                      aria-hidden="true"
                    ></div>
                    <ul className="relative flex justify-between w-full">
                      <li>
                        <span className="flex items-center justify-center w-6 h-6 rounded-full text-xs font-semibold bg-indigo-500 text-white">
                          1
                        </span>
                      </li>
                      <li>
                        <span className="flex items-center justify-center w-6 h-6 rounded-full text-xs font-semibold bg-slate-100 dark:bg-slate-700 text-slate-500 dark:text-slate-400">
                          2
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div className="px-4 py-8 pt-0">
              <div className="max-w-md mx-auto">
                <h1 className="text-3xl text-slate-800 dark:text-slate-100 font-bold mb-6">
                  Connect your financial accounts ✨
                </h1>

                <div className="flex items-center justify-between">
                  {/* <button
                    className="btn bg-indigo-500 hover:bg-indigo-600 text-white ml-auto"
                    to="/onboarding-02"
                  >
                    Connect Bank Account -&gt;
                  </button> */}
                  {isLoading ? (
                    <h6>Loading ...</h6>
                  ) : (
                    token && (
                      <PlaidLink
                        loadData={onNextStep}
                        setIsLoading={setIsLoading}
                        token={token}
                        accessToken={accessToken}
                        shouldNavigate
                      />
                    )
                  )}
                </div>
                <Link
                  className="text-sm font-medium underline cursor-pointer text-blue-600 ml-2	 mt-4 inline-flex"
                  to="/"
                >
                  Skip for now
                </Link>
              </div>
            </div>
          </div>
        </div>

        {/* Image */}
        <div
          className="hidden md:block absolute top-0 bottom-0 right-0 md:w-1/2"
          aria-hidden="true"
        >
          <img
            className="object-cover object-center w-full h-full"
            src={OnboardingImage}
            width="760"
            height="1024"
            alt="Onboarding"
          />
          <img
            className="absolute top-1/4 left-0 -translate-x-1/2 ml-8 hidden lg:block"
            src={OnboardingDecoration}
            width="218"
            height="224"
            alt="Authentication decoration"
          />
        </div>
      </div>
    </main>
  );
}

export default Onboarding1;
