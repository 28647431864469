import React from 'react';

const TobTabs = ({
  activeTab,
  setActiveTab,
  PAYROLL_HISTORY_TAB,
  PAY_SETTINGS_TAB,
  RUN_PAYROLL_TAB,
  PAYROLL_SCHEDULE,
}) => (
  <div className="flex border-b-[2px] border-[#D0D5DD] gap-x-3 sm:gap-8 mb-10 flex-wrap">
    <div
      onClick={() => setActiveTab(RUN_PAYROLL_TAB)}
      className={`text-sm border-b-[3px] translate-y-[2px] border-transparent  cursor-pointer text-[#667085] pb-3
                  ${
                    activeTab === RUN_PAYROLL_TAB &&
                    ' !border-indigo-500  font-medium !text-[#101828]'
                  } `}
      role="button"
      tabIndex="0"
    >
      Run Payroll
    </div>
    <div
      onClick={() => setActiveTab(PAYROLL_HISTORY_TAB)}
      className={`text-sm border-b-[3px] translate-y-[2px] border-transparent  cursor-pointer text-[#667085] pb-3
                  ${
                    activeTab === PAYROLL_HISTORY_TAB &&
                    ' !border-indigo-500  font-medium !text-[#101828]'
                  } `}
      role="button"
      tabIndex="0"
    >
      Payroll History
    </div>

    <div
      onClick={() => setActiveTab(PAY_SETTINGS_TAB)}
      className={`text-sm border-b-[3px] translate-y-[2px] border-transparent  cursor-pointer text-[#667085] pb-3
                  ${
                    activeTab === PAY_SETTINGS_TAB &&
                    ' !border-indigo-500  font-medium !text-[#101828]'
                  } `}
      role="button"
      tabIndex="0"
    >
      Employee Pay Settings
    </div>
    <div
      onClick={() => setActiveTab(PAYROLL_SCHEDULE)}
      className={`text-sm border-b-[3px] translate-y-[2px] border-transparent  cursor-pointer text-[#667085] pb-3
                  ${
                    activeTab === PAYROLL_SCHEDULE &&
                    ' !border-indigo-500  font-medium !text-[#101828]'
                  } `}
      role="button"
      tabIndex="0"
    >
      Pay Schedule
    </div>
  </div>
);

export default TobTabs;
