import React from 'react';

const Tabs = ({
  activeTab,
  setActiveTab,
  OVERVIEW_TAB,
  INBOX_TAB,
  CLIENTS_TAB,
}) => (
  <div className="flex border-b-[2px] border-[#D0D5DD] gap-x-3 sm:gap-8 mb-6 flex-wrap">
    <div
      onClick={() => setActiveTab(OVERVIEW_TAB)}
      className={`text-sm  border-b-[3px] translate-y-[2px] border-transparent  cursor-pointer text-[#667085] pb-3
         ${
           activeTab === OVERVIEW_TAB &&
           ' !border-indigo-500  font-medium !text-[#101828]'
         } `}
      role="button"
      tabIndex="0"
    >
      Overview
    </div>
    {/* <div
      onClick={() => setActiveTab(INBOX_TAB)}
      className={`text-sm  border-b-[3px] translate-y-[2px] border-transparent  cursor-pointer text-[#667085] pb-3
         ${
           activeTab === INBOX_TAB &&
           ' !border-indigo-500  font-medium !text-[#101828]'
         } `}
      role="button"
      tabIndex="0"
    >
      Inbox
    </div> */}
    <div
      onClick={() => setActiveTab(CLIENTS_TAB)}
      className={`text-sm  border-b-[3px] translate-y-[2px] border-transparent  cursor-pointer text-[#667085] pb-3
         ${
           activeTab === CLIENTS_TAB &&
           ' !border-indigo-500  font-medium !text-[#101828]'
         } `}
      role="button"
      tabIndex="0"
    >
      Clients
    </div>
  </div>
);

export default Tabs;
