import React, { useState, useEffect } from "react";
import ModalBasic from "../../components/ModalBasic";
import { Loader } from "../../components/Svg";

const DeleteVendorModal = ({
  isOpen,
  setIsOpen,
  handleDeleteVendor,
  activeVendor,
  errMessage,
  setErrMessage,
  deleteVendorLoading,
}) => {

    useEffect(() => {
        if (!isOpen) setErrMessage("");
    }, [isOpen]);

  return (
    <>
      <ModalBasic
        title={errMessage ? "Error" : "Delete Vendor"}
        modalOpen={isOpen}
        setModalOpen={setIsOpen}
        smallModal
      >
        <div className="w-full px-5 py-[1.875rem] flex items-center self-stretch bg-white">
          {errMessage ? (
            <p className="text-base leading-6 text-slate-600 text-opacity-80">
              {errMessage.message}
            </p>
          ) : (
            <p className="text-base leading-6 text-slate-600 text-opacity-80">
              Are you sure you want to delete this vendor? This action cannot be
              undone.
            </p>
          )}
        </div>
        <div className="border-t border-gray-300 h-20 flex items-center justify-end gap-4 px-5">
          {errMessage ? (
            <button
              className="w-[5.625rem] h-11 flex items-center justify-center py-2.5 px-[1.875rem] rounded-[0.313rem] bg-indigo-500 shadow text-white leading-6 "
              onClick={() => {
                setIsOpen(false);
                setErrMessage("");
              }}
            >
              Close
            </button>
          ) : (
            <>
              <button
                className="w-[5.625rem] h-11 flex items-center justify-center py-2.5 px-[1.875rem] rounded-[0.313rem] bg-white border border-slate-600 text-slate-600 text-base leading-6"
                onClick={() => setIsOpen(false)}
              >
                No
              </button>
              <button
                className="w-[5.625rem] h-11 flex items-center justify-center py-2.5 px-[1.875rem] rounded-[0.313rem] bg-indigo-500 shadow text-white leading-6"
                onClick={() => handleDeleteVendor(activeVendor)}
                disabled={deleteVendorLoading}
              >
                {deleteVendorLoading ? (
                  <Loader width="w-4" height="h-4" color="#FFFFFF" />
                ) : (
                  "Yes"
                )}
              </button>
            </>
          )}
        </div>
      </ModalBasic>
    </>
  );
};

export default DeleteVendorModal;
