export const GoogleFonts = [
  { family: 'Roboto', name: 'Roboto', fontFamily: 'Roboto', id: 'Roboto' },
  {
    family: 'Open Sans',
    name: 'Open Sans',
    fontFamily: 'Open Sans',
    id: 'Open Sans',
  },
  { family: 'Lato', name: 'Lato', fontFamily: 'Lato', id: 'Lato' },
  {
    family: 'Montserrat',
    name: 'Montserrat',
    fontFamily: 'Montserrat',
    id: 'Montserrat',
  },
  { family: 'Raleway', name: 'Raleway', fontFamily: 'Raleway', id: 'Raleway' },
  {
    family: 'Playfair Display',
    name: 'Playfair Display',
    fontFamily: 'Playfair Display',
    id: 'Playfair Display',
  },
  {
    family: 'Noto Sans',
    name: 'Noto Sans',
    fontFamily: 'Noto Sans',
    id: 'Noto Sans',
  },
  { family: 'Poppins', name: 'Poppins', fontFamily: 'Poppins', id: 'Poppins' },
  { family: 'Oswald', name: 'Oswald', fontFamily: 'Oswald', id: 'Oswald' },
  { family: 'PT Sans', name: 'PT Sans', fontFamily: 'PT Sans', id: 'PT Sans' },
  { family: 'Lora', name: 'Lora', fontFamily: 'Lora', id: 'Lora' },
  {
    family: 'Roboto Slab',
    name: 'Roboto Slab',
    fontFamily: 'Roboto Slab',
    id: 'Roboto Slab',
  },
  {
    family: 'Merriweather',
    name: 'Merriweather',
    fontFamily: 'Merriweather',
    id: 'Merriweather',
  },
  {
    family: 'Source Sans 3',
    name: 'Source Sans 3',
    fontFamily: 'Source Sans 3',
    id: 'Source Sans 3',
  },
  { family: 'Ubuntu', name: 'Ubuntu', fontFamily: 'Ubuntu', id: 'Ubuntu' },
  { family: 'Nunito', name: 'Nunito', fontFamily: 'Nunito', id: 'Nunito' },
  { family: 'Bitter', name: 'Bitter', fontFamily: 'Bitter', id: 'Bitter' },
  {
    family: 'Pacifico',
    name: 'Pacifico',
    fontFamily: 'Pacifico',
    id: 'Pacifico',
  },
  {
    family: 'Josefin Sans',
    name: 'Josefin Sans',
    fontFamily: 'Josefin Sans',
    id: 'Josefin Sans',
  },
  { family: 'Cabin', name: 'Cabin', fontFamily: 'Cabin', id: 'Cabin' },
  { family: 'Barlow', name: 'Barlow', fontFamily: 'Barlow', id: 'Barlow' },
  { family: 'Rubik', name: 'Rubik', fontFamily: 'Rubik', id: 'Rubik' },
  {
    family: 'Fira Sans',
    name: 'Fira Sans',
    fontFamily: 'Fira Sans',
    id: 'Fira Sans',
  },
  { family: 'Teko', name: 'Teko', fontFamily: 'Teko', id: 'Teko' },
  { family: 'Archivo', name: 'Archivo', fontFamily: 'Archivo', id: 'Archivo' },
  { family: 'Syne', name: 'Syne', fontFamily: 'Syne', id: 'Syne' },
  { family: 'Inter', name: 'Inter', fontFamily: 'Inter', id: 'Inter' },
  {
    family: 'Quattrocento',
    name: 'Quattrocento',
    fontFamily: 'Quattrocento',
    id: 'Quattrocento',
  },
  {
    family: 'Fanwood Text',
    name: 'Fanwood Text',
    fontFamily: 'Fanwood Text',
    id: 'Fanwood Text',
  },
  {
    family: 'Fjalla One',
    name: 'Fjalla One',
    fontFamily: 'Fjalla One',
    id: 'Fjalla One',
  },
  {
    family: 'Libre Baskerville',
    name: 'Libre Baskerville',
    fontFamily: 'Libre Baskerville',
    id: 'Libre Baskerville',
  },
  { family: 'Lustria', name: 'Lustria', fontFamily: 'Lustria', id: 'Lustria' },
  {
    family: 'Quattrocento Sans',
    name: 'Quattrocento Sans',
    fontFamily: 'Quattrocento Sans',
    id: 'Quattrocento Sans',
  },
  {
    family: 'Cormorant Garamond',
    name: 'Cormorant Garamond',
    fontFamily: 'Cormorant Garamond',
    id: 'Cormorant Garamond',
  },
  {
    family: 'Proza Libre',
    name: 'Proza Libre',
    fontFamily: 'Proza Libre',
    id: 'Proza Libre',
  },
  {
    family: 'Pinyon Script',
    name: 'Pinyon Script',
    fontFamily: 'Pinyon Script',
    id: 'Pinyon Script',
  },
  { family: 'Caveat', name: 'Caveat', fontFamily: 'Caveat', id: 'Caveat' },
  {
    family: 'Amatic SC',
    name: 'Amatic SC',
    fontFamily: 'Amatic SC',
    id: 'Amatic SC',
  },
  {
    family: 'Abril Fatface',
    name: 'Abril Fatface',
    fontFamily: 'Abril Fatface',
    id: 'Abril Fatface',
  },
  { family: 'Average', name: 'Average', fontFamily: 'Average', id: 'Average' },
  { family: 'Cinzel', name: 'Cinzel', fontFamily: 'Cinzel', id: 'Cinzel' },
  {
    family: 'EB Garamond',
    name: 'EB Garamond',
    fontFamily: 'EB Garamond',
    id: 'EB Garamond',
  },
  { family: 'Praise', name: 'Praise', fontFamily: 'Praise', id: 'Praise' },
  { family: 'Arvo', name: 'Arvo', fontFamily: 'Arvo', id: 'Arvo' },
  {
    family: 'Bree Serif',
    name: 'Bree Serif',
    fontFamily: 'Bree Serif',
    id: 'Bree Serif',
  },
  {
    family: 'Josefin Slab',
    name: 'Josefin Slab',
    fontFamily: 'Josefin Slab',
    id: 'Josefin Slab',
  },
  {
    family: 'Old Standard TT',
    name: 'Old Standard TT',
    fontFamily: 'Old Standard TT',
    id: 'Old Standard TT',
  },
  {
    family: 'Crimson Text',
    name: 'Crimson Text',
    fontFamily: 'Crimson Text',
    id: 'Crimson Text',
  },
  {
    family: 'Ooh Baby',
    name: 'Ooh Baby',
    fontFamily: 'Ooh Baby',
    id: 'Ooh Baby',
  },
  { family: 'Cardo', name: 'Cardo', fontFamily: 'Cardo', id: 'Cardo' },
  { family: 'DM Sans', name: 'DM Sans', fontFamily: 'DM Sans', id: 'DM Sans' },
  { family: 'Karla', name: 'Karla', fontFamily: 'Karla', id: 'Karla' },
  { family: 'Arimo', name: 'Arimo', fontFamily: 'Arimo', id: 'Arimo' },
  {
    family: 'Birthstone',
    name: 'Birthstone',
    fontFamily: 'Birthstone',
    id: 'Birthstone',
  },
  {
    family: 'Bebas Neue',
    name: 'Bebas Neue',
    fontFamily: 'Bebas Neue',
    id: 'Bebas Neue',
  },
];
