import React from 'react';
import AvatarImage from '../../images/custom/payment.png';

const StartPayrollStep = ({ handleGetStarted }) => (
  <div className="flex-1 flex flex-col justify-center items-center">
    {/* Avatar Image  */}
    <img src={AvatarImage} alt="" className="h-[12.5rem]" />
    <h3 className="mb-6 mt-4 leading-tight text-[36px] font-medium text-[#00000099] ">
      Effortlessly Run Payroll
    </h3>
    <p className="text-sm text-[#667085] max-w-[500px] text-center mb-8 leading-tight">
      Simplify your payroll process with our intuitive tools. Calculate wages,
      manage deductions, and ensure timely payments with just a few clicks.
    </p>
    <button
      onClick={handleGetStarted}
      className="h-11 px-6 min-w-[131px] text-center text-white inline-flex justify-center items-center bg-indigo-500 shadow-button rounded-[5px]"
      type="button"
    >
      Run Payroll
    </button>
  </div>
);

export default StartPayrollStep;
