import React, { useEffect, useRef, useState } from 'react';

const TaskTab = ({ task, handleUpdateTask }) => {
  const taskDescriptionRef = useRef();

  const [editMode, setEditMode] = useState(false);
  const [description, setDescription] = useState(task?.description);

  useEffect(() => {
    if (taskDescriptionRef.current) {
      taskDescriptionRef.current.style.height = 'auto';
      taskDescriptionRef.current.style.height = `${taskDescriptionRef.current.scrollHeight + 10}px`;
    }
  }, [description, editMode]);

  useEffect(() => {
    setEditMode(false);
    setDescription(task?.description);
  }, [task?.id]);

  const handleSaveDescription = () => {
    if (description !== task?.description) {
      handleUpdateTask(task?.id, { ...task, description });
    }
    setEditMode(false);
  };

  return (
    <div className="pt-4 pb-6 px-6 border-b border-[#D0D5DD] min-h-[330px]">
      {!editMode ? (
        <div
          className=" p-2 cursor-pointer w-full inline-flex justify-start px-2 hover:bg-slate-100"
          onClick={() => setEditMode(true)}
        >
          {task?.description ? (
            <div className="w-full flex flex-col ">
              <p
                className="w-full h-fit break-words text-slate-600 leading-6 text-base font-normal "
                dangerouslySetInnerHTML={{
                  __html: task?.description?.replace(/\n/g, '<br>'),
                }}
              />
            </div>
          ) : (
            'Add a description...'
          )}
        </div>
      ) : (
        <div className="mt-2">
          <textarea
            type="textarea"
            placeholder=" Add a description..."
            rows="6"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            ref={taskDescriptionRef}
            className="form-textarea  w-full"
            onBlur={handleSaveDescription}
          />
          <div className="flex gap-4 justify-end mt-2">
            <button
              className=" border border-indigo-500 text-indigo-500 text-[16px] leading-tight h-8 w-20 flex items-center justify-center gap-2 rounded-[0.313rem] shadow-sm whitespace-nowrap"
              type="button"
              onClick={() => setEditMode(false)}
            >
              Cancel
            </button>
            <button
              className=" border border-indigo-500 bg-indigo-500 text-white text-[16px] leading-tight h-8 w-20  flex items-center justify-center gap-2 rounded-[0.313rem] shadow-sm whitespace-nowrap"
              type="button"
              onClick={() => {
                handleSaveDescription();
              }}
            >
              Save
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default TaskTab;
