import React, { useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import ModalBasic from '../../components/ModalBasic';
import { updateInstanceById } from '../../API/backend_helper';

const BillInstanceModal = ({
  isOpen,
  setIsOpen,
  instance,
  setInstance,
  reloadData,
  setBillModalOpen,
}) => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!isOpen) {
      setInstance(null);
    }
  }, [isOpen]);

  const handleSubmit = async (values, formikHandler) => {
    setLoading(true);
    try {
      const res = await updateInstanceById(
        instance?.billId,
        instance?.id,
        values,
      );
      if (res?.id) {
        await reloadData();
        formikHandler.resetForm();
        setInstance(null);
        setBillModalOpen(true);
        setIsOpen(false);
      }
    } catch (err) {
      console.log('err', err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <ModalBasic
      modalOpen={isOpen}
      setModalOpen={setIsOpen}
      title="Update Instance"
      width="max-w-sm"
    >
      {/* Modal content */}
      <div className="px-5 pt-4 pb-1  ">
        <Formik
          enableReinitialize
          initialValues={{
            total: +instance?.total || '',
          }}
          validationSchema={Yup.object({
            total: Yup.number().required('Please Enter Instance Total'),
          })}
          onSubmit={handleSubmit}
        >
          {(validation) => (
            <Form>
              <div className=" pb-1 grid grid-cols-12 gap-2 sm:gap-4">
                <div className="col-span-full">
                  <label
                    className="block text-sm font-medium mb-1"
                    htmlFor="total"
                  >
                    Total
                  </label>
                  <input
                    id="total"
                    type="text"
                    placeholder="Total"
                    name="total"
                    className={`form-input w-full ${
                      validation.touched.total &&
                      validation.errors.total &&
                      'border-rose-300'
                    }`}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.total || ''}
                  />
                  {validation.touched.total && validation.errors.total ? (
                    <div className="text-xs mt-1 text-rose-500">
                      {validation.errors.total}
                    </div>
                  ) : null}
                </div>
              </div>

              {/* Modal footer */}
              <div className="py-4">
                <div className="flex flex-wrap justify-end space-x-2">
                  <button
                    type="button"
                    className="btn-sm border-slate-200 dark:border-slate-700 hover:border-slate-300 dark:hover:border-slate-600 text-slate-600 dark:text-slate-300"
                    onClick={(e) => {
                      e.stopPropagation();
                      setIsOpen(false);
                    }}
                  >
                    Close
                  </button>
                  <button
                    type="submit"
                    className="btn-sm bg-indigo-500 hover:bg-indigo-600 text-white
                  disabled:bg-indigo-400    disabled:cursor-not-allowed"
                    disabled={loading}
                  >
                    {loading && (
                      <svg
                        className="animate-spin w-4 h-4 fill-current shrink-0 mr-2"
                        viewBox="0 0 16 16"
                      >
                        <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z" />
                      </svg>
                    )}
                    Update
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </ModalBasic>
  );
};

export default BillInstanceModal;
