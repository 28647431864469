/* eslint-disable react/display-name */
import React, { forwardRef, useState } from 'react';
import Header from '../partials/Header';
import Sidebar from '../partials/Sidebar/Sidebar';
import PersonSidebar from '../partials/Sidebar/PersonSidebar';
import { IS_PERSON } from '../utils/Utils';

const AppLayout = forwardRef(
  (
    {
      children,
      pageId = '',
      mainClass = '',
      isDashboardPage = false,
      onClickInvoiceEstimate = null,
      showSidebar = true,
      showNavLogo = false,
    },
    ref,
  ) => {
    const IsPerson = localStorage.getItem(IS_PERSON)
      ? JSON.parse(localStorage.getItem(IS_PERSON))
      : null;

    const [sidebarOpen, setSidebarOpen] = useState(window.innerWidth > 640);

    return (
      <div className={`flex h-[100dvh] overflow-hidden ${mainClass || ''}`}>
        {/* Sidebar */}

        {showSidebar &&
          (IsPerson ? (
            <PersonSidebar
              sidebarOpen={sidebarOpen}
              setSidebarOpen={setSidebarOpen}
              onClickInvoiceEstimate={onClickInvoiceEstimate}
            />
          ) : (
            <Sidebar
              sidebarOpen={sidebarOpen}
              setSidebarOpen={setSidebarOpen}
              onClickInvoiceEstimate={onClickInvoiceEstimate}
            />
          ))}

        {/* Content area */}
        <div
          id={pageId}
          className={`${
            isDashboardPage ? '' : 'relative'
          } flex flex-col flex-1 overflow-y-auto overflow-x-hidden`}
          ref={ref}
        >
          {/*  Site header */}
          <Header
            sidebarOpen={sidebarOpen}
            setSidebarOpen={setSidebarOpen}
            workflows={showNavLogo}
          />
          {children}
        </div>
      </div>
    );
  },
);
export default AppLayout;
