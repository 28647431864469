import React, { useState, useEffect, useRef } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { Loader } from '../../components/Svg';
import ModalBasic from '../../components/ModalBasic';
import CategoriesDropdown from '../transactions/CategoriesDropdown';

const ExpenseTypeModal = ({
  open,
  setOpen,
  allAccounts,
  activeExpenseType,
  handleCreateExpenseType,
  handleUpdateExpenseType,
}) => {
  const formikRef = useRef(null);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (values, formik) => {
    setLoading(true);
    try {
      if (activeExpenseType?.id) {
        await handleUpdateExpenseType(activeExpenseType?.id, values);
      } else {
        await handleCreateExpenseType(values);
      }
      setOpen(false);
      formik.resetForm();
    } catch (err) {
      console.log('err', err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!open) {
      formikRef?.current?.resetForm();
    }
  }, [open]);

  return (
    <ModalBasic
      title={`${activeExpenseType?.id ? 'Edit' : 'Add'} Expense`}
      smallModal
      modalOpen={open}
      setModalOpen={setOpen}
    >
      <Formik
        enableReinitialize
        initialValues={{
          name: activeExpenseType?.name || '',
          accountId: activeExpenseType?.accountId || null,
        }}
        validationSchema={Yup.object({
          name: Yup.string().required('Please Enter Name'),
          accountId: Yup.string().required('Please Select Account'),
        })}
        onSubmit={handleSubmit}
        innerRef={formikRef}
      >
        {(validation) => (
          <Form>
            <div className="flex justify-between flex-col min-h-[450px]">
              <div className="px-5 pt-4 pb-1">
                <label
                  className="block text-sm text-slate-600 mb-1 font-medium leading-5"
                  htmlFor="name"
                >
                  Name <span className="text-rose-500">*</span>
                </label>
                <input
                  id="name"
                  type="name"
                  placeholder="Name"
                  name="name"
                  className={`btn text-base h-12 w-full rounded-[0.313rem] border border-[#D0D5DD] text-slate-600leading-6 font-normal px-4 py-3.5 bg-white
                              placeholder:text-base placeholder:text-slate-600 placeholder:text-opacity-80 placeholder:leading-6 placeholder:font-normal ${
                                validation.touched.name &&
                                validation.errors.name &&
                                'border-rose-300'
                              }`}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.name || ''}
                />
                {validation.touched.name && validation.errors.name ? (
                  <div className="text-xs mt-1 text-rose-500">
                    {validation.errors.name}
                  </div>
                ) : null}
                <div className="flex flex-col items-start w-full mt-4">
                  <label
                    className="block text-sm mb-1 text-slate-600 font-medium leading-5"
                    htmlFor="accountId"
                  >
                    Account <span className="text-rose-500">*</span>
                  </label>
                  <CategoriesDropdown
                    allCategories={allAccounts}
                    selectedCategoryId={validation.values.accountId}
                    setSelectedCategoryId={(value) => {
                      validation.setFieldValue('accountId', value);
                    }}
                    isSetCategoryStyle
                    id="accountId"
                    name="accountId"
                    height="h-12"
                    type="account"
                  />
                  {validation.touched.accountId &&
                  validation.errors.accountId ? (
                    <div className="text-xs mt-1 text-rose-500">
                      {validation.errors.accountId}
                    </div>
                  ) : null}
                </div>
              </div>
              {/* Modal footer */}
              <div className="flex flex-wrap justify-end space-x-2 px-5 py-4 mt-3 border-t border-[#D0D5DD]">
                <button
                  type="button"
                  className="py-2.5 px-[1.875rem] h-11 w-[7rem] text-center flex justify-center items-center text-base leading-6 font-base rounded-[0.313rem] bg-white text-indigo-500 border border-indigo-500"
                  onClick={() => {
                    validation.resetForm();
                    setOpen(false);
                  }}
                >
                  Close
                </button>
                <button
                  type="submit"
                  className="py-2.5 px-[1.875rem] h-11 w-[7rem] bg-indigo-500 text-center text-white text-base leading-6 font-base rounded-[0.313rem] disabled:cursor-not-allowed"
                  disabled={loading}
                >
                  {loading ? (
                    <div className="flex items-center justify-center">
                      <Loader width="w-6" height="h-6" color="#FFFFFF" />
                    </div>
                  ) : (
                    <span>{`${activeExpenseType?.id ? 'Update' : 'Add'}`}</span>
                  )}
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </ModalBasic>
  );
};

export default ExpenseTypeModal;
