import React from 'react';
import ModalBasic from '../../../components/ModalBasic';
// import DatePickerDash from "../../../components/DatePickerDash";

const AdjustmentModal = ({ isOpen, setIsOpen }) => (
  <ModalBasic
    id="adjustmentModal"
    modalOpen={isOpen}
    setModalOpen={setIsOpen}
    title="Hold On!"
    width="!w-[689px] max-w-[90vw]"
  >
    <div className="py-7 px-[60px]">
      <svg
        width="38"
        height="39"
        viewBox="0 0 38 39"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M18.9993 35.3346C27.7439 35.3346 34.8327 28.2458 34.8327 19.5013C34.8327 10.7568 27.7439 3.66797 18.9993 3.66797C10.2548 3.66797 3.16602 10.7568 3.16602 19.5013C3.16602 28.2458 10.2548 35.3346 18.9993 35.3346Z"
          stroke="#E48642"
          strokeWidth="1.9"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M19 13.1641V19.4974"
          stroke="#E48642"
          strokeWidth="1.9"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M19 25.832H19.0168"
          stroke="#E48642"
          strokeWidth="1.18028"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
      <h3 className="text-[24px] font-medium text-[#101828] leading-tight mb-6 mt-4">
        Hold on! Your difference is isn’t $0.00 yet.
      </h3>
      <p className="text-[#667085] mb-4">
        You are not ready to reconcile yet because your transactions do not
        match your statement. When they match, you will have a difference of
        $0.00.
      </p>
      {/* <p className="text-[#667085] mb-4">
          If you’d still like to procedd, confirm the following below, and then
          click “Add adjustment and finsh:
        </p>
        <label className="text-[#101828] mb-2 block w-fit" htmlFor="adjustmentDate">
          Adjustment Date*
        </label>
        <DatePickerDash
          width="w-[300px] max-w-full"
          id="adjustmentDate"
          placeholder="Select Date"
          value={null}
        /> */}
    </div>
    <footer className="px-9 py-[18px] border-t border-[#D0D5DD] flex justify-end items-center">
      {/* <button className="h-11  px-7 bg-white text-[#667085] rounded-[5px] shadow-button" >Add Adjustment & Finish</button> */}
      <div />
      <button
        className="h-11  px-7 bg-indigo-500 text-white rounded-[5px] "
        onClick={() => setIsOpen(false)}
        type="button"
      >
        Go, Back
      </button>
    </footer>
  </ModalBasic>
);

export default AdjustmentModal;
