import React, { useContext, useEffect, useState } from "react";
import {
  changeMemberRole,
  deleteInvitedMemberApi,
  removeUserFromTeamApi,
  addTeamMember,
  getDefaultAccounts,
  getAccountTypes,
  getAccountSubTypes,
  deleteDefaultAccount,
} from "../../../API/backend_helper";
import { OWNER } from "../../../utils/Utils";
import Tooltip from "../../../components/Tooltip";
import DefaultAccountModal from "./DefAccountModal";
import PaginationClassic from "../../../components/PaginationClassic";
import DropdownFull from "../../../components/DropdownFull";

const DefaultAccounts = () => {
  const [loading, setLoading] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [accounts, setAccounts] = useState([]);
  const [localLimit, setLocalLimit] = useState(50);
  const [types, setTypes] = useState([]);
  const [subTypes, setSubTypes] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [selectedType, setSelectedType] = useState("");
  const [selectedSubType, setSelectedSubType] = useState("");

  const getAccountTypesApi = async () => {
    try {
      const typeResponse = await getAccountTypes();
      const subTypeResponse = await getAccountSubTypes();
      setTypes(typeResponse);
      setSubTypes(subTypeResponse);
    } catch (e) {
      console.log("error", e);
    }
  };

  const getAccounts = async ({
    isLoading = false,
    limit = localLimit,
    page = accounts?.page,
    type = selectedType,
    subType = selectedSubType,
  }) => {
    if (!loading && isLoading) {
      setLoading(true);
    }
    try {
      const res = await getDefaultAccounts({ limit, page, type, subType });
      setAccounts(res?.data);
    } catch (err) {
      console.log("err", err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAccounts({ isLoading: true });
    getAccountTypesApi();
  }, []);

  const onDeleteAccount = async (id) => {
    try {
      const res = await deleteDefaultAccount(id);
      if (res) {
        getAccounts({});
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  const onSubTypeChange = (val) => {
    setSelectedSubType(val);
    getAccounts({
      subType: val,
    });
  };

  const onTypeChange = (val) => {
    setSelectedType(val);
    setSelectedSubType("");
    getAccounts({
      type: val,
      subType: "",
    });
  };

  const handleResetFilters = () => {
    setSelectedType("");
    setSelectedSubType("");
    getAccounts({
      type: "",
      subType: "",
    });
  };

  const { page, totalRecords, records } = accounts || {};

  return (
    <div className="flex overflow-hidden" style={{ width: "100%" }}>
      <DefaultAccountModal
        isOpen={isOpenModal}
        setIsOpen={setIsOpenModal}
        accountTypes={types}
        accountSubTypes={subTypes}
        loadData={getAccounts}
        account={selectedAccount}
        setSelectedAccount={setSelectedAccount}
      />
      <div className="relative flex  flex-col flex-1 overflow-y-auto overflow-x-hidden bg-white dark:bg-[#182235]">
        {loading && false ? (
          <div className="flex flex-col gap-2 items-center pb-2 justify-center mt-8">
            <svg
              width="29"
              height="29"
              viewBox="0 0 29 29"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="animate-spin w-10 h-10 fill-current shrink-0"
            >
              <path
                d="M14.5 2.41699V7.25033"
                stroke="#E48642"
                stroke-width="2.55882"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M14.5 21.749V26.5824"
                stroke="#E48642"
                stroke-width="2.55882"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M5.95703 5.95703L9.37662 9.37662"
                stroke="#E48642"
                stroke-width="1.58955"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M19.623 19.6211L23.0426 23.0407"
                stroke="#E48642"
                stroke-width="1.58955"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M2.41699 14.5H7.25033"
                stroke="#E48642"
                stroke-width="1.58955"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M21.749 14.5H26.5824"
                stroke="#E48642"
                stroke-width="1.58955"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M5.95703 23.0407L9.37662 19.6211"
                stroke="#E48642"
                stroke-width="1.58955"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M19.623 9.37662L23.0426 5.95703"
                stroke="#E48642"
                stroke-width="1.58955"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <p className="text-slate-600 font-medium text-[0.875rem] text-center">
              Fetching data, please wait...
            </p>
          </div>
        ) : (
          <main className="grow pb-4 w-full max-w-9xl mx-auto">
            <div className="flex items-center justify-between mb-7  ">
              <h1 className="text-2xl  text-[#101828B2] dark:text-slate-100 font-medium">
                Default Accounts
              </h1>
              <button
                className="btn !bg-indigo-500 text-base hover:!bg-indigo-600 text-white"
                aria-controls="basic-modal"
                onClick={() => {
                  setIsOpenModal(true);
                }}
              >
                <svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="mr-2"
                >
                  <path
                    d="M7.00008 1.16602V12.8327M1.16675 6.99935H12.8334"
                    stroke="white"
                    stroke-width="1.66667"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                Add Account
              </button>
            </div>
            <div className="bg-white flex-1 dark:bg-slate-800 rounded-sm  overflow-x-auto">
              <div className="w-full mb-4 mt-2 flex sm:flex-row flex-col justify-between items-center gap-4 sm:gap-7">
              <div className="flex-1 flex gap-4 flex-wrap items-center">
               <div className="flex-1.5">
                  <DropdownFull
                    options={types.map((d) => ({
                      ...d,
                      name: d.name,
                      id: d.name,
                    }))}
                  scrollIntoView={false}
                    name="name"
                    setSelected={(name) => {
                      onTypeChange(name);
                    }}
                    selected={selectedType}
                    accounts
                    accountStyle
                    placeholder="Select Type"
                  />
                </div>
                <div className="flex-1.5">
                  <DropdownFull
                  scrollIntoView={false}
                    options={subTypes
                      .filter((d) => {
                        const tempSelectedType = types.find(
                          (type) => type?.name === selectedType
                        );
                        return d.type === tempSelectedType?.name;
                      })
                      .map((d) => ({
                        ...d,
                        subTypeId: d.name,
                        id: d.id,
                      }))}
                    name="subTypeId"
                    setSelected={(option) => {
                      onSubTypeChange(option);
                    }}
                    selected={selectedSubType}
                    // accounts
                    accountStyle
                    subType
                    placeholder="Select Subtype"
                    disabled={!selectedType}
                  />
                </div>
                <div className="flex-1">
                  <button
                    onClick={handleResetFilters}
                    className="btn h-10 py-0 px-4 font-normal text-sm leading-6 border border-red-400 text-red-400 bg-[#FF4B4B05]"
                  >
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="mr-1 f"
                    >
                      <path
                        d="M12.6683 0.535107C12.2503 0.726513 12.0472 1.18354 12.1917 1.61323L12.2542 1.79683L12.1683 1.74214C10.8519 0.902294 9.49642 0.49995 8.00033 0.49995C5.41439 0.49995 3.06283 1.78511 1.65267 3.9687C1.21127 4.6562 0.789391 5.71089 0.61361 6.57808C0.492516 7.17573 0.465172 8.41792 0.555016 9.05464C0.922203 11.582 2.47689 13.707 4.76595 14.7929C6.80502 15.7656 9.1722 15.7734 11.1956 14.8164C11.7386 14.5585 12.016 14.3906 12.4886 14.0429C14.0785 12.871 15.1527 11.0703 15.4417 9.0898C15.5238 8.52339 15.5199 7.80073 15.4378 7.63667C15.1449 7.05464 14.3519 6.98042 13.9769 7.49605C13.848 7.67573 13.8441 7.69526 13.7855 8.42964C13.6488 10.1367 12.8285 11.625 11.4574 12.6601C11.0589 12.957 10.2503 13.375 9.77377 13.5273C8.58627 13.9023 7.40267 13.9023 6.22689 13.5195C3.03939 12.4882 1.36752 8.9687 2.59798 5.87495C3.19173 4.37495 4.38314 3.18354 5.87142 2.59761C7.17611 2.08198 8.57455 2.05073 9.87533 2.49995C10.223 2.62104 11.0081 2.99214 11.0081 3.03511C11.0081 3.05073 10.9456 3.0937 10.8714 3.13276C10.7972 3.17183 10.6878 3.27729 10.6292 3.36323C10.5316 3.50386 10.5199 3.55464 10.5199 3.8398C10.5199 4.12886 10.5316 4.17573 10.6331 4.32026C10.7035 4.42183 10.8167 4.51948 10.9417 4.58198L11.141 4.67964H12.598C14.1878 4.67964 14.223 4.67573 14.4613 4.45308C14.5238 4.39448 14.6058 4.2773 14.6449 4.19136C14.7816 3.89448 14.7542 3.74605 14.2699 2.30073C14.0238 1.55854 13.7777 0.890575 13.7269 0.81245C13.5238 0.507763 13.0199 0.37495 12.6683 0.535107Z"
                        fill="#FF4B4B"
                      />
                    </svg>
                    Reset
                  </button>
                </div>
                </div>

              </div>

              <table className="table-auto w-full dark:text-slate-300 mb-[5.938rem]">
                {/* Table header */}
                <thead className="text-sm shadow-md text-[#667085] dark:text-slate-400 border-none ">
                  <tr>
                    <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                      <div className="font-medium text-left">Name</div>
                    </th>
                    <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                      <div className="font-medium text-left">Description</div>
                    </th>
                    <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                      <div className="font-medium text-left">Type</div>
                    </th>
                    <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                      <div className="font-medium text-left">Sub Type</div>
                    </th>
                    <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                      <div className="font-medium text-left">Color</div>
                    </th>
                    <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                      <div className="font-medium text-left">Keywords</div>
                    </th>
                    <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                      <div className="font-medium text-left">Actions</div>
                    </th>
                  </tr>
                </thead>
                {/* Table body */}
                <tbody className="text-sm  divide-y divide-slate-200 dark:divide-slate-700 pb-5">
                  {/* Row */}
                  {records?.map?.((record) => (
                    <tr className="border-b border-slate-300">
                      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                        <div className="flex items-center text-slate-800 pr-3 w-max">
                          <div className="text-sm text-[#667085] dark:text-slate-100">
                            {record?.name}
                          </div>
                        </div>
                      </td>
                      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap ">
                        <div className=" text-[#667085] dark:text-slate-100">
                          <div className="font-normal text-slate-600 leading-6 mr-2">
                            {record?.description?.length > 19 ? (
                              <Tooltip
                                content={
                                  record?.description?.length > 50
                                    ? (() => {
                                        const segmentLength = 75;
                                        let segments = [];
                                        for (
                                          let i = 0;
                                          i < record?.description.length;
                                          i += segmentLength
                                        ) {
                                          segments.push(
                                            record?.description.substring(
                                              i,
                                              i + segmentLength
                                            )
                                          );
                                        }
                                        return segments.map(
                                          (segment, index) => (
                                            <p key={index}>{segment}</p>
                                          )
                                        );
                                      })()
                                    : record?.description
                                }
                                contentClassName={`border-none rounded-[10px] overflow-visible text-sm text-[#667085] relative tooltipArrowDown`}
                              >
                                <span>
                                  {record?.description?.slice(0, 19) + "..."}
                                </span>
                              </Tooltip>
                            ) : (
                              <span>{record?.description}</span>
                            )}
                          </div>
                        </div>
                      </td>
                      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap ">
                        <div className=" text-[#667085] dark:text-slate-100">
                          {record?.type || "--"}
                        </div>
                      </td>
                      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap ">
                        <div className=" text-[#667085] dark:text-slate-100">
                          {record?.subType?.name || "--"}
                        </div>
                      </td>
                      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap ">
                        <div
                          className=" text-[#667085] dark:text-slate-100 bg-gradient-to-tr from-slate-500 to-slate-300 w-[20px] h-[20px] rounded-sm"
                          style={
                            record?.color ? { background: record?.color } : {}
                          }
                        />
                      </td>
                      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap ">
                        {record?.keywords?.split(",")?.length > 1 ? (
                          <Tooltip
                            className=" "
                            contentClassName="border-none rounded-[10px] overflow-visible text-sm text-[#667085] relative tooltipArrowDown"
                            RenderComponent={() => (
                              <div className="pl-4 overflow-auto pr-8  py-1 flex flex-col text-[13px] font-light text-[#667085] gap-1 ">
                                <span className="text-[#101828CC] font-normal">
                                  Keywords
                                </span>
                                {record?.keywords?.split(",")?.map((t, i) => (
                                  <span key={i}>{t}</span>
                                ))}
                              </div>
                            )}
                          >
                            <div className="text-sm w-fit text-[#667085] bg-[#6670851A] px-2.5 py-[2px] rounded-[5px] whitespace-nowrap">
                              {record?.keywords?.split(",")?.[0]}{" "}
                              <span className="font-medium">
                                +{record?.keywords?.split(",")?.length - 1}
                              </span>
                            </div>
                          </Tooltip>
                        ) : (
                          record?.keywords?.split(",")?.[0] && (
                            <div className="text-sm text-[#667085] bg-[#6670851A] px-2.5 py-[2px] rounded-[5px] text-center w-fit">
                              {record?.keywords?.split(",")?.[0]}
                            </div>
                          )
                        )}
                      </td>
                      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap text-center">
                        <div className="text-sm text-[#667085] capitalize inline-flex items-center gap-3.5">
                          <div className="w-[1.625rem] h-[1.625rem] cursor-pointer">
                              <svg
                                onClick={() => {
                                  setSelectedAccount(record);
                                  setIsOpenModal(true);
                                }}
                                width="28"
                                height="28"
                                viewBox="0 0 28 28"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M10.1 26.667H17.9C24.4 26.667 27 24.067 27 17.567V9.76699C27 3.26699 24.4 0.666992 17.9 0.666992H10.1C3.6 0.666992 1 3.26699 1 9.76699V17.567C1 24.067 3.6 26.667 10.1 26.667Z"
                                  fill="white"
                                  stroke="#D0D5DD"
                                  strokeOpacity="0.6"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M13.416 7.83398H12.2493C9.33268 7.83398 8.16602 9.00065 8.16602 11.9173V15.4173C8.16602 18.334 9.33268 19.5007 12.2493 19.5007H15.7493C18.666 19.5007 19.8327 18.334 19.8327 15.4173V14.2507"
                                  stroke="#667085"
                                  strokeOpacity="0.8"
                                  stroke-width="1.3"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M16.3561 8.42905L11.7594 13.0257C11.5844 13.2007 11.4094 13.5449 11.3744 13.7957L11.1236 15.5515C11.0303 16.1874 11.4794 16.6307 12.1153 16.5432L13.8711 16.2924C14.1161 16.2574 14.4603 16.0824 14.6411 15.9074L19.2378 11.3107C20.0311 10.5174 20.4044 9.59572 19.2378 8.42905C18.0711 7.26238 17.1494 7.63572 16.3561 8.42905Z"
                                  stroke="#667085"
                                  strokeOpacity="0.8"
                                  stroke-width="1.3"
                                  stroke-miterlimit="10"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M15.6973 9.08789C16.0881 10.4821 17.1789 11.5729 18.5789 11.9696"
                                  stroke="#858D9D"
                                  stroke-miterlimit="10"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                          </div>
                          <div className="w-[1.625rem] h-[1.625rem] cursor-pointer">
                            <svg
                              width="28"
                              height="28"
                              viewBox="0 0 28 28"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              onClick={() => onDeleteAccount(record?.id)}
                            >
                              <path
                                d="M10.1 26.667H17.9C24.4 26.667 27 24.067 27 17.567V9.76699C27 3.26699 24.4 0.666992 17.9 0.666992H10.1C3.6 0.666992 1 3.26699 1 9.76699V17.567C1 24.067 3.6 26.667 10.1 26.667Z"
                                fill="white"
                                stroke="#D0D5DD"
                                strokeOpacity="0.6"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M19.6063 9.54173C17.4487 9.32792 15.2782 9.21777 13.1141 9.21777C11.8313 9.21777 10.5484 9.28257 9.26551 9.41215L7.94376 9.54173"
                                stroke="#667085"
                                strokeOpacity="0.8"
                                stroke-width="1.3"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M11.5073 8.8872L11.6498 8.03843C11.7535 7.42291 11.8313 6.96289 12.9262 6.96289H14.6238C15.7188 6.96289 15.803 7.44883 15.9002 8.04491L16.0427 8.8872"
                                stroke="#858D9D"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M18.2133 11.5889L17.7921 18.1134C17.7208 19.1306 17.6625 19.9211 15.8548 19.9211H11.6952C9.88752 19.9211 9.82921 19.1306 9.75794 18.1134L9.33679 11.5889"
                                stroke="#667085"
                                strokeOpacity="0.8"
                                stroke-width="1.3"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M12.693 16.3574H14.8505"
                                stroke="#667085"
                                strokeOpacity="0.8"
                                stroke-width="1.3"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M12.1552 13.7656H15.3948"
                                stroke="#667085"
                                strokeOpacity="0.8"
                                stroke-width="1.3"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="mt-8">
                {!loading && page >= 0 && (
                  <PaginationClassic
                    pagination={{ limit: localLimit, page, totalRecords }}
                    onPageChange={getAccounts}
                    setLimit={setLocalLimit}
                  />
                )}
              </div>
            </div>
          </main>
        )}
      </div>
    </div>
  );
};

export default DefaultAccounts;
