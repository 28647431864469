import React, { useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { forgotPassword } from '../../API/backend_helper';
import Logo from '../../images/custom/count-logo.png';
import AuthSlider from '../../components/AuthSlider';
import TextInput from '../../components/TextInput';

const ForgotPassword = () => {
  const [searchParams] = useSearchParams();
  const defaultMail = searchParams.get('email') || '';
  const [loading, setLoading] = useState(false);

  document.title = 'COUNT | Forgot Password';

  const onSubmit = async ({ email }, formikHandler) => {
    setLoading(true);
    try {
      const response = await forgotPassword({
        email: email?.toLowerCase(),
      });
      if (typeof response === 'string') {
        toast.error(response);
      } else {
        formikHandler.resetForm();
      }
    } catch (e) {
      console.log('error', e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <main className="bg-white dark:bg-slate-900">
      <div className="relative md:flex">
        {/* Content */}
        <div className="md:w-1/2">
          <div className="min-h-[100dvh] h-full flex flex-col after:flex-1">
            {/* Header */}
            <div className="flex-1">
              <div className="flex items-center justify-between h-16 px-4 sm:px-6 lg:px-8">
                {/* Logo */}
                <Link className="block" to="/">
                  <img src={Logo} alt="logo" className="h-8" />
                </Link>
              </div>
            </div>

            <div className="max-w-[476px] min-w-[50%] mx-auto w-full px-4 py-8">
              <h1 className="text-[40px] font-medium text-[#232323CC] leading-tight mb-10">
                Please enter your registered email address
              </h1>

              {/* Form */}
              <Formik
                enableReinitialize
                initialValues={{
                  email: decodeURI(defaultMail || ''),
                }}
                validationSchema={Yup.object({
                  email: Yup.string()
                    .matches(
                      /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      'Please enter valid email',
                    )
                    .required('Please Enter Email'),
                })}
                onSubmit={onSubmit}
              >
                {(validation) => (
                  <Form>
                    <div className="space-y-6">
                      <TextInput
                        label="Email"
                        type="email"
                        placeholder=" "
                        id="email"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.email || ''}
                        error={
                          validation.touched.email
                            ? validation.errors.email
                            : ''
                        }
                        required
                      />
                    </div>

                    <button
                      className="btn mt-12 w-full py-4 text-lg rounded-[10px] font-semibold !bg-indigo-500 hover:bg-indigo-600 text-white
                        disabled:!bg-indigo-300 disabled:text-white disabled:cursor-not-allowed"
                      type="submit"
                      disabled={loading}
                    >
                      {loading && (
                        <svg
                          className="animate-spin w-4 h-4 fill-current shrink-0 mr-2"
                          viewBox="0 0 16 16"
                        >
                          <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z" />
                        </svg>
                      )}
                      Request
                    </button>
                  </Form>
                )}
              </Formik>
              <div className="mt-12 text-center">
                <div className="text-base">
                  <Link
                    className="font-medium underline mr-px text-indigo-500 hover:text-indigo-600 dark:hover:text-indigo-400"
                    to="/signup"
                  >
                    Create An Account
                  </Link>{' '}
                  or{' '}
                  <Link
                    className="font-medium underline ml-px text-indigo-500 hover:text-indigo-600 dark:hover:text-indigo-400"
                    to="/signin"
                  >
                    Sign In
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Auth Slider */}

        <AuthSlider />
      </div>
    </main>
  );
};

export default ForgotPassword;
